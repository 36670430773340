import React from "react";
import PropTypes from "prop-types";
import { Button, Badge } from "@mui/material";
import { useFormikContext } from "formik";

export const BADGE_BUTTON_TEST_ID =
	"submit-button-with-badge-unapplied-changes-indicator";

function SubmitButtonWithDirtyIndicator(props) {
	const {
		actionButtonText,
		actionButtonIconComponent: ActionButtonIconComponent,
		isActionButtonDisabled
	} = props;

	const { dirty } = useFormikContext();
	const startIcon = ActionButtonIconComponent ? (
		<ActionButtonIconComponent />
	) : (
		false
	);
	return (
		<Badge
			color="warning"
			badgeContent=""
			invisible={!dirty}
			componentsProps={{
				badge: {
					"data-testid": BADGE_BUTTON_TEST_ID,
					"aria-hidden": !dirty
				}
			}}
		>
			<Button
				sx={{
					color: "common.white",
					backgroundColor: "primary.900",
					"&:hover": {
						backgroundColor: "primary.800"
					}
				}}
				variant="contained"
				size="large"
				startIcon={startIcon}
				component="button"
				type="submit"
				disabled={isActionButtonDisabled}
			>
				{actionButtonText}
			</Button>
		</Badge>
	);
}

SubmitButtonWithDirtyIndicator.propTypes = {
	actionButtonText: PropTypes.string,
	actionButtonIconComponent: PropTypes.shape(),
	isActionButtonDisabled: PropTypes.bool
};

SubmitButtonWithDirtyIndicator.defaultProps = {
	actionButtonText: null,
	actionButtonIconComponent: null,
	isActionButtonDisabled: false
};

export default SubmitButtonWithDirtyIndicator;
