export default {
	TAB_LABEL_SETTINGS: "Settings",
	TAB_LABEL_INSERTION_ORDERS: "Insertions",
	ADVERTISER_NAME_LABEL: "Advertiser Name",
	STATUS_LABEL: "Status",
	STATUS_OPTION_LABEL_ACTIVE: "Active",
	STATUS_OPTION_LABEL_INACTIVE: "Inactive",
	PRICING_OPTIONS_LABEL: "Pricing Options",
	PRICING_OPTIONS_TOOLTIP:
		"Used to specify the default pricing structure used by the Advertiser.",
	DEMAND_FEE_PERCENTAGE_LABEL: "Demand Fee Percentage",
	DEMAND_FEE_PERCENTAGE_TOOLTIP:
		"Percentage of revenue we pay to demand partners during active transactions.",
	CONTACT_NAME_LABEL: "Contact Name",
	CONTACT_EMAIL_LABEL: "Contact Email",
	CONTACT_PHONE_LABEL: "Phone Number",
	CONTACT_ADDRESS_LABEL: "Company Address",
	IS_BILLING_INFO_APPLICABLE_LABEL: "Billing Different From Contact Info",
	BILLING_CONTACT_NAME_LABEL: "Billing Name",
	BILLING_CONTACT_EMAIL_LABEL: "Billing Email",
	BILLING_CONTACT_PHONE_LABEL: "Billing Phone Number",
	BILLING_CONTACT_ADDRESS_LABEL: "Billing Address"
};
