import {
	differenceInDays,
	subDays,
	subMonths,
	endOfMonth,
	startOfMonth,
	sub,
	isToday,
	parse,
	format
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import {
	dateRangeServerFormat,
	dateTimeServerFormat
} from "../config/constants";

export const yesterdayInTimezone = timeZone => {
	const yesterdayUtc = subDays(new Date(), 1);
	return utcToZonedTime(yesterdayUtc, timeZone);
};

export const todayInTimezone = timeZone => {
	return utcToZonedTime(new Date(), timeZone);
};

export const DATE_RANGE = {
	today: "today",
	yesterday: "yesterday",
	previous7: "previous7",
	previous30: "previous30",
	monthToDate: "monthToDate",
	previousMonth: "previousMonth"
};

export const getDateRangeByTimezone = (dateRangeType, timezone) => {
	const currentDate = todayInTimezone(timezone);
	const yesterday = subDays(currentDate, 1);
	let formattedDate = { startDate: "", endDate: "" };
	switch (dateRangeType) {
		case DATE_RANGE.today:
			formattedDate.startDate = currentDate;
			formattedDate.endDate = currentDate;
			break;
		case DATE_RANGE.yesterday:
			formattedDate.startDate = yesterday;
			formattedDate.endDate = yesterday;
			break;
		case DATE_RANGE.previous7:
			formattedDate.startDate = subDays(yesterday, 6);
			formattedDate.endDate = yesterday;
			break;
		case DATE_RANGE.previous30:
			formattedDate.startDate = subDays(yesterday, 29);
			formattedDate.endDate = yesterday;
			break;
		case DATE_RANGE.monthToDate:
			formattedDate.startDate = startOfMonth(currentDate);
			formattedDate.endDate = currentDate;
			break;
		case DATE_RANGE.previousMonth:
			formattedDate.startDate = startOfMonth(subMonths(currentDate, 1));
			formattedDate.endDate = endOfMonth(subMonths(currentDate, 1));
			break;
		default:
			formattedDate = {};
			break;
	}
	return formattedDate;
};

/**
 *
 * @param start & end  expects date in dateRangeServerFormat
 * @returns range object with start and end as key
 */
export const getPreviousDateRange = ({ start, end, tz }, dateRangeType) => {
	const today = todayInTimezone(tz);
	const sourceStartDate = parse(start, dateRangeServerFormat, today);
	const sourceEndDate = parse(end, dateRangeServerFormat, today);

	const diffInDays = differenceInDays(sourceEndDate, sourceStartDate) + 1;
	let prevStart;
	let prevEnd;
	let exactTime = false;
	let useHourlyOverride = false;
	if (dateRangeType === DATE_RANGE.monthToDate) {
		prevEnd = subMonths(sourceEndDate, 1);
		prevStart = startOfMonth(prevEnd);
	} else if (dateRangeType === DATE_RANGE.previousMonth) {
		prevStart = startOfMonth(subMonths(sourceStartDate, 1));
		prevEnd = endOfMonth(prevStart);
	} else {
		prevStart = sub(sourceStartDate, { days: diffInDays });
		prevEnd = sub(sourceEndDate, { days: diffInDays });
	}

	if (isToday(sourceEndDate)) {
		// set time component today's day is included
		prevEnd.setHours(today.getHours());
		prevEnd.setMinutes(today.getMinutes());
		exactTime = true;
		useHourlyOverride = true;
	}

	return {
		start: format(prevStart, dateRangeServerFormat),
		end: format(
			prevEnd,
			exactTime ? dateTimeServerFormat : dateRangeServerFormat
		),
		diffInDays,
		exactTime,
		useHourlyOverride
	};
};

export default {
	yesterdayInTimezone
};
