import React from "react";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";
import localeContent from "./localeContent";

const userColumnsConfig = [{ key: "email", name: localeContent.COLUMN_EMAIL }];

function UserIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.USER}
			columnsConfig={userColumnsConfig}
			resourceEndpoint="manage/users"
			resourceRoute="users"
		/>
	);
}

export default UserIndex;
