import flatten from "lodash/flatten";
import PLACEMENT_TYPE_ENUM from "../../enum/placementType";

const CONTENT_PARAMS = [
	"content_episode",
	"content_title",
	"content_series",
	"content_genre",
	"content_cat",
	"content_prodq",
	"content_qagmediarating",
	"content_livestream",
	"content_len",
	"content_language"
];
const GDPR_PARAMS = ["gdpr", "gdpr_consent", "gdpr_pd"];

const POD_PARAMS = [
	"pod_size",
	"pod_max_dur",
	"pod_min_ad_dur",
	"pod_max_ad_dur"
];

const PLAYER_PARAMS = ["player_width", "player_height"];

const APP_PARAMS = ["app_name", "app_id", "app_uri", "app_ver"];

const LOCATION_PARAMS = ["ip_address", "latlng", "lat", "long"];

const DEVICE_PARAMS = ["did", "did_type"];

const MEDIA_PARAMS = [
	"video_duration",
	"media_file_url",
	"media_file_title",
	"media_description",
	"media_file_id"
];

const PAGE_PARAMS = ["page_url", "ref_page_url"];
const CB_TIMESTAMP_PARAM = "cb";

const US_PRIVACY_PARAM = "us_privacy";
const SCHAIN_BID_PARAM = "schain";
const COPPA_PARM = "coppa";
const LMT_PARAM = "lmt";
const UA_PARAM = "ua";

const MACROS = macros => {
	return flatten(macros)
		.map(macro => `${macro}=[REPLACE]`)
		.join("&");
};

const MOBILE_TAG_MACROS = MACROS([
	PLAYER_PARAMS,
	LOCATION_PARAMS,
	UA_PARAM,
	APP_PARAMS,
	SCHAIN_BID_PARAM,
	DEVICE_PARAMS,
	LMT_PARAM,
	COPPA_PARM,
	CONTENT_PARAMS,
	POD_PARAMS,
	GDPR_PARAMS,
	US_PRIVACY_PARAM
]);

const VAST_TAG_MACROS = MACROS([
	PAGE_PARAMS,
	CB_TIMESTAMP_PARAM,
	PLAYER_PARAMS,
	MEDIA_PARAMS,
	SCHAIN_BID_PARAM,
	COPPA_PARM,
	CONTENT_PARAMS,
	POD_PARAMS,
	GDPR_PARAMS,
	US_PRIVACY_PARAM
]);

const MOBILE_TAG = (pathService, uid) =>
	`${pathService}?uid=${uid}&${MOBILE_TAG_MACROS}`;

const VAST_TAG = (pathService, uid) =>
	`${pathService}?uid=${uid}&${VAST_TAG_MACROS}`;

const DISPLAY_TAG = (pathService, uid) =>
	`<script src="${pathService}/${uid}?${US_PRIVACY_PARAM} type="text/javascript"></script>`;

const OUTSTREAM_TAG = (pathService, uid) =>
	`<script type="text/javascript" id="gm-script-tag" src="${pathService}?uid=${uid}&${US_PRIVACY_PARAM}"></script>`;

function PLACEMENT_TAG_GENERATOR(placementType, pathService, uid) {
	if (placementType == null) {
		return null;
	}

	const PLACEMENT_TAG_BUILDER_TYPE = {
		[PLACEMENT_TYPE_ENUM.VAST.value]: VAST_TAG,
		[PLACEMENT_TYPE_ENUM.MOBILE.value]: MOBILE_TAG,
		[PLACEMENT_TYPE_ENUM.DISPLAY.value]: DISPLAY_TAG,
		[PLACEMENT_TYPE_ENUM.OUTSTREAM.value]: OUTSTREAM_TAG
	};

	return PLACEMENT_TAG_BUILDER_TYPE[placementType](pathService, uid);
}

export default PLACEMENT_TAG_GENERATOR;
