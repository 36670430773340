import React, { useState, useContext, forwardRef } from "react";
import PropTypes from "prop-types";

import { Operations } from "../../hooks/useResource";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import ConfirmDialogueSimple from "../ConfirmDialogueSimple/ConfirmDialogueSimple";
import MenuOptionClickHandler from "./MenuOptionClickHandler";
import { SnackbarContext } from "../../context/SnackbarContext";

const MenuOptionDeleteResource = forwardRef((props, ref) => {
	const {
		label,
		onClick,
		resourceEndpoint,
		rowItemId,
		deleteItemCallback,
		title,
		description,
		confirmLabel,
		successMessage,
		setIsLoading,
		customStartPathEndPoint
	} = props;

	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const { execute: deleteEntity } = useResourceAsync(
		resourceEndpoint,
		Operations.DELETE,
		{ customStartPathEndPoint }
	);

	const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(
		false
	);

	const openDeleteConfirmation = () => {
		setIsOpenDeleteConfirmation(true);
	};

	const closeDeleteConfirmation = () => {
		setIsOpenDeleteConfirmation(false);
	};

	const handleDeleteItem = async () => {
		closeDeleteConfirmation();
		setIsLoading(true);
		const { error } = await deleteEntity({ resourceId: rowItemId });
		setIsLoading(false);
		if (error) {
			const { message } = error;
			triggerNewSnackbarMessage({
				message,
				severity: "error"
			});
			closeDeleteConfirmation();
			return;
		}
		// Delete doesn't return data, so if no errors, it's a success
		triggerNewSnackbarMessage({
			message: successMessage,
			severity: "success"
		});
		deleteItemCallback(rowItemId);
	};

	return (
		<>
			<MenuOptionClickHandler
				ref={ref}
				label={label}
				onClick={() => {
					onClick();
					openDeleteConfirmation();
				}}
			/>
			<ConfirmDialogueSimple
				title={title}
				description={description}
				isOpen={isOpenDeleteConfirmation}
				onConfirm={handleDeleteItem}
				confirmLabel={confirmLabel}
				onCancel={closeDeleteConfirmation}
				onClose={closeDeleteConfirmation}
			/>
		</>
	);
});

MenuOptionDeleteResource.propTypes = {
	onClick: PropTypes.func,
	label: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	confirmLabel: PropTypes.string.isRequired,
	successMessage: PropTypes.string.isRequired,
	resourceEndpoint: PropTypes.string.isRequired,
	rowItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
		.isRequired,
	deleteItemCallback: PropTypes.func,
	setIsLoading: PropTypes.func.isRequired,
	customStartPathEndPoint: PropTypes.func
};

MenuOptionDeleteResource.defaultProps = {
	onClick: undefined,
	deleteItemCallback: () => {},
	customStartPathEndPoint: undefined
};

export default MenuOptionDeleteResource;
