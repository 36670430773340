import { useContext, useEffect, useReducer, useCallback } from "react";
import { FetchContext } from "../context/FetchContext";
import { getAuthenticatedEndpoint } from "../utils/endpointUtils";

export const GENERIC_API_ERROR = "Unable to fetch data";

export const Operations = {
	LIST: {
		method: "get",
		getResourceEndpoint: baseResourcePath => baseResourcePath,
		getRequestBody: () => null,
		getRequestParams: params => params.requestParams
	},
	ONE: {
		method: "get",
		getResourceEndpoint: (baseResourcePath, params) =>
			`${baseResourcePath}/${params.resourceId}`,
		getRequestBody: () => null,
		getRequestParams: params => params.requestParams
	},
	CREATE: {
		method: "post",
		getResourceEndpoint: baseResourcePath => baseResourcePath,
		getRequestBody: params => params.data,
		getRequestParams: () => null
	},
	UPDATE: {
		method: "put",
		getResourceEndpoint: (baseResourcePath, params) =>
			`${baseResourcePath}/${params.resourceId}`,
		getRequestBody: params => params.data,
		getRequestParams: () => null
	},
	UPDATE_WITHOUT_ID: {
		method: "put",
		getResourceEndpoint: baseResourcePath => baseResourcePath,
		getRequestBody: params => params.data,
		getRequestParams: () => null
	},
	DELETE: {
		method: "delete",
		getResourceEndpoint: (baseResourcePath, params) =>
			`${baseResourcePath}/${params.resourceId}`,
		getRequestBody: () => null,
		getRequestParams: () => null
	}
};

const dataFetchReducer = (state, action) => {
	switch (action.type) {
		case "SET_REQUEST":
			return {
				...state,
				request: action.payload
			};
		case "REQUEST_INIT":
			return {
				...state,
				isLoading: true,
				error: null
			};
		case "REQUEST_SUCCESS":
			return {
				...state,
				isLoading: false,
				// Delete returns no data so we set data to true to indicate success
				data: action.payload || true,
				request: null
			};
		case "REQUEST_FAILURE":
			return {
				...state,
				isLoading: false,
				error: action.payload,
				request: null
			};
		default:
			throw new Error();
	}
};

export const getApiError = axiosError => {
	if (!axiosError || !axiosError.response) {
		return GENERIC_API_ERROR;
	}
	const { detail, message } = axiosError.response.data;
	return { detail, message };
};

/**
 * Hook that returns a function that can be called on demand to set the next request
 * @param {String} resourceEndpoint the string path to an API resource (domain and authenticated endpoint prefix are added automatically)
 */
export const useResourceClient = (
	resourceEndpoint,
	operation,
	initialRequest,
	customStartPathEndPoint
) => {
	const fetchContext = useContext(FetchContext);

	const [state, dispatch] = useReducer(dataFetchReducer, {
		isLoading: false,
		error: null,
		data: null,
		request: initialRequest || null
	});

	useEffect(() => {
		let didCancel = false;

		const urlCreator = customStartPathEndPoint || getAuthenticatedEndpoint;

		const getAuthenticatedData = async () => {
			if (!state.request || !resourceEndpoint) return;
			try {
				dispatch({ type: "REQUEST_INIT" });
				const result = await fetchContext.authAxios({
					method: operation.method,
					url: urlCreator(
						operation.getResourceEndpoint(resourceEndpoint, state.request)
					),
					data: operation.getRequestBody(state.request),
					params: operation.getRequestParams(state.request)
				});

				if (!didCancel) {
					dispatch({ type: "REQUEST_SUCCESS", payload: result.data });
				}
			} catch (error) {
				if (!didCancel) {
					dispatch({ type: "REQUEST_FAILURE", payload: getApiError(error) });
				}
			}
		};
		getAuthenticatedData();

		return () => {
			didCancel = true;
		};
	}, [
		fetchContext,
		resourceEndpoint,
		operation,
		state.request,
		customStartPathEndPoint
	]);

	const setRequest = useCallback(
		(data = {}) => {
			dispatch({ type: "SET_REQUEST", payload: data });
		},
		[dispatch]
	);

	return [state.data, state.error, state.isLoading, setRequest];
};

// A stable object is used to trigger requests when no params are passed.
const defaultParams = {};
/**
 * A convenience hook used to immediately request a resource, (calls useResourceClient under the hood)
 * @param {String} resourceEndpoint the string path to an API resource (domain and authenticated endpoint prefix are added automatically)
 * @param {String} operation one of the Operations defined above representing CRUD actions/operations
 * @param {Object} params optional query parameters
 * @param {Object} getEndPoint optional parameters to base endpoint
 */
const useResource = (resourceEndpoint, operation, params, getEndPoint) => {
	const [data, error, isLoading] = useResourceClient(
		resourceEndpoint,
		operation,
		params || defaultParams,
		getEndPoint
	);

	return [data, error, isLoading];
};

export default useResource;
