import React, { useContext, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
	StyledEngineProvider,
	ThemeProvider,
	GlobalStyles
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useLocation } from "react-router-dom";
import {
	buildDashboardMuiTheme,
	buildAuthenticationMuiTheme
} from "../config/defaultMuiTheme";
import { SnackbarProvider } from "./SnackbarContext";
import DateProviders from "./DateProviders";
import { BrandingContext } from "./BrandingContext";
import { UNPROTECTED_CONTENT_ROUTES_CONFIG } from "../config/routesConfig";

/**
 * Wrap all the MUI providers so we can control branding in a common location
 */
function MuiProvider(props) {
	const { children } = props;
	const [theme, setTheme] = useState(buildDashboardMuiTheme());

	const { pathname } = useLocation();

	const isAuthRoute = useMemo(
		() =>
			UNPROTECTED_CONTENT_ROUTES_CONFIG.some(route => pathname === route.path),
		[pathname]
	);

	const { branding } = useContext(BrandingContext);
	useEffect(() => {
		setTheme(
			isAuthRoute
				? buildAuthenticationMuiTheme()
				: buildDashboardMuiTheme(branding?.companyPrimaryColor)
		);
	}, [isAuthRoute, branding]);
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<DateProviders>
					<CssBaseline />
					{isAuthRoute && (
						<GlobalStyles
							styles={{
								body: {
									height: "100vh",
									background: "url(/siprocal_bg_img.svg) no-repeat;",
									backgroundPosition: "right -180px bottom -100px;",
									backgroundSize: "70vh"
								}
							}}
						/>
					)}
					<SnackbarProvider>{children}</SnackbarProvider>
				</DateProviders>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

MuiProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default MuiProvider;
