import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material";

import lc from "./localeContent";
import { MUI_GRID_CONTAINER_SPACING } from "../../../../config/constants";
import FormField from "../../FormField";
import FormTextField from "../../../FormTextField/FormTextField";
import commonLocaleContent from "../../../../screens/commonLocaleContent";
import FormFieldLargeList from "../../../FormFieldLargeList/FormFieldLargeList";

const FIELD_KEYS = {
	customUrlKey: "customUrlKey",
	customUrlValues: "customUrlValues"
};

const VALIDATION_SCHEMA = Yup.object({
	[FIELD_KEYS.customUrlKey]: Yup.string().required(
		commonLocaleContent.REQUIRED_FIELD_WARNING
	),
	[FIELD_KEYS.customUrlValues]: Yup.array()
		.of(Yup.string())
		.min(
			1,
			lc.CUSTOM_LEAF_CREATION.VALUES_FIELD.MIN_ONE_ITEM_VALIDATION_MESSAGE
		)
});

function LeafCreationForm(props) {
	const { dialogTitleId, dialogDescriptionId, closeModal } = props;
	return (
		<Form>
			<DialogTitle id={dialogTitleId}>
				{lc.CUSTOM_LEAF_CREATION.DIALOGUE_TITLE}
				<IconButton
					aria-label="close"
					onClick={closeModal}
					sx={theme => ({
						position: "absolute",
						right: theme.spacing(1),
						top: theme.spacing(1)
					})}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={MUI_GRID_CONTAINER_SPACING}>
					<Grid item xs={12}>
						<DialogContentText id={dialogDescriptionId}>
							{lc.CUSTOM_LEAF_CREATION.DIALOGUE_DESCRIPTION}
						</DialogContentText>
					</Grid>
					<FormField>
						<FormTextField
							name={FIELD_KEYS.customUrlKey}
							id={FIELD_KEYS.customUrlKey}
							label={lc.CUSTOM_LEAF_CREATION.KEY_FIELD.LABEL}
						/>
					</FormField>
					<FormField>
						<FormFieldLargeList
							name={FIELD_KEYS.customUrlValues}
							fieldTypeOptions={{
								targetingTypeLabel:
									lc.CUSTOM_LEAF_CREATION.VALUES_FIELD.TARGETING_TYPE_LABEL
							}}
							value={[]}
						/>
					</FormField>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					color="primary"
					variant="contained"
					type="submit"
					startIcon={<SaveIcon />}
				>
					{commonLocaleContent.SAVE}
				</Button>
			</DialogActions>
		</Form>
	);
}

LeafCreationForm.propTypes = {
	dialogDescriptionId: PropTypes.string.isRequired,
	dialogTitleId: PropTypes.string.isRequired,
	closeModal: PropTypes.func.isRequired
};

function CustomUrlLeafCreator(props) {
	const { onSubmit, editLeafInitData, cancelEdit } = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		if (cancelEdit) cancelEdit();
		setIsModalOpen(false);
	};

	const dialogTitleId = "leaf-creation-dialog-title";
	const dialogDescriptionId = "leaf-creation-description";

	useEffect(() => {
		if (editLeafInitData) {
			openModal();
		}
	}, [editLeafInitData]);

	const initialValues = useMemo(() => {
		const {
			[FIELD_KEYS.customUrlKey]: existingCustomUrlKey,
			[FIELD_KEYS.customUrlValues]: existingCustomUrlValues
		} = editLeafInitData || {};
		return {
			[FIELD_KEYS.customUrlKey]: existingCustomUrlKey || "",
			[FIELD_KEYS.customUrlValues]: existingCustomUrlValues || []
		};
	}, [editLeafInitData]);

	// Submits the form data only, removing our context namespace first
	const submitAndCloseModal = formData => {
		onSubmit(formData);
		closeModal();
	};

	return (
		<>
			<Button variant="contained" onClick={openModal}>
				{lc.CUSTOM_LEAF_CREATION.BUTTON_LABEL}
			</Button>
			<Dialog
				open={isModalOpen}
				onClose={closeModal}
				aria-labelledby={dialogTitleId}
				aria-describedby={dialogDescriptionId}
				fullWidth
				maxWidth="sm"
			>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={VALIDATION_SCHEMA}
					onSubmit={submitAndCloseModal}
				>
					<LeafCreationForm
						dialogDescriptionId={dialogDescriptionId}
						dialogTitleId={dialogTitleId}
						closeModal={closeModal}
					/>
				</Formik>
			</Dialog>
		</>
	);
}

CustomUrlLeafCreator.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	editLeafInitData: PropTypes.shape(),
	cancelEdit: PropTypes.func
};

CustomUrlLeafCreator.defaultProps = {
	editLeafInitData: null,
	cancelEdit: null
};

export default CustomUrlLeafCreator;
