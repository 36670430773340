/**
 * Given a sort order asc/desc, returns the opposite
 * @param {String} orderKey asc or desc
 */
export function getOppositeOrder(orderKey) {
	return orderKey === "desc" ? "asc" : "desc";
}

function shouldCoerce(value) {
	return value === null || value === undefined;
}

function checkCoercionValue(aValue, bValue) {
	const shouldCoerceA = shouldCoerce(aValue);
	const shouldCoerceB = shouldCoerce(bValue);
	if (shouldCoerceA) {
		if (shouldCoerceB) return 0;
		return 1;
	}
	if (shouldCoerceB) {
		return -1;
	}
	return null;
}

// Below are from https://material-ui.com/components/tables/#sorting-amp-selecting
// (With the exception of the coercion logic to handle undefined and null properties)
function descendingComparator(a, b, orderBy, isNumeric) {
	const aValue = a[orderBy];
	const bValue = b[orderBy];
	const coercionValue = checkCoercionValue(aValue, bValue);
	if (coercionValue !== null) {
		return isNumeric ? coercionValue : -coercionValue;
	}
	if (bValue < aValue) {
		return -1;
	}
	if (bValue > aValue) {
		return 1;
	}
	return 0;
}

export function getComparator(order, orderBy, isNumeric = true) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy, isNumeric)
		: (a, b) => -descendingComparator(a, b, orderBy, isNumeric);
}

export function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}
