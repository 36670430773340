import React, {
	createContext,
	useCallback,
	useState,
	useEffect,
	useContext
} from "react";
import PropTypes from "prop-types";
import { AuthContext } from "./AuthContext";
import { FetchContext } from "./FetchContext";
import { DASHBOARD_API_URL } from "../config/ApiUrlConfig";
import {
	DEFAULT_BID_REQUEST_MULTIPLIER,
	DEFAULT_OPPORTUNITY_COST_MULTIPLIER
} from "../config/constants";

const SERVER_URL = DASHBOARD_API_URL;

const BrandingContext = createContext();
const { Provider } = BrandingContext;

function BrandingProvider({ children }) {
	const authContext = useContext(AuthContext);
	const { authAxios } = useContext(FetchContext);
	const [brandingState, setBrandingState] = useState(null);
	const [companyConfig, setCompanyConfig] = useState({
		bidRequestMultiplier: DEFAULT_BID_REQUEST_MULTIPLIER,
		opportunityCostMultiplier: DEFAULT_OPPORTUNITY_COST_MULTIPLIER,
		companyName: null
	});

	const updateFavicon = useCallback(id => {
		const faviconEl = document.getElementById("favicon");
		if (faviconEl) {
			faviconEl.href = `${DASHBOARD_API_URL}/branding/favicon/${id}`;
		}
	}, []);

	const fetchBranding = useCallback(
		id => {
			const defaultBrandingInfo = {
				companyPrimaryColor: "blue"
			};
			const brandingAPI = {
				url: `${SERVER_URL}/v2/branding/companies/${id}/MUI_REDESIGN_DASHBOARD`,
				method: "get",
				data: {}
			};
			return authAxios(brandingAPI)
				.then(response => {
					const {
						config,
						companyName,
						bidRequestMultiplier,
						opportunityCostMultiplier
					} = response.data;
					setBrandingState({
						companyPrimaryColor: config?.primaryColor
					});
					setCompanyConfig({
						bidRequestMultiplier,
						opportunityCostMultiplier,
						companyName
					});
					return response;
				})
				.catch(err => {
					setBrandingState(defaultBrandingInfo);
					return err;
				});
		},
		[authAxios]
	);

	// The full authContext object changes frequently but we only want to refetch branding when the companyId changes
	const [shouldFetchBranding, setShouldFetchBranding] = useState(false);
	useEffect(() => {
		if (authContext.companyId) {
			setShouldFetchBranding(true);
		}
	}, [authContext.companyId]);

	useEffect(() => {
		if (shouldFetchBranding && authContext.companyId !== null) {
			setBrandingState(null);
			fetchBranding(authContext.companyId);
			updateFavicon(authContext.companyId);
			setShouldFetchBranding(false);
		}
	}, [
		fetchBranding,
		updateFavicon,
		authContext.companyId,
		shouldFetchBranding
	]);

	useEffect(() => {
		if (authContext.authState.token == null) {
			// this is cleared so that on login, dashboard page don't show up till the time latest branding is loaded
			setBrandingState(null);
		}
	}, [authContext.authState.token]);

	return (
		<Provider
			value={{
				branding: brandingState,
				companyName: companyConfig.companyName,
				companyConfig
			}}
		>
			{children}
		</Provider>
	);
}

BrandingProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { BrandingProvider, BrandingContext };
