import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import RouterLink from "../Routing/LinkWithQuery";

const MenuOptionLink = forwardRef((props, ref) => {
	const { to, onClick, label } = props;
	return (
		<MenuItem
			ref={ref}
			to={to}
			color="inherit"
			component={RouterLink}
			onClick={onClick}
		>
			{label}
		</MenuItem>
	);
});

MenuOptionLink.propTypes = {
	to: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	label: PropTypes.string.isRequired
};

MenuOptionLink.defaultProps = {
	onClick: undefined
};

export default MenuOptionLink;
