import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { TextField, Popper } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import useResource, { Operations } from "../../hooks/useResource";
import transformOptions from "../../utils/transformOptions";
import useReplaceQueryParam from "../../hooks/useReplaceQueryParam";
import commonLocaleContent from "../../screens/commonLocaleContent";

const filterOptions = createFilterOptions({
	limit: 100
});

function FormFieldComboBox(props) {
	const {
		id,
		label,
		name,
		required,
		disabled,
		optionsConfig,
		replaceQueryParamName
	} = props;
	const { requestEndpoint, requestParams } = optionsConfig;

	const [field, meta, helpers] = useField(name);

	const replaceQueryParam = useReplaceQueryParam(replaceQueryParamName);

	const handleChange = useCallback(
		(event, newValue) => {
			const newValueId = newValue ? newValue.id : null;
			if (replaceQueryParamName) {
				replaceQueryParam(newValueId);
			}
			helpers.setValue(newValueId);
		},
		[helpers, replaceQueryParam, replaceQueryParamName]
	);
	const handleBlur = useCallback(() => {
		helpers.setTouched(true);
	}, [helpers]);

	const [optionsData, requestError] = useResource(
		requestEndpoint,
		Operations.LIST,
		{
			requestParams
		}
	);
	const fieldOptions = transformOptions(optionsConfig, optionsData);

	let showError = Boolean(meta.touched && meta.error);
	let helperText = showError ? meta.error : "";
	if (requestError) {
		showError = true;
		helperText = commonLocaleContent.UNABLE_TO_RETRIEVE_OPTIONS_WARNING;
	}

	const optionMatch =
		fieldOptions.find(option => option.id === field.value) || null;

	return (
		<Autocomplete
			id={id}
			value={optionMatch}
			onChange={handleChange}
			onBlur={handleBlur}
			disabled={disabled}
			options={fieldOptions}
			getOptionLabel={option => option.name || ""}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			filterOptions={filterOptions}
			PopperComponent={muiProps => (
				<Popper
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...muiProps}
					modifiers={[{ name: "flip", enabled: false }]}
				/>
			)}
			renderInput={params => (
				<TextField
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...params}
					label={label}
					error={showError}
					placeholder="Type to search"
					helperText={helperText}
					required={required}
					variant="filled"
				/>
			)}
			// We need to use renderOption approach becuase MUI uses label as key by default https://stackoverflow.com/a/69396153/4267979
			renderOption={(optionProps, option) => (
				// Passing MUI props
				// eslint-disable-next-line react/jsx-props-no-spreading
				<li {...optionProps} key={option.id}>
					{option.name || ""}
				</li>
			)}
			selectOnFocus
		/>
	);
}

FormFieldComboBox.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	optionsConfig: PropTypes.shape(),
	replaceQueryParamName: PropTypes.string
};

FormFieldComboBox.defaultProps = {
	required: false,
	disabled: false,
	optionsConfig: {},
	replaceQueryParamName: null
};

export default FormFieldComboBox;
