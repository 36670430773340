import { useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

/**
 * Implementation from the react router docs https://reactrouter.com/web/example/query-parameters
 * For documentation on how to use URLSearchParams see:
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 */
const useReplaceQueryParam = paramName => {
	const location = useLocation();
	const history = useHistory();
	// Memoize searchParams because new URLSearchParams creates a new object reference each time
	const searchParams = useMemo(() => new URLSearchParams(location.search), [
		location.search
	]);

	// This callback changes on every render...
	const setQueryParam = useCallback(
		value => {
			if (value === null) {
				searchParams.delete(paramName);
			} else {
				searchParams.set(paramName, value);
			}
			history.replace(`${location.pathname}?${searchParams.toString()}`);
		},
		[searchParams, location, history, paramName]
	);

	return setQueryParam;
};

export default useReplaceQueryParam;
