import React from "react";
import CrudForm from "../../components/CrudForm/CrudForm";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import validators from "../../utils/validators";
import { BREADCRUMBS_ENUM } from "../../config/constants";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";

const breadcrumbsConfig = [BREADCRUMBS_ENUM.ADVERTISER, BREADCRUMBS_ENUM.NAME];
const TABS_CONFIG = [
	{
		label: localeContent.TAB_LABEL_SETTINGS,
		key: "SETTINGS",
		fieldsConfig: [
			{
				key: "ADVERTISER_SETTINGS",
				hideOnEdit: true,
				fields: [
					{
						label: localeContent.ADVERTISER_ID_LABEL,
						tooltip: localeContent.ADVERTISER_ID_TOOLTIP,
						name: "advId",
						isRequired: true,
						disableOnEdit: true,
						gridConfig: { md: 8 },
						fieldType: FieldTypes.COMBO_BOX,
						optionsConfig: {
							requestEndpoint: "manage/search/advtsrs"
						}
					}
				]
			},
			{
				key: "BASIC_SETTINGS",
				renderIf: formValues => formValues.advId,
				fields: [
					{
						label: localeContent.INSERTION_ORDER_NAME_LABEL,
						name: "name",
						isRequired: true,
						gridConfig: { md: 8 }
					},
					{
						label: commonLocaleContent.FIELD_LABEL_STATUS,
						name: "status",
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									value: "active",
									label: commonLocaleContent.STATUS_OPTION_LABEL_ACTIVE
								},
								{
									value: "inactive",
									label: commonLocaleContent.STATUS_OPTION_LABEL_INACTIVE
								}
							]
						},
						defaultValue: "active"
					},
					{
						label: localeContent.PAYMENT_TERMS_LABEL,
						name: "terms",
						gridConfig: { md: 3, lg: 2 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [
								{ value: "30", label: "30 Days" },
								{ value: "45", label: "45 Days" },
								{ value: "60", label: "60 Days" }
							]
						},
						defaultValue: "30"
					}
				]
			}
		]
	},
	{
		label: localeContent.TAB_LABEL_QUALITY,
		key: "QUALITY",
		disableIf: formValues => !formValues.advId,
		fieldsConfig: [
			{
				key: "GEO_TARGETING_SETTINGS",
				fields: [
					{
						name: "geoTargetingConfig",
						fieldType: FieldTypes.GEO_TARGETING
					}
				]
			},
			{
				key: "DEVICE_TARGETING_SETTINGS",
				fields: [
					{
						name: "deviceTargeting",
						fieldType: FieldTypes.DEVICE_TARGETING,
						defaultValue: { devices: [] }
					}
				]
			},
			{
				key: "SIZE_TARGETING_SETTINGS",
				fields: [
					{
						name: "sizeTargeting",
						fieldType: FieldTypes.SIZE_TARGETING
					}
				]
			},
			{
				key: "LIST_TARGETING_SETTINGS",
				fields: [
					{
						name: "blacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: localeContent.TARGETING_TYPE_DOMAIN_LABEL,
							validatorFunction: validators.validateDomain
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "appNameBlacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: localeContent.TARGETING_TYPE_APP_NAME_LABEL,
							validatorFunction: validators.validateUTF8
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "appBundleIdBlacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel:
								localeContent.TARGETING_TYPE_APP_BUNDLE_IDS_LABEL,
							validatorFunction: validators.validateUTF8
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					}
				]
			}
		]
	},
	{
		label: localeContent.TAB_LABEL_CAMPAIGNS,
		key: "CAMPAIGNS",
		disableOnCreate: true,
		childResourceConfig: {
			columnsConfig: [
				{
					name: commonLocaleContent.COLUMN_HEADER_STATUS,
					key: COLUMN_KEY_STATUS,
					cellDataType: "upperCase"
				},
				{
					key: "startDate",
					name: commonLocaleContent.COLUMN_HEADER_START_DATE,
					cellDataType: "date"
				},
				{
					key: "endDate",
					name: commonLocaleContent.COLUMN_HEADER_END_DATE,
					cellDataType: "date"
				},
				{
					name: commonLocaleContent.COLUMN_HEADER_CPM,
					key: "cpm",
					cellDataType: "currency"
				}
			],
			resourceEndpoint: "manage/campaigns",
			resourceRoute: "campaigns",
			resourceName: commonLocaleContent.CAMPAIGN,
			getRequestParams: insertionOrderId => ({
				insertionOrderId
			})
		}
	}
];

const viewReportConfig = {
	permissionsRequired: ["VIEW_REPORT_BUTTON", "VIEW_CAMPAIGN_REPORT"],
	reportType: "campaign",
	filterKeyValuesGetter: context => {
		return {
			insertionOrder: context.initData.guid
		};
	}
};

function InsertionOrderResourceScreen() {
	return (
		<CrudForm
			resourceEndpoint="manage/insertion-orders"
			resourceRoute="insertion-orders"
			parentIdField="advId"
			parentStatusKey="advertiserStatus"
			breadcrumbsConfig={breadcrumbsConfig}
			resourceString={commonLocaleContent.INSERTION_ORDER}
			tabsConfig={TABS_CONFIG}
			viewReportConfig={viewReportConfig}
		/>
	);
}

export default InsertionOrderResourceScreen;
