import React from "react";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";
import { isEmpty } from "../../utils/objectUtils";
import localeContent from "./localeContent";

function InheritedHelperText(props) {
	const { inheritedValues } = props;

	return !isEmpty(inheritedValues) ? (
		<FormHelperText>
			{`${localeContent.INHERITED_LABEL}: ${inheritedValues
				.map(({ name }) => name)
				.join(", ")}`}
		</FormHelperText>
	) : null;
}

InheritedHelperText.propTypes = {
	inheritedValues: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

InheritedHelperText.defaultProps = {};

export default InheritedHelperText;
