export default {
	TAB_LABEL_SETTINGS: "Settings",
	TAB_LABEL_QUALITY: "Quality",
	TAB_LABEL_CAMPAIGNS: "Campaigns",
	ADVERTISER_ID_LABEL: "Advertiser Name",
	ADVERTISER_ID_TOOLTIP:
		"Select the Advertiser which this Insertion should belong to.",
	INSERTION_ORDER_NAME_LABEL: "Insertion Name",
	PAYMENT_TERMS_LABEL: "Payment Terms",
	TARGETING_TYPE_DOMAIN_LABEL: "Domain",
	TARGETING_TYPE_APP_NAME_LABEL: "App Name",
	TARGETING_TYPE_APP_BUNDLE_IDS_LABEL: "App Bundle ID"
};
