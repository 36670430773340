import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box, Container } from "@mui/material";

import { NAV_RAIL_WIDTH } from "../../config/constants";

const APP_BAR_HEIGHT = 80;

function SkeletonLoader() {
	return (
		<>
			<Skeleton
				variant="rectangular"
				sx={{
					width: NAV_RAIL_WIDTH,
					top: 0,
					height: "100vh",
					position: "fixed",
					boxShadow: "11px 0px 52px -35px rgb(0 0 0 / 75%)",
					backgroundColor: "white"
				}}
			/>
			<Box sx={{ ml: `${NAV_RAIL_WIDTH}px` }}>
				<Skeleton
					variant="rectangular"
					sx={{
						bgcolor: "common.white"
					}}
					height={APP_BAR_HEIGHT}
				/>
				<Container maxWidth={false} sx={{ pt: 3 }}>
					<Skeleton
						variant="rectangular"
						height="80vh"
						sx={{
							bgcolor: "common.white"
						}}
					/>
				</Container>
			</Box>
		</>
	);
}

export default SkeletonLoader;
