export default {
	BUTTON_TEXT_ADD_MACRO: "Add Macro",
	INPUT_TEXT_ADD_MACRO_NAME: "macro-auto-complete-input",
	GEO_LABEL: "Geo",
	COUNTRY_LABEL: "Country",
	DMA_LABEL: "DMA",
	DNT_LABEL: "Do Not Track",
	SSAI_LABEL: "SSAI",
	STATE_CODE_LABEL: "State/Region Code",
	STATE_NAME_LABEL: "State/Region Name",
	POSTAL_CODE_LABEL: "Postal Code",
	CONNECTION_TYPE_LABEL: "Connection Type",
	IP_ADDRESS_LABEL: "IP Address",
	LATITUDE_LABEL: "Latitude",
	LONGITUDE_LABEL: "Longitude",
	LAT_LONG_LABEL: "Lat/Long",
	INTERNET_SERVICE_PROVIDER_LABEL: "Internet Service Provider",
	PAGE_LABEL: "Page",
	PAGE_DOMAIN_LABEL: "Page Domain",
	REFERRAL_DOMAIN_LABEL: "Referral Domain",
	PAGE_URL_LABEL: "Page URL",
	REFERRAL_URL_LABEL: "Referral URL",
	USER_LABEL: "User",
	ADVERTISING_DEVICE_LABEL: "Advertising Device ID",
	IDFA_DEVICE_LABEL: "IDFA Device ID",
	USER_ID_LABEL: "User ID",
	USER_ID_TYPE_LABEL: "User ID Type",
	US_PRIVACY_LABEL: "US Privacy",
	MOBILE_APP_LABEL: "Mobile App",
	APP_NAME_LABEL: "App Name",
	APP_ID_LABEL: "App ID",
	APP_URI_LABEL: "App URI",
	APP_VERSION_LABEL: "App Version",
	MEDIA_LABEL: "Media",
	DEAL_ID_LABEL: "Deal ID",
	DEAL_FLOOR_LABEL: "Deal Floor",
	PRIVATE_DEAL_LABEL: "Private Deal",
	BIDDER_GUID_LABEL: "Bidder GUID",
	PLACEMENT_LABEL: "Placement",
	PLAYER_WIDTH_LABEL: "Player Width",
	PLAYER_HEIGHT_LABEL: "Player Height",
	MAX_DURATION_LABEL: "Max Duration",
	POD_SIZE_LABEL: "Pod Size",
	POD_MAX_DURATION_LABEL: "Pod Max Duration",
	MINIMUM_AD_DURATION_LABEL: "Minimum Ad Duration",
	MAXIMUM_AD_DURATION_LABEL: "Maximum Ad Duration",
	MEDIA_GUID_LABEL: "Media GUID",
	CAMPAIGN_GUID_LABEL: "Campaign GUID",
	IO_GUID_LABEL: "IO GUID",
	SITE_GUID_LABEL: "Site GUID",
	PLACEMENT_GUID_LABEL: "Placement GUID",
	PUBLISHER_GUID_LABEL: "Publisher GUID",
	ADVERTISER_GUID_LABEL: "Advertiser GUID",
	MISC_LABEL: "Misc",
	RANDOM_NUMBER_LABEL: "Random Number",
	TIMESTAMP_LABEL: "Timestamp",
	CACHEBUSTER_LABEL: "Cachebuster",
	TID_LABEL: "Transaction ID",
	CONTENT_LABEL: "Content",
	CONTENT_EPISODE_LABEL: "Content Episode",
	CONTENT_TITLE_LABEL: "Content Title",
	CONTENT_SERIES_LABEL: "Content Series",
	CONTENT_GENRE_LABEL: "Content Genre",
	CONTENT_CATEGORY_LABEL: "Content Category",
	CONTENT_PRODUCTION_QUALITY_LABEL: "Content Production Quality",
	CONTENT_MEDIA_RATING_LABEL: "Content Media Rating",
	CONTENT_LIVESTREAM_LABEL: "Content Livestream",
	CONTENT_LENGTH_LABEL: "Content Length",
	CONTENT_LANGUAGE_LABEL: "Content Language",
	CONTENT_NETWORK_LABEL: "Network Name",
	CONTENT_CHANNEL_LABEL: "Channel Name",
	PLCMT_LABEL: "Placement",
	DEVICE_LABEL: "Device",
	USER_AGENT_LABEL: "User Agent",
	OPERATING_SYSTEM_LABEL: "Operating System",
	OS_VERSION_LABEL: "OS Version",
	OS_MAJOR_VERSION_LABEL: "OS Major Version",
	OS_MINOR_VERSION_LABEL: "OS Minor Version",
	DEVICE_TYPE_LABEL: "Device Type",
	DEVICE_MAKE_LABEL: "Device Make",
	DEVICE_MODEL_LABEL: "Device Model",
	DEVICE_ID_LABEL: "Device ID",
	GPP_LABEL: "GPP String",
	GPP_SID_LABEL: "GPP SID",
	TOOLTIP: {
		COUNTRY_TOOLTIP: "Country Code (example: US)",
		DMA_TOOLTIP: "DMA Code (example: 524)",
		DNT_TOOLTIP:
			"Browser setting that signals to websites and online services if the user does or does not want to be tracked for advertising or analytics purposes",
		STATE_CODE_TOOLTIP: "State/Region Code (example: CO)",
		STATE_NAME_TOOLTIP: "State/Region Full Name (example: Colorado)",
		POSTAL_CODE_TOOLTIP: "Postal Code (example: 10010)",
		CONNECTION_TYPE_TOOLTIP: "Connection Type: (example: Con)",
		IP_ADDRESS_TOOLTIP: "IP Address (example: 192.168.10.1)",
		LATITUDE_TOOLTIP: "Latitude (example: 39.732926)",
		LONGITUDE_TOOLTIP: "Longitude (example: -104.9222)",
		LAT_LONG_TOOLTIP: "Latitude/Longitude (example: 39/73926/-104.9222)",
		INTERNET_SERVICE_PROVIDER_TOOLTIP:
			"Internet Service Provider (example: Comcast)",
		PAGE_DOMAIN_TOOLTIP: "Page Domain (example: test.com)",
		REFERRAL_DOMAIN_TOOLTIP: "Referral Domain (example: reftest.com)",
		PAGE_URL_TOOLTIP: "Page URL (example: http://test.com/path)",
		PLCMT_TOOLTIP: "IAB defined Video placement type for the impression",
		REFERRAL_URL_TOOLTIP: "Referral URL (example: http://reftest.com/path)",
		ADVERTISING_DEVICE_TOOLTIP:
			"Advertising Device ID (example: 328131e7-85ae-4de6-a9de-551593956de4)",
		IDFA_DEVICE_TOOLTIP:
			"IDFA Device ID (example: ac854cf4-c6fe-48e7-af45-a9c812663e97)",
		USER_ID_TOOLTIP:
			"User ID - One of IDFA, AID or Cookie ID (example: ac854c6fe48e7af45a9c812663e97)",
		USER_ID_TYPE_TOOLTIP:
			"Type of ID provided by CID - IDFA, AID, or Cookie ID (example: CID)",
		US_PRIVACY_TOOLTIP: "User Privacy Setting (example: 1YN1-)",
		APP_NAME_TOOLTIP: "Application Name (example: Spotify)",
		APP_ID_TOOLTIP: "Application Bundle ID (example: com.apple.mobilesafari)",
		APP_URI_TOOLTIP:
			"Application App Store URI (example: http://itunes.apple.com/,country./app/,app-name>/id<app-ID.?mt=8)",
		APP_VERSION_TOOLTIP: "Application Version (example: 1.2.1)",
		DEAL_ID_TOOLTIP: "Deal ID (example: 1322465)",
		DEAL_FLOOR_TOOLTIP: "Deal Floor (example: 15.00)",
		PRIVATE_DEAL_TOOLTIP: "Is Private Deal? (example: true or false)",
		BIDDER_GUID_TOOLTIP: "Bidder GUID (example: HA4P1BS8G88UP7DSA9)",
		PLAYER_WIDTH_TOOLTIP: "Player Width (example: 300)",
		PLAYER_HEIGHT_TOOLTIP: "Player Height (example: 250)",
		MAX_DURATION_TOOLTIP: "Max Ad Duration (example: 60)",
		POD_SIZE_TOOLTIP: "Max number of ads in the pod",
		POD_MAX_DURATION_TOOLTIP: "Length of ad pod in seconds",
		ADVERTISER_GUID_TOOLTIP:
			"Advertiser GUID (example: DMA26CK9PD1334R2ERDHMTSBES)",
		PUBLISHER_GUID_TOOLTIP:
			"Publisher GUID (example: DMA26CK9PD1334R2ERDHMTSBES)",
		PLACEMENT_GUID_TOOLTIP:
			"Placement GUID (example: DMA26CK9PD1334R2ERDHMTSBES)",
		SITE_GUID_TOOLTIP: "Site GUID (example: DMA26CK9PD1334R2ERDHMTSBES)",
		SSAI_TOOLTIP: "SSAI (example: 0,1,2,3)",
		CAMPAIGN_GUID_TOOLTIP:
			"Campaign GUID (example: DMA26CK9PD1334R2ERDHMTSBES)",
		IO_GUID_TOOLTIP: "IO GUID (example: DMA26CK9PD1334R2ERDHMTSBES)",
		MEDIA_GUID_TOOLTIP: "Media GUID (example: DMA26CK9PD1334R2ERDHMTSBES)",
		MINIMUM_AD_DURATION_TOOLTIP:
			"Minimum length of each ad in the pod in seconds",
		MAXIMUM_AD_DURATION_TOOLTIP:
			"Maximum length of each ad in the pod in seconds",
		RANDOM_NUMBER_TOOLTIP: "Random Number (example: 1455054556154)",
		TIMESTAMP_TOOLTIP: "EPOC in milliseconds (example: 1455054556154)",
		CACHEBUSTER_TOOLTIP: "CacheBuster String (example: 1455054556154)",
		TID_TOOLTIP: "Transaction ID (example: HA4P1BS8G88UP77K7UG63)",
		CONTENT_EPISODE_TOOLTIP: "Episode Number (example: 5)",
		CONTENT_TITLE_TOOLTIP: "Content Title (example: Star Wars)",
		CONTENT_SERIES_TOOLTIP: "Content Series (example: The Office)",
		CONTENT_GENRE_TOOLTIP: "Content Genre (example: Comedy, Drama)",
		CONTENT_CATEGORY_TOOLTIP: "IAB Content Category (example: IAB1-7)",
		CONTENT_PRODUCTION_QUALITY_TOOLTIP: "Production Quality (example: 0,1,2,3)",
		CONTENT_MEDIA_RATING_TOOLTIP:
			"Media rating per IQG guidelines (example: 1,2,3)",
		CONTENT_LIVESTREAM_TOOLTIP: "Livestream Status (example: 0 or 1)",
		CONTENT_LENGTH_TOOLTIP: "Length of content in seconds",
		CONTENT_LANGUAGE_TOOLTIP: "Language of content",
		CONTENT_NETWORK_TOOLTIP:
			'Network the content is on (e.g., a TV network like "ABC")',
		CONTENT_CHANNEL_TOOLTIP:
			'Channel the content is on (e.g., a local channel like "WABC-TV")',
		USER_AGENT_TOOLTIP:
			"User Agent (example: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.90 Safari/537.36)",
		OPERATING_SYSTEM_TOOLTIP: "OS (example: Windows, OSX, iOS, Android, Other)",
		OS_VERSION_TOOLTIP: "OS Version (example: 10.1)",
		OS_MAJOR_VERSION_TOOLTIP: "OS Major Version (example: 10)",
		OS_MINOR_VERSION_TOOLTIP: "OS Minor Version (example: 1)",
		DEVICE_TYPE_TOOLTIP:
			"Device Type (example: Desktop, Tablet, SmartPhone, Connected TV)",
		DEVICE_MAKE_TOOLTIP: "Device Make (example: Samsung)",
		DEVICE_MODEL_TOOLTIP: "Device Model (example: Galaxy Nexus)",
		DEVICE_ID_TOOLTIP:
			"Device ID (example: 12345678-ABCD-1234-ZZYY-0987654321BE)",
		GPP_TOOLTIP: "GPP String (e.g. DBABRg~BQAAABA)",
		GPP_SID_TOOLTIP: "GPP Section ID (e.g. 9)"
	},
	LIMIT_AD_TRACKING: {
		LABEL: "Limit Ad Tracking",
		TOOLTIP: "Allows user to opt-out of ad targeting based on IDFA"
	}
};
