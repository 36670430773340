import React from "react";
import PropTypes from "prop-types";
import {
	FormControlLabel,
	FormControl,
	FormHelperText,
	Box
} from "@mui/material";
import MuiSwitch from "@mui/material/Switch";

function Switch(props) {
	const {
		id,
		label,
		name,
		value,
		showError,
		helperText,
		onChange,
		onBlur,
		disabled
	} = props;
	return (
		<FormControl error={showError}>
			<div>
				<FormControlLabel
					control={
						<MuiSwitch
							id={id}
							checked={value}
							onChange={onChange}
							onBlur={onBlur}
							name={name}
							color="primary"
						/>
					}
					disabled={disabled}
					label={label}
					labelPlacement="start"
					sx={{ ml: 0.5 }}
				/>
			</div>

			<Box ml={0.5}>
				<FormHelperText>{helperText}</FormHelperText>
			</Box>
		</FormControl>
	);
}

Switch.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.bool,
	showError: PropTypes.bool,
	helperText: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool
};

Switch.defaultProps = {
	value: false,
	showError: false,
	helperText: null,
	onChange: null,
	onBlur: null,
	disabled: false
};

export default Switch;
