export default {
	TRAFFIC_FILTER_NAME: "Traffic Filter",
	TRAFFIC_FILTERS_TITLE: "Edit Traffic Filters",
	RULE_TYPE_LABEL: "Rule Type",
	FILTER_VALUE_LABEL: "Filter Value",
	COLUMN_HEADERS: {
		CREATED_AT: "Created",
		UPDATED_AT: "Updated"
	},
	FILTER_VALUE_REQUIRED_MESSAGE: "A value is required",
	FILTER_ADDED_SUCCESS_MESSAGE: "Filter added",
	FILTER_DELETED_SUCCESS_MESSAGE: "Successfully deleted filter",
	RULE_TYPE_OPTION_LABEL: {
		HEADER_ACCEPT_ENCODING: "Header - Accept Encoding",
		HEADER_USER_AGENT: "Header - User-Agent",
		HEADER_COOKIE: "Header - Cookie",
		HEADER_REFERER: "Header - Referrer",
		HEADER_HOST: "Header - Host",
		REQUEST_URL: "Request URL",
		IP_ADDRESS: "IP Address",
		PLACEMENT_GUID: "Placement GUID"
	},
	ADD_TRAFFIC_FILTER_BUTTON_LABEL: "add traffic filter",
	TRAFFIC_FILTERS_TABLE_DESCRIPTION: "traffic filters table"
};
