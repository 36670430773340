import * as Yup from "yup";
import React from "react";
import PropTypes from "prop-types";
import { IconButton, Grid } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import TextField from "../FormTextField/TextField";
import PLACEMENT_TAG_GENERATOR from "./PlacementTagGenerator";

function PlacementTag(props) {
	const { name, fieldTypeOptions, formValues } = props;

	const {
		getPlacementType,
		pathService,
		labelTag,
		getGuid,
		getDescription
	} = fieldTypeOptions;

	const nameTag = `${name}-tag`;

	const guid = getGuid(formValues);
	const description = getDescription(formValues);

	const tagValue =
		PLACEMENT_TAG_GENERATOR(getPlacementType(formValues), pathService, guid) ||
		"";

	const onDownload = () => {
		const fileName = description.replace(" ", "_");
		const title = `<!-- ${description} -->\n`;
		const file = new Blob([title, tagValue], { type: "text/plain" });

		const link = document.createElement("a");
		link.download = `${fileName}.txt`;
		link.href = URL.createObjectURL(file);

		document.body.appendChild(link); // Required for this to work in FireFox
		link.click();
	};

	return (
		<Grid container spacing={MUI_GRID_CONTAINER_SPACING}>
			<Grid item xs={11}>
				<TextField
					id={nameTag}
					name={nameTag}
					label={labelTag}
					value={tagValue}
					disabled
					multiline
				/>
			</Grid>
			<Grid item xs={1}>
				<IconButton
					variant="contained"
					onClick={() => {
						navigator.clipboard.writeText(tagValue);
					}}
				>
					<ContentCopyIcon />
				</IconButton>
				<IconButton variant="contained" onClick={() => onDownload()}>
					<DownloadIcon />
				</IconButton>
			</Grid>
		</Grid>
	);
}

PlacementTag.propTypes = {
	name: PropTypes.string.isRequired,
	fieldTypeOptions: PropTypes.shape(),
	formValues: PropTypes.shape()
};

PlacementTag.defaultProps = { fieldTypeOptions: {}, formValues: {} };

export const FieldTypePlacementTag = {
	component: PlacementTag,
	baseValidation: Yup.string().nullable()
};

export default PlacementTag;
