import React from "react";
import { Container, Typography } from "@mui/material";
import lc from "./localeContent";

function ReportingPageError() {
	return (
		<Container
			maxWidth={false}
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<Typography variant="inherit" align="center">
				{lc.API_FAILS_ERROR_MESSAGE}
			</Typography>
		</Container>
	);
}
export default ReportingPageError;
