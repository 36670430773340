export default {
	FIELD_LABEL_BIDDERS: "Bidders",
	TOOLBAR_TITLE_SELECED_BIDDERS: "Selected Bidders",
	BUTTON_LABEL_CLEAR_ALL: "Clear",
	BUTTON_LABEL_ADD: "add",
	BUTTON_LABEL_REMOVE: "remove",
	VALIDATION_MESSAGES: {
		MIN_ONE_BIDDER: "Must select at least one bidder",
		MAX_ONE_BIDDER_WHEN_OPEN_AUCTION:
			"Only a single bidder may be selected when Deal is disabled. Please remove additional bidders.",
		MAX_ONE_BIDDER_WHEN_FIXED_PRICE_DEAL:
			"Only a single bidder can participate in Fixed-Price Deals. Please remove additional bidders.",
		ATLEAST_ONE_ACTIVE_BIDDER:
			"An active bidder is required to save this demand as active. - Please remove inactive bidders and seats"
	},
	NO_SEATS_SELECTED: "No Seat Restrictions",
	SEATS_SELECTED: count => `${count} Seat Restriction${count > 1 ? "s" : ""}`
};
