import React, { useContext, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { FieldArray } from "formik";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import DemandSourceRow from "./DemandSourceRow";
import Yup from "../../config/yupConfig";
import commonLocaleContent from "../commonLocaleContent";
import localeContent from "./localeContent";
import { Operations, useResourceClient } from "../../hooks/useResource";
import { routersEndpoint } from "../../utils/endpointUtils";
import { AuthContext } from "../../context/AuthContext";
import { SnackbarContext } from "../../context/SnackbarContext";

const MAX_ROWS_OF_DEMAND_SOURCES = 5;

const filterPlacementsByRouterType = (options, typeRouter) =>
	options &&
	options.filter &&
	options.filter(({ type }) => type === typeRouter);

const filterPlacementsByStateInActive = (options, demandSources) => {
	const placementsFromDemandList =
		demandSources.map(demandSource => demandSource.placementId) || [];
	return (
		options &&
		options.filter &&
		options.filter(
			({ status, id }) =>
				status !== "active" && placementsFromDemandList.includes(id)
		)
	);
};

const formatMacro = function formatMacro(tagValue, charsFormatMacro) {
	let tmpTag = tagValue || "";
	tmpTag = tmpTag.replace(
		charsFormatMacro.openChartFrom,
		charsFormatMacro.openChartTo
	);
	tmpTag = tmpTag.replace(
		charsFormatMacro.closeChartFrom,
		charsFormatMacro.closeChartTo
	);
	return tmpTag;
};

const formatMacroDashboard = {
	openCharacterFrom: /{/g,
	openCharacterTo: "[",
	closeCharacterFrom: /}/g,
	closeCharacterTo: "]"
};

const formatMacroRouters = {
	openCharacterFrom: /\[/g,
	openCharacterTo: "{",
	closeCharacterFrom: /\]/g,
	closeCharacterTo: "}"
};

const transformDemandSources = (demandSources, formatOfMacro) => {
	const demandSourcesToReturn = [];

	demandSources.map(demandSource => {
		const data = {
			id: demandSource.id,
			type: demandSource.type,
			placementId: demandSource.placementId || "",
			tag: formatMacro(demandSource.tag, formatOfMacro),
			allocation: demandSource.allocation
		};
		demandSourcesToReturn.push(data);
		return data;
	});
	return demandSourcesToReturn;
};

const transformInitDataDemandSource = data => {
	if (isEmpty(data.demandSources)) {
		return [
			{
				clientId: uuidv4(),
				type: "PLACEMENT",
				allocation: undefined,
				tag: undefined,
				placementId: ""
			}
		];
	}

	const returnInfo = transformDemandSources(
		data.demandSources,
		formatMacroDashboard
	);
	return returnInfo;
};

const transformSubmitDataDemandSource = (data, fieldName) => {
	const returnInfo = transformDemandSources(data || [], formatMacroRouters);

	return {
		[fieldName]: returnInfo
	};
};

function FormFieldDemandSource(props) {
	const { formValues, name } = props;
	const authContext = useContext(AuthContext);
	const { companyId } = authContext;
	const placementsResourceEndPoint = `model/company/${companyId}/placements`;
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const [placementListFromApi] = useResourceClient(
		placementsResourceEndPoint,
		Operations.LIST,
		{},
		routersEndpoint
	);

	const placementOptionsList = useMemo(
		() => filterPlacementsByRouterType(placementListFromApi, formValues.type),
		[formValues.type, placementListFromApi]
	);
	const demandSources = formValues[name];

	const placementsInactiveList = useMemo(
		() => filterPlacementsByStateInActive(placementOptionsList, demandSources),
		[demandSources, placementOptionsList]
	);
	useEffect(() => {
		if ((placementsInactiveList || []).length > 0) {
			const placementNames = placementsInactiveList
				.map(placement => placement.name)
				.join(",");

			const message = `${localeContent.PLACEMENT_INACTIVE_WARNING} [${placementNames}]`;

			triggerNewSnackbarMessage({
				message,
				severity: "warning",
				forceDismiss: true
			});
		}
	}, [placementsInactiveList, triggerNewSnackbarMessage]);

	return (
		<FieldArray name={name}>
			{({ form, ...fieldArrayHelpers }) => {
				const removeDemandSource = indexToDelete => {
					fieldArrayHelpers.remove(indexToDelete);
				};

				const showAddDemandSourceButton =
					demandSources.length < MAX_ROWS_OF_DEMAND_SOURCES;

				const addNewDemandSource = () => {
					fieldArrayHelpers.push({
						clientId: uuidv4(),
						type: "PLACEMENT",
						allocation: undefined,
						tag: undefined,
						placementId: undefined
					});
				};

				return (
					<Grid
						container
						direction="column"
						justifyContent="center"
						spacing={MUI_GRID_CONTAINER_SPACING}
					>
						{demandSources.map((demandSource, index) => (
							<DemandSourceRow
								name={name}
								key={demandSource.id || demandSource.clientId}
								index={index}
								demandSource={demandSource}
								deleteEvent={removeDemandSource}
								renderDeleteButton={demandSources.length > 1}
								placementOptions={placementOptionsList}
							/>
						))}
						{showAddDemandSourceButton && (
							<Grid item xs={12}>
								<Button variant="contained" onClick={addNewDemandSource}>
									{localeContent.DEMAND_SOURCE_ADD_BUTTON_LABEL}
								</Button>
							</Grid>
						)}
					</Grid>
				);
			}}
		</FieldArray>
	);
}

Yup.addMethod(
	Yup.object,
	"allRowsMustSumTo100",
	function validateSumAllocation() {
		return this.test(
			"allRowsMustSumTo100",
			"",
			function testValidateSumAllocation() {
				const sum = this.parent.reduce((accumulator, object) => {
					return accumulator + object.allocation;
				}, 0);
				if (sum !== 100) {
					throw this.createError({
						path: `${this.path}.allocation`,
						message: localeContent.VALIDATION_ALLOCATION_WARNING
					});
				}

				return true;
			}
		);
	}
);

const validationSchema = Yup.array().of(
	Yup.object({
		id: Yup.string().nullable(),
		clientId: Yup.string().nullable(),
		type: Yup.string().required(commonLocaleContent.REQUIRED_FIELD_WARNING),
		allocation: Yup.number()
			.min(1)
			.max(100)
			.required(commonLocaleContent.REQUIRED_FIELD_WARNING),
		placementId: Yup.string().when("type", {
			is: type => type === "PLACEMENT",
			then: Yup.string().required(commonLocaleContent.REQUIRED_FIELD_WARNING)
		}),
		tag: Yup.string().when("type", {
			is: type => type === "THIRD_PARTY",
			then: Yup.string().required(commonLocaleContent.REQUIRED_FIELD_WARNING)
		})
	}).allRowsMustSumTo100()
);

FormFieldDemandSource.propTypes = {
	name: PropTypes.string.isRequired,
	formValues: PropTypes.shape()
};

FormFieldDemandSource.defaultProps = { formValues: {} };

export const FieldTypeDemandSource = {
	component: FormFieldDemandSource,
	transformInitData: transformInitDataDemandSource,
	transformSubmitData: transformSubmitDataDemandSource,
	baseValidation: validationSchema
};

export default FormFieldDemandSource;
