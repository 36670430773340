import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";

export const getFileUploadButtonTestId = fieldName =>
	`file-upload-button-${fieldName}`;

function FileUploadButton(props) {
	const {
		name,
		label,
		acceptedFileTypes,
		onFileUpload,
		largeVariant,
		fullWidth
	} = props;

	const handleChange = event => {
		const { target } = event;
		const file = target.files[0];
		onFileUpload(file);
		target.value = "";
	};

	const inputId = `upload-button-${name}`;
	return (
		<label htmlFor={inputId}>
			<Box display="none">
				<input
					accept={acceptedFileTypes}
					id={inputId}
					data-testid={getFileUploadButtonTestId(name)}
					type="file"
					onChange={handleChange}
				/>
			</Box>
			<Button
				variant="contained"
				component="span"
				sx={{
					width: fullWidth ? "100%" : undefined,
					color: "white",
					bgcolor: "dimgrey",
					"&:hover": {
						bgcolor: "black"
					},
					lineHeight: largeVariant ? 3 : undefined
				}}
				startIcon={<PublishIcon />}
			>
				{label}
			</Button>
		</label>
	);
}

FileUploadButton.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
	onFileUpload: PropTypes.func.isRequired,
	largeVariant: PropTypes.bool,
	fullWidth: PropTypes.bool
};

FileUploadButton.defaultProps = {
	acceptedFileTypes: ["*"],
	largeVariant: false,
	fullWidth: false
};

export default FileUploadButton;
