import lc from "./localeContent";

export default [
	{
		label: lc.ADVERTISER_GUID_LABEL,
		macro: "[adv_guid]",
		tooltip: lc.TOOLTIP.ADVERTISER_GUID_TOOLTIP
	},
	{
		label: lc.ADVERTISING_DEVICE_LABEL,
		macro: "[adv_id]",
		tooltip: lc.TOOLTIP.ADVERTISING_DEVICE_TOOLTIP
	},
	{
		label: lc.APP_ID_LABEL,
		macro: "[app_id]",
		tooltip: lc.TOOLTIP.APP_ID_TOOLTIP
	},
	{
		label: lc.APP_NAME_LABEL,
		macro: "[app_name]",
		tooltip: lc.TOOLTIP.APP_NAME_TOOLTIP
	},
	{
		label: lc.APP_URI_LABEL,
		macro: "[app_uri]",
		tooltip: lc.TOOLTIP.APP_URI_TOOLTIP
	},
	{
		label: lc.APP_VERSION_LABEL,
		macro: "[app_ver]",
		tooltip: lc.TOOLTIP.APP_VERSION_TOOLTIP
	},
	{
		label: lc.CACHEBUSTER_LABEL,
		macro: "[cb]",
		tooltip: lc.TOOLTIP.CACHEBUSTER_TOOLTIP
	},
	{
		label: lc.CAMPAIGN_GUID_LABEL,
		macro: "[camp_guid]",
		tooltip: lc.TOOLTIP.CAMPAIGN_GUID_TOOLTIP
	},
	{
		label: lc.CONNECTION_TYPE_LABEL,
		macro: "[geo_conn_type]",
		tooltip: lc.TOOLTIP.CONNECTION_TYPE_TOOLTIP
	},
	{
		label: lc.CONTENT_CATEGORY_LABEL,
		macro: "[content_cat]",
		tooltip: lc.TOOLTIP.CONTENT_CATEGORY_TOOLTIP
	},
	{
		label: lc.CONTENT_EPISODE_LABEL,
		macro: "[content_episode]",
		tooltip: lc.TOOLTIP.CONTENT_EPISODE_TOOLTIP
	},
	{
		label: lc.CONTENT_CHANNEL_LABEL,
		macro: "[channel]",
		tooltip: lc.TOOLTIP.CONTENT_CHANNEL_TOOLTIP
	},
	{
		label: lc.CONTENT_GENRE_LABEL,
		macro: "[content_genre]",
		tooltip: lc.TOOLTIP.CONTENT_GENRE_TOOLTIP
	},
	{
		label: lc.CONTENT_LANGUAGE_LABEL,
		macro: "[content_language]",
		tooltip: lc.TOOLTIP.CONTENT_LANGUAGE_TOOLTIP
	},
	{
		label: lc.CONTENT_LENGTH_LABEL,
		macro: "[content_len]",
		tooltip: lc.TOOLTIP.CONTENT_LENGTH_TOOLTIP
	},
	{
		label: lc.CONTENT_LIVESTREAM_LABEL,
		macro: "[content_livestream]",
		tooltip: lc.TOOLTIP.CONTENT_LIVESTREAM_TOOLTIP
	},
	{
		label: lc.CONTENT_MEDIA_RATING_LABEL,
		macro: "[content_qagmediarating]",
		tooltip: lc.TOOLTIP.CONTENT_MEDIA_RATING_TOOLTIP
	},
	{
		label: lc.CONTENT_PRODUCTION_QUALITY_LABEL,
		macro: "[content_prodq]",
		tooltip: lc.TOOLTIP.CONTENT_PRODUCTION_QUALITY_TOOLTIP
	},
	{
		label: lc.CONTENT_SERIES_LABEL,
		macro: "[content_series]",
		tooltip: lc.TOOLTIP.CONTENT_SERIES_TOOLTIP
	},
	{
		label: lc.CONTENT_TITLE_LABEL,
		macro: "[content_title]",
		tooltip: lc.TOOLTIP.CONTENT_TITLE_TOOLTIP
	},
	{
		label: lc.COUNTRY_LABEL,
		macro: "[geo_co]",
		tooltip: lc.TOOLTIP.COUNTRY_TOOLTIP
	},
	{
		label: lc.DEVICE_ID_LABEL,
		macro: "[device_id]",
		tooltip: lc.TOOLTIP.DEVICE_ID_TOOLTIP
	},
	{
		label: lc.DEVICE_MAKE_LABEL,
		macro: "[devicemake]",
		tooltip: lc.TOOLTIP.DEVICE_MAKE_TOOLTIP
	},
	{
		label: lc.DEVICE_MODEL_LABEL,
		macro: "[devicemodel]",
		tooltip: lc.TOOLTIP.DEVICE_MODEL_TOOLTIP
	},
	{
		label: lc.DEVICE_TYPE_LABEL,
		macro: "[devicecategory]",
		tooltip: lc.TOOLTIP.DEVICE_TYPE_TOOLTIP
	},
	{
		label: lc.DMA_LABEL,
		macro: "[geo_dma]",
		tooltip: lc.TOOLTIP.DMA_TOOLTIP
	},
	{
		label: lc.DNT_LABEL,
		macro: "[dnt]",
		tooltip: lc.TOOLTIP.DNT_TOOLTIP
	},
	{
		label: lc.IDFA_DEVICE_LABEL,
		macro: "[idfa]",
		tooltip: lc.TOOLTIP.IDFA_DEVICE_TOOLTIP
	},
	{
		label: lc.INTERNET_SERVICE_PROVIDER_LABEL,
		macro: "[geo_isp_name]",
		tooltip: lc.TOOLTIP.INTERNET_SERVICE_PROVIDER_TOOLTIP
	},
	{
		label: lc.IO_GUID_LABEL,
		macro: "[io_guid]",
		tooltip: lc.TOOLTIP.IO_GUID_TOOLTIP
	},
	{
		label: lc.IP_ADDRESS_LABEL,
		macro: "[geo_ip]",
		tooltip: lc.TOOLTIP.IP_ADDRESS_TOOLTIP
	},
	{
		label: lc.LAT_LONG_LABEL,
		macro: "[geo_latlng]",
		tooltip: lc.TOOLTIP.LAT_LONG_TOOLTIP
	},
	{
		label: lc.LATITUDE_LABEL,
		macro: "[geo_lat]",
		tooltip: lc.TOOLTIP.LATITUDE_TOOLTIP
	},
	{
		label: lc.GPP_LABEL,
		macro: "[gpp]",
		tooltip: lc.TOOLTIP.GPP_TOOLTIP
	},
	{
		label: lc.GPP_SID_LABEL,
		macro: "[gpp_sid]",
		tooltip: lc.TOOLTIP.GPP_SID_TOOLTIP
	},
	{
		label: lc.LIMIT_AD_TRACKING.LABEL,
		macro: "[lmt]",
		tooltip: lc.LIMIT_AD_TRACKING.TOOLTIP
	},
	{
		label: lc.LONGITUDE_LABEL,
		macro: "[geo_long]",
		tooltip: lc.TOOLTIP.LONGITUDE_TOOLTIP
	},
	{
		label: lc.MAX_DURATION_LABEL,
		macro: "[max_ad_duration]",
		tooltip: lc.TOOLTIP.MAX_DURATION_TOOLTIP
	},
	{
		label: lc.MAXIMUM_AD_DURATION_LABEL,
		macro: "[pod_max_ad_dur]",
		tooltip: lc.TOOLTIP.MAXIMUM_AD_DURATION_TOOLTIP
	},
	{
		label: lc.MEDIA_GUID_LABEL,
		macro: "[media_guid]",
		tooltip: lc.TOOLTIP.MEDIA_GUID_TOOLTIP
	},
	{
		label: lc.MINIMUM_AD_DURATION_LABEL,
		macro: "[pod_min_ad_dur]",
		tooltip: lc.TOOLTIP.MINIMUM_AD_DURATION_TOOLTIP
	},
	{
		label: lc.CONTENT_NETWORK_LABEL,
		macro: "[network]",
		tooltip: lc.TOOLTIP.CONTENT_NETWORK_TOOLTIP
	},
	{
		label: lc.OPERATING_SYSTEM_LABEL,
		macro: "[osplatform]",
		tooltip: lc.TOOLTIP.OPERATING_SYSTEM_TOOLTIP
	},
	{
		label: lc.OS_MAJOR_VERSION_LABEL,
		macro: "[osvmajor]",
		tooltip: lc.TOOLTIP.OS_MAJOR_VERSION_TOOLTIP
	},
	{
		label: lc.OS_MINOR_VERSION_LABEL,
		macro: "[osvminor]",
		tooltip: lc.TOOLTIP.OS_MINOR_VERSION_TOOLTIP
	},
	{
		label: lc.OS_VERSION_LABEL,
		macro: "[osv]",
		tooltip: lc.TOOLTIP.OS_VERSION_TOOLTIP
	},
	{
		label: "Page Domain",
		macro: "[page_domain]",
		tooltip: lc.TOOLTIP.PAGE_DOMAIN_TOOLTIP
	},
	{
		label: lc.PAGE_URL_LABEL,
		macro: "[page_url]",
		tooltip: lc.TOOLTIP.PAGE_URL_TOOLTIP
	},
	{
		label: lc.PLCMT_LABEL,
		macro: "[plcmt]",
		tooltip: lc.TOOLTIP.PLCMT_TOOLTIP
	},
	{
		label: lc.PLACEMENT_GUID_LABEL,
		macro: "[pla_guid]",
		tooltip: lc.TOOLTIP.PLACEMENT_GUID_TOOLTIP
	},
	{
		label: lc.PLAYER_HEIGHT_LABEL,
		macro: "[player_height]",
		tooltip: lc.TOOLTIP.PLAYER_HEIGHT_TOOLTIP
	},
	{
		label: lc.PLAYER_WIDTH_LABEL,
		macro: "[player_width]",
		tooltip: lc.TOOLTIP.PLAYER_WIDTH_TOOLTIP
	},
	{
		label: lc.POD_MAX_DURATION_LABEL,
		macro: "[pod_max_dur]",
		tooltip: lc.TOOLTIP.POD_MAX_DURATION_TOOLTIP
	},
	{
		label: lc.POD_SIZE_LABEL,
		macro: "[pod_size]",
		tooltip: lc.TOOLTIP.POD_SIZE_TOOLTIP
	},
	{
		label: lc.POSTAL_CODE_LABEL,
		macro: "[geo_code]",
		tooltip: lc.TOOLTIP.POSTAL_CODE_TOOLTIP
	},
	{
		label: lc.PUBLISHER_GUID_LABEL,
		macro: "[pub_guid]",
		tooltip: lc.TOOLTIP.PUBLISHER_GUID_TOOLTIP
	},
	{
		label: lc.RANDOM_NUMBER_LABEL,
		macro: "[random_number]",
		tooltip: lc.TOOLTIP.RANDOM_NUMBER_TOOLTIP
	},
	{
		label: lc.REFERRAL_DOMAIN_LABEL,
		macro: "[ref_page_domain]",
		tooltip: lc.TOOLTIP.REFERRAL_DOMAIN_TOOLTIP
	},
	{
		label: lc.REFERRAL_URL_LABEL,
		macro: "[ref_page_url]",
		tooltip: lc.TOOLTIP.REFERRAL_URL_TOOLTIP
	},
	{
		label: lc.SITE_GUID_LABEL,
		macro: "[site_guid]",
		tooltip: lc.TOOLTIP.SITE_GUID_TOOLTIP
	},
	{
		label: lc.SSAI_LABEL,
		macro: "[ssai]",
		tooltip: lc.TOOLTIP.SSAI_TOOLTIP
	},
	{
		label: lc.STATE_CODE_LABEL,
		macro: "[geo_sub]",
		tooltip: lc.TOOLTIP.STATE_CODE_TOOLTIP
	},
	{
		label: lc.STATE_NAME_LABEL,
		macro: "[geo_subname]",
		tooltip: lc.TOOLTIP.STATE_NAME_TOOLTIP
	},
	{
		label: lc.TIMESTAMP_LABEL,
		macro: "[timestamp]",
		tooltip: lc.TOOLTIP.TIMESTAMP_TOOLTIP
	},
	{
		label: lc.TID_LABEL,
		macro: "[tid]",
		tooltip: lc.TOOLTIP.TID_TOOLTIP
	},
	{
		label: lc.US_PRIVACY_LABEL,
		macro: "[us_privacy]",
		tooltip: lc.TOOLTIP.US_PRIVACY_TOOLTIP
	},
	{
		label: lc.USER_AGENT_LABEL,
		macro: "[ua]",
		tooltip: lc.TOOLTIP.USER_AGENT_TOOLTIP
	},
	{
		label: lc.USER_ID_LABEL,
		macro: "[cid]",
		tooltip: lc.TOOLTIP.USER_ID_TOOLTIP
	},
	{
		label: lc.USER_ID_TYPE_LABEL,
		macro: "[user_id_type]",
		tooltip: lc.TOOLTIP.USER_ID_TYPE_TOOLTIP
	}
];
