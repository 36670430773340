import ExpressionBuilderLc from "../../components/FormFieldExpressionBuilder/localeContent";

export default {
	COLUMN_HEADER_DEAL_ID: "Deal ID",
	TAB_LABEL_SETTINGS: "Settings",
	TAB_LABEL_QUALITY: "Quality",
	TAB_LABEL_ALIGNMENTS: "Alignments",
	ENABLE_DEAL_KEY: "enableDeal",
	BUTTON_LABEL_SAVE: "Save",
	FIELD_PROGRAMMATIC_DEMAND_NAME: {
		LABEL: "Demand Name"
	},
	FIELD_STATUS: {
		LABEL: "Status",
		OPTION_LABELS: {
			ACTIVE: "Active",
			INACTIVE: "Inactive"
		}
	},
	FIELD_ENABLE_DEAL: {
		LABEL: "Deal",
		TOOLTIP: "Enable in order to include a Deal ID in the bid request"
	},
	FIELD_PRIVATE_AUCTION: {
		LABEL: "Private Auction",
		TOOLTIP:
			"Indicator of auction eligibility to seats named in the Direct Deals object, where No = all bids are accepted, Yes = bids are restricted to the deals specified. If both a public deal and private deal are to be sent in a single bid request, then the private_auction flag will be set to private"
	},
	FIELD_AUCTION_TYPE: {
		LABEL: "Auction Type",
		TOOLTIP:
			"Optional override of the overall auction type of the bid request set by the placement",
		OPTION_LABELS: {
			FIRST_PRICE: "First Price",
			SECOND_PRICE: "Second Price",
			AGREED_UPON_DEAL_PRICE: "Fixed Price"
		}
	},
	FIELD_DEAL_ID: {
		LABEL: "Deal ID",
		TOOLTIP: "Enter Deal ID which should be passed in the bid request",
		NON_ALPHANUMERIC_PERIOD_AND_UNDERCORE_VALUE_ERROR:
			"May only contain alphanumeric characters, underscores, periods, and dashes."
	},
	FIELD_SEAT_TYPE: {
		LABEL: "Seat Type",
		TOOLTIP:
			"If bseat is selected all selected seats will be prevented from participating in the auction.",
		OPTION_LABELS: {
			WSEAT: "Wseat",
			BSEAT: "Bseat"
		}
	},
	FIELD_DEAL_GOAL_TYPE: {
		LABEL: "Deal Goal Type",
		TOOLTIP:
			"Select Impression or Spend and enter the corresponding goal to pace the campaign across the given flight dates. If Open is selected a goal is not required and the campaign will run without pacing.",
		OPTION_LABELS: {
			IMPRESSION: "Impression",
			SPEND: "Spend",
			OPEN: "Open"
		}
	},
	FIELD_DEAL_GOAL_TARGET: {
		LABEL: "Deal Goal",
		TOOLTIP: "Input goal amount",
		PLACEHOLDER: "# of impressions",
		REQUIRED_ERROR: "Value is required"
	},
	FIELD_START_DATE: {
		LABEL: "Start Date",
		TOOLTIP: "Date and Time at which this demand will go live"
	},
	FIELD_END_DATE: {
		LABEL: "End Date",
		TOOLTIP:
			"Date and Time at which this demand will end. If an End Date is not supplied then the campaign will run indefinitely",
		REQUIRED_ERROR: "End Date is required to pace against the set deal goal"
	},
	FIELD_FLOOR: {
		LABEL: "Floor Price",
		TOOLTIP: "Floor price which will be sent in the bid request",
		REQUIRED_ERROR: ({ enableDealLabel }) =>
			`Required when ${enableDealLabel} is enabled`,
		MAX_VALUE_ERROR: "Must be less than or equal to 100."
	},
	FIELD_PRIORITY: {
		LABEL: "Priority",
		TOOLTIP:
			"Priority is used to specify the order in which Demand should be served. A Priority of 1 will serve before all other priority levels"
	},
	FIELD_WEIGHT: {
		LABEL: "Weight",
		TOOLTIP:
			"Weighting is used to differentiate between Priorities of the same level. The first option to serve will be Priority 1 with a Weight of 10. The last option to serve will be a Priority of 10 with a Weight of 1"
	},
	FIELD_TRACKERS: {
		LABEL: "Trackers"
	},
	FIELD_REQUIRE_IVT_FILTERING: {
		LABEL: "Require IVT Filtering",
		TOOLTIP:
			"If enabled, this demand will only run on aligned Placements which filter out invalid traffic."
	},
	FIELD_AUDIENCE: {
		LEAF_COLUMN_HEADERS: {
			NAME: "Audience Name",
			ESTIMATED_CPM: "Estimated CPM",
			POTENTIAL_COOKIE_REACH: "Potential Cookie Reach",
			POTENTIAL_DEVICE_REACH: "Potential Device Reach"
		},
		ENABLE_FIELD_LABEL: "Audience Targeting",
		LEAF_NAME_LABEL: "audience",
		EMPTY_EXPRESSION_WARNING: ExpressionBuilderLc.DEFAULT_EMPTY_EXPRESSION_WARNING(
			"an audience"
		)
	},
	TARGETING_TYPE_DOMAIN_LABEL: "Domain",
	TARGETING_TYPE_APP_NAME_LABEL: "App Name",
	TARGETING_TYPE_APP_BUNDLE_IDS_LABEL: "App Bundle ID",
	ENABLE_FREQUENCY_CAPPING_TOOLTIP:
		"If enabled, enter a number into the Impressions Per User field to limit the number of times a user can view this deal over the specified timeframe. If disabled there are no restrictions on the number of views per user",
	IMPRESSION_PER_USER_TOOLTIP:
		"The number of times a user can see ads from this Deal over the given timeframe",
	TIMEFRAME_TOOLTIP: "The timeframe over which the impression cap applies",
	CLONE_ALIGNMENTS: {
		LABEL: "Clone Alignments",
		TOOLTIP:
			"If enabled, all associated alignments will be copied to the cloned resource"
	}
};
