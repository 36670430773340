import React, { useEffect } from "react";
import { Form, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Box, Paper, Grid, InputAdornment, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Select from "../../components/FormFieldSelect/Select";
import FormTextField from "../../components/FormTextField/FormTextField";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import lc from "./localeContent";
import FileUploadButton from "../../components/FileUploadButton/FileUploadButton";

export const KEY_VALUE = "value";

function GlobalBlacklistForm(props) {
	const {
		typeOptions,
		typeValue,
		typeLabel,
		onChangeType,
		onFileUpload
	} = props;
	const { validateForm } = useFormikContext();

	useEffect(() => {
		validateForm();
	}, [typeValue, validateForm]);

	return (
		<Form noValidate>
			<Box component={Paper} my={2} p={2}>
				<Grid
					container
					spacing={MUI_GRID_CONTAINER_SPACING}
					justifyContent="flex-start"
					alignItems="flex-start"
				>
					<Grid item xs={4}>
						<Select
							id="type"
							label={lc.TYPE_LABEL}
							name="type"
							value={typeValue}
							onChange={event => {
								onChangeType(event.target.value);
							}}
							options={typeOptions}
						/>
					</Grid>
					<Grid item xs={5}>
						<FormTextField
							id={KEY_VALUE}
							name={KEY_VALUE}
							label={typeLabel}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label={lc.ADD_GLOBAL_BLACKLIST_BUTTON_LABEL}
											type="submit"
										>
											<AddIcon />
										</IconButton>
									</InputAdornment>
								)
							}}
							required
						/>
					</Grid>
					<Grid item sm={3}>
						<FileUploadButton
							name="bulk-upload-file"
							label={lc.FILE_BULK_UPLOAD_BUTTON_LABEL}
							onFileUpload={onFileUpload}
							largeVariant
							fullWidth
						/>
					</Grid>
				</Grid>
			</Box>
		</Form>
	);
}

GlobalBlacklistForm.propTypes = {
	typeOptions: PropTypes.arrayOf(PropTypes.shape).isRequired,
	typeValue: PropTypes.string.isRequired,
	typeLabel: PropTypes.string.isRequired,
	onChangeType: PropTypes.func.isRequired,
	onFileUpload: PropTypes.func.isRequired
};

GlobalBlacklistForm.defaultProps = {};

export default GlobalBlacklistForm;
