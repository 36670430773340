import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { TextField } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers-pro";
import { format } from "date-fns";
import Tooltip from "../Tooltip/Tooltip";

export const SERVER_DATE_FORMAT = "MM/dd/yyyy hh:mm a";

export const transformInitData = (data, fieldName, fieldConfig) => {
	let fieldValue;
	if (Object.prototype.hasOwnProperty.call(fieldConfig, "defaultValue")) {
		fieldValue = fieldConfig.defaultValue;
	}
	const fieldData = data[fieldName];
	if (fieldData) {
		fieldValue = fieldData;
	}

	return fieldValue ? new Date(fieldValue) : null;
};
export const transformSubmitData = (data, fieldName) => {
	return {
		[fieldName]: data ? format(data, SERVER_DATE_FORMAT) : null
	};
};

function FormFieldDateTimePicker(props) {
	const {
		id,
		label,
		name,
		required,
		disabled,
		tooltip,
		fieldTypeOptions
	} = props;
	const [field, meta, helpers] = useField(name);
	const {
		minDateTime,
		maxDateTime,
		clearable,
		showTodayButton,
		disablePast
	} = fieldTypeOptions;
	// Error handling
	const showError = Boolean(meta.touched && meta.error);
	const helperText = showError ? meta.error : "";
	const handleChange = useCallback(
		newDate => {
			helpers.setValue(newDate);
		},
		[helpers]
	);

	const actions = ["accept", "cancel"];
	if (clearable) {
		actions.push("clear");
	}
	if (showTodayButton) {
		actions.push("today");
	}

	return (
		<MobileDateTimePicker
			value={field.value}
			onChange={handleChange}
			minDateTime={minDateTime}
			maxDateTime={maxDateTime}
			componentsProps={{ actionBar: { actions } }}
			disabled={disabled}
			disablePast={disablePast}
			renderInput={muiProps => {
				const textField = (
					<TextField
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...muiProps}
						onBlur={field.onBlur}
						id={id}
						name={name}
						error={showError}
						label={label}
						helperText={helperText}
						disabled={disabled}
						required={required}
						placeholder={undefined}
						variant="filled"
						fullWidth
					/>
				);
				return tooltip ? (
					<Tooltip title={tooltip || ""}>{textField}</Tooltip>
				) : (
					textField
				);
			}}
		/>
	);
}

FormFieldDateTimePicker.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
	initData: PropTypes.shape(),
	fieldTypeOptions: PropTypes.shape()
};

FormFieldDateTimePicker.defaultProps = {
	required: false,
	disabled: false,
	tooltip: null,
	initData: {},
	fieldTypeOptions: {}
};

export default FormFieldDateTimePicker;
