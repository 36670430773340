import React, { useCallback, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import _isEmpty from "lodash/isEmpty";
import parseHTML from "html-react-parser";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import { Operations } from "../../hooks/useResource";
import { updateCompanyIdQueryStringParameter } from "../../utils/objectUtils";
import { AuthContext } from "../../context/AuthContext";
import { SnackbarContext } from "../../context/SnackbarContext";
import commonLocaleContent from "../../screens/commonLocaleContent";
import { makeDashboardUrl } from "../../utils/endpointUtils";
import lc from "./localeContent";
import { TIMEZONE_CONFIGS } from "../../utils/timeZoneUtils";

const REPORT_NAME_MAX_LENGTH = 300;
export const removeSpaces = value => value.replace(/ /g, "");

function ExportButton({ exportReportParams }) {
	// to get the props from the DataGrid via GridToolbarContainer pass the props as parameter to ExportButton
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);
	const authContext = useContext(AuthContext);
	const [showDialog, setShowDialog] = useState(false);
	const [reportName, setReportName] = useState(null);
	const [reportNameError, setReportNameError] = useState(null);

	const { isLoading, execute: executeRunReport } = useResourceAsync(
		"reports/run-reports",
		Operations.CREATE,
		{
			preserveDataOnExecute: true
		}
	);
	useEffect(() => {
		if (!reportName) {
			setReportNameError(commonLocaleContent.REQUIRED_FIELD_WARNING);
		} else if (reportName.length > REPORT_NAME_MAX_LENGTH) {
			setReportNameError(lc.REPORT_NAME.CHAR_LIMIT_ERROR);
		} else {
			setReportNameError(null);
		}
	}, [setReportNameError, reportName]);
	const runReport = useCallback(async () => {
		const {
			reportType,
			slice,
			startDate,
			endDate,
			timeZone
		} = exportReportParams;
		const sliceItem = slice.length ? `${slice[0]}_` : "";
		const makeReportName = `${reportType}_${sliceItem}${startDate}_${endDate}_${TIMEZONE_CONFIGS[timeZone].shortName}`;
		setReportName(makeReportName);
		setShowDialog(true);
	}, [exportReportParams, setReportName, setShowDialog]);

	const handleClose = () => setShowDialog(false);

	const onReportSave = async () => {
		const companyId = authContext?.companyId;
		const myReportUrl = updateCompanyIdQueryStringParameter(
			"my-reports",
			companyId
		);
		setShowDialog(false);
		exportReportParams.name = reportName;
		const { data, error } = await executeRunReport({
			data: exportReportParams
		});
		if (error) {
			triggerNewSnackbarMessage({
				message: error.message,
				severity: "error"
			});
		}
		if (data) {
			const { successDto } = data;
			const savedReportName = successDto.name;
			const myReportPath = makeDashboardUrl(myReportUrl);
			triggerNewSnackbarMessage({
				message: parseHTML(
					lc.RUN_REPORT_SUCCESS_MESSAGE(savedReportName, myReportPath)
				),
				severity: "success"
			});
		}
	};

	return (
		<>
			<Dialog open={showDialog} onClose={handleClose} fullWidth>
				<DialogTitle>
					{lc.REPORT_NAME.MODAL_TITLE_CUSTOMIZE_REPORT_NAME}
				</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label={lc.REPORT_NAME.MODAL_REPORT_NAME}
						type="text"
						fullWidth
						variant="standard"
						value={reportName}
						onChange={e => setReportName(removeSpaces(e.target.value))}
						error={!_isEmpty(reportNameError)}
						helperText={reportNameError}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>
						{lc.REPORT_NAME.MODAL_BUTTON_CANCEL}
					</Button>
					<Button onClick={onReportSave} disabled={!_isEmpty(reportNameError)}>
						{lc.REPORT_NAME.MODAL_BUTTON_SAVE}
					</Button>
				</DialogActions>
			</Dialog>
			<Button
				color="primary"
				size="small"
				startIcon={<FileDownloadIcon />}
				onClick={runReport}
			>
				{lc.EXPORT_BUTTON_LABEL}
			</Button>
			{isLoading && <CircularProgress size="1rem" />}
		</>
	);
}
ExportButton.propTypes = {
	exportReportParams: PropTypes.shape().isRequired
};

export default ExportButton;
