export default {
	NEW_PASSWORD_LABEL: "New Password",
	CONFIRM_PASSWORD_LABEL: "Confirm Password",
	BACK_TO_LOGIN_LABEL: "Back to login screen",
	SUBMIT_BUTTON_LABEL: "Submit",
	RESET_PASSWORD_FORM_TITLE: "Reset Password",
	PASSWORD_MESSAGE: {
		DO_NOT_MATCH: "Passwords do not match!",
		EXISTING_SAME:
			"The password entered matches a previously used password. Please select a new password",
		MIN_LENGTH: "Must be minimum of 10 characters",
		INVALID_TOKEN:
			"The authentication link provided is either invalid or has expired. Please enter your email address again to receive a new reset password email",
		SUCCESS: "Password Saved!",
		INVALID_REQUEST: "Invalid Request!",
		REQUIRED: "Password is required",
		NUMBER_MISSING: "Must contain atleast 1 numeric character",
		UPPERCASE_MISSING: "Must contain atleast 1 uppercase character",
		SPECIAL_CHAR:
			"Must contain at least 1 of these special characters (@#$_^&+=!)"
	},
	PASSWORD_REQUIREMENTS: [
		"Minimum of 10 characters",
		"At least 1 uppercase character",
		"At least 1 numeric value",
		"At least 1 of these special characters (@#$_^&+=!)"
	],
	PASSWORD_MUST_CONTAIN_LABEL: "Must contain:"
};
