import React from "react";

export default {
	TAB_LABEL_SETTINGS: "Settings",
	TAB_LABEL_VERIFICATION: "Verification",
	TAB_LABEL_SITES_SECTION: "Sites",
	PUBLISHER_NAME_LABEL: "Publisher Name",
	STATUS_LABEL: "Status",
	DEFAULT_FLOOR_LABEL: "Default Floor",
	REVENUE_SHARE_LABEL: "Revenue Share",
	CONTACT_NAME_LABEL: "Contact Name",
	CONTACT_EMAIL_LABEL: "Contact Email",
	CONTACT_PHONE_LABEL: "Phone Number",
	CONTACT_ADDRESS_LABEL: "Company Address",
	IS_BILLING_INFO_APPLICABLE_LABEL: "Billing Different From Contact Info",
	BILLING_CONTACT_NAME_LABEL: "Billing Name",
	BILLING_CONTACT_EMAIL_LABEL: "Billing Email",
	BILLING_CONTACT_PHONE_LABEL: "Billing Phone Number",
	BILLING_CONTACT_ADDRESS_LABEL: "Billing Address",
	AD_SYSTEM_DOMAIN_LABEL: "Ad System Domain",
	ADS_TXT_ACCOUNT_ID_LABEL: "Account ID",
	ACCOUNT_RELATIONSHIP_LABEL: "Account Relationship",
	CERT_AUTHORITY_ID_LABEL: "Certification Authority ID",
	STATUS_OPTION_LABEL_ACTIVE: "Active",
	STATUS_OPTION_LABEL_INACTIVE: "Inactive",
	ACCOUNT_RELATIONSHIP_OPTION_LABEL_DIRECT: "Direct",
	ACCOUNT_RELATIONSHIP_OPTION_LABEL_RESELLER: "Reseller",
	DEFAULT_REVENUE_SHARE_TOOLTIP:
		"Percentage of gross revenue earned by the Publisher.",
	DEFAULT_FLOOR_TOOLTIP:
		"Used to set the minimum Media CPM which can be aligned to Placements within this Publisher. If a Site or Placement, belonging to this Publisher, has a different floor, then the Publisher’s floor will be overridden to match that of the Site and Placement.",
	AD_SYSTEM_DOMAIN_TOOLTIP:
		"The canonical domain name of the SSP, Exchange, Header Wrapper, etc system that bidders connect to. If two domains are listed, each should be set on their own line within the ads.txt file with the IDs and relationship being the same on both lines.",
	ADS_TXT_ACCOUNT_ID_TOOLTIP:
		"The identifier associated with the seller or reseller account within the advertising system.",
	ACCOUNT_RELATIONSHIP_TOOLTIP: (
		<div data-testid="account-relationship-tooltip">
			<b>Reseller:</b> The Publisher has authorized another entity to control
			the account.
			<br />
			<b>Direct:</b> The Publisher (content owner) directly controls the
			account.
		</div>
	),
	WEB_SITE_DOMAIN_LABEL: "Publisher Domain",
	WEB_SITE_DOMAIN_TOOLTIP: "Enter the Publisher’s corporate website",
	SELLER_TYPE_LABEL: "Seller Type",
	SELLER_TYPE_TOOLTIP: (
		<div data-testid="seller-type-tooltip">
			<b>Publisher:</b> Indicates that the inventory sold through this account
			is on a site, app, or other medium owned by the named entity.
			<br />
			<b>Intermediary:</b> Indicates that the inventory sold through this
			account is not owned by the named entity.
			<br />
			<b>Both:</b> Indicates that both types of inventory are transacted by this
			seller.
		</div>
	),
	IS_CONFIDENTIAL_LABEL: "Is Confidential",
	IS_CONFIDENTIAL_TOOLTIP:
		"If Yes, is_confidential will be set to 1 in sellers.json and limited information will be included",
	DOWNLOAD_ADS_FILE_LABEL: "DOWNLOAD ADS.TXT",
	ACTION_BUTTON_LABEL_DOWNLOAD_ADS_TXT: "Download ads.txt",
	TMAX_TITLE_LABEL: "Tmax (milliseconds)",
	TMAX_TOOLTIP_LABEL:
		"Maximum time in milliseconds that a publisher allows bidders to submit a response during the auction.",
	TMAX_RANGE_VALIDATION_MSG: "Must be between 1-60,000"
};
