import React from "react";
import CrudForm from "../../components/CrudForm/CrudForm";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import {
	ACCESS_RESTRICTION_TYPE_ENUM,
	DEFAULT_ACCESS_RESTRICTION_TYPE_OPTIONS
} from "../../components/FormFieldAccessRestriction/FormFieldAccessRestriction";
import { UTC_TIME_ZONE } from "../../config/constants";

const ACCESS_RESTRICTION_TYPE_OPTIONS = [
	{
		value: ACCESS_RESTRICTION_TYPE_ENUM.ALL,
		label: localeContent.ACCESS_RESTRICTION_TYPE_ALL_LABEL
	},
	{
		value: ACCESS_RESTRICTION_TYPE_ENUM.NONE,
		label: localeContent.ACCESS_RESTRICTION_TYPE_NONE_LABEL
	},
	...DEFAULT_ACCESS_RESTRICTION_TYPE_OPTIONS
];

const FIELDS_CONFIG = [
	{
		key: "BASIC_SETTINGS",
		fields: [
			{
				name: "firstName",
				label: localeContent.FIRST_NAME_LABEL,
				gridConfig: { md: 6 }
			},
			{
				name: "lastName",
				label: localeContent.LAST_NAME_LABEL,
				gridConfig: { md: 6 }
			},
			{
				name: "phoneNumber",
				label: localeContent.PHONE_NUMBER_LABEL,
				gridConfig: { md: 6 }
			},
			{
				name: "email",
				label: localeContent.EMAIL_ADDR_LABEL,
				gridConfig: { md: 6 },
				isRequired: true
			},
			{
				name: "timeZone",
				label: localeContent.TIMEZONE_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.COMBO_BOX,
				optionsConfig: {
					requestEndpoint: "manage/catalogue/timezones",
					sortOptionsByName: false
				},
				defaultValue: UTC_TIME_ZONE
			},
			{
				name: "status",
				label: localeContent.STATUS_LABEL,
				gridConfig: { md: 4 },
				fieldType: FieldTypes.RADIO,
				optionsConfig: {
					options: [
						{
							value: "active",
							label: commonLocaleContent.STATUS_OPTION_LABEL_ACTIVE
						},
						{
							value: "inactive",
							label: commonLocaleContent.STATUS_OPTION_LABEL_INACTIVE
						}
					]
				},
				defaultValue: "active"
			},
			{
				name: "roles",
				label: localeContent.ROLES_LABEL,
				tooltip: localeContent.ROLES_TOOLTIP,
				gridConfig: { md: 8, lg: 6 },
				fieldType: FieldTypes.MULTISELECT,
				optionsConfig: {
					requestEndpoint: "manage/user-roles"
				},
				isRequired: true
			},
			{
				name: "isDemandClient",
				label: localeContent.FIELD_IS_DEMAND_CLIENT.LABEL,
				tooltip: localeContent.FIELD_IS_DEMAND_CLIENT.TOOLTIP,
				gridConfig: { md: 4, lg: 6 },
				fieldType: FieldTypes.SWITCH,
				defaultValue: false
			}
		]
	},
	{
		key: "ACCESS_RESTRICTION_SETTINGS",
		fields: [
			{
				name: "publisherAccessRestrictionConfig",
				fieldType: FieldTypes.ACCESS_RESTRICTION,
				optionsConfig: {
					requestEndpoint: "manage/publishers",
					requestParams: {
						"statusList[]": "active"
					}
				},
				fieldTypeOptions: {
					accessRestrictionTypeLabel:
						localeContent.FIELD_PUBLISHER_ACCESS_RESTRICTION.TYPE_LABEL,
					blacklistLabel:
						localeContent.FIELD_PUBLISHER_ACCESS_RESTRICTION.BLACKLIST_LABEL,
					whitelistLabel:
						localeContent.FIELD_PUBLISHER_ACCESS_RESTRICTION.WHITELIST_LABEL,
					accessRestrictionTypeOptions: ACCESS_RESTRICTION_TYPE_OPTIONS
				},
				defaultValue: {
					accessRestriction: "all",
					blacklist: [],
					whitelist: []
				}
			},
			{
				name: "advertiserAccessRestrictionConfig",
				fieldType: FieldTypes.ACCESS_RESTRICTION,
				optionsConfig: {
					requestEndpoint: "manage/advtsrs",
					requestParams: {
						"statusList[]": "active"
					}
				},
				fieldTypeOptions: {
					accessRestrictionTypeLabel:
						localeContent.FIELD_ADVERTISER_ACCESS_RESTRICTION.TYPE_LABEL,
					blacklistLabel:
						localeContent.FIELD_ADVERTISER_ACCESS_RESTRICTION.BLACKLIST_LABEL,
					whitelistLabel:
						localeContent.FIELD_ADVERTISER_ACCESS_RESTRICTION.WHITELIST_LABEL,
					accessRestrictionTypeOptions: ACCESS_RESTRICTION_TYPE_OPTIONS
				},
				defaultValue: {
					accessRestriction: "all",
					blacklist: [],
					whitelist: []
				}
			}
		]
	}
];

function UsersResource() {
	return (
		<CrudForm
			resourceEndpoint="manage/users"
			resourceRoute="users"
			resourceString={commonLocaleContent.USER}
			fieldsConfig={FIELDS_CONFIG}
			preSubmit={(formValues, resourceId) => ({
				...formValues,
				id: resourceId,
				resetPassword: []
			})}
			prepopulateCreate
		/>
	);
}

export default UsersResource;
