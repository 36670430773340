import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import _isEmpty from "lodash/isEmpty";
import _snakeCase from "lodash/snakeCase";
import { AuthorizationContext } from "../../../context/AuthorizationContext";
import lc from "../localeContent";
import { PRIMARY_COLORS } from "../../../config/constants";
import { BrandingContext } from "../../../context/BrandingContext";
import { METRIC_TYPES } from "../metricsConfig";

const roundValue = value => {
	return parseFloat(value).toFixed(2);
};

const transformValues = (kpiInfo, metricConfig, companyConfig) => {
	const fieldName = metricConfig.field;
	// If no key returned from API then setting its default value to 0
	let data = kpiInfo[fieldName] || 0;

	// Check if metric value will be calculated
	if (metricConfig.getDerivedValue) {
		data = metricConfig.getDerivedValue(kpiInfo, companyConfig);
	}
	const isCurrencyField = metricConfig.dataType === METRIC_TYPES.CURRENCY;
	// Formatting currency values
	const formattedValueData = isCurrencyField ? roundValue(data) : data;
	// Format the values.
	const formattedValue = metricConfig.valueFormatter({
		value: formattedValueData
	});
	return isCurrencyField ? `$${formattedValue}` : formattedValue;
};

const calculatePercentageChange = (
	kpiInfo,
	prevKPITotals,
	metricConfig,
	companyConfig
) => {
	const fieldName = metricConfig.field;
	let data = kpiInfo[fieldName];
	let prevData = prevKPITotals[fieldName];

	if (metricConfig.getDerivedValue && !_isEmpty(prevKPITotals)) {
		data = metricConfig.getDerivedValue(kpiInfo, companyConfig);
		prevData = metricConfig.getDerivedValue(prevKPITotals, companyConfig);
	}
	if (prevData > 0) {
		prevData = Math.round(((data - prevData) / prevData) * 100);
		const symbol = prevData > 0 ? "+" : "";
		const color =
			prevData >= 0 ? PRIMARY_COLORS.GREEN.id : PRIMARY_COLORS.RED.id;
		return (
			<Box component="span" sx={{ color: { color }, pr: 1 }}>
				{symbol}
				{prevData}%
			</Box>
		);
	}
	return "";
};

const getToolTip = (dateRangeType, diffInDays) => {
	return (
		(dateRangeType &&
			lc.KPI_VALUES_TOOLTIP[_snakeCase(dateRangeType).toUpperCase()]) ||
		(diffInDays && lc.KPI_VALUES_DAYS_DIFF(diffInDays)) ||
		""
	);
};

function KPIMetricsCard(props) {
	const { metric, kpiInfo, prevKpiInfo } = props;
	const metricKey = metric.field;
	const authorizationContext = useContext(AuthorizationContext);
	const { companyConfig } = useContext(BrandingContext);

	if (
		!metric.permissionsRequired.every(permission =>
			authorizationContext?.hasPermission(permission)
		)
	) {
		return null;
	}

	return (
		<Grid item xs={12} sm={6} md={3} key={`key-${metricKey}`}>
			<Card sx={{ minWidth: 100 }}>
				<CardContent
					sx={{
						"&:last-child": { pb: "16px" },
						fontSize: "14px",
						display: "flex"
					}}
				>
					<Tooltip title={metric.description}>
						<Box component="span" data-testid={metricKey}>
							{metric.name}
						</Box>
					</Tooltip>
					<Box sx={{ ml: "auto" }}>
						<Tooltip
							title={getToolTip(
								prevKpiInfo.dateRangeType,
								prevKpiInfo.diffInDays
							)}
						>
							<Box
								sx={{ display: "inline" }}
								data-testid={`${metricKey}Change`}
							>
								{calculatePercentageChange(
									kpiInfo,
									prevKpiInfo.totals,
									metric,
									companyConfig
								)}
							</Box>
						</Tooltip>
						<Box
							sx={{ display: "inline" }}
							fontWeight="bold"
							data-testid={`${metricKey}Value`}
						>
							{!_isEmpty(kpiInfo)
								? transformValues(kpiInfo, metric, companyConfig)
								: 0}
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Grid>
	);
}
KPIMetricsCard.propTypes = {
	kpiInfo: PropTypes.shape().isRequired,
	prevKpiInfo: PropTypes.shape().isRequired,
	metric: PropTypes.shape().isRequired
};

export default KPIMetricsCard;
