import FieldTypes from "../../FieldTypes";
import { buildValidationSchema } from "../../../FormFieldExpressionBuilder/FormFieldExpressionBuilder";
import CustomUrlLeafCreator from "./CustomUrlLeafCreator";
import Operators from "../../../FormFieldExpressionBuilder/Operators";
import lc from "./localeContent";
import CustomUrlRuleVisualizer from "./CustomUrlRuleVisualizer";
import { EXPRESSION_NODE_TYPE } from "../../../../config/constants";

const CustomUrlParamsFieldDefinition = {
	name: "customUrlParamsExpression",
	fieldType: FieldTypes.EXPRESSION_BUILDER,
	validationSchema: buildValidationSchema({
		leafName: lc.VALIDATION_LEAF_NAME,
		leafNodeKey: EXPRESSION_NODE_TYPE.CUSTOM_URL_PARAM,
		canEnable: true
	}),
	defaultValue: { expression: [] },
	fieldTypeOptions: {
		enableFieldLabel: lc.ENABLE_FIELD_LABEL,
		enableFieldTooltip: lc.ENABLE_FIELD_TOOLTIP,
		emptyExpressionWarning: lc.EMPTY_EXPRESSION_WARNING,
		leafExpressionNodeRepresentation: {
			nodeType: EXPRESSION_NODE_TYPE.CUSTOM_URL_PARAM,
			labelKey: "customUrlKey"
		},
		customLeafCreation: {
			Component: CustomUrlLeafCreator,
			transformExpressionOnInit: expression => {
				if (!expression) {
					return expression;
				}

				function transformExpression(node) {
					const transformOperator = targetNode => ({
						...targetNode,
						nodes: targetNode.nodes.map(subnode => transformExpression(subnode))
					});

					switch (node.nodeType) {
						case Operators.AND.nodeType:
						case Operators.NOT.nodeType:
							return transformOperator(node);
						case Operators.OR.nodeType: {
							const subnodes = node.nodes;
							const areAllSubnodesLeafs = subnodes.every(
								subnode =>
									subnode.nodeType === EXPRESSION_NODE_TYPE.CUSTOM_URL_PARAM
							);
							if (areAllSubnodesLeafs) {
								return {
									nodeType: EXPRESSION_NODE_TYPE.CUSTOM_URL_PARAM,
									customUrlKey: subnodes[0].customUrlKey,
									customUrlValues: subnodes.map(
										subnode => subnode.customUrlValue
									)
								};
							}
							return transformOperator(node);
						}
						default:
							return null;
					}
				}

				return transformExpression(expression);
			},
			transformExpressionOnSubmit: expression => {
				function transformExpression(node) {
					const transformOperator = targetNode => ({
						...targetNode,
						nodes: targetNode.nodes.map(subnode => transformExpression(subnode))
					});

					switch (node.nodeType) {
						case Operators.AND.nodeType:
						case Operators.NOT.nodeType:
						case Operators.OR.nodeType:
							return transformOperator(node);
						case EXPRESSION_NODE_TYPE.CUSTOM_URL_PARAM: {
							return {
								nodeType: "OR",
								nodes: node.customUrlValues.map(customUrlValue => ({
									nodeType: EXPRESSION_NODE_TYPE.CUSTOM_URL_PARAM,
									customUrlKey: node.customUrlKey,
									customUrlValue
								}))
							};
						}
						default:
							return null;
					}
				}

				return transformExpression(expression);
			}
		},
		RuleVisualizer: CustomUrlRuleVisualizer
	}
};

export default CustomUrlParamsFieldDefinition;
