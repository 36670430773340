import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import _isEmpty from "lodash/isEmpty";
import {
	Checkbox,
	FormControlLabel,
	FormHelperText,
	FormControl,
	FormLabel,
	FormGroup
} from "@mui/material";

const COMPANY_AUDIENCE = "companyAudience";
const ROLE_AUDIENCE = "roleAudience";
const USER_AUDIENCE = "userAudience";
export const transformInitData = data => {
	const isSelectedMap = {
		[COMPANY_AUDIENCE]: !_isEmpty(data[COMPANY_AUDIENCE]),
		[ROLE_AUDIENCE]: !_isEmpty(data[ROLE_AUDIENCE]),
		[USER_AUDIENCE]: !_isEmpty(data[USER_AUDIENCE])
	};
	return isSelectedMap;
};

export const transformSubmitData = () => {
	return {};
};

function FormCheckboxMulti(props) {
	const { label, id, name, disabled, optionsConfig, required } = props;
	const [field, meta, helpers] = useField(name);
	const showError = Boolean(meta.touched && meta.error);
	const helperText = showError ? meta.error : "";
	const updateSelectedOption = useCallback(
		audienceType => {
			helpers.setValue({
				...field.value,
				[audienceType]: !field.value[audienceType]
			});
		},
		[helpers, field.value]
	);
	const getLabel = () => (required ? `${label} *` : label);
	return (
		<FormControl error={showError}>
			<FormLabel component="legend" id={id}>
				{getLabel()}
			</FormLabel>
			<FormGroup row>
				{optionsConfig.options.map(option => (
					<FormControlLabel
						key={option.value}
						control={
							<Checkbox
								checked={field.value[option.value]}
								onChange={() => updateSelectedOption(option.value)}
								name={label}
								disabled={disabled}
								id={id}
								color="primary"
								required={required}
							/>
						}
						label={option.label}
					/>
				))}
			</FormGroup>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

FormCheckboxMulti.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	optionsConfig: PropTypes.shape(),
	required: PropTypes.bool
};

FormCheckboxMulti.defaultProps = {
	disabled: false,
	optionsConfig: {},
	required: false
};

export default FormCheckboxMulti;
