import React from "react";
import PropTypes from "prop-types";

function TabPanelComponentWrapper(props) {
	const { componentConfig, resourceId, formContext } = props;
	const { Component: TabComponent, props: tabComponentProps } = componentConfig;

	return (
		<TabComponent
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...tabComponentProps}
			resourceId={resourceId}
			isCreateResource={formContext.isCreateResource}
			formContext={formContext}
		/>
	);
}
TabPanelComponentWrapper.propTypes = {
	componentConfig: PropTypes.shape({
		Component: PropTypes.func.isRequired,
		props: PropTypes.shape()
	}).isRequired,
	formContext: PropTypes.shape().isRequired,
	resourceId: PropTypes.string
};
TabPanelComponentWrapper.defaultProps = {
	resourceId: null
};

export default TabPanelComponentWrapper;
