import React from "react";
import * as Yup from "yup";
import CrudForm from "../../components/CrudForm/CrudForm";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import FormFieldButton from "../../components/FormFieldButton/FormFieldButton";
import { SERVER_URL_WITH_VERSION } from "../../config/constants";

const tabsConfig = [
	{
		label: localeContent.TAB_LABEL_SETTINGS,
		key: "SETTINGS",
		fieldsConfig: [
			{
				key: "BASIC_SETTINGS",
				fields: [
					{
						label: localeContent.PUBLISHER_NAME_LABEL,
						name: "name",
						gridConfig: { md: 8 },
						isRequired: true
					},
					{
						label: localeContent.STATUS_LABEL,
						name: "status",
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									value: "active",
									label: localeContent.STATUS_OPTION_LABEL_ACTIVE
								},
								{
									value: "inactive",
									label: localeContent.STATUS_OPTION_LABEL_INACTIVE
								}
							]
						},
						defaultValue: "active"
					},
					{
						label: localeContent.DEFAULT_FLOOR_LABEL,
						fieldType: FieldTypes.CURRENCY,
						name: "defaultFloor",
						gridConfig: { md: 4, lg: 3 },
						tooltip: localeContent.DEFAULT_FLOOR_TOOLTIP
					},
					{
						label: localeContent.REVENUE_SHARE_LABEL,
						fieldType: FieldTypes.PERCENT,
						name: "defaultRevenueShare",
						gridConfig: { md: 4, lg: 3 },
						tooltip: localeContent.DEFAULT_REVENUE_SHARE_TOOLTIP
					},
					{
						label: localeContent.TMAX_TITLE_LABEL,
						fieldType: FieldTypes.NUMBER,
						name: "tmax",
						gridConfig: { md: 4, lg: 3 },
						tooltip: localeContent.TMAX_TOOLTIP_LABEL,
						max: 60000,
						defaultValue: 300,
						validationSchema: Yup.number()
							.max(60000, localeContent.TMAX_RANGE_VALIDATION_MSG)
							.min(1, localeContent.TMAX_RANGE_VALIDATION_MSG),
						fieldTypeOptions: {
							min: 1,
							max: 60000
						}
					}
				]
			},
			{
				key: "CONTACT_INFO",
				fields: [
					{
						label: localeContent.CONTACT_NAME_LABEL,
						name: "contactName",
						gridConfig: { md: 6 }
					},
					{
						label: localeContent.CONTACT_EMAIL_LABEL,
						name: "contactEmail",
						gridConfig: { md: 6 },
						fieldType: FieldTypes.EMAIL
					},
					{
						label: localeContent.CONTACT_PHONE_LABEL,
						name: "contactPhone",
						gridConfig: { md: 6 },
						fieldType: FieldTypes.PHONE_NUMBER
					},
					{
						label: localeContent.CONTACT_ADDRESS_LABEL,
						name: "contactAddress",
						gridConfig: { md: 6 }
					},
					{
						label: localeContent.IS_BILLING_INFO_APPLICABLE_LABEL,
						name: "billingInfoApplicable",
						fieldType: FieldTypes.CHECKBOX_SINGLE,
						defaultValue: false
					}
				]
			},
			{
				key: "BILLING_INFO",
				renderIf: formValues => formValues.billingInfoApplicable,
				fields: [
					{
						label: localeContent.BILLING_CONTACT_NAME_LABEL,
						name: "billingContactName",
						gridConfig: { md: 6 }
					},
					{
						label: localeContent.BILLING_CONTACT_EMAIL_LABEL,
						name: "billingContactEmail",
						gridConfig: { md: 6 },
						fieldType: FieldTypes.EMAIL
					},
					{
						label: localeContent.BILLING_CONTACT_PHONE_LABEL,
						name: "billingContactPhone",
						gridConfig: { md: 6 },
						fieldType: FieldTypes.PHONE_NUMBER
					},
					{
						label: localeContent.BILLING_CONTACT_ADDRESS_LABEL,
						name: "billingContactAddress",
						gridConfig: { md: 6 }
					}
				]
			}
		]
	},
	{
		label: localeContent.TAB_LABEL_VERIFICATION,
		key: "VERIFICATION",
		fieldsConfig: [
			{
				key: "ADS_TXT_FIELDS",
				fields: [
					{
						label: localeContent.AD_SYSTEM_DOMAIN_LABEL,
						name: "adSystemDomain",
						gridConfig: { md: 6 },
						readOnly: true,
						defaultValue: "siprocalads.com",
						tooltip: localeContent.AD_SYSTEM_DOMAIN_TOOLTIP
					},
					{
						label: localeContent.ADS_TXT_ACCOUNT_ID_LABEL,
						name: "adsTxtAccountId",
						gridConfig: { md: 6 },
						readOnly: true,
						tooltip: localeContent.ADS_TXT_ACCOUNT_ID_TOOLTIP
					},
					{
						label: localeContent.ACCOUNT_RELATIONSHIP_LABEL,
						name: "accountRelationship",
						fieldType: FieldTypes.RADIO,
						gridConfig: { md: 6 },
						optionsConfig: {
							options: [
								{
									value: "direct",
									label: localeContent.ACCOUNT_RELATIONSHIP_OPTION_LABEL_DIRECT
								},
								{
									value: "reseller",
									label:
										localeContent.ACCOUNT_RELATIONSHIP_OPTION_LABEL_RESELLER
								}
							]
						},
						defaultValue: "direct",
						tooltip: localeContent.ACCOUNT_RELATIONSHIP_TOOLTIP
					},
					{
						label: localeContent.CERT_AUTHORITY_ID_LABEL,
						name: "certAuthorityID",
						gridConfig: { md: 6 }
					},
					{
						name: "downloadAds",
						fieldTypeOptions: {
							buttonLabel: localeContent.DOWNLOAD_ADS_FILE_LABEL,
							getLinkToRoute: crudFormResourceId =>
								`${SERVER_URL_WITH_VERSION}/manage/publishers/${crudFormResourceId}/ads_txt`
						},
						fieldType: FieldTypes.NON_FIELD_COMPONENT,
						component: FormFieldButton,
						gridConfig: { md: 6 },
						hideOnCreate: true
					}
				]
			},
			{
				key: "SELLERS_JSON_FIELDS",
				fields: [
					{
						label: localeContent.WEB_SITE_DOMAIN_LABEL,
						name: "webSiteDomain",
						gridConfig: { md: 6 },
						isRequired: true,
						tooltip: localeContent.WEB_SITE_DOMAIN_TOOLTIP
					},
					{
						label: localeContent.SELLER_TYPE_LABEL,
						name: "sellerType",
						fieldType: FieldTypes.RADIO,
						gridConfig: { md: 6, lg: 4, offset: { md: 6 } },
						optionsConfig: {
							options: [
								{
									value: "publisher",
									label: "Publisher"
								},
								{
									value: "intermediary",
									label: "Intermediary"
								},
								{
									value: "both",
									label: "Both"
								}
							]
						},
						defaultValue: "publisher",
						tooltip: localeContent.SELLER_TYPE_TOOLTIP
					},
					{
						label: localeContent.IS_CONFIDENTIAL_LABEL,
						name: "confidential",
						fieldType: FieldTypes.RADIO,
						gridConfig: { md: 6, lg: 2 },
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									value: true,
									label: "Yes"
								},
								{
									value: false,
									label: "No"
								}
							]
						},
						defaultValue: false,
						tooltip: localeContent.IS_CONFIDENTIAL_TOOLTIP
					}
				]
			}
		]
	},
	{
		label: localeContent.TAB_LABEL_SITES_SECTION,
		key: "SITES",
		disableOnCreate: true,
		childResourceConfig: {
			columnsConfig: [
				{
					name: commonLocaleContent.COLUMN_HEADER_STATUS,
					key: COLUMN_KEY_STATUS,
					cellDataType: "upperCase"
				}
			],
			resourceEndpoint: "manage/sites",
			resourceRoute: "sites",
			resourceName: commonLocaleContent.SITE,
			getRequestParams: publisherId => ({
				publisherId
			})
		}
	}
];

const viewReportConfig = {
	permissionsRequired: ["VIEW_REPORT_BUTTON", "VIEW_NETWORK_REPORT"],
	reportType: "network",
	filterKeyValuesGetter: context => {
		return {
			publisher: context.initData.guid
		};
	}
};

function PublisherResourceScreen() {
	return (
		<CrudForm
			resourceEndpoint="manage/publishers"
			resourceRoute="publishers"
			resourceString={commonLocaleContent.PUBLISHER}
			preSubmit={formValues => {
				const submissionValues = { ...formValues };
				if (!formValues.billingInfoApplicable) {
					submissionValues.billingContactName = null;
					submissionValues.billingContactEmail = null;
					submissionValues.billingContactPhone = null;
					submissionValues.billingContactAddress = null;
				}
				return submissionValues;
			}}
			tabsConfig={tabsConfig}
			prepopulateCreate
			mapCloneDataOverride={data => ({
				...data,
				name: "",
				adsTxtAccountId: ""
			})}
			viewReportConfig={viewReportConfig}
		/>
	);
}

export default PublisherResourceScreen;
