import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import {
	Checkbox,
	FormControlLabel,
	FormHelperText,
	FormControl
} from "@mui/material";

function FormCheckboxSingle(props) {
	const { label, id, name, disabled } = props;
	const [field, meta] = useField(name);
	const showError = Boolean(meta.touched && meta.error);
	const helperText = showError ? meta.error : "";
	return (
		<FormControl>
			<FormControlLabel
				control={
					<Checkbox
						checked={field.value || false}
						onChange={field.onChange}
						name={name}
						disabled={disabled}
						id={id}
						color="primary"
					/>
				}
				label={label}
			/>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

FormCheckboxSingle.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool
};

FormCheckboxSingle.defaultProps = {
	disabled: false
};

export default FormCheckboxSingle;
