import React from "react";
import PropTypes from "prop-types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import DnDListItem from "./DnDListItem";

function SortableDnDListItem(props) {
	const { sortableId, label, secondaryAction, disableDrag } = props;
	const {
		attributes,
		listeners,
		isDragging,
		setNodeRef,
		transform,
		transition
	} = useSortable({ id: sortableId });

	const style = {
		opacity: isDragging ? 0.5 : undefined,
		transform: CSS.Translate.toString(transform),
		transition
	};

	return (
		<DnDListItem
			ref={setNodeRef}
			style={style}
			label={label}
			attributes={attributes}
			listeners={listeners}
			secondaryAction={secondaryAction}
			disableDrag={disableDrag}
		/>
	);
}

SortableDnDListItem.propTypes = {
	sortableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	label: PropTypes.string.isRequired,
	secondaryAction: PropTypes.shape(),
	disableDrag: PropTypes.bool
};

SortableDnDListItem.defaultProps = {
	secondaryAction: undefined,
	disableDrag: false
};

export default SortableDnDListItem;
