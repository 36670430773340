const constants = {
	reportStatus: {
		PENDING: "Pending",
		FINISHED: "Finished",
		RUNNING: "Running",
		ERROR: "Error",
		DELETED: "Deleted"
	},
	downloadFileTypes: {
		DOWNLOAD_EXCEL: "DOWNLOAD-EXCEL",
		DOWNLOAD_CSV: "DOWNLOAD-CSV"
	}
};
export default constants;
