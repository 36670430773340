import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Grid, FormHelperText } from "@mui/material";
import { useField } from "formik";

import Multiselect from "../FormFieldMultiselect/Multiselect";
import FormField from "../CrudForm/FormField";
import BooleanCheckbox from "../FormFieldBooleanCheckbox/BooleanCheckbox";
import localeContent from "./localeContent";
import { Operations, useResourceClient } from "../../hooks/useResource";
import { defaultFilterSelectedOptions } from "../FormFieldMultiselect/FormFieldMultiselect";

const getSelectedVendorOptions = (optionsList, fieldValue) => {
	return fieldValue
		? optionsList.filter(defaultFilterSelectedOptions(fieldValue))
		: [];
};

const filterInheritedOptions = (options, inheritedValues) =>
	options.filter(
		({ id }) =>
			!inheritedValues ||
			inheritedValues.length === 0 ||
			inheritedValues.includes(id)
	);

const mapEntriesToOptions = ([key, value]) => ({ id: key, name: value });

const transformObjectToOptionsArray = obj =>
	Object.entries(obj || {}).map(mapEntriesToOptions);

function DeviceTargetingRow(props) {
	const {
		isSelected,
		checkboxLabel,
		onSelect,
		operatingSystemOptions,
		hasInheritValues,
		vendorOptions,
		inheritedValues
	} = props;
	const [osField, , osHelper] = useField(operatingSystemOptions.fieldName);
	const [vendorField, , vendorHelper] = useField(vendorOptions.fieldName);
	const [vendorListsFromAPI] = useResourceClient(
		`manage/catalogue/deviceVendor?deviceClass=${vendorOptions.vendorType}`,
		Operations.LIST,
		{}
	);
	const vendorOptionsList = useMemo(
		() =>
			filterInheritedOptions(
				transformObjectToOptionsArray(vendorListsFromAPI),
				inheritedValues[vendorOptions.id]
			),
		[vendorListsFromAPI, inheritedValues, vendorOptions.id]
	);
	const osOptionsList = useMemo(
		() =>
			filterInheritedOptions(
				operatingSystemOptions.options,
				inheritedValues[operatingSystemOptions.id]
			),
		[operatingSystemOptions.options, inheritedValues, operatingSystemOptions.id]
	);
	const selectedVendorOptions = getSelectedVendorOptions(
		vendorOptionsList,
		vendorField.value
	);
	const selectedOSOptions = getSelectedVendorOptions(
		osOptionsList,
		osField.value
	);
	return (
		<>
			<Grid item xs={12} md={2}>
				<BooleanCheckbox
					label={checkboxLabel}
					checked={isSelected}
					disabled={hasInheritValues}
					onChange={onSelect}
				/>
			</Grid>
			{isSelected && (
				<>
					<Grid item xs={12} md={5}>
						<FormField tooltip={vendorOptions.tooltip}>
							<Multiselect
								id={vendorOptions.id}
								name={vendorOptions.label}
								label={vendorOptions.label}
								options={vendorOptionsList}
								selectedOptions={selectedVendorOptions}
								onChange={(event, value) => {
									vendorHelper.setValue(value.map(data => data.id));
								}}
							/>
						</FormField>
						{!!inheritedValues[vendorOptions.id] && (
							<FormHelperText>
								{`${localeContent.INHERITED_LABEL}: ${vendorOptionsList
									.map(option => option.name)
									.join(", ")}`}
							</FormHelperText>
						)}
					</Grid>
					{operatingSystemOptions.label && (
						<Grid item xs={12} md={5}>
							<FormField tooltip={operatingSystemOptions.tooltip}>
								<Multiselect
									id={operatingSystemOptions.id}
									name={operatingSystemOptions.label}
									label={operatingSystemOptions.label}
									options={osOptionsList}
									selectedOptions={selectedOSOptions}
									onChange={(event, value) => {
										osHelper.setValue(value.map(data => data.id));
									}}
								/>
							</FormField>
							{operatingSystemOptions.label &&
								!!inheritedValues[operatingSystemOptions.id] && (
									<FormHelperText>
										{`${localeContent.INHERITED_LABEL}: ${osOptionsList
											.map(option => option.name)
											.join(", ")}`}
									</FormHelperText>
								)}
						</Grid>
					)}
				</>
			)}
		</>
	);
}

DeviceTargetingRow.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	checkboxLabel: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired,
	operatingSystemOptions: PropTypes.shape(),
	hasInheritValues: PropTypes.bool.isRequired,
	vendorOptions: PropTypes.shape().isRequired,
	inheritedValues: PropTypes.shape().isRequired
};

DeviceTargetingRow.defaultProps = {
	operatingSystemOptions: {
		options: [],
		fieldName: "default"
	}
};

export default DeviceTargetingRow;
