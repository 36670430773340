import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { List, Paper, Toolbar, Divider } from "@mui/material";
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy
} from "@dnd-kit/sortable";

import SortableDnDListItem from "./SortableDndListItem";
import CONDITIONAL_SCHEDULED_REPORTS_ITEMS from "../../screens/ScheduledReports/config";

const SELECTION_LIST_PAPER_ELEVATION = 2;

const calculateSpacerHeight = (options, itemIds) => {
	if (!options) {
		return 0;
	}
	const spacerItemsCount = options.length - itemIds.length;
	return spacerItemsCount * 36.016;
};

function DropabbleContainer(props) {
	const {
		id,
		itemIds,
		options,
		ariaLabel,
		toolbarContent,
		strategy = verticalListSortingStrategy,
		secondaryAction,
		error,
		selectedIds,
		disabledItems
	} = props;

	const { setNodeRef } = useSortable({
		id
	});
	// This Method is returning value, if item is present in the selected list.
	const isItemSelected = useCallback(
		selectedItem => itemIds && itemIds.find(item => item === selectedItem),
		[itemIds]
	);

	// this method is enabling/disabling the available list items.
	/* Here we are using tow conditional sceraios
		- 1: disabledWith: This Object key is containg an array of items, If a user selectd any of the item, the other will became disabled.
			For Example, If a user if selecting the dimension then the hour and day will be disabled and visa versa.
		- 2: enabledWith: This object key is also containing an array of items but on the basis of the item selection, the other item will become enabled.
	*/
	const isIncompatibleItem = selectedId => {
		if (disabledItems?.includes(selectedId)) {
			return true;
		}

		let isIncompatible = false;
		if (id === "available") {
			const item = CONDITIONAL_SCHEDULED_REPORTS_ITEMS[selectedId];
			const conditions = item?.disabledWith
				? item?.disabledWith
				: item?.enabledWith;
			if (conditions) {
				const count = selectedIds.filter(value => conditions.includes(value))
					.length;
				isIncompatible = item.disabledWith ? count > 0 : count === 0;
			}
		}
		return isIncompatible;
	};

	// This method is basically removing the dependent incompatible items from the selected panel.
	const removeDependentIncompatibleItems = useCallback(() => {
		if (id === "selected" && itemIds) {
			// commonItems are basically the item which has the enableWith key with config object from the list of selected items.
			const commonItems = itemIds.find(
				value => CONDITIONAL_SCHEDULED_REPORTS_ITEMS[value]?.enabledWith
			);
			const enabledWith =
				CONDITIONAL_SCHEDULED_REPORTS_ITEMS[commonItems]?.enabledWith;
			if (enabledWith)
				enabledWith.forEach(item => {
					// If any a user is removing the parent key of enableWith object then its dependent child will also be removed
					if (!isItemSelected(item) && isItemSelected(commonItems)) {
						secondaryAction.action(commonItems);
					}
				});
		}
	}, [id, isItemSelected, itemIds, secondaryAction]);

	useEffect(() => {
		removeDependentIncompatibleItems();
	}, [removeDependentIncompatibleItems]);

	return (
		<Paper elevation={SELECTION_LIST_PAPER_ELEVATION}>
			<Toolbar
				variant="dense"
				sx={{
					justifyContent: "right",
					...(error
						? {
								borderBottom: "2px solid",
								borderBottomColor: "error.main"
						  }
						: {})
				}}
			>
				{toolbarContent}
			</Toolbar>
			<Divider />
			<div ref={setNodeRef}>
				{/*
					Container-Item context
				*/}
				<SortableContext items={itemIds} strategy={strategy}>
					<List
						dense
						aria-label={ariaLabel}
						sx={theme => ({
							minHeight: theme.spacing(40)
						})}
					>
						{options &&
							itemIds.map(optionId => {
								const option = options.find(item => item.id === optionId);
								return (
									<SortableDnDListItem
										key={option.id}
										sortableId={option.id}
										label={option.label}
										disableDrag={isIncompatibleItem(option.id)}
										secondaryAction={
											secondaryAction && !isIncompatibleItem(option.id)
												? {
														...secondaryAction,
														action: () => secondaryAction.action(option.id)
												  }
												: undefined
										}
									/>
								);
							})}

						<div
							style={{
								height: calculateSpacerHeight(options, itemIds)
							}}
						/>
					</List>
				</SortableContext>
			</div>
		</Paper>
	);
}

DropabbleContainer.propTypes = {
	id: PropTypes.string.isRequired,
	itemIds: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	).isRequired,
	options: PropTypes.arrayOf(PropTypes.shape()),
	ariaLabel: PropTypes.string.isRequired,
	toolbarContent: PropTypes.node,
	strategy: PropTypes.func,
	secondaryAction: PropTypes.shape(),
	error: PropTypes.bool,
	selectedIds: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	),
	disabledItems: PropTypes.arrayOf(PropTypes.string)
};

DropabbleContainer.defaultProps = {
	toolbarContent: undefined,
	strategy: undefined,
	secondaryAction: undefined,
	options: undefined,
	error: false,
	selectedIds: undefined,
	disabledItems: []
};

export default DropabbleContainer;
