import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { useField } from "formik";
import FormTextField from "../FormTextField/FormTextField";
import FormFieldSelect from "../FormFieldSelect/FormFieldSelect";
import FormFieldNumber from "../FormFieldNumber/FormFieldNumber";
import GridOffset from "../GridOffset/GridOffset";
import localeContent from "./localeContent";
import crudFormLocaleContent from "../CrudForm/localeContent";
import {
	MEDIA_TYPE_VIDEO_ID,
	MEDIA_SOURCE_ASSET_ID,
	CREATIVE_TYPE_MEDIA_URL,
	MUI_GRID_CONTAINER_SPACING
} from "../../config/constants";

const MIME_TYPE_OPTIONS = [
	{ value: "video/mp4", label: "video/mp4" },
	{ value: "video/x-mp4", label: "video/x-mp4" },
	{ value: "video/flv", label: "video/flv" },
	{ value: "video/x-flv", label: "video/x-flv" },
	{ value: "video/ogg", label: "video/ogg" },
	{ value: "video/webm", label: "video/webm" },
	{ value: "video/H264", label: "video/H264" },
	{ value: "video/mpeg", label: "video/mpeg" },
	{ value: "video/3gp", label: "video/3gp" },
	{ value: "application/javascript", label: "application/javascript" },
	{
		value: "application/x-shockwave-flash",
		label: "application/x-shockwave-flash"
	}
];

export const KEY_NAME = "name";
export const KEY_URL = "url";
export const KEY_MIME_TYPE = "mimeType";
export const KEY_BITRATE = "bitRate";
export const KEY_WIDTH = "width";
export const KEY_HEIGHT = "height";

const requiredStringValidation = Yup.string().required(
	crudFormLocaleContent.REQUIRED_FIELD_WARNING
);

export const validationSchema = Yup.array().when(
	["mediaTypeId", "mediaSource", "creativeType"],
	{
		is: (mediaTypeId, mediaSource, creativeType) =>
			mediaTypeId === String(MEDIA_TYPE_VIDEO_ID) &&
			mediaSource === MEDIA_SOURCE_ASSET_ID &&
			creativeType === CREATIVE_TYPE_MEDIA_URL,
		then: Yup.array().of(
			Yup.object({
				[KEY_NAME]: requiredStringValidation,
				[KEY_URL]: requiredStringValidation,
				[KEY_MIME_TYPE]: requiredStringValidation,
				[KEY_BITRATE]: requiredStringValidation,
				[KEY_WIDTH]: requiredStringValidation,
				[KEY_HEIGHT]: requiredStringValidation
			})
		)
	}
);

export const transformSubmitData = (data, fieldName) => {
	if (Object.keys(data[0] || {}).length > 0) {
		return {
			[fieldName]: [
				{
					...data[0]
				}
			]
		};
	}
	return {
		[fieldName]: []
	};
};

function FormFieldMediaUrl(props) {
	const { name } = props;
	const [field, , helpers] = useField(name);

	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (!isLoaded && field.value.length === 0) {
			helpers.setValue([
				{
					[KEY_NAME]: "",
					[KEY_URL]: "",
					[KEY_MIME_TYPE]: "",
					[KEY_BITRATE]: "",
					[KEY_WIDTH]: "",
					[KEY_HEIGHT]: ""
				}
			]);
			setIsLoaded(true);
		}
	}, [isLoaded, helpers, field.value.length]);

	return (
		<Grid container spacing={MUI_GRID_CONTAINER_SPACING}>
			<Grid item xs={8}>
				<FormTextField
					id={`${name}[0].${KEY_NAME}`}
					name={`${name}[0].${KEY_NAME}`}
					label={localeContent.FIELD_LABEL_CREATIVE_NAME}
					required
				/>
			</Grid>
			<Grid item xs={8}>
				<FormTextField
					id={`${name}[0].${KEY_URL}`}
					name={`${name}[0].${KEY_URL}`}
					label={localeContent.FIELD_LABEL_MEDIA_FILE_URL}
					required
				/>
			</Grid>
			<GridOffset offsetConfig={{ fullRowClear: true }} />
			<Grid item xs={3}>
				<FormFieldSelect
					id={`${name}[0].${KEY_MIME_TYPE}`}
					name={`${name}[0].${KEY_MIME_TYPE}`}
					label={localeContent.FIELD_LABEL_MIME_TYPE}
					optionsConfig={{ options: MIME_TYPE_OPTIONS }}
					required
				/>
			</Grid>
			<Grid item xs={3}>
				<FormFieldNumber
					id={`${name}[0].${KEY_BITRATE}`}
					name={`${name}[0].${KEY_BITRATE}`}
					label={localeContent.FIELD_LABEL_BITRATE}
					fieldTypeOptions={{
						endAdornmentText: "kbps"
					}}
					required
				/>
			</Grid>
			<Grid item xs={3}>
				<FormFieldNumber
					id={`${name}[0].${KEY_WIDTH}`}
					name={`${name}[0].${KEY_WIDTH}`}
					label={localeContent.FIELD_LABEL_WIDTH}
					fieldTypeOptions={{
						endAdornmentText: "px"
					}}
					required
				/>
			</Grid>
			<Grid item xs={3}>
				<FormFieldNumber
					id={`${name}[0].${KEY_HEIGHT}`}
					name={`${name}[0].${KEY_HEIGHT}`}
					label={localeContent.FIELD_LABEL_HEIGHT}
					fieldTypeOptions={{
						endAdornmentText: "px"
					}}
					required
				/>
			</Grid>
		</Grid>
	);
}

FormFieldMediaUrl.propTypes = {
	name: PropTypes.string.isRequired
};

FormFieldMediaUrl.defaultProps = {};

export default FormFieldMediaUrl;
