import React from "react";
import { format } from "date-fns";
import * as Yup from "yup";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import CrudForm from "../../components/CrudForm/CrudForm";
import localeContent from "./localeContent";
import FormCheckboxMulti, {
	transformInitData as transformMultiCheckboxInitData,
	transformSubmitData as transformMultiCheckboxSubmitData
} from "./FormCheckboxMulti/FormCheckboxMulti";
import crudFormLocaleContent from "../../components/CrudForm/localeContent";
import { todayInTimezone } from "../../utils/dateUtils";

const DATE_TIME_FORMAT = "MM/dd/yyyy hh:mm a";

const COMPANY_AUDIENCE = "companyAudience";
const ROLE_AUDIENCE = "roleAudience";
const USER_AUDIENCE = "userAudience";

const currentDateTime = userTimeZone =>
	format(todayInTimezone(userTimeZone), DATE_TIME_FORMAT);

const NOTIFICATION_TYPE_OPTIONS = [
	{
		id: "other",
		name: localeContent.GENERAL_NOTIFICATION
	},
	{
		id: "optimization",
		name: localeContent.OPTIMIZATION_ALERT
	},
	{
		id: "product",
		name: localeContent.PRODUCT_UPDATE
	},
	{
		id: "system",
		name: localeContent.SYSTEM_MAINTENANCE
	}
];

const AUDIENCE_SELECTION_TYPE_OPTIONS = [
	{
		value: COMPANY_AUDIENCE,
		label: localeContent.COMPANY
	},
	{
		value: ROLE_AUDIENCE,
		label: localeContent.USER_ROLE
	},
	{
		value: USER_AUDIENCE,
		label: localeContent.USER
	}
];

const validationSchema = KEY_SELECTED_IDS =>
	Yup.array().when("audienceSelectionType", {
		is: audienceSelectionType => audienceSelectionType[KEY_SELECTED_IDS],
		then: schema => schema.min(1, localeContent.FIELD_IS_REQUIRED)
	});

const multiCheckboxValidationSchema = () =>
	Yup.object({
		[USER_AUDIENCE]: Yup.boolean(),
		[COMPANY_AUDIENCE]: Yup.boolean(),
		[ROLE_AUDIENCE]: Yup.boolean()
	}).test("audienceSelectionType", null, audienceType => {
		if (
			audienceType[USER_AUDIENCE] ||
			audienceType[COMPANY_AUDIENCE] ||
			audienceType[ROLE_AUDIENCE]
		) {
			return true;
		}
		return new Yup.ValidationError(
			crudFormLocaleContent.REQUIRED_FIELD_WARNING,
			null,
			"audienceSelectionType"
		);
	});

const FIELDS_CONFIG = [
	{
		key: "BASIC_SETTINGS",
		fields: [
			{
				name: "name",
				label: localeContent.NOTIFICATION_NAME,
				isRequired: true,
				gridConfig: { md: 8 }
			},
			{
				name: "type",
				label: localeContent.NOTIFICATION_TYPE,
				fieldType: FieldTypes.COMBO_BOX,
				gridConfig: { md: 4 },
				defaultValue: NOTIFICATION_TYPE_OPTIONS[0].id,
				optionsConfig: {
					options: NOTIFICATION_TYPE_OPTIONS
				},
				disableOnEdit: true
			},
			{
				name: "notificationText",
				label: localeContent.NOTIFICATION_TEXT,
				fieldType: FieldTypes.RICH_TEXT,
				gridConfig: { md: 12 },
				disableOnEdit: true,
				disableIf: (formValues, initData, resourceId) => resourceId,
				isRequired: true,
				fieldTypeOptions: {
					multiline: true,
					rows: 4
				}
			}
		]
	},
	{
		key: "DELIVERY_SETTINGS",
		fields: [
			{
				name: "sendImmediately",
				label: localeContent.SEND_IMMEDIATELY,
				isRequired: true,
				gridConfig: { md: 3 },
				fieldType: FieldTypes.SWITCH,
				defaultValue: true,
				hideOnEdit: true
			},
			{
				name: "forceNotify",
				label: localeContent.FORCE_NOTIFICATION,
				isRequired: true,
				gridConfig: { md: 3 },
				fieldType: FieldTypes.SWITCH,
				defaultValue: false,
				tooltip: localeContent.FORCE_NOTIFICATION_TOOLTIP,
				disableOnEdit: true
			},
			{
				name: "notificationDate",
				label: localeContent.NOTIFICATION_DATE_TIME,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.DATE_TIME_PICKER,
				isRequired: true,
				tooltip: localeContent.NOTIFICATION_DATE_TIME_TOOLTIP,
				renderIf: (formValues, initData, resourceId) =>
					resourceId || !formValues.sendImmediately,
				disableOnEdit: true,
				getValidationSchema: formContext =>
					Yup.date().when(["sendImmediately"], {
						is: sendImmediately => !sendImmediately,
						then: Yup.date().min(
							currentDateTime(formContext.userTimeZone),
							localeContent.NOTIFICATION_DATE_TIME_ERROR
						)
					}),
				transformInitData: (data, fieldName, fieldConfig, formContext) => {
					let fieldValue;
					if (data[fieldName]) {
						fieldValue = data[fieldName];
					} else if (formContext) {
						fieldValue = currentDateTime(formContext.userTimeZone);
					}
					return fieldValue && new Date(fieldValue);
				}
			},
			{
				name: "expires",
				label: localeContent.SET_EXPIRATION,
				gridConfig: { md: 4, lg: 3 },
				fieldType: FieldTypes.SWITCH,
				defaultValue: false,
				tooltip: localeContent.SET_EXPIRATION_TOOLTIP
			},
			{
				name: "expireDays",
				label: localeContent.EXPIRES_IN,
				isRequired: true,
				gridConfig: { lg: 3, md: 4 },
				fieldType: FieldTypes.NUMBER,
				fieldTypeOptions: {
					endAdornmentText: "days"
				},
				defaultValue: 7,
				renderIf: formValues => formValues.expires,
				validationSchema: Yup.number().when(["expires"], {
					is: expires => expires,
					then: Yup.number().min(1, localeContent.EXPIRATION_DAYS_ERROR)
				})
			}
		]
	},
	{
		key: "AUDIENCE",
		fields: [
			{
				name: "audienceSelectionType",
				label: localeContent.AUDIENCE_SELECTION_TYPE,
				gridConfig: { md: 6, lg: 4 },
				fieldType: {
					component: FormCheckboxMulti,
					baseValidation: Yup.object(),
					transformInitData: transformMultiCheckboxInitData,
					transformSubmitData: transformMultiCheckboxSubmitData
				},
				disableOnEdit: true,
				isRequired: true,
				optionsConfig: {
					options: AUDIENCE_SELECTION_TYPE_OPTIONS
				},
				validationSchema: multiCheckboxValidationSchema()
			},
			{
				name: COMPANY_AUDIENCE,
				label: localeContent.COMPANY_AUDIENCE_SELECTION,
				gridConfig: { md: 12, lg: 12 },
				fieldType: FieldTypes.MULTISELECT,
				disableOnEdit: true,
				tooltip: localeContent.AUDIENCE_SELECTION_TYPE_OPTION_TOOLTIP,
				optionsConfig: {
					requestEndpoint: "manage/companies"
				},
				renderIf: formValues =>
					formValues.audienceSelectionType[COMPANY_AUDIENCE],
				validationSchema: validationSchema(COMPANY_AUDIENCE),
				defaultValue: []
			},
			{
				name: ROLE_AUDIENCE,
				label: localeContent.ROLE_AUDIENCE_SELECTION,
				gridConfig: { md: 12, lg: 12 },
				fieldType: FieldTypes.MULTISELECT,
				disableOnEdit: true,
				tooltip: localeContent.AUDIENCE_SELECTION_TYPE_OPTION_TOOLTIP,
				optionsConfig: {
					requestEndpoint: "manage/user-roles"
				},
				renderIf: formValues => formValues.audienceSelectionType[ROLE_AUDIENCE],
				validationSchema: validationSchema(ROLE_AUDIENCE),
				defaultValue: []
			},
			{
				name: USER_AUDIENCE,
				label: localeContent.USER_AUDIENCE_SELECTION,
				gridConfig: { md: 12, lg: 12 },
				fieldType: FieldTypes.MULTISELECT,
				disableOnEdit: true,
				tooltip: localeContent.AUDIENCE_SELECTION_TYPE_OPTION_TOOLTIP,
				optionsConfig: {
					requestEndpoint: "manage/users"
				},
				renderIf: formValues => formValues.audienceSelectionType[USER_AUDIENCE],
				validationSchema: validationSchema(USER_AUDIENCE),
				defaultValue: []
			}
		]
	}
];

function NotificationsResourceScreen() {
	return (
		<CrudForm
			resourceEndpoint="manage/notification"
			resourceRoute="notifications"
			resourceString={localeContent.PAGE_TITLE}
			fieldsConfig={FIELDS_CONFIG}
			prepopulateCreate={false}
			preSubmit={(formValues, resourceId) => {
				return {
					...formValues,
					id: resourceId
				};
			}}
		/>
	);
}

export default NotificationsResourceScreen;
