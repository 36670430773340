import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";

const MenuOptionExternalLink = forwardRef((props, ref) => {
	const { href, onClick, label, accessibilityLabel, target, download } = props;
	return (
		<MenuItem
			ref={ref}
			href={href}
			color="inherit"
			component="a"
			onClick={onClick}
			target={target}
			download={download}
		>
			{label}
			{accessibilityLabel && (
				<span className="sr-only-text">({accessibilityLabel})</span>
			)}
		</MenuItem>
	);
});

MenuOptionExternalLink.propTypes = {
	href: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	label: PropTypes.string.isRequired,
	accessibilityLabel: PropTypes.string,
	target: PropTypes.string,
	download: PropTypes.string
};

MenuOptionExternalLink.defaultProps = {
	onClick: undefined,
	accessibilityLabel: undefined,
	target: undefined,
	download: undefined
};

export default MenuOptionExternalLink;
