import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import IconGetApp from "@mui/icons-material/GetApp";
import ButtonContainedDark from "../ButtonContainedDark/ButtonContainedDark";

function FormFieldButton(props) {
	const {
		crudFormResourceId,
		fieldTypeOptions: { buttonLabel, getLinkToRoute }
	} = props;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<ButtonContainedDark
					linkToRoute={getLinkToRoute(crudFormResourceId)}
					label={buttonLabel}
					StartIconComponent={IconGetApp}
					download="ads.txt"
					isExternal
				/>
			</Grid>
		</Grid>
	);
}

FormFieldButton.propTypes = {
	crudFormResourceId: PropTypes.string,
	fieldTypeOptions: PropTypes.shape({
		buttonLabel: PropTypes.string.isRequired,
		getLinkToRoute: PropTypes.func
	}).isRequired
};

FormFieldButton.defaultProps = {
	crudFormResourceId: null
};

export default FormFieldButton;
