import React from "react";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import FormTextField from "../FormTextField/FormTextField";

function FormFieldNumber(props) {
	const { id, name, label, required, fieldTypeOptions, errorOverride } = props;
	const {
		placeholder,
		min,
		max,
		step,
		endAdornmentText,
		startAdornmentText
	} = fieldTypeOptions;
	return (
		<FormTextField
			id={id}
			label={label}
			name={name}
			type="number"
			required={required}
			inputProps={{
				placeholder: placeholder || null,
				min: min || 0,
				max: max || null,
				step: step || 1
			}}
			errorOverride={errorOverride}
			// This is a material-ui prop so we have no control over this
			// eslint-disable-next-line react/jsx-no-duplicate-props
			InputProps={{
				endAdornment: endAdornmentText && (
					<InputAdornment position="end">{endAdornmentText}</InputAdornment>
				),
				startAdornment: startAdornmentText && (
					<InputAdornment position="start">{startAdornmentText}</InputAdornment>
				)
			}}
		/>
	);
}

FormFieldNumber.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	fieldTypeOptions: PropTypes.shape(),
	required: PropTypes.bool,
	errorOverride: PropTypes.string
};

FormFieldNumber.defaultProps = {
	fieldTypeOptions: {},
	required: false,
	errorOverride: null
};

export default FormFieldNumber;
