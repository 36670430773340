import React from "react";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";
import localeContent from "./localeContent";

const audienceColumnsConfig = [
	{ key: "description", name: commonLocaleContent.COLUMN_HEADER_DESCRIPTION },
	{
		key: "cpm",
		name: localeContent.COLUMN_ESTIMATED_CPM,
		align: "right"
	},
	{
		key: "potentialReach",
		name: localeContent.COLUMN_POTENTIAL_COOKIE_REACH,
		cellDataType: "number"
	},
	{
		key: "potentialDeviceReach",
		name: localeContent.COLUMN_POTENTIAL_DEVICE_REACH,
		cellDataType: "number"
	}
];

function AudienceIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.AUDIENCE}
			columnsConfig={audienceColumnsConfig}
			resourceEndpoint="manage/audiences"
			resourceRoute="audiences"
		/>
	);
}

export default AudienceIndex;
