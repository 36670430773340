import React, { useState } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import Tooltip from "../Tooltip/Tooltip";
import { EVENT_ENTER_KEY } from "../../config/constants";

const getApplicableHelperText = (showError, metaError) => {
	if (!showError) {
		return "";
	}
	if (Array.isArray(metaError)) {
		return metaError.join(" - ");
	}
	return metaError;
};

function FormFieldTags(props) {
	const { id, label, name, fieldTypeOptions, tooltip } = props;
	const {
		fixedOptions = [],
		placeholder,
		validOptionsList = [],
		chipUpdate
	} = fieldTypeOptions;
	const [field, meta, helpers] = useField(name);
	const showError = Boolean(meta.touched && meta.error);
	const helperText = getApplicableHelperText(showError, meta.error);
	const [showDialog, setShowDialog] = useState(false);
	const [chipValue, setChipValue] = useState(null);
	const [chipValueToChange, setChipValueToChange] = useState(null);

	const [tooltipOpen, setTooltipOpen] = useState(false);

	const handleChange = React.useCallback(
		(event, newValue) => {
			helpers.setValue([
				...fixedOptions,
				...newValue.filter(option => fixedOptions.indexOf(option) === -1)
			]);
			helpers.setTouched(true);
		},
		[helpers, fixedOptions]
	);

	const openEditBox = React.useCallback(val => {
		setShowDialog(true);
		setChipValue(val);
		setChipValueToChange(val);
		setTooltipOpen(false);
	}, []);

	const handleClose = () => setShowDialog(false);

	const onChipNameChange = () => {
		const itemIndexToUpdate = field.value.findIndex(
			item => item === chipValueToChange
		);

		const fieldValue = field.value;
		fieldValue[itemIndexToUpdate] = chipValue;
		helpers.setValue([
			...fixedOptions,
			...fieldValue.filter(option => fixedOptions.indexOf(option) === -1)
		]);
		helpers.setTouched(true);
		setShowDialog(false);
	};

	const handleTextFieldKeyUp = e => {
		if (e.key === EVENT_ENTER_KEY) {
			onChipNameChange();
		}
	};

	const formControl = (
		<Autocomplete
			multiple
			id={id}
			value={
				field.value
					? [
							...fixedOptions,
							...field.value.filter(
								option => fixedOptions.indexOf(option) === -1
							)
					  ]
					: [...fixedOptions]
			}
			onChange={handleChange}
			options={[]}
			freeSolo
			autoSelect // Creates a chip on blur if there is any user entry in the input that has not been selected using the enter key
			renderTags={(value, getTagProps) => {
				return value.map((option, index) => {
					const tagProps = getTagProps({ index });
					const isOptionDisabled = fixedOptions.indexOf(option) !== -1;
					if (isOptionDisabled) {
						tagProps.onDelete = null;
					}
					const isInvalid =
						validOptionsList.length > 0 && !validOptionsList.includes(option);
					return (
						<Chip
							key={option}
							variant="outlined"
							size="small"
							label={option}
							onClick={chipUpdate ? () => openEditBox(option) : null}
							sx={
								isInvalid
									? {
											color: "error.500",
											borderColor: "error.500",
											"& .MuiSvgIcon-root": {
												color: "error.500"
											}
									  }
									: undefined
							}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...tagProps}
							disabled={isOptionDisabled}
							data-testid="tags-chip"
						/>
					);
				});
			}}
			renderInput={params => (
				<TextField
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...params}
					variant="filled"
					name={name}
					label={label}
					helperText={helperText}
					placeholder={placeholder}
					error={showError}
				/>
			)}
		/>
	);

	return (
		<>
			<Dialog
				open={showDialog}
				onClose={handleClose}
				fullWidth
				onKeyUp={e => handleTextFieldKeyUp(e)}
			>
				<DialogTitle>Update Email Address</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Email Address"
						type="text"
						fullWidth
						variant="standard"
						value={chipValue}
						onChange={e => setChipValue(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={onChipNameChange} disabled={false}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
			{tooltip ? (
				<Tooltip
					open={tooltipOpen}
					onClose={() => setTooltipOpen(false)}
					onOpen={() => setTooltipOpen(true)}
					title={tooltip}
				>
					{formControl}
				</Tooltip>
			) : (
				formControl
			)}
		</>
	);
}

FormFieldTags.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	fieldTypeOptions: PropTypes.shape(),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
};

FormFieldTags.defaultProps = {
	fieldTypeOptions: { chipUpdate: false },
	tooltip: null
};
export default FormFieldTags;
