// Splits file content of the uploaded file by lines and generates an array of strings representing unique lines.
// Uniqueness check is case-insensitive
const uniqueElementsFromCsv = uploadedFileContent => {
	const splitList = uploadedFileContent
		.split(/[\r\n]+/)
		.map(item => item.toLowerCase().trim());
	return [...new Set(splitList)].filter(Boolean);
};

export default uniqueElementsFromCsv;
