import { isEmpty } from "./objectUtils";

const validateDomain = domain => {
	// Make sure we have a *.* format
	const domainStyle = /[a-z0-9]+\.[a-z0-9]+/i;
	// Make sure only the legal a-z, A-Z, 0-9, . and - characters are present.
	const characters = /[^a-zA-Z0-9\-.]/;
	return domainStyle.test(domain) && !characters.test(domain);
};

const validateDomainEmptyAsValid = domain => {
	if (isEmpty(domain)) return true;
	return validateDomain(domain);
};

const validateUTF8 = value => {
	// eslint-disable-next-line no-control-regex
	const validUTF8 = /(?![\x00-\x7F]|[\xC0-\xDF][\x80-\xBF]|[\xE0-\xEF][\x80-\xBF]{2}|[\xF0-\xF7][\x80-\xBF]{3})./g;
	return !validUTF8.test(value);
};

const containsNonAlphaNumericCharaters = val => {
	// Approached from https://stackoverflow.com/questions/32311081/check-for-special-characters-in-string
	// eslint-disable-next-line no-useless-escape
	const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

	return format.test(val);
};

const validationPatternAlphanumericWithUnderscoreDashAndPeriod = /^[a-zA-Z0-9._-]+$/;

const isValidAlphaNumericCharactersAndUTF8 = val =>
	validateUTF8(val) && !containsNonAlphaNumericCharaters(val);

const isSubsetOfInheritedValues = (val, inherited) => {
	return isEmpty(inherited) || inherited.some(id => val === id);
};

const getTextFromHtml = content =>
	content.replace(/<(.|\n)*?>/g, "").trim().length === 0;

const isZeroOrUndefinedNumber = val => val === 0 || val === undefined;

export default {
	validateDomain,
	validateUTF8,
	containsNonAlphaNumericCharaters,
	validationPatternAlphanumericWithUnderscoreDashAndPeriod,
	isValidAlphaNumericCharactersAndUTF8,
	isSubsetOfInheritedValues,
	validateDomainEmptyAsValid,
	getTextFromHtml,
	isZeroOrUndefinedNumber
};
