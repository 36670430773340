import React, { useContext } from "react";
import * as Yup from "yup";
import { format } from "date-fns";

import { isEmpty } from "../../utils/objectUtils";
import commonLocaleContent from "../commonLocaleContent";
import CrudForm from "../../components/CrudForm/CrudForm";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import localeContent from "./localeContent";
import FilterReports, {
	transformFilterReportInitData,
	transformFilterReportSubmit
} from "../../components/FilterReports/FilterReports";
import { REPORT_TYPE_OPTIONS, UTC_TIME_ZONE } from "../../config/constants";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";
import { SERVER_DATE_FORMAT } from "../../components/FormFieldDateTimePicker/FormFieldDateTimePicker";
import { METRICS_DEFAULT_VALUES } from "../../config/metricsList";
import { TIMEZONE_OPTIONS } from "../../utils/timeZoneUtils";

const LOOKBACK_OPTIONS = [
	{ value: "WeekToDate", label: "Week to Date" },
	{ value: "LastWeek", label: "Prior Week" },
	{ value: "MonthToDate", label: "Month to Date" },
	{ value: "LastMonth", label: "Prior Month" },
	{ value: "LastNDays", label: "Custom" }
];
const REPEATS_OPTIONS = [
	{ value: "Weekly", label: "Weekly" },
	{ value: "Daily", label: "Daily" },
	{ value: "Monthly", label: "Monthly" }
];

const DAY_OF_MONTH_OPTIONS = [
	{ value: 1, label: 1 },
	{ value: 2, label: 2 },
	{ value: 3, label: 3 },
	{ value: 4, label: 4 },
	{ value: 5, label: 5 },
	{ value: 6, label: 6 },
	{ value: 7, label: 7 },
	{ value: 8, label: 8 },
	{ value: 9, label: 9 },
	{ value: 10, label: 10 },
	{ value: 11, label: 11 },
	{ value: 12, label: 12 },
	{ value: 13, label: 13 },
	{ value: 14, label: 14 },
	{ value: 15, label: 15 },
	{ value: 16, label: 16 },
	{ value: 17, label: 17 },
	{ value: 18, label: 18 },
	{ value: 19, label: 19 },
	{ value: 20, label: 20 },
	{ value: 21, label: 21 },
	{ value: 22, label: 22 },
	{ value: 23, label: 23 },
	{ value: 24, label: 24 },
	{ value: 25, label: 25 },
	{ value: 26, label: 26 },
	{ value: 27, label: 27 },
	{ value: 28, label: 28 },
	{ value: 29, label: 29 },
	{ value: 30, label: 30 },
	{ value: 31, label: 31 }
];

const SEND_TO_USER = "sendToUser";
const LOOK_BACK = "lookBack";
const REPEATS = "repeats";
const HOUR_DIMENSION_KEY = "hour";

const getDefaultNetworkMetrics = (initMetrics, userMetrics) => {
	if (initMetrics) {
		return initMetrics;
	}
	if (userMetrics && userMetrics.network?.length) {
		return userMetrics.network;
	}
	return METRICS_DEFAULT_VALUES.network;
};

function needsDataOlderThan31Days(fieldValues) {
	return (
		fieldValues.lookBack === "LastMonth" ||
		(fieldValues.lookBack === "LastNDays" && fieldValues.lookBackNumDays > 31)
	);
}

const tabsConfig = authenticatedUserSettings => [
	{
		label: commonLocaleContent.TAB_LABEL_SETTINGS,
		key: "SETTINGS",
		fieldsConfig: [
			{
				key: "BASIC_SETTINGS",
				fields: [
					{
						label: localeContent.FILE_NAME.LABEL,
						name: "fileName",
						gridConfig: { md: 8 },
						isRequired: true,
						tooltip: localeContent.FILE_NAME.TOOLTIP,
						validationSchema: Yup.string().matches(
							"^[A-Za-z0-9\\-_]+$",
							localeContent.FILE_NAME.VALIDATION
						)
					},
					{
						label: localeContent.TIMEZONE.LABEL,
						name: "timeZone",
						gridConfig: { md: 4 },
						tooltip: localeContent.TIMEZONE.TOOLTIP,
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: TIMEZONE_OPTIONS
						},
						defaultValue: UTC_TIME_ZONE,
						readOnlyIf: fieldValues => needsDataOlderThan31Days(fieldValues),
						dependentFieldValueSetter: (fieldValues, setFieldValue) => {
							if (needsDataOlderThan31Days(fieldValues)) {
								setFieldValue(UTC_TIME_ZONE);
							}
						}
					},
					{
						label: localeContent.LOOK_BACK_PERIOD.LABEL,
						name: LOOK_BACK,
						gridConfig: { md: 4, lg: 3 },
						tooltip: localeContent.LOOK_BACK_PERIOD.TOOLTIP,
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: LOOKBACK_OPTIONS
						},
						defaultValue: "WeekToDate"
					},
					{
						label: localeContent.CUSTOM_RANGE.LABEL,
						name: "lookBackNumDays",
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.NUMBER,
						fieldTypeOptions: {
							startAdornmentText: "Last",
							endAdornmentText: "Days"
						},
						defaultValue: 7,
						renderIf: formValues => formValues.lookBack === "LastNDays",
						validationSchema: Yup.number()
							.when(LOOK_BACK, {
								is: lookBack => lookBack === "LastNDays",
								then: Yup.number().required(
									commonLocaleContent.REQUIRED_FIELD_WARNING
								)
							})
							.min(1, localeContent.CUSTOM_RANGE.VALIDATION)
							.max(90, localeContent.CUSTOM_RANGE.VALIDATION)
					},
					{
						label: localeContent.REPEATS.LABEL,
						name: REPEATS,
						gridConfig: { md: 4, lg: 3 },
						tooltip: localeContent.REPEATS.TOOLTIP,
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: REPEATS_OPTIONS
						},
						defaultValue: "Weekly"
					},
					{
						label: localeContent.REPEATS_ON.LABEL,
						name: "days",
						gridConfig: { md: 4, lg: 3 },
						tooltip: localeContent.REPEATS_ON.TOOLTIP,
						fieldType: FieldTypes.MULTISELECT,
						optionsConfig: {
							options: localeContent.DAYS_OPTIONS,
							sortOptionsByName: false
						},
						renderIf: formValues => formValues.repeats === "Weekly",
						validationSchema: Yup.array().when(REPEATS, {
							is: repeats => {
								return repeats === "Weekly";
							},
							then: schema =>
								schema.min(1, commonLocaleContent.REQUIRED_FIELD_WARNING)
						}),
						defaultValue: []
					},
					{
						label: localeContent.DAY_OF_MONTH.LABEL,
						name: "dayOfMonth",
						gridConfig: { md: 4, lg: 3 },
						tooltip: localeContent.DAY_OF_MONTH.TOOLTIP,
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: DAY_OF_MONTH_OPTIONS
						},
						renderIf: formValues => formValues.repeats === "Monthly"
					},
					{
						name: "includeHeader",
						label: localeContent.INCLUDE_HEADER.LABEL,
						tooltip: localeContent.INCLUDE_HEADER.TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false,
						readOnlyIf: fieldValues => needsDataOlderThan31Days(fieldValues),
						dependentFieldValueSetter: (fieldValues, setFieldValue) => {
							if (needsDataOlderThan31Days(fieldValues)) {
								setFieldValue(true);
							}
						}
					}
				]
			},
			{
				key: "DATE_RANGE",
				fields: [
					{
						name: "startDate",
						label: localeContent.START_DATE.LABEL,
						tooltip: localeContent.START_DATE.TOOLTIP,
						gridConfig: { md: 6 },
						fieldType: FieldTypes.DATE_TIME_PICKER,
						isRequired: true,
						fieldTypeOptions: {
							showTodayButton: true
						},
						defaultValue: format(new Date(), SERVER_DATE_FORMAT)
					},
					{
						name: "endDate",
						label: localeContent.END_DATE.LABEL,
						tooltip: localeContent.END_DATE.TOOLTIP,
						gridConfig: { md: 6 },
						fieldType: FieldTypes.DATE_TIME_PICKER,
						fieldTypeOptions: {
							clearable: true
						},
						defaultValue: null
					}
				]
			},
			{
				key: "BASIC_SETTINGS_EMAIL",
				fields: [
					{
						label: localeContent.SEND_TO_ME.LABEL,
						name: SEND_TO_USER,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.CHECKBOX_SINGLE,
						tooltip: localeContent.SEND_TO_ME.TOOLTIP,
						defaultValue: true
					},
					{
						label: localeContent.ADD_RECIPIENTS.LABEL,
						name: "recipients",
						fieldType: FieldTypes.TAGS,
						fieldTypeOptions: { chipUpdate: true },
						gridConfig: { md: 8, lg: 6 },
						tooltip: localeContent.ADD_RECIPIENTS.TOOLTIP,
						validationSchema: Yup.array()
							.of(
								Yup.lazy(value =>
									Yup.string().email(localeContent.VALID_EMAIL_WARNING(value))
								)
							)
							.when(SEND_TO_USER, {
								is: sendToUser => {
									return sendToUser === false;
								},
								then: baseSchema =>
									baseSchema.min(1, localeContent.ADD_RECIPIENTS.VALIDATION)
							}),
						defaultValue: []
					}
				]
			}
		]
	},
	{
		label: localeContent.TAB_LABEL_DATA,
		key: "DATA",
		componentConfigTop: {
			name: "filters",
			queryParamKey: "filters",
			Component: FilterReports,
			transformInitData: transformFilterReportInitData,
			transformSubmitData: transformFilterReportSubmit
		},
		fieldsConfig: [
			{
				key: "DATA_BASIC_SETTINGS",
				fields: [
					{
						label: localeContent.REPORT_TYPE.LABEL,
						name: "reportType",
						queryParamKey: "reportType",
						gridConfig: { md: 4, lg: 3 },
						tooltip: localeContent.REPORT_TYPE.TOOLTIP,
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: REPORT_TYPE_OPTIONS
						},
						defaultValue: "network",
						disableOnEdit: true
					},
					{
						name: "metrics",
						queryParamKey: "metrics",
						label: localeContent.METRICS.LABEL,
						gridConfig: { md: 8, lg: 9 },
						fieldType: FieldTypes.METRICS,
						tooltip: localeContent.METRICS.TOOLTIP,
						transformInitData: initData =>
							getDefaultNetworkMetrics(
								initData.metrics,
								authenticatedUserSettings.defaultUserMetrics
							)
					},
					{
						name: "slice",
						queryParamKey: "dimensions",
						label: localeContent.DIMENSIONS.LABEL,
						fieldType: FieldTypes.DIMENSIONS,
						fieldTypeOptions: {
							getDisabledDimensions: fieldValues =>
								needsDataOlderThan31Days(fieldValues)
									? [HOUR_DIMENSION_KEY]
									: []
						},
						transformInitData: (initData, fieldName) => ({
							isEnabled: !isEmpty(initData[fieldName]),
							selectedIds: initData.slice
								? initData.slice
								: authenticatedUserSettings.defaultUserDimensions?.network,
							isEdit: !!initData.id
						})
					}
				]
			}
		]
	}
];

function ScheduledReportsResource() {
	const authenticatedUserSettings = useContext(
		AuthenticatedUserSettingsContext
	);
	return (
		<CrudForm
			resourceString={commonLocaleContent.SCHEDULED_REPORT}
			tabsConfig={tabsConfig(authenticatedUserSettings)}
			resourceEndpoint="reports/scheduled-reports"
			resourceRoute="scheduled-reports"
			entityNameKey="fileName"
			preSubmit={formValues => {
				const submissionValues = { ...formValues };
				submissionValues.enabled = true;
				return submissionValues;
			}}
		/>
	);
}

export default ScheduledReportsResource;
