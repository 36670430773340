import { useContext, useEffect, useReducer, useCallback, useRef } from "react";
import { FetchContext } from "../context/FetchContext";
import { getAuthenticatedEndpoint } from "../utils/objectUtils";
import {
	Operations as useResourceOperations,
	getApiError
} from "./useResource";

// Re-export Operations from useResource for convenience. Eventually we may want to combine the two hooks somehow and refactor
export const Operations = useResourceOperations;

const dataFetchReducer = (state, action) => {
	switch (action.type) {
		case "REQUEST_INIT":
			return {
				...state,
				isLoading: true,
				data: action.payload.preserveDataOnExecute ? state.data : null,
				error: null
			};
		case "REQUEST_SUCCESS":
			return {
				...state,
				isLoading: false,
				data: action.payload || true
			};
		case "REQUEST_FAILURE":
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		default:
			throw new Error();
	}
};

/**
 * Hook that returns a function that can be called on demand to set the next request
 * @param {String} baseResourceEndpoint the string path to an API resource (domain and authenticated endpoint prefix are added automatically)
 * @param	{String} operation the request Operation to perform
 */
export const useResourceAsync = (
	baseResourceEndpoint,
	operation,
	options = {}
) => {
	const fetchContext = useContext(FetchContext);
	const {
		preserveDataOnExecute,
		transformResponse,
		customStartPathEndPoint
	} = options;
	const [state, dispatch] = useReducer(dataFetchReducer, {
		isLoading: false,
		error: null,
		data: null
	});

	const stableTransformResponse = useCallback(
		data => {
			if (transformResponse) {
				return transformResponse(data);
			}
			return data;
		},
		[transformResponse]
	);

	// We want to cancel state changes when components unmount due to route changes / dynamic rendering
	const cancelRequests = useRef();
	useEffect(() => {
		cancelRequests.current = false;
		return () => {
			cancelRequests.current = true;
		};
	}, []);

	const execute = useCallback(
		async (request = {}) => {
			try {
				dispatch({ type: "REQUEST_INIT", payload: { preserveDataOnExecute } });
				const urlCreator = customStartPathEndPoint || getAuthenticatedEndpoint;
				const result = await fetchContext.authAxios({
					method: operation.method,
					url: urlCreator(
						operation.getResourceEndpoint(baseResourceEndpoint, request)
					),
					data: operation.getRequestBody(request),
					params: operation.getRequestParams(request)
				});
				if (!cancelRequests.current) {
					const { data } = result;
					const transformedData = stableTransformResponse(data);
					dispatch({ type: "REQUEST_SUCCESS", payload: transformedData });
					return { data: transformedData, isSuccess: true };
				}
			} catch (err) {
				if (!cancelRequests.current) {
					const error = getApiError(err);
					dispatch({ type: "REQUEST_FAILURE", payload: error });
					return { error };
				}
			}
			// This will only happen if the request has been cancelled
			return {};
		},
		[
			preserveDataOnExecute,
			fetchContext,
			operation,
			baseResourceEndpoint,
			stableTransformResponse,
			customStartPathEndPoint
		]
	);

	const { data, error, isLoading } = state;
	return {
		execute,
		data,
		error,
		isLoading
	};
};

export default useResourceAsync;
