import React from "react";
import PropTypes from "prop-types";
import { addDays, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import BasicLineChart from "./BasicLineChart";
import { UTC_TIME_ZONE } from "../../../config/constants";

function DailyChart(props) {
	const {
		metricsData,
		isLoadingMetricsData,
		startDateTimestamp,
		reportType,
		series
	} = props;

	const startDate = utcToZonedTime(startDateTimestamp, UTC_TIME_ZONE);

	return (
		<BasicLineChart
			metricsData={metricsData}
			isLoadingMetricsData={isLoadingMetricsData}
			minTickGap={300}
			getSeriesPointLabel={index => format(addDays(startDate, index), "MM/dd")}
			reportType={reportType}
			seriesDefaultData={series}
		/>
	);
}

DailyChart.propTypes = {
	startDateTimestamp: PropTypes.number,
	metricsData: PropTypes.arrayOf(PropTypes.shape()),
	isLoadingMetricsData: PropTypes.bool.isRequired,
	reportType: PropTypes.string.isRequired,
	series: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

DailyChart.defaultProps = {
	startDateTimestamp: null,
	metricsData: null
};

export default DailyChart;
