import React from "react";
import CrudForm from "../../components/CrudForm/CrudForm";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import commonFieldOptions from "../commonFieldOptions";
import FieldTypes from "../../components/CrudForm/FieldTypes";

const FIELDS_CONFIG = [
	{
		key: "BASIC_SETTINGS",
		fields: [
			{
				name: "name",
				label: localeContent.TRACKER_NAME.LABEL,
				gridConfig: { md: 8 },
				isRequired: true
			},
			{
				name: "status",
				label: commonLocaleContent.FIELD_LABEL_STATUS,
				gridConfig: { md: 4 },
				fieldType: FieldTypes.RADIO,
				optionsConfig: {
					options: commonFieldOptions.mapEnumToFieldOptions(
						commonFieldOptions.STATUS_ENUM
					)
				},
				defaultValue: commonFieldOptions.STATUS_ENUM.active.value
			},
			{
				name: "description",
				label: localeContent.TRACKER_LABEL.LABEL,
				tooltip: localeContent.TRACKER_LABEL.TOOLTIP,
				gridConfig: { md: 8 },
				isRequired: true
			}
		]
	},
	{
		key: "TYPE_SETTINGS",
		fields: [
			{
				name: "type",
				label: localeContent.TRACKER_TYPE.LABEL,
				tooltip: localeContent.TRACKER_TYPE.TOOLTIP,
				gridConfig: { md: 4, lg: 3 },
				fieldType: FieldTypes.SELECT,
				optionsConfig: {
					options: [
						{
							value: "IMG",
							label: localeContent.TRACKER_TYPE.OPTION_LABELS.BEACON
						},
						{
							value: "JS",
							label: localeContent.TRACKER_TYPE.OPTION_LABELS.JAVASCRIPT
						}
					]
				},
				defaultValue: "IMG",
				isRequired: true
			},
			{
				name: "event",
				label: localeContent.EVENT.LABEL,
				tooltip: localeContent.EVENT.TOOLTIP,
				gridConfig: { md: 4, lg: 3 },
				fieldType: FieldTypes.SELECT,
				optionsConfig: {
					options: [
						{
							value: "opportunity",
							label: localeContent.EVENT.OPTION_LABELS.OPPORTUNITY
						},
						{
							value: "impression",
							label: localeContent.EVENT.OPTION_LABELS.IMPRESSION
						}
					]
				},
				defaultValue: "impression",
				isRequired: true
			},
			{
				name: "url",
				label: localeContent.TRACKER_URL.LABEL,
				fieldType: FieldTypes.MACRO_TEXT,
				isRequired: true
			}
		]
	},
	{
		key: "TRACKER_SETTINGS",
		fields: [
			{
				name: "placementEnabled",
				label: localeContent.PLACEMENT_TRACKER.LABEL,
				tooltip: localeContent.PLACEMENT_TRACKER.TOOLTIP,
				gridConfig: { md: 3 },
				fieldType: FieldTypes.SWITCH,
				defaultValue: false
			},
			{
				name: "placementDefault",
				label: localeContent.DEFAULT_PLACEMENT_TRACKER.LABEL,
				tooltip: localeContent.DEFAULT_PLACEMENT_TRACKER.TOOLTIP,
				gridConfig: { md: 3 },
				renderIf: formValues => formValues.placementEnabled,
				fieldType: FieldTypes.SWITCH,
				defaultValue: false
			},
			{
				name: "mediaEnabled",
				label: localeContent.MEDIA_TRACKER.LABEL,
				tooltip: localeContent.MEDIA_TRACKER.TOOLTIP,
				disableIf: formValues => formValues.event === "opportunity",
				dependentFieldValueSetter: (formValues, setFieldValue) => {
					if (formValues.mediaEnabled && formValues.event === "opportunity") {
						setFieldValue(false);
					}
				},
				getGridConfig: formValues => ({
					md: 3,
					...(formValues.placementEnabled
						? { offset: { md: 6 } }
						: { offset: { md: 9 } })
				}),
				fieldType: FieldTypes.SWITCH,
				defaultValue: false
			},
			{
				name: "mediaDefault",
				label: localeContent.DEFAULT_MEDIA_TRACKER.LABEL,
				tooltip: localeContent.DEFAULT_MEDIA_TRACKER.TOOLTIP,
				gridConfig: { md: 3 },
				renderIf: formValues => formValues.mediaEnabled,
				fieldType: FieldTypes.SWITCH,
				dependentFieldValueSetter: (formValues, setFieldValue) => {
					if (formValues.mediaDefault && formValues.event === "opportunity") {
						setFieldValue(false);
					}
				},
				defaultValue: false
			}
		]
	}
];

function TrackerResource() {
	return (
		<CrudForm
			resourceEndpoint="manage/trackers"
			resourceRoute="trackers"
			resourceString={commonLocaleContent.TRACKER}
			fieldsConfig={FIELDS_CONFIG}
		/>
	);
}

export default TrackerResource;
