import React from "react";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";

const userColumnsConfig = [
	{
		key: COLUMN_KEY_STATUS,
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		cellDataType: "upperCase"
	}
];

function CompanyIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.COMPANY}
			columnsConfig={userColumnsConfig}
			resourceEndpoint="manage/companies"
			resourceRoute="companies"
			resourceNamePlural={commonLocaleContent.COMPANIES}
		/>
	);
}

export default CompanyIndex;
