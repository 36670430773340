import { useContext, useEffect, useReducer, useCallback, useRef } from "react";
import { FetchContext } from "../context/FetchContext";
import { getAuthenticatedEndpoint } from "../utils/endpointUtils";

const UNABLE_TO_UPLOAD_FILE_WARNING = "Unable to upload file";

const dataFetchReducer = (state, action) => {
	switch (action.type) {
		case "REQUEST_INIT":
			return {
				...state,
				isSubmitting: true,
				data: null,
				error: null
			};
		case "REQUEST_SUCCESS":
			return {
				...state,
				isSubmitting: false,
				data: action.payload || true
			};
		case "REQUEST_FAILURE":
			return {
				...state,
				isSubmitting: false,
				error: action.payload
			};
		default:
			throw new Error();
	}
};

const getApiError = axiosError => {
	if (!axiosError || !axiosError.response) {
		return UNABLE_TO_UPLOAD_FILE_WARNING;
	}
	const { detail, message } = axiosError.response.data;
	return { detail, message };
};

const getRequestBody = formValues => {
	const formData = new FormData();
	Object.entries(formValues).forEach(([key, value]) =>
		formData.append(key, value)
	);
	return formData;
};

/**
 * Hook used to upload a multipart file as part of FormData
 * @param {String} requestEndpoint the string path to an API resource (domain and authenticated endpoint prefix are added automatically)
 */
const useFileUpload = requestEndpoint => {
	const fetchContext = useContext(FetchContext);

	const [state, dispatch] = useReducer(dataFetchReducer, {
		isSubmitting: false,
		error: null,
		data: null
	});

	// We want to cancel state changes when components unmount due to route changes / dynamic rendering
	const cancelRequests = useRef();
	useEffect(() => {
		cancelRequests.current = false;
		return () => {
			cancelRequests.current = true;
		};
	}, []);

	const uploadFileAsync = useCallback(
		async formValues => {
			try {
				dispatch({ type: "REQUEST_INIT" });
				const url = getAuthenticatedEndpoint(requestEndpoint);
				const config = {
					headers: {
						"content-type": "multipart/form-data"
					}
				};
				const result = await fetchContext.authAxios.post(
					url,
					getRequestBody(formValues),
					config
				);
				if (!cancelRequests.current) {
					const { data } = result;
					dispatch({ type: "REQUEST_SUCCESS", payload: data });
					return { data };
				}
			} catch (err) {
				if (!cancelRequests.current) {
					const error = getApiError(err);
					dispatch({ type: "REQUEST_FAILURE", payload: error });
					return { error };
				}
			}
			// This will only happen if the request has been cancelled
			return {};
		},
		[fetchContext, requestEndpoint]
	);

	const { data, error, isSubmitting } = state;
	return {
		uploadFileAsync,
		data,
		error,
		isSubmitting
	};
};

export default useFileUpload;
