import React from "react";
import PropTypes from "prop-types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DimensionChip from "./DimensionChip";

function SortableDimensionChip(props) {
	const { sortableId, label, onDelete } = props;

	const {
		attributes,
		listeners,
		isDragging,
		setNodeRef,
		transform,
		transition
	} = useSortable({ id: sortableId });

	const style = {
		opacity: isDragging ? 0.5 : undefined,
		transform: CSS.Translate.toString(transform),
		transition
	};

	return (
		<DimensionChip
			ref={setNodeRef}
			style={style}
			attributes={attributes}
			listeners={listeners}
			label={label}
			onDelete={onDelete}
		/>
	);
}

SortableDimensionChip.propTypes = {
	sortableId: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired
};

export default SortableDimensionChip;
