export default {
	SHARE_RESOURCE_MODAL: {
		LABEL: "Email",
		FIELD_NAME: "Email",
		HELPER_TEXT: "Separate each email address with a comma"
	},
	EMPTY_SHARE_FIELD: "At least one recipient is required.",
	MAX_LIMIT_RECIPIENT: "Must select less than ten recipients.",
	INVALID_EMAIL: recipient => `${recipient} is not a valid email.`,
	SHARE_REPORT_SUCCESS_MESSAGE: "Your report has been sent!",
	SHARE_REPORT_FAILURE_MESSAGE: "Failed to send report.",
	ACTIONS_BUTTON_LABEL: "actions"
};
