import React, { useCallback, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";

import { isEmpty } from "../../utils/objectUtils";
import lc from "./localeContent";
import Yup from "../../config/yupConfig";
import { useResourceAsync, Operations } from "../../hooks/useResourceAsync";
import Dimensions, { KEY_IS_ENABLED, KEY_SELECTED_IDS } from "./Dimensions";
import useSkipInitChangeWatcher from "../../hooks/useSkipInitChangeWatcher";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";
import useParsedQueryParams from "../../hooks/useParsedQueryParams";

// Used to convert the object map to an array of options objects
const transformDimensionsResponse = data =>
	Object.entries(data).map(([key, value]) => ({
		id: key,
		label: value
	}));

export const transformInitData = (data, fieldName) => {
	const fieldData = data[fieldName] || [];

	return {
		[KEY_IS_ENABLED]: !isEmpty(fieldData),
		[KEY_SELECTED_IDS]: fieldData
	};
};

export const transformSubmitData = (data, fieldName) => {
	const { [KEY_IS_ENABLED]: enabled, [KEY_SELECTED_IDS]: selectedIds } = data;
	if (!enabled) {
		return {
			[fieldName]: []
		};
	}
	return { [fieldName]: selectedIds };
};

export const validationSchema = Yup.object({
	[KEY_SELECTED_IDS]: Yup.array().when(KEY_IS_ENABLED, {
		is: true,
		then: schema => schema.min(1, lc.ONE_ITEM_REQUIRED_WARNING)
	})
});

function CrudFormFieldDimensions(props) {
	const { id, label, name, fieldTypeOptions } = props;
	const queryParams = useParsedQueryParams();
	const [field, , helpers] = useField(name);
	const {
		values: { reportType }
	} = useFormikContext();
	const [dimensionsChosen, setDimensionsChosen] = useState(false);
	const authenticatedUserSettings = useContext(
		AuthenticatedUserSettingsContext
	);
	const {
		data: dimensionOptions,
		execute: requestDimensionOptions
	} = useResourceAsync(
		`reports/scheduled-reports/${reportType.toUpperCase()}/slices`,
		Operations.LIST,
		{
			transformResponse: transformDimensionsResponse
		}
	);
	useEffect(() => {
		if (reportType) {
			requestDimensionOptions();
		}
	}, [reportType, requestDimensionOptions]);

	const [selectedValuesField, selectedValuesMeta] = useField(
		`${name}.${KEY_SELECTED_IDS}`
	);
	// Error handling for selected ids
	const selectedItemsError =
		selectedValuesMeta.touched && selectedValuesMeta.error
			? selectedValuesMeta.error
			: null;

	const selectedIds = selectedValuesField.value;
	const setSelectedIds = useCallback(
		items => {
			helpers.setValue({
				...field.value,
				[KEY_SELECTED_IDS]: items
			});
		},
		[helpers, field.value]
	);

	// Init data load sets the report type, which counts as the first "change" detected by the useEffect watcher. We need to take certain actions only on subsequent, post-init user changes to the form
	const [isReportTypeChangedByUser, dispatch] = useSkipInitChangeWatcher();
	useEffect(() => {
		if (reportType) {
			dispatch({ type: "VALUE_CHANGED" });
		}
	}, [reportType, dispatch]);

	useEffect(() => {
		if (!dimensionsChosen && authenticatedUserSettings) {
			const { dimensions } = queryParams;
			helpers.setValue({
				...field.value,
				[KEY_SELECTED_IDS]:
					field.value.isEdit || dimensions?.length
						? field.value.selectedIds
						: authenticatedUserSettings.defaultUserDimensions[reportType]
			});
			setDimensionsChosen(true);
		}
	}, [
		helpers,
		reportType,
		dimensionsChosen,
		authenticatedUserSettings,
		field.value,
		queryParams
	]);

	useEffect(() => {
		if (isReportTypeChangedByUser) {
			setDimensionsChosen(false);
			helpers.setValue({ ...field.value, [KEY_SELECTED_IDS]: [] });
			dispatch({ type: "RESET" });
		}
	}, [isReportTypeChangedByUser, helpers, field.value, dispatch]);

	const isEnabled = field.value ? field.value[KEY_IS_ENABLED] : false;
	return (
		<Dimensions
			id={id}
			name={name}
			label={label}
			isEnabled={isEnabled}
			options={dimensionOptions}
			selectedIds={selectedIds}
			setSelectedIds={setSelectedIds}
			selectedItemsError={selectedItemsError}
			fieldTypeOptions={fieldTypeOptions}
		/>
	);
}

CrudFormFieldDimensions.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	fieldTypeOptions: PropTypes.shape({
		getDisabledDimensions: PropTypes.func
	})
};

CrudFormFieldDimensions.defaultProps = {
	fieldTypeOptions: {}
};

export default CrudFormFieldDimensions;
