export default {
	DAY_PARTING_LABEL: "Dayparting",
	DAY_PARTING_TOOLTIP:
		"If a day has been selected, but hours for that day have not been specified, then delivery will occur for the entire day",
	HOURS_LABEL: "Hours",
	SUNDAY_LABEL: "Sunday",
	MONDAY_LABEL: "Monday",
	TUESDAY_LABEL: "Tuesday",
	WEDNESDAY_LABEL: "Wednesday",
	THURSDAY_LABEL: "Thursday",
	FRIDAY_LABEL: "Friday",
	SATURDAY_LABEL: "Saturday",
	AM_LABEL: "am",
	PM_LABEL: "pm",
	VALIDATION_MESSAGES: {
		MIN_ONE_DAY_SELECT:
			"If Dayparting is enabled at least one day must be selected."
	}
};
