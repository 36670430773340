import React, { useContext } from "react";
import PropTypes from "prop-types";

import { OpenInNew } from "@mui/icons-material";

import { stringify } from "qs";
import ButtonContainedDark from "../ButtonContainedDark/ButtonContainedDark";
import lc from "./localeContent";
import { getDateRangeByTimezone } from "../../utils/dateUtils";
import {
	DATE_RANGE_KEY,
	FILTER_KEY,
	REPORT_TYPE_KEY,
	TIME_ZONE_KEY
} from "../Reporting/constants";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import { AuthContext } from "../../context/AuthContext";

function FormButttonViewReport(props) {
	const { viewReportConfig, formContext, initData, formValues } = props;
	const {
		label = lc.VIEW_REPORT_LABEL,
		tooltip = lc.VIEW_REPORT_TOOLTIP,
		reportType,
		filterKeyValuesGetter,
		permissionsRequired
	} = viewReportConfig;

	const { userTimeZone } = formContext;
	const authorizationContext = useContext(AuthorizationContext);

	const hasPermission =
		permissionsRequired &&
		authorizationContext &&
		permissionsRequired.every(permission =>
			authorizationContext.hasPermission(permission)
		);

	// Get the date range for today in the user's timezone
	const dateRange = getDateRangeByTimezone("today", userTimeZone);

	const filterKeyValues =
		initData &&
		filterKeyValuesGetter({
			formContext,
			initData,
			formValues
		});

	const nullSafeFilterKeyValues = Object.entries(filterKeyValues || {});

	// generate the report filters base on resource form config viewReportConfig
	const reportFilters = nullSafeFilterKeyValues.reduce(
		(acc, [key, value], index) => {
			const filter = `${FILTER_KEY}[${index}]`;
			const filterDimension = `${filter}[dimension]=${key}`;
			const filterComparation = `${filter}[comparator]==`;
			const filterType = `${filter}[type]=alpha_multiple`;
			if (Array.isArray(value)) {
				const filterValue = value.reduce(
					(accValue, val, indexValue) =>
						`&${accValue}&${filter}[value][${indexValue}]=${val}`,
					""
				);
				return `${acc}&${filterDimension}&${filterComparation}&${filterType}${filterValue}`;
			}
			const filterValue = `${filter}[value][0]=${value}`;
			return `${acc}&${filterDimension}&${filterComparation}&${filterType}&${filterValue}`;
		},
		""
	);
	const authContext = useContext(AuthContext);
	const companyId = authContext?.companyId;

	// generate the report config string without filters
	const reportConfig = {
		[REPORT_TYPE_KEY]: reportType,
		[TIME_ZONE_KEY]: userTimeZone,
		[DATE_RANGE_KEY]: [dateRange.startDate, dateRange.endDate],
		companyId
	};

	const reportConfigString = hasPermission ? stringify(reportConfig) : "";

	const url = `/dashboard/reporting?${reportConfigString}${reportFilters}`;

	const canShowButton = hasPermission && !formContext.isCreateResource;

	return (
		(canShowButton && (
			<ButtonContainedDark
				sx={{
					color: "primary.900",
					"&:hover": {
						color: "primary.800"
					}
				}}
				label={label}
				linkToRoute={url}
				component="a"
				variant="text"
				type="button"
				StartIconComponent={OpenInNew}
				target="_blank"
				isExternal
				tooltip={tooltip}
			/>
		)) ||
		!canShowButton
	);
}

FormButttonViewReport.propTypes = {
	formValues: PropTypes.shape().isRequired,
	formContext: PropTypes.shape().isRequired,
	initData: PropTypes.shape(),
	viewReportConfig: PropTypes.shape()
};

FormButttonViewReport.defaultProps = {
	viewReportConfig: {},
	initData: {}
};
export default FormButttonViewReport;
