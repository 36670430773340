import React from "react";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { IconButton, ListItemSecondaryAction } from "@mui/material";
import lc from "./localeContent";

import BidderSeatListItem from "./BidderSeatListItem";

export const EXPANSION_ICON_TEST_ID = "bidder-list-item-expansion-icon";
export const INVALID_BIDDER_TEST_ID = "invalid-bidder";

function BidderOptionsListItem(props) {
	const {
		bidder,
		bidderSeatOptions,
		selectBidderIcon: SelectBidderIcon,
		expanded,
		onClickExpansionIcon,
		onSelectBidder,
		onSelectSeat,
		onSelectActionLabel,
		showError
	} = props;
	const isExpandable = bidderSeatOptions && bidderSeatOptions.length > 0;
	const ariaLabel = isExpandable
		? `${expanded ? "hide" : "show"} seats`
		: bidder.name;
	const selectedSeatsLabel = isExpandable
		? lc.SEATS_SELECTED(bidderSeatOptions.length)
		: lc.NO_SEATS_SELECTED;

	return (
		<>
			<ListItem
				button={isExpandable}
				aria-label={ariaLabel}
				ContainerProps={{
					"aria-label": bidder.name
				}}
				onClick={() => onClickExpansionIcon(bidder.id)}
			>
				{isExpandable ? (
					<ListItemIcon data-testid={EXPANSION_ICON_TEST_ID}>
						{expanded ? <ExpandLess /> : <ExpandMore />}
					</ListItemIcon>
				) : (
					<ListItemIcon />
				)}
				<ListItemText
					sx={showError ? { color: "error.main" } : undefined}
					data-testid={showError ? INVALID_BIDDER_TEST_ID : undefined}
				>
					{bidder.name}
					{onSelectActionLabel === lc.BUTTON_LABEL_REMOVE &&
						` (${selectedSeatsLabel})`}
				</ListItemText>
				{onSelectBidder && (
					<ListItemSecondaryAction onClick={() => onSelectBidder(bidder)}>
						<IconButton
							edge="end"
							color="inherit"
							aria-label={onSelectActionLabel}
						>
							<SelectBidderIcon />
						</IconButton>
					</ListItemSecondaryAction>
				)}
			</ListItem>
			{isExpandable && (
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					{bidderSeatOptions.map(bidderSeat => (
						<BidderSeatListItem
							key={bidderSeat.id}
							bidderSeat={bidderSeat}
							onSelectSeat={onSelectSeat}
							selectSeatIcon={SelectBidderIcon}
							onSelectActionLabel={onSelectActionLabel}
						/>
					))}
				</Collapse>
			)}
		</>
	);
}
BidderOptionsListItem.propTypes = {
	bidder: PropTypes.shape().isRequired,
	bidderSeatOptions: PropTypes.arrayOf(PropTypes.shape()),
	onSelectBidder: PropTypes.func,
	onSelectSeat: PropTypes.func,
	selectBidderIcon: PropTypes.shape().isRequired,
	expanded: PropTypes.bool,
	onClickExpansionIcon: PropTypes.func.isRequired,
	onSelectActionLabel: PropTypes.string.isRequired,
	showError: PropTypes.bool
};
BidderOptionsListItem.defaultProps = {
	bidderSeatOptions: null,
	expanded: false,
	onSelectBidder: null,
	onSelectSeat: null,
	showError: false
};

export default BidderOptionsListItem;
