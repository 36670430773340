import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { FieldArray, useField } from "formik";
import { Button, Grid, IconButton } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import FormField from "../CrudForm/FormField";
import FormTextField from "../FormTextField/FormTextField";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import localeContent from "./localeContent";
import crudFormLocaleContent from "../CrudForm/localeContent";
import useErrorDeserializer from "../../hooks/useErrorDeserializer";
import Yup from "../../config/yupConfig";
import FormFieldSelect from "../FormFieldSelect/FormFieldSelect";

const CUSTOM_EXTENSION_NAME_KEY = "name";
const CUSTOM_EXTENSION_VALUE_KEY = "value";
const CUSTOM_EXTENSION_OBJECT_KEY = "rtbObject";

const rtbExtensionOptions = Object.entries(
	localeContent.FIELD_RTB_OBJECT.OPTIONS
).map(([value, label]) => {
	return {
		value,
		label
	};
});

export const TEST_ID_CUSTOM_EXTENSION_ROW = "custom-extension-row";

function BidderCustomExtensionRow(props) {
	const { index, name, deleteRow, disabled } = props;
	const customExtensionNameField = `${name}[${index}].${CUSTOM_EXTENSION_NAME_KEY}`;
	const customExtensionValueField = `${name}[${index}].${CUSTOM_EXTENSION_VALUE_KEY}`;
	const customExtensionObjectField = `${name}[${index}].${CUSTOM_EXTENSION_OBJECT_KEY}`;

	const onDeleteRow = useCallback(() => {
		deleteRow(index);
	}, [deleteRow, index]);

	return (
		<Grid
			container
			item
			spacing={MUI_GRID_CONTAINER_SPACING}
			alignItems="flex-start"
			data-testid={TEST_ID_CUSTOM_EXTENSION_ROW}
		>
			<FormField gridConfig={{ xs: 3 }}>
				<FormFieldSelect
					id={customExtensionObjectField}
					label={localeContent.FIELD_RTB_OBJECT.LABEL}
					name={customExtensionObjectField}
					disabled={disabled}
					required
					optionsConfig={{
						options: rtbExtensionOptions
					}}
				/>
			</FormField>
			<FormField gridConfig={{ xs: 4 }}>
				<FormTextField
					id={customExtensionNameField}
					label={localeContent.FIELD_LABEL_NAME}
					name={customExtensionNameField}
					disabled={disabled}
					required
				/>
			</FormField>
			<FormField gridConfig={{ xs: 4 }}>
				<FormTextField
					id={customExtensionValueField}
					label={localeContent.FIELD_LABEL_VALUE}
					name={customExtensionValueField}
					disabled={disabled}
					required
				/>
			</FormField>
			<Grid item xs={2} sm={1}>
				<IconButton
					onClick={onDeleteRow}
					aria-label={localeContent.ARIA_LABEL_DELETE_ROW}
				>
					<DeleteIcon fontSize="inherit" />
				</IconButton>
			</Grid>
		</Grid>
	);
}

BidderCustomExtensionRow.propTypes = {
	index: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	deleteRow: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired
};

function FormFieldBidderCustomExtensions(props) {
	const { name } = props;
	const [field, meta, helpers] = useField({ name });

	useErrorDeserializer(meta.error, helpers);

	return (
		<FieldArray name={name}>
			{fieldArrayHelpers => {
				const addNewCustomExtension = () =>
					fieldArrayHelpers.push({
						clientId: uuidv4(),
						CUSTOM_EXTENSION_VALUE_KEY: "",
						CUSTOM_EXTENSION_NAME_KEY: ""
					});
				return (
					<Grid
						container
						direction="column"
						spacing={MUI_GRID_CONTAINER_SPACING}
					>
						{field.value.map(({ clientId, id }, index) => (
							<BidderCustomExtensionRow
								key={id ?? clientId}
								index={index}
								name={name}
								deleteRow={fieldArrayHelpers.remove}
								disabled={id !== undefined}
							/>
						))}
						<Grid item xs={12}>
							<Button variant="contained" onClick={addNewCustomExtension}>
								{localeContent.BUTTON_TEXT_ADD_ROW}
							</Button>
						</Grid>
					</Grid>
				);
			}}
		</FieldArray>
	);
}

FormFieldBidderCustomExtensions.propTypes = {
	name: PropTypes.string.isRequired
};

export const formFieldBidderCustomExtensionsValidationSchema = Yup.array().of(
	Yup.object({
		[CUSTOM_EXTENSION_NAME_KEY]: Yup.string().required(
			crudFormLocaleContent.REQUIRED_FIELD_WARNING
		),
		[CUSTOM_EXTENSION_VALUE_KEY]: Yup.string().required(
			crudFormLocaleContent.REQUIRED_FIELD_WARNING
		)
	}).uniqueProperty(
		CUSTOM_EXTENSION_NAME_KEY,
		localeContent.UNIQUE_PARAMETER_WARNING
	)
);

export const CUSTOM_EXTENSIONS_TYPE = {
	baseValidation: formFieldBidderCustomExtensionsValidationSchema,
	component: FormFieldBidderCustomExtensions
};

export default FormFieldBidderCustomExtensions;
