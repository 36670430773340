import React from "react";

import ExpressionBuilderLc from "../../components/FormFieldExpressionBuilder/localeContent";

export default {
	FIELD_CAMPAIGN_ID: {
		LABEL: "Campaign Name",
		TOOLTIP: "Select the Campaign which this Media belongs to."
	},
	FIELD_MEDIA_NAME: {
		LABEL: "Media Name"
	},
	FIELD_ENABLE_PCPM: {
		LABEL: "CPM Option",
		OPTION_LABELS: {
			CPM: "CPM",
			PCPM: "Predicted CPM"
		}
	},
	FIELD_CPM: {
		LABEL: "CPM",
		TOOLTIP:
			"Used to calculate how much revenue has been earned for a given Media."
	},
	FIELD_PCPM: {
		LABEL: "Predicted CPM"
	},
	FIELD_PCPM_API_ID: {
		LABEL: "Predicted CPM API ID"
	},
	FIELD_MEDIA_TYPE: {
		LABEL: "Media Type",
		TOOLTIP:
			"Determines if this Media will result in a video or display banner.",
		OPTION_LABELS: {
			VIDEO: "Video",
			DISPLAY: "Display"
		}
	},
	FIELD_MEDIA_SOURCE: {
		LABEL: "Media Source",
		TOOLTIP: (
			<div data-testid="account-relationship-tooltip">
				<b>Ad Tag:</b> VAST Wrapper Tag.
				<br />
				<b>Asset:</b> Upload a media file which will be hosted and served from
				platform servers.
			</div>
		),
		OPTION_LABELS: {
			AD_TAG: "Ad Tag",
			ASSET: "Asset"
		}
	},
	FIELD_CREATIVE_TYPE: {
		LABEL: "Media File Type",
		TOOLTIP:
			"Select Upload Media File if you have direct access to the Media File creative and need it hosted on the platform servers. Select URL if your creative has already been hosted and you are uploading a link to the file.",
		OPTION_LABELS: {
			MEDIA_FILE: "Uploaded File",
			MEDIA_URL: "URL"
		}
	},
	FIELD_SIZE: {
		LABEL: "Size",
		TOOLTIP: "Size of the display creative."
	},
	FIELD_AD_TAG: {
		LABEL: "Ad Tag",
		TOOLTIP:
			"Insert third-party VAST tags here. Selected macros will be inserted at the cursor's position."
	},
	FIELD_SUPPORTS_VPAID: {
		LABEL: "VPAID Supported Tag",
		TOOLTIP: "Select Yes if the Ad Tag will result in a VPAID creative."
	},
	FIELD_LABEL_MEDIA_FILE_TYPE: "Media File Type",
	FIELD_POSITION: {
		LABEL: "Position",
		TOOLTIP:
			"Select whether the given Media should run before, during, or after content.",
		OPTION_LABELS: {
			PRE_ROLL: "Pre-Roll",
			MID_ROLL: "Mid-Roll",
			POST_ROLL: "Post-Roll"
		}
	},
	FIELD_DURATION: {
		LABEL: "Duration (seconds)",
		TOOLTIP: "Length of the ad in seconds."
	},
	FIELD_ADVERTISER_DOMAIN: {
		LABEL: "Advertiser Domain",
		TOOLTIP:
			"Domain associated with the brand/advertiser in the creative.  Ex: ford.com"
	},
	FIELD_CLICK_THROUGH_URL: {
		LABEL: "Click Through URL"
	},
	FIELD_MEDIA_PRIORITY: {
		LABEL: "Media Priority",
		TOOLTIP:
			"Priority is used to specify the order in which Media should be served. A Priority of 1 will serve before all other priority levels."
	},
	FIELD_MEDIA_WEIGHT: {
		LABEL: "Media Weight",
		TOOLTIP:
			"Weighting is used to differentiate between Priorities of the same level. The first option to serve will be Priority 1 with a Weight of 10. The last option to serve will be a Priority of 10 with a Weight of 1."
	},
	FIELD_TRACKERS: {
		LABEL: "Trackers",
		TOOLTIP: "All selected Trackers will be fired at the time of impression."
	},
	FIELD_REQUIRE_IVT_FILTERING: {
		LABEL: "Require IVT Filtering",
		TOOLTIP:
			"If enabled, this Media will only run on aligned Placements which filter out invalid traffic."
	},
	TARGETING_TYPE_DOMAIN_LABEL: "Domain",
	TARGETING_TYPE_APP_NAME_LABEL: "App Name",
	TARGETING_TYPE_APP_BUNDLE_IDS_LABEL: "App Bundle ID",
	FIELD_AUDIENCE: {
		LEAF_COLUMN_HEADERS: {
			NAME: "Audience Name",
			ESTIMATED_CPM: "Estimated CPM",
			POTENTIAL_COOKIE_REACH: "Potential Cookie Reach",
			POTENTIAL_DEVICE_REACH: "Potential Device Reach"
		},
		ENABLE_FIELD_LABEL: "Audience Targeting",
		LEAF_NAME_LABEL: "audience",
		EMPTY_EXPRESSION_WARNING: ExpressionBuilderLc.DEFAULT_EMPTY_EXPRESSION_WARNING(
			"an audience"
		)
	},
	CUSTOM_PARAMETER_PASSTHROUGH: {
		LABEL: "Custom Parameter Passthrough",
		TOOLTIP:
			"Placement tag parameter associated with the key/value pair that should be passed with this Media's Ad tag"
	},
	AVAILABLE_PLACEMENTS: "Available Placements",
	ALIGNED_PLACEMENTS: "Aligned Placements",
	FIELD_CUSTOM_DATE_RANGE: {
		LABEL: "Custom Date Range",
		TOOLTIP:
			"Allows users to set a date range that varies from the Campaign's Start and End Dates"
	},
	FIELD_CUSTOM_START_DATE: {
		LABEL: "Start Date",
		TOOLTIP: "Date and Time at which this Media will begin",
		CLEAR: "Clear",
		OK: "OK",
		REQUIRED: "Required when custom date range is enabled"
	},
	FIELD_CUSTOM_END_DATE: {
		LABEL: "End Date",
		TOOLTIP: "Date and Time at which this Media will end",
		CLEAR: "Clear"
	},
	CLONE_ALIGNMENTS: {
		LABEL: "Clone Alignments",
		TOOLTIP:
			"If enabled, all associated alignments will be copied to the cloned resource"
	}
};
