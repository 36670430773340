import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import transformOptions from "../../utils/transformOptions";
import Multiselect from "../FormFieldMultiselect/Multiselect";
import commonLocaleContent from "../../screens/commonLocaleContent";

function GeoTargetingMultiselect(props) {
	const {
		id,
		label,
		name,
		required,
		disabled,
		options,
		onInputChange,
		hasRequestError
	} = props;
	const [field, meta, helpers] = useField(name);

	// Error handling
	let showError = Boolean(meta.touched && meta.error);
	let helperText = showError ? meta.error : "";
	if (hasRequestError) {
		showError = true;
		helperText = commonLocaleContent.UNABLE_TO_RETRIEVE_OPTIONS_WARNING;
	}

	const fieldOptions = transformOptions({ options });

	const handleChange = React.useCallback(
		(event, newValue) => {
			helpers.setValue(newValue);
		},
		[helpers]
	);

	return (
		<Multiselect
			id={id}
			label={label}
			name={name}
			options={fieldOptions}
			selectedOptions={field.value}
			onChange={handleChange}
			onInputChange={onInputChange}
			onBlur={field.onBlur}
			required={required}
			disabled={disabled}
			showError={showError}
			helperText={helperText}
		/>
	);
}

GeoTargetingMultiselect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.shape()),
	onInputChange: PropTypes.func,
	hasRequestError: PropTypes.bool
};

GeoTargetingMultiselect.defaultProps = {
	required: false,
	disabled: false,
	options: [],
	onInputChange: null,
	hasRequestError: false
};

export default GeoTargetingMultiselect;
