export default (leafName, leafNodeType) => {
	const blockTypes = {
		leftParen: "LEFT_PAREN",
		rightParen: "RIGHT_PAREN",
		not: "NOT",
		or: "OR",
		and: "AND",
		leaf: leafNodeType
	};

	const errorMessages = {
		EMPTY_EXPRESSION_NOT_ALLOWED: `Please select at least one ${leafName.toLowerCase()}`,
		INVALID_FIRST_BLOCK_TYPE: `Expressions must begin with ${leafName}, NOT, or ( blocks`,
		INVALID_LAST_BLOCK_TYPE: `Expressions must end with ${leafName} or ) blocks`,
		INVALID_FIRST_AND_LAST_BLOCK_TYPES: `Expressions must begin with ${leafName}, NOT, or ( blocks, and end with ${leafName} or ) blocks`,
		INVALID_PARENTHESIS_SET_FIRST_BLOCK_TYPE:
			"Parenthesis sets cannot begin with an operator",
		INVALID_PARENTHESIS_SET_LAST_BLOCK: `Parenthesis sets must end with a ${leafName} block`,
		EMPTY_PARENTHESIS_NOT_ALLOWED: "Parenthesis sets cannot be empty",
		INVALID_LEFT_PAREN_AFTER_LEAF: `${leafName} blocks and parenthesis sets must be connected by an operator`,
		CONSECUTIVE_LEAFS_NOT_ALLOWED: `Consecutive ${leafName} blocks are not allowed`,
		INVALID_NOT_AFTER_LEAF: `${leafName} blocks and NOT blocks must be connected by an operator`,
		CONSECUTIVE_OPERATORS_NOT_ALLOWED: "Consecutive operators are not allowed",
		INVALID_LEAF_AFTER_RIGHT_PAREN: `${leafName} blocks and parenthesis sets must be connected by an operator`,
		CONSECUTIVE_PARENTHESIS_SETS_NOT_ALLOWED:
			"Parenthesis sets must be connected by an operator",
		INVALID_NOT_AFTER_PARENTHESIS_SET:
			"Parenthesis sets and NOT blocks must be connected by an operator",
		INVALID_OPERATOR_AFTER_NOT: "NOT blocks cannot be followed by an operator",
		CONSECUTIVE_NOTS_NOT_ALLOWED: "Consecutive NOT blocks are not allowed"
	};

	function getExpressionLevelError(firstBlock, lastBlock) {
		if (!firstBlock) {
			return errorMessages.EMPTY_EXPRESSION_NOT_ALLOWED;
		}

		const allowedFirstBlockTypes = [
			blockTypes.leaf,
			blockTypes.leftParen,
			blockTypes.not
		];
		const isFirstBlockInvalid = !allowedFirstBlockTypes.includes(
			firstBlock.nodeType
		);

		const allowedLastBlockTypes = [blockTypes.leaf, blockTypes.rightParen];
		const isLastBlockInvalid = !allowedLastBlockTypes.includes(
			lastBlock.nodeType
		);

		if (isFirstBlockInvalid && isLastBlockInvalid) {
			return errorMessages.INVALID_FIRST_AND_LAST_BLOCK_TYPES;
		}
		if (isFirstBlockInvalid) {
			return errorMessages.INVALID_FIRST_BLOCK_TYPE;
		}
		if (isLastBlockInvalid) {
			return errorMessages.INVALID_LAST_BLOCK_TYPE;
		}

		return null;
	}

	const getLeafAdjacentBlockErrors = () => ({
		[blockTypes.leftParen]: errorMessages.INVALID_LEFT_PAREN_AFTER_LEAF,
		[blockTypes.leaf]: errorMessages.CONSECUTIVE_LEAFS_NOT_ALLOWED,
		[blockTypes.not]: errorMessages.INVALID_NOT_AFTER_LEAF
	});

	// Used for both AND and OR blocks
	const getOperatorAdjacentBlockErrors = () => ({
		[blockTypes.and]: errorMessages.CONSECUTIVE_OPERATORS_NOT_ALLOWED,
		[blockTypes.or]: errorMessages.CONSECUTIVE_OPERATORS_NOT_ALLOWED,
		[blockTypes.rightParen]: errorMessages.INVALID_PARENTHESIS_SET_LAST_BLOCK
	});

	const getNotAdjacentBlockErrors = () => ({
		[blockTypes.and]: errorMessages.INVALID_OPERATOR_AFTER_NOT,
		[blockTypes.or]: errorMessages.INVALID_OPERATOR_AFTER_NOT,
		[blockTypes.not]: errorMessages.CONSECUTIVE_NOTS_NOT_ALLOWED,
		[blockTypes.rightParen]: errorMessages.INVALID_PARENTHESIS_SET_LAST_BLOCK
	});

	const getLeftParenAdjacentBlockErrors = () => ({
		[blockTypes.and]: errorMessages.INVALID_PARENTHESIS_SET_FIRST_BLOCK_TYPE,
		[blockTypes.or]: errorMessages.INVALID_PARENTHESIS_SET_FIRST_BLOCK_TYPE,
		[blockTypes.rightParen]: errorMessages.EMPTY_PARENTHESIS_NOT_ALLOWED
	});

	const getRightParenAdjacentBlockErrors = () => ({
		[blockTypes.leaf]: errorMessages.INVALID_LEAF_AFTER_RIGHT_PAREN,
		[blockTypes.leftParen]:
			errorMessages.CONSECUTIVE_PARENTHESIS_SETS_NOT_ALLOWED,
		[blockTypes.not]: errorMessages.INVALID_NOT_AFTER_PARENTHESIS_SET
	});

	const adjacentBlockErrorMap = {
		[blockTypes.leaf]: getLeafAdjacentBlockErrors(),
		[blockTypes.and]: getOperatorAdjacentBlockErrors(),
		[blockTypes.or]: getOperatorAdjacentBlockErrors(),
		[blockTypes.not]: getNotAdjacentBlockErrors(),
		[blockTypes.leftParen]: getLeftParenAdjacentBlockErrors(),
		[blockTypes.rightParen]: getRightParenAdjacentBlockErrors()
	};

	const getAdjacentBlockError = (blockNodeType, adjacentBlockNodeType) => {
		// Skip adjacent validation if it's the first or last block
		if (!blockNodeType || !adjacentBlockNodeType) {
			return null;
		}
		return adjacentBlockErrorMap[blockNodeType][adjacentBlockNodeType] || null;
	};

	return {
		getExpressionLevelError,
		getAdjacentBlockError
	};
};
