export const SORTABLE_ID_KEY = "sortableId";
export const DROPZONE_SORTABLE_ID = "dropzone";
export const OPERATORS_SORTABLE_ID = "operators";
export const LEAF_CONTAINER_SORTABLE_ID = "leafs";
export const EXPRESSION_CHIP_TEST_ID = "expression-chip";

export default {
	SORTABLE_ID_KEY,
	DROPZONE_SORTABLE_ID,
	OPERATORS_SORTABLE_ID,
	LEAF_CONTAINER_SORTABLE_ID,
	EXPRESSION_CHIP_TEST_ID
};
