/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import {
	Box,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel
} from "@mui/material";
import SearchTextField from "../SearchTextField/SearchTextField";
import { buildColumnDefinition } from "../../utils/ReactTableUtils/ReactTableUtils";
import SelectRowCheckbox from "../ReactTableCellEnabledOriginalRowCheckbox/ReactTableCellEnabledOriginalRowCheckbox";
import { MUI_PADDING_NORMAL } from "../../config/constants";

const COLUMNS = [
	buildColumnDefinition({
		Header: "Name",
		accessor: "name",
		enableGlobalFilter: true
	}),
	buildColumnDefinition({
		Header: "Description",
		accessor: "description",
		enableGlobalFilter: true
	})
];

function FormFieldPermissions(props) {
	const { name } = props;
	const [field, , helpers] = useField(name);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => field.value, [field.value]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		state: { globalFilter },
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			autoResetSelectedRows: false
		},
		useGlobalFilter,
		useSortBy,
		hooks => {
			hooks.visibleColumns.push(cols => [
				// Make a column for selection
				{
					id: "selection",
					tableCellPadding: "checkbox",
					Cell: SelectRowCheckbox
				},
				...cols
			]);
		}
	);

	const toggleRowEnabled = useCallback(
		originalRowId => () => {
			const rowToUpdate = field.value.find(({ id }) => id === originalRowId);
			rowToUpdate.enabled = !rowToUpdate.enabled;
			helpers.setValue(field.value);
		},
		[helpers, field.value]
	);

	return (
		<>
			<Box display="flex" flexDirection="row">
				<SearchTextField
					searchQueryValue={globalFilter || ""}
					onSearchQueryValueChange={setGlobalFilter}
					id="search-permission"
				/>
			</Box>
			<br />
			<TableContainer>
				<Table {...getTableProps()} size="small" sx={{ minWidth: "960px" }}>
					<TableHead>
						{headerGroups.map(headerGroup => (
							<TableRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<TableCell
										{...column.getHeaderProps(column.getSortByToggleProps())}
										sx={{ bgcolor: "primary.800", color: "common.white" }}
										data-testid={column.id}
										scope="col"
										sortDirection={
											column.isSorted && (column.isSortedDesc ? "desc" : "asc")
										}
										padding={column.tableCellPadding || MUI_PADDING_NORMAL}
										align={column.align}
									>
										{column.canSort ? (
											<TableSortLabel
												active={column.isSorted}
												direction={column.isSortedDesc ? "desc" : "asc"}
											>
												{column.render("Header")}
												{column.isSorted && (
													<span className="sr-only-text">
														{column.isSortedDesc
															? "sorted descending"
															: "sorted ascending"}
													</span>
												)}
											</TableSortLabel>
										) : (
											column.render("Header")
										)}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody {...getTableBodyProps()}>
						{rows.map(row => {
							prepareRow(row);
							return (
								<TableRow
									{...row.getRowProps()}
									data-testid={row.original.id}
									sx={{ cursor: "pointer" }}
									selected={row.isSelected}
									onClick={toggleRowEnabled(row.original.id)}
									hover
								>
									{row.cells.map(cell => (
										<TableCell
											{...cell.getCellProps()}
											padding={
												cell.column.tableCellPadding || MUI_PADDING_NORMAL
											}
											align={cell.column.align}
										>
											{cell.render("Cell")}
										</TableCell>
									))}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

FormFieldPermissions.propTypes = {
	name: PropTypes.string.isRequired
};

FormFieldPermissions.defaultProps = {};

export default FormFieldPermissions;
