/**
 * Converts number to a string
 * null and undefined values are converted to an empty string
 * @param {Number} value the value to stringifiy
 */
const stringifiedNumber = value => {
	const nullEscapedValue = value ?? "";
	return `${nullEscapedValue}`;
};

export default stringifiedNumber;
