import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Box, Chip, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import EditIcon from "@mui/icons-material/Edit";

import Tooltip from "../Tooltip/Tooltip";
import lc from "./localeContent";

const errorSx = {
	borderColor: "error.main",
	borderWidth: "1px",
	borderStyle: "solid"
};

const ExpressionNode = forwardRef((props, ref) => {
	const {
		style,
		label,
		attributes,
		listeners,
		onDelete,
		onEdit,
		size,
		dataTestId,
		tooltip,
		isInvalid,
		isLeafNode,
		expressionChipSx
	} = props;

	const theme = useTheme();

	return (
		<Box
			ref={ref}
			sx={{ position: "relative", display: "inline-block" }}
			style={style}
		>
			<Chip
				label={
					<Tooltip title={tooltip || ""}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center"
							}}
						>
							{onEdit && (
								<IconButton
									size="small"
									onClick={onEdit}
									aria-label={lc.EDIT_CHIP_BUTTON_ARIA_LABEL}
								>
									<EditIcon />
								</IconButton>
							)}
							<Box
								sx={{
									cursor: "grab",
									pr: 1.5,
									pl: onEdit ? 0.5 : 1.5,
									display: "inline-block"
								}}
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...attributes}
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...listeners}
							>
								{label}
							</Box>
						</Box>
					</Tooltip>
				}
				sx={{
					minWidth: !isLeafNode ? theme.spacing(6) : undefined,
					...(isInvalid ? errorSx : {}),
					...expressionChipSx,
					"& .MuiChip-label": {
						px: 0
					}
				}}
				onDelete={onDelete}
				variant={isLeafNode ? "default" : "outlined"}
				size={size}
				data-testid={dataTestId}
			/>
		</Box>
	);
});

ExpressionNode.propTypes = {
	label: PropTypes.string.isRequired,
	style: PropTypes.shape(),
	attributes: PropTypes.shape(),
	listeners: PropTypes.shape(),
	onDelete: PropTypes.func,
	chipVariant: PropTypes.string,
	size: PropTypes.string,
	expressionChipSx: PropTypes.shape(),
	dataTestId: PropTypes.string,
	tooltip: PropTypes.string,
	isInvalid: PropTypes.bool,
	isLeafNode: PropTypes.bool,
	onEdit: PropTypes.func
};

ExpressionNode.defaultProps = {
	style: {},
	attributes: {},
	listeners: {},
	onDelete: undefined,
	onEdit: undefined,
	chipVariant: "default",
	size: "medium",
	expressionChipSx: {},
	dataTestId: undefined,
	tooltip: null,
	isInvalid: false,
	isLeafNode: false
};

export default ExpressionNode;
