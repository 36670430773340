import PublisherResource from "../screens/Publisher/Resource";
import PublisherIndex from "../screens/Publisher/Index";
import SiteResource from "../screens/Site/Resource";
import SiteIndex from "../screens/Site/Index";
import PlacementResource from "../screens/Placement/Resource";
import PlacementIndex from "../screens/Placement/Index";
import RouterIndex from "../screens/Router/Index";
import RoutersResource from "../screens/Router/Resource";
import AdvertiserResource from "../screens/Advertiser/Resource";
import AdvertiserIndex from "../screens/Advertiser/Index";
import InsertionOrderResource from "../screens/InsertionOrder/Resource";
import InsertionOrderIndex from "../screens/InsertionOrder/Index";
import CampaignResource from "../screens/Campaign/Resource";
import CampaignIndex from "../screens/Campaign/Index";
import MediaResource from "../screens/Media/Resource";
import MediaIndex from "../screens/Media/Index";
import ProgrammaticDemandResource from "../screens/ProgrammaticDemand/Resource";
import ProgrammaticDemandIndex from "../screens/ProgrammaticDemand/Index";
import BidderResource from "../screens/Bidder/Resource";
import BidderIndex from "../screens/Bidder/Index";
import UsersResource from "../screens/User/Resource";
import UserIndex from "../screens/User/Index";
import RolesResource from "../screens/Role/Resource";
import RoleIndex from "../screens/Role/Index";
import CompanyResource from "../screens/Company/Resource";
import CompanyIndex from "../screens/Company/Index";
import AudienceResource from "../screens/Audience/Resource";
import AudienceIndex from "../screens/Audience/Index";
import TrackerResource from "../screens/Tracker/Resource";
import TrackerIndex from "../screens/Tracker/Index";
import DataDistributionResource from "../screens/DataDistribution/Resource";
import DataDistributionIndex from "../screens/DataDistribution/Index";
import UserSettingsResource from "../screens/UserSettings/Resource";
import TrafficFiltersResource from "../screens/TrafficFilters/Resource";
import GlobalBlacklistResource from "../screens/GlobalBlacklist/Resource";
import MyReportsIndex from "../screens/MyReports/Index";
import ScheduledReportsResource from "../screens/ScheduledReports/Resource";
import Reporting from "../screens/Reporting/ReportingScreen";
import { PROTECTED_CONTENT_PATH, HELP_URL } from "./constants";
import { BASE_ROUTES } from "./baseRoutesConfig";
import Login from "../screens/Login/Login";
import ForgotPassword from "../screens/ForgotPassword/ForgotPassword";
import ResetPassword from "../screens/ResetPassword/ResetPassword";
import AccessDeniedErrorPageComponent from "../screens/AccessDeniedErrorPage/AccessDeniedErrorPage";
import NotificationsIndex from "../screens/Notifications/Index";
import NotificationsResource from "../screens/Notifications/Resource";
import UserNotificationsScreen from "../screens/Notifications/UserNotifications";

function getProtectedRoute(routeKey) {
	return routeKey ? `${PROTECTED_CONTENT_PATH}${routeKey}` : routeKey;
}

export const PROTECTED_CONTENT_ROUTES_CONFIG = [
	{
		key: "REPORTING_DASHBOARD",
		path: "/reporting",
		component: Reporting
	},
	{
		key: "MY_REPORTS",
		path: "/my-reports",
		component: MyReportsIndex
	},
	{
		key: "SCHEDULED_REPORTS",
		path: "/scheduled-reports/:id",
		component: ScheduledReportsResource
	},
	{
		key: "PUBLISHERS_RESOURCE",
		path: "/publishers/:id",
		component: PublisherResource
	},
	{
		key: "PUBLISHERS",
		path: "/publishers",
		component: PublisherIndex,
		exact: true
	},
	{
		key: "SITES_RESOURCE",
		path: "/sites/:id",
		component: SiteResource
	},
	{
		key: "SITES",
		path: "/sites",
		component: SiteIndex,
		exact: true
	},
	{
		key: "PLACEMENTS_RESOURCE",
		path: "/placements/:id",
		component: PlacementResource
	},
	{
		key: "PLACEMENTS",
		path: "/placements",
		component: PlacementIndex,
		exact: true
	},
	{
		key: "ROUTERS",
		path: "/routers",
		component: RouterIndex,
		exact: true
	},
	{
		key: "ROUTERS_RESOURCE",
		path: "/routers/:id",
		component: RoutersResource,
		exact: true
	},
	{
		key: "ADVERTISERS_RESOURCE",
		path: "/advertisers/:id",
		component: AdvertiserResource
	},
	{
		key: "ADVERTISERS",
		path: "/advertisers",
		component: AdvertiserIndex,
		exact: true
	},
	{
		key: "INSERTION_ORDERS_RESOURCE",
		path: "/insertion-orders/:id",
		component: InsertionOrderResource
	},
	{
		key: "INSERTION_ORDERS",
		path: "/insertion-orders",
		component: InsertionOrderIndex,
		exact: true
	},
	{
		key: "CAMPAIGNS_RESOURCE",
		path: "/campaigns/:id",
		component: CampaignResource
	},
	{
		key: "CAMPAIGNS",
		path: "/campaigns",
		component: CampaignIndex,
		exact: true
	},
	{
		key: "MEDIA_RESOURCE",
		path: "/media/:id",
		component: MediaResource
	},
	{
		key: "MEDIA",
		path: "/media",
		component: MediaIndex,
		exact: true
	},
	{
		key: "PROGRAMMATIC_DEMAND_RESOURCE",
		path: "/programmatic-demand/:id",
		component: ProgrammaticDemandResource
	},
	{
		key: "PROGRAMMATIC_DEMAND",
		path: "/programmatic-demand",
		component: ProgrammaticDemandIndex,
		exact: true
	},
	{
		key: "BIDDERS_RESOURCE",
		path: `/${BASE_ROUTES.bidders}/:id`,
		component: BidderResource
	},
	{
		key: "BIDDERS",
		path: `/${BASE_ROUTES.bidders}`,
		component: BidderIndex,
		exact: true
	},
	{
		key: "USERS_RESOURCE",
		path: "/users/:id",
		component: UsersResource
	},
	{
		key: "USERS",
		path: "/users",
		component: UserIndex,
		exact: true
	},
	{
		key: "ROLES_RESOURCE",
		path: "/user-roles/:id",
		component: RolesResource
	},
	{
		key: "ROLES",
		path: "/roles",
		component: RoleIndex,
		exact: true
	},
	{
		key: "COMPANIES_RESOURCE",
		path: "/companies/:id",
		component: CompanyResource
	},
	{
		key: "COMPANIES",
		path: "/companies",
		component: CompanyIndex,
		exact: true
	},
	{
		key: "AUDIENCES_RESOURCE",
		path: "/audiences/:id",
		component: AudienceResource
	},
	{
		key: "AUDIENCES",
		path: "/audiences",
		component: AudienceIndex,
		exact: true
	},
	{
		key: "TRACKERS_RESOURCE",
		path: "/trackers/:id",
		component: TrackerResource
	},
	{
		key: "TRACKERS",
		path: "/trackers",
		component: TrackerIndex,
		exact: true
	},
	{
		key: "DATA_DISTRIBUTIONS_RESOURCE",
		path: `/${BASE_ROUTES.dataDistributions}/:id`,
		component: DataDistributionResource
	},
	{
		key: "DATA_DISTRIBUTIONS",
		path: `/${BASE_ROUTES.dataDistributions}`,
		component: DataDistributionIndex,
		exact: true
	},
	{
		key: "USER_SETTINGS",
		path: `/${BASE_ROUTES.userSettings}`,
		component: UserSettingsResource
	},
	{
		key: "TRAFFIC_FILTERS",
		path: `/${BASE_ROUTES.trafficFilters}`,
		component: TrafficFiltersResource
	},
	{
		key: "GLOBAL_BLOCKLIST",
		path: `/${BASE_ROUTES.globalBlocklist}`,
		component: GlobalBlacklistResource
	},
	{
		key: "NOTIFICATIONS",
		path: `/notifications`,
		component: NotificationsIndex,
		exact: true
	},
	{
		key: "NOTIFICATIONS_RESOURCE",
		path: "/notifications/:id",
		component: NotificationsResource
	},
	{
		key: "USER_NOTIFICATIONS",
		path: `/user-notifications`,
		component: UserNotificationsScreen,
		exact: true
	}
].map(routeConfig => ({
	...routeConfig,
	path: getProtectedRoute(routeConfig.path)
}));

// Configuration of External URLs
export const USER_HELP = "USER_HELP";
const EXTERNAL_URLS = {
	[USER_HELP]: HELP_URL
};

export const openExternalUrl = (routeKey, target) => {
	const externalUrl = EXTERNAL_URLS[routeKey];
	window.open(externalUrl, target);
};
export function getRoutePathByKey(routeKey) {
	const routeConfig = PROTECTED_CONTENT_ROUTES_CONFIG.find(
		route => route.key === routeKey
	);
	return routeConfig?.path;
}

export const UNPROTECTED_CONTENT_ROUTES_CONFIG = [
	{
		key: "LOGIN",
		path: "/login",
		component: Login
	},
	{
		key: "FORGOT",
		path: "/forgot",
		component: ForgotPassword
	},
	{
		key: "RESET_PASSWORD",
		path: "/reset",
		component: ResetPassword
	},
	{
		key: "ACCESSDENIED",
		path: "/403",
		component: AccessDeniedErrorPageComponent
	}
];
