import React from "react";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";

const advertiserColumnsConfig = [
	{
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		key: COLUMN_KEY_STATUS,
		cellDataType: "upperCase"
	}
];

const advertiserChildResourceConfig = {
	name: "Insertion",
	route: "insertion-orders",
	deleteAssociatedResourceWarning: "insertions, campaigns and media"
};

function AdvertiserIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.ADVERTISER}
			columnsConfig={advertiserColumnsConfig}
			resourceEndpoint="manage/advtsrs"
			resourceRoute="advertisers"
			childResourceConfig={advertiserChildResourceConfig}
		/>
	);
}

export default AdvertiserIndex;
