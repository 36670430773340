export default {
	UPLOAD_MEDIA_FILE_BUTTON_LABEL: "Upload Media File",
	UPLOAD_MEDIA_FILE_DIALOGUE_TITLE: "Upload Media File",
	FIELD_LABEL_UPLOAD_NAME: "Upload Name",
	FIELD_LABEL_MIME_TYPE: "Mime Type",
	FIELD_LABEL_BITRATE: "Bitrate",
	FIELD_LABEL_WIDTH: "Width",
	FIELD_LABEL_HEIGHT: "Height",
	FIELD_LABEL_FILE: "Upload Media File",
	ACTION_BUTTON_LABEL_CANCEL: "Cancel",
	ACTION_BUTTON_LABEL_SUBMIT: "Upload",
	FILE_LIST_ACCESSIBLE_TABLE_DESCRIPTION: "uploaded media files",
	ACTION_BUTTON_LABEL_PREVIEW: "Preview",
	ACCESSIBILITY_LABEL_PREVIEW: "opens in new tab",
	ACTION_BUTTON_LABEL_DELETE: "Delete",
	DELETE_ASSET_MODAL: {
		TITLE: "Confirm Asset Delete",
		DESCRIPTION: ({ assetName }) =>
			`Are you sure you want to delete this file: ${assetName}?`,
		ACTION_BUTTON_LABEL_CONFIRM: "Delete"
	},
	DELETE_ASSET_SUCCESS_MESSAGE: ({ assetName }) =>
		`${assetName} was successfully deleted`
};
