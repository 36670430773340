import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import TextField from "./TextField";

const getHelperTextWithOverrides = (
	showError,
	metaError,
	helperText,
	errorOverride
) => {
	if (errorOverride) {
		return errorOverride;
	}
	if (showError) {
		return metaError;
	}
	return helperText;
};

/**
 * A wrapper around the mui TextField that:
 *   - adds Formik functionality by passing Field value, onChange, and onBlur
 *   - adds error handling functionality
 * Because our style rules don't allow for prop spreading, we need to be explicit about what we're passing to the mui component
 * @param {Object} props defined in propTypes
 */
function FormTextField(props) {
	const {
		id,
		label,
		name,
		required,
		disabled,
		readOnly,
		type,
		helperText,
		errorOverride,
		inputProps,
		InputProps,
		fieldTypeOptions
	} = props;
	const { multiline, rows, rowsMax } = fieldTypeOptions;

	const [field, meta] = useField(name);
	const value = Array.isArray(field.value)
		? field.value.join(",")
		: field.value;
	const showError =
		Boolean(meta.touched && meta.error) || Boolean(errorOverride);
	const helperTextValue = getHelperTextWithOverrides(
		showError,
		meta.error,
		helperText,
		errorOverride
	);

	return (
		<TextField
			id={id}
			name={name}
			label={label}
			type={type}
			value={value}
			onChange={field.onChange}
			onBlur={field.onBlur}
			disabled={disabled}
			showError={showError}
			helperText={helperTextValue}
			inputProps={inputProps}
			// This is a material-ui prop so we have no control over this
			// eslint-disable-next-line react/jsx-no-duplicate-props
			InputProps={{ readOnly, ...InputProps }}
			required={required}
			multiline={multiline}
			rows={rows}
			maxRows={rowsMax}
		/>
	);
}

FormTextField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	helperText: PropTypes.string,
	type: PropTypes.string,
	inputProps: PropTypes.shape(),
	InputProps: PropTypes.shape(),
	fieldTypeOptions: PropTypes.shape(),
	errorOverride: PropTypes.string
};

FormTextField.defaultProps = {
	required: false,
	disabled: false,
	readOnly: false,
	helperText: null,
	type: "text",
	inputProps: {},
	InputProps: {},
	fieldTypeOptions: {},
	errorOverride: null
};

export default FormTextField;
