import React, { useEffect, createContext, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useLocation } from "react-router-dom";

const SnackbarContext = createContext();
const { Provider } = SnackbarContext;

function SnackbarProvider({ children }) {
	const [snackPack, setSnackPack] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [messageInfo, setMessageInfo] = React.useState(undefined);
	const location = useLocation();
	const shouldDismissOnLocationChange = useRef(false);

	useEffect(() => {
		if (snackPack.length && !messageInfo) {
			// Set a new snack when we don't have an active one
			setMessageInfo({ ...snackPack[0] });
			setSnackPack(prev => prev.slice(1));
			setOpen(true);
		} else if (snackPack.length && messageInfo && open) {
			// Close an active snack when a new one is added
			setOpen(false);
		}
	}, [snackPack, messageInfo, open]);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const handleExited = () => {
		setMessageInfo(undefined);
	};

	useEffect(() => {
		if (shouldDismissOnLocationChange.current) {
			setOpen(false);
			shouldDismissOnLocationChange.current = false;
		}
	}, [location.pathname]);

	const triggerNewSnackbarMessage = useCallback(
		({
			message,
			severity,
			icon,
			style,
			forceDismiss,
			dismissOnLocationChange
		}) => {
			setSnackPack(prev => [
				...prev,
				{
					key: new Date().getTime(),
					message,
					severity,
					icon,
					style,
					forceDismiss
				}
			]);
			shouldDismissOnLocationChange.current = dismissOnLocationChange;
		},
		[setSnackPack]
	);

	const showSnackbarInfoMessage = useCallback(
		message =>
			triggerNewSnackbarMessage({
				message,
				severity: "info",
				icon: false,
				style: { backgroundColor: "black", fontFamily: "Poppins" }
			}),
		[triggerNewSnackbarMessage]
	);

	return (
		<Provider
			value={{
				triggerNewSnackbarMessage,
				showSnackbarInfoMessage
			}}
		>
			{children}
			<Snackbar
				key={messageInfo?.key}
				open={open}
				autoHideDuration={messageInfo?.forceDismiss ? null : 5000}
				onClose={handleClose}
				TransitionProps={{
					onExited: handleExited
				}}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert
					elevation={6}
					variant="filled"
					onClose={handleClose}
					severity={messageInfo?.severity || "success"}
					icon={messageInfo?.icon}
					style={messageInfo?.style}
				>
					{messageInfo?.message}
				</Alert>
			</Snackbar>
		</Provider>
	);
}

SnackbarProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { SnackbarContext, SnackbarProvider };
