import React from "react";
import PropTypes from "prop-types";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	Button,
	IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import localeContent from "./localeContent";

function ConfirmDialogueSimple(props) {
	const {
		title,
		description,
		isOpen,
		onConfirm,
		confirmLabel,
		onCancel,
		cancelLabel,
		onClose,
		children
	} = props;

	const dialogTitleId = "confirm-dialog-title";
	const dialogDescriptionId = "confirm-dialog-description";

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby={dialogTitleId}
			aria-describedby={dialogDescriptionId}
		>
			<DialogTitle id={dialogTitleId}>
				{title}
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={theme => ({
						position: "absolute",
						right: theme.spacing(1),
						top: theme.spacing(1)
					})}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText id={dialogDescriptionId}>
					{description}
				</DialogContentText>
				{children}
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="primary">
					{cancelLabel}
				</Button>
				<Button onClick={onConfirm} color="primary" autoFocus>
					{confirmLabel}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

ConfirmDialogueSimple.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onConfirm: PropTypes.func.isRequired,
	confirmLabel: PropTypes.string,
	onCancel: PropTypes.func.isRequired,
	cancelLabel: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node
};

ConfirmDialogueSimple.defaultProps = {
	confirmLabel: localeContent.ACTION_BUTTON_LABEL_CONFIRM,
	cancelLabel: localeContent.ACTION_BUTTON_LABEL_CANCEL,
	children: null
};

export default ConfirmDialogueSimple;
