import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import ConfirmDialogueSimple from "../ConfirmDialogueSimple/ConfirmDialogueSimple";
import { useResourceAsync, Operations } from "../../hooks/useResourceAsync";
import { SnackbarContext } from "../../context/SnackbarContext";
import lc from "./localeContent";

function TableRowActionDelete(props) {
	const {
		rowData,
		resourceName,
		deleteEndpoint,
		deleteSuccessMessage,
		updateTableData
	} = props;

	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(
		false
	);

	const { isLoading: isDeleting, execute: deleteEntity } = useResourceAsync(
		deleteEndpoint,
		Operations.DELETE
	);

	const openDeleteConfirmation = () => {
		setIsOpenDeleteConfirmation(true);
	};

	const closeDeleteConfirmation = () => {
		setIsOpenDeleteConfirmation(false);
	};

	const handleDeleteItem = async () => {
		closeDeleteConfirmation();
		const { isSuccess, error } = await deleteEntity({ resourceId: rowData.id });
		if (error) {
			const { message } = error;
			triggerNewSnackbarMessage({ message, severity: "error" });
		}
		if (isSuccess) {
			updateTableData();
			triggerNewSnackbarMessage({
				message: deleteSuccessMessage,
				severity: "success"
			});
		}
	};

	return (
		<>
			<IconButton
				aria-label={lc.DELETE_ITEM_BUTTON_LABEL({
					resourceName,
					rowValue: rowData.value
				})}
				aria-haspopup="true"
				onClick={openDeleteConfirmation}
			>
				<DeleteIcon />
			</IconButton>
			<LoadingBackdrop isOpen={isDeleting} testId="delete-item" />
			<ConfirmDialogueSimple
				title={lc.DELETE_MODAL.TITLE({ resourceName })}
				description={lc.DELETE_MODAL.DESCRIPTION({
					rowItemName: rowData.value
				})}
				isOpen={isOpenDeleteConfirmation}
				onConfirm={handleDeleteItem}
				confirmLabel={lc.DELETE_MODAL.ACTION_BUTTON_LABEL_CONFIRM}
				onCancel={closeDeleteConfirmation}
				onClose={closeDeleteConfirmation}
			/>
		</>
	);
}

TableRowActionDelete.propTypes = {
	rowData: PropTypes.shape().isRequired,
	resourceName: PropTypes.string.isRequired,
	deleteEndpoint: PropTypes.string.isRequired,
	deleteSuccessMessage: PropTypes.string,
	updateTableData: PropTypes.func
};

TableRowActionDelete.defaultProps = {
	updateTableData: null,
	deleteSuccessMessage: lc.DEFAULT_DELETE_SUCCESS_MESSAGE
};

export default TableRowActionDelete;
