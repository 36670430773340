import React from "react";
import PropTypes from "prop-types";
import FormTextField from "../FormTextField/FormTextField";

/**
 * A wrapper around the base FormTextField that:
 *   - adds phone attributes to the input
 * @param {Object} props defined in propTypes
 */
function FormPhoneNumberField(props) {
	const { name, id, label, required } = props;
	return (
		<FormTextField
			label={label}
			id={id}
			name={name}
			type="tel"
			required={required}
		/>
	);
}

FormPhoneNumberField.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool
};

FormPhoneNumberField.defaultProps = {
	required: false
};

export default FormPhoneNumberField;
