import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import IconClose from "@mui/icons-material/Close";
import { Box, Chip, IconButton, Popover, Tooltip } from "@mui/material";
import { useField } from "formik";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { differenceInDays } from "date-fns";
import { DATE_RANGE_KEY, TIME_ZONE_KEY } from "./constants";
import { todayInTimezone } from "../../utils/dateUtils";
import lc from "./localeContent";
import { TIMEZONE_CONFIGS, TIMEZONES } from "../../utils/timeZoneUtils";

const defaultTimezoneConfig = { xsSize: 6, buttonWidth: "100%" };
const TIMEZONE_OPTIONS_STYLE_OVERRIDES = {
	Z: { xsSize: 12, buttonWidth: "70%" }
};

function TimeZoneCard({ updateTimeZone }) {
	return (
		<>
			{Object.keys(TIMEZONE_CONFIGS).map(timeZoneKey => {
				const { xsSize, buttonWidth } =
					TIMEZONE_OPTIONS_STYLE_OVERRIDES[timeZoneKey] ||
					defaultTimezoneConfig;

				return (
					<Grid item xs={xsSize} key={`tz-${timeZoneKey}`} textAlign="center">
						<Button
							variant="outlined"
							sx={{ width: buttonWidth }}
							onClick={() => updateTimeZone(timeZoneKey)}
						>
							{TIMEZONE_CONFIGS[timeZoneKey].label}
						</Button>
					</Grid>
				);
			})}
		</>
	);
}

TimeZoneCard.propTypes = {
	updateTimeZone: PropTypes.func.isRequired
};

function TimeZoneFilter() {
	const [field, , helpers] = useField(TIME_ZONE_KEY);
	const [dateRangeField] = useField(DATE_RANGE_KEY);
	const allowTimeZoneChange = useRef(true);
	const timeZone = TIMEZONE_CONFIGS[field.value]?.shortName;

	const today = todayInTimezone(field.value);
	const moreThan31DaysReporting =
		differenceInDays(today, dateRangeField.value[0]) > 31;

	const [anchorEl, setAnchorEl] = useState(null);

	const openFilterMenu = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const updateTimeZone = useCallback(
		timeZoneValue => {
			setAnchorEl(null);
			helpers.setValue(timeZoneValue);
		},
		[helpers]
	);

	useEffect(() => {
		if (moreThan31DaysReporting && allowTimeZoneChange.current) {
			updateTimeZone(TIMEZONES.UTC);
			allowTimeZoneChange.current = false;
		} else if (!moreThan31DaysReporting) {
			allowTimeZoneChange.current = true;
		}
	}, [moreThan31DaysReporting, updateTimeZone, allowTimeZoneChange]);

	const isFilterMenuOpen = Boolean(anchorEl);

	return (
		<>
			<Tooltip title={lc.TIME_ZONE_TOOLTIP}>
				<Chip
					label={timeZone || ""}
					onClick={openFilterMenu}
					sx={{ mr: 1 }}
					disabled={moreThan31DaysReporting}
					data-testid="timeZoneChip"
				/>
			</Tooltip>
			<Popover
				open={isFilterMenuOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left"
				}}
				sx={{ width: "650px" }}
			>
				<IconButton
					onClick={handleClose}
					aria-label={lc.BUTTON_LABEL_CLOSE_FILTER_MENU}
					sx={{ float: "right" }}
				>
					<IconClose />
				</IconButton>
				<Box sx={{ m: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 2 }}
					>
						<TimeZoneCard updateTimeZone={updateTimeZone} />
					</Grid>
				</Box>
			</Popover>
		</>
	);
}

export default TimeZoneFilter;
