export default {
	DELETE_MODAL: {
		TITLE: ({ resourceName }) => `Delete ${resourceName}`,
		DESCRIPTION: ({ rowItemName }) =>
			`You are about to delete ${rowItemName}. This action cannot be undone.`,
		ACTION_BUTTON_LABEL_CONFIRM: "Delete"
	},
	DEFAULT_DELETE_SUCCESS_MESSAGE: "Successfully deleted item",
	SHOW_HIDE_COLUMN_MENU_LABEL: "show/hide columns menu",
	PAGINATION_LABEL_FIRST_PAGE: "first page",
	PAGINATION_LABEL_PREVIOUS_PAGE: "previous page",
	PAGINATION_LABEL_NEXT_PAGE: "next page",
	PAGINATION_LABEL_LAST_PAGE: "last page",
	DELETE_ITEM_BUTTON_LABEL: ({ resourceName, rowValue }) =>
		`delete ${resourceName} ${rowValue}`
};
