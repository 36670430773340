import React from "react";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const BIDDER_SEAT_ITEM_TEST_ID = "bidder-seat-list-item";

function BidderSeatListItem(props) {
	const {
		bidderSeat,
		onSelectSeat,
		selectSeatIcon: SelectSeatIcon,
		onSelectActionLabel
	} = props;
	const ariaLabel = onSelectSeat
		? `${onSelectActionLabel} ${bidderSeat.seatName}`
		: undefined;
	return (
		<ListItem
			button={Boolean(onSelectSeat)}
			onClick={onSelectSeat ? () => onSelectSeat(bidderSeat) : undefined}
			sx={{ pl: 6 }}
			data-testid={BIDDER_SEAT_ITEM_TEST_ID}
			aria-label={ariaLabel}
		>
			{/* Empty icon for spacing */}
			<ListItemIcon />
			<ListItemText>{bidderSeat.seatName}</ListItemText>
			{onSelectSeat && <SelectSeatIcon />}
		</ListItem>
	);
}
BidderSeatListItem.propTypes = {
	bidderSeat: PropTypes.shape().isRequired,
	onSelectSeat: PropTypes.func,
	selectSeatIcon: PropTypes.shape().isRequired,
	onSelectActionLabel: PropTypes.string.isRequired
};
BidderSeatListItem.defaultProps = {
	onSelectSeat: null
};

export default BidderSeatListItem;
