import React from "react";
import PropTypes from "prop-types";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import muiLocaleOverrides from "../config/muiLocaleOverrides";

/**
 * Wrap all the MUI providers so future MUI migrations/upgrades are easier.
 */
function DateProviders(props) {
	const { children } = props;
	return (
		<LocalizationProvider
			dateAdapter={AdapterDateFns}
			localeText={muiLocaleOverrides}
		>
			{children}
		</LocalizationProvider>
	);
}

DateProviders.propTypes = {
	children: PropTypes.node.isRequired
};

export default DateProviders;
