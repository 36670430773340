import * as Yup from "yup";

import FormTextField from "../FormTextField/FormTextField";
import FormCurrencyField from "../FormCurrencyField/FormCurrencyField";
import FormPercentField from "../FormPercentField/FormPercentField";
import FormFieldRadio from "../FormFieldRadio/FormFieldRadio";
import FormCheckboxSingle from "../FormCheckboxSingle/FormCheckboxSingle";
import FormEmailField from "../FormEmailField/FormEmailField";
import FormPhoneNumberField from "../FormPhoneNumberField/FormPhoneNumberField";
import FormFieldComboBox from "../FormFieldComboBox/FormFieldComboBox";
import FormFieldSelect from "../FormFieldSelect/FormFieldSelect";
import FormFieldSwitch from "../FormFieldSwitch/FormFieldSwitch";
import localeContent from "./localeContent";
import FormFieldNumber from "../FormFieldNumber/FormFieldNumber";
import FormFieldTags from "../FormFieldTags/FormFieldTags";
import FormFieldMultiselect from "../FormFieldMultiselect/FormFieldMultiselect";
import FormFieldMultiselectTypeAhead from "../FormFieldMultiselectTypeAhead/FormFieldMultiselectTypeAhead";
import FormFieldBooleanMultiselect from "../FormFieldBooleanMultiselect/FormFieldBooleanMultiselect";
import FormFieldFrequencyCap from "../FormFieldFrequencyCap/FormFieldFrequencyCap";
import FormFieldDateTimePicker, {
	transformInitData as transformInitDataDateTimePicker,
	transformSubmitData as transformSubmitDataDateTimePicker
} from "../FormFieldDateTimePicker/FormFieldDateTimePicker";
import FormFieldMacroText from "../FormFieldMacroText/FormFieldMacroText";
import FormFieldDuration from "../FormFieldDuration/FormFieldDuration";
import FormFieldAccessRestriction, {
	ValidationSchema as ValidationSchemaAccessRestriction
} from "../FormFieldAccessRestriction/FormFieldAccessRestriction";
import FormFieldBidderSeats, {
	formFieldBidderSeatsValidationSchema
} from "../FormFieldBidderSeats/FormFieldBidderSeats";
import FormFieldBidderRegionConfig, {
	ValidationSchema as formFieldBidderRegionConfigValidationSchema
} from "../FormFieldBidderRegionConfig/FormFieldBidderRegionConfig";
import FormFieldPermissions from "../FormFieldPermissions/FormFieldPermissions";
import FormFieldBidderConfig, {
	ValidationSchema as formFieldBidderConfigValidationSchema
} from "../FormFieldBidderConfig/FormFieldBidderConfig";
import FormFieldDeviceTargeting, {
	transformInitData as transformInitDataDeviceTargeting,
	transformSubmitData as transformSubmitDataDeviceTargeting
} from "../FormFieldDeviceTargeting/FormFieldDeviceTargeting";
import FormFieldListTargeting, {
	transformInitData as transformInitDataListTargeting,
	transformSubmitData as transformSubmitDataListTargeting
} from "../FormFieldListTargeting/FormFieldListTargeting";
import FormFieldMediaFileManager from "../FormFieldMediaFileManager/FormFieldMediaFileManager";
import FormFieldFileUpload from "../FormFieldFileUpload/FormFieldFileUpload";
import FormFieldCloneRole from "../FormFieldCloneRole/FormFieldCloneRole";
import FormFieldImageUploadPreview from "../FormFieldImageUploadPreview/FormFieldImageUploadPreview";
import FormFieldSizeTargeting, {
	validationSchema as ValidationSchemaSizeTargeting,
	transformInitData as transformInitDataSizeTargeting,
	transformSubmitData as transformSubmitDataSizeTargeting,
	incrementBarTitleErrorCount as incrementBarTitleErrorCountSizeTargeting
} from "../FormFieldSizeTargeting/FormFieldSizeTargeting";
import FormFieldCustomQueryParameter, {
	ValidationSchema as ValidationSchemaCustomQueryParameter,
	transformInitData as transformInitDataCustomQueryParameter,
	transformSubmitData as transformSubmitDataCustomQueryParameter
} from "../FormFieldCustomQueryParameter/FormFieldCustomQueryParameter";
import FormFieldDayParting, {
	validationSchema as ValidationSchemaDayParting,
	transformInitData as transformInitDataDayParting,
	transformSubmitData as transformSubmitDataDayParting
} from "../FormFieldDayParting/FormFieldDayParting";
import FormFieldMediaUrl, {
	validationSchema as validationSchemaMediaUrl,
	transformSubmitData as transformSubmitDataMediaUrl
} from "../FormFieldMediaUrl/FormFieldMediaUrl";
import FormFieldGeoTargeting, {
	validationSchema as validationSchemaGeoTargeting,
	transformInitData as transformInitDataGeoTargeting,
	transformSubmitData as transformSubmitDataGeoTargeting
} from "../FormFieldGeoTargeting/FormFieldGeoTargeting";
import FormFieldPrimaryColor, {
	transformSubmitData as transformSubmitPrimaryColor,
	ValidationSchema as ValidationSchemaPrimaryColor,
	transformInitData as transformInitDataPrimaryColor
} from "../FormFieldPrimaryColor/FormFieldPrimaryColor";
import FormFieldExpressionBuilder, {
	transformInitData as transformInitDataExpressionBuilder,
	transformSubmitData as transformSubmitDataExpressionBuilder
} from "../FormFieldExpressionBuilder/FormFieldExpressionBuilder";
import FormFieldImageFileManager from "../FormFieldImageFileManager/FormFieldImageFileManager";
import FormFieldMetrics from "../FormFieldMetrics/FormFieldMetrics";
import CrudFormFieldDimensions, {
	transformInitData as transformInitDataDimensions,
	transformSubmitData as transformSubmitDataDimensions,
	validationSchema as validationSchemaDimensions
} from "../FormFieldDimensions/CrudFormFieldDimensions";
import FormFieldLargeList, {
	transformInitData as transformInitDataLargeList,
	transformSubmitData as transformSubmitLargeList
} from "../FormFieldLargeList/FormFieldLargeList";
import FormFieldCustomKey, {
	transformInitData as transformInitCustomKeys,
	transformSubmitData as transformSubmitCustomKeys,
	ValidationSchema as customKeysValidationSchema
} from "../FormFieldCustomKeys/FormFieldCustomKeys";
import FormQuill from "../FormQuill/FormQuill";

const FieldTypes = {
	ACCESS_RESTRICTION: {
		baseValidation: ValidationSchemaAccessRestriction,
		component: FormFieldAccessRestriction
	},
	ACCESS_RESTRICTION_FREEFORM: {
		component: FormFieldListTargeting,
		transformInitData: transformInitDataListTargeting,
		transformSubmitData: transformSubmitDataListTargeting,
		overrideTooltip: true
	},
	LARGE_LIST: {
		component: FormFieldLargeList,
		transformInitData: transformInitDataLargeList,
		transformSubmitData: transformSubmitLargeList,
		overrideTooltip: true
	},
	BIDDER_CONFIG: {
		component: FormFieldBidderConfig,
		baseValidation: formFieldBidderConfigValidationSchema,
		customErrorParseFunction: serverError => {
			const [error] = serverError;
			const transformedError = error.replace(/&quot;/g, '"');
			return JSON.parse(transformedError);
		}
	},
	BIDDER_REGION_CONFIG: {
		baseValidation: formFieldBidderRegionConfigValidationSchema,
		component: FormFieldBidderRegionConfig
	},
	BIDDER_SEATS: {
		baseValidation: formFieldBidderSeatsValidationSchema,
		component: FormFieldBidderSeats
	},
	BOOLEAN_MULTISELECT: {
		component: FormFieldBooleanMultiselect,
		overrideTooltip: true
	},
	NON_FIELD_COMPONENT: {
		isNonField: true
	},
	CHECKBOX_SINGLE: {
		baseValidation: Yup.bool(),
		component: FormCheckboxSingle
	},
	CLONE_ROLE: {
		component: FormFieldCloneRole,
		// Set default value as null instead of an empty string
		transformInitData: (initData, fieldName) => initData[fieldName] || null
	},
	COMBO_BOX: {
		baseValidation: Yup.string().nullable(),
		component: FormFieldComboBox
	},
	PRIMARY_COLOR: {
		component: FormFieldPrimaryColor,
		transformSubmitData: transformSubmitPrimaryColor,
		transformInitData: transformInitDataPrimaryColor,
		baseValidation: ValidationSchemaPrimaryColor
	},
	CURRENCY: {
		baseValidation: Yup.number().min(
			0,
			localeContent.NON_NEGATIVE_VALUE_WARNING
		),
		component: FormCurrencyField
	},
	DATE_TIME_PICKER: {
		baseValidation: Yup.date()
			.typeError(localeContent.INVALID_DATE_WARNING)
			.nullable(),
		component: FormFieldDateTimePicker,
		transformInitData: transformInitDataDateTimePicker,
		transformSubmitData: transformSubmitDataDateTimePicker,
		overrideTooltip: true
	},
	DEVICE_TARGETING: {
		component: FormFieldDeviceTargeting,
		transformInitData: transformInitDataDeviceTargeting,
		transformSubmitData: transformSubmitDataDeviceTargeting
	},
	DAY_PARTING: {
		component: FormFieldDayParting,
		transformInitData: transformInitDataDayParting,
		transformSubmitData: transformSubmitDataDayParting,
		baseValidation: ValidationSchemaDayParting
	},
	DIMENSIONS: {
		component: CrudFormFieldDimensions,
		transformInitData: transformInitDataDimensions,
		transformSubmitData: transformSubmitDataDimensions,
		baseValidation: validationSchemaDimensions
	},
	DURATION: {
		baseValidation: Yup.number(),
		component: FormFieldDuration
	},
	EMAIL: {
		baseValidation: Yup.string().email(localeContent.VALID_EMAIL_WARNING),
		component: FormEmailField
	},
	EXPRESSION_BUILDER: {
		component: FormFieldExpressionBuilder,
		transformInitData: transformInitDataExpressionBuilder,
		transformSubmitData: transformSubmitDataExpressionBuilder,
		baseValidation: Yup.object()
	},
	FILE_UPLOAD: {
		component: FormFieldFileUpload
	},
	FREQUENCY_CAP: {
		component: FormFieldFrequencyCap
	},
	GEO_TARGETING: {
		component: FormFieldGeoTargeting,
		transformInitData: transformInitDataGeoTargeting,
		transformSubmitData: transformSubmitDataGeoTargeting,
		baseValidation: validationSchemaGeoTargeting
	},
	IMAGE_FILE_MANAGER: {
		component: FormFieldImageFileManager,
		transformInitData: (initData, fieldName) => initData[fieldName] || {}
	},
	IMAGE_UPLOAD: {
		component: FormFieldImageUploadPreview
	},
	MACRO_TEXT: {
		component: FormFieldMacroText,
		baseValidation: Yup.string(),
		overrideTooltip: true
	},
	MEDIA_FILE_MANAGER: {
		component: FormFieldMediaFileManager,
		// Set default value as an array instead of an empty string
		transformInitData: (initData, fieldName) => initData[fieldName] || []
	},
	MEDIA_URL: {
		component: FormFieldMediaUrl,
		transformSubmitData: transformSubmitDataMediaUrl,
		baseValidation: validationSchemaMediaUrl
	},
	MULTISELECT: {
		baseValidation: Yup.array().of(Yup.string()),
		component: FormFieldMultiselect,
		overrideTooltip: true
	},
	MULTISELECT_TYPEAHEAD: {
		baseValidation: Yup.array().of(Yup.string()),
		component: FormFieldMultiselectTypeAhead,
		overrideTooltip: true
	},
	METRICS: {
		baseValidation: Yup.array().of(Yup.string()),
		component: FormFieldMetrics
	},
	NUMBER: {
		baseValidation: Yup.number(),
		component: FormFieldNumber
	},
	PERCENT: {
		baseValidation: Yup.number()
			.min(0, localeContent.NON_NEGATIVE_VALUE_WARNING)
			.max(100, localeContent.GREATER_THAN_ONE_HUNDRED_WARNING),
		component: FormPercentField
	},
	PERMISSIONS: {
		component: FormFieldPermissions
	},
	PHONE_NUMBER: {
		baseValidation: Yup.string().matches(
			/^[\W_0-9]+$/,
			localeContent.NO_ALPHA_CHARACTERS_WARNING
		),
		component: FormPhoneNumberField
	},
	RADIO: {
		baseValidation: Yup.string(),
		component: FormFieldRadio
	},
	SELECT: {
		baseValidation: Yup.string().nullable(),
		component: FormFieldSelect,
		overrideTooltip: true
	},
	SIZE_TARGETING: {
		component: FormFieldSizeTargeting,
		transformInitData: transformInitDataSizeTargeting,
		transformSubmitData: transformSubmitDataSizeTargeting,
		incrementBarTitleErrorCount: incrementBarTitleErrorCountSizeTargeting,
		baseValidation: ValidationSchemaSizeTargeting
	},
	CUSTOM_QUERY_PARAMETER: {
		component: FormFieldCustomQueryParameter,
		transformInitData: transformInitDataCustomQueryParameter,
		transformSubmitData: transformSubmitDataCustomQueryParameter,
		baseValidation: ValidationSchemaCustomQueryParameter
	},
	CUSTOM_TAG: {
		component: FormFieldCustomKey,
		transformInitData: transformInitCustomKeys,
		transformSubmitData: transformSubmitCustomKeys,
		baseValidation: customKeysValidationSchema
	},
	SWITCH: {
		baseValidation: Yup.bool(),
		component: FormFieldSwitch
	},
	TAGS: {
		baseValidation: Yup.array(),
		component: FormFieldTags,
		overrideTooltip: true
	},
	TEXT: {
		baseValidation: Yup.string(),
		component: FormTextField
	},
	RICH_TEXT: {
		component: FormQuill,
		baseValidation: Yup.string()
	}
};

/**
 * Gets the FieldType from fieldConfig or else returns a Text field
 * @param {Object} fieldType a FieldType in imports
 */
export const getFieldTypeOrDefault = fieldConfig => {
	return fieldConfig.fieldType ? fieldConfig.fieldType : FieldTypes.TEXT;
};

export default FieldTypes;
