// Component design based on https://material-ui.com/components/tables/#custom-pagination-actions
import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import lc from "./localeContent";

function DataTablePaginationActions(props) {
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = event => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = event => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = event => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = event => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	const arePreviousButtonsDisabled = page === 0;
	const areNextButtonsDisabled = page >= Math.ceil(count / rowsPerPage) - 1;

	return (
		<Box
			sx={{
				flexShrink: 0,
				ml: 2.5
			}}
		>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={arePreviousButtonsDisabled}
				aria-label={lc.PAGINATION_LABEL_FIRST_PAGE}
				data-testid="first-page-button"
			>
				<FirstPageIcon />
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={arePreviousButtonsDisabled}
				aria-label={lc.PAGINATION_LABEL_PREVIOUS_PAGE}
				data-testid="previous-page-button"
			>
				<KeyboardArrowLeftIcon />
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={areNextButtonsDisabled}
				aria-label={lc.PAGINATION_LABEL_NEXT_PAGE}
				data-testid="next-page-button"
			>
				<KeyboardArrowRightIcon />
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={areNextButtonsDisabled}
				aria-label={lc.PAGINATION_LABEL_LAST_PAGE}
				data-testid="last-page-button"
			>
				<LastPageIcon />
			</IconButton>
		</Box>
	);
}

DataTablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired
};

export default DataTablePaginationActions;
