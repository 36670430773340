import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox } from "@mui/material";

function BooleanCheckbox(props) {
	const { label, checked, disabled, onChange } = props;

	const handleChange = event => {
		onChange(event.target.checked);
	};

	return (
		<FormControlLabel
			control={<Checkbox />}
			label={label}
			checked={checked}
			disabled={disabled}
			onChange={handleChange}
		/>
	);
}

BooleanCheckbox.propTypes = {
	label: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired
};

BooleanCheckbox.defaultProps = {
	disabled: false
};

export default BooleanCheckbox;
