import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import TextField from "../FormTextField/TextField";
import Tooltip from "../Tooltip/Tooltip";
import defaultMacroMenuConfig from "./defaultMacroMenuConfig";
import MacroAutoComplete from "../MacroAutoComplete/MacroAutoComplete";

// Rationale for the forwardRef is explained here https://stackoverflow.com/questions/56307332/how-to-use-custom-functional-components-within-material-ui-menu
const CategoryMenuItem = React.forwardRef((props, ref) => {
	const { label, categoryKey, expanded, handleToggleCategory } = props;
	return (
		<MenuItem ref={ref} onClick={handleToggleCategory(categoryKey)} dense>
			<ListItemText primary={label} />
			{expanded ? <ExpandLess /> : <ExpandMore />}
		</MenuItem>
	);
});
CategoryMenuItem.propTypes = {
	label: PropTypes.string.isRequired,
	categoryKey: PropTypes.string.isRequired,
	expanded: PropTypes.bool.isRequired,
	handleToggleCategory: PropTypes.func.isRequired
};

function MacroMenuItem(props) {
	const { label, tooltip, handleMacroSelection } = props;
	return (
		<MenuItem
			onClick={handleMacroSelection}
			sx={{ pl: 6 }}
			dense
			aria-label={label}
		>
			<Tooltip title={tooltip}>
				<ListItemText primary={label} />
			</Tooltip>
		</MenuItem>
	);
}
MacroMenuItem.propTypes = {
	label: PropTypes.string.isRequired,
	handleMacroSelection: PropTypes.func.isRequired,
	tooltip: PropTypes.string
};
MacroMenuItem.defaultProps = {
	tooltip: ""
};

/**
 * We need to map the array of category config objects to a flat array of
 * menuItem config objects (category and macro) to preserve menu interactivity
 * @param {Object} macroMenuConfig
 */
function FormFieldMacroText(props) {
	const { id, label, name, tooltip, required, fieldTypeOptions } = props;
	const { macroMenuConfig = defaultMacroMenuConfig } = fieldTypeOptions;
	const macroMenuItemsConfig = useMemo(() => {
		return macroMenuConfig.map(menu => {
			menu.id = menu.label;
			menu.name = menu.label;
			return menu;
		});
	}, [macroMenuConfig]);

	const [field, meta, helpers] = useField(name);
	const showError = Boolean(meta.touched && meta.error);
	const helperText = showError ? meta.error : "";

	const [anchorEl, setAnchorEl] = useState(null);
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const handleMacroMenuClose = () => {
		setAnchorEl(null);
	};

	const handleTooltipOpen = () => {
		setTooltipOpen(!anchorEl);
	};
	const handleTooltipClose = () => {
		setTooltipOpen(false);
	};

	const inputRef = useRef(null);

	const handleMacroSelection = macro => {
		if (inputRef.current) {
			const { selectionStart, selectionEnd } = inputRef.current;
			let replacedString;
			if (meta.touched) {
				// Replace the current selection with the macro
				replacedString =
					field.value.slice(0, selectionStart) +
					macro +
					field.value.slice(selectionEnd);
			} else {
				// Add the macro to the end of the field value and make sure we scroll to show it
				replacedString = field.value + macro;
				inputRef.current.scrollTop = inputRef.current.scrollHeight;
			}
			helpers.setValue(replacedString);
		}
		handleMacroMenuClose();
	};

	const formControl = (
		<Grid item container justifyContent="flex-end">
			<MacroAutoComplete
				items={macroMenuItemsConfig}
				handleMacroSelection={handleMacroSelection}
			/>
			<Grid item xs={12}>
				<TextField
					id={id}
					name={name}
					label={label}
					value={field.value}
					onChange={field.onChange}
					onBlur={field.onBlur}
					showError={showError}
					helperText={helperText}
					required={required}
					maxRows={4}
					// This is a material-ui prop so we have no control over this
					// eslint-disable-next-line react/jsx-no-duplicate-props
					InputProps={{ sx: { fontFamily: "monospace" } }}
					multiline
					inputRef={inputRef}
				/>
			</Grid>
		</Grid>
	);
	return tooltip ? (
		<Tooltip
			open={tooltipOpen}
			onClose={handleTooltipClose}
			onOpen={handleTooltipOpen}
			title={tooltip}
		>
			{formControl}
		</Tooltip>
	) : (
		formControl
	);
}

FormFieldMacroText.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	fieldTypeOptions: PropTypes.shape({
		macroMenuConfig: PropTypes.arrayOf(PropTypes.shape())
	}),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
};

FormFieldMacroText.defaultProps = {
	required: false,
	fieldTypeOptions: {},
	tooltip: null
};

export default FormFieldMacroText;
