import React, { useState, useRef, useContext } from "react";
import { useField } from "formik";
import { Box, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

import useFileUpload from "../../hooks/useFileUpload";
import { SnackbarContext } from "../../context/SnackbarContext";
import localeContent from "./localeContent";
import { GENERIC_API_ERROR } from "../../hooks/useResource";
import { MEDIA_TYPE_DISPLAY_ID } from "../../config/constants";
import FileUploadButton from "../FileUploadButton/FileUploadButton";

export const IMAGE_FILE_UPLOAD_INPUT_TEST_ID = "image-file-upload-input";
const ACCEPTED_TYPES = ["png", "jpg", "gif"];

function FormFieldImageFileManager(props) {
	const { name } = props;
	const [field, , helpers] = useField(name);
	const imageRef = useRef({});
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const [fileExtensionError, setFileExtensionError] = useState("");

	const { uploadFileAsync } = useFileUpload("manage/mediaAssets/display");

	const handleUploadImage = async file => {
		if (file) {
			const extension = file.name
				.split(".")
				.pop()
				.toLowerCase();

			if (ACCEPTED_TYPES.some(type => type === extension)) {
				setFileExtensionError("");

				const { data: uploadedFile, error } = await uploadFileAsync({
					file,
					mediaType: MEDIA_TYPE_DISPLAY_ID
				});

				if (error) {
					triggerNewSnackbarMessage({
						message: error.message || GENERIC_API_ERROR,
						severity: "error"
					});
				} else {
					triggerNewSnackbarMessage({
						message: localeContent.UPLOAD_IMAGE_SUCCESS_MESSAGE({
							name: file.name
						}),
						severity: "success"
					});
					imageRef.current.src = uploadedFile.previewURL;
					helpers.setValue({ ...field.value, ...uploadedFile });
				}
			} else {
				setFileExtensionError(localeContent.FILE_EXTENSION_ERROR_MESSAGE);
			}
		}
	};

	return (
		<>
			<FileUploadButton
				name={name}
				label={localeContent.BUTTON_LABEL}
				acceptedFileTypes={ACCEPTED_TYPES.map(type => `.${type}`)}
				onFileUpload={handleUploadImage}
			/>
			{fileExtensionError && (
				<FormHelperText error>{fileExtensionError}</FormHelperText>
			)}
			{field.value.id && (
				<Box mt={2}>
					<span>{localeContent.DISPLAY_PREVIEW_LABEL}</span>
					<Box
						sx={{
							width: 0,
							mt: 1,
							borderRadius: 1,
							bgcolor: "lightgrey"
						}}
					>
						<img
							ref={imageRef}
							src={field.value.previewURL}
							alt={localeContent.PREVIEW_ALT_TEXT}
							height="200"
						/>
					</Box>
				</Box>
			)}
		</>
	);
}

FormFieldImageFileManager.propTypes = {
	name: PropTypes.string.isRequired
};

FormFieldImageFileManager.defaultProps = {};

export default FormFieldImageFileManager;
