import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useField, Formik } from "formik";
import Grid from "@mui/material/Grid";
import IconPublish from "@mui/icons-material/Publish";
import Dialog from "@mui/material/Dialog";
import { SnackbarContext } from "../../context/SnackbarContext";
import UploadMediaFileForm, {
	KEY_UPLOAD_NAME,
	KEY_FILE_NAME,
	KEY_MIME_TYPE,
	KEY_HEIGHT,
	KEY_WIDTH,
	KEY_BITRATE,
	VALIDATION_SCHEMA,
	DEFAULT_FORM_VALUES,
	UPLOAD_FILE_MENU_TITLE_ID
} from "./UploadMediaFileForm";
import useFileUpload from "../../hooks/useFileUpload";
import { isEmpty, objectMap } from "../../utils/objectUtils";
import ButtonContainedDark from "../ButtonContainedDark/ButtonContainedDark";
import MediaFileTable from "./MediaFileTable";
import lc from "./localeContent";
import { buildColumnDefinition } from "../../utils/ReactTableUtils/ReactTableUtils";

const mediaFileColumnsConfig = [
	buildColumnDefinition({ Header: "Creative Name", accessor: KEY_UPLOAD_NAME }),
	buildColumnDefinition({ Header: "File Name", accessor: KEY_FILE_NAME }),
	buildColumnDefinition({ Header: "Mime Type", accessor: KEY_MIME_TYPE }),
	buildColumnDefinition({
		Header: "Height",
		accessor: KEY_HEIGHT,
		type: "number"
	}),
	buildColumnDefinition({
		Header: "Width",
		accessor: KEY_WIDTH,
		type: "number"
	}),
	buildColumnDefinition({
		Header: "Bitrate",
		accessor: KEY_BITRATE,
		type: "number"
	})
];

const MEDIA_ID_KEY = "mediaId";

function FormFieldMediaFileManager(props) {
	const { name, crudFormResourceId } = props;
	const [field, , helpers] = useField(name);
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const [open, setOpen] = useState(false);

	const openFileUploadModal = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason !== "backdropClick") {
			setOpen(false);
		}
	};

	const { uploadFileAsync } = useFileUpload("manage/mediaAssets/video");

	const handleUploadFile = async (formValues, actions) => {
		const submissionValues = { ...formValues };
		if (crudFormResourceId) {
			submissionValues[MEDIA_ID_KEY] = crudFormResourceId;
		}
		const { data: uploadedFile, error } = await uploadFileAsync(
			submissionValues
		);
		if (error) {
			const { detail, message } = error;
			if (detail) {
				actions.setErrors(objectMap(detail, value => value.join(" - ")));
			} else if (message) {
				triggerNewSnackbarMessage({ message, severity: "error" });
			}
		} else {
			triggerNewSnackbarMessage({
				message: `Uploaded ${uploadedFile[KEY_UPLOAD_NAME]}`,
				severity: "success"
			});
			setOpen(false);
			helpers.setValue([...field.value, uploadedFile]);
		}
	};

	const handleDeleteRow = id => {
		helpers.setValue(field.value.filter(mediaFile => mediaFile.id !== id));
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<ButtonContainedDark
						label={lc.UPLOAD_MEDIA_FILE_BUTTON_LABEL}
						onClick={openFileUploadModal}
						StartIconComponent={IconPublish}
					/>
				</Grid>
				{!isEmpty(field.value) && (
					<Grid item xs={12}>
						<MediaFileTable
							columns={mediaFileColumnsConfig}
							data={field.value}
							onDeleteRow={handleDeleteRow}
							accessibleTableLabel={lc.FILE_LIST_ACCESSIBLE_TABLE_DESCRIPTION}
						/>
					</Grid>
				)}
			</Grid>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby={UPLOAD_FILE_MENU_TITLE_ID}
				fullWidth
				maxWidth="md"
			>
				<Formik
					initialValues={DEFAULT_FORM_VALUES}
					validationSchema={VALIDATION_SCHEMA}
					onSubmit={handleUploadFile}
				>
					<UploadMediaFileForm onClose={handleClose} />
				</Formik>
			</Dialog>
		</>
	);
}

FormFieldMediaFileManager.propTypes = {
	name: PropTypes.string.isRequired,
	crudFormResourceId: PropTypes.string
};

FormFieldMediaFileManager.defaultProps = {
	crudFormResourceId: null
};

export default FormFieldMediaFileManager;
