// Component design based on https://material-ui.com/components/drawers/#full-height-navigation
import React, { useContext } from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";

import RouterLink from "../../Routing/LinkWithQuery";
import { NAV_RAIL_WIDTH as drawerWidth } from "../../../config/constants";
import { AuthContext } from "../../../context/AuthContext";
import LogoutItem from "./LogoutItem";
import lc from "../localeContent";

function NavRail(props) {
	const authContext = useContext(AuthContext);
	const {
		getBrandingResource,
		managementNavItems,
		userNavItems,
		onOpenCompanySwitch,
		onManagementNavItemClicked,
		activeMenuItemKey
	} = props;

	const CompanyListItemComponent = onOpenCompanySwitch
		? ListItemButton
		: ListItem;
	return (
		<Drawer
			sx={{
				display: "flex",
				flexDirection: "column",
				"& .MuiDrawer-paper": {
					width: drawerWidth
				}
			}}
			variant="permanent"
			anchor="left"
		>
			<List>
				<CompanyListItemComponent
					onClick={onOpenCompanySwitch}
					sx={{ justifyContent: "center" }}
					aria-label={lc.ARIA_LABEL_COMPANY_SWITCH_MENU}
				>
					<ListItemAvatar sx={{ minWidth: 0 }}>
						<Avatar
							src={getBrandingResource("branding/favicon")}
							alt={lc.AVATAR_ALT_TEXT}
							imgProps={{
								style: {
									objectFit: "scale-down",
									padding: "4px"
								}
							}}
							sx={theme => ({
								width: theme.spacing(4),
								height: theme.spacing(4),
								backgroundColor: "secondary.800"
							})}
							variant="circular"
						/>
					</ListItemAvatar>
				</CompanyListItemComponent>
			</List>
			<Box flexGrow={1}>
				<List>
					{managementNavItems.map(navItem => (
						<Tooltip
							key={navItem.key}
							title={navItem.tooltip}
							placement="right"
							arrow
						>
							<ListItemButton
								onClick={onManagementNavItemClicked(navItem.key)}
								aria-label={navItem.ariaLabel}
								sx={{ justifyContent: "center" }}
							>
								<ListItemIcon sx={{ minWidth: 0 }}>
									<navItem.iconComponent
										color={
											navItem.key === activeMenuItemKey ? "primary" : "inherit"
										}
									/>
								</ListItemIcon>
							</ListItemButton>
						</Tooltip>
					))}
				</List>
			</Box>
			<Box mb={1}>
				<List>
					{userNavItems.map(userNavItem => (
						<Tooltip
							key={userNavItem.key}
							title={userNavItem.tooltip}
							placement="right"
							arrow
						>
							<ListItemButton
								selected={userNavItem.key === activeMenuItemKey}
								aria-label={userNavItem.ariaLabel}
								sx={{ justifyContent: "center" }}
								onClick={userNavItem.onClick ? userNavItem.onClick : null}
								component={userNavItem.route ? RouterLink : ListItem}
								to={userNavItem.route ? userNavItem.route : null}
							>
								<ListItemIcon sx={{ minWidth: 0 }}>
									<userNavItem.iconComponent
										color={
											userNavItem.key === activeMenuItemKey
												? "primary"
												: "inherit"
										}
									/>
								</ListItemIcon>
							</ListItemButton>
						</Tooltip>
					))}
					<LogoutItem
						activeMenuItemKey={activeMenuItemKey}
						onClick={authContext.logout}
					/>
				</List>
			</Box>
		</Drawer>
	);
}

NavRail.propTypes = {
	getBrandingResource: PropTypes.func.isRequired,
	managementNavItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
	userNavItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
	onOpenCompanySwitch: PropTypes.func,
	onManagementNavItemClicked: PropTypes.func.isRequired,
	activeMenuItemKey: PropTypes.string
};

NavRail.defaultProps = {
	activeMenuItemKey: "",
	onOpenCompanySwitch: undefined
};

export default NavRail;
