import React from "react";
import PropTypes from "prop-types";
import MuiTextField from "@mui/material/TextField";

/**
 * A wrapper around the mui TextField that:
 * @param {Object} props defined in propTypes
 */
function TextField(props) {
	const {
		id,
		label,
		name,
		required,
		disabled,
		readOnly,
		type,
		showError,
		helperText,
		inputProps,
		InputProps,
		value,
		onChange,
		onBlur,
		multiline,
		rows,
		rowsMax,
		inputRef
	} = props;
	return (
		<MuiTextField
			id={id}
			name={name}
			label={label}
			type={type}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			disabled={disabled}
			error={showError}
			helperText={helperText}
			inputProps={inputProps}
			// This is a material-ui prop so we have no control over this
			// eslint-disable-next-line react/jsx-no-duplicate-props
			InputProps={{ readOnly, ...InputProps }}
			variant="filled"
			autoComplete="off"
			required={required}
			multiline={multiline}
			minRows={rows}
			maxRows={rowsMax}
			fullWidth
			inputRef={inputRef}
		/>
	);
}

TextField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	helperText: PropTypes.string,
	type: PropTypes.string,
	inputProps: PropTypes.shape(),
	InputProps: PropTypes.shape(),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	showError: PropTypes.bool,
	multiline: PropTypes.bool,
	rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	inputRef: PropTypes.shape()
};

TextField.defaultProps = {
	required: false,
	disabled: false,
	readOnly: false,
	helperText: null,
	type: "text",
	inputProps: {},
	InputProps: {},
	value: "",
	onChange: null,
	onBlur: null,
	showError: false,
	multiline: false,
	rows: null,
	rowsMax: null,
	inputRef: undefined
};

export default TextField;
