import React, {
	useState,
	useCallback,
	useMemo,
	useContext,
	useEffect
} from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Typography, Container, Grid } from "@mui/material";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import { Operations, useResourceClient } from "../../hooks/useResource";
import AlignmentTableSingle from "./AlignmentTableSingle";
import { SnackbarContext } from "../../context/SnackbarContext";
import localeContent from "./localeContent";
import FiltersToolbar from "../FiltersToolbar/FiltersToolbar";
import { FILTER_TYPE, TABLE_DATA_TYPE } from "../../config/constants";
import { useResourceAsync } from "../../hooks/useResourceAsync";

export const AVAILABLE_TABLE_ID = "available";
export const ALIGNED_TABLE_ID = "aligned";

// We need stable refs to request operations to prevent infinite requests
const ALIGNMENT_ENDPOINT_OPERATION = {
	...Operations.ONE,
	getResourceEndpoint: (baseResourcePath, params) =>
		`manage/${baseResourcePath}/${params.resourceId}/alignments`
};
// We need a stable ref to an operation to prevent infinite requests
const ALIGNMENT_UPDATE_ENDPOINT_OPERATION = {
	...Operations.UPDATE,
	getResourceEndpoint: (baseResourcePath, params) =>
		`manage/${baseResourcePath}/${params.resourceId}/alignments`
};

const filterAlignmentOptions = (options, filters) => {
	return filters.reduce(
		(filteredOptionsList, filterConfig) =>
			filteredOptionsList.filter(option => {
				const optionDimensionValue = option[filterConfig.dimension];
				const { comparator, type, value } = filterConfig;
				switch (type) {
					case FILTER_TYPE.PERCENTAGE:
					case FILTER_TYPE.CURRENCY: {
						let filterValue = value;
						if (type === FILTER_TYPE.PERCENTAGE) {
							filterValue /= 100;
						}
						switch (comparator) {
							case "=":
								return optionDimensionValue === filterValue;
							case ">":
								return optionDimensionValue > filterValue;
							case ">=":
								return optionDimensionValue >= filterValue;
							case "<":
								return optionDimensionValue < filterValue;
							case "<=":
								return optionDimensionValue <= filterValue;
							default:
								return false;
						}
					}
					case FILTER_TYPE.ALPHA_SINGLE:
						return value === optionDimensionValue;
					case FILTER_TYPE.ALPHA_MULTIPLE:
					default:
						return (value || []).includes(optionDimensionValue);
				}
			}),
		[...options]
	);
};

const getFilterType = (type, filterType) => {
	if (filterType) {
		return filterType;
	}
	switch (type) {
		case TABLE_DATA_TYPE.CURRENCY:
			return FILTER_TYPE.CURRENCY;
		case TABLE_DATA_TYPE.PERCENTAGE:
			return FILTER_TYPE.PERCENTAGE;
		default:
			return FILTER_TYPE.ALPHA_MULTIPLE;
	}
};

function AlignmentTool(props) {
	const {
		columns: allColumns,
		tableOptionLabelAvailable,
		tableOptionLabelAligned,
		availableOptionsEndpoint,
		availableOptionsIdKey,
		alignmentEndpoint,
		mapAvailableOptions,
		mapAlignedOptions,
		defaultShownColumns,
		onSave,
		resourceId,
		formContext,
		entityTypeLabel
	} = props;
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);
	const { values } = useFormikContext();

	const [potentialRedirectLocation, setPotentialRedirectLocation] = useState(
		null
	);
	const [redirect, setRedirect] = useState(null);

	const columns = useMemo(() => {
		return allColumns.filter(({ renderIf }) => renderIf?.(values) ?? true);
		// following comment is added as selected columns list were getting lost on save action etc, as values are latest always so it works for now
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [filters, setFilters] = useState([]);
	const [commonPageSize, setCommonPageSize] = useState(15);
	// Setup the table selection options and state
	const tableSelectionOptions = useMemo(
		() => [
			{ label: tableOptionLabelAvailable, value: AVAILABLE_TABLE_ID },
			{ label: tableOptionLabelAligned, value: ALIGNED_TABLE_ID }
		],
		[tableOptionLabelAvailable, tableOptionLabelAligned]
	);
	const [tableSelectionValue, setTableSelectionValue] = useState(
		AVAILABLE_TABLE_ID
	);

	// Setup hiddenColumns config
	const defaultHiddenColumns = useMemo(() => {
		return columns
			.filter(column => !defaultShownColumns.includes(column.accessor))
			.map(column => column.accessor);
	}, [columns, defaultShownColumns]);

	const {
		data: availableOptionsDataRaw,
		error: availableOptionsError,
		isLoading: isAvailableOptionsLoading,
		execute: requestAvailableOptionsData
	} = useResourceAsync(availableOptionsEndpoint, Operations.LIST, {
		preserveDataOnExecute: true
	});
	// Some endpoints provide data using a non-default data structure (something other than a simple array of options)
	const availableOptionsData = useMemo(
		() => mapAvailableOptions(availableOptionsDataRaw),
		[mapAvailableOptions, availableOptionsDataRaw]
	);

	const [
		alignedOptionsData,
		alignedOptionsError,
		isAlignedOptionsLoading,
		requestAlignedOptionsData
	] = useResourceClient(alignmentEndpoint, ALIGNMENT_ENDPOINT_OPERATION);
	// Whenever the resourceId changes, we need to request available and existing alignments
	useEffect(() => {
		if (resourceId) {
			requestAvailableOptionsData({
				requestParams: { [availableOptionsIdKey]: resourceId }
			});
			requestAlignedOptionsData({ resourceId });
		}
	}, [
		resourceId,
		requestAvailableOptionsData,
		requestAlignedOptionsData,
		availableOptionsIdKey
	]);

	const {
		data: saveAlignmentsData,
		isLoading: isSaveAlignmentsSubmitting,
		execute: submitAlignments
	} = useResourceAsync(alignmentEndpoint, ALIGNMENT_UPDATE_ENDPOINT_OPERATION, {
		preserveDataOnExecute: true
	});

	const { setDirtyOverride } = formContext;
	useEffect(() => {
		if (saveAlignmentsData) {
			setDirtyOverride({});
			setRedirect(potentialRedirectLocation);
		}
	}, [saveAlignmentsData, potentialRedirectLocation, setDirtyOverride]);

	const onSaveAlignments = useCallback(
		async alignmentObjects => {
			const saveData = onSave(alignmentObjects);
			const { data, error } = await submitAlignments({
				resourceId,
				data: {
					id: resourceId,
					...saveData
				}
			});
			if (error) {
				const { message } = error;
				triggerNewSnackbarMessage({ message, severity: "error" });
			}
			if (data) {
				const message = localeContent.ALIGNMENTS_SAVED_SUCCESS_MESSAGE;
				triggerNewSnackbarMessage({ message, severity: "success" });
			}
		},
		[onSave, submitAlignments, resourceId, triggerNewSnackbarMessage]
	);

	const alignmentsData = useMemo(
		() => mapAlignedOptions(saveAlignmentsData || alignedOptionsData),
		[saveAlignmentsData, alignedOptionsData, mapAlignedOptions]
	);

	const filteredAlignmentData = useMemo(
		() => filterAlignmentOptions(alignmentsData, filters),
		[alignmentsData, filters]
	);

	const availableOptions = useMemo(() => {
		if (!availableOptionsData || !alignmentsData) return [];
		const alignedIds = alignmentsData.reduce(
			(aggregator, alignedOption) => ({
				...aggregator,
				[alignedOption.id]: true
			}),
			{}
		);
		const unselectedOptions = availableOptionsData.filter(
			({ id }) => !alignedIds[id]
		);
		return filterAlignmentOptions(unselectedOptions, filters);
	}, [availableOptionsData, alignmentsData, filters]);

	const filterDimensionOptions = useMemo(
		() =>
			columns
				.filter(({ accessor }) => accessor !== "name")
				.map(({ accessor, Header, type, ext = {} }) => {
					const { filterType } = ext;
					return {
						value: accessor,
						label: Header,
						type: getFilterType(type, filterType)
					};
				}),
		[columns]
	);

	const dimensionValuesOptions = useMemo(() => {
		const alphaDimensions = filterDimensionOptions.filter(
			({ type }) => type === FILTER_TYPE.ALPHA_MULTIPLE
		);
		// First, we need all the distinct dimensions options
		const uniqueOptionsMap = (availableOptionsData || []).reduce(
			(dimensionValuesMap, availableOption) => ({
				...alphaDimensions.reduce((aggregator, dimension) => {
					// Ignore undefined and null values when constructing options
					const extensionValue = {};
					const availableOptionValue = availableOption[dimension.value];
					if (
						availableOptionValue !== undefined &&
						availableOptionValue !== null
					) {
						extensionValue[availableOptionValue] = true;
					}
					return {
						...aggregator,
						[dimension.value]: {
							...dimensionValuesMap[dimension.value],
							...extensionValue
						}
					};
				}, {})
			}),
			{}
		);
		// Convert unique options map to select options objects
		return Object.entries(uniqueOptionsMap).reduce(
			(aggregator, [dimension, optionsMap]) => ({
				...aggregator,
				[dimension]: Object.keys(optionsMap).map(key => ({
					id: key,
					name: key
				}))
			}),
			{}
		);
	}, [filterDimensionOptions, availableOptionsData]);

	const [editedTableId, setEditedTableId] = useState(null);
	const setEditedTable = useCallback(tableId => {
		setEditedTableId(tableId);
		if (tableId) {
			setTableSelectionValue(tableId);
		}
	}, []);
	return (
		<>
			{redirect && <Redirect to={redirect} />}
			<FiltersToolbar
				filters={filters}
				setFilters={setFilters}
				dimensionOptions={filterDimensionOptions}
				dimensionValuesOptions={dimensionValuesOptions}
			/>
			<Container maxWidth={false}>
				<LoadingBackdrop
					isOpen={
						isAvailableOptionsLoading ||
						isAlignedOptionsLoading ||
						isSaveAlignmentsSubmitting
					}
				/>
				{availableOptionsError || alignedOptionsError ? (
					<Typography>
						Could not load options, please refresh to try again
					</Typography>
				) : (
					<Grid container spacing={2}>
						<Grid item xs={12} lg={6}>
							<AlignmentTableSingle
								tableId={AVAILABLE_TABLE_ID}
								tableSelectionOptions={tableSelectionOptions}
								tableSelectionValue={tableSelectionValue}
								setTableSelectionValue={setTableSelectionValue}
								editedTableId={editedTableId}
								setEditedTable={setEditedTable}
								accessibleTableLabel={tableOptionLabelAvailable}
								selectionText={
									localeContent.SELECTED_OPTIONS_COUNT_LABEL.AVAILABLE
								}
								columns={columns}
								data={availableOptions}
								defaultHiddenColumns={defaultHiddenColumns}
								onSubmit={selectedRows => {
									// We need to align the selectedRows
									const alignmentObjects = [...alignmentsData, ...selectedRows];
									onSaveAlignments(alignmentObjects);
								}}
								commonPageSize={commonPageSize}
								setCommonPageSize={setCommonPageSize}
								setDirtyOverride={formContext.setDirtyOverride}
								setPotentialRedirectLocation={setPotentialRedirectLocation}
								entityTypeLabel={entityTypeLabel}
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<AlignmentTableSingle
								tableId={ALIGNED_TABLE_ID}
								tableSelectionOptions={tableSelectionOptions}
								tableSelectionValue={tableSelectionValue}
								setTableSelectionValue={setTableSelectionValue}
								editedTableId={editedTableId}
								setEditedTable={setEditedTable}
								accessibleTableLabel={tableOptionLabelAligned}
								selectionText={
									localeContent.SELECTED_OPTIONS_COUNT_LABEL.ALIGNED
								}
								columns={columns}
								data={filteredAlignmentData}
								defaultHiddenColumns={defaultHiddenColumns}
								onSubmit={selectedRows => {
									// We need to unalign the selectedRowIds
									const selectedRowIds = selectedRows.map(({ id }) => id);
									const alignmentObjects = alignmentsData.filter(
										({ id }) => !selectedRowIds.includes(id)
									);
									onSaveAlignments(alignmentObjects);
								}}
								commonPageSize={commonPageSize}
								setCommonPageSize={setCommonPageSize}
								setDirtyOverride={formContext.setDirtyOverride}
								setPotentialRedirectLocation={setPotentialRedirectLocation}
								entityTypeLabel={entityTypeLabel}
							/>
						</Grid>
					</Grid>
				)}
			</Container>
		</>
	);
}

AlignmentTool.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	tableOptionLabelAvailable: PropTypes.string.isRequired,
	tableOptionLabelAligned: PropTypes.string.isRequired,
	availableOptionsEndpoint: PropTypes.string.isRequired,
	availableOptionsIdKey: PropTypes.string.isRequired,
	entityTypeLabel: PropTypes.string,
	alignmentEndpoint: PropTypes.string.isRequired,
	onSave: PropTypes.func.isRequired,
	defaultShownColumns: PropTypes.arrayOf(PropTypes.string),
	mapAvailableOptions: PropTypes.func,
	mapAlignedOptions: PropTypes.func,
	resourceId: PropTypes.string,
	formContext: PropTypes.shape().isRequired
};

AlignmentTool.defaultProps = {
	defaultShownColumns: [],
	mapAvailableOptions: options => options || [],
	mapAlignedOptions: options => options || [],
	resourceId: null,
	entityTypeLabel: null
};

export default AlignmentTool;
