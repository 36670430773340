export default {
	PAGE_HEADING: "Reporting",
	METRIC_LABELS: {
		OPPORTUNITIES: "Opportunities",
		IMPRESSIONS: "Impressions",
		FILL_RATE: "Fill Rate",
		GROSS_CPM: "Gross CPM",
		GROSS_REVENUE: "Gross Revenue",
		FILTERED_REQUESTS: "Filtered Requests",
		POTENTIAL_BID_REQUESTS: "Potential Bid Requests",
		RTB_OPPORTUNITIES: "Requests Sent",
		RTB_BIDS: "Bids",
		WINS: "Wins",
		BID_RATE: "Bid Rate",
		WIN_RATE: "Win Rate",
		CLOSE_RATE: "Close Rate",
		CLOSE_CPM: "Close CPM",
		CLOSE_REVENUE: "Close Revenue",
		QUARTILE_0: "Quartile 0",
		QUARTILE_25: "Quartile 25",
		QUARTILE_50: "Quartile 50",
		QUARTILE_75: "Quartile 75",
		QUARTILE_100: "Quartile 100",
		PUBLISHER_CPM: "Publisher CPM",
		PUBLISHER_REVENUE: "Publisher Revenue",
		NET_MARGIN: "Net Margin",
		NET_REVENUE: "Net Revenue",
		CLICKS: "Clicks",
		CTR: "CTR",
		MOAT_VIEWABILITY: "Moat Viewability",
		MOAT_AUDIBILITY: "Moat Audibility",
		MOAT_V_REVENUE: "Moat vRevenue",
		MOAT_V_CPM: "Moat vCPM",
		VIEWABLE: "Viewabilty",
		DEMAND_OPPORTUNITIES: "Demand Opportunities",
		AD_ATTEMPTS: "Ad Attempts",
		BID_CPM: "Bid CPM",
		BID_REVENUE: "Bid Revenue",
		BID_ERRORS: "Bid Errors",
		TCP_ERRORS: "TCP Errors",
		TIMEOUTS: "Timeouts",
		POTENTIAL_TO_FILL: "Potential Fill Rate",
		BID_REQUEST_COST: "Bid Request Cost",
		OPPORTUNITY_COST: "Opportunity Cost",
		OPPORTUNITY_COST_PERCENT: "Opportunity Cost %",
		DEMAND_PARTNER_FEE: "Demand Partner Fees"
	},
	// Demand Clients see different headers since they are paying for impressions instead of selling them
	DEMAND_CLIENT_METRIC_LABELS: {
		GROSS_REVENUE: "Gross Spend"
	},
	RUN_REPORT_BUTTON: "Run Report",
	TAB_LABEL_NETWORK: "Network",
	TAB_LABEL_CAMPAIGN: "Campaign",
	TAB_LABEL_RTB: "RTB",
	ALL_LABEL: "All",
	DATE_RANGE: {
		CHIP_PLACEHOLDER: "Date Range",
		POPOVER_TITLE: "Date Range"
	},
	EXPANSION_ICON_LABEL: {
		EXPAND: "expand group",
		COLLAPSE: "collapse group"
	},
	NOT_AVAILABLE: "Not Available",
	NOT_PROVIDED: "Not Provided",
	BUTTON_LABEL_CLOSE_FILTER_MENU: "close",
	SCHEDULED_REPORTS_BUTTON_LINK: "Schedule",
	COLUMNS_TOOLBAR_LABEL: "Metrics",
	COLUMNS_PANEL_TEXTFIELD_PLACEHOLDER: "Find Metric",
	SELECT_COLUMNS_NAME: "select metrics",
	SHOW_ALL_BUTTON: "Show all",
	REMOVE_ALL_LABEL: "Remove All",
	CUSTOM_TAB: "Custom",
	RELATIVE_TAB: "Relative",
	TODAY: "Today",
	YESTERDAY: "Yesterday",
	PREV_7_DAYS: "Previous 7 Days",
	PREV_30_DAYS: "Previous 30 Days",
	MONTH_TO_DATE: "Month to Date",
	PREVIOUS_MONTH: "Previous Month",
	SELECT_METRICS_ARIA_LABEL: "select metrics",
	RUN_REPORT_SUCCESS_MESSAGE: (reportName, myReportLink) =>
		`Successfully saved ${reportName}. Click <a href=${myReportLink} style='color:white' target="_blank">here</a> to access the report `,
	EXPORT_BUTTON_LABEL: "Export",
	// Yup allows us to pass an interpolation argument in the string https://github.com/jquense/yup#arraymaxlimit-number--ref-message-string--function-this
	// eslint-disable-next-line no-template-curly-in-string
	MAX_FILTER_VALUES_WARNING: "Cannot be more than ${max}",
	CELL_VALUE_REPLACE: "[REPLACE]",
	API_FAILS_ERROR_MESSAGE: "Unable to retrieve data. Please try again.",
	KPI_VALUES_DAYS_DIFF: noOfdays =>
		`Data compared to previous ${noOfdays} day(s).`,
	KPI_VALUES_TOOLTIP: {
		TODAY: "Data compared to respective period Yesterday",
		MONTH_TO_DATE: "Data compared to respective period Last Month",
		PREVIOUS_MONTH: "Data compared to respective last whole Month"
	},
	METRICS_TOOLTIPS: {
		OPPORTUNITIES: "Total number of ad opportunities available",
		IMPRESSIONS: "Total number of ads served",
		FILL_RATE:
			"Total number of impressions as a percentage of overall opportunities",
		POTENTIAL_FILL_RATE:
			"The hypothetical fill rate if all available ad opportunities were utilized",
		GROSS_CPM:
			"Overall Cost Per Mille (Cost Per Thousand Impressions) as owed to the publisher",
		GROSS_REVENUE: "Total revenue due to the publisher",
		PUBLISHER_CPM:
			"The CPM owed to the publisher after revenue split has been applied",
		PUBLISHER_REVENUE:
			"The amount of revenue owed to the publisher after revenue split has been applied",
		NET_MARGIN: "Net Revenue as a percentage of Gross Revenue",
		NET_REVENUE:
			"Net earnings after accounting for publisher payouts and fees paid to demand partners.",
		CLICKS: "The number of times users clicked on the ads",
		CTR:
			"Click-Through Rate, the percentage of impressions that resulted in a click",
		QUARTILE_0:
			"The percentage of ads that were viewed for up to 25% of their duration",
		QUARTILE_25:
			"The percentage of ads that were viewed for up to 50% of their duration",
		QUARTILE_50:
			"The percentage of ads that were viewed for up to 75% of their duration",
		QUARTILE_75:
			"The percentage of ads that were viewed for up to 100% of their duration",
		QUARTILE_100: "The percentage of ads that were viewed in their entirety",
		MOAT_VIEWABILITY: "The percentage of ads that met viewability standards",
		MOAT_AUDIBILITY: "The percentage of ads that met the audibility standards",
		MOAT_vREVENUE:
			"The revenue generated from the ads that met the Moat's viewability standards",
		MOAT_vCPM:
			"The cost of impressions for the ads that met the Moat's viewability standards",
		VIEWABLE:
			"The percentage of ads that were viewable according to the industry standards",
		DEMAND_OPPORTUNITIES:
			"An event signifying a media's potential eligibility to be returned to a placement in a given ad response",
		AD_ATTEMPTS: "The number of network requests sent from a client to a media",
		FILTERED_REQUESTS:
			"The number of Potential Bid Requests that were prevented from being sent",
		POTENTIAL_BID_REQUESTS:
			"The number of Bid Requests that could have been sent",
		BID_REQUESTS: "The number of impressions that were available to be bid on",
		RTB_BIDS: "The total number of actual bids made by demand partners",
		WINS: "The number of bids that were successful in winning the ad auction",
		BID_RATE: "The number of bids over opportunities",
		WIN_RATE: "The number of wins over the number of bids",
		CLOSE_RATE: "The number of actual impressions over the number of wins",
		CLOSE_CPM: "Price at which the RTB auction closed",
		CLOSE_REVENUE: "The amount of revenue generated at the Close CPM price",
		BID_CPM: "CPM price the Buyer bid/offered to pay for the impression",
		BID_REVENUE:
			"The amount of revenue generated had bids been won at first price/full price",
		BID_ERRORS:
			"Total number of bid errors experienced that caused a bid request to fail",
		TCP_ERRORS: "Errors caused by bid request connection issues",
		TIMEOUTS: "Total number of bid requests that timed out",
		BID_REQUEST_COST: "The cost incurred based on the number of Bid Requests",
		OPPORTUNITY_COST: "The cost incurred based on the number of opportunities",
		OPPORTUNITY_COST_PERCENT:
			"Opportunity Cost as a percentage of Gross Revenue",
		DEMAND_PARTNER_FEE:
			"Dollar amount that gets taken out of Gross Revenue for Demand Partner Fees."
	},
	TIME_ZONE_TOOLTIP:
		"Reports will be pulled using the selected timezone.  All reports that contain a date more than 31 days in the past will be pulled in UTC.",
	REPORT_NAME: {
		CHAR_LIMIT_ERROR: "Must not exceed 300 characters",
		MODAL_REPORT_NAME: "Report Name",
		MODAL_TITLE_CUSTOMIZE_REPORT_NAME: "Customize Report Name",
		MODAL_BUTTON_SAVE: "Save",
		MODAL_BUTTON_CANCEL: "Cancel"
	}
};
