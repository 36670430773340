import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Grid } from "@mui/material";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";

// A wrapper for a group of inputs that adds a mui Paper background and some styling rules
function FormFieldsGroup(props) {
	const { children } = props;
	return (
		<Box component={Paper} my={2} p={2}>
			<Grid
				container
				spacing={MUI_GRID_CONTAINER_SPACING}
				justifyContent="flex-start"
				alignItems="flex-start"
			>
				{children}
			</Grid>
		</Box>
	);
}

FormFieldsGroup.propTypes = {
	children: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()])
	)
};
FormFieldsGroup.defaultProps = { children: null };

export default FormFieldsGroup;
