import React from "react";
import * as Yup from "yup";
import CrudForm from "../../components/CrudForm/CrudForm";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import { getDefaultRowConfig } from "../../components/FormFieldBidderRegionConfig/FormFieldBidderRegionConfig";
import {
	ACCESS_RESTRICTION_TYPE_ENUM,
	DEFAULT_ACCESS_RESTRICTION_TYPE_OPTIONS
} from "../../components/FormFieldAccessRestriction/FormFieldAccessRestriction";
import validators from "../../utils/validators";
import { BASE_ROUTES } from "../../config/baseRoutesConfig";
import { COMPANY_LIST_ID } from "../../config/constants";
import { CUSTOM_EXTENSIONS_TYPE } from "../../components/FormFieldBidderCustomExtensions/FormFieldBidderCustomExtensions";

const PARAMETER_OPTIONS_BID_REQUEST = [
	{ id: "sendAllimps", name: "allimps" },
	{ id: "sendAllowedBidCurrencies", name: "cur" },
	{ id: "sendAuctionType", name: "at" },
	{ id: "sendBidTimeout", name: "tmax" },
	{ id: "sendBlockedBuyerSeats", name: "bseat" },
	{ id: "sendLanguages", name: "wlang" },
	{ id: "sendSource", name: "source" }
];

const PARAMETER_OPTIONS_SOURCE = [
	{ id: "sendSourceFd", name: "fd" },
	{ id: "sendSourcePChain", name: "pchain" },
	{ id: "sendSourceSChain", name: "schain" },
	{ id: "sendSourceTid", name: "tid" }
];

const PARAMETER_OPTIONS_IMPRESSION = [
	{ id: "sendBidFloorCurrency", name: "bidfloorcur" },
	{ id: "sendBidFloor", name: "bidfloor" },
	{ id: "sendMetrics", name: "metrics" },
	{ id: "sendTagId", name: "tagid" },
	{ id: "sendInstl", name: "instl" },
	{ id: "sendExp", name: "exp" }
];

// Default values for enabled Bid Request impression parameters excluding
// parameters disabled by default such as exp
const PARAMETER_OPTIONS_IMPRESSION_DEFAULT = [
	{ id: "sendBidFloorCurrency", name: "bidfloorcur" },
	{ id: "sendBidFloor", name: "bidfloor" },
	{ id: "sendMetrics", name: "metrics" },
	{ id: "sendTagId", name: "tagid" },
	{ id: "sendInstl", name: "instl" }
];

const PARAMETER_OPTIONS_BANNER = [
	{ id: "sendBannerFormat", name: "format" },
	{ id: "sendBannerWidth", name: "w" },
	{ id: "sendBannerHeight", name: "h" },
	{ id: "sendBannerMaxWidth", name: "wmax" },
	{ id: "sendBannerMinWidth", name: "wmin" },
	{ id: "sendBannerMaxHeight", name: "hmax" },
	{ id: "sendBannerMinHeight", name: "hmin" },
	{ id: "sendBannerPosition", name: "pos" },
	{ id: "sendBlockedBannerAdTypes", name: "btype" },
	{ id: "sendBlockedCreativeAttr", name: "battr" },
	{ id: "sendBannerMimeTypes", name: "mimes" },
	{ id: "sendBannerTopFrame", name: "topframe" },
	{ id: "sendBannerExpDir", name: "expdir" },
	{ id: "sendBannerApiFrameworks", name: "api" }
];

const PARAMETER_OPTIONS_VIDEO = [
	{ id: "sendDeliveryMethod", name: "delivery" },
	{ id: "sendMaxExtendedDuration", name: "maxduration" },
	{ id: "sendMaxBitrate", name: "maxbitrate" },
	{ id: "sendMinBitrate", name: "minbitrate" },
	{ id: "sendPlacement", name: "placement" },
	{ id: "sendPlayBackend", name: "playbackend" },
	{ id: "sendVideoHeight", name: "h" },
	{ id: "sendVideoWidth", name: "w" },
	{ id: "sendVideoPosition", name: "pos" },
	{ id: "sendSkip", name: "skip" },
	{ id: "sendApiFrameworks", name: "api" },
	{ id: "sendSupportedProtocols", name: "protocols" },
	{ id: "sendVideoLinearity", name: "linearity" },
	{ id: "sendStartDelay", name: "startdelay" }
];

const CONTENT_DELIVERY_TYPE_OPTIONS = [
	{
		id: 1,
		name:
			localeContent.FIELD_CONTENT_DELIVERY_METHOD_CONFIG.OPTION_LABELS.STREAMING
	},
	{
		id: 2,
		name:
			localeContent.FIELD_CONTENT_DELIVERY_METHOD_CONFIG.OPTION_LABELS
				.PROGRESSIVE
	},
	{
		id: 3,
		name:
			localeContent.FIELD_CONTENT_DELIVERY_METHOD_CONFIG.OPTION_LABELS.DOWNLOAD
	}
];

const PARAMETER_OPTIONS_DEAL = [
	{ id: "sendDealFloorCurrency", name: "bidfloorcur" },
	{ id: "sendPrivateDealFlag", name: "at" },
	{ id: "sendDealFloor", name: "bidfloor" }
];

const PARAMETER_OPTIONS_SITE = [
	{ id: "sendSiteId", name: "id" },
	{ id: "sendSiteDomain", name: "domain" },
	{ id: "sendRef", name: "ref" },
	{ id: "sendPageUrl", name: "page" }
];

const PARAMETER_OPTIONS_PUBLISHER = [
	{ id: "sendPublisherId", name: "id" },
	{ id: "sendPublisherDomain", name: "domain" },
	{ id: "sendPublisherName", name: "name" }
];

const PARAMETER_OPTIONS_DEVICE = [
	{ id: "sendGeoObject", name: "geo" },
	{ id: "sendHeight", name: "h" },
	{ id: "sendWidth", name: "w" },
	{ id: "sendDeviceModel", name: "model" },
	{ id: "sendDeviceMake", name: "make" },
	{ id: "sendDeviceType", name: "devicetype" },
	{ id: "sendDoNotTrack", name: "dnt" },
	{ id: "sendLimitAdTracking", name: "lmt" },
	{ id: "sendGeoFetch", name: "geofetch" },
	{ id: "sendDeviceConnectionType", name: "connectiontype" },
	{ id: "sendDeviceIpv4", name: "ip" },
	{ id: "sendNativeAdvertiserId", name: "ifa" },
	{ id: "sendDeviceJsSupportFlag", name: "js" },
	{ id: "sendLanguage", name: "language" },
	{ id: "sendDeviceCarrierId", name: "carrier" },
	{ id: "sendDeviceOs", name: "os" },
	{ id: "sendDeviceOsVersion", name: "osv" },
	{ id: "sendDeviceUserAgent", name: "ua" }
];

const PARAMETER_OPTIONS_GEO = [
	{ id: "sendCity", name: "city" },
	{ id: "sendCoordinates", name: "coordinates" },
	{ id: "sendCountry", name: "country" },
	{ id: "sendIpService", name: "ipservice" },
	{ id: "sendMetro", name: "metro" },
	{ id: "sendRegion", name: "region" },
	{ id: "sendZip", name: "zip" },
	{ id: "sendUtcOffset", name: "utcoffset" }
];

const PARAMETER_OPTIONS_USER = [
	{ id: "sendBuyerUid", name: "buyeruid" },
	{ id: "sendGeoObject", name: "geo" },
	{ id: "sendEids", name: "eids" }
];

const PARAMETER_OPTIONS_USER_DEFAULT = [
	{ id: "sendBuyerUid", name: "buyeruid" },
	{ id: "sendGeoObject", name: "geo" }
];

const buildDefaultBooleanMultiselectValue = options =>
	options.reduce(
		(aggregator, option) => ({ ...aggregator, [option.id]: true }),
		{}
	);

const bidderTabsConfig = [
	{
		label: commonLocaleContent.TAB_LABEL_SETTINGS,
		key: "SETTINGS",
		fieldsConfig: [
			{
				key: "BASIC_SETTINGS",
				fields: [
					{
						name: "name",
						label: localeContent.FIELD_BIDDER_NAME.LABEL,
						gridConfig: { md: 8 },
						isRequired: true
					},
					{
						name: "status",
						label: commonLocaleContent.FIELD_LABEL_STATUS,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									value: "active",
									label: commonLocaleContent.STATUS_OPTION_LABEL_ACTIVE
								},
								{
									value: "inactive",
									label: commonLocaleContent.STATUS_OPTION_LABEL_INACTIVE
								}
							]
						},
						defaultValue: "active"
					},
					{
						name: "rtbSpecVersion",
						label: localeContent.FIELD_RTB_SPEC_VERSION.LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [
								{ value: "2.2", label: "2.2" },
								{ value: "2.3", label: "2.3" },
								{ value: "2.4", label: "2.4" },
								{ value: "2.5", label: "2.5" },
								{ value: "2.6", label: "2.6" }
							]
						},
						defaultValue: "2.5"
					},
					{
						name: "testMode",
						label: localeContent.FIELD_TEST_MODE.LABEL,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					},
					{
						name: "usingMultipleImpObjects",
						label: localeContent.FIELD_MULTIPLE_IMP_OBJECTS.LABEL,
						gridConfig: { md: 2 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false,
						tooltip: localeContent.FIELD_MULTIPLE_IMP_OBJECTS.TOOLTIP,
						permissionRequired: "VIEW_MULTIPLE_IMP_OBJECTS_FIELDS"
					},
					{
						label: localeContent.DEMAND_FEE_PERCENTAGE.LABEL,
						tooltip: localeContent.DEMAND_FEE_PERCENTAGE.TOOLTIP,
						permissionRequired: "EDIT_DEMAND_FEE_PERCENTAGE_PROGRAMMATIC_FORM",
						name: "demandFeePercentage",
						gridConfig: { md: 3 },
						fieldType: FieldTypes.PERCENT,
						fieldTypeOptions: {
							endAdornmentText: "%"
						},
						validationSchema: Yup.number()
							.min(0, commonLocaleContent.PERCENTAGE_FIELD_MUST_BE_POSITIVE)
							.max(
								100,
								commonLocaleContent.PERCENTAGE_FIELD_MUST_BE_BETWEEN_0_100
							)
					},
					{
						name: "companyAccessRestrictionConfig",
						permissionRequired: "MANAGE_RTB_BIDDER_COMPANY_ACCESS",
						fieldType: FieldTypes.ACCESS_RESTRICTION,
						gridConfig: { xs: 12 },
						optionsConfig: {
							requestEndpoint: "manage/companys/select",
							requestParams: {}
						},
						fieldTypeOptions: {
							fixedOptions: {
								blacklist: [],
								whitelist: [COMPANY_LIST_ID.column6CompanyId] // Company Id, ie: Column6, show as prefilled and disabled
							},
							accessRestrictionTypeLabel:
								localeContent.FIELD_COMPANY_ACCESS_RESTRICTION.TYPE_LABEL,
							blacklistLabel:
								localeContent.FIELD_COMPANY_ACCESS_RESTRICTION.BLACKLIST_LABEL,
							whitelistLabel:
								localeContent.FIELD_COMPANY_ACCESS_RESTRICTION.WHITELIST_LABEL,
							accessRestrictionTypeOptions: [
								{
									value: ACCESS_RESTRICTION_TYPE_ENUM.NONE,
									label:
										localeContent.FIELD_COMPANY_ACCESS_RESTRICTION
											.TYPE_NONE_LABEL
								},
								...DEFAULT_ACCESS_RESTRICTION_TYPE_OPTIONS
							],
							gridConfig: {
								accessRestrictionType: { md: 3 },
								accessRestrictionList: { xs: 12, md: 9, lg: 8 }
							}
						},
						defaultValue: {
							accessRestriction: "none",
							blacklist: [],
							whitelist: []
						}
					}
				]
			},
			{
				key: "COOKIE_SYNC_FIELDS",
				fields: [
					{
						name: "cookieSyncUrl",
						label: localeContent.FIELD_COOKIE_SYNC_URL.LABEL,
						gridConfig: { md: 8 }
					},
					{
						name: "cookieSyncPercentage",
						label: localeContent.FIELD_COOKIE_SYNC_PERCENTAGE.LABEL,
						gridConfig: { md: 4, lg: 3, offset: { md: 4 } },
						fieldType: FieldTypes.PERCENT,
						defaultValue: 100
					},
					{
						name: "matchedCookieEnforced",
						label: localeContent.FIELD_MATCHED_COOKIE_BIDDING.LABEL,
						tooltip: localeContent.FIELD_MATCHED_COOKIE_BIDDING.TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					}
				]
			},
			{
				key: "BIDDER_SETTINGS",
				fields: [
					{
						name: "rtbUrls",
						fieldType: FieldTypes.BIDDER_REGION_CONFIG,
						defaultValue: [getDefaultRowConfig()]
					}
				]
			},
			{
				key: "SEATS_SETTINGS",
				fields: [
					{
						name: "rtbBidderSeats",
						fieldType: FieldTypes.BIDDER_SEATS,
						defaultValue: []
					}
				]
			},
			{
				key: "DEFAULTS",
				fields: [
					{
						name: "supportedRtbMediaTypes",
						label: localeContent.FIELD_SUPPORTED_RTB_MEDIA_TYPES.LABEL,
						tooltip: localeContent.FIELD_SUPPORTED_RTB_MEDIA_TYPES.TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.MULTISELECT,
						optionsConfig: {
							options: [
								{
									id: "VIDEO",
									name:
										localeContent.FIELD_SUPPORTED_RTB_MEDIA_TYPES.OPTION_LABELS
											.VIDEO
								},
								{
									id: "DISPLAY",
									name:
										localeContent.FIELD_SUPPORTED_RTB_MEDIA_TYPES.OPTION_LABELS
											.DISPLAY
								},
								{
									id: "MOBILE",
									name:
										localeContent.FIELD_SUPPORTED_RTB_MEDIA_TYPES.OPTION_LABELS
											.MOBILE
								}
							]
						}
					},
					{
						name: "compressBidRequest",
						label: localeContent.FIELD_COMPRESS_BID_REQUESTS.LABEL,
						tooltip: localeContent.FIELD_COMPRESS_BID_REQUESTS.TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					},
					{
						name: "floor",
						label: localeContent.FIELD_FLOOR.LABEL,
						gridConfig: { md: 4, lg: 3, offset: { md: 4, lg: 6 } },
						fieldType: FieldTypes.CURRENCY
					},
					{
						name: "responseTimeout",
						label: localeContent.FIELD_RESPONSE_TIMEOUT.LABEL,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.DURATION
					},
					{
						name: "classOverride",
						label: localeContent.FIELD_CLASS_OVERRIDE.LABEL,
						gridConfig: { md: 4, lg: 3 }
					}
				]
			}
		]
	},
	{
		label: localeContent.TAB_LABEL_BID_PARAMETERS,
		key: "BID_PARAMETERS_TAB",
		fieldsConfig: [
			{
				key: "BID_PARAMETERS_FIELDS",
				fields: [
					{
						name: "rtbCustomExtensions",
						label: localeContent.FIELD_BID_REQUEST_EXTENSION_CONFIG.LABEL,
						gridConfig: { lg: 10 },
						fieldType: CUSTOM_EXTENSIONS_TYPE
					},
					{
						name: "bidRequestConfig",
						label: localeContent.FIELD_BID_REQUEST_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_REQUEST_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						optionsConfig: {
							options: PARAMETER_OPTIONS_BID_REQUEST
						},
						fieldTypeOptions: {
							isBlacklist: true
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_BID_REQUEST
						)
					},
					{
						name: "bidSourceConfig",
						label: localeContent.FIELD_BID_SOURCE_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_SOURCE_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						optionsConfig: {
							options: PARAMETER_OPTIONS_SOURCE
						},
						fieldTypeOptions: {
							isBlacklist: true
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_SOURCE
						)
					},
					{
						name: "bidImpressionConfig",
						label: localeContent.FIELD_BID_IMPRESSION_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_IMPRESSION_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						optionsConfig: {
							options: PARAMETER_OPTIONS_IMPRESSION
						},
						fieldTypeOptions: {
							isBlacklist: true
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_IMPRESSION_DEFAULT
						)
					},
					{
						name: "bidBannerConfig",
						label: localeContent.FIELD_BID_BANNER_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_BANNER_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						optionsConfig: {
							options: PARAMETER_OPTIONS_BANNER
						},
						fieldTypeOptions: {
							isBlacklist: true
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_BANNER
						)
					},
					{
						name: "bidVideoConfig",
						label: localeContent.FIELD_BID_VIDEO_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_VIDEO_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						optionsConfig: {
							options: PARAMETER_OPTIONS_VIDEO
						},
						fieldTypeOptions: {
							isBlacklist: true
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_VIDEO
						)
					},
					{
						name: "contentDeliveryMethodConfig",
						label: localeContent.FIELD_CONTENT_DELIVERY_METHOD_CONFIG.LABEL,
						tooltip: localeContent.FIELD_CONTENT_DELIVERY_METHOD_CONFIG.TOOLTIP,
						gridConfig: { md: 5 },
						fieldType: FieldTypes.MULTISELECT,
						optionsConfig: {
							options: CONTENT_DELIVERY_TYPE_OPTIONS
						},
						defaultValue: CONTENT_DELIVERY_TYPE_OPTIONS.map(
							option => option.id
						),
						renderIf: formValues => formValues.bidVideoConfig.sendDeliveryMethod
					},
					{
						name: "linearity",
						label: localeContent.FIELD_LINEARITY_CONFIG.LABEL,
						tooltip: localeContent.FIELD_LINEARITY_CONFIG.TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									value: "LINEAR",
									label:
										localeContent.FIELD_LINEARITY_CONFIG.OPTION_LABELS.LINEAR
								},
								{
									value: "NON_LINEAR",
									label:
										localeContent.FIELD_LINEARITY_CONFIG.OPTION_LABELS
											.NON_LINEAR
								}
							]
						},
						defaultValue: "LINEAR",
						renderIf: formValues => formValues.bidVideoConfig.sendVideoLinearity
					},
					{
						name: "bidDealConfig",
						label: localeContent.FIELD_BID_DEAL_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_DEAL_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						fieldTypeOptions: {
							isBlacklist: true
						},
						optionsConfig: {
							options: PARAMETER_OPTIONS_DEAL
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_DEAL
						)
					},
					{
						name: "bidSiteConfig",
						label: localeContent.FIELD_BID_SITE_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_SITE_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						fieldTypeOptions: {
							isBlacklist: true
						},
						optionsConfig: {
							options: PARAMETER_OPTIONS_SITE
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_SITE
						)
					},
					{
						name: "bidPublisherConfig",
						label: localeContent.FIELD_BID_PUBLISHER_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_PUBLISHER_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						fieldTypeOptions: {
							isBlacklist: true
						},
						optionsConfig: {
							options: PARAMETER_OPTIONS_PUBLISHER
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_PUBLISHER
						)
					},
					{
						name: "bidDeviceConfig",
						label: localeContent.FIELD_BID_DEVICE_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_DEVICE_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						fieldTypeOptions: {
							isBlacklist: true
						},
						optionsConfig: {
							options: PARAMETER_OPTIONS_DEVICE
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_DEVICE
						)
					},
					{
						name: "bidGeoConfig",
						label: localeContent.FIELD_BID_GEO_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_GEO_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						fieldTypeOptions: {
							isBlacklist: true
						},
						optionsConfig: {
							options: PARAMETER_OPTIONS_GEO
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_GEO
						)
					},
					{
						name: "bidUserConfig",
						label: localeContent.FIELD_BID_USER_CONFIG.LABEL,
						tooltip: localeContent.FIELD_BID_USER_CONFIG.TOOLTIP,
						gridConfig: { lg: 10 },
						fieldType: FieldTypes.BOOLEAN_MULTISELECT,
						fieldTypeOptions: {
							isBlacklist: true
						},
						optionsConfig: {
							options: PARAMETER_OPTIONS_USER
						},
						defaultValue: buildDefaultBooleanMultiselectValue(
							PARAMETER_OPTIONS_USER_DEFAULT
						)
					}
				]
			}
		]
	},
	{
		label: commonLocaleContent.TAB_LABEL_QUALITY,
		key: "QUALITY",
		fieldsConfig: [
			{
				key: "LIST_TARGETING_SETTINGS",
				fields: [
					{
						name: "blacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: localeContent.TARGETING_TYPE_DOMAIN_LABEL,
							validatorFunction: validators.validateDomain
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "advertiserDomainBlacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel:
								localeContent.TARGETING_TYPE_ADVERTISER_DOMAIN_LABEL,
							validatorFunction: validators.validateDomain
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					}
				]
			}
		]
	}
];

const viewReportConfig = {
	permissionsRequired: ["VIEW_REPORT_BUTTON", "VIEW_RTB_REPORT"],
	reportType: "rtb",
	filterKeyValuesGetter: context => {
		return {
			buyers: context.initData.guid
		};
	}
};

function BidderResourceScreen() {
	return (
		<CrudForm
			resourceEndpoint="manage/rtb-bidders"
			resourceRoute={BASE_ROUTES.bidders}
			resourceString={commonLocaleContent.BIDDER}
			tabsConfig={bidderTabsConfig}
			prepopulateCreate
			viewReportConfig={viewReportConfig}
		/>
	);
}

export default BidderResourceScreen;
