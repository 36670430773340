import React from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { MUI_PADDING_NORMAL } from "../../config/constants";

function ReactTableSortableHeaderCell(props) {
	const { column, sxProps, renderProps } = props;
	return (
		<TableCell
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...column.getHeaderProps(column.getSortByToggleProps())}
			sx={{ bgcolor: "primary.800", color: "common.white", ...sxProps }}
			data-testid={column.id}
			scope="col"
			sortDirection={column.isSorted && (column.isSortedDesc ? "desc" : "asc")}
			padding={column.tableCellPadding || MUI_PADDING_NORMAL}
			align={column.align}
		>
			{column.canSort ? (
				<TableSortLabel
					active={column.isSorted}
					direction={column.isSortedDesc ? "desc" : "asc"}
				>
					{column.render("Header", renderProps)}
					{column.isSorted && (
						<span className="sr-only-text">
							{column.isSortedDesc ? "sorted descending" : "sorted ascending"}
						</span>
					)}
				</TableSortLabel>
			) : (
				column.render("Header", renderProps)
			)}
		</TableCell>
	);
}

ReactTableSortableHeaderCell.propTypes = {
	column: PropTypes.shape({
		id: PropTypes.string.isRequired,
		align: PropTypes.string,
		canSort: PropTypes.bool,
		isSorted: PropTypes.bool,
		isSortedDesc: PropTypes.bool,
		tableCellPadding: PropTypes.string,
		render: PropTypes.func.isRequired,
		getHeaderProps: PropTypes.func.isRequired,
		getSortByToggleProps: PropTypes.func.isRequired
	}).isRequired,
	sxProps: PropTypes.shape(),
	renderProps: PropTypes.shape()
};

ReactTableSortableHeaderCell.defaultProps = {
	sxProps: {},
	renderProps: {}
};

export default ReactTableSortableHeaderCell;
