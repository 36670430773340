import React from "react";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import FormTextField from "../FormTextField/FormTextField";

/**
 * A wrapper around the base FormTextField that:
 *   - adds number attributes to the input
 *   - adds a "%" input adornment
 * @param {Object} props defined in propTypes
 */
function FormPercentField(props) {
	const { name, id, label, required } = props;
	return (
		<FormTextField
			label={label}
			id={id}
			name={name}
			type="number"
			required={required}
			inputProps={{
				min: 0,
				max: 100
			}}
			// This is a material-ui prop so we have no control over this
			// eslint-disable-next-line react/jsx-no-duplicate-props
			InputProps={{
				endAdornment: <InputAdornment position="end">%</InputAdornment>
			}}
		/>
	);
}

FormPercentField.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool
};

FormPercentField.defaultProps = {
	required: false
};

export default FormPercentField;
