import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import _unionBy from "lodash/unionBy";
import useResource, { Operations } from "../../hooks/useResource";
import transformOptions from "../../utils/transformOptions";
import Multiselect from "./Multiselect";
import commonLocaleContent from "../../screens/commonLocaleContent";
import getDropdownOptionsStatus from "../../utils/inputUtils";

export const defaultFilterSelectedOptions = fieldValue => option =>
	Array.isArray(fieldValue) ? fieldValue.indexOf(option.id) !== -1 : null;

const defaultSetValueFunction = newValue => {
	// Map Autocomplete value (Array[Object]) to Formik value (Array[String])
	return newValue.map(selectedOption => selectedOption.id);
};

function FormFieldMultiselect(props) {
	const {
		id,
		label,
		name,
		required,
		disabled,
		optionsConfig,
		fieldTypeOptions,
		tooltip
	} = props;
	const [field, meta, helpers] = useField(name);

	const {
		requestEndpoint,
		requestParams,
		showOptionsRequestError
	} = optionsConfig;

	const {
		fixedOptions,
		setValueOverride,
		filterSelectedOptionsOverride,
		formStateSetter
	} = fieldTypeOptions;

	const setValueFunction = setValueOverride || defaultSetValueFunction;
	const filterSelectedOptions =
		filterSelectedOptionsOverride || defaultFilterSelectedOptions;

	const [optionsData, requestError, isLoadingOptions] = useResource(
		requestEndpoint,
		Operations.LIST,
		{
			requestParams
		}
	);
	const fieldOptions = transformOptions(optionsConfig, optionsData);

	let showError = Boolean(meta.touched && meta.error);
	let helperText = showError ? meta.error : "";
	if (showOptionsRequestError || (!isLoadingOptions && requestError)) {
		showError = true;
		helperText = commonLocaleContent.UNABLE_TO_RETRIEVE_OPTIONS_WARNING;
	}

	const handleChange = React.useCallback(
		(event, newValue) => {
			const value = setValueFunction(newValue, fieldOptions);
			if (formStateSetter) {
				formStateSetter(value);
			} else {
				helpers.setValue(value);
			}
		},
		[helpers, setValueFunction, fieldOptions, formStateSetter]
	);

	const selectedOptions = field.value
		? fieldOptions.filter(filterSelectedOptions(field.value))
		: [];

	// Formik value => Autocomplete value
	const loadingStatus = getDropdownOptionsStatus(
		optionsConfig?.fetchingStatus,
		fieldOptions.length
	);

	const fixedOptionItems = useMemo(() => {
		return fixedOptions
			? fieldOptions.filter(filterSelectedOptions(fixedOptions.whitelist))
			: [];
	}, [fixedOptions, fieldOptions, filterSelectedOptions]);

	const [updateStatus, setUpdateStatus] = useState(false);
	useEffect(() => {
		if (
			!updateStatus &&
			fieldOptions.length &&
			field.value &&
			fixedOptionItems.length
		) {
			const items = _unionBy(
				fieldOptions.filter(filterSelectedOptions(field.value)),
				fixedOptionItems,
				"id"
			);
			const value = setValueFunction(items, fieldOptions);
			helpers.setValue(value);
			setUpdateStatus(true);
		}
	}, [
		field.value,
		fieldOptions,
		filterSelectedOptions,
		helpers,
		setValueFunction,
		updateStatus,
		fixedOptionItems
	]);

	return (
		<Multiselect
			id={id}
			label={label}
			name={name}
			options={fieldOptions}
			selectedOptions={selectedOptions}
			onChange={handleChange}
			onBlur={field.onBlur}
			required={required}
			disabled={disabled}
			showError={showError}
			helperText={loadingStatus.helperText || helperText}
			noOptionsText={loadingStatus.noOptionsText}
			tooltip={tooltip}
			preSelected={fixedOptionItems}
		/>
	);
}

FormFieldMultiselect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	optionsConfig: PropTypes.shape(),
	fieldTypeOptions: PropTypes.shape(),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
};

FormFieldMultiselect.defaultProps = {
	required: false,
	disabled: false,
	optionsConfig: {},
	fieldTypeOptions: {},
	tooltip: null
};

export default FormFieldMultiselect;
