import React from "react";
import PropTypes from "prop-types";
import { Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useField } from "formik";
import FormFieldsGroup from "../../components/FormFieldsGroup/FormFieldsGroup";
import FormFieldMacroText from "../../components/FormFieldMacroText/FormFieldMacroText";
import FormTextField from "../../components/FormTextField/FormTextField";
import FormFieldSelect from "../../components/FormFieldSelect/FormFieldSelect";
import localeContent from "./localeContent";
import FormFieldComboBox from "../../components/FormFieldComboBox/FormFieldComboBox";

export const DEMAND_SOURCE_TYPE_OPTIONS = [
	{ value: "PLACEMENT", label: "Placement" },
	{ value: "THIRD_PARTY", label: "Third Party" }
];

const getUnAvailableIds = (index, demandSources) =>
	demandSources.reduce((agg, demandSource, rowIndex) => {
		if (
			demandSource.type === "PLACEMENT" &&
			demandSource.placementId &&
			rowIndex !== index
		) {
			agg.push(demandSource.placementId);
		}
		return agg;
	}, []);

function DemandSourceRow(props) {
	const {
		demandSource,
		index,
		deleteEvent,
		renderDeleteButton,
		placementOptions,
		name: fieldName
	} = props;
	const { type } = demandSource;

	const [field] = useField(fieldName);

	const availableOptions = (placementOptions || []).filter(
		placement => !getUnAvailableIds(index, field.value).includes(placement.id)
	);

	// We need account for MACRO button height + padding when is a THIRD_PARTY and 36.5
	// is a magic number that we computed when developing the component
	const macroSpacerStyle =
		type === "THIRD_PARTY" ? { mt: "36.5px" } : undefined;

	return (
		<FormFieldsGroup>
			<Grid item xs={12} sm={3} sx={macroSpacerStyle}>
				<FormFieldSelect
					id={`${fieldName}-${index}-type`}
					name={`${fieldName}[${index}].type`}
					label={localeContent.DEMAND_SOURCE_TYPE}
					optionsConfig={{ options: DEMAND_SOURCE_TYPE_OPTIONS }}
					disabled={false}
				/>
			</Grid>

			<Grid item xs={12} sm={6}>
				{type === "PLACEMENT" && (
					<FormFieldComboBox
						id={`${fieldName}-${index}-placementId`}
						name={`${fieldName}[${index}].placementId`}
						label={localeContent.DEMAND_SOURCE_LABEL}
						disabled={false}
						readOnly={false}
						required
						optionsConfig={{ options: availableOptions }}
					/>
				)}
				{type === "THIRD_PARTY" && (
					<FormFieldMacroText
						id={`${fieldName}-${index}-tag`}
						name={`${fieldName}[${index}].tag`}
						label={localeContent.DEMAND_SOURCE_LABEL}
						required
					/>
				)}
			</Grid>
			<Grid item xs={12} sm={2} sx={macroSpacerStyle}>
				<FormTextField
					id={`${fieldName}-${index}-allocation`}
					name={`${fieldName}[${index}].allocation`}
					label={localeContent.ALLOCATION_LABEL}
					required
					type="number"
					inputProps={{
						min: 0,
						max: 100
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={1} sx={macroSpacerStyle}>
				{renderDeleteButton && (
					<IconButton
						variant="contained"
						aria-label={localeContent.DEMAND_SOURCE_DELETE_BUTTON_LABEL}
						onClick={() => {
							deleteEvent(index);
						}}
					>
						<DeleteIcon />
					</IconButton>
				)}
			</Grid>
		</FormFieldsGroup>
	);
}

DemandSourceRow.propTypes = {
	index: PropTypes.number.isRequired,
	demandSource: PropTypes.shape().isRequired,
	deleteEvent: PropTypes.func.isRequired,
	renderDeleteButton: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	placementOptions: PropTypes.arrayOf(PropTypes.shape)
};

DemandSourceRow.defaultProps = {
	placementOptions: []
};

export default DemandSourceRow;
