import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tooltip from "../Tooltip/Tooltip";
import GridOffset from "../GridOffset/GridOffset";

/**
 * Renders a passed field component
 *   - within a grid
 *   - with an optional tooltip
 * @param {Object} props defined in propTypes
 */
function FormField(props) {
	const { gridConfig, tooltip, overrideTooltip, children } = props;
	// We wrap the field component in a Grid, unless the component has a custom configuration and needs to control its own grid system
	const gridWrappedFieldComponent = gridConfig.custom ? (
		children
	) : (
		<Grid
			item
			xs={gridConfig.xs || 12}
			sm={gridConfig.sm}
			md={gridConfig.md}
			lg={gridConfig.lg}
			xl={gridConfig.xl}
		>
			{children}
		</Grid>
	);
	return (
		<>
			{gridConfig.offset && <GridOffset offsetConfig={gridConfig.offset} />}
			{tooltip && !overrideTooltip ? (
				<Tooltip title={tooltip}>{gridWrappedFieldComponent}</Tooltip>
			) : (
				gridWrappedFieldComponent
			)}
		</>
	);
}

FormField.propTypes = {
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
	overrideTooltip: PropTypes.bool,
	gridConfig: PropTypes.shape(),
	children: PropTypes.node.isRequired
};
FormField.defaultProps = {
	gridConfig: {},
	tooltip: null,
	overrideTooltip: false
};

export default FormField;
