import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
	Grid,
	Popper,
	TextField,
	InputAdornment,
	IconButton
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FileCopy from "@mui/icons-material/FileCopy";
import useResource, {
	useResourceClient,
	Operations
} from "../../hooks/useResource";
import transformOptions from "../../utils/transformOptions";
import { SnackbarContext } from "../../context/SnackbarContext";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import commonLocaleContent from "../../screens/commonLocaleContent";
import lc from "./localeContent";

function FormFieldCloneRole(props) {
	const { name, crudFormResourceId } = props;
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const [roleId, setRoleId] = useState(null);
	const [roleIdError, setRoleIdError] = useState("");
	const [newRoleName, setNewRoleName] = useState("");
	const [newRoleNameError, setNewRoleNameError] = useState("");

	const handleChangeRole = (event, newValue) => {
		setRoleIdError("");
		setRoleId(newValue ? newValue.id : null);
	};

	const handleChangeRoleName = event => {
		setNewRoleNameError("");
		setNewRoleName(event.target.value);
	};

	const [rolesData] = useResource("manage/user-roles", Operations.LIST);
	const roleOptions = transformOptions({ sortOptionsByName: true }, rolesData);
	const selectedRole = roleOptions.find(option => option.id === roleId) || null;

	const [cloneRoleData, cloneRoleError, , submitCloneRole] = useResourceClient(
		"manage/companies/copyRole",
		Operations.CREATE
	);

	useEffect(() => {
		if (cloneRoleData) {
			const message = lc.ROLE_CLONED_SUCCESS_MESSAGE;
			triggerNewSnackbarMessage({ message, severity: "success" });
		}
	}, [cloneRoleData, triggerNewSnackbarMessage]);

	useEffect(() => {
		if (cloneRoleError) {
			const { message } = cloneRoleError;
			triggerNewSnackbarMessage({ message, severity: "error" });
		}
	}, [cloneRoleError, triggerNewSnackbarMessage]);

	const handleClone = () => {
		if (roleId && newRoleName.trim()) {
			submitCloneRole({
				data: {
					companyId: Number(crudFormResourceId),
					altitudeRoleId: roleId,
					name: newRoleName
				}
			});
		}
		setRoleIdError(roleId ? "" : commonLocaleContent.REQUIRED_FIELD_WARNING);
		setNewRoleNameError(
			newRoleName.trim() ? "" : commonLocaleContent.REQUIRED_FIELD_WARNING
		);
	};

	return (
		<Grid container spacing={MUI_GRID_CONTAINER_SPACING}>
			<Grid item xs={6}>
				<Autocomplete
					id={`${name}-autocomplete`}
					value={selectedRole}
					onChange={handleChangeRole}
					options={roleOptions}
					getOptionLabel={option => option.name || ""}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					PopperComponent={muiProps => (
						<Popper
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...muiProps}
							modifiers={[{ name: "flip", enabled: false }]}
						/>
					)}
					renderInput={params => (
						<TextField
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...params}
							label={lc.ROLE_LABEL}
							placeholder={lc.ROLE_PLACEHOLDER}
							error={Boolean(roleIdError)}
							helperText={roleIdError}
							required={Boolean(roleIdError)}
							variant="filled"
						/>
					)}
					selectOnFocus
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					id={`${name}-textfield`}
					name={name}
					label={lc.ROLE_NAME_LABEL}
					type="text"
					value={newRoleName}
					onChange={handleChangeRoleName}
					error={Boolean(newRoleNameError)}
					helperText={newRoleNameError}
					required={Boolean(newRoleNameError)}
					fullWidth
					variant="filled"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label={lc.CLONE_ROLE_BUTTON_LABEL}
									onClick={handleClone}
								>
									<FileCopy />
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</Grid>
		</Grid>
	);
}

FormFieldCloneRole.propTypes = {
	name: PropTypes.string.isRequired,
	crudFormResourceId: PropTypes.string
};

FormFieldCloneRole.defaultProps = {
	crudFormResourceId: null
};

export default FormFieldCloneRole;
