import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { FieldArray, useField } from "formik";
import { Button, Grid, IconButton, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import FormField from "../CrudForm/FormField";
import FormFieldSwitch from "../FormFieldSwitch/FormFieldSwitch";
import FormTextField from "../FormTextField/FormTextField";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import localeContent from "./localeContent";
import crudFormLocaleContent from "../CrudForm/localeContent";
import Yup from "../../config/yupConfig";

export const TEST_ID_ROW = "custom-key-row";
export const KEY_PROP = "key";

const getDefaultRowConfig = () => ({
	clientId: uuidv4(),
	[KEY_PROP]: ""
});

export const transformInitData = (data, fieldName) => {
	const fieldValue = data[fieldName] || [];
	const enabled = Boolean(fieldValue.length);

	return {
		enabled,
		keys: fieldValue.map(key => {
			return { key };
		})
	};
};

export const transformSubmitData = (data, fieldName) => {
	const { enabled, ...submissionData } = data;
	return {
		[fieldName]: enabled ? submissionData.keys.map(({ key }) => key) : []
	};
};

function CustomKeyRow(props) {
	const { index, name, deleteRow, tooltip } = props;
	const fieldNameKey = `${name}[${index}].${KEY_PROP}`;
	const onDeleteRow = useCallback(() => {
		if (deleteRow) deleteRow(index);
	}, [deleteRow, index]);

	return (
		<Grid
			container
			item
			spacing={MUI_GRID_CONTAINER_SPACING}
			alignItems="flex-start"
			data-testid={TEST_ID_ROW}
		>
			<FormField gridConfig={{ md: 3, lg: 4 }}>
				<FormTextField
					id={fieldNameKey}
					label={localeContent.FIELD_LABEL_KEY}
					name={fieldNameKey}
					required
					tooltip={tooltip}
				/>
			</FormField>
			{deleteRow && (
				<Grid item xs={2} sm={1}>
					<IconButton
						onClick={onDeleteRow}
						aria-label={localeContent.ARIA_LABEL_DELETE_ROW}
					>
						<DeleteIcon />
					</IconButton>
				</Grid>
			)}
		</Grid>
	);
}

CustomKeyRow.propTypes = {
	index: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	tooltip: PropTypes.string,
	deleteRow: PropTypes.func
};

CustomKeyRow.defaultProps = {
	deleteRow: null,
	tooltip: ""
};

function FormFieldCustomKey(props) {
	const { name, label, tooltip } = props;
	const [field, , helpers] = useField({ name });

	useEffect(() => {
		if (field.value.enabled && !field.value.keys.length) {
			const fieldValueObj = {
				enabled: field.value.enabled,
				keys: [{ [KEY_PROP]: "" }]
			};
			helpers.setValue(fieldValueObj);
		}
	}, [field.value, helpers]);

	return (
		<>
			<Box sx={{ mb: 1 }}>
				<FormFieldSwitch
					id={`${name}Enabled`}
					name={`${name}.enabled`}
					label={label}
				/>
			</Box>
			{field.value.enabled && (
				<FieldArray name={`${name}.keys`}>
					{fieldArrayHelpers => {
						const addRow = () => {
							fieldArrayHelpers.push(getDefaultRowConfig());
						};
						return (
							<Grid
								container
								direction="column"
								spacing={MUI_GRID_CONTAINER_SPACING}
							>
								{field.value.keys &&
									field.value.keys.map(({ clientId }, index) => {
										return (
											<CustomKeyRow
												key={index ?? clientId}
												index={index}
												name={`${name}.keys`}
												deleteRow={
													field.value.keys.length > 1
														? fieldArrayHelpers.remove
														: undefined
												}
												value={field.value.keys[index]}
												tooltip={tooltip}
											/>
										);
									})}
								<Grid item xs={12}>
									<Button variant="contained" onClick={addRow}>
										{localeContent.BUTTON_TEXT_ADD_ROW}
									</Button>
								</Grid>
							</Grid>
						);
					}}
				</FieldArray>
			)}
		</>
	);
}

FormFieldCustomKey.propTypes = {
	name: PropTypes.string.isRequired,
	tooltip: PropTypes.string,
	label: PropTypes.string.isRequired
};

FormFieldCustomKey.defaultProps = {
	tooltip: ""
};

export const ValidationSchema = Yup.object({
	keys: Yup.array().when("enabled", {
		is: true,
		then: Yup.array().of(
			Yup.object({
				[KEY_PROP]: Yup.string().required(
					crudFormLocaleContent.REQUIRED_FIELD_WARNING
				)
			}).uniqueProperty(KEY_PROP, localeContent.UNIQUE_CUSTOM_QUERY_KEY_WARNING)
		)
	})
});

export default FormFieldCustomKey;
