import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import Radio from "./Radio";
import transformOptions from "../../utils/transformOptions";

/*
		Boolean/number conversion approach taken from https://github.com/formium/formik/issues/2411
		Should use https://github.com/formium/formik/pull/2255 solution when ready
*/
const VALUE_PARSERS = {
	STRING: value => value,
	BOOLEAN: value => value === "true",
	NUMBER: value => parseInt(value, 10)
};

/**
 * A wrapper around mui's suggested radio input group implementation (https://material-ui.com/components/radio-buttons/#radiogroup) that:
 *   - adds Formik functionality by passing the radios value, onChange, and onBlur
 *   - adds error handling functionality
 * Because our style rules don't allow for prop spreading, we need to be explicit about what we're passing to the mui components
 * @param {Object} props defined in propTypes
 */
function FormRadioGroup(props) {
	const { id, label, name, optionsConfig, disabled } = props;
	const { useBooleanValues, useNumberValues } = optionsConfig;
	const [field, meta, helpers] = useField(name);
	const showError = Boolean(meta.touched && meta.error);
	const helperText = showError ? meta.error : "";

	// TODO: CP-1094 Refactor useBooleanValues and useNumberValues to inject parsing function in field config (we will need to export them from this file)
	let parseValue = VALUE_PARSERS.STRING;
	if (useBooleanValues) parseValue = VALUE_PARSERS.BOOLEAN;
	if (useNumberValues) parseValue = VALUE_PARSERS.NUMBER;

	// If we ever need to get options from an endpoint, we should pass them here
	const fieldOptions = transformOptions(optionsConfig, []);

	const handleChange = React.useCallback(
		(event, newValue) => {
			helpers.setValue(parseValue(newValue));
		},
		[helpers, parseValue]
	);

	return (
		<Radio
			id={id}
			label={label}
			name={name}
			value={field.value}
			onChange={handleChange}
			onBlur={field.onBlur}
			options={fieldOptions}
			showError={showError}
			helperText={helperText}
			disabled={disabled}
		/>
	);
}

FormRadioGroup.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	optionsConfig: PropTypes.shape(),
	disabled: PropTypes.bool
};

FormRadioGroup.defaultProps = {
	disabled: false,
	optionsConfig: {}
};

export default FormRadioGroup;
