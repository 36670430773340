export default {
	COLUMN_HEADER_NAME: "Name",
	COLUMN_HEADER_TYPE: "Type",
	COLUMN_HEADER_REQUESTS: "Requests",

	ROUTER: "Router",

	TAB_LABEL_SETTINGS: "Settings",
	ROUTERS_NAME_LABEL: "Router Name",
	STATUS_LABEL: "Status",
	STATUS_OPTION_LABEL_ACTIVE: "Active",
	STATUS_OPTION_LABEL_INACTIVE: "Inactive",

	ROUTER_TYPE_LABEL: "Router Type",
	ROUTER_TYPE_TOOLTIP:
		"Specify if the router tag will be used for app or web-based inventory.",
	DEMAND_TYPE_LABEL: "Demand Type",
	DEMAND_SOURCE_LABEL: "Demand Source",
	DEMAND_SOURCE_ADD_BUTTON_LABEL: "Add Demand Source",
	DEMAND_SOURCE_DELETE_BUTTON_LABEL: "Delete",
	ALLOCATION_LABEL: "Allocation",
	DEMAND_SOURCE_TYPE: "Demand Type",
	VALIDATION_ALLOCATION_WARNING: "Total allocation must be 100%",
	PLACEMENT_INACTIVE_WARNING:
		"The placements are state inactive with the names "
};
