import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import {
	FormControl,
	InputLabel,
	FilledInput,
	InputAdornment,
	IconButton,
	FormHelperText
} from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import lc from "./localeContent";

export const FILE_UPLOAD_INPUT_TEST_ID = "file-upload-input";

function FormFieldFileUpload(props) {
	const {
		id,
		name,
		label,
		fieldTypeOptions: { acceptedFileTypes }
	} = props;
	const [field, meta, helpers] = useField(name);
	const showError = meta.error && meta.touched;

	const onFileUploaded = useCallback(
		event => {
			const uploadedFile = event.currentTarget.files[0];
			helpers.setValue(uploadedFile ?? null);
		},
		[helpers]
	);
	const fileUploadInputRef = useRef(null);
	const triggerUpload = useCallback(() => {
		if (fileUploadInputRef.current) {
			fileUploadInputRef.current.click();
		}
	}, [fileUploadInputRef]);

	const fileUploadNameLabelId = `file-upload-name-${name}`;
	const uploadedFilename = field.value?.name;
	return (
		<FormControl variant="filled" fullWidth error={showError}>
			<InputLabel
				htmlFor={fileUploadNameLabelId}
				shrink={Boolean(uploadedFilename)}
			>
				{label}
			</InputLabel>
			<FilledInput
				id={fileUploadNameLabelId}
				value={uploadedFilename || ""}
				onClick={triggerUpload}
				readOnly
				endAdornment={
					<InputAdornment position="end">
						<IconButton aria-label={lc.UPLOAD_FILE_BUTTON_LABEL}>
							<PublishIcon />
						</IconButton>
					</InputAdornment>
				}
			/>
			<input
				id={id}
				type="file"
				name={name}
				onChange={onFileUploaded}
				ref={fileUploadInputRef}
				data-testid={FILE_UPLOAD_INPUT_TEST_ID}
				aria-label="file upload"
				accept={acceptedFileTypes.join(",")}
				style={{ opacity: 0, width: 0, height: 0, margin: 0, padding: 0 }}
			/>
			{showError && <FormHelperText>{meta.error}</FormHelperText>}
		</FormControl>
	);
}

FormFieldFileUpload.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	fieldTypeOptions: PropTypes.shape({
		acceptedFileTypes: PropTypes.arrayOf(PropTypes.string)
	})
};

FormFieldFileUpload.defaultProps = {
	fieldTypeOptions: { acceptedFileTypes: ["*"] }
};

export default FormFieldFileUpload;
