import React from "react";
import PropTypes from "prop-types";
import LinkToNewTab from "../Routing/LinkToNewTab";

function AlignmentColumnEditLink({ row, column }) {
	const { getEditURL } = column.ext;
	const url = getEditURL(row.original);
	return <LinkToNewTab to={url} text={row.values[column.id]} />;
}

AlignmentColumnEditLink.propTypes = {
	row: PropTypes.shape().isRequired,
	column: PropTypes.shape().isRequired
};
export default AlignmentColumnEditLink;
