import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

function SecondaryAction(props) {
	const { action, icon: Icon, label } = props;
	return (
		<ListItemSecondaryAction onClick={() => action()}>
			<IconButton edge="end" color="inherit" aria-label={label}>
				<Icon />
			</IconButton>
		</ListItemSecondaryAction>
	);
}
SecondaryAction.propTypes = {
	icon: PropTypes.shape().isRequired,
	label: PropTypes.string.isRequired,
	action: PropTypes.func.isRequired
};

const DndListItem = forwardRef((props, ref) => {
	const {
		label,
		style,
		attributes,
		listeners,
		isDragging,
		secondaryAction,
		disableDrag
	} = props;

	return (
		<div ref={ref} style={style}>
			{/* TODO: Item seems to shift down when clicking the drag handle so sometimes pointer doesn't change without this class on the entire list component */}
			<ListItem
				sx={isDragging ? { cursor: "grab" } : undefined}
				disabled={disableDrag}
			>
				<ListItemIcon
					sx={{ cursor: isDragging ? "grabbing" : "grab" }}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...attributes}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...listeners}
				>
					{!disableDrag && <DragIndicatorIcon />}
				</ListItemIcon>
				<ListItemText primary={label} />
				{secondaryAction && (
					<SecondaryAction
						action={secondaryAction.action}
						icon={secondaryAction.icon}
						label={secondaryAction.label}
					/>
				)}
			</ListItem>
		</div>
	);
});

DndListItem.propTypes = {
	style: PropTypes.shape(),
	attributes: PropTypes.shape(),
	listeners: PropTypes.shape(),
	label: PropTypes.string.isRequired,
	onDelete: PropTypes.func,
	isDragging: PropTypes.bool,
	secondaryAction: PropTypes.shape({
		icon: PropTypes.shape(),
		label: PropTypes.string,
		action: PropTypes.func
	}),
	disableDrag: PropTypes.bool
};

DndListItem.defaultProps = {
	style: {},
	attributes: {},
	listeners: {},
	onDelete: undefined,
	isDragging: false,
	secondaryAction: undefined,
	disableDrag: false
};

export default DndListItem;
