export default {
	SWITCH_LABEL: ({ targetingTypeLabel }) => `${targetingTypeLabel} Targeting`,
	ALLOW_LABEL: "Allow",
	BLOCK_LABEL: "Block",
	FILE_UPLOAD_BUTTON_LABEL: "Upload",
	REMOVE_ALL_LABEL: "REMOVE ALL",
	DOMAIN_ERROR_MESSAGE: "Must be a valid domain",
	FILE_EXTENSION_ERROR_MESSAGE: "File must contain a .csv extension",
	INVALID_ERROR_MESSAGE: ({ targetingTypeLabel }) =>
		`Must be a valid ${targetingTypeLabel}`,
	DUPPLICATED_ERROR_MESSAGE: ({ targetingTypeLabel }) =>
		`${targetingTypeLabel} already in list`,
	RESTRICTION_INPUT_LABEL: ({ targetingTypeLabel }) =>
		`Enter ${targetingTypeLabel}`,
	RADIO_BUTTON_ARIA_LABEL: "allow/block selection",
	ADD_RESTRICTION_BUTTON_LABEL: "add restriction",
	REMOVE_RESTRICTION_BUTTON_LABEL: ({ restriction }) => `rm ${restriction}`
};
