import React, { useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import IconClose from "@mui/icons-material/Close";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { useAsyncDebounce } from "react-table";

import lc from "./localeContent";
import { EVENT_ENTER_KEY } from "../../config/constants";

function SearchTextField(props) {
	const {
		searchQueryValue,
		onSearchQueryValueChange,
		id,
		searchFieldSxExtension,
		debounceTime,
		variant,
		size,
		fullWidth
	} = props;

	const [value, setValue] = useState(searchQueryValue);

	const onChange = useAsyncDebounce(val => {
		onSearchQueryValueChange(val);
	}, debounceTime);

	return (
		<TextField
			autoComplete="off"
			id={`${id || "default"}-table-search`}
			name={`${id || "default"}-table-search`}
			placeholder={lc.SEARCH_PLACEHOLDER}
			sx={theme => ({
				maxWidth: theme.spacing(48),
				flexGrow: 2,
				flexShrink: 1,
				...searchFieldSxExtension
			})}
			value={value}
			fullWidth={fullWidth}
			onChange={e => {
				setValue(e.target.value);
				onChange(e.target.value);
			}}
			onKeyDown={event => {
				if (event.key === EVENT_ENTER_KEY) {
					event.preventDefault();
				}
			}}
			variant={variant}
			size={size}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon
							data-testid="table-search-icon"
							color={searchQueryValue !== "" ? "primary" : "inherit"}
						/>
					</InputAdornment>
				),
				endAdornment: searchQueryValue ? (
					<InputAdornment position="end">
						<IconButton
							aria-label={lc.CLEAR_SEARCH_LABEL}
							onClick={() => {
								setValue("");
								onChange("");
							}}
						>
							<IconClose />
						</IconButton>
					</InputAdornment>
				) : (
					undefined
				)
			}}
		/>
	);
}

SearchTextField.propTypes = {
	searchQueryValue: PropTypes.string.isRequired,
	onSearchQueryValueChange: PropTypes.func.isRequired,
	id: PropTypes.string,
	searchFieldSxExtension: PropTypes.shape(),
	debounceTime: PropTypes.number,
	variant: PropTypes.string,
	size: PropTypes.string,
	fullWidth: PropTypes.bool
};
SearchTextField.defaultProps = {
	id: "",
	searchFieldSxExtension: {},
	debounceTime: 300,
	variant: "standard",
	size: "medium",
	fullWidth: false
};

export default SearchTextField;
