import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import isEmpty from "lodash/isEmpty";

import DimensionsToolbar from "./DimensionsToolbar";
import { SLICE_CONFIG_KEY } from "../Reporting/constants";
import useSkipInitChangeWatcher from "../../hooks/useSkipInitChangeWatcher";

function FormFieldDimensionsToolbar(props) {
	const { dimensionOptions, defaultUserDimensions } = props;
	const [field, , helpers] = useField(SLICE_CONFIG_KEY);
	const [dimensionsChosen, setDimensionsChosen] = useState(false);
	const {
		values: { reportType }
	} = useFormikContext();

	// Init data load sets the report type, which counts as the first "change" detected by the useEffect watcher. We need to take certain actions only on subsequent, post-init user changes to the form
	const [isReportTypeChangedByUser, dispatch] = useSkipInitChangeWatcher();
	useEffect(() => {
		if (reportType) {
			dispatch({ type: "VALUE_CHANGED" });
		}
	}, [reportType, dispatch]);

	useEffect(() => {
		if (isReportTypeChangedByUser) {
			setDimensionsChosen(false);
			helpers.setValue([]);
			dispatch({ type: "RESET" });
		}
	}, [isReportTypeChangedByUser, helpers, dispatch, setDimensionsChosen]);

	useEffect(() => {
		if (!dimensionsChosen) {
			helpers.setValue(defaultUserDimensions[reportType] || []);
			setDimensionsChosen(true);
		}
	}, [helpers, reportType, dimensionsChosen, defaultUserDimensions]);

	const onSelectDimension = id => {
		helpers.setValue([...field.value, id]);
	};

	const onReorder = newOrder => {
		helpers.setValue(newOrder);
	};

	const onDeleteDimension = idToRemove => {
		const dimensionIndex = field.value.findIndex(id => id === idToRemove);
		const newDimensions = [...field.value];
		newDimensions.splice(dimensionIndex, 1);
		helpers.setValue(newDimensions);
	};

	const onBulkDeleteDimension = idsToRemove => {
		if (idsToRemove.length) {
			const newDimensions = field.value.filter(id => !idsToRemove.includes(id));
			helpers.setValue(newDimensions);
		}
	};

	// Filter available options in sorted order
	const availableOptions = useMemo(
		() => dimensionOptions.filter(option => !field.value.includes(option.id)),
		[dimensionOptions, field.value]
	);
	// Use field value to set order of selected options
	const selectedOptions = useMemo(
		() =>
			isEmpty(dimensionOptions)
				? []
				: field.value.map(selectedId =>
						dimensionOptions.find(({ id }) => id === selectedId)
				  ),
		[dimensionOptions, field.value]
	);

	return (
		<DimensionsToolbar
			availableOptions={availableOptions}
			selectedOptions={selectedOptions}
			onSelect={onSelectDimension}
			onDelete={onDeleteDimension}
			onReorder={onReorder}
			bulkDelete={onBulkDeleteDimension}
		/>
	);
}

FormFieldDimensionsToolbar.propTypes = {
	dimensionOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	defaultUserDimensions: PropTypes.shape()
};

FormFieldDimensionsToolbar.defaultProps = {
	defaultUserDimensions: {}
};

export default FormFieldDimensionsToolbar;
