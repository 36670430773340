import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Paper, Tooltip, useTheme } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import Operators from "../../../FormFieldExpressionBuilder/Operators";
import lc from "./localeContent";
import { EXPRESSION_NODE_TYPE } from "../../../../config/constants";

export const RULE_VISUALIZER_TEST_ID = "rule-visualizer";

function VisualizerBlock(props) {
	const { node } = props;
	const { nodeType, customUrlKey, customUrlValues } = node;
	let label;
	let tooltip;
	let bold = false;
	switch (nodeType) {
		case EXPRESSION_NODE_TYPE.CUSTOM_URL_PARAM:
			label = `( ${customUrlValues.join(` ${Operators.OR.label} `)} )`;
			tooltip = `${lc.RULE_VISUALIZER.TOOLTIP_KEY_LABEL} = ${customUrlKey}`;
			break;
		default:
			label = ` ${Operators[nodeType].label} `;
			bold = true;
			break;
	}

	const baseElement = (
		<Box component="span" sx={{ fontWeight: bold ? "bold" : "normal" }}>
			{label}
		</Box>
	);

	return tooltip ? (
		<Tooltip title={tooltip} arrow placement="top">
			{baseElement}
		</Tooltip>
	) : (
		baseElement
	);
}

VisualizerBlock.propTypes = {
	node: PropTypes.shape({
		nodeType: PropTypes.string,
		customUrlKey: PropTypes.string,
		customUrlValues: PropTypes.arrayOf(PropTypes.string)
	}).isRequired
};

function CustomUrlRuleVisualizer(props) {
	const { expression } = props;
	const theme = useTheme();
	const expressionWithIds = expression.map(value => ({
		...value,
		key: uuidv4()
	}));

	const [showVisualizer, setShowVisualizer] = useState(false);
	return (
		expressionWithIds.length > 0 && (
			<>
				<Button
					onClick={() => setShowVisualizer(!showVisualizer)}
					startIcon={showVisualizer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					// Use a minWidth to keep the button the same size regardless of content
					sx={{ minWidth: theme.spacing(16), justifyContent: "left" }}
				>
					{showVisualizer
						? lc.RULE_VISUALIZER.HIDE_RULE_LABEL
						: lc.RULE_VISUALIZER.SHOW_RULE_LABEL}
				</Button>
				{showVisualizer && (
					<Paper
						sx={{
							boxShadow: "inset 1px 1px 2px rgba(0, 0, 0, 0.3)",
							p: 0.5,
							backgroundColor: theme.palette.secondary[200]
						}}
						data-testid={RULE_VISUALIZER_TEST_ID}
					>
						{expressionWithIds.map(node => (
							<VisualizerBlock node={node} key={node.key} />
						))}
					</Paper>
				)}
			</>
		)
	);
}

CustomUrlRuleVisualizer.propTypes = {
	expression: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default CustomUrlRuleVisualizer;
