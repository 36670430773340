export default {
	DEVICE_TARGETING_LABEL: "Device Targeting",
	INHERITED_LABEL: "Inherited",
	DESKTOP_LABEL: "Desktop",
	DESKTOP_OS_LABEL: "Desktop OS",
	DESKTOP_TOOLTIP:
		"Select all that apply. If none are selected then all Desktop operating systems will be allowed",
	DESKTOP_VENDOR_LABEL: "Desktop Vendors",
	DESKTOP_VENDOR_TOOLTIP:
		"Select one to apply. If none are selected then all Desktop vendors will be allowed",
	PHONE_LABEL: "SmartPhone",
	PHONE_OS_LABEL: "Smartphone OS",
	PHONE_TOOLTIP:
		"Select all that apply. If none are selected then all Smartphone operating systems will be allowed",
	PHONE_VENDOR_LABEL: "Smartphone Vendors",
	PHONE_VENDOR_TOOLTIP:
		"Select one to apply. If none are selected then all Smartphone vendors will be allowed",
	TABLET_LABEL: "Tablet",
	TABLET_OS_LABEL: "Tablet OS",
	TABLET_TOOLTIP:
		"Select all that apply. If none are selected then all Tablet operating systems will be allowed",
	TABLET_VENDOR_LABEL: "Tablet Vendors",
	TABLET_VENDOR_TOOLTIP:
		"Select one to apply. If none are selected then all Tablet vendors will be allowed",
	CTV_LABEL: "CTV/OTT",
	CTV_VENDOR_LABEL: "CTV Vendors",
	CTV_VENDOR_TOOLTIP:
		"Select one to apply. If none are selected then all CTV vendors will be allowed"
};
