import ExpressionBuilderLc from "../../components/FormFieldExpressionBuilder/localeContent";

export default {
	COLUMN_ESTIMATED_CPM: "Estimated CPM",
	COLUMN_POTENTIAL_COOKIE_REACH: "Potential Cookie Reach",
	COLUMN_POTENTIAL_DEVICE_REACH: "Potential Device Reach",
	FIELD_AUDIENCE_NAME: {
		LABEL: "Audience Name"
	},
	FIELD_DESCRIPTION: {
		LABEL: "Description"
	},
	AUDIENCE_LEAF_COLUMN_HEADERS: {
		NAME: "Name",
		PROVIDER: "Provider",
		CPM: "CPM ($)",
		COOKIE_REACH: "Cookie Reach",
		DEVICE_REACH: "Device Reach"
	},
	FIELD_EXPRESSION: {
		LEAF_NAME_LABEL: "segment",
		EMPTY_EXPRESSION_WARNING: ExpressionBuilderLc.DEFAULT_EMPTY_EXPRESSION_WARNING(
			"an audience segment"
		)
	}
};
