import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import startCase from "lodash/startCase";
import { Box } from "@mui/material";

import Navigation from "../Navigation/Navigation";
import {
	NAV_RAIL_WIDTH,
	FROM_QUERY_PARAM,
	getHomePageFullPath,
	PROTECTED_CONTENT_PATH,
	COMPANY_ID_QUERY_PARAM
} from "../../config/constants";
import {
	PROTECTED_CONTENT_ROUTES_CONFIG,
	UNPROTECTED_CONTENT_ROUTES_CONFIG
} from "../../config/routesConfig";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { AuthContext } from "../../context/AuthContext";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import { GoogleAnalyticsContext } from "../../context/GoogleAnalyticsContext";
import {
	MANAGEMENT_MENU_ITEMS,
	USER_MENU_ITEMS
} from "../../config/navigationConfig";
import { BrandingContext } from "../../context/BrandingContext";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";

/**
 * The protected application view includes the nav-bar and route
 * specific content
 * @returns {*}
 * @constructor
 */
function ProtectedAppView() {
	const { arePermissionsAvailable } = useContext(AuthorizationContext);
	const { isFetchingUserSettings } = useContext(
		AuthenticatedUserSettingsContext
	);
	const { branding } = useContext(BrandingContext);

	const isBrandingAvailable = Boolean(branding);
	return isBrandingAvailable &&
		arePermissionsAvailable &&
		!isFetchingUserSettings ? (
		<>
			<Navigation
				managementMenuItems={MANAGEMENT_MENU_ITEMS}
				userMenuItems={USER_MENU_ITEMS}
			/>
			<Box component="main" sx={{ ml: `${NAV_RAIL_WIDTH}px` }}>
				<ProtectedContentRoutes />
			</Box>
		</>
	) : (
		<SkeletonLoader />
	);
}

const setCompanyInTitle = (companyName, locationPathName) => {
	if (companyName != null) {
		const paths = locationPathName.split("/");
		const basePathName = paths.length >= 3 ? paths[2] : paths[1];
		document.title = `${companyName} - ${startCase(basePathName)}`;
	}
};

function Routing() {
	const authContext = useContext(AuthContext);
	const location = useLocation();
	const { sendPageView } = useContext(GoogleAnalyticsContext);
	const { companyName } = useContext(BrandingContext);
	useEffect(() => {
		const { pathname, search = "" } = location;
		const page = pathname + search;
		sendPageView(page);

		setCompanyInTitle(companyName, pathname);
	}, [companyName, location, sendPageView]);

	const getLoginQueryParams = () => {
		const { pathname, search = "" } = location;
		return `?${FROM_QUERY_PARAM}=${pathname + search}`;
	};

	return (
		<Switch>
			{UNPROTECTED_CONTENT_ROUTES_CONFIG.map(({ path, key, component }) => {
				return <Route path={path} key={key} component={component} />;
			})}
			{!authContext.isAuthenticated() && (
				<Redirect to={`/login${getLoginQueryParams()}`} />
			)}
			{authContext.isAccessDenied && (
				<Redirect
					to={`/403?${COMPANY_ID_QUERY_PARAM}=${authContext.companyId}`}
				/>
			)}
			<ProtectedAppView />
		</Switch>
	);
}

function RenderRoutes({ routes }) {
	return (
		<>
			{routes.map(route => {
				const RouteComponent = route.component;
				return (
					<Route path={route.path} key={route.key} exact={route.exact}>
						<RouteComponent />
					</Route>
				);
			})}
		</>
	);
}

RenderRoutes.propTypes = {
	routes: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

function ProtectedContentRoutes() {
	const authContext = useContext(AuthContext);
	const companyId = authContext?.companyId;

	return (
		<Switch>
			<Route path={PROTECTED_CONTENT_PATH} exact>
				<Redirect to={getHomePageFullPath(companyId)} />
			</Route>
			<Route path="/" exact>
				<Redirect to={PROTECTED_CONTENT_PATH} />
			</Route>
			<Route path={PROTECTED_CONTENT_PATH}>
				<Switch>
					<RenderRoutes routes={PROTECTED_CONTENT_ROUTES_CONFIG} />
				</Switch>
			</Route>
		</Switch>
	);
}

export default Routing;
