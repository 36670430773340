import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import MultiselectTypeAhead from "./MultiselectTypeAhead";

const transformDefaultSelectedOptions = (fieldValues, options) => {
	return fieldValues
		? fieldValues.map(item => ({
				id: item,
				name: options.find(option => option.id === item)?.name || item
		  }))
		: [];
};

const defaultSetValueFunction = newValue => {
	// Map Autocomplete value (Array[Object]) to Formik value (Array[String])
	return newValue.map(selectedOption => selectedOption.id);
};

function FormFieldMultiselectTypeAhead(props) {
	const {
		id,
		name,
		label,
		required,
		disabled,
		optionsConfig,
		fieldTypeOptions,
		tooltip,
		minCharsRequired
	} = props;
	const [field, meta, helpers] = useField(name);

	const { options } = optionsConfig;
	const {
		setValueOverride,
		transformSelectedOptionsOverride,
		formStateSetter,
		typeaheadDetails
	} = fieldTypeOptions;

	const setValueFunction = setValueOverride || defaultSetValueFunction;
	const transformSelectedOptions =
		transformSelectedOptionsOverride || transformDefaultSelectedOptions;

	const showError = Boolean(meta.touched && meta.error);
	const helperText = showError ? meta.error : "";

	const handleChange = useCallback(
		(event, newValue) => {
			const value = setValueFunction(newValue);
			if (formStateSetter) {
				formStateSetter(value);
			} else {
				helpers.setValue(value);
			}
		},
		[helpers, setValueFunction, formStateSetter]
	);

	const selectedOptions = transformSelectedOptions(field.value, options);

	return (
		<MultiselectTypeAhead
			id={id}
			label={label}
			name={name}
			options={options}
			selectedOptions={selectedOptions}
			onChange={handleChange}
			onBlur={field.onBlur}
			required={required}
			disabled={disabled}
			showError={showError}
			helperText={helperText}
			tooltip={tooltip}
			transformOptions={typeaheadDetails.transformOptions}
			requestEndpoint={typeaheadDetails.requestEndpoint}
			minCharsRequired={minCharsRequired}
		/>
	);
}

FormFieldMultiselectTypeAhead.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	optionsConfig: PropTypes.shape(),
	fieldTypeOptions: PropTypes.shape(),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
	minCharsRequired: PropTypes.number
};

FormFieldMultiselectTypeAhead.defaultProps = {
	required: false,
	disabled: false,
	optionsConfig: {},
	fieldTypeOptions: {},
	tooltip: null,
	minCharsRequired: 3
};

export default FormFieldMultiselectTypeAhead;
