import { createTheme } from "@mui/material/styles";
import { tableSortLabelClasses } from "@mui/material/TableSortLabel";
import {
	red,
	grey as secondaryColor,
	lightBlue,
	blue,
	green,
	pink,
	purple,
	deepPurple,
	indigo,
	cyan,
	teal,
	lightGreen,
	brown,
	lime,
	yellow,
	amber,
	orange,
	deepOrange,
	blueGrey,
	common
} from "@mui/material/colors";
import { PRIMARY_COLORS as COLOR_OPTIONS } from "./constants";

const commonWhiteColor = common.white;

export const getColorDefinition = colorEnum => {
	switch (colorEnum) {
		case COLOR_OPTIONS.GREEN.id:
			return green;
		case COLOR_OPTIONS.BLUE.id:
			return blue;
		case COLOR_OPTIONS.LIGHT_BLUE.id:
			return lightBlue;
		case COLOR_OPTIONS.PINK.id:
			return pink;
		case COLOR_OPTIONS.PURPLE.id:
			return purple;
		case COLOR_OPTIONS.DEEP_PURPLE.id:
			return deepPurple;
		case COLOR_OPTIONS.INDIGO.id:
			return indigo;
		case COLOR_OPTIONS.CYAN.id:
			return cyan;
		case COLOR_OPTIONS.TEAL.id:
			return teal;
		case COLOR_OPTIONS.LIGHT_GREEN.id:
			return lightGreen;
		case COLOR_OPTIONS.LIME.id:
			return lime;
		case COLOR_OPTIONS.YELLOW.id:
			return yellow;
		case COLOR_OPTIONS.AMBER.id:
			return amber;
		case COLOR_OPTIONS.ORANGE.id:
			return orange;
		case COLOR_OPTIONS.DEEP_ORANGE.id:
			return deepOrange;
		case COLOR_OPTIONS.BROWN.id:
			return brown;
		case COLOR_OPTIONS.GREY.id:
			return secondaryColor;
		case COLOR_OPTIONS.BLUE_GREY.id:
			return blueGrey;
		case COLOR_OPTIONS.RED.id:
			return red;
		default:
			return lightBlue;
	}
};

export const COMMON_FONTS = ["Roboto", "'Helvetica Neue'", "Arial"].join(",");

export const buildDashboardMuiTheme = primaryColorEnum =>
	createTheme({
		palette: {
			primary: getColorDefinition(primaryColorEnum),
			secondary: secondaryColor,
			error: red,
			background: {
				default: secondaryColor[200]
			}
		},
		typography: {
			useNextVariants: true,
			fontFamily: COMMON_FONTS
		},
		spacing: 8,
		components: {
			MuiIconButton: {
				defaultProps: {
					size: "large"
				}
			},
			MuiLink: {
				defaultProps: {
					underline: "hover"
				}
			},
			MuiTableSortLabel: {
				styleOverrides: {
					// Used to override the header interaction styling
					// MUI component style implementation: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/TableSortLabel/TableSortLabel.js
					// More info: https://github.com/mui-org/material-ui/issues/20460
					root: {
						"&:focus": {
							color: secondaryColor[300],
							"&& $icon": {
								opacity: 0.5
							}
						},
						"&:hover": {
							color: secondaryColor[300]
						},
						"&.Mui-active": {
							color: commonWhiteColor,
							[`&& .${tableSortLabelClasses.icon}`]: {
								color: commonWhiteColor
							}
						}
					}
				}
			},
			MuiTextField: {
				defaultProps: {
					// The standard variant is no longer the default as of mui v5 so we're making it the default prop for our app (https://mui.com/material-ui/migration/v5-component-changes/#%E2%9C%85-update-default-variant-3)
					variant: "standard"
				}
			},
			// Per MUI defaults, columns that cannot be sorted are given a disabled label in the column visibility toggle menu. Since users cannot interact with the toggle, and there is no intention of eventually enabling the toggle, we choose to hide the toggle and label completely
			MuiDataGrid: {
				styleOverrides: {
					columnsPanelRow: {
						".Mui-disabled span": {
							display: "none"
						}
					}
				}
			}
		}
	});

export const buildAuthenticationMuiTheme = () =>
	createTheme({
		palette: {
			background: {
				default: "white"
			},
			primary: {
				main: "#121244"
			}
		},
		typography: {
			useNextVariants: true,
			fontFamily: COMMON_FONTS
		},
		spacing: 8,
		components: {
			MuiIconButton: {
				defaultProps: {
					size: "large"
				}
			},

			MuiLink: {
				defaultProps: {
					underline: "hover"
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						background: "white",
						border: "none",
						borderRadius: "3px",
						boxShadow: "0 1px 4px #28282f",
						color: "#121244",
						fontSize: "14px",
						textTransform: "none",
						fontWeight: "400",
						marginBottom: "25px",
						"&:hover": {
							backgroundColor: "#121244",
							color: "white"
						}
					},
					text: {
						padding: "12px 40px"
					}
				}
			},
			MuiFilledInput: {
				styleOverrides: {
					underline: {
						"&:after": {
							borderBottom: "2px solid black"
						}
					}
				}
			},
			MuiFormLabel: {
				styleOverrides: {
					root: {
						"&$focused": {
							color: "#121244"
						}
					}
				}
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						color: "#121244"
					}
				}
			},
			MuiContainer: {
				styleOverrides: {
					root: {
						color: "#121244"
					}
				}
			}
		}
	});

export default buildDashboardMuiTheme(COLOR_OPTIONS.BLUE.id);
