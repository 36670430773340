import React, { useContext, forwardRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { updateCompanyIdQueryStringParameter } from "../../utils/endpointUtils";
import { AuthContext } from "../../context/AuthContext";

const LinkWithQuery = forwardRef(({ to, children, ...props }, ref) => {
	const authContext = useContext(AuthContext);
	const companyId = authContext?.companyId;

	const toWithQuery = updateCompanyIdQueryStringParameter(to, companyId);

	const propsWithQuery = { ...props, to: toWithQuery };
	return (
		// This component is used as a MUI component prop so we don't control which props need to be passed through
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Link {...propsWithQuery} ref={ref}>
			{children}
		</Link>
	);
});

LinkWithQuery.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape(),
		PropTypes.array
	]).isRequired
};

LinkWithQuery.defaultProps = {};

export default LinkWithQuery;
