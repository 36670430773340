import React, { useState, useContext, forwardRef } from "react";
import PropTypes from "prop-types";

import { Operations } from "../../hooks/useResource";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import ConfirmDialogueSimple from "../ConfirmDialogueSimple/ConfirmDialogueSimple";
import MenuOptionClickHandler from "./MenuOptionClickHandler";
import { SnackbarContext } from "../../context/SnackbarContext";

const MenuOptionConfirmPostResource = forwardRef((props, ref) => {
	const {
		label,
		onClick,
		resourceEndpoint,
		rowItemId,
		itemCallback,
		title,
		description,
		confirmLabel,
		successMessage,
		setIsLoading,
		customStartPathEndPoint
	} = props;

	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const { execute: resourceEntity } = useResourceAsync(
		resourceEndpoint,
		Operations.CREATE,
		{ customStartPathEndPoint }
	);

	const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

	const openConfirmation = () => {
		setIsOpenConfirmation(true);
	};

	const closeConfirmation = () => {
		setIsOpenConfirmation(false);
	};

	const handleConfirmItem = async () => {
		closeConfirmation();
		setIsLoading(true);
		const { error } = await resourceEntity({ resourceId: rowItemId });
		setIsLoading(false);
		if (error) {
			const { message } = error;
			triggerNewSnackbarMessage({
				message,
				severity: "error"
			});
			closeConfirmation();
			return;
		}

		triggerNewSnackbarMessage({
			message: successMessage,
			severity: "success"
		});
		itemCallback(rowItemId);
	};

	return (
		<>
			<MenuOptionClickHandler
				ref={ref}
				label={label}
				onClick={() => {
					onClick();
					openConfirmation();
				}}
			/>
			<ConfirmDialogueSimple
				title={title}
				description={description}
				isOpen={isOpenConfirmation}
				onConfirm={handleConfirmItem}
				confirmLabel={confirmLabel}
				onCancel={closeConfirmation}
				onClose={closeConfirmation}
			/>
		</>
	);
});

MenuOptionConfirmPostResource.propTypes = {
	onClick: PropTypes.func,
	label: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	confirmLabel: PropTypes.string.isRequired,
	successMessage: PropTypes.string.isRequired,
	resourceEndpoint: PropTypes.string.isRequired,
	rowItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
		.isRequired,
	itemCallback: PropTypes.func,
	setIsLoading: PropTypes.func.isRequired,
	customStartPathEndPoint: PropTypes.func
};

MenuOptionConfirmPostResource.defaultProps = {
	onClick: undefined,
	itemCallback: () => {},
	customStartPathEndPoint: undefined
};

export default MenuOptionConfirmPostResource;
