import { METRIC_TYPES } from "./metricsConfig";
import localeContent from "./localeContent";

export const SLICE_CONFIG_KEY = "sliceConfig";
export const REPORT_TYPE_KEY = "reportType";
export const DATE_RANGE_KEY = "dateRange";
export const TIME_ZONE_KEY = "tz";
export const FILTER_KEY = "filters";
export const LEFT_METRIC_INDEX = 0;
export const RIGHT_METRIC_INDEX = 1;

export const CHART_CONFIG = {
	campaign: {
		label: localeContent.METRIC_LABELS.GROSS_REVENUE,
		metric: "grossrevenue",
		type: METRIC_TYPES.CURRENCY
	},
	network: {
		label: localeContent.METRIC_LABELS.GROSS_REVENUE,
		metric: "grossrevenue",
		type: METRIC_TYPES.CURRENCY
	},
	rtb: {
		label: localeContent.METRIC_LABELS.CLOSE_REVENUE,
		metric: "winrevenue",
		type: METRIC_TYPES.CURRENCY
	}
};
