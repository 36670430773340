/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useTable, useSortBy, usePagination } from "react-table";
import { SortableContext } from "@dnd-kit/sortable";
import TableContainer from "@mui/material/TableContainer";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

import TablePaginationActions from "../CrudDataGrid/DataTablePaginationActions";
import SortableExpressionNode from "./SortableExpressionNode";
import lc from "./localeContent";
import ReactTableSortableHeaderCell from "../ReactTableSortableHeaderCell/ReactTableSortableHeaderCell";
import { MUI_PADDING_NORMAL } from "../../config/constants";

export const TABLE_ROW_TEST_ID = "expression-leaf-table-row";

function ExpressionLeafTable(props) {
	const { leafOptions, sortableIdKey, columns } = props;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		gotoPage,
		state: { pageIndex, pageSize }
	} = useTable(
		{
			columns,
			data: leafOptions,
			initialState: {
				pageIndex: 0,
				pageSize: 5
			},
			disableSortRemove: true,
			autoResetSortBy: false,
			autoResetPage: false
		},
		useSortBy,
		usePagination
	);

	const sortableIds = useMemo(() => leafOptions.map(({ id }) => id), [
		leafOptions
	]);

	const emptyRows = pageSize - page.length;

	const safePage = Math.floor(rows.length / pageSize);

	// Anytime the options change, go to page 1
	useEffect(() => {
		gotoPage(0);
	}, [leafOptions, gotoPage]);
	return (
		<SortableContext items={sortableIds} strategy={() => {}}>
			<TableContainer>
				<MuiTable
					{...getTableProps()}
					aria-label={lc.ACCESSIBLE_TABLE_LABEL_EXPRESSION_OPTIONS}
					size="small"
					sx={{ minWidth: "960px" }}
				>
					<TableHead>
						{headerGroups.map(headerGroup => (
							<TableRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<ReactTableSortableHeaderCell
										key={column.id}
										column={column}
									/>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody {...getTableBodyProps()}>
						{page.map(row => {
							prepareRow(row);
							return (
								<TableRow
									{...row.getRowProps()}
									data-testid={`${TABLE_ROW_TEST_ID}-${row.original.id}`}
								>
									{row.cells.map(cell => {
										const {
											[sortableIdKey]: sortableId,
											name
										} = cell.row.original;
										return (
											<TableCell
												{...cell.getCellProps()}
												padding={
													cell.column.tableCellPadding || MUI_PADDING_NORMAL
												}
												align={cell.column.align}
											>
												{cell.column.isSortable ? (
													<SortableExpressionNode
														sortableId={sortableId}
														label={name}
														size="small"
														isLeafNode
													/>
												) : (
													cell.render("Cell")
												)}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
						{emptyRows > 0 && (
							<TableRow style={{ height: 37 * emptyRows }}>
								<TableCell colSpan={columns.length} />
							</TableRow>
						)}
					</TableBody>
				</MuiTable>
			</TableContainer>
			<TablePagination
				labelDisplayedRows={
					rows.length < 1000
						? undefined
						: ({ from, to }) => lc.PAGINATION_LABEL_UNKNOWN_COUNT({ from, to })
				}
				rowsPerPageOptions={[]}
				component="div"
				count={rows.length}
				rowsPerPage={pageSize}
				page={Math.min(safePage, pageIndex)}
				onPageChange={(event, newPage) => {
					gotoPage(newPage);
				}}
				ActionsComponent={TablePaginationActions}
				SelectProps={{
					labelId: "rows-per-page-label",
					name: "rowsPerPage"
				}}
			/>
		</SortableContext>
	);
}

ExpressionLeafTable.propTypes = {
	sortableIdKey: PropTypes.string.isRequired,
	leafOptions: PropTypes.arrayOf(PropTypes.shape()),
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			accessor: PropTypes.string.isRequired,
			Header: PropTypes.string.isRequired
		})
	).isRequired
};

ExpressionLeafTable.defaultProps = {
	leafOptions: []
};

export default ExpressionLeafTable;
