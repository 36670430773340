import React from "react";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import FormTextField from "../FormTextField/FormTextField";

/**
 * A wrapper around the base FormTextField that:
 *   - adds number attributes to the input
 *   - adds a "$" input adornment
 * @param {Object} props defined in propTypes
 */
function FormCurrencyField(props) {
	const { name, id, label, required, disabled } = props;
	return (
		<FormTextField
			label={label}
			id={id}
			name={name}
			type="number"
			required={required}
			disabled={disabled}
			inputProps={{
				min: 0,
				step: 0.01
			}}
			// This is a material-ui prop so we have no control over this
			// eslint-disable-next-line react/jsx-no-duplicate-props
			InputProps={{
				startAdornment: <InputAdornment position="start">$</InputAdornment>
			}}
		/>
	);
}

FormCurrencyField.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool
};

FormCurrencyField.defaultProps = {
	required: false,
	disabled: false
};

export default FormCurrencyField;
