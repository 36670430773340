import React, { useContext } from "react";
import PropTypes from "prop-types";
import { IconButton, Link } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import { updateCompanyIdQueryStringParameter } from "../../utils/endpointUtils";

// Turn on the button visible when hover on the name
const hoverLinkNameProps = {
	"&:hover > button": {
		opacity: 1
	}
};

// Have the icon button by default not visible and when we hover turn it on
const hoverLinkSxIconProps = {
	opacity: 0,
	py: 0,
	"&:hover": {
		bgColor: "transparent",
		opacity: 1
	},
	m: "auto"
};

// Renders a link that opens in a new browser tab/window
function LinkToNewTab({ to, text }) {
	const { companyId } = useContext(AuthContext);
	const urlWithCompanyId = updateCompanyIdQueryStringParameter(to, companyId);

	return (
		<Link
			href={urlWithCompanyId}
			target="_blank"
			rel="noopener"
			underline="hover"
			color="inherit"
			sx={hoverLinkNameProps}
			onClick={event => {
				event.stopPropagation();
			}}
		>
			{text}
			<IconButton sx={hoverLinkSxIconProps}>
				<OpenInNew />
			</IconButton>
		</Link>
	);
}

LinkToNewTab.propTypes = {
	to: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired
};

export default LinkToNewTab;
