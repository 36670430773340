export default {
	GEO_TARGETING_LABEL: "Geo Targeting",
	COUNTRY: {
		LABEL: "Country",
		TOOLTIP:
			"Demand will be limited to selected countries. If none are selected, then all are allowed.",
		NON_INHERITED_VALIDATION_ERROR:
			"The highlighted countries are invalid because they are not an inherited value"
	},
	REGION: {
		LABEL: "Region",
		TOOLTIP:
			"Demand will be limited to selected states and regions. If none are selected, then all states and regions belonging to targeted countries are allowed.",
		NON_INHERITED_VALIDATION_ERROR:
			"The highlighted regions are invalid because they are not an inherited value"
	},
	CITY: {
		LABEL: "City",
		TOOLTIP:
			"Demand will be limited to selected cities. If none are selected, then all are cities belonging to targeted countries or states/regions are allowed.",
		NON_INHERITED_VALIDATION_ERROR:
			"The highlighted cities are invalid because they are not an inherited value"
	},
	DMA: {
		LABEL: "DMA",
		TOOLTIP:
			"Demand will be limited to selected DMAs. If none are selected, then all DMAs are allowed.",
		NON_INHERITED_VALIDATION_ERROR:
			"The highlighted DMAs are invalid because they are not an inherited value"
	},
	ZIP_CODE: {
		LABEL: "Postal Code",
		TOOLTIP:
			"Demand will be limited to selected Postal Codes. If none are selected, then all Postal Codes are allowed.",
		NON_INHERITED_VALIDATION_ERROR:
			"The highlighted postal codes are invalid because they are not an inherited value",
		NON_ALPHANUMERIC_VALIDATION_ERROR:
			"Value may only contain alpha-numeric characters"
	},
	INHERITED_LABEL: "Inherited"
};
