import React, {
	createContext,
	useContext,
	useEffect,
	useCallback,
	useState
} from "react";
import PropTypes from "prop-types";

import { AuthContext } from "./AuthContext";
import { Operations, useResourceAsync } from "../hooks/useResourceAsync";

const AuthorizationContext = createContext();
const { Provider } = AuthorizationContext;

function AuthorizationProvider({ children }) {
	const { authState } = useContext(AuthContext);
	const [arePermissionsAvailable, setArePermissionsAvailable] = useState(false);

	// On application init, and whenever the token changes, re-request permissions
	const [shouldRequest, setShouldRequest] = useState(false);
	useEffect(() => {
		if (authState.token) {
			setShouldRequest(true);
		} else {
			setArePermissionsAvailable(false);
		}
	}, [authState.token]);

	const {
		data: userPermissions,
		execute: requestUserPermissions
	} = useResourceAsync("authenticated-user/permissions", Operations.LIST);

	useEffect(() => {
		const refreshUserPermissions = async () => {
			const { error: userPermissionsError } = await requestUserPermissions({});
			if (userPermissionsError) {
				setArePermissionsAvailable(false);
			}
			setArePermissionsAvailable(true);
		};

		if (shouldRequest) {
			refreshUserPermissions();
			setShouldRequest(false);
		}
	}, [shouldRequest, requestUserPermissions]);

	const hasPermission = useCallback(
		permission =>
			authState.token &&
			userPermissions &&
			userPermissions.includes(permission),
		[authState.token, userPermissions]
	);

	return (
		<Provider value={{ hasPermission, arePermissionsAvailable }}>
			{children}
		</Provider>
	);
}

AuthorizationProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { AuthorizationContext, AuthorizationProvider };
