import React from "react";
import PropTypes from "prop-types";
import FormFieldMultiselect from "../FormFieldMultiselect/FormFieldMultiselect";

function FormFieldBooleanMultiselect(props) {
	const {
		id,
		label,
		name,
		required,
		disabled,
		optionsConfig,
		fieldTypeOptions,
		tooltip
	} = props;
	const { isBlacklist } = fieldTypeOptions;
	const reverseBooleanForBlacklist = boolean => {
		return isBlacklist ? !boolean : boolean;
	};
	return (
		<FormFieldMultiselect
			id={id}
			label={label}
			name={name}
			required={required}
			disabled={disabled}
			optionsConfig={optionsConfig}
			fieldTypeOptions={{
				setValueOverride: (newValue, fieldOptions) =>
					fieldOptions.reduce(
						(reducer, fieldOption) => ({
							...reducer,
							[fieldOption.id]: reverseBooleanForBlacklist(
								newValue.indexOf(fieldOption) !== -1
							)
						}),
						{}
					),
				filterSelectedOptionsOverride: fieldValue => option =>
					reverseBooleanForBlacklist(fieldValue[option.id]),
				...fieldTypeOptions
			}}
			tooltip={tooltip}
		/>
	);
}

FormFieldBooleanMultiselect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	optionsConfig: PropTypes.shape(),
	fieldTypeOptions: PropTypes.shape(),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
};

FormFieldBooleanMultiselect.defaultProps = {
	required: false,
	disabled: false,
	optionsConfig: {},
	fieldTypeOptions: {},
	tooltip: null
};

export default FormFieldBooleanMultiselect;
