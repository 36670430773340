import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import {
	Paper,
	Box,
	Grid,
	Button,
	IconButton,
	InputBase,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Divider,
	FormHelperText
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IconClose from "@mui/icons-material/Close";
import _isEmpty from "lodash/isEmpty";

import FileUploadButton from "../FileUploadButton/FileUploadButton";
import SearchTextField from "../SearchTextField/SearchTextField";
import lc from "./localeContent";
import validators from "../../utils/validators";
import { EVENT_ENTER_KEY } from "../../config/constants";
import uniqueElementsFromCsv from "../../utils/uniqueElementsFromCsv";

// Used by FieldTypes
export const transformInitData = (data, fieldName) => {
	return data[fieldName] || {};
};

export const transformSubmitData = (data, fieldName) => {
	return { [fieldName]: data };
};

const buildInheritedValuesMessage = (inheritedValues, label) =>
	_isEmpty(inheritedValues)
		? ""
		: lc.INHERITED_TEXT(label, inheritedValues.length);

function FormFieldLargeList(props) {
	const {
		name,
		fieldTypeOptions: { targetingTypeLabel, validatorFunction },
		inheritedValues
	} = props;
	const [field, meta, helpers] = useField(name);
	const fieldValues = _isEmpty(field.value) ? [] : field.value;
	const [inheritedValuesMessage, setInheritedValuesMessage] = useState("");

	// Watch for changes to inheritedValues
	useEffect(() => {
		const newInheritedValues = buildInheritedValuesMessage(
			inheritedValues,
			targetingTypeLabel
		);
		setInheritedValuesMessage(newInheritedValues);
	}, [inheritedValues, targetingTypeLabel]);

	const [searchFilter, setSearchFilter] = useState("");
	const fieldInfo = { value: "", error: "" };
	const [newField, setNewField] = useState(fieldInfo);

	const addFieldValue = () => {
		const newValue = newField.value.toLowerCase().trim();
		const isValid = !validatorFunction || validatorFunction(newValue);
		let error = "";
		let { value } = newField;
		if (!isValid) {
			error = lc.INVALID_ERROR_MESSAGE({ targetingTypeLabel });
		} else if (fieldValues.includes(newValue)) {
			error = lc.DUPPLICATED_ERROR_MESSAGE({ targetingTypeLabel });
		} else if (
			!validators.isSubsetOfInheritedValues(newValue, inheritedValues)
		) {
			error = lc.INHERITED_ERROR_MESSAGE;
		} else if (newValue) {
			fieldValues.push(newValue);
			helpers.setValue(fieldValues);
			value = "";
			error = "";
		}
		setNewField({ value, error });
	};

	const removeFieldValue = valueToBeRemoved => {
		helpers.setValue(fieldValues.filter(val => val !== valueToBeRemoved));
	};

	const removeAll = () => {
		helpers.setValue([]);
	};

	const uploadFile = file => {
		const fileReader = new FileReader();

		fileReader.onload = fileLoadedEvent => {
			const records = uniqueElementsFromCsv(fileLoadedEvent.target.result);
			const validate = validatorFunction || (() => true);
			const newRecords = records
				.filter(validate)
				.filter(record =>
					validators.isSubsetOfInheritedValues(record, inheritedValues)
				)
				.filter(record => !fieldValues.includes(record));

			helpers.setValue(newRecords.concat(fieldValues));
		};
		fileReader.readAsText(file);
	};

	const fieldChangeHandler = event => {
		setNewField({ value: event.target.value, error: "" });
	};

	const fieldKeydownHandler = event => {
		if (event.key === EVENT_ENTER_KEY && event.target.value !== "") {
			event.preventDefault();
			addFieldValue();
		}
	};

	const errorMessage = newField.error || (meta.touched && meta.error);

	return (
		<Grid item md={12}>
			<Paper elevation={3}>
				<Box display="flex" px={2} py={1}>
					<Grid item md={6} sm={12} container>
						<SearchTextField
							id="search-largelist"
							searchQueryValue={searchFilter}
							onSearchQueryValueChange={setSearchFilter}
							variant="filled"
							size="small"
						/>
					</Grid>
					<Grid
						item
						md={6}
						sm={12}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "end"
						}}
					>
						<Button onClick={removeAll}>{lc.REMOVE_ALL_LABEL}</Button>
						<Box sx={{ width: "50%" }}>
							<FileUploadButton
								name={name}
								label={lc.FILE_UPLOAD_BUTTON_LABEL}
								acceptedFileTypes={[".csv"]}
								onFileUpload={uploadFile}
								fullWidth
							/>
						</Box>
					</Grid>
				</Box>

				<Divider />
				<Grid item container sm={12} md={12}>
					<Box p={1.5} width={1} height={240} sx={{ overflowY: "auto" }}>
						<List dense>
							{fieldValues
								?.filter(
									value => searchFilter === "" || value.includes(searchFilter)
								)
								.map((value, index) => (
									<ListItem key={value}>
										<ListItemText primary={value} />
										<ListItemSecondaryAction>
											<IconButton
												aria-label={lc.REMOVE_VALUE_BUTTON_LABEL({
													index
												})}
												onClick={() => removeFieldValue(value)}
											>
												<IconClose />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
						</List>
					</Box>
				</Grid>
				<Divider />
				{inheritedValuesMessage && (
					<FormHelperText sx={{ pl: 2 }}>
						{inheritedValuesMessage}
					</FormHelperText>
				)}
				<Box display="flex" px={2} pt={1}>
					<InputBase
						id="new-field-value-input"
						name="newRestrictionInput"
						placeholder={lc.FIELD_VALUE_INPUT_LABEL({
							targetingTypeLabel
						})}
						fullWidth
						value={newField.value}
						onChange={fieldChangeHandler}
						onKeyDown={fieldKeydownHandler}
					/>
					<IconButton
						aria-label={lc.ADD_BUTTON_LABEL}
						size="small"
						onClick={addFieldValue}
					>
						<AddIcon />
					</IconButton>
				</Box>
				{errorMessage && (
					<FormHelperText sx={{ pl: 2, pb: 1 }} error>
						{errorMessage}
					</FormHelperText>
				)}
			</Paper>
		</Grid>
	);
}

FormFieldLargeList.propTypes = {
	name: PropTypes.string.isRequired,
	fieldTypeOptions: PropTypes.shape({
		targetingTypeLabel: PropTypes.string.isRequired,
		validatorFunction: PropTypes.func
	}).isRequired,
	inheritedValues: PropTypes.instanceOf(Array)
};

FormFieldLargeList.defaultProps = {
	inheritedValues: []
};

export default FormFieldLargeList;
