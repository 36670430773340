export default {
	SIZE_TARGETING_LABEL: "Size Targeting",
	SIZE_TARGETING_TYPE: {
		STANDARD_LABEL: "Standard",
		CUSTOM_LABEL: "Custom"
	},
	STANDARD_SIZE: {
		SMALL_LABEL: "Small",
		MEDIUM_LABEL: "Medium",
		LARGE_LABEL: "Large"
	},
	TYPE_LABEL: "Type",
	STANDARD_SIZE_OPTIONS_LABEL: "Standard Sizes",
	CUSTOM_SIZE_WIDTH_MIN_LABEL: "Minimum Width",
	CUSTOM_SIZE_WIDTH_MAX_LABEL: "Maximum Width",
	CUSTOM_SIZE_HEIGHT_MIN_LABEL: "Minimum Height",
	CUSTOM_SIZE_HEIGHT_MAX_LABEL: "Maximum Height",
	NON_NEGATIVE_VALUE_WARNING: "Must be a non-negative number",
	MAXIMUM_VALUE_WARNING: ({ maxValue }) => `Cannot be greater than ${maxValue}`,
	AT_LEAST_ONE_FIELD_REQUIRED_ERROR_STRING:
		"A value is required for at least one of the custom dimensions",
	CUSTOM_WIDTH_RANGE_VALIDATION_ERROR_STRING:
		"Maximum width cannot be less than minimum width",
	CUSTOM_HEIGHT_RANGE_VALIDATION_ERROR_STRING:
		"Maximum height cannot be less than minimum height"
};
