import React, { createContext, useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import { getAuthenticatedEndpoint } from "../utils/endpointUtils";
import { FetchContext } from "./FetchContext";
import { AuthContext } from "./AuthContext";

const UserNotificationContext = createContext();
const { Provider } = UserNotificationContext;

function UserNotificationProvider(props) {
	const { children } = props;
	const { authAxios } = useContext(FetchContext);
	const { authState } = useContext(AuthContext);
	const [unreadNotifications, setUnreadNotifications] = useState({});
	const [intervalId, setIntervalId] = useState(null);
	useEffect(() => {
		if (!authState.token) {
			clearInterval(intervalId);
			setIntervalId(null);
		}
	}, [intervalId, authState.token]);
	const fetchUnreadNotifications = async () => {
		try {
			const result = await authAxios({
				method: "get",
				url: getAuthenticatedEndpoint(
					`notifications/user-notifications?state=UNREAD`
				)
			});
			setUnreadNotifications(result.data);
		} catch (err) {
			setUnreadNotifications([]);
		}
	};
	if (authState.token && !intervalId) {
		fetchUnreadNotifications();
		const interval = setInterval(() => fetchUnreadNotifications(), 60000);
		// IntervalId is used to cancel the interval when user logged out from the application.
		setIntervalId(interval);
	}

	return (
		<Provider
			value={{
				unreadNotifications
			}}
		>
			{children}
		</Provider>
	);
}

UserNotificationProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { UserNotificationProvider, UserNotificationContext };
