import React from "react";
import PropTypes from "prop-types";
import {
	Toolbar,
	AppBar,
	Typography,
	Button,
	Tabs,
	Tab,
	Badge,
	Box
} from "@mui/material";

import RouterLink from "../Routing/LinkWithQuery";
import CrudFormBreadcrumbs from "../CrudFormBreadcrumbs/CrudFormBreadcrumbs";

function PageTitleBar(props) {
	const {
		barTitle,
		actionButtonText,
		actionButtonRoute,
		actionButtonIconComponent: ActionButtonIconComponent,
		isActionButtonDisabled,
		tabsConfig,
		activeTabKey,
		handleTabChange,
		breadCrumbs,
		actionButtonComponent
	} = props;

	const buttonVariant = "contained";
	const buttonSize = "large";
	const startIcon = ActionButtonIconComponent ? (
		<ActionButtonIconComponent />
	) : (
		false
	);
	const defaultActionButton = actionButtonText && (
		<Button
			sx={{
				color: "common.white",
				backgroundColor: "primary.900",
				"&:hover": {
					backgroundColor: "primary.800"
				}
			}}
			variant={buttonVariant}
			size={buttonSize}
			startIcon={startIcon}
			component={actionButtonRoute ? RouterLink : "button"}
			to={actionButtonRoute}
			type={actionButtonRoute ? "button" : "submit"}
			disabled={isActionButtonDisabled}
		>
			{actionButtonText}
		</Button>
	);
	return (
		<AppBar
			position="sticky"
			sx={theme => ({
				borderTopWidth: theme.spacing(2),
				borderTopColor: theme.palette.primary[900],
				borderTopStyle: "solid"
			})}
		>
			<Toolbar
				sx={{
					minHeight: breadCrumbs.length > 0 ? "42px" : undefined
				}}
			>
				<Box sx={{ flexGrow: 1 }}>
					{breadCrumbs.length > 0 && (
						<CrudFormBreadcrumbs breadcrumbs={breadCrumbs} />
					)}
					<Typography
						variant={breadCrumbs.length > 0 ? "h5" : "h4"}
						component="h1"
						data-testid="page-title-h1"
					>
						{barTitle}
					</Typography>
				</Box>
				{actionButtonComponent || defaultActionButton}
			</Toolbar>
			{tabsConfig && activeTabKey && (
				<Tabs
					value={activeTabKey}
					onChange={handleTabChange}
					aria-label="form sections control"
					variant="fullWidth"
					textColor="inherit"
					sx={{
						"& .MuiTabs-indicator": {
							bgcolor: "primary.900"
						}
					}}
				>
					{tabsConfig.map(tabConfig => (
						<Tab
							id={`tab-${tabConfig.key}`}
							aria-controls={`tabpanel-${tabConfig.key}`}
							key={tabConfig.key}
							label={
								<Badge
									color="error"
									badgeContent={
										tabConfig.errorCount > 0 ? tabConfig.errorCount : null
									}
									overlap="circular"
								>
									<Typography>{tabConfig.label}</Typography>
								</Badge>
							}
							value={tabConfig.key}
							disabled={tabConfig.disabled}
						/>
					))}
				</Tabs>
			)}
		</AppBar>
	);
}

PageTitleBar.propTypes = {
	barTitle: PropTypes.string.isRequired,
	actionButtonText: PropTypes.string,
	actionButtonRoute: PropTypes.string,
	actionButtonIconComponent: PropTypes.shape(),
	isActionButtonDisabled: PropTypes.bool,
	tabsConfig: PropTypes.arrayOf(PropTypes.shape()),
	activeTabKey: PropTypes.string,
	handleTabChange: PropTypes.func,
	breadCrumbs: PropTypes.arrayOf(PropTypes.shape()),
	actionButtonComponent: PropTypes.shape()
};

PageTitleBar.defaultProps = {
	actionButtonText: null,
	actionButtonRoute: null,
	actionButtonIconComponent: null,
	isActionButtonDisabled: false,
	tabsConfig: null,
	activeTabKey: null,
	handleTabChange: () => {},
	breadCrumbs: [],
	actionButtonComponent: null
};

export default PageTitleBar;
