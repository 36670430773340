import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormikContext } from "formik";

import isEmpty from "lodash/isEmpty";
import KPIMetricsCard from "./KPIMetricCard";
import { AuthenticatedUserSettingsContext } from "../../../context/AuthenticatedUserSettingsContext";
import { getMetricsConfigByType } from "../metricsConfig";
import { AuthContext } from "../../../context/AuthContext";

const GRID_COLUMN_SPACING = 1;

function mapKpiIdsToColumnConfigObjects(kpiIds, columnsConfigObjects) {
	if (isEmpty(kpiIds)) {
		return [];
	}

	return columnsConfigObjects.filter(v => {
		return kpiIds.includes(v.field);
	});
}

function KPIMetrics(props) {
	const {
		values: { reportType }
	} = useFormikContext();
	const { kpiInfo, prevKpiInfo } = props;
	const { defaultUserKpis } = useContext(AuthenticatedUserSettingsContext);
	const { isDemandClient } = useContext(AuthContext);
	// Do not render until we have a report type
	const configuredKpiIds = reportType ? defaultUserKpis[reportType] : {};
	const configuredMetrics = mapKpiIdsToColumnConfigObjects(
		configuredKpiIds,
		getMetricsConfigByType(reportType, { isDemandClient })
	);

	return (
		<Box sx={{ m: 3 }}>
			<Grid container spacing={GRID_COLUMN_SPACING}>
				<Grid
					item
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={GRID_COLUMN_SPACING}
					xs={12}
					data-testid="kpi-metrics-parent"
				>
					{configuredMetrics.map(metric => {
						return (
							<KPIMetricsCard
								metric={metric}
								kpiInfo={kpiInfo}
								prevKpiInfo={prevKpiInfo}
								key={metric.field}
							/>
						);
					})}
				</Grid>
			</Grid>
		</Box>
	);
}

KPIMetrics.propTypes = {
	kpiInfo: PropTypes.shape(),
	prevKpiInfo: PropTypes.shape()
};

KPIMetrics.defaultProps = {
	kpiInfo: {},
	prevKpiInfo: {}
};

export default KPIMetrics;
