import BarChartIcon from "@mui/icons-material/BarChart";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import HelpIcon from "@mui/icons-material/HelpOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import { getRoutePathByKey, openExternalUrl, USER_HELP } from "./routesConfig";
import { OPEN_URL_TARGET } from "./constants";
import NotificationIconWithBadge from "../components/NotificationIconWithBadge/NotificationIconWithBadge";

export const MANAGEMENT_MENU_ITEMS = [
	{
		key: "REPORTING",
		iconComponent: BarChartIcon,
		tooltip: "Reporting",
		ariaLabel: "reporting menu",
		permissionRequired: "VIEW_REPORTING_MENU",
		subItems: [
			{
				key: "DASHBOARD",
				title: "Dashboard",
				route: getRoutePathByKey("REPORTING_DASHBOARD")
			},
			{
				key: "MY_REPORTS",
				title: "My Reports",
				route: getRoutePathByKey("MY_REPORTS")
			}
		]
	},
	{
		key: "SUPPLY",
		iconComponent: LibraryBooksIcon,
		tooltip: "Supply",
		ariaLabel: "supply menu",
		permissionRequired: "VIEW_INVENTORY_MENU",
		subItems: [
			{
				key: "PUBLISHERS",
				title: "Publishers",
				route: getRoutePathByKey("PUBLISHERS"),
				permissionRequired: "VIEW_PUBLISHER_MENU"
			},
			{
				key: "SITES",
				title: "Sites",
				route: getRoutePathByKey("SITES"),
				permissionRequired: "VIEW_SITE_MENU"
			},
			{
				key: "PLACEMENTS",
				title: "Placements",
				route: getRoutePathByKey("PLACEMENTS"),
				permissionRequired: "VIEW_PLACEMENT_MENU"
			},
			{
				key: "ROUTERS",
				title: "Routers",
				route: getRoutePathByKey("ROUTERS"),
				permissionRequired: "VIEW_ROUTERS"
			}
		]
	},
	{
		key: "DEMAND",
		iconComponent: PlayCircleOutlineIcon,
		tooltip: "Demand",
		ariaLabel: "demand menu",
		permissionRequired: "VIEW_DEMAND_MENU",
		subItems: [
			{
				key: "ADVERTISERS",
				title: "Advertisers",
				route: getRoutePathByKey("ADVERTISERS"),
				permissionRequired: "VIEW_ADVERTISER_MENU"
			},
			{
				key: "INSERTIONS",
				title: "Insertions",
				route: getRoutePathByKey("INSERTION_ORDERS"),
				permissionRequired: "VIEW_INSERTION_ORDER_MENU"
			},
			{
				key: "CAMPAIGNS",
				title: "Campaigns",
				route: getRoutePathByKey("CAMPAIGNS"),
				permissionRequired: "VIEW_CAMPAIGN_MENU"
			},
			{
				key: "MEDIA",
				title: "Media",
				route: getRoutePathByKey("MEDIA"),
				permissionRequired: "VIEW_MEDIA_MENU"
			},
			{
				key: "PROGRAMMATIC",
				title: "Programmatic",
				route: getRoutePathByKey("PROGRAMMATIC_DEMAND"),
				permissionRequired: "VIEW_PROGRAMMATIC_DEMAND_MENU"
			}
		]
	},
	{
		key: "ADMIN",
		iconComponent: SettingsIcon,
		tooltip: "Admin",
		ariaLabel: "admin menu",
		permissionRequired: "VIEW_SETTINGS_MENU",
		subItems: [
			{
				key: "USERS",
				title: "Users",
				route: getRoutePathByKey("USERS"),
				permissionRequired: "VIEW_USERS_MENU"
			},
			{
				key: "ROLES",
				title: "Roles",
				route: getRoutePathByKey("ROLES"),
				permissionRequired: "VIEW_ROLES_MENU"
			},
			{
				key: "COMPANIES",
				title: "Companies",
				route: getRoutePathByKey("COMPANIES"),
				permissionRequired: "VIEW_COMPANY_MENU"
			},
			{
				key: "BIDDERS",
				title: "Bidders",
				route: getRoutePathByKey("BIDDERS"),
				permissionRequired: "VIEW_RTB_BIDDERS_MENU"
			},
			{
				key: "NOTIFICATIONS",
				title: "Notifications",
				route: getRoutePathByKey("NOTIFICATIONS"),
				permissionRequired: "VIEW_NOTIFICATION_MENU"
			},
			{
				key: "AUDIENCES",
				title: "Audiences",
				route: getRoutePathByKey("AUDIENCES"),
				permissionRequired: "VIEW_AUDIENCE_MENU"
			},
			{
				key: "DATA_DISTRIBUTIONS",
				title: "Data Distributions",
				route: getRoutePathByKey("DATA_DISTRIBUTIONS"),
				permissionRequired: "VIEW_DATA_DISTRIBUTION"
			},
			{
				key: "TRACKERS",
				title: "Trackers",
				route: getRoutePathByKey("TRACKERS"),
				permissionRequired: "VIEW_TRACKER_MENU"
			},
			{
				key: "TRAFFIC_FILTERS",
				title: "Traffic Filters",
				route: getRoutePathByKey("TRAFFIC_FILTERS"),
				permissionRequired: "VIEW_TRAFFIC_FILTERS_MENU"
			},
			{
				key: "GLOBAL_BLOCKLIST",
				title: "Global Blocklist",
				route: getRoutePathByKey("GLOBAL_BLOCKLIST"),
				permissionRequired: "VIEW_GLOBAL_BLOCKLIST_MENU"
			}
		]
	}
];

export const USER_MENU_ITEMS = [
	{
		key: "USER_SETTINGS",
		iconComponent: AccountCircleIcon,
		tooltip: "User Settings",
		ariaLabel: "user settings menu",
		route: getRoutePathByKey("USER_SETTINGS")
	},
	{
		key: "NOTIFICATIONS",
		iconComponent: NotificationIconWithBadge,
		tooltip: "Notifications",
		ariaLabel: "notifications",
		route: getRoutePathByKey("USER_NOTIFICATIONS")
	},
	{
		key: "HELP",
		iconComponent: HelpIcon,
		tooltip: "Help",
		ariaLabel: "help menu",
		onClick: () => openExternalUrl(USER_HELP, OPEN_URL_TARGET.NEW_TAB)
	}
];
