import React, {
	useState,
	useEffect,
	useMemo,
	useCallback,
	useContext
} from "react";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Menu, MenuItem, Portal } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors
} from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates
} from "@dnd-kit/sortable";
import { useField } from "formik";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import lc from "./localeContent";
import SortableDimensionChip from "./SortableDimensionChip";
import DimensionChip from "./DimensionChip";
import { CONDITIONAL_REPORTING_ITEMS as conditionalItems } from "../../screens/ScheduledReports/config";
import au from "../../utils/arrayUtils";
import cdrUtils from "./commonDimensionRestrictionUtils";
import { SLICE_CONFIG_KEY } from "../Reporting/constants";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";
import { todayInTimezone } from "../../utils/dateUtils";

const openMenuButtonId = "open-dimension-selection-menu";
const menuId = "dimension-selection-menu";
const menuMaxHeight = 36 * 11.5; // ITEM_HEIGHT * no of items to be shown

const dateDiff = dateField =>
	differenceInCalendarDays(
		new Date(dateField.value[1]),
		new Date(dateField.value[0])
	) + 1;
const dateDiffPast = (dateField, timezone) =>
	differenceInCalendarDays(
		todayInTimezone(timezone),
		new Date(dateField.value[0])
	) + 1;
// get the dimensions which are applicable to to disable on specific condition
const disableDimension = (
	availableOptions,
	selectedOptions,
	dateField,
	timezone
) => {
	const conditionalOptions = cdrUtils.conditionalItems(availableOptions);

	let toBeDisabledItems = [];
	// Get the dimension which have to be disabled based on the specific date ranges
	const dateRangeFilterItems = conditionalOptions.filter(
		item => dateDiff(dateField) > item.disabledWhenDateRange.greaterThan
	);
	const dateRangeOlderThan31Days = conditionalOptions.filter(
		item =>
			dateDiffPast(dateField, timezone) >
			item.disabledWhenDateRange.greaterThanInPast
	);
	toBeDisabledItems = [
		...toBeDisabledItems,
		...dateRangeFilterItems,
		...dateRangeOlderThan31Days
	];
	const enabledWithItems = conditionalOptions.filter(item => {
		if (item.enabledWith.length) {
			const enabledItem = !selectedOptions.some(selectedItem =>
				item.enabledWith.includes(selectedItem.id)
			);
			return enabledItem;
		}
		return null;
	});

	toBeDisabledItems = [...toBeDisabledItems, ...enabledWithItems];
	return toBeDisabledItems;
};

function DimensionsToolbar(props) {
	const [, , helpers] = useField(SLICE_CONFIG_KEY);
	const [dateField] = useField("dateRange");
	const { userTimeZone } = useContext(AuthenticatedUserSettingsContext);
	const {
		availableOptions,
		selectedOptions,
		onSelect,
		onDelete,
		onReorder,
		bulkDelete
	} = props;

	// Available dimensions menu state handlers
	const [anchorEl, setAnchorEl] = React.useState(null);
	const isOpen = Boolean(anchorEl);
	const handleOpenMenu = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// DnD config
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	);

	// TODO: Refactor this. We're splitting options into available and selected at the parent level. Instead we can pass selectedIds and all options into this component and then figure out the selected ones here. That would let us build a dictionary from the all options source
	const optionsDictionary = useMemo(
		() => ({
			...availableOptions.reduce(
				(agg, option) => ({
					...agg,
					[option.id]: option
				}),
				{}
			),
			...selectedOptions.reduce(
				(agg, option) => ({
					...agg,
					[option.id]: option
				}),
				{}
			)
		}),
		[selectedOptions, availableOptions]
	);

	// Maintain a separate array of visible items that we don't need to update formik until drag ends
	const [sortableIds, setSortableIds] = useState([]);
	useEffect(() => {
		setSortableIds(selectedOptions.map(option => option.id));
	}, [selectedOptions]);

	const [activeItemSortableId, setActiveItemSortableId] = useState(null);
	const activeItem = optionsDictionary[activeItemSortableId] || {};

	const handleDragStart = event => {
		const { active } = event;
		setActiveItemSortableId(active.id);
	};
	const handleDragOver = event => {
		const { active, over } = event;
		if (over && active.id !== over.id) {
			const oldIndex = sortableIds.indexOf(active.id);
			const newIndex = sortableIds.indexOf(over.id);
			setSortableIds(arrayMove(sortableIds, oldIndex, newIndex));
		}
	};

	const endDrag = () => {
		onReorder(sortableIds);
	};
	const selectedIds = sortableIds;

	// This Method is getting called whne user is dragging the items.
	const updateItemsOrder = useCallback(
		(activeId, dragOverId) =>
			cdrUtils.dimensionOrderUpdate(activeId, dragOverId, sortableIds),
		[sortableIds]
	);
	const collisionDetectionStrategy = useCallback(
		args =>
			cdrUtils.collisionDetection(
				args,
				sortableIds,
				activeItemSortableId,
				updateItemsOrder
			),
		[activeItemSortableId, updateItemsOrder, sortableIds]
	);

	// Updating the Dimension Index
	const updateItemIndex = useCallback(
		(item, toIndex, itemsList) => {
			// If there is no conditional item
			if (!itemsList.length || toIndex === null) {
				setSortableIds(itemsList);
			} else {
				const fromIndex = itemsList.indexOf(item); // 👉️ 0
				const element = itemsList.splice(fromIndex, 1)[0];
				itemsList.splice(toIndex, 0, element);
				setSortableIds(itemsList);
			}
			helpers.setValue(itemsList);
		},
		[setSortableIds, helpers]
	);

	// This method is getting called when a user is selecting the dimenstions from the plus button click.
	const updateItemsOnAdd = useCallback(
		(itemsList, item) => {
			const items = [...itemsList, item];
			let toIndex = null;
			// If a dimension can be placed before and/or after a specific dimension.
			if (
				conditionalItems[item]?.mustBeBefore ||
				conditionalItems[item]?.mustBeAfter
			) {
				const mustBeBeforeItems = cdrUtils.getMustBeBeforeItems(item, items);
				const mustBeAfterItems = cdrUtils.getMustBeAfterItems(item, items);
				if (mustBeAfterItems.length) {
					toIndex = items.indexOf(au.getFirstItem(mustBeAfterItems));
				}
				if (mustBeBeforeItems.length) {
					toIndex = items.indexOf(au.getLastItem(mustBeBeforeItems)) + 1;
				}
			}
			updateItemIndex(item, toIndex, items);
		},
		[updateItemIndex]
	);

	// Add item to the end of the selected items list
	const onAddSelectedItem = useCallback(
		itemId => {
			onSelect(itemId);
			updateItemsOnAdd(selectedIds, itemId);
		},
		[updateItemsOnAdd, selectedIds, onSelect]
	);

	const removeDependentIncompatibleItems = useCallback(
		() => cdrUtils.deleteDependentIncompatibleItems(selectedIds, bulkDelete),
		[selectedIds, bulkDelete]
	);

	useEffect(() => {
		const greaterThanDimension = Object.entries(conditionalItems).filter(
			([, value]) =>
				dateDiff(dateField) > value.disabledWhenDateRange?.greaterThan
		);
		const past31DaysDimension = Object.entries(conditionalItems).filter(
			([, value]) =>
				dateDiffPast(dateField, userTimeZone) >
				value.disabledWhenDateRange?.greaterThanInPast
		);
		const conditionalDim = [...greaterThanDimension, ...past31DaysDimension];
		let toBeDeleteDimensions = sortableIds.filter(item =>
			conditionalDim.some(conditionalItem => conditionalItem[0] === item)
		);

		bulkDelete(toBeDeleteDimensions);
		toBeDeleteDimensions = [];
		removeDependentIncompatibleItems();
	}, [
		dateField,
		sortableIds,
		bulkDelete,
		removeDependentIncompatibleItems,
		userTimeZone
	]);

	return (
		<Box
			sx={{
				p: 1
			}}
		>
			<Button
				id={openMenuButtonId}
				disabled={isEmpty(availableOptions)}
				aria-controls={isOpen ? menuId : undefined}
				aria-haspopup="true"
				aria-expanded={isOpen}
				onClick={handleOpenMenu}
				startIcon={<AddIcon />}
				size="small"
			>
				{lc.ADD_DIMENSION_BUTTON_LABEL}
			</Button>
			<Menu
				id={menuId}
				anchorEl={anchorEl}
				open={isOpen}
				onClose={handleClose}
				MenuListProps={{ "aria-labelledby": openMenuButtonId }}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				PaperProps={{
					style: {
						maxHeight: menuMaxHeight
					}
				}}
			>
				{availableOptions.map(option => (
					<MenuItem
						key={option.id}
						onClick={() => onAddSelectedItem(option.id)}
						disabled={disableDimension(
							availableOptions,
							selectedOptions,
							dateField,
							userTimeZone
						).some(item => item.id === option.id)}
					>
						<AddIcon
							sx={theme => ({
								color: theme.palette.primary.light,
								marginRight: "0.3em"
							})}
						/>
						{option.label}
					</MenuItem>
				))}
			</Menu>
			<DndContext
				sensors={sensors}
				onDragStart={handleDragStart}
				onDragOver={handleDragOver}
				onDragEnd={endDrag}
				onDragCancel={endDrag}
				autoScroll={false}
				collisionDetection={collisionDetectionStrategy}
			>
				<SortableContext items={sortableIds} strategy={() => {}}>
					{/* Wrap sortable items in an unordered list for accessibility purposes */}
					<ul
						aria-label={lc.DIMENSIONS_LABEL}
						style={{
							margin: 0,
							padding: 0,
							display: "inline-block",
							listStyleType: "none"
						}}
					>
						{sortableIds.map(sortableId => {
							const option = optionsDictionary[sortableId];
							return (
								<SortableDimensionChip
									key={option.id}
									sortableId={sortableId}
									label={option.label}
									onDelete={() => {
										onDelete(option.id);
									}}
								/>
							);
						})}
					</ul>
				</SortableContext>
				<Portal container={document.body}>
					<DragOverlay>
						{activeItemSortableId && (
							<DimensionChip label={activeItem.label} onDelete={() => {}} />
						)}
					</DragOverlay>
				</Portal>
			</DndContext>
		</Box>
	);
}

DimensionsToolbar.propTypes = {
	availableOptions: PropTypes.arrayOf(PropTypes.shape({})),
	selectedOptions: PropTypes.arrayOf(PropTypes.shape({})),
	onSelect: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onReorder: PropTypes.func.isRequired,
	bulkDelete: PropTypes.func
};

DimensionsToolbar.defaultProps = {
	availableOptions: [],
	selectedOptions: [],
	bulkDelete: () => []
};

export default DimensionsToolbar;
