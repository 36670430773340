export default {
	FILE_UPLOAD_BUTTON_LABEL: "Upload",
	REMOVE_ALL_LABEL: "REMOVE ALL",
	FILE_EXTENSION_ERROR_MESSAGE: "File must contain a .csv extension",
	INVALID_ERROR_MESSAGE: ({ targetingTypeLabel }) =>
		`Must be a valid ${targetingTypeLabel}`,
	DUPPLICATED_ERROR_MESSAGE: ({ targetingTypeLabel }) =>
		`${targetingTypeLabel} already in list`,
	FIELD_VALUE_INPUT_LABEL: ({ targetingTypeLabel }) =>
		`Enter ${targetingTypeLabel}`,
	REMOVE_VALUE_BUTTON_LABEL: ({ index }) => `remove value ${index + 1}`,
	INHERITED_TEXT: (targetingTypeLabel, count) =>
		`There are ${count} inherited ${targetingTypeLabel}s`,
	INHERITED_ERROR_MESSAGE: "Must be part of inherited values",
	ADD_BUTTON_LABEL: "Add value"
};
