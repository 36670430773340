import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import MenuOptionLink from "./MenuOptionLink";
import MenuOptionExternalLink from "./MenuOptionExternalLink";
import MenuOptionClickHandler from "./MenuOptionClickHandler";
import MenuOptionDeleteResource from "./MenuOptionDeleteResource";
import MenuOptionShareResource from "./MenuOptionShareResource";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import lc from "./localeContent";
import MenuConfirmPostResource from "./MenuOptionConfirmPostResource";

// Returns true if no permissionRequired flag, else check if user's permission
const hasPermission = (option, authorizationContext) =>
	option.permissionRequired
		? option.permissionRequired &&
		  authorizationContext.hasPermission(option.permissionRequired)
		: true;

export const ACTION_MENU_ITEM_TYPES = {
	link: "link",
	externalLink: "externalLink",
	clickHandler: "clickHandler",
	deleteResource: "deleteResource",
	shareResource: "shareResource",
	confirmPostResource: "confirmPostResource"
};

function GenericTableRowActionMenu(props) {
	const { rowData, menuOptions } = props;
	const { id: rowItemId, name: rowItemName } = rowData;
	const [anchorEl, setAnchorEl] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const authorizationContext = useContext(AuthorizationContext);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const closeActionMenu = () => {
		setAnchorEl(null);
	};

	const menuId = `table-actions-${rowItemId || rowItemName}`;

	return (
		<>
			<IconButton
				aria-label={lc.ACTIONS_BUTTON_LABEL}
				aria-controls={menuId}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>

			<Menu
				id={menuId}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closeActionMenu}
			>
				{menuOptions.map(option => {
					const { type, key } = option;

					if (!hasPermission(option, authorizationContext)) {
						return null;
					}

					switch (type) {
						case ACTION_MENU_ITEM_TYPES.link: {
							const { to, label } = option;
							return (
								<MenuOptionLink
									key={key}
									to={to}
									label={label}
									onClick={closeActionMenu}
								/>
							);
						}
						case ACTION_MENU_ITEM_TYPES.externalLink: {
							const {
								href,
								getHref,
								label,
								accessibilityLabel,
								target,
								download
							} = option;
							return (
								<MenuOptionExternalLink
									key={key}
									href={getHref ? getHref(rowData) : href}
									label={label}
									accessibilityLabel={accessibilityLabel}
									target={target}
									download={download}
									onClick={closeActionMenu}
								/>
							);
						}
						case ACTION_MENU_ITEM_TYPES.clickHandler: {
							const { label, onClick } = option;
							return (
								<MenuOptionClickHandler
									key={key}
									label={label}
									onClick={event => {
										closeActionMenu();
										onClick({ event, rowData });
									}}
								/>
							);
						}
						case ACTION_MENU_ITEM_TYPES.deleteResource: {
							const {
								label,
								title,
								description,
								confirmLabel,
								resourceEndpoint,
								deleteItemCallback,
								successMessage,
								customStartPathEndPoint
							} = option;
							return (
								<MenuOptionDeleteResource
									key={key}
									label={label}
									onClick={closeActionMenu}
									resourceEndpoint={resourceEndpoint}
									rowItemId={rowItemId}
									deleteItemCallback={deleteItemCallback}
									title={title}
									description={description}
									confirmLabel={confirmLabel}
									successMessage={successMessage}
									setIsLoading={setIsLoading}
									customStartPathEndPoint={customStartPathEndPoint}
								/>
							);
						}
						case ACTION_MENU_ITEM_TYPES.shareResource: {
							const {
								label,
								title,
								description,
								confirmLabel,
								resourceEndpoint
							} = option;
							return (
								<MenuOptionShareResource
									key={key}
									label={label}
									onClick={closeActionMenu}
									resourceEndpoint={resourceEndpoint}
									title={title}
									description={description}
									confirmLabel={confirmLabel}
									setIsLoading={setIsLoading}
								/>
							);
						}
						case ACTION_MENU_ITEM_TYPES.confirmPostResource: {
							const {
								label,
								title,
								description,
								confirmLabel,
								resourceEndpoint,
								getResourceEndpoint,
								successMessage
							} = option;

							const confirmEndPoint = getResourceEndpoint
								? getResourceEndpoint(rowItemId)
								: resourceEndpoint;

							return (
								<MenuConfirmPostResource
									key={key}
									rowItemId={rowItemId}
									label={label}
									onClick={closeActionMenu}
									resourceEndpoint={confirmEndPoint}
									title={title}
									description={description}
									confirmLabel={confirmLabel}
									setIsLoading={setIsLoading}
									successMessage={successMessage}
								/>
							);
						}
						default:
							return null;
					}
				})}
			</Menu>
			<LoadingBackdrop isOpen={isLoading} testId="action-menu-loading" />
		</>
	);
}

GenericTableRowActionMenu.propTypes = {
	rowData: PropTypes.shape().isRequired,
	menuOptions: PropTypes.arrayOf(PropTypes.shape())
};

GenericTableRowActionMenu.defaultProps = {
	menuOptions: null
};

export default GenericTableRowActionMenu;
