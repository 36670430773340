import { useEffect } from "react";

function useErrorDeserializer(error, helpers) {
	useEffect(() => {
		if (!error || typeof error !== "string") return;
		const transformedError = error.replace(/&quot;/g, '"');
		helpers.setError(JSON.parse(transformedError));
	}, [error, helpers]);
}

export default useErrorDeserializer;
