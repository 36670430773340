import React, { useContext } from "react";
import PropTypes from "prop-types";

import FormFieldsGroup from "../FormFieldsGroup/FormFieldsGroup";
import FormField from "./FormField";
import { getFieldTypeOrDefault } from "./FieldTypes";
import { PARENT_ID_PARAM_KEY } from "./constants";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import useQueryParam from "../../hooks/useQueryParam";

// Used to get dynamic query params for a field's options request
const getFieldOptionsConfig = (optionsConfig, formValues) => {
	if (!optionsConfig) {
		return null;
	}

	const { getRequestParams, ...fieldOptionsConfig } = optionsConfig;
	if (getRequestParams) {
		fieldOptionsConfig.requestParams = getRequestParams(formValues);
	}
	if (fieldOptionsConfig.options) {
		fieldOptionsConfig.options.forEach(option => {
			const disabled = option.disableIf && option.disableIf(formValues);
			option.disabled = disabled;
		});
	}
	return fieldOptionsConfig;
};

// Returns true if no hideOnCreate flag, else checks if we're in the create resource flow
const passesHideOnCreateCheck = (config, formContext) =>
	!config.hideOnCreate ||
	(config.hideOnCreate && !formContext.isCreateResource);

const passesHideOnEditCheck = (config, formContext) =>
	formContext.isCreateResource || !config.hideOnEdit;

// Returns true if no renderIf flag, else calls field's renderIf, passing formValues
const passesRenderIfCheck = (
	config,
	formValues,
	initData,
	resourceId,
	isClonedResource
) =>
	!config.renderIf ||
	config.renderIf(formValues, initData || {}, resourceId, isClonedResource);

// Returns false if no permissionRequired flag, else check if user's permission
const hasPermission = (config, authorizationContext) =>
	config.permissionRequired
		? config.permissionRequired &&
		  authorizationContext.hasPermission(config.permissionRequired)
		: true;

// Configure the name of the query param that should get updated by a field
const getReplaceQueryParamName = (formContext, fieldConfig) => {
	if (formContext.parentIdField === fieldConfig.name) {
		return PARENT_ID_PARAM_KEY;
	}
	return fieldConfig.replaceQueryParam ? fieldConfig.name : null;
};

/**
 * Used to render a "panel" of form fields. Each form tab has its own panel (or we use a single panel if it's a tabless)
 * @param {Object} props defined in propTypes
 */
function FormFieldsPanel(props) {
	const { fieldsConfig, formValues, formContext, initData } = props;
	const authorizationContext = useContext(AuthorizationContext);
	const isClonedResource = useQueryParam("clone");

	return (
		<>
			{fieldsConfig.map(
				fieldGroupConfig =>
					passesHideOnCreateCheck(fieldGroupConfig, formContext) &&
					passesHideOnEditCheck(fieldGroupConfig, formContext) &&
					passesRenderIfCheck(
						fieldGroupConfig,
						formValues,
						initData,
						formContext,
						isClonedResource
					) &&
					hasPermission(fieldGroupConfig, authorizationContext) && (
						<FormFieldsGroup
							key={fieldGroupConfig.key}
							fieldGroupKey={fieldGroupConfig.key}
						>
							{fieldGroupConfig.fields.map(fieldConfig => {
								if (
									!passesHideOnCreateCheck(fieldConfig, formContext) ||
									!passesHideOnEditCheck(fieldConfig, formContext) ||
									!passesRenderIfCheck(
										fieldConfig,
										formValues,
										initData,
										formContext,
										isClonedResource
									) ||
									!hasPermission(fieldConfig, authorizationContext)
								) {
									return null;
								}
								const { component, overrideTooltip } = getFieldTypeOrDefault(
									fieldConfig
								);
								const FieldComponent = component || fieldConfig.component;
								const gridConfig = fieldConfig.getGridConfig
									? fieldConfig.getGridConfig(formValues, initData)
									: fieldConfig.gridConfig;
								return (
									<FormField
										key={fieldConfig.name}
										gridConfig={gridConfig}
										tooltip={fieldConfig.tooltip}
										overrideTooltip={overrideTooltip}
									>
										<FieldComponent
											id={fieldConfig.name}
											name={fieldConfig.name}
											label={fieldConfig.label}
											fieldTypeOptions={
												fieldConfig.getFieldTypeOptions
													? fieldConfig.getFieldTypeOptions({ initData })
													: fieldConfig.fieldTypeOptions
											}
											inheritedValues={
												fieldConfig.useInheritedValues
													? initData?.inheritedValues
													: undefined
											}
											optionsConfig={getFieldOptionsConfig(
												fieldConfig.optionsConfig,
												formValues
											)}
											formValues={formValues}
											// TODO: CP-926 refactor conditional rendering of indicator and yup validation
											required={
												fieldConfig.isRequired ||
												fieldConfig.isRequiredIfRendered
											}
											readOnly={
												fieldConfig.readOnly ||
												(fieldConfig.readOnlyIf &&
													fieldConfig.readOnlyIf(formValues, initData))
											}
											disabled={
												fieldConfig.disabled ||
												(fieldConfig.disableOnEdit &&
													!formContext.isCreateResource) ||
												(fieldConfig.disableIf &&
													fieldConfig.disableIf(formValues, initData))
											}
											replaceQueryParamName={getReplaceQueryParamName(
												formContext,
												fieldConfig
											)}
											tooltip={overrideTooltip ? fieldConfig.tooltip : null}
											isCreateResource={formContext.isCreateResource}
											crudFormResourceId={formContext.resourceId}
											userTimeZone={formContext.userTimeZone}
										/>
									</FormField>
								);
							})}
						</FormFieldsGroup>
					)
			)}
		</>
	);
}
FormFieldsPanel.propTypes = {
	fieldsConfig: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	formValues: PropTypes.shape().isRequired,
	formContext: PropTypes.shape().isRequired,
	initData: PropTypes.shape()
};
FormFieldsPanel.defaultProps = {
	initData: null
};

export default FormFieldsPanel;
