import React, { useState, useRef, useContext } from "react";
import { Box, FormHelperText, Typography } from "@mui/material";

import PropTypes from "prop-types";

import useFileUpload from "../../hooks/useFileUpload";
import { SnackbarContext } from "../../context/SnackbarContext";
import localeContent from "./localeContent";
import { GENERIC_API_ERROR } from "../../hooks/useResource";
import FileUploadButton from "../FileUploadButton/FileUploadButton";
import { DASHBOARD_API_URL } from "../../config/ApiUrlConfig";

const getImagePreviewSrc = (ImagePath, crudFormResourceId) =>
	`${DASHBOARD_API_URL}/${ImagePath}/${crudFormResourceId}`;

function FormFieldImageUploadPreview(props) {
	const {
		name,
		crudFormResourceId,
		fieldTypeOptions: {
			acceptedTypes,
			buttonLabel,
			previewLabel,
			ImagePath,
			ImageHeight,
			ImageWidth
		}
	} = props;
	const imageRef = useRef(null);
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const [fileExtensionError, setFileExtensionError] = useState("");

	const { uploadFileAsync } = useFileUpload(
		`${ImagePath}/${crudFormResourceId}`
	);

	const handleUploadImage = async file => {
		if (file) {
			const extension = file.name
				.split(".")
				.pop()
				.toLowerCase();

			if (acceptedTypes.some(type => type === extension)) {
				setFileExtensionError("");
				const submissionValues = { file, id: crudFormResourceId };

				const { error } = await uploadFileAsync(submissionValues);
				imageRef.current.src = getImagePreviewSrc(
					ImagePath,
					crudFormResourceId
				);
				if (error) {
					triggerNewSnackbarMessage({
						message: error.message || GENERIC_API_ERROR,
						severity: "error"
					});
				} else {
					triggerNewSnackbarMessage({
						message: `Uploaded ${file.name}`,
						severity: "success"
					});
				}
			} else {
				setFileExtensionError(localeContent.FILE_EXTENSION_ERROR_MESSAGE);
			}
		}
	};

	return (
		<Box sx={{ boxShadow: 1, p: 1 }}>
			<FileUploadButton
				name={name}
				label={buttonLabel}
				acceptedFileTypes={acceptedTypes.map(type => `.${type}`)}
				onFileUpload={handleUploadImage}
				fullWidth
			/>
			{fileExtensionError && (
				<FormHelperText error>{fileExtensionError}</FormHelperText>
			)}
			<FormHelperText sx={{ mb: 2 }}>
				{`${
					localeContent.ACCEPTED_FILE_TYPES_LABEL
				}: ${acceptedTypes.map(type => type.toUpperCase()).join(", ")}`}
			</FormHelperText>
			<Typography>{previewLabel}</Typography>
			<Box
				sx={theme => ({
					width: "100%",
					height: theme.spacing(25),
					mt: 1,
					borderRadius: 1,
					backgroundColor: "lightgrey"
				})}
			>
				<img
					ref={imageRef}
					src={getImagePreviewSrc(ImagePath, crudFormResourceId)}
					alt=""
					height={ImageHeight ?? "200"}
					width={ImageWidth ?? "100%"}
				/>
			</Box>
		</Box>
	);
}

FormFieldImageUploadPreview.propTypes = {
	name: PropTypes.string.isRequired,
	crudFormResourceId: PropTypes.string,
	fieldTypeOptions: PropTypes.shape({
		acceptedTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
		buttonLabel: PropTypes.string.isRequired,
		previewLabel: PropTypes.string.isRequired,
		ImagePath: PropTypes.string.isRequired,
		ImageHeight: PropTypes.string,
		ImageWidth: PropTypes.string
	}).isRequired
};

FormFieldImageUploadPreview.defaultProps = {
	crudFormResourceId: null
};

export default FormFieldImageUploadPreview;
