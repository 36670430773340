import React, { useContext, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { format, sub } from "date-fns";
import IconClose from "@mui/icons-material/Close";
import IconDateRange from "@mui/icons-material/DateRange";
import {
	Chip,
	Popover,
	Paper,
	Toolbar,
	Typography,
	IconButton,
	Box
} from "@mui/material";
import { useField } from "formik";
import { visuallyHidden } from "@mui/utils";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormFieldDateRangePicker from "../FormFieldDateRangePicker/FormFieldDateRangePicker";
import {
	todayInTimezone,
	DATE_RANGE,
	getDateRangeByTimezone
} from "../../utils/dateUtils";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";
import lc from "./localeContent";
import { DATE_RANGE_KEY } from "./constants";

const dateFormat = "MM/dd/yy";

const dateRangeConfigs = {
	[DATE_RANGE.today]: lc.TODAY,
	[DATE_RANGE.yesterday]: lc.YESTERDAY,
	[DATE_RANGE.previous7]: lc.PREV_7_DAYS,
	[DATE_RANGE.previous30]: lc.PREV_30_DAYS,
	[DATE_RANGE.monthToDate]: lc.MONTH_TO_DATE,
	[DATE_RANGE.previousMonth]: lc.PREVIOUS_MONTH
};

function TabPanel(props) {
	const { children, value, index } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}
TabPanel.propTypes = {
	children: PropTypes.node.isRequired,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

function DateRangeCards(props) {
	const { onDateSelection } = props;
	const [, , helpers] = useField(DATE_RANGE_KEY);

	const { userTimeZone } = useContext(AuthenticatedUserSettingsContext);

	const updateDates = useCallback(
		(val, dateKey) => {
			onDateSelection(dateKey);
			if (helpers.setValue) helpers.setValue([val.startDate, val.endDate]);
		},
		[helpers, onDateSelection]
	);
	return (
		<>
			{Object.keys(dateRangeConfigs).map(dateKey => (
				<Grid item xs={6} key={`date-${dateKey}`}>
					<Button
						variant="outlined"
						sx={{ width: "100%" }}
						onClick={() =>
							updateDates(
								getDateRangeByTimezone(dateKey, userTimeZone),
								dateKey
							)
						}
					>
						{dateRangeConfigs[dateKey]}
					</Button>
				</Grid>
			))}
		</>
	);
}
DateRangeCards.propTypes = {
	onDateSelection: PropTypes.func.isRequired
};

const getFilterChipLabel = (startDate, endDate) => {
	if (!startDate) {
		return lc.DATE_RANGE.CHIP_PLACEHOLDER;
	}
	return (
		<>
			<Box sx={visuallyHidden}>{lc.DATE_RANGE.CHIP_PLACEHOLDER}</Box>
			{`${format(startDate, dateFormat)} - ${
				endDate ? format(endDate, dateFormat) : ""
			}`}
		</>
	);
};

function DateRangeFilter(props) {
	const [field] = useField(DATE_RANGE_KEY);
	const [startDate, endDate] = field.value;
	const { onDateSelection } = props;
	const [anchorEl, setAnchorEl] = useState(null);

	const openFilterMenu = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		onDateSelection(null);
	};

	const isFilterMenuOpen = Boolean(anchorEl);

	const { userTimeZone } = useContext(AuthenticatedUserSettingsContext);
	const todayInUserTimeZone = todayInTimezone(userTimeZone);
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const onDateSelectionHandler = newValue => {
		setAnchorEl(null);
		onDateSelection(newValue);
	};

	return (
		<>
			<Chip
				icon={<IconDateRange />}
				label={getFilterChipLabel(startDate, endDate)}
				onClick={openFilterMenu}
			/>
			<Popover
				open={isFilterMenuOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left"
				}}
				sx={{ width: "770px" }}
			>
				<IconButton
					onClick={handleClose}
					aria-label={lc.BUTTON_LABEL_CLOSE_FILTER_MENU}
					sx={{ float: "right" }}
				>
					<IconClose />
				</IconButton>
				<Tabs
					value={value}
					onChange={handleChange}
					centered
					sx={{ marginLeft: "7%" }}
				>
					<Tab label={lc.RELATIVE_TAB} />
					<Tab label={lc.CUSTOM_TAB} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 2 }}
					>
						<DateRangeCards
							onDateSelection={val => onDateSelectionHandler(val)}
						/>
					</Grid>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Paper sx={{ flexGrow: 1, width: 626, overflow: "auto" }}>
						<Toolbar
							variant="dense"
							sx={{ pl: 3, pr: 1 }}
							// Toolbar horizontal padding does not appear to be overridable with sx unless we disableGutters
							disableGutters
						>
							<Typography sx={{ flex: "1 1 100%" }} component="div">
								{lc.DATE_RANGE.POPOVER_TITLE}
							</Typography>
						</Toolbar>
						<FormFieldDateRangePicker
							name={DATE_RANGE_KEY}
							minDate={sub(todayInUserTimeZone, { days: 396 })}
							maxDate={todayInUserTimeZone}
							maxDateRange={31}
							persistentCalendar
							onAccept={handleClose}
						/>
					</Paper>
				</TabPanel>
			</Popover>
		</>
	);
}

DateRangeFilter.propTypes = {
	onDateSelection: PropTypes.func.isRequired
};
DateRangeFilter.defaultProps = {};

export default DateRangeFilter;
