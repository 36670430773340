import React from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";
import localeContent from "../../screens/Company/localeContent";
import FormFieldComboBox from "../FormFieldComboBox/FormFieldComboBox";
import commonLocaleContent from "../../screens/commonLocaleContent";

const CONFIG_KEY = "config";
const PRIMARY_COLOR_KEY = "primaryColor";

export const transformSubmitData = (data, fieldName, resourceId) => {
	return {
		[fieldName]: {
			applicationType: "MUI_REDESIGN_DASHBOARD",
			companyId: resourceId === "INIT" ? null : resourceId,
			config: {
				primaryColor: !data[CONFIG_KEY]
					? data
					: data[CONFIG_KEY][PRIMARY_COLOR_KEY]
			}
		}
	};
};

export const transformInitData = (initData, fieldName) => {
	const fieldData = initData[fieldName] || {};
	// We need to provide formik with all nested keys defined in order to make touched status work properly
	const isPrimaryColorDefined = Boolean(fieldData.config?.primaryColor);
	return isPrimaryColorDefined
		? fieldData
		: {
				[CONFIG_KEY]: {
					[PRIMARY_COLOR_KEY]: null
				}
		  };
};

export const ValidationSchema = Yup.object({
	[CONFIG_KEY]: Yup.object({
		[PRIMARY_COLOR_KEY]: Yup.string()
			.nullable()
			.required(commonLocaleContent.REQUIRED_FIELD_WARNING)
	})
});

function FormFieldPrimaryColor(props) {
	const { id, label, name, required, optionsConfig } = props;
	return (
		<div>
			<FormFieldComboBox
				label={label}
				name={`${name}.${CONFIG_KEY}.${PRIMARY_COLOR_KEY}`}
				id={id}
				optionsConfig={optionsConfig}
				required={required}
			/>
			<FormHelperText>
				{localeContent.PRIMARY_COLOR.HELPER_TEXT}&nbsp;
				<a
					href="https://material-ui.com/customization/color/#color-palette"
					target="_blank"
					rel="noreferrer"
				>
					{localeContent.PRIMARY_COLOR.LINK_TEXT}
				</a>
			</FormHelperText>
		</div>
	);
}

FormFieldPrimaryColor.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	optionsConfig: PropTypes.shape()
};

FormFieldPrimaryColor.defaultProps = {
	required: false,
	optionsConfig: {}
};

export default FormFieldPrimaryColor;
