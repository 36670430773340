import React, { useContext } from "react";
import CrudForm from "../../components/CrudForm/CrudForm";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import localeContent from "./localeContent";
import { routersEndpoint } from "../../utils/endpointUtils";
import { FieldTypeDemandSource } from "./FormFieldDemandSource";
import { FieldTypePlacementTag } from "../../components/PlacementTag/PlacementTag";
import { AuthContext } from "../../context/AuthContext";
import { ROUTER_API_URL } from "../../config/ApiUrlConfig";

export const ROUTERS_TYPE_OPTIONS_ENUM = [
	{ value: "APP", label: "App", placementType: "mobile" },
	{ value: "WEB", label: "Web", placementType: "vast" }
];

const ROUTERS_TYPE_OPTIONS = ROUTERS_TYPE_OPTIONS_ENUM.map(value => {
	return value;
});

const routerPathVast = `${ROUTER_API_URL}/vast`;

const routerTypeSelected = routerType => {
	return (
		ROUTERS_TYPE_OPTIONS_ENUM.find(element => element.value === routerType)
			?.placementType || null
	);
};

const FIELDS_CONFIG = [
	{
		key: "ROUTER",
		fields: [
			{
				name: "guid",
				label: "Router",
				fieldType: FieldTypePlacementTag,
				hideOnCreate: true,
				fieldTypeOptions: {
					pathService: routerPathVast,
					getPlacementType: formValues => routerTypeSelected(formValues.type),
					getGuid: formValues => formValues.guid,
					getDescription: formValues => formValues.name,
					labelTag: "Router"
				}
			},
			{
				name: "name",
				label: localeContent.ROUTERS_NAME_LABEL,
				gridConfig: { md: 6 },
				isRequired: true
			},
			{
				name: "status",
				label: localeContent.STATUS_LABEL,
				gridConfig: { md: 4 },
				fieldType: FieldTypes.RADIO,
				optionsConfig: {
					options: [
						{
							value: "active",
							label: localeContent.STATUS_OPTION_LABEL_ACTIVE
						},
						{
							value: "inactive",
							label: localeContent.STATUS_OPTION_LABEL_INACTIVE
						}
					]
				},
				defaultValue: "active"
			},
			{
				name: "type",
				label: localeContent.ROUTER_TYPE_LABEL,
				gridConfig: { md: 6 },
				disableOnEdit: true,
				fieldType: FieldTypes.SELECT,
				optionsConfig: {
					options: ROUTERS_TYPE_OPTIONS
				},
				default: "APP",
				tooltip: localeContent.ROUTER_TYPE_TOOLTIP
			},
			{
				name: "demandSources",
				label: localeContent.DemandSources,
				gridConfig: { xs: 12 },
				fieldType: FieldTypeDemandSource
			}
		]
	}
];

function RoutersResource() {
	const authContext = useContext(AuthContext);
	const { companyId } = authContext;
	const routersResourceEndPoint = `model/company/${companyId}/routers`;

	return (
		<CrudForm
			resourceEndpoint={routersResourceEndPoint}
			resourceRoute="routers"
			resourceString={localeContent.ROUTER}
			customStartPathEndPoint={routersEndpoint}
			fieldsConfig={FIELDS_CONFIG}
			prepopulateCreate
		/>
	);
}

export default RoutersResource;
