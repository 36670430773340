import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

function GridOffset(props) {
	const { offsetConfig } = props;
	const { xs, sm, md, lg, xl, fullRowClear } = offsetConfig;

	return (
		offsetConfig &&
		(fullRowClear ? (
			<Grid
				item
				xs={12}
				/*
									Using important because:
										* the Mui style in this case is nested and has greater specificity
										* it's being used on an element designed to be "invisible" (only impacting grid Items that follow it)
								*/
				sx={{ p: "0 !important" }}
			/>
		) : (
			<Grid
				item
				sx={{
					display: {
						xs: xs ? "block" : "none",
						sm: sm ? "block" : "none",
						md: md ? "block" : "none",
						lg: lg ? "block" : "none",
						xl: xl ? "block" : "none"
					}
				}}
				xs={xs}
				sm={sm}
				md={md}
				lg={lg}
				xl={xl}
			/>
		))
	);
}

GridOffset.propTypes = {
	offsetConfig: PropTypes.shape()
};

GridOffset.defaultProps = {
	offsetConfig: null
};

export default GridOffset;
