import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	useMemo
} from "react";
import PropTypes from "prop-types";
import { Grid, List, Button, FormHelperText } from "@mui/material";
import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
	rectIntersection,
	closestCenter,
	pointerWithin,
	getFirstCollision
} from "@dnd-kit/core";
import {
	SortableContext,
	sortableKeyboardCoordinates,
	arrayMove
} from "@dnd-kit/sortable";
import Portal from "@mui/material/Portal";
import IconClose from "@mui/icons-material/Close";
import IconAdd from "@mui/icons-material/Add";

import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import DndListItem from "./DnDListItem";
import DropabbleContainer from "./DroppableContainer";
import lc from "./localeContent";
import CONDITIONAL_SCHEDULED_REPORTS_ITEMS from "../../screens/ScheduledReports/config";
import au from "../../utils/arrayUtils";

const CONTAINER_AVAILABLE = "available";
const CONTAINER_SELECTED = "selected";

const getMustBeBeforeItems = (activeId, items = []) => {
	const mustBeBeforeItems = [];
	items.forEach(item => {
		if (
			CONDITIONAL_SCHEDULED_REPORTS_ITEMS[activeId]?.mustBeBefore?.includes(
				item
			)
		) {
			mustBeBeforeItems.push(item);
		}
	});
	return mustBeBeforeItems;
};
const getMustBeAfterItems = (activeId, items = []) => {
	const mustBeAfterItems = [];
	items.forEach(item => {
		if (
			CONDITIONAL_SCHEDULED_REPORTS_ITEMS[activeId]?.mustBeAfter?.includes(item)
		) {
			mustBeAfterItems.push(item);
		}
	});
	return mustBeAfterItems;
};
function DragAndDropSelector(props) {
	const {
		options,
		setSelectedIds,
		selectedIds,
		selectedItemsError,
		disabledItems
	} = props;
	/**
	 * Drag and Drop
	 */
	const sortableItems = useMemo(() => {
		return {
			[CONTAINER_SELECTED]: selectedIds,
			[CONTAINER_AVAILABLE]: (options || [])
				.filter(({ id }) => !selectedIds.includes(id))
				.map(({ id }) => id)
		};
	}, [selectedIds, options]);

	const [sortableContainers] = useState([Object.keys(sortableItems)]);

	const findContainer = id => {
		if (id in sortableItems) {
			return id;
		}

		return Object.keys(sortableItems).find(key =>
			sortableItems[key].includes(id)
		);
	};

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(TouchSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	);

	const [activeItemSortableId, setActiveItemSortableId] = useState(null);
	const lastOverId = useRef(null);
	const recentlyMovedToNewContainer = useRef(false);
	const activeItem = (options || []).find(
		({ id }) => id === activeItemSortableId
	);
	const [clonedItems, setClonedItems] = useState(null);

	// Updating the Dimension Index
	const updateItemIndex = useCallback(
		(item, toIndex, itemsList) => {
			// If there is no conditional item
			if (!itemsList.length || toIndex === null) {
				setSelectedIds(itemsList);
			} else {
				const fromIndex = itemsList.indexOf(item); // 👉️ 0
				const element = itemsList.splice(fromIndex, 1)[0];
				itemsList.splice(toIndex, 0, element);
				setSelectedIds(itemsList);
			}
		},
		[setSelectedIds]
	);
	// This method is getting called when a user is selecting the dimensions from the plus button click.
	const updateItemsOnAdd = useCallback(
		(itemsList, item) => {
			const items = [...itemsList, item];
			let toIndex = null;
			// If a dimension can be placed before and/or after a specific dimension.
			if (
				CONDITIONAL_SCHEDULED_REPORTS_ITEMS[item]?.mustBeBefore ||
				CONDITIONAL_SCHEDULED_REPORTS_ITEMS[item]?.mustBeAfter
			) {
				const mustBeBeforeItems = getMustBeBeforeItems(item, items);
				const mustBeAfterItems = getMustBeAfterItems(item, items);
				if (mustBeAfterItems.length) {
					toIndex = items.indexOf(au.getFirstItem(mustBeAfterItems));
				}
				if (mustBeBeforeItems.length) {
					toIndex = items.indexOf(au.getLastItem(mustBeBeforeItems)) + 1;
				}
			}
			updateItemIndex(item, toIndex, items);
		},
		[updateItemIndex]
	);

	// This Method is getting called when user is dragging the items.
	const updateItemsOrder = useCallback(
		(activeId, dragOverId) => {
			// If we are selecting the first option, then there should not be any condion check.
			if (dragOverId === "selected") {
				lastOverId.current = dragOverId;
				return [{ id: lastOverId.current }];
			}
			// If a dimension can be placed before and/or after a specific dimension.
			if (
				CONDITIONAL_SCHEDULED_REPORTS_ITEMS[activeId]?.mustBeBefore ||
				CONDITIONAL_SCHEDULED_REPORTS_ITEMS[activeId]?.mustBeAfter
			) {
				const mustBeBeforeItems = getMustBeBeforeItems(activeId, selectedIds);
				const mustBeAfterItems = getMustBeAfterItems(activeId, selectedIds);
				if (
					CONDITIONAL_SCHEDULED_REPORTS_ITEMS[activeId]?.mustBeBefore &&
					selectedIds.indexOf(activeId) >= selectedIds.indexOf(dragOverId)
				) {
					lastOverId.current =
						selectedIds[
							selectedIds.indexOf(au.getLastItem(mustBeBeforeItems)) + 1
						];
					return [{ id: lastOverId.current }];
				}
				if (
					CONDITIONAL_SCHEDULED_REPORTS_ITEMS[activeId]?.mustBeAfter &&
					selectedIds.indexOf(activeId) <= selectedIds.indexOf(dragOverId) &&
					selectedIds.indexOf(dragOverId) >=
						selectedIds.indexOf(au.getFirstItem(mustBeAfterItems))
				) {
					lastOverId.current =
						selectedIds[
							selectedIds.indexOf(au.getFirstItem(mustBeAfterItems)) - 1
						];
					return [{ id: lastOverId.current }];
				}
			}
			lastOverId.current = dragOverId;
			return [{ id: lastOverId.current }];
		},
		[selectedIds]
	);

	/**
	 * Custom collision detection strategy optimized for multiple containers (from dnd-kit docs https://master--5fc05e08a4a65d0021ae0bf2.chromatic.com/?path=/docs/presets-sortable-multiple-containers--basic-setup)
	 *
	 * - First, find any droppable containers intersecting with the pointer.
	 * - If there are none, find intersecting containers with the active draggable.
	 * - If there are no intersecting containers, return the last matched intersection
	 *
	 */
	const collisionDetectionStrategy = useCallback(
		args => {
			if (activeItemSortableId && activeItemSortableId in sortableItems) {
				return closestCenter({
					...args,
					droppableContainers: args.droppableContainers.filter(
						container => container.id in sortableItems
					)
				});
			}

			// Start by finding any intersecting droppable
			const pointerIntersections = pointerWithin(args);
			const intersections =
				pointerIntersections.length > 0
					? // If there are droppables intersecting with the pointer, return those
					  pointerIntersections
					: rectIntersection(args);
			let overId = getFirstCollision(intersections, "id");

			if (overId != null) {
				if (overId in sortableItems) {
					const containerItems = sortableItems[overId];

					// If a container is matched and it contains items (columns 'A', 'B', 'C')
					if (containerItems.length > 0) {
						// Return the closest droppable within that container
						overId = closestCenter({
							...args,
							droppableContainers: args.droppableContainers.filter(
								container =>
									container.id !== overId &&
									containerItems.includes(container.id)
							)
						})[0]?.id;
					}
				}

				updateItemsOrder(args.active.id, overId);
			}

			// When a draggable item moves to a new container, the layout may shift
			// and the `overId` may become `null`. We manually set the cached `lastOverId`
			// to the id of the draggable item that was moved to the new container, otherwise
			// the previous `overId` will be returned which can cause items to incorrectly shift positions
			if (recentlyMovedToNewContainer.current) {
				lastOverId.current = activeItemSortableId;
			}

			// If no droppable is matched, return the last match
			return lastOverId.current ? [{ id: lastOverId.current }] : [];
		},
		[activeItemSortableId, sortableItems, updateItemsOrder]
	);

	const handleDragStart = event => {
		const { active } = event;
		setActiveItemSortableId(active.id);
		setClonedItems(sortableItems[CONTAINER_SELECTED]);
	};

	const handleDragOver = ({ active, over }) => {
		const overId = over?.id;

		if (overId == null || active.id in sortableItems) {
			return;
		}

		const overContainer = findContainer(overId);
		const activeContainer = findContainer(active.id);

		if (!overContainer || !activeContainer) {
			return;
		}

		/**
		 * Only modify container arrays when necessary i.e. when an items's container changes
		 * This allows the more performant dnd animations to work while dragging within the same container
		 */
		if (activeContainer !== overContainer) {
			if (overContainer === CONTAINER_AVAILABLE) {
				setSelectedIds(clonedItems);
			}
			if (overContainer === CONTAINER_SELECTED) {
				const activeItems = sortableItems[activeContainer];
				const overItems = sortableItems[overContainer];
				const overIndex = overItems.indexOf(overId);
				const activeIndex = activeItems.indexOf(active.id);

				const isBelowOverItem =
					over &&
					active.rect.current.translated &&
					active.rect.current.translated.top > over.rect.top + over.rect.height;
				const modifier = isBelowOverItem ? 1 : 0;

				const newIndex =
					overIndex >= 0 ? overIndex + modifier : overItems.length + 1;

				recentlyMovedToNewContainer.current = true;

				setSelectedIds([
					...sortableItems[overContainer].slice(0, newIndex),
					sortableItems[activeContainer][activeIndex],
					...sortableItems[overContainer].slice(
						newIndex,
						sortableItems[overContainer].length
					)
				]);
			}
		}
	};

	const handleDragEnd = ({ active, over }) => {
		const activeContainer = findContainer(active.id);
		if (!activeContainer) {
			setActiveItemSortableId(null);
			return;
		}

		const overId = over?.id;
		if (overId == null) {
			setActiveItemSortableId(null);
			return;
		}
		const overContainer = findContainer(overId);

		if (overContainer && overContainer === CONTAINER_SELECTED) {
			const activeIndex = sortableItems[activeContainer].indexOf(active.id);
			const overIndex = sortableItems[overContainer].indexOf(overId);
			if (activeIndex !== overIndex) {
				setSelectedIds(
					arrayMove(sortableItems[CONTAINER_SELECTED], activeIndex, overIndex)
				);
			}
		}

		setActiveItemSortableId(null);
	};

	// Remove item from selected items
	const onDeleteSelectedItem = useCallback(
		itemId => {
			setSelectedIds(
				sortableItems[CONTAINER_SELECTED].filter(
					optionId => optionId !== itemId
				)
			);
		},
		[setSelectedIds, sortableItems]
	);

	// Add item to the end of the selected items list
	const onAddSelectedItem = useCallback(
		itemId => {
			const selectedItems = [...sortableItems[CONTAINER_SELECTED]];
			updateItemsOnAdd(selectedItems, itemId);
		},
		[updateItemsOnAdd, sortableItems]
	);

	const onDragCancel = () => {
		if (clonedItems) {
			// Reset items to their original state in case items have been
			// Dragged across containers
			setSelectedIds(clonedItems);
		}
		setActiveItemSortableId(null);
		setClonedItems(null);
	};

	useEffect(() => {
		requestAnimationFrame(() => {
			recentlyMovedToNewContainer.current = false;
		});
	}, [sortableItems]);

	return (
		<Grid container spacing={MUI_GRID_CONTAINER_SPACING} sx={{ m: 0, p: 0 }}>
			<DndContext
				sensors={sensors}
				onDragOver={handleDragOver}
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
				onDragCancel={onDragCancel}
				collisionDetection={collisionDetectionStrategy}
			>
				{/*
				Container context

				We make sortable containers to use the drop behavior only
				We don't allow actual drag/sort of the containers so we don't need a strategy here

			*/}
				<SortableContext items={sortableContainers} strategy={() => {}}>
					<Grid item xs={6}>
						<DropabbleContainer
							id={CONTAINER_AVAILABLE}
							itemIds={sortableItems[CONTAINER_AVAILABLE]}
							options={options}
							ariaLabel={lc.AVAILABLE_OPTIONS_LABEL}
							strategy={() => {}}
							secondaryAction={{
								icon: IconAdd,
								label: lc.ADD_ITEM_LABEL,
								action: onAddSelectedItem
							}}
							selectedIds={sortableItems[CONTAINER_SELECTED]}
							disabledItems={disabledItems}
						/>
					</Grid>
					<Grid item xs={6}>
						<DropabbleContainer
							id={CONTAINER_SELECTED}
							itemIds={sortableItems[CONTAINER_SELECTED]}
							options={options}
							ariaLabel={lc.SELECTED_OPTIONS_LABEL}
							toolbarContent={
								<Button onClick={() => setSelectedIds([])}>
									{lc.REMOVE_ALL_LABEL}
								</Button>
							}
							secondaryAction={{
								icon: IconClose,
								label: lc.REMOVE_ITEM_LABEL,
								action: onDeleteSelectedItem
							}}
							error={Boolean(selectedItemsError)}
							disabledItems={disabledItems}
						/>
						{selectedItemsError && (
							<FormHelperText error>{selectedItemsError}</FormHelperText>
						)}
					</Grid>
				</SortableContext>
				<Portal container={document.body}>
					<DragOverlay>
						{activeItemSortableId ? (
							<List sx={{ margin: 0, padding: 0 }}>
								<DndListItem
									isDragging
									label={activeItem.label || activeItem.name}
									onDelete={() => {}}
								/>
							</List>
						) : null}
					</DragOverlay>
				</Portal>
			</DndContext>
		</Grid>
	);
}

DragAndDropSelector.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape()),
	selectedIds: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	).isRequired,
	setSelectedIds: PropTypes.func.isRequired,
	selectedItemsError: PropTypes.string,
	disabledItems: PropTypes.arrayOf(PropTypes.string)
};

DragAndDropSelector.defaultProps = {
	options: undefined,
	selectedItemsError: undefined,
	disabledItems: []
};

export default DragAndDropSelector;
