import React from "react";
import PropTypes from "prop-types";
import MuiTooltip from "@mui/material/Tooltip";

function Tooltip(props) {
	const { title, children, open, onClose, onOpen, placement } = props;

	return (
		<MuiTooltip
			open={open}
			onClose={onClose}
			onOpen={onOpen}
			title={title}
			placement={placement}
			enterDelay={500}
			enterNextDelay={500}
			arrow
		>
			{children}
		</MuiTooltip>
	);
}

Tooltip.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
	children: PropTypes.node.isRequired,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onOpen: PropTypes.func,
	placement: PropTypes.string
};

Tooltip.defaultProps = {
	open: undefined,
	onClose: undefined,
	onOpen: undefined,
	placement: "top-start"
};

export default Tooltip;
