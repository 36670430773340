const ApiEnvironmentMapping = [
	{
		env: "local",
		webHostName: "localhost",
		apiUrl:
			process.env.REACT_APP_DASHBOARD_API_URL || "https://manage.ad.local:8082",
		routerUrl: process.env.REACT_APP_ROUTERS_API_URL || "http://localhost:8111"
	},
	{
		env: "dev",
		webHostName: "dev.platform.column6.tv",
		apiUrl: "https://managedev.siprocalads.com",
		routerUrl: "https://routerdev.siprocalads.com"
	},
	{
		env: "platformDev",
		webHostName: "platformdev.siprocalads.com",
		apiUrl: "https://managedev.siprocalads.com",
		routerUrl: "https://routerdev.siprocalads.com"
	},
	{
		env: "qa1",
		webHostName: "qa1.platform.column6.tv",
		apiUrl: "https://manageqa1.siprocalads.com",
		routerUrl: "https://routerqa1.siprocalads.com"
	},
	{
		env: "platformQa1",
		webHostName: "platformqa1.siprocalads.com",
		apiUrl: "https://manageqa1.siprocalads.com",
		routerUrl: "https://routerqa1.siprocalads.com"
	},
	{
		env: "qa2",
		webHostName: "qa2.platform.column6.tv",
		apiUrl: "https://manageqa2.siprocalads.com",
		routerUrl: "https://routerqa2.siprocalads.com"
	},
	{
		env: "platformQa2",
		webHostName: "platformqa2.siprocalads.com",
		apiUrl: "https://manageqa2.siprocalads.com",
		routerUrl: "https://routerqa2.siprocalads.com"
	},
	{
		env: "int1",
		webHostName: "int1.platform.column6.tv",
		apiUrl: "https://manageint1.siprocalads.com",
		routerUrl: "https://routerint1.siprocalads.com"
	},
	{
		env: "platformInt1",
		webHostName: "platformint1.siprocalads.com",
		apiUrl: "https://manageint1.siprocalads.com",
		routerUrl: "https://routerint1.siprocalads.com"
	},
	{
		env: "prod1",
		webHostName: "platform.column6.tv",
		apiUrl: "https://manage.siprocalads.com",
		routerUrl: "https://router.siprocalads.com"
	},
	{
		env: "prod2",
		webHostName: "platform.siprocal.com",
		apiUrl: "https://manage.siprocalads.com",
		routerUrl: "https://router.siprocalads.com"
	}
];

let appHostName = "http://localhost:3004";
if (window && window.location) {
	appHostName = window.location.hostname;
}

const DASHBOARD_API_URL = ApiEnvironmentMapping.find(
	env => env.webHostName === appHostName
)?.apiUrl;

const ROUTER_API_URL = ApiEnvironmentMapping.find(
	env => env.webHostName === appHostName
)?.routerUrl;

export { DASHBOARD_API_URL, ROUTER_API_URL };
