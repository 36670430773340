/* eslint-disable no-template-curly-in-string */
const localeContent = {
	ENABLE_FREQUENCY_CAP_LABEL: "Set Frequency Cap",
	ENABLE_FREQUENCY_CAP_TOOLTIP:
		"Enable to limit the number of times an end-user can see this C6 Outstream ad unit over a 24 hour period",
	FREQUENCY_CAP_LABEL: "Frequency Cap",
	FREQUENCY_CAP_TOOLTIP:
		"Define the number of times an end-user can see this C6 Outstream ad unit over 24 hour period.",
	NUMBER_TYPE_WARNING: "Must be a number",
	INTEGER_WARNING: "Must be an integer",
	GREATER_THAN_ZERO_WARNING: "Must be greater than zero",
	MAX_VALUE_EXCEEDED_WARNING: "Cannot be more than ${max}",
	REQUIRED_WHEN_ENABLED_WARNING: null
};
localeContent.REQUIRED_WHEN_ENABLED_WARNING = `Required when ${localeContent.ENABLE_FREQUENCY_CAP_LABEL} is enabled`;
export default localeContent;
