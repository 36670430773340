import React from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Typography } from "@mui/material";
import RouterLink from "../Routing/LinkWithQuery";
import { BREADCRUMB_TEXT_FOR_ARIA_LABEL } from "./textFixtures";

export const BREADCRUMB_ITEM_TEST_ID = "breadcrumbs-current-item-name";

function CrudFormBreadcrumbs(props) {
	const { breadcrumbs } = props;

	return (
		<Breadcrumbs
			separator="›"
			aria-label={BREADCRUMB_TEXT_FOR_ARIA_LABEL}
			sx={{
				minHeight: "24px",
				color: "inherit"
			}}
		>
			{breadcrumbs.map(breadCrumbObj => {
				const isLink = breadCrumbObj.title && breadCrumbObj.href;
				return (
					<Typography
						key={breadCrumbObj.key}
						component={isLink ? RouterLink : undefined}
						variant="caption"
						to={isLink ? `/dashboard/${breadCrumbObj.href}` : undefined}
						sx={
							isLink
								? {
										textDecoration: "none",
										color: "inherit"
								  }
								: {
										fontWeight: "700"
								  }
						}
						data-testid={BREADCRUMB_ITEM_TEST_ID}
					>
						{breadCrumbObj.title || " "}
					</Typography>
				);
			})}
			;
		</Breadcrumbs>
	);
}

CrudFormBreadcrumbs.propTypes = {
	breadcrumbs: PropTypes.arrayOf(PropTypes.shape())
};

CrudFormBreadcrumbs.defaultProps = {
	breadcrumbs: []
};

export default CrudFormBreadcrumbs;
