import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import crudFormLocaleContent from "../CrudForm/localeContent";
import FormTextField from "../FormTextField/FormTextField";
import FormFieldSelect from "../FormFieldSelect/FormFieldSelect";
import FormFieldFileUpload from "../FormFieldFileUpload/FormFieldFileUpload";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import lc from "./localeContent";
import FormFieldNumber from "../FormFieldNumber/FormFieldNumber";

export const KEY_UPLOAD_NAME = "uploadName";
export const KEY_FILE_NAME = "fileName";
export const KEY_MIME_TYPE = "mimeType";
export const KEY_HEIGHT = "height";
export const KEY_WIDTH = "width";
export const KEY_BITRATE = "bitRate";
const KEY_FILE = "file";

const requiredStringValidation = Yup.string().required(
	crudFormLocaleContent.REQUIRED_FIELD_WARNING
);

export const VALIDATION_SCHEMA = Yup.object({
	[KEY_UPLOAD_NAME]: requiredStringValidation,
	[KEY_BITRATE]: requiredStringValidation,
	[KEY_WIDTH]: requiredStringValidation,
	[KEY_HEIGHT]: requiredStringValidation,
	[KEY_FILE]: Yup.mixed().required(crudFormLocaleContent.REQUIRED_FIELD_WARNING)
});

const MIME_TYPE_OPTIONS = [
	{ value: "video/mp4", label: "video/mp4" },
	{ value: "video/x-mp4", label: "video/x-mp4" },
	{ value: "video/flv", label: "video/flv" },
	{ value: "video/x-flv", label: "video/x-flv" },
	{ value: "video/ogg", label: "video/ogg" },
	{ value: "video/webm", label: "video/webm" },
	{ value: "video/H264", label: "video/H264" },
	{ value: "video/mpeg", label: "video/mpeg" },
	{ value: "video/3gp", label: "video/3gp" },
	{ value: "application/javascript", label: "application/javascript" },
	{
		value: "application/x-shockwave-flash",
		label: "application/x-shockwave-flash"
	}
];

export const DEFAULT_FORM_VALUES = {
	[KEY_UPLOAD_NAME]: "",
	[KEY_MIME_TYPE]: "video/mp4",
	[KEY_HEIGHT]: "",
	[KEY_WIDTH]: "",
	[KEY_BITRATE]: "",
	[KEY_FILE]: ""
};

export const UPLOAD_FILE_MENU_TITLE_ID = "upload-media-dialog-title";
export const UPLOAD_FILE_LOADING_SPINNER_TEST_ID = "upload-file-form";

function UploadMediaFileForm(props) {
	const { onClose } = props;
	const {
		handleSubmit,
		values,
		setFieldValue,
		resetForm,
		isSubmitting
	} = useFormikContext();

	const [resetValues, setResetValues] = useState(null);
	useEffect(() => {
		if (resetValues) {
			resetForm({ values: { ...values, ...resetValues } });
			setResetValues(null);
		}
	}, [resetValues, values, resetForm]);

	const fileValue = values[KEY_FILE];
	useEffect(() => {
		if (fileValue) {
			const extensionProps = {};
			const filename = fileValue.name;
			const splitFilename = filename.split(".");
			splitFilename.pop();
			extensionProps[KEY_UPLOAD_NAME] = splitFilename.join();

			if (
				MIME_TYPE_OPTIONS.some(mimeType => mimeType.value === fileValue.type)
			) {
				extensionProps[KEY_MIME_TYPE] = fileValue.type;
			}
			setResetValues(extensionProps);
		}
	}, [fileValue, setFieldValue]);

	return (
		<form noValidate onSubmit={handleSubmit}>
			<LoadingBackdrop
				isOpen={isSubmitting}
				testId={UPLOAD_FILE_LOADING_SPINNER_TEST_ID}
			/>
			<DialogTitle id={UPLOAD_FILE_MENU_TITLE_ID}>
				{lc.UPLOAD_MEDIA_FILE_DIALOGUE_TITLE}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormFieldFileUpload
							id={KEY_FILE}
							name={KEY_FILE}
							label={lc.FIELD_LABEL_FILE}
							fieldTypeOptions={{
								acceptedFileTypes: MIME_TYPE_OPTIONS.map(({ value }) => value)
							}}
						/>
					</Grid>
					{fileValue && (
						<>
							<Grid item xs={12}>
								<FormTextField
									id={KEY_UPLOAD_NAME}
									name={KEY_UPLOAD_NAME}
									label={lc.FIELD_LABEL_UPLOAD_NAME}
									required
								/>
							</Grid>
							<Grid item xs={6}>
								<FormFieldSelect
									id={KEY_MIME_TYPE}
									name={KEY_MIME_TYPE}
									label={lc.FIELD_LABEL_MIME_TYPE}
									optionsConfig={{ options: MIME_TYPE_OPTIONS }}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormFieldNumber
									id={KEY_BITRATE}
									name={KEY_BITRATE}
									label={lc.FIELD_LABEL_BITRATE}
									fieldTypeOptions={{
										endAdornmentText: "kbps"
									}}
									required
								/>
							</Grid>
							<Grid item xs={6}>
								<FormFieldNumber
									id={KEY_WIDTH}
									name={KEY_WIDTH}
									label={lc.FIELD_LABEL_WIDTH}
									fieldTypeOptions={{
										endAdornmentText: "px"
									}}
									required
								/>
							</Grid>
							<Grid item xs={6}>
								<FormFieldNumber
									id={KEY_HEIGHT}
									name={KEY_HEIGHT}
									label={lc.FIELD_LABEL_HEIGHT}
									fieldTypeOptions={{
										endAdornmentText: "px"
									}}
									required
								/>
							</Grid>
						</>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>{lc.ACTION_BUTTON_LABEL_CANCEL}</Button>
				<Button type="submit">{lc.ACTION_BUTTON_LABEL_SUBMIT}</Button>
			</DialogActions>
		</form>
	);
}

UploadMediaFileForm.propTypes = {
	onClose: PropTypes.func.isRequired
};

export default UploadMediaFileForm;
