import PropTypes from "prop-types";
import { formatAsCurrency, formatAsPercent } from "../formatters";
import { TABLE_DATA_TYPE } from "../../config/constants";

function CurrencyCell({ value }) {
	return formatAsCurrency(value) || null;
}
CurrencyCell.propTypes = {
	value: PropTypes.number
};
CurrencyCell.defaultProps = {
	value: undefined
};

function PercentCell({ value }) {
	return formatAsPercent(value) || null;
}
PercentCell.propTypes = {
	value: PropTypes.number
};
PercentCell.defaultProps = {
	value: undefined
};

export const buildColumnDefinition = columnDefinition => {
	switch (columnDefinition.type) {
		case TABLE_DATA_TYPE.CURRENCY:
			return {
				...columnDefinition,
				align: "right",
				Cell: CurrencyCell,
				disableGlobalFilter: !columnDefinition.enableGlobalFilter
			};
		case TABLE_DATA_TYPE.NUMBER:
			return {
				...columnDefinition,
				align: "right",
				disableGlobalFilter: !columnDefinition.enableGlobalFilter
			};
		case TABLE_DATA_TYPE.PERCENTAGE:
			return {
				...columnDefinition,
				align: "right",
				Cell: PercentCell,
				disableGlobalFilter: !columnDefinition.enableGlobalFilter
			};
		default:
			return {
				...columnDefinition,
				disableGlobalFilter: !columnDefinition.enableGlobalFilter
			};
	}
};

export default { buildColumnDefinition };
