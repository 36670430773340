import commonLocaleContent from "../screens/commonLocaleContent";
import { UTC_TIME_ZONE } from "../config/constants";

export const TIMEZONE_LABELS = {
	UTC: commonLocaleContent.TIMEZONE.UTC,
	ET: commonLocaleContent.TIMEZONE.ET,
	CT: commonLocaleContent.TIMEZONE.CT,
	PT: commonLocaleContent.TIMEZONE.PT,
	MT: commonLocaleContent.TIMEZONE.MT
};
export const TIMEZONES = {
	UTC: UTC_TIME_ZONE,
	ET: "America/Detroit",
	CT: "America/Chicago",
	PT: "America/Los_Angeles",
	MT: "America/Denver"
};
export const TIMEZONE_CONFIGS = Object.keys(TIMEZONES).reduce(
	(configs, key) => {
		configs[TIMEZONES[key]] = { label: TIMEZONE_LABELS[key], shortName: key };
		return configs;
	},
	{}
);
export const TIMEZONE_OPTIONS = Object.keys(TIMEZONES).map(key => ({
	value: TIMEZONES[key],
	label: TIMEZONE_LABELS[key]
}));
