import commonLocaleContent from "./commonLocaleContent";
import mapEnumToFieldOptions from "../utils/mapEnumToOptions";

const PRIORITY = [
	{
		value: "",
		label: commonLocaleContent.PRIORITY_OPTION_BACKFILL
	},
	{ value: 1, label: "1" },
	{ value: 2, label: "2" },
	{ value: 3, label: "3" },
	{ value: 4, label: "4" },
	{ value: 5, label: "5" },
	{ value: 6, label: "6" },
	{ value: 7, label: "7" },
	{ value: 8, label: "8" },
	{ value: 9, label: "9" },
	{ value: 10, label: "10" }
];
const WEIGHT = [...PRIORITY];

const STATUS_ENUM = {
	active: {
		value: "active",
		label: commonLocaleContent.STATUS_OPTION_LABEL_ACTIVE
	},
	inactive: {
		value: "inactive",
		label: commonLocaleContent.STATUS_OPTION_LABEL_INACTIVE
	}
};

const FIELD_OPTION_ENUMS = {
	PRIORITY,
	WEIGHT,
	STATUS_ENUM
};

export default {
	...FIELD_OPTION_ENUMS,
	mapEnumToFieldOptions
};
