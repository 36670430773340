import React, { createContext, useCallback, useState } from "react";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_4_ID;

const GoogleAnalyticsContext = createContext();
const { Provider } = GoogleAnalyticsContext;

function GoogleAnalyticsProvider({ children }) {
	const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(false);

	// Initialize Google Analytics (once only) if an analytics id is provided via env variable
	if (GOOGLE_ANALYTICS_ID && !isAnalyticsEnabled) {
		ReactGA.initialize(GOOGLE_ANALYTICS_ID);
		setIsAnalyticsEnabled(true);
	}

	// Sends a google analytics pageview if analytics is enabled
	const sendPageView = useCallback(
		page => {
			if (isAnalyticsEnabled) {
				ReactGA.send({ hitType: "pageview", page });
			}
		},
		[isAnalyticsEnabled]
	);

	return (
		<Provider
			value={{
				sendPageView
			}}
		>
			{children}
		</Provider>
	);
}

GoogleAnalyticsProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { GoogleAnalyticsContext, GoogleAnalyticsProvider };
