import React, { useState, useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import _orderBy from "lodash/orderBy";
import PageTitleBar from "../../components/PageTitleBar/PageTitleBar";
import { Operations, useResourceClient } from "../../hooks/useResource";
import LoadingBackdrop from "../../components/LoadingBackdrop/LoadingBackdrop";
import ConfirmDialogueSimple from "../../components/ConfirmDialogueSimple/ConfirmDialogueSimple";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import { SnackbarContext } from "../../context/SnackbarContext";
import localeContent from "./localeContent";

export const DATE_FORMAT = "MM/dd/yyyy";

const READ_NOTIFICATION_ENDPOINT_OPERATION = {
	...Operations.UPDATE,
	getResourceEndpoint: (baseResourceEndpoint, params) =>
		`${baseResourceEndpoint}/${params.selectedNotificationId}/state`
};

const DELETE_NOTIFICATION_ENDPOINT_OPERATION = {
	...Operations.DELETE,
	getRequestBody: params => params.data,
	getResourceEndpoint: (baseResourceEndpoint, params) =>
		`${baseResourceEndpoint}/${params.selectedNotificationId}`
};

const customStyles = {
	".MuiAccordionSummary-expandIconWrapper ": {
		marginRight: 2
	},
	".MuiAccordionSummary-root": {
		justifyContent: "space-between",
		flexDirection: "row-reverse"
	},
	display: "flex",
	justifyContent: "flex-start"
};

function UserNotificationsScreen() {
	const [selectedId, setSelectedId] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedNotificationId, setSelectedNotificationId] = useState(null);
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const [
		userNotificationOptions,
		,
		isFetching,
		requestUserNotifications
	] = useResourceClient(
		"notifications/user-notifications?",
		Operations.LIST,
		{}
	);

	const { isLoading: isReading, execute: requestRead } = useResourceAsync(
		"notifications/user-notification",
		READ_NOTIFICATION_ENDPOINT_OPERATION
	);

	const { isLoading: isDeleting, execute: deleteEntity } = useResourceAsync(
		"notifications/user-notification",
		DELETE_NOTIFICATION_ENDPOINT_OPERATION
	);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const handleDelete = async () => {
		closeDeleteModal();
		const { isSuccess, error } = await deleteEntity({
			selectedNotificationId
		});
		if (error) {
			const { message } = error;
			triggerNewSnackbarMessage({ message, severity: "error" });
		}
		if (isSuccess) {
			triggerNewSnackbarMessage({
				message: localeContent.DELETED_SUCCESSFULLY_MESSAGE,
				severity: "success"
			});
			requestUserNotifications({});
		}
	};

	const handleChange = panel => async (event, isExpanded) => {
		setSelectedId(isExpanded ? panel.notificationId : false);
		if (panel.status === "Viewed") {
			const { isSuccess, error } = await requestRead({
				selectedNotificationId: panel.notificationId,
				data: { state: "READ" }
			});
			if (error) {
				const { message } = error;
				triggerNewSnackbarMessage({ message, severity: "error" });
			}
			if (isSuccess) {
				requestUserNotifications({});
			}
		}
	};
	const openDeleteConfirmation = notificationId => {
		setIsDeleteModalOpen(true);
		setSelectedNotificationId(notificationId);
	};

	const getFontWeight = option =>
		option.status === "Viewed" ? "bold" : "normal";

	return (
		<>
			<LoadingBackdrop
				isOpen={isFetching || isDeleting || isReading}
				testId="fetching-blacklist"
			/>
			<PageTitleBar
				barTitle={`${localeContent.PAGE_TITLE}s`}
				actionButtonIconComponent={AddIcon}
			/>

			<Container maxWidth={false} sx={{ pt: 3 }}>
				{userNotificationOptions ? (
					_orderBy(userNotificationOptions, ["notificationDate"], ["desc"]).map(
						option => (
							<Box key={`key-${option.notificationId}`} sx={{ pt: 1 }}>
								<Accordion
									expanded={selectedId === option.notificationId}
									onChange={handleChange(option)}
									key={`key-${option.notificationId}`}
									sx={{
										".MuiAccordionDetails-root p": { margin: "unset" }
									}}
								>
									<Box sx={customStyles} key={`key-${option.notificationId}`}>
										<Box
											sx={{
												width: "95%"
											}}
										>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<Typography
													sx={{
														width: "50%",
														fontWeight: getFontWeight(option)
													}}
												>
													{`${option.notificationType}: ${option.notificationName}`}
												</Typography>
												<Typography
													sx={{
														width: "15%",
														fontWeight: getFontWeight(option)
													}}
												>
													{option.notificationDate}
												</Typography>
											</AccordionSummary>
										</Box>
										<Box sx={{ width: "5%" }}>
											<IconButton
												onClick={() =>
													openDeleteConfirmation(option.notificationId)
												}
											>
												<DeleteIcon />
											</IconButton>
										</Box>
									</Box>
									<AccordionDetails sx={{ marginTop: "-10px" }}>
										<Typography
											className="ql-editor"
											dangerouslySetInnerHTML={{
												__html: option.notificationText
											}}
										/>
									</AccordionDetails>
								</Accordion>
							</Box>
						)
					)
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column"
						}}
					>
						<NotificationsOffOutlinedIcon />
						<Typography variant="subtitle1">
							{localeContent.NO_NOTIFICATION_MESSAGE}
						</Typography>
					</Box>
				)}
				<ConfirmDialogueSimple
					title={localeContent.DELETE_NOTIFICATION_MODAL.TITLE}
					description={localeContent.DELETE_NOTIFICATION_MODAL.DESCRIPTION}
					isOpen={isDeleteModalOpen}
					onConfirm={handleDelete}
					confirmLabel={
						localeContent.DELETE_NOTIFICATION_MODAL.DELETE_BUTTON_LABEL
					}
					onCancel={closeDeleteModal}
					onClose={closeDeleteModal}
				/>
			</Container>
		</>
	);
}

export default UserNotificationsScreen;
