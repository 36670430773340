import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link as RouterLink } from "react-router-dom";
import { Link, List, ListItem, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import FormTextField from "../../components/FormTextField/FormTextField";
import useQueryParam from "../../hooks/useQueryParam";
import lc from "./localeContent";
import { SnackbarContext } from "../../context/SnackbarContext";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import { Operations } from "../../hooks/useResource";
import AppBarWithLogo from "../AppBarWithLogo/AppBarWithLogo";

const RESET_PASSWORD_ROUTE = "manage/user-password";
const ValidationSchema = Yup.object().shape({
	newPassword: Yup.string().required(lc.PASSWORD_MESSAGE.REQUIRED),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("newPassword"), null], lc.PASSWORD_MESSAGE.DO_NOT_MATCH)
		.required(lc.PASSWORD_MESSAGE.REQUIRED)
});

function ShowErrors({ errors }) {
	return (
		<List
			sx={{
				color: theme => theme.palette.error.main,
				fontSize: "0.75rem"
			}}
		>
			{errors.map(name => (
				<ListItem sx={{ padding: 0 }} key={name}>
					{name}
				</ListItem>
			))}
		</List>
	);
}

ShowErrors.propTypes = {
	errors: PropTypes.arrayOf(PropTypes.string).isRequired
};

function PasswordRequirements() {
	return (
		<List sx={{ fontSize: "0.75rem", p: 0 }}>
			<ListItem sx={{ fontWeight: "bold", p: 0 }}>
				{lc.PASSWORD_MUST_CONTAIN_LABEL}
			</ListItem>
			{lc.PASSWORD_REQUIREMENTS.map(it => (
				<ListItem key={it} sx={{ p: 0 }}>
					{it}
				</ListItem>
			))}
		</List>
	);
}

function ResetPassword() {
	const userToken = useQueryParam("token");
	const [redirectPath, setRedirectPath] = useState(null);
	const [passwordErrors, setPasswordErrors] = useState([]);

	const { showSnackbarInfoMessage } = useContext(SnackbarContext);

	const { execute: submitFormData } = useResourceAsync(
		RESET_PASSWORD_ROUTE,
		Operations.UPDATE_WITHOUT_ID
	);

	const VALIDATION_MESSAGES = {
		existingSame: lc.PASSWORD_MESSAGE.EXISTING_SAME,
		notMatch: lc.PASSWORD_MESSAGE.DO_NOT_MATCH,
		minLength: lc.PASSWORD_MESSAGE.MIN_LENGTH,
		invalidToken: lc.PASSWORD_MESSAGE.INVALID_TOKEN,
		upperCaseMissing: lc.PASSWORD_MESSAGE.UPPERCASE_MISSING,
		numberMissing: lc.PASSWORD_MESSAGE.NUMBER_MISSING,
		specialCharMissing: lc.PASSWORD_MESSAGE.SPECIAL_CHAR,
		notSame: lc.PASSWORD_MESSAGE.DO_NOT_MATCH
	};

	const handleSubmit = async values => {
		setPasswordErrors([]);
		const { error } = await submitFormData({
			data: {
				...values,
				token: userToken
			}
		});
		const errors = error?.detail?.newPassword;
		const { message } = error || {};
		if (!isEmpty(errors)) {
			const allErrors = [];
			errors.map(errorKey => allErrors.push(VALIDATION_MESSAGES[errorKey]));
			setPasswordErrors(allErrors);
		} else if (message === "invalidToken") {
			showSnackbarInfoMessage(VALIDATION_MESSAGES[message]);
			setRedirectPath("forgot");
		} else if (!isEmpty(message)) {
			setPasswordErrors([VALIDATION_MESSAGES[message]]);
		} else {
			showSnackbarInfoMessage(lc.PASSWORD_MESSAGE.SUCCESS);
			setRedirectPath("login");
		}
	};

	useEffect(() => {
		if (userToken === null) {
			showSnackbarInfoMessage(lc.INVALID_REQUEST_VALIDATION_MESSAGE);
		}
	}, [showSnackbarInfoMessage, userToken]);

	return (
		<>
			{redirectPath && <Redirect to={redirectPath} />}
			<AppBarWithLogo />
			<Container maxWidth="xs" sx={{ pt: 15 }}>
				<Typography
					variant="subtitle1"
					component="h1"
					sx={{ textAlign: "center", mb: 2 }}
				>
					{lc.RESET_PASSWORD_FORM_TITLE}
				</Typography>
				<Formik
					initialValues={{ newPassword: "", confirmPassword: "" }}
					onSubmit={handleSubmit}
					validationSchema={ValidationSchema}
				>
					<Form noValidate>
						<Paper elevation={4} sx={{ mb: 5, p: 4, pb: 3 }}>
							<Grid item xs={12} sx={{ mb: 3 }}>
								<FormTextField
									type="password"
									id="newPassword"
									name="newPassword"
									label={lc.NEW_PASSWORD_LABEL}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormTextField
									type="password"
									id="confirmPassword"
									name="confirmPassword"
									label={lc.CONFIRM_PASSWORD_LABEL}
								/>
							</Grid>
							<ShowErrors errors={passwordErrors} />
							<PasswordRequirements />
						</Paper>
						<Grid container>
							<Grid item xs={12} sx={{ textAlign: "center" }}>
								<Button type="submit">{lc.SUBMIT_BUTTON_LABEL}</Button>
								<Link
									to="login"
									color="inherit"
									component={RouterLink}
									sx={{ display: "block" }}
								>
									{lc.BACK_TO_LOGIN_LABEL}
								</Link>
							</Grid>
						</Grid>
					</Form>
				</Formik>
			</Container>
		</>
	);
}
export default ResetPassword;
