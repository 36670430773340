import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import * as Yup from "yup";
import { Box } from "@mui/material";

import Switch from "../FormFieldSwitch/Switch";
import localeContent from "./localeContent";
import { DAYS_LIST, HOURS } from "./constants";
import DayPartingTable from "./DayPartingTable";
import { ENABLED_KEY } from "../FormFieldGeoTargeting/FormFieldGeoTargeting";

export const KEY_ENABLED = "enabled";

/**
 * Determine if field is enabled or not.
 * Also convert the map of day => hourArray to a map of day => hourMap to make it easier to manipulate selections
 * From:
 * {
 * 		sunday: [1, 2],
 * 		monday: [15, 17]
 * }
 * To:
 * {
 * 		sunday: {
 * 				1: true,
 * 				2: true
 * 		},
 * 		monday: {
 * 		 		15: true,
 * 				17: true
 * 		}
 * }
 * @param {*} data initData provided by CrudForm
 * @param {*} fieldName name of the field in the Formik context
 */
export const transformInitData = (data, fieldName) => {
	const enabled = Boolean(data[fieldName]);

	const fieldData = data[fieldName] || {};
	// Initialize day keys with data from api or an empty array
	const convertDayArrayToBooleanMap = sourceArray =>
		sourceArray.reduce((agg, item) => ({ ...agg, [item]: true }), {});

	const selectedDays = DAYS_LIST.reduce(
		(agg, item) => ({
			...agg,
			[item.id]: convertDayArrayToBooleanMap(fieldData[item.id] || [])
		}),
		{}
	);

	return { ...fieldData, selectedDays, [KEY_ENABLED]: enabled };
};

/**
 * Strip client-side state like enabled key and convert map of day => hourMap back to map of day => hourArray for submission
 * @param {*} data submissionData provided by CrudForm
 * @param {*} fieldName name of the field in the Formik context
 */
export const transformSubmitData = (data, fieldName) => {
	if (data[KEY_ENABLED]) {
		const convertBooleanMapToDayArray = sourceBooleanMap =>
			Object.entries(sourceBooleanMap)
				// Filter only keys whose values are "true"
				.filter(([, hourValue]) => hourValue)
				// Parse the string key back to an integer for the submission array
				.reduce((agg, [hourKey]) => [...agg, parseInt(hourKey, 10)], []);

		const { selectedDays } = data;
		const submissionData = Object.fromEntries(
			Object.entries(selectedDays).map(([day, hoursMap]) => [
				day,
				convertBooleanMapToDayArray(hoursMap)
			])
		);
		return { [fieldName]: submissionData };
	}

	// If field not enabled, nullify any existing data
	return { [fieldName]: null };
};

function FormFieldDayParting(props) {
	const { name } = props;
	const [field, meta, helpers] = useField(name);
	const formikData = field.value;
	const showError = Boolean(meta.touched && meta.error);
	const helperText = showError ? meta.error : "";

	const { selectedDays } = formikData;

	const toggleHourSelected = (day, hour) => {
		const isSelected = Boolean(selectedDays[day.id][hour.id]);

		helpers.setValue({
			...formikData,
			selectedDays: {
				...selectedDays,
				[day.id]: { ...selectedDays[day.id], [hour.id]: !isSelected }
			}
		});
	};

	// If any hours are not yet selected, then select all, else if all are already selected, then unselect all
	const toggleAllHoursForDay = day => {
		const anyUnselected = HOURS.some(hour => !selectedDays[day.id][hour.id]);

		helpers.setValue({
			...formikData,
			selectedDays: {
				...selectedDays,
				[day.id]: HOURS.reduce(
					(agg, { id }) => ({ ...agg, [id]: anyUnselected }),
					{}
				)
			}
		});
	};

	// If any days are not yet selected, then select all, else if all are already selected, then unselect all
	const toggleAllDaysForHour = hour => {
		const anyUnselected = DAYS_LIST.some(day => !selectedDays[day.id][hour.id]);

		helpers.setValue({
			...formikData,
			selectedDays: {
				...DAYS_LIST.reduce(
					(agg, day) => ({
						...agg,
						[day.id]: {
							...selectedDays[day.id],
							[hour.id]: anyUnselected
						}
					}),
					{}
				)
			}
		});
	};

	return (
		<>
			<Box sx={{ mb: formikData[KEY_ENABLED] ? 1 : undefined }}>
				<Switch
					id="day-parting-enabled"
					name="dayPartingEnabled"
					label={localeContent.DAY_PARTING_LABEL}
					value={formikData[KEY_ENABLED]}
					showError={showError}
					helperText={helperText}
					onChange={event => {
						helpers.setValue({
							...field.value,
							[KEY_ENABLED]: event.target.checked
						});
					}}
				/>
			</Box>

			{formikData[KEY_ENABLED] && (
				<DayPartingTable
					selectedDays={selectedDays}
					toggleHourSelected={toggleHourSelected}
					toggleAllHoursForDay={toggleAllHoursForDay}
					toggleAllDaysForHour={toggleAllDaysForHour}
				/>
			)}
		</>
	);
}

FormFieldDayParting.propTypes = {
	name: PropTypes.string.isRequired
};

FormFieldDayParting.defaultProps = {};

Yup.addMethod(Yup.object, "atLeastOneHourSelected", function addUniqueProperty(
	message
) {
	return this.test(
		"atLeastOneHourSelected",
		message,
		function testUniqueProperty(value) {
			if (!value[ENABLED_KEY]) return true;
			const atLeastOneHourSelected = DAYS_LIST.some(day => {
				const dayHours = value.selectedDays[day.id];
				return Object.entries(dayHours).some(([, isSelected]) => isSelected);
			});

			if (!atLeastOneHourSelected) {
				throw this.createError({
					path: this.path,
					message
				});
			}

			return true;
		}
	);
});

export const validationSchema = Yup.object().atLeastOneHourSelected(
	localeContent.VALIDATION_MESSAGES.MIN_ONE_DAY_SELECT
);

export default FormFieldDayParting;
