import React, { useCallback, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconAdd from "@mui/icons-material/Add";
import IconClose from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import isEmpty from "lodash/isEmpty";

import {
	AUCTION_TYPE_AGREED_UPON_DEAL_PRICE,
	MUI_GRID_CONTAINER_SPACING
} from "../../config/constants";
import useResource, { Operations } from "../../hooks/useResource";
import { getComparator, stableSort } from "../../utils/sortUtil";
import BidderListItem from "./BidderListItem";
import SearchTextField from "../SearchTextField/SearchTextField";
import Tooltip from "../Tooltip/Tooltip";
import lc from "./localeContent";

const BIDDER_ID_KEY = "rtbBidderId";
const BIDDER_SEATS_KEY = "rtbBidderSeats";
const SELECTION_LIST_PAPER_ELEVATION = 2;

const getSelectionListSx = theme => ({
	height: theme.spacing(38),
	overflowY: "scroll"
});
const selectedBiddersErrorSx = {
	borderBottom: "2px solid",
	borderBottomColor: "error.main"
};

const getSelectedBidderOptions = (bidderOptions, fieldValue) =>
	bidderOptions
		? fieldValue.map(bidderRow => {
				const rtbBidderId = bidderRow[BIDDER_ID_KEY];
				return bidderOptions.find(
					bidderOption => rtbBidderId === bidderOption.id
				);
		  })
		: [];

const getAvailableSeatOptions = (bidderSeatOptions, selectedSeats) => {
	if (!selectedSeats || isEmpty(selectedSeats)) {
		return bidderSeatOptions;
	}
	return bidderSeatOptions.filter(
		seatOption =>
			!selectedSeats.find(selectedSeat => selectedSeat.id === seatOption.id)
	);
};

const hasAvailableSeats = (bidder, selectedBidderSeatMap) => {
	if (selectedBidderSeatMap[bidder.id]) {
		return (
			selectedBidderSeatMap[bidder.id].length < bidder.rtbBidderSeats.length
		);
	}
	return bidder.rtbBidderSeats.length > 0;
};

const getAvailableBidderOptions = (
	bidderOptions,
	selectedBidderSeatMap,
	lockedBidderSelection
) => {
	// Existing demand of Open Auction
	if (lockedBidderSelection !== null) {
		return (bidderOptions || []).filter(
			bidder =>
				bidder.id === lockedBidderSelection &&
				hasAvailableSeats(bidder, selectedBidderSeatMap)
		);
	}

	// Deal
	return bidderOptions
		? bidderOptions.filter(
				bidder =>
					(bidder.status === "active" && !selectedBidderSeatMap[bidder.id]) ||
					hasAvailableSeats(bidder, selectedBidderSeatMap)
		  )
		: [];
};

// TODO: Maybe only map active bidders?
const getSelectedBidderSeatMap = fieldValue =>
	fieldValue
		? fieldValue.reduce(
				(selectedBidderSeatMap, bidderConfigRow) => ({
					...selectedBidderSeatMap,
					[bidderConfigRow[BIDDER_ID_KEY]]: bidderConfigRow[BIDDER_SEATS_KEY]
				}),
				{}
		  )
		: [];

const updateBidderExpansionState = (
	expansionConfig,
	expansionConfigKey,
	bidderId,
	expansionState
) => ({
	...expansionConfig,
	[expansionConfigKey]: {
		...expansionConfig[expansionConfigKey],
		[bidderId]: Boolean(expansionState)
	}
});

const getUnclickedSeatOptions = (
	existingSelectedSeats,
	bidderSeatOptions,
	clickedSeatId
) => {
	// If no seats were selected before, filter the full set of options, else filter the existing selected seats
	const options = isEmpty(existingSelectedSeats)
		? bidderSeatOptions
		: existingSelectedSeats;
	return options.filter(option => option.id !== clickedSeatId);
};

function FormFieldBidderConfig(props) {
	const {
		name,
		isCreateResource,
		fieldTypeOptions: { seatTypeKey, wSeatKey },
		formValues: { auctionType },
		crudFormResourceId
	} = props;
	const [field, meta, helpers] = useField({ name });
	const [selectedInactiveBidders, setSelectedInactiveBidders] = useState([]);

	const {
		values: { enableDeal },
		setFieldValue
	} = useFormikContext();
	const showError =
		meta.error && typeof meta.error === "string" && meta.touched;

	useEffect(() => {
		const detectedErrors = [];
		if (typeof meta.error !== "string" && meta.error?.fieldError) {
			detectedErrors.push(meta.error.fieldError);
		}

		if (typeof meta.error !== "string" && meta.error?.rowErrors) {
			detectedErrors.push("Please remove inactive bidders and seats");

			const rowErrorIndexes = Object.keys(meta.error.rowErrors).map(parseFloat);
			const inactiveBidderIds = rowErrorIndexes.map(
				i => field.value[i].rtbBidderId
			);
			setSelectedInactiveBidders(inactiveBidderIds);
		}

		if (detectedErrors.length) {
			helpers.setError(detectedErrors.join(" - "));
		}
	}, [helpers, meta.error, field.value]);

	const [expansionConfig, setExpansionConfig] = useState({
		available: {},
		selected: {}
	});

	const [lockedBidderSelection, setLockedBidderSelection] = useState(null);
	useEffect(() => {
		if (
			lockedBidderSelection === null &&
			field.value[0] &&
			!enableDeal &&
			!isCreateResource
		) {
			setLockedBidderSelection(field.value[0][BIDDER_ID_KEY]);
		}
	}, [enableDeal, isCreateResource, field.value, lockedBidderSelection]);

	const onClickExpansionIcon = useCallback(
		expansionConfigKey => bidderId =>
			setExpansionConfig({
				...expansionConfig,
				[expansionConfigKey]: {
					...expansionConfig[expansionConfigKey],
					[bidderId]: !expansionConfig[expansionConfigKey][bidderId]
				}
			}),
		[expansionConfig]
	);

	const selectedBidderSeatMap = useMemo(
		() => getSelectedBidderSeatMap(field.value),
		[field.value]
	);

	const [bidderOptionsData] = useResource(
		`manage/programmatic-demand/${crudFormResourceId || "INIT"}/bidder-seats`,
		Operations.LIST
	);
	const sortedBidderOptionsData = useMemo(
		() =>
			bidderOptionsData
				? stableSort(bidderOptionsData, getComparator("asc", "name", false))
				: null,
		[bidderOptionsData]
	);

	const bidderSeatOptions = useMemo(
		() =>
			sortedBidderOptionsData
				? sortedBidderOptionsData.reduce(
						(seatOptionsCache, { id, rtbBidderSeats }) => ({
							...seatOptionsCache,
							[id]: stableSort(
								rtbBidderSeats || [],
								getComparator("asc", "seatName", false)
							)
						}),
						{}
				  )
				: {},
		[sortedBidderOptionsData]
	);

	const selectedBidderOptions = useMemo(
		() => getSelectedBidderOptions(sortedBidderOptionsData, field.value),
		[sortedBidderOptionsData, field.value]
	);

	const availableBidderOptions = useMemo(
		() =>
			getAvailableBidderOptions(
				sortedBidderOptionsData,
				selectedBidderSeatMap,
				lockedBidderSelection
			),
		[sortedBidderOptionsData, selectedBidderSeatMap, lockedBidderSelection]
	);

	const addBidder = useCallback(
		bidder => {
			const { id } = bidder;
			const existingBidderIndex = field.value.findIndex(
				bidderRow => bidderRow[BIDDER_ID_KEY] === id
			);
			const updatedBidderRow = {
				[BIDDER_ID_KEY]: id,
				[BIDDER_SEATS_KEY]: []
			};
			let newFieldValue;
			if (existingBidderIndex !== -1) {
				// Replace at the index
				newFieldValue = [...field.value];
				newFieldValue.splice(existingBidderIndex, 1, updatedBidderRow);
			} else {
				// Add to the end
				newFieldValue = [...field.value, updatedBidderRow];
			}
			helpers.setValue(newFieldValue);
			setFieldValue(seatTypeKey, wSeatKey);
			selectedBidderSeatMap[id] = [];
		},
		[
			field.value,
			helpers,
			setFieldValue,
			seatTypeKey,
			wSeatKey,
			selectedBidderSeatMap
		]
	);

	const removeBidder = useCallback(
		bidder => {
			const { id } = bidder;
			helpers.setValue(
				field.value.filter(({ [BIDDER_ID_KEY]: bidderId }) => bidderId !== id)
			);
			// Reset the expansion state for the selected bidder list
			setExpansionConfig(
				updateBidderExpansionState(expansionConfig, "selected", id, false)
			);

			// Removing inactive bidder from Selected bidder list
			setSelectedInactiveBidders(
				selectedInactiveBidders.filter(bidderId => bidderId !== id)
			);
		},
		[field.value, helpers, expansionConfig, selectedInactiveBidders]
	);

	const addSeat = useCallback(
		seat => {
			const seatBidderId = seat.bidderId;
			const existingBidderIndex = field.value.findIndex(
				bidderRow => bidderRow[BIDDER_ID_KEY] === seatBidderId
			);
			// Get existing bidder row or create a new one
			const updatedBidderRow =
				existingBidderIndex !== -1
					? field.value[existingBidderIndex]
					: { [BIDDER_ID_KEY]: seatBidderId, [BIDDER_SEATS_KEY]: [] };
			// Add seat to end of bidder seats array
			updatedBidderRow[BIDDER_SEATS_KEY] = [
				...updatedBidderRow[BIDDER_SEATS_KEY],
				seat
			];
			let newFieldValue;
			// Replace bidder row if it exists
			if (existingBidderIndex !== -1) {
				newFieldValue = [...field.value];
				newFieldValue.splice(existingBidderIndex, 1, updatedBidderRow);
			} else {
				// Else add new row to end of list
				newFieldValue = [...field.value, updatedBidderRow];
			}
			helpers.setValue(newFieldValue);
			setExpansionConfig(
				updateBidderExpansionState(
					expansionConfig,
					"selected",
					seatBidderId,
					true
				)
			);
		},
		[field.value, helpers, expansionConfig]
	);

	const removeSeat = useCallback(
		seat => {
			const clickedSeatId = seat.id;
			const seatBidderId = seat.bidderId;
			// Get existing bidder row seats
			const modifiedBidderRow = field.value.find(
				({ [BIDDER_ID_KEY]: bidderId }) => bidderId === seatBidderId
			);
			const existingSeats = modifiedBidderRow[BIDDER_SEATS_KEY];
			const unclickedSeatOptions = getUnclickedSeatOptions(
				existingSeats,
				bidderSeatOptions[seatBidderId],
				clickedSeatId
			);
			// If it's the last seat to be removed, remove the whole bidder
			if (existingSeats.length === 1 || isEmpty(unclickedSeatOptions)) {
				removeBidder({ id: seatBidderId });
				return;
			}
			helpers.setValue(
				field.value.map(bidderRow => {
					const isModifiedRow = bidderRow === modifiedBidderRow;
					// Set the new bidder row value
					if (isModifiedRow) {
						return {
							...bidderRow,
							[BIDDER_SEATS_KEY]: unclickedSeatOptions
						};
					}
					// Non-modified rows are mapped as-is
					return bidderRow;
				})
			);
		},
		[field.value, helpers, bidderSeatOptions, removeBidder]
	);

	const clearAll = useCallback(() => {
		helpers.setValue([]);
	}, [helpers]);

	const [searchQueryValue, setSearchQueryValue] = useState("");
	const selectedBiddersAriaLabelId = "selected-bidders-title";
	const shouldDisableBidderSelection =
		(!enableDeal && field.value?.length > 0) ||
		(enableDeal &&
			auctionType === AUCTION_TYPE_AGREED_UPON_DEAL_PRICE &&
			field.value?.length > 0);
	const enabledBidders = shouldDisableBidderSelection
		? field.value.reduce(
				(aggregator, fieldValue) => ({
					...aggregator,
					[fieldValue[BIDDER_ID_KEY]]: true
				}),
				{}
		  )
		: null;
	return (
		<>
			<Typography sx={{ pl: 2, pb: 1 }}>{lc.FIELD_LABEL_BIDDERS}:</Typography>
			<Tooltip
				placement="top"
				title="All selected bidders and/or seats will be sent or excluded from bid requests depending on Seat Type.  If Open Auction, only a single bidder may be selected."
			>
				<Grid container spacing={MUI_GRID_CONTAINER_SPACING}>
					<Grid item xs={6}>
						<Paper elevation={SELECTION_LIST_PAPER_ELEVATION}>
							<Toolbar sx={{ pl: 1.75 }} variant="dense">
								<SearchTextField
									searchQueryValue={searchQueryValue}
									onSearchQueryValueChange={setSearchQueryValue}
								/>
							</Toolbar>
							<Divider />
							<List
								dense
								sx={theme => getSelectionListSx(theme)}
								aria-label="available bidders"
							>
								{availableBidderOptions &&
									availableBidderOptions
										.filter(availableBidderOption =>
											availableBidderOption.name
												.toLowerCase()
												.includes(searchQueryValue.toLowerCase())
										)
										.map(bidder => (
											<BidderListItem
												key={bidder.id}
												bidder={bidder}
												bidderSeatOptions={getAvailableSeatOptions(
													bidderSeatOptions[bidder.id],
													selectedBidderSeatMap[bidder.id]
												)}
												expanded={expansionConfig.available[bidder.id]}
												onClickExpansionIcon={onClickExpansionIcon("available")}
												selectBidderIcon={IconAdd}
												onSelectBidder={
													(shouldDisableBidderSelection &&
														!enabledBidders[bidder.id]) ||
													(selectedBidderSeatMap[bidder.id] &&
														isEmpty(selectedBidderSeatMap[bidder.id]))
														? undefined
														: addBidder
												}
												onSelectSeat={
													shouldDisableBidderSelection &&
													!enabledBidders[bidder.id]
														? undefined
														: addSeat
												}
												onSelectActionLabel={lc.BUTTON_LABEL_ADD}
											/>
										))}
							</List>
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper
							elevation={SELECTION_LIST_PAPER_ELEVATION}
							sx={showError ? selectedBiddersErrorSx : undefined}
						>
							<Toolbar
								sx={{ pr: 1, ...(showError ? selectedBiddersErrorSx : {}) }}
								variant="dense"
							>
								<Typography
									sx={{
										flexGrow: 1,
										color: showError ? "error.main" : undefined
									}}
									id={selectedBiddersAriaLabelId}
								>
									{lc.TOOLBAR_TITLE_SELECED_BIDDERS}
								</Typography>
								<Button onClick={clearAll} color="inherit">
									{lc.BUTTON_LABEL_CLEAR_ALL}
								</Button>
							</Toolbar>
							<Divider />
							<List
								dense
								sx={theme => getSelectionListSx(theme)}
								aria-labelledby={selectedBiddersAriaLabelId}
							>
								{selectedBidderOptions &&
									selectedBidderOptions.map(bidder => {
										return (
											<BidderListItem
												key={bidder.id}
												bidder={bidder}
												bidderSeatOptions={selectedBidderSeatMap[bidder.id]}
												expanded={expansionConfig.selected[bidder.id]}
												onClickExpansionIcon={onClickExpansionIcon("selected")}
												selectBidderIcon={IconClose}
												onSelectBidder={removeBidder}
												onSelectSeat={removeSeat}
												onSelectActionLabel="remove"
												showError={
													(selectedInactiveBidders &&
														selectedInactiveBidders.includes(bidder.id)) ||
													!bidder.accessAllowed
												}
											/>
										);
									})}
							</List>
						</Paper>
						{showError && (
							<FormHelperText sx={{ pr: 3 }} error>
								{meta.error}
							</FormHelperText>
						)}
					</Grid>
				</Grid>
			</Tooltip>
		</>
	);
}

FormFieldBidderConfig.propTypes = {
	name: PropTypes.string.isRequired,
	isCreateResource: PropTypes.bool.isRequired,
	fieldTypeOptions: PropTypes.shape({
		seatTypeKey: PropTypes.string.isRequired,
		wSeatKey: PropTypes.string.isRequired
	}).isRequired,
	formValues: PropTypes.shape().isRequired,
	crudFormResourceId: PropTypes.string
};
FormFieldBidderConfig.defaultProps = {
	crudFormResourceId: null
};

export const ValidationSchema = Yup.array()
	.of(
		Yup.object({
			[BIDDER_ID_KEY]: Yup.string(),
			[BIDDER_SEATS_KEY]: Yup.array().of(Yup.string())
		})
	)
	.min(1, lc.VALIDATION_MESSAGES.MIN_ONE_BIDDER)
	.when("enableDeal", {
		is: enableDeal => !enableDeal,
		then: Yup.array().max(
			1,
			lc.VALIDATION_MESSAGES.MAX_ONE_BIDDER_WHEN_OPEN_AUCTION
		)
	})
	.when(["enableDeal", "auctionType"], {
		is: (enableDeal, auctionType) =>
			enableDeal && auctionType === AUCTION_TYPE_AGREED_UPON_DEAL_PRICE,
		then: Yup.array().max(
			1,
			lc.VALIDATION_MESSAGES.MAX_ONE_BIDDER_WHEN_FIXED_PRICE_DEAL
		)
	});

export default FormFieldBidderConfig;
