export default {
	TAB_LABEL_SETTINGS: "Settings",
	TAB_LABEL_PLACEMENTS: "Placements",
	PUBLISHER_ID_LABEL: "Publisher Name",
	PUBLISHER_ID_TOOLTIP: "Select the Publisher which this Site belongs to.",
	SITE_NAME_LABEL: "Site Name",
	STATUS_LABEL: "Status",
	STATUS_OPTION_LABEL_ACTIVE: "Active",
	STATUS_OPTION_LABEL_INACTIVE: "Inactive",
	DEFAULT_FLOOR_LABEL: "Default Floor",
	DEFAULT_FLOOR_TOOLTIP:
		"Used to set the minimum Media CPM which can be aligned to Placements within this Site. If a Placement, belonging to this Site, has a different floor, then the Site’s floor will be overridden to match that of the Placement.",
	REVENUE_SHARE_LABEL: "Revenue Share",
	DEFAULT_REVENUE_SHARE_TOOLTIP:
		"Percentage of gross revenue earned by the Site.",
	URL_LABEL: "URL",
	URL_FIELD_TOOLTIP: "Website domain or URL."
};
