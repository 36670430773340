import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useField, useFormikContext } from "formik";
import { Box, Grid } from "@mui/material";

import FormFieldSwitch from "../FormFieldSwitch/FormFieldSwitch";
import FormFieldSelect from "../FormFieldSelect/FormFieldSelect";
import FormFieldNumber from "../FormFieldNumber/FormFieldNumber";
import FormFieldMultiselect from "../FormFieldMultiselect/FormFieldMultiselect";
import localeContent from "./localeContent";
import commonLocaleContent from "../../screens/commonLocaleContent";
import { MAXIMUM_DB_VALUE } from "../../config/constants";

const ENABLED_KEY = "enabled";
const CUSTOM_SIZE_ENABLED_KEY = "customSizeEnabled";
const SIZE_TARGETING_KEY = "sizeTargeting";
const CUSTOM_SIZE_WIDTH_MIN_KEY = "customSizeWidthMin";
const CUSTOM_SIZE_WIDTH_MAX_KEY = "customSizeWidthMax";
const CUSTOM_SIZE_HEIGHT_MIN_KEY = "customSizeHeightMin";
const CUSTOM_SIZE_HEIGHT_MAX_KEY = "customSizeHeightMax";

const CUSTOM_SIZE_FIELD_NAMES = [
	CUSTOM_SIZE_WIDTH_MIN_KEY,
	CUSTOM_SIZE_WIDTH_MAX_KEY,
	CUSTOM_SIZE_HEIGHT_MIN_KEY,
	CUSTOM_SIZE_HEIGHT_MAX_KEY
];

const AT_LEAST_ONE_FIELD_REQUIRED_ERROR_CODE =
	"{AT_LEAST_ONE_FIELD_REQUIRED_ERROR}";

const SIZE_TARGETING_TYPE_OPTIONS = [
	{
		value: false,
		label: localeContent.SIZE_TARGETING_TYPE.STANDARD_LABEL
	},
	{
		value: true,
		label: localeContent.SIZE_TARGETING_TYPE.CUSTOM_LABEL
	}
];

const STANDARD_SIZE_OPTIONS = [
	{
		id: "small",
		name: localeContent.STANDARD_SIZE.SMALL_LABEL
	},
	{
		id: "medium",
		name: localeContent.STANDARD_SIZE.MEDIUM_LABEL
	},
	{
		id: "large",
		name: localeContent.STANDARD_SIZE.LARGE_LABEL
	}
];

const getAllFieldsTouched = (touchedData = {}, fieldNames = []) =>
	fieldNames.every(fieldName => touchedData[fieldName]);

const getAtLeastOneFieldRequiredError = (fieldTouched, fieldError) => {
	const allCustomFieldsTouched = getAllFieldsTouched(
		fieldTouched,
		CUSTOM_SIZE_FIELD_NAMES
	);
	return allCustomFieldsTouched &&
		fieldError === AT_LEAST_ONE_FIELD_REQUIRED_ERROR_CODE
		? localeContent.AT_LEAST_ONE_FIELD_REQUIRED_ERROR_STRING
		: null;
};

// TODO: Test this
const getCustomRangeValidationError = (fieldTouched, fieldError, maxKey) =>
	fieldTouched[maxKey] && fieldError[maxKey];

function FormFieldSizeTargeting(props) {
	const { name } = props;
	const [field, meta] = useField(name);
	const formikData = field.value || {};

	const { touched } = useFormikContext();

	const atLeastOneFieldRequiredError = getAtLeastOneFieldRequiredError(
		touched[name],
		meta.error
	);

	return (
		<>
			<Box sx={{ mb: formikData[ENABLED_KEY] ? 1 : undefined }}>
				<FormFieldSwitch
					id={`${name}.${ENABLED_KEY}`}
					name={`${name}.${ENABLED_KEY}`}
					label={localeContent.SIZE_TARGETING_LABEL}
				/>
			</Box>
			{formikData[ENABLED_KEY] && (
				<>
					<Grid container spacing={1}>
						<Grid item xs={3}>
							<FormFieldSelect
								id={`${name}.${CUSTOM_SIZE_ENABLED_KEY}`}
								name={`${name}.${CUSTOM_SIZE_ENABLED_KEY}`}
								label={localeContent.TYPE_LABEL}
								optionsConfig={{ options: SIZE_TARGETING_TYPE_OPTIONS }}
							/>
						</Grid>
						{!formikData[CUSTOM_SIZE_ENABLED_KEY] && (
							<Grid item xs={6}>
								<FormFieldMultiselect
									id={`${name}.${SIZE_TARGETING_KEY}`}
									name={`${name}.${SIZE_TARGETING_KEY}`}
									label={localeContent.STANDARD_SIZE_OPTIONS_LABEL}
									optionsConfig={{
										options: STANDARD_SIZE_OPTIONS,
										sortOptionsByName: false
									}}
									required
								/>
							</Grid>
						)}
					</Grid>
					{formikData[CUSTOM_SIZE_ENABLED_KEY] && (
						<Grid container spacing={1}>
							<Grid item xs={3}>
								<FormFieldNumber
									id={`${name}.${CUSTOM_SIZE_WIDTH_MIN_KEY}`}
									name={`${name}.${CUSTOM_SIZE_WIDTH_MIN_KEY}`}
									label={localeContent.CUSTOM_SIZE_WIDTH_MIN_LABEL}
									errorOverride={atLeastOneFieldRequiredError}
									required
								/>
							</Grid>
							<Grid item xs={3}>
								<FormFieldNumber
									id={`${name}.${CUSTOM_SIZE_WIDTH_MAX_KEY}`}
									name={`${name}.${CUSTOM_SIZE_WIDTH_MAX_KEY}`}
									label={localeContent.CUSTOM_SIZE_WIDTH_MAX_LABEL}
									errorOverride={atLeastOneFieldRequiredError}
									required
								/>
							</Grid>
							<Grid item xs={3}>
								<FormFieldNumber
									id={`${name}.${CUSTOM_SIZE_HEIGHT_MIN_KEY}`}
									name={`${name}.${CUSTOM_SIZE_HEIGHT_MIN_KEY}`}
									label={localeContent.CUSTOM_SIZE_HEIGHT_MIN_LABEL}
									errorOverride={atLeastOneFieldRequiredError}
									required
								/>
							</Grid>
							<Grid item xs={3}>
								<FormFieldNumber
									id={`${name}.${CUSTOM_SIZE_HEIGHT_MAX_KEY}`}
									name={`${name}.${CUSTOM_SIZE_HEIGHT_MAX_KEY}`}
									label={localeContent.CUSTOM_SIZE_HEIGHT_MAX_LABEL}
									errorOverride={atLeastOneFieldRequiredError}
									required
								/>
							</Grid>
						</Grid>
					)}
				</>
			)}
		</>
	);
}

FormFieldSizeTargeting.propTypes = {
	name: PropTypes.string.isRequired
};

FormFieldSizeTargeting.defaultProps = {};

Yup.addMethod(
	Yup.object,
	"atLeastOneFieldRequired",
	function atLeastOneFieldRequired(propertyNames, message) {
		return this.test(
			"atLeastOneFieldRequired",
			message,
			function testAtLeastOneFieldRequired(value) {
				if (!value[ENABLED_KEY] || !value[CUSTOM_SIZE_ENABLED_KEY]) {
					return true;
				}
				if (!propertyNames.some(el => Boolean(value[el]))) {
					throw this.createError({
						path: this.path,
						message
					});
				}

				return true;
			}
		);
	}
);

Yup.addMethod(
	Yup.number,
	"customRangeValidation",
	function customRangeValidation(minKey, message) {
		return this.test(
			"customRangeValidation",
			message,
			function testCustomRangeValidation(value) {
				const { [minKey]: minValue } = this.parent;
				if (minValue > value) {
					throw this.createError({
						path: this.path,
						message
					});
				}

				return true;
			}
		);
	}
);

export const validationSchema = Yup.object({
	[SIZE_TARGETING_KEY]: Yup.array().when(
		[CUSTOM_SIZE_ENABLED_KEY, ENABLED_KEY],
		{
			is: (customSizeEnabled, enabled) => enabled && !customSizeEnabled,
			then: Yup.array().min(1, commonLocaleContent.REQUIRED_FIELD_WARNING)
		}
	),
	[CUSTOM_SIZE_WIDTH_MIN_KEY]: Yup.number().when(
		[ENABLED_KEY, CUSTOM_SIZE_ENABLED_KEY],
		{
			is: (enabled, customSizeEnabled) => enabled && customSizeEnabled,
			then: Yup.number()
				.min(0, localeContent.NON_NEGATIVE_VALUE_WARNING)
				.max(
					MAXIMUM_DB_VALUE,
					localeContent.MAXIMUM_VALUE_WARNING({ maxValue: MAXIMUM_DB_VALUE })
				)
		}
	),
	[CUSTOM_SIZE_WIDTH_MAX_KEY]: Yup.number().when(
		[ENABLED_KEY, CUSTOM_SIZE_ENABLED_KEY],
		{
			is: (enabled, customSizeEnabled) => enabled && customSizeEnabled,
			then: Yup.number()
				.min(0, localeContent.NON_NEGATIVE_VALUE_WARNING)
				.max(
					MAXIMUM_DB_VALUE,
					localeContent.MAXIMUM_VALUE_WARNING({ maxValue: MAXIMUM_DB_VALUE })
				)
				.customRangeValidation(
					CUSTOM_SIZE_WIDTH_MIN_KEY,
					localeContent.CUSTOM_WIDTH_RANGE_VALIDATION_ERROR_STRING
				)
		}
	),
	[CUSTOM_SIZE_HEIGHT_MIN_KEY]: Yup.number().when(
		[ENABLED_KEY, CUSTOM_SIZE_ENABLED_KEY],
		{
			is: (enabled, customSizeEnabled) => enabled && customSizeEnabled,
			then: Yup.number()
				.min(0, localeContent.NON_NEGATIVE_VALUE_WARNING)
				.max(
					MAXIMUM_DB_VALUE,
					localeContent.MAXIMUM_VALUE_WARNING({ maxValue: MAXIMUM_DB_VALUE })
				)
		}
	),
	[CUSTOM_SIZE_HEIGHT_MAX_KEY]: Yup.number().when(
		[ENABLED_KEY, CUSTOM_SIZE_ENABLED_KEY],
		{
			is: (enabled, customSizeEnabled) => enabled && customSizeEnabled,
			then: Yup.number()
				.min(0, localeContent.NON_NEGATIVE_VALUE_WARNING)
				.max(
					MAXIMUM_DB_VALUE,
					localeContent.MAXIMUM_VALUE_WARNING({ maxValue: MAXIMUM_DB_VALUE })
				)
				.customRangeValidation(
					CUSTOM_SIZE_HEIGHT_MIN_KEY,
					localeContent.CUSTOM_HEIGHT_RANGE_VALIDATION_ERROR_STRING
				)
		}
	)
}).atLeastOneFieldRequired(
	CUSTOM_SIZE_FIELD_NAMES,
	AT_LEAST_ONE_FIELD_REQUIRED_ERROR_CODE
);

const getInitEnabled = formData => {
	const isStandardSizeDefined =
		!formData[CUSTOM_SIZE_ENABLED_KEY] &&
		(formData[SIZE_TARGETING_KEY] || []).length > 0;
	const isCustomSizeDefined =
		formData[CUSTOM_SIZE_ENABLED_KEY] &&
		CUSTOM_SIZE_FIELD_NAMES.some(
			customSizeFieldName => formData[customSizeFieldName]
		);
	return (
		formData[CUSTOM_SIZE_ENABLED_KEY] !== undefined &&
		(isStandardSizeDefined || isCustomSizeDefined)
	);
};

export const transformInitData = formData => ({
	[ENABLED_KEY]: getInitEnabled(formData),
	[CUSTOM_SIZE_ENABLED_KEY]: Boolean(formData[CUSTOM_SIZE_ENABLED_KEY]),
	[SIZE_TARGETING_KEY]: formData[SIZE_TARGETING_KEY] || [],
	[CUSTOM_SIZE_WIDTH_MIN_KEY]: formData[CUSTOM_SIZE_WIDTH_MIN_KEY],
	[CUSTOM_SIZE_WIDTH_MAX_KEY]: formData[CUSTOM_SIZE_WIDTH_MAX_KEY],
	[CUSTOM_SIZE_HEIGHT_MIN_KEY]: formData[CUSTOM_SIZE_HEIGHT_MIN_KEY],
	[CUSTOM_SIZE_HEIGHT_MAX_KEY]: formData[CUSTOM_SIZE_HEIGHT_MAX_KEY]
});

export const transformSubmitData = data => {
	const { [ENABLED_KEY]: enabled, ...submissionData } = data;
	if (!enabled) {
		return {
			[CUSTOM_SIZE_ENABLED_KEY]: false
		};
	}
	return {
		...submissionData
	};
};

export const incrementBarTitleErrorCount = (
	errorFieldName,
	errors,
	touched
) => {
	const fieldError = errors[errorFieldName];
	const fieldTouched = touched[errorFieldName];
	if (!fieldError) {
		return false;
	}
	if (Object.prototype.hasOwnProperty.call(fieldError, SIZE_TARGETING_KEY)) {
		return (
			fieldTouched &&
			Object.prototype.hasOwnProperty.call(fieldTouched, SIZE_TARGETING_KEY)
		);
	}
	return (
		getAtLeastOneFieldRequiredError(fieldTouched, fieldError) ||
		getCustomRangeValidationError(
			fieldTouched,
			fieldError,
			CUSTOM_SIZE_WIDTH_MAX_KEY
		) ||
		getCustomRangeValidationError(
			fieldTouched,
			fieldError,
			CUSTOM_SIZE_HEIGHT_MAX_KEY
		)
	);
};

export default FormFieldSizeTargeting;
