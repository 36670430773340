import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import Switch from "./Switch";

function FormFieldSwitch(props) {
	const { id, label, name, disabled } = props;
	const [field, meta] = useField(name);
	const showError = Boolean(meta.touched && meta.error);
	const helperText = showError ? meta.error : "";

	return (
		<Switch
			id={id}
			label={label}
			name={name}
			value={field.value}
			showError={showError}
			helperText={helperText}
			onChange={field.onChange}
			onBlur={field.onBlur}
			disabled={disabled}
		/>
	);
}

FormFieldSwitch.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool
};
FormFieldSwitch.defaultProps = {
	disabled: false
};

export default FormFieldSwitch;
