export default {
	FILE_NAME: {
		LABEL: "File Name",
		TOOLTIP: "Name of the saved file when downloaded",
		VALIDATION:
			"File name must only consist of: letters, numbers, dashes (-), and underscores(_)"
	},
	TIMEZONE: {
		LABEL: "Timezone",
		TOOLTIP: "Defines the timezone that the data should be based on"
	},
	LOOK_BACK_PERIOD: {
		LABEL: "Look Back Period",
		TOOLTIP:
			"Determines the timeframe of the data which will be returned in the report. If Custom is selected you must define the previous number of days from which data should be returned."
	},
	CUSTOM_RANGE: {
		LABEL: "Custom Range",
		VALIDATION: "Value must be between 1 and 90"
	},
	REPEATS: {
		LABEL: "Repeats",
		TOOLTIP: "Defines how often the scheduled report will be sent."
	},
	REPEATS_ON: {
		LABEL: "Repeats On",
		TOOLTIP: "Defines the day or days of the week that the report will be."
	},
	DAY_OF_MONTH: {
		LABEL: "Day of Month",
		TOOLTIP: "Defines how often the scheduled report will be sent."
	},
	SEND_TO_ME: {
		LABEL: "Send to Me",
		TOOLTIP:
			"If selected this report will be sent to the logged-in email address."
	},
	ADD_RECIPIENTS: {
		LABEL: "Add Recipients",
		TOOLTIP:
			'These are the email addresses that will receive your report. You can add multiple email addresses by pressing "Enter" after each address.',
		VALIDATION:
			"At least one email address is required if Send to Me is not selected"
	},
	REPORT_TYPE: {
		LABEL: "Report Type",
		TOOLTIP:
			"Change Report Type to schedule a report with either Network, Campaign, or RTB data. Available Metrics, Slices, and Filters will vary by selection. If the selection is changed previously selected Metrics, Slices and Filters will be removed."
	},
	METRICS: {
		LABEL: "Metrics",
		TOOLTIP:
			"Selected metrics will define the data that is returned in the report."
	},
	DIMENSIONS: {
		LABEL: "Dimensions"
	},
	INCLUDE_HEADER: {
		LABEL: "Include Header"
	},
	START_DATE: {
		LABEL: "Start Date",
		TOOLTIP:
			"Input the first day that this report should be sent to the listed recipients."
	},
	END_DATE: {
		LABEL: "End Date",
		TOOLTIP:
			"Input the last day that this report should be sent to the listed recipients."
	},
	TAB_LABEL_DATA: "Data",
	VALID_EMAIL_WARNING: email => `${email} is invalid`,
	DAYS_OPTIONS: [
		{ id: "SUNDAY", name: "Sunday" },
		{ id: "MONDAY", name: "Monday" },
		{ id: "TUESDAY", name: "Tuesday" },
		{ id: "WEDNESDAY", name: "Wednesday" },
		{ id: "THURSDAY", name: "Thursday" },
		{ id: "FRIDAY", name: "Friday" },
		{ id: "SATURDAY", name: "Saturday" }
	]
};
