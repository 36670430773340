import {
	COMPANY_ID_QUERY_PARAM,
	SERVER_URL_WITH_VERSION
} from "../config/constants";
import { ROUTER_API_URL } from "../config/ApiUrlConfig";

export const getAuthenticatedEndpoint = endpoint => {
	return `${SERVER_URL_WITH_VERSION}/${endpoint}`;
};

export const routersEndpoint = endpoint => {
	return `${ROUTER_API_URL}/api/v1/${endpoint}`;
};

const companyIdRegEx = new RegExp(
	`([?&])${COMPANY_ID_QUERY_PARAM}=.*?(&|$)`,
	"i"
);

export const addHashToURL = hash => {
	window.location.href = `${window.location.href}#${hash}`;
};

// created it specific to company id so that reg ex is not be created for every link considering performance
// following method replaces/adds/updates the COMPANY_ID_QUERY_PARAM value to the passed
export const updateCompanyIdQueryStringParameter = (uri, companyId) => {
	// as conversion of companyId happens somewhere it could be 0 or NaN too
	if ([0, NaN, undefined, null].includes(companyId)) return uri;

	const separator = uri.indexOf("?") !== -1 ? "&" : "?";
	if (uri.match(COMPANY_ID_QUERY_PARAM)) {
		return uri.replace(
			companyIdRegEx,
			`$1${COMPANY_ID_QUERY_PARAM}=${companyId}$2`
		);
	}
	return `${uri + separator + COMPANY_ID_QUERY_PARAM}=${companyId}`;
};

export const makeDashboardUrl = path =>
	`${window.location.origin}/dashboard/${path}`;

export default {
	routersEndpoint
};
