import { useLocation } from "react-router-dom";

/**
 * Implementation from the react router docs https://reactrouter.com/web/example/query-parameters
 * For documentation on how to use URLSearchParams see:
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 */
function useQueryParam(paramName) {
	return new URLSearchParams(useLocation().search).get(paramName);
}

export default useQueryParam;
