export default {
	GLOBAL_BLACKLIST_NAME: "Global Blacklist",
	GLOBAL_BLACKLIST_TITLE: "Edit Global Blocklist",
	TYPE_LABEL: "Type",
	GLOBAL_BLACKLIST_TYPE_OPTIONS: {
		DOMAIN_LABEL: "Domain",
		ADVERTISER_DOMAIN_LABEL: "Advertiser Domain",
		APP_NAME_LABEL: "App Name",
		APP_BUNDLE_ID_LABEL: "App Bundle ID"
	},
	VALUE_REQUIRED_MESSAGE: "A value is required",
	DOMAIN_INVALID_MESSAGE: "Must be a valid domain",
	FILE_BULK_UPLOAD_BUTTON_LABEL: "Bulk Upload",
	BLACKLIST_ADDED_SUCCESS_MESSAGE: ({ type, typeValue }) =>
		`${type} ${typeValue} added.`,
	BLACKLIST_DELETED_SUCCESS_MESSAGE: ({ type, typeValue }) =>
		`${type} ${typeValue} deleted.`,
	BLACKLIST_FILE_UPLOAD_SUCCESS_MESSAGE: ({
		validCount,
		duplicateCount,
		invalidCount
	}) =>
		`Upload complete. Inserted ${validCount} Duplicates ${duplicateCount} Invalid ${invalidCount}`,
	DELETE_MODAL: {
		TITLE: "Delete Traffic Filter",
		DESCRIPTION:
			"You are about to delete this Traffic Filter. This action can not be undone. Do you wish to continue?",
		ACTION_BUTTON_LABEL_CONFIRM: "Delete"
	},
	ADD_GLOBAL_BLACKLIST_BUTTON_LABEL: "add global blacklist"
};
