/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useTable, useSortBy } from "react-table";

import TableContainer from "@mui/material/TableContainer";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ReactTableSortableHeaderCell from "../ReactTableSortableHeaderCell/ReactTableSortableHeaderCell";
import MediaFileTableRowActionMenu from "./MediaFileTableRowActionMenu";
import { MUI_PADDING_NORMAL } from "../../config/constants";

export const TABLE_ROW_TEST_ID = "media-file-table-row";

function MediaFileTable(props) {
	const { columns, data, onDeleteRow, accessibleTableLabel } = props;
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows
	} = useTable(
		{
			columns,
			data,
			disableSortRemove: true,
			autoResetSortBy: false
		},
		useSortBy,
		hooks => {
			hooks.visibleColumns.push(cols => [
				{
					id: "selection",
					tableCellPadding: "checkbox",
					Cell: MediaFileTableRowActionMenu
				},
				...cols
			]);
		}
	);

	return (
		<TableContainer>
			<MaUTable
				{...getTableProps()}
				aria-label={accessibleTableLabel}
				size="small"
				sx={{ minWidth: "960px" }}
			>
				<TableHead>
					{headerGroups.map(headerGroup => (
						<TableRow {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<ReactTableSortableHeaderCell key={column.id} column={column} />
							))}
						</TableRow>
					))}
				</TableHead>
				<TableBody {...getTableBodyProps()}>
					{rows.map(row => {
						prepareRow(row);
						return (
							<TableRow
								{...row.getRowProps()}
								data-testid={`${TABLE_ROW_TEST_ID}-${row.original.id}`}
							>
								{row.cells.map(cell => (
									<TableCell
										{...cell.getCellProps()}
										padding={cell.column.tableCellPadding || MUI_PADDING_NORMAL}
										align={cell.column.align}
									>
										{cell.render("Cell", { onDeleteRow })}
									</TableCell>
								))}
							</TableRow>
						);
					})}
				</TableBody>
			</MaUTable>
		</TableContainer>
	);
}

MediaFileTable.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	data: PropTypes.arrayOf(PropTypes.shape()),
	onDeleteRow: PropTypes.func.isRequired,
	accessibleTableLabel: PropTypes.string.isRequired
};

MediaFileTable.defaultProps = {
	data: []
};

export default MediaFileTable;
