export default {
	COLUMN_DISPLAY_NAME: "Display Name",
	COLUMN_DEFAULT_NAME: "Default Name",
	DISPLAY_NAME: {
		LABEL: "Displayed Distribution Name",
		TOOLTIP: "Changes the name displayed in the Data Distributions table"
	},
	DEFAULT_NAME: {
		LABEL: "Default Distribution Name",
		TOOLTIP: "The name of the distribution as provided by Liveramp"
	},
	ALLOWED_ACCESS: {
		LABEL: "Allowed Access",
		TOOLTIP:
			"Selected companies will be given access to all data segments associated with this distribution"
	},
	SEGMENT: {
		NAME: "Segment Name",
		RESOURCE_NAME: "Segment"
	}
};
