import React from "react";
import PropTypes from "prop-types";
import { lighten } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import IconClose from "@mui/icons-material/Close";
import IconSave from "@mui/icons-material/Save";
import SearchTextField from "../SearchTextField/SearchTextField";
import TableColumnToggleMenu from "../CheckboxMenu/TableColumnToggleMenu";
import Radio from "../FormFieldRadio/Radio";
import localeContent from "./localeContent";

const getToolbarHighlightSx = theme => {
	return theme.palette.mode === "light"
		? {
				color: "secondary.main",
				bgcolor: lighten(theme.palette.primary.light, 0.85)
		  }
		: {
				color: "text.primary",
				bgcolor: "primary.dark"
		  };
};

function EnhancedTableToolbar(props) {
	const {
		numSelected,
		selectionText,
		allColumns,
		globalFilter,
		setGlobalFilter,
		toggleAllRowsSelected,
		tableId,
		tableSelectionOptions,
		tableSelectionValue,
		setTableSelectionValue,
		isSingleTableView,
		onSubmit
	} = props;

	return (
		<Toolbar
			sx={theme => {
				const highlightSx = getToolbarHighlightSx(theme);
				return { px: 0, ...(numSelected > 0 ? highlightSx : {}) };
			}}
		>
			{numSelected > 0 ? (
				<>
					<Typography
						sx={{ pl: 3, flex: "1 1 100%" }}
						color="inherit"
						variant="subtitle1"
						component="div"
					>
						{selectionText}: {numSelected}
					</Typography>
					<Tooltip title={localeContent.SUBMIT_ALIGNMENTS_BUTTON_LABEL}>
						<IconButton
							onClick={() => onSubmit()}
							aria-label={localeContent.SUBMIT_ALIGNMENTS_BUTTON_LABEL}
						>
							<IconSave />
						</IconButton>
					</Tooltip>
					<Tooltip title={localeContent.CLEAR_ALIGNMENTS_BUTTON_LABEL}>
						<IconButton
							onClick={() => toggleAllRowsSelected(false)}
							aria-label={localeContent.CLEAR_ALIGNMENTS_BUTTON_LABEL}
						>
							<IconClose />
						</IconButton>
					</Tooltip>
				</>
			) : (
				<>
					{isSingleTableView && (
						<Radio
							id="table-selection"
							ariaLabel="table selection"
							name="tableSelection"
							options={tableSelectionOptions}
							value={tableSelectionValue}
							onChange={e => setTableSelectionValue(e.target.value)}
						/>
					)}
					<SearchTextField
						searchQueryValue={globalFilter || ""}
						onSearchQueryValueChange={setGlobalFilter}
						id={tableId}
						searchFieldSxExtension={{ pb: 0.5 }}
					/>
					<Box flexGrow={1} flexShrink={2} />
					<TableColumnToggleMenu
						menuId="show-hide-columns"
						menuButtonLabel="show/hide columns menu"
						columnOptions={allColumns.filter(
							column => !column.preventVisibilityToggle
						)}
					/>
				</>
			)}
		</Toolbar>
	);
}
EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
	selectionText: PropTypes.string.isRequired,
	allColumns: PropTypes.arrayOf(PropTypes.shape).isRequired,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func.isRequired,
	toggleAllRowsSelected: PropTypes.func.isRequired,
	tableId: PropTypes.string.isRequired,
	tableSelectionOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	tableSelectionValue: PropTypes.string.isRequired,
	setTableSelectionValue: PropTypes.func.isRequired,
	isSingleTableView: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired
};
EnhancedTableToolbar.defaultProps = {
	globalFilter: undefined,
	isSingleTableView: false
};

export default EnhancedTableToolbar;
