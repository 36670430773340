import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import {
	formatAsDateWithTime,
	getTimezoneString
} from "../../utils/formatters";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";
import localeContent from "./localeContent";

function AuditEvent(props) {
	const { userTimeZone, isFetchingUserSettings } = useContext(
		AuthenticatedUserSettingsContext
	);
	const { auditEvent } = props;

	const { user, auditDateTime, source } = auditEvent;

	let message;
	if (!isFetchingUserSettings) {
		const timeZone = getTimezoneString(userTimeZone);
		const dateString = formatAsDateWithTime(auditDateTime, {
			timeZone
		});
		switch (source) {
			case "human":
				if (!user) {
					break;
				}
				message = localeContent.HUMAN_MESSAGE({
					userName: user.name,
					dateString
				});
				break;
			case "job":
				switch (auditEvent.jobNameEnum) {
					case "campaign_delivery_targeting":
					case "campaign_pacing_realtime":
						message = localeContent.CAMPAIGN_MESSAGE({
							dateString
						});
						break;
					case "notification_expiration":
						message = localeContent.NOTIFICATION_EXPIRATION_MESSAGE({
							dateString
						});
						break;
					default:
				}
				break;
			default:
		}
	}
	return (
		<Typography variant="body1" display="inline-flex">
			{message}
		</Typography>
	);
}

AuditEvent.propTypes = {
	auditEvent: PropTypes.shape()
};

AuditEvent.defaultProps = {
	auditEvent: {}
};

export default AuditEvent;
