// import { useRef } from "react";
import {
	pointerWithin,
	rectIntersection,
	getFirstCollision,
	closestCenter
} from "@dnd-kit/core";
import au from "../../utils/arrayUtils";
import { CONDITIONAL_REPORTING_ITEMS } from "../../screens/ScheduledReports/config";

const getMustBeBeforeItems = (activeId, items = []) => {
	const mustBeBeforeItems = [];
	items.forEach(item => {
		if (CONDITIONAL_REPORTING_ITEMS[activeId]?.mustBeBefore?.includes(item)) {
			mustBeBeforeItems.push(item);
		}
	});
	return mustBeBeforeItems;
};
const getMustBeAfterItems = (activeId, items = []) => {
	const mustBeAfterItems = [];
	items.forEach(item => {
		if (CONDITIONAL_REPORTING_ITEMS[activeId]?.mustBeAfter?.includes(item)) {
			mustBeAfterItems.push(item);
		}
	});
	return mustBeAfterItems;
};

const recentlyMovedToNewContainer = {};
const lastOverId = {};

const dimensionOrderUpdate = (activeId, dragOverId, selectedIds) => {
	// If we are selecting the first option, then there should not be any condion check.
	if (dragOverId === "selected") {
		lastOverId.current = dragOverId;
		return [{ id: lastOverId.current }];
	}
	// If a dimension can be placed before and/or after a specific dimension.
	if (
		CONDITIONAL_REPORTING_ITEMS[activeId]?.mustBeBefore ||
		CONDITIONAL_REPORTING_ITEMS[activeId]?.mustBeAfter
	) {
		const mustBeBeforeItems = getMustBeBeforeItems(activeId, selectedIds);
		const mustBeAfterItems = getMustBeAfterItems(activeId, selectedIds);
		// This condition is checking if a dimension has the items which should be placed before that selected dimension
		if (
			CONDITIONAL_REPORTING_ITEMS[activeId]?.mustBeBefore &&
			selectedIds.indexOf(activeId) >= selectedIds.indexOf(dragOverId) &&
			selectedIds.indexOf(dragOverId) <=
				selectedIds.indexOf(au.getLastItem(mustBeBeforeItems))
		) {
			lastOverId.current =
				selectedIds[selectedIds.indexOf(au.getLastItem(mustBeBeforeItems)) + 1];
			return [{ id: lastOverId.current }];
		}
		// This condition is checking if a dimension has the items which should be placed after that selected dimension
		if (
			CONDITIONAL_REPORTING_ITEMS[activeId]?.mustBeAfter &&
			selectedIds.indexOf(activeId) <= selectedIds.indexOf(dragOverId) &&
			selectedIds.indexOf(dragOverId) >=
				selectedIds.indexOf(au.getFirstItem(mustBeAfterItems))
		) {
			lastOverId.current =
				selectedIds[selectedIds.indexOf(au.getFirstItem(mustBeAfterItems)) - 1];
			return [{ id: lastOverId.current }];
		}
	}
	lastOverId.current = dragOverId;
	return [{ id: lastOverId.current }];
};

const collisionDetection = (
	args,
	sortableItems,
	activeItemSortableId,
	updateItemsOrder
) => {
	if (activeItemSortableId && activeItemSortableId in sortableItems) {
		return closestCenter({
			...args,
			droppableContainers: args.droppableContainers.filter(
				container => container.id in sortableItems
			)
		});
	}

	// Start by finding any intersecting droppable
	const pointerIntersections = pointerWithin(args);
	const intersections =
		pointerIntersections.length > 0
			? // If there are droppables intersecting with the pointer, return those
			  pointerIntersections
			: rectIntersection(args);
	let overId = getFirstCollision(intersections, "id");

	if (overId != null) {
		if (overId in sortableItems) {
			const containerItems = sortableItems[overId];

			// If a container is matched and it contains items (columns 'A', 'B', 'C')
			if (containerItems.length > 0) {
				// Return the closest droppable within that container
				overId = closestCenter({
					...args,
					droppableContainers: args.droppableContainers.filter(
						container =>
							container.id !== overId && containerItems.includes(container.id)
					)
				})[0]?.id;
			}
		}

		updateItemsOrder(args.active.id, overId);
	}

	// When a draggable item moves to a new container, the layout may shift
	// and the `overId` may become `null`. We manually set the cached `lastOverId`
	// to the id of the draggable item that was moved to the new container, otherwise
	// the previous `overId` will be returned which can cause items to incorrectly shift positions
	if (recentlyMovedToNewContainer.current) {
		lastOverId.current = activeItemSortableId;
	}

	// If no droppable is matched, return the last match
	return lastOverId.current ? [{ id: lastOverId.current }] : [];
};

const conditionalItems = availableOptions => {
	const conditionalOptions = availableOptions
		.filter(dimension => dimension.condition)
		.map(dimension => {
			dimension.enabledWith = dimension.condition.enabledWith || [];
			dimension.disabledWhenDateRange =
				dimension.condition.disabledWhenDateRange || [];
			return dimension;
		});
	return conditionalOptions;
};

const deleteDependentIncompatibleItems = (selectedIds, bulkDelete) => {
	if (selectedIds) {
		// commonItems are basically the item which has the enableWith key with config object from the list of selected items.
		const commonItem = selectedIds.find(
			value => CONDITIONAL_REPORTING_ITEMS[value]?.enabledWith
		);
		const enabledWith = CONDITIONAL_REPORTING_ITEMS[commonItem]?.enabledWith;
		if (enabledWith)
			enabledWith.forEach(item => {
				// If any a user is removing the parent key of enableWith object then its dependent child will also be removed
				if (!selectedIds.includes(item) && selectedIds.includes(commonItem)) {
					bulkDelete([commonItem]);
				}
			});
	}
};

export default {
	dimensionOrderUpdate,
	collisionDetection,
	getMustBeBeforeItems,
	getMustBeAfterItems,
	conditionalItems,
	deleteDependentIncompatibleItems
};
