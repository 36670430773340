import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Typography } from "@mui/material";
import { Operations } from "../../hooks/useResource";
import LoadingBackdrop from "../../components/LoadingBackdrop/LoadingBackdrop";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import localeContent from "./localeContent";
import CompletedTableRowActionMenu from "./CompletedReportTableRowActionMenu";
import constants from "./constants";
import CrudDataGrid from "../../components/CrudDataGrid/CrudDataGrid";

const REFRESH_REPORTS_INTERVAL = 10000; // Timer to rerun the report

const RESOURCE_COMPLETED_DELETE_ENDPOINT = "reports/run-report";
const completedReportColumnsConfig = [
	{
		key: "name",
		name: localeContent.REPORT_NAME_LABEL,
		cellDataType: "string"
	},
	{ key: "status", name: localeContent.STATUS_LABEL, cellDataType: "string" },
	{
		key: "createdAt",
		name: localeContent.RUN_DATE_LABEL,
		cellDataType: "date"
	},
	{
		key: "expiresAt",
		name: localeContent.EXPIRY_DATE_LABEL,
		cellDataType: "date"
	}
];

const refreshReportsConfig = {
	preserveDataOnExecute: true
};

function CompletedReport() {
	const { data, error, isLoading, execute: refreshReports } = useResourceAsync(
		"reports/run-reports",
		Operations.LIST,
		refreshReportsConfig
	);

	useEffect(() => {
		refreshReports();
	}, [refreshReports]);

	// Keep polling the completed reports endpoint until we determine that all reports are finished
	useEffect(() => {
		let timer;
		const isReportUnavailable = data?.find(
			item =>
				item.status === constants.reportStatus.PENDING ||
				item.status === constants.reportStatus.RUNNING
		);
		if (isReportUnavailable && !isLoading) {
			timer = setTimeout(() => refreshReports(), REFRESH_REPORTS_INTERVAL);
		}
		return () => {
			clearInterval(timer);
		};
	}, [data, isLoading, refreshReports]);

	const [deletedItems, setDeletedItems] = useState([]);
	const deleteItem = useCallback(
		itemId => setDeletedItems([...deletedItems, itemId]),
		[deletedItems]
	);

	const filteredData = useMemo(() => {
		if (!data) return [];

		return data.filter(row => !deletedItems.includes(row.id));
	}, [data, deletedItems]);

	const columns = useMemo(
		() => [
			{
				key: "actions",
				padding: "checkbox",
				preventSort: true,
				preventVisibilityToggle: true,
				formatter: (value, rowData) => {
					return (
						<CompletedTableRowActionMenu
							rowData={rowData?.row}
							resourceEndpoint={RESOURCE_COMPLETED_DELETE_ENDPOINT}
							deleteItemCallback={deleteItem || null}
							resourceName={localeContent.COMPLETED_REPORT_RESOURCE_NAME}
						/>
					);
				}
			},
			...completedReportColumnsConfig
		],
		[deleteItem]
	);
	return (
		<>
			<LoadingBackdrop isOpen={isLoading} />
			{error ? (
				// This is a temporary solution pending https://beezag.jira.com/browse/CP-790
				<Typography>{error}</Typography>
			) : (
				<CrudDataGrid
					columns={columns}
					filteredData={filteredData}
					paginationConfig={{ defaultRowsPerPage: 5 }}
					heading={localeContent.COMPLETED_REPORTS_TABLE_HEADING}
					gridHeight={450}
					tableTestId="completedReports"
				/>
			)}
		</>
	);
}

export default CompletedReport;
