import React from "react";
import { Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PageTitleBar from "../../components/PageTitleBar/PageTitleBar";
import localeContent from "./localeContent";

import CompletedReport from "./CompletedReport";
import ScheduledReport from "./ScheduledReport";

function MyReportsIndex() {
	return (
		<>
			<PageTitleBar
				barTitle={localeContent.MY_REPORTS_TITLE}
				actionButtonText={localeContent.SCHEDULED_REPORTS_BUTTON_TEXT}
				actionButtonIconComponent={AddIcon}
				actionButtonRoute="/dashboard/scheduled-reports/INIT"
			/>
			<Container maxWidth="xl">
				<ScheduledReport />
				<CompletedReport />
			</Container>
		</>
	);
}

export default MyReportsIndex;
