import Operators from "./Operators";

export const getExpressionParser = nodeType => {
	/**
	 * Consumes first node from nodes.
	 *
	 * @param [] nodes Array of nodes
	 */
	function consumeFirst(nodes) {
		nodes.shift();
	}

	/**
	 * Consumes first node from nodes only if its nodeType is equals to the given nodeType.
	 *
	 * @param [] nodes Array of nodes
	 * @param {string} testNodeType The node type
	 * @return {boolean} True if the first node was consumed, false otherwise
	 */
	function consumeFirstIf(nodes, testNodeType) {
		if (nodes.length === 0) {
			return false;
		}
		if (nodes[0].nodeType === testNodeType) {
			nodes.shift();
			return true;
		}
		return false;
	}

	/**
	 * Creates an AND expression node.
	 *
	 * @param [] nodes Array of nodes
	 * @return {json} AND node as a json object: {nodeType: "AND", nodes: []}.
	 */
	function getAndExprJson(nodes) {
		const nodeList = [];
		do {
			// eslint-disable-next-line no-use-before-define
			nodeList.push(getOrExprJson(nodes));
		} while (nodes.length > 0 && consumeFirstIf(nodes, Operators.AND.nodeType));

		if (nodeList.length === 1) {
			return nodeList[0];
		}
		return {
			nodeType: Operators.AND.nodeType,
			nodes: nodeList
		};
	}

	/**
	 * Creates a subexpression node.
	 *
	 * @param [] nodes Array of nodes
	 * @return {json} Json object representing one of these nodes:
	 * - Leaf node: {nodeType: leaf.nodeType, leafNodeId: "INT" or "String"}.
	 * - NOT node: {nodeType: "NOT", nodes: []}.
	 * - Unrecognized node: {nodeType: "UNRECOGNIZED"}.
	 * - Node representing a sub-expression between parenthesis.
	 */
	function getSubExprJson(nodes) {
		if (nodes.length === 0) {
			return {};
		}

		if (nodes[0].nodeType === nodeType) {
			const node = nodes[0];
			consumeFirst(nodes);
			return node; // This node has nodeType and leafNodeId
		}
		if (nodes.length > 1 && nodes[0].nodeType === Operators.NOT.nodeType) {
			consumeFirst(nodes);
			return {
				nodeType: Operators.NOT.nodeType,
				nodes: [getSubExprJson(nodes)]
			};
		}
		if (nodes[0].nodeType === Operators.LEFT_PAREN.nodeType) {
			consumeFirst(nodes); // Consume left paren
			const subExpr = getAndExprJson(nodes);
			consumeFirst(nodes); // Consume right paren
			return subExpr;
		}
		consumeFirst(nodes);
		return {
			nodeType: "UNRECOGNIZED"
		};
	}

	/**
	 * Creates an OR expression node.
	 *
	 * @param [] nodes Array of nodes
	 * @return {json} OR node as a json object: {nodeType: "OR", nodes: []}.
	 */
	function getOrExprJson(nodes) {
		const nodeList = [];
		do {
			nodeList.push(getSubExprJson(nodes));
		} while (nodes.length > 0 && consumeFirstIf(nodes, Operators.OR.nodeType));

		if (nodeList.length === 1) {
			return nodeList[0];
		}
		return {
			nodeType: Operators.OR.nodeType,
			nodes: nodeList
		};
	}

	/**
	 * Expression conversion: flat representation -> tree representation
	 * Gets an ExpressionNode as json (tree representation) given an array of nodes (flat representation)
	 *
	 * @param [] nodes Array of nodes representing the expression. Each element is one of the following:
	 * - {nodeType: "AND"}
	 * - {nodeType: "OR"}
	 * - {nodeType: "NOT"}
	 * - {nodeType: "LEFT_PAREN"}
	 * - {nodeType: "RIGHT_PAREN"}
	 *
	 *   Leaf nodes: node type and id key are injected when creating new expressionBuilderUI components
	 * - {nodeType: leaf.nodeType, leafNodeId: "INT" or "String"}
	 *
	 * @return {json} The expression node representing a tree.
	 */
	function getTreeJson(nodes) {
		return getAndExprJson(nodes);
	}

	return {
		getTreeJson
	};
};

export default getExpressionParser;
