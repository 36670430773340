export default {
	MY_REPORTS_TITLE: "My Reports",
	RESOURCE_NAME: "Report",
	SCHEDULED_REPORT_RESOURCE_NAME: "Scheduled Report",
	COMPLETED_REPORT_RESOURCE_NAME: "Completed Report",
	COLUMN_HEADER_FILE_NAME: "Report Name",

	SCHEDULED_REPORTS_TABLE_HEADING: "Scheduled Reports",
	COMPLETED_REPORTS_TABLE_HEADING: "Completed Reports",

	SCHEDULED_REPORTS_TABLE_CAPTION:
		"Table containing information about Scheduled Reports",
	COMPLETED_REPORTS_TABLE_CAPTION:
		"Table containing information about Completed Reports",

	SCHEDULED_REPORTS_BUTTON_TEXT: "Schedule Report",

	REPORT_NAME_LABEL: "Report Name",
	STATUS_LABEL: "Status",
	RUN_DATE_LABEL: "Run Date",
	EXPIRY_DATE_LABEL: "Expiry Date",

	ACTION_BUTTON_LABEL_DOWNLOAD_EXCEL: "Download Excel",
	ACCESSIBILITY_LABEL_DOWNLOAD_EXCEL: "triggers download",

	ACTION_BUTTON_LABEL_DOWNLOAD_CSV: "Download CSV",
	ACCESSIBILITY_LABEL_DOWNLOAD_CSV: "triggers download",

	DELETE_REPORT_MODAL: {
		TITLE: "Delete Report",
		ACTION_BUTTON_LABEL_CONFIRM: "Delete"
	},
	DELETE_ASSET_SUCCESS_MESSAGE: ({ assetName }) =>
		`${assetName} was successfully deleted`,

	ACTION_BUTTON_LABEL_SHARE: "Share",
	ACCESSIBILITY_LABEL_SHARE: "Share",
	SHARE_RESOURCE_SUCCESS_MESSAGE: resourceName =>
		`${resourceName} was successfully shared`,
	SHARE_RESOURCE_MODAL: {
		TITLE: "Share Report",
		DESCRIPTION: ({ itemName }) =>
			`Enter email addresses that should receive ${itemName} report.`,
		ACTION_BUTTON_LABEL_CONFIRM: "Share"
	},
	ACTION_BUTTON_LABEL_RETRY: "Retry",
	ACCESSIBILITY_LABEL_RETRY: "Retry",
	RETRY_RESOURCE_SUCCESS_MESSAGE: resourceName =>
		`${resourceName} was successfully submitted`,
	RETRY_RESOURCE_MODAL: {
		TITLE: "Retry Report",
		DESCRIPTION: ({ itemName }) => `Run ${itemName}`,
		ACTION_BUTTON_LABEL_CONFIRM: "Run",
		SUCCESS_MESSAGE: ({ itemName }) =>
			`Report ${itemName} was successfully submitted`
	},
	SCHEDULED_REPORT_MANUAL_RUN_BUTTON_TEXT: "Run",
	SCHEDULED_REPORT_MANUAL_RUN_ACCESSIBILITY_TEXT: "Run",
	SCHEDULED_REPORT_MANUAL_RUN_RESOURCE_MODAL: {
		TITLE: "Run Scheduled Report",
		DESCRIPTION: "Confirm to run Scheduled Report.",
		ACTION_BUTTON_LABEL_CONFIRM: "Run",
		SUCCESS_MESSAGE: "Scheduled report was successfully submitted"
	}
};
