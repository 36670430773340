import React, { useContext, useEffect } from "react";
import { Link, Typography, Container } from "@mui/material";
import Paper from "@mui/material/Paper";

import RouterLink from "../../components/Routing/LinkWithQuery";
import localeContent from "./localeContent";
import { AuthContext } from "../../context/AuthContext";
import { HOME_PAGE_PATH } from "../../config/constants";

function AccessDeniedErrorPageComponent() {
	const authContext = useContext(AuthContext);

	useEffect(() => {
		authContext.setAccessDenied(false);
	});

	return (
		<Container maxWidth="sm" sx={{ pt: 22 }}>
			<Paper elevation={4} sx={{ textAlign: "center", p: 5 }}>
				<Typography variant="h4" component="h2">
					{localeContent.ACCESS_DENIED_ERROR_WARNING}
				</Typography>
				<br />
				<Typography>
					{`${localeContent.ERROR_MESSAGE} `}
					<Link href="mailto: support@column6.com">
						{localeContent.SUPPORT_EMAIL_ADDRESS}
					</Link>
					.
				</Typography>
				<br />
				<Typography>
					{`${localeContent.BACK_TO} `}
					<RouterLink to={HOME_PAGE_PATH}>{localeContent.PLATFORM}</RouterLink>
				</Typography>
			</Paper>
		</Container>
	);
}
export default AccessDeniedErrorPageComponent;
