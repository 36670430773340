export default {
	EXPRESSION_OPERATORS_LABEL: "Drag Operators Below to Extend a Rule",
	DEFAULT_EMPTY_EXPRESSION_WARNING: leafName =>
		`Place ${leafName} here to begin creating a rule`,
	PAGINATION_LABEL_UNKNOWN_COUNT: ({ from, to }) => `${from}-${to} of many`,
	ACCESSIBLE_TABLE_LABEL_EXPRESSION_OPTIONS: "expression options",
	VALIDATION_MESSAGES: {
		AT_LEAST_ONE_LEAF: leafName => `Must select at least one ${leafName}`,
		BLOCK_ERRORS:
			"Something's not quite right, hover over blocks for more information"
	},
	EDIT_CHIP_BUTTON_ARIA_LABEL: "Edit",
	OPERATOR_LABELS: {
		AND: "AND",
		OR: "OR",
		NOT: "NOT"
	}
};
