import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ListItemButton from "@mui/material/ListItemButton";
import React from "react";
import PropTypes from "prop-types";

const logoutKey = "LOGOUT";
const logoutName = "Logout";
function LogoutItem({ activeMenuItemKey, onClick }) {
	return (
		<Tooltip key={logoutKey} title={logoutName} placement="right" arrow>
			<ListItemButton
				selected={activeMenuItemKey === logoutKey}
				aria-label={logoutName}
				sx={{ justifyContent: "center" }}
				onClick={onClick}
			>
				<ListItemIcon sx={{ minWidth: 0 }}>
					<ExitToAppIcon />
				</ListItemIcon>
			</ListItemButton>
		</Tooltip>
	);
}

LogoutItem.propTypes = {
	onClick: PropTypes.func.isRequired,
	activeMenuItemKey: PropTypes.string.isRequired
};

export default LogoutItem;
