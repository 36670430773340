import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import {
	FormHelperText,
	FormControl,
	FormLabel,
	FormGroup,
	Box
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import validators from "../../utils/validators";

/*
 * @param {Object} props defined in propTypes
 */

// This style will be used to enable and disabled the Rich Text editor's disabled style.
const RTEStyleOverride = {
	".ql-toolbar, .ql-editor": {
		pointerEvents: "none",
		opacity: "0.5"
	}
};

function FormQuillField(props) {
	const { id, label, name, required, disabled, errorOverride } = props;

	const [field, meta, helpers] = useField(name);
	const showError =
		Boolean(meta.touched && meta.error) || Boolean(errorOverride);
	const helperText = showError ? meta.error : "";
	const getLabel = () => (required ? `${label} *` : label);
	const onChange = content => {
		if (!disabled) {
			if (validators.getTextFromHtml(content)) {
				return helpers.setValue("");
			}
			return helpers.setValue(content);
		}
		return null;
	};

	return (
		<FormControl
			error={showError}
			fullWidth
			area-label={id}
			sx={disabled ? RTEStyleOverride : null}
		>
			<FormLabel id={id} area-label={id}>
				{getLabel()}
			</FormLabel>
			<FormGroup>
				<Box
					sx={{
						".ql-container": { minHeight: "15em" },
						".ql-editor": { maxHeight: "20em" }
					}}
				>
					<ReactQuill
						aria-label={id}
						name={name}
						value={field.value}
						onChange={onChange}
						onBlur={e => field.onBlur(name, e)}
						readOnly={disabled}
						modules={{
							clipboard: {
								matchVisual: false
							}
						}}
						theme="snow"
					/>
				</Box>
			</FormGroup>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

FormQuillField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	errorOverride: PropTypes.string
};

FormQuillField.defaultProps = {
	required: false,
	disabled: false,
	errorOverride: null
};

export default FormQuillField;
