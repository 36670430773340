import React from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";
import { FetchProvider } from "./context/FetchContext";
import { AuthenticatedUserSettingsProvider } from "./context/AuthenticatedUserSettingsContext";
import { AuthorizationProvider } from "./context/AuthorizationContext";
import Routing from "./components/Routing/Routing";
import { GoogleAnalyticsProvider } from "./context/GoogleAnalyticsContext";
import { BrandingProvider } from "./context/BrandingContext";
import MuiProvider from "./context/MuiProvider";
import { UserNotificationProvider } from "./context/UserNotificationContext";

// https://mui.com/x/introduction/licensing/#how-to-install-the-key
const muiLicenseKey = process.env.REACT_APP_MUI_LICENSE_KEY;
LicenseInfo.setLicenseKey(muiLicenseKey);

function App() {
	return (
		<GoogleAnalyticsProvider>
			<Router>
				<AuthProvider>
					<FetchProvider>
						<BrandingProvider>
							<AuthorizationProvider>
								<UserNotificationProvider>
									<AuthenticatedUserSettingsProvider>
										<MuiProvider>
											<Routing />
										</MuiProvider>
									</AuthenticatedUserSettingsProvider>
								</UserNotificationProvider>
							</AuthorizationProvider>
						</BrandingProvider>
					</FetchProvider>
				</AuthProvider>
			</Router>
		</GoogleAnalyticsProvider>
	);
}

export default App;
