export const EXAMPLE_RESOURCE_ROUTE = "resource";
export const EXAMPLE_RESOURCE_ID = "123";
export const EXAMPLE_RESOURCE_STRING = "Resource";
export const PARENT_ONE_KEY = "parent1";
export const PARENT_ONE_NAME = "parent1Name";
export const PARENT_TWO_KEY = "parent2";
export const PARENT_TWO_NAME = "parent2Name";
export const PARENT_ONE_BASE_ROUTE = "parent-one";
export const PARENT_TWO_BASE_ROUTE = "parent-two";
export const PARENT_ONE_ID = "parent1Id";
export const PARENT_TWO_ID = "parent2Id";
export const NAME_KEY = "name";
export const CURRENT_NAME = "Current Name";

export const breadcrumbsConfig = [
	{
		key: "publisherName",
		responseKeyName: "publisherName",
		responseKeyId: "publisherId",
		baseRoute: "publishers"
	},
	{
		key: "siteName",
		responseKeyName: "siteName",
		responseKeyId: "siteId",
		baseRoute: "sites"
	},
	{
		key: "name",
		responseKeyName: "name"
	}
];

export const DEFAULT_SITES_OPTIONS = [
	{ id: 0, name: "Site A" },
	{ id: 1, name: "Site B" },
	{ id: 2, name: "Site C" }
];

export const PARENT_ONE_DISPLAY_NAME = "Parent One - ABC";
export const PARENT_ONE_DISPLAY_ID = "1";
export const PARENT_TWO_DISPLAY_NAME = "Parent TWO - DEF";
export const PARENT_TWO_DISPLAY_ID = "2";
export const EXISTING_RESOURCE_NAME = "Existing Resource";
export const DEFAULT_EDIT_RESPONSE = {
	siteId: "21",
	name: EXISTING_RESOURCE_NAME,
	parent1Name: PARENT_ONE_DISPLAY_NAME,
	parent1Id: PARENT_ONE_KEY,
	parent2Name: PARENT_TWO_DISPLAY_NAME,
	parent2Id: PARENT_TWO_DISPLAY_ID
};
export const SITE_ID_LABEL = "Site";
export const EXAMPLE_BREADCRUMB_TITLE = "Example Publisher name";
export const EXAMPLE_BREADCRUMB_TITLE_LINK = "/dashboard/publishers/1";
export const FORM_ROUTE_CREATE = `/dashboard/${EXAMPLE_RESOURCE_ROUTE}/INIT`;
export const EXAMPLE_PARENT_OPTION_ID = "345";
export const FORM_ROUTE_CREATE_WITH_PARENT_ID = `${FORM_ROUTE_CREATE}?parentId=${EXAMPLE_PARENT_OPTION_ID}`;
export const BREADCRUMB_TEXT_FOR_ARIA_LABEL = "breadcrumb";
export const PARENT_LABEL = "Parent";
export const SITE_OPTION_ONE = "Parent one";
export const SITE_OPTION_TWO = "Parent two";
export const EXAMPLE_PARENT_OPTIONS = [
	{
		id: 1,
		name: SITE_OPTION_ONE
	},
	{
		id: 2,
		name: SITE_OPTION_TWO
	}
];
