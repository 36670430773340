export default {
	FIRST_NAME_LABEL: "First Name",
	LAST_NAME_LABEL: "Last Name",
	PHONE_NUMBER_LABEL: "Phone Number",
	EMAIL_ADDRESS_LABEL: "Email Address",
	TIMEZONE_LABEL: "Timezone",
	HIDE_INACTIVE_ITEMS_LABEL: "Hide Inactive Items",
	DEFAULT_COMPANY_VIEW: "Default Company View",
	DEFAULT_DENSITY: {
		FIELD_LABEL: "Default Density",
		TOOLTIP:
			"Set your preferred row height (Compact, Standard, Comfortable) for index pages and reporting tables across the platform.",
		OPTIONS: {
			COMPACT: "Compact",
			STANDARD: "Standard",
			COMFORTABLE: "Comfortable"
		}
	},
	DEFAULT_NETWORK_METRICS_LABEL: "Default Network Metrics",
	DEFAULT_CAMPAIGN_METRICS_LABEL: "Default Campaign Metrics",
	DEFAULT_RTB_METRICS_LABEL: "Default RTB Metrics",
	DEFAULT_NETWORK_DIMENSIONS_LABEL: "Default Network Dimensions",
	DEFAULT_CAMPAIGN_DIMENSIONS_LABEL: "Default Campaign Dimensions",
	DEFAULT_RTB_DIMENSIONS_LABEL: "Default RTB Dimensions",
	DEFAULT_DIMENSIONS_TOOLTIP:
		"The order of the dimensions you select is the same order they will appear in the chart.",
	DEFAULT_KPIS: {
		NETWORK_LABEL: "Default Network KPIs",
		CAMPAIGN_LABEL: "Default Campaign KPIs",
		RTB_LABEL: "Default RTB KPIs",
		TOOLTIP: "Must enter a minimum of 4 KPIs and a maximum of 8 KPIs.",
		MIN_VALIDATION_MESSAGE: "Must select at least 4 metrics for default KPIs.",
		MAX_VALIDATION_MESSAGE:
			"Cannot select more than 8 metrics for default KPIs."
	}
};
