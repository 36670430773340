import React, { useMemo } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useField } from "formik";
import { Box, Grid } from "@mui/material";
import { useAsyncDebounce } from "react-table";

import { useResourceClient, Operations } from "../../hooks/useResource";
import FormField from "../CrudForm/FormField";
import FormFieldSwitch from "../FormFieldSwitch/FormFieldSwitch";
import FormFieldMultiselect from "../FormFieldMultiselect/FormFieldMultiselect";
import GeoTargetingMultiselect from "./GeoTargetingMultiselect";
import InheritedHelperText from "./InheritedHelperText";
import { isEmpty } from "../../utils/objectUtils";
import localeContent from "./localeContent";
import validators from "../../utils/validators";
import FormFieldLargeList from "../FormFieldLargeList/FormFieldLargeList";

export const ENABLED_KEY = "enabled";
export const COUNTRY_KEY = "geoCountry";
export const COUNTRY_INITIAL_OPTIONS_KEY = "geoCountryInitialOptions";
export const COUNTRY_INHERITED_KEY = "geoCountryInherited";
export const REGION_KEY = "geoRegion";
export const REGION_INITIAL_OPTIONS_KEY = "geoRegionInitialOptions";
export const REGION_INHERITED_KEY = "geoRegionInherited";
export const CITY_KEY = "geoCity";
export const CITY_INITIAL_OPTIONS_KEY = "geoCityInitialOptions";
export const CITY_SELECTED_OPTIONS_KEY = "geoCitySelectedOptions";
export const CITY_INHERITED_KEY = "geoCityInherited";
export const DMA_KEY = "geoDma";
export const DMA_INITIAL_OPTIONS_KEY = "geoDmaInitialOptions";
export const DMA_INHERITED_KEY = "geoDmaInherited";
export const ZIP_CODE_KEY = "geoZipCode";
export const ZIP_CODE_INHERITED_KEY = "geoZipCodeInherited";
export const ZIP_CODE_INITIAL_OPTIONS_KEY = "geoZipCodeInitialOptions";

const GEO_FIELDS_KEYS = [
	COUNTRY_KEY,
	REGION_KEY,
	CITY_KEY,
	DMA_KEY,
	ZIP_CODE_KEY
];

const mapEntriestoOptions = ([key, value]) => ({ id: key, name: value });

const transformObjectToOptionsArray = obj =>
	Object.entries(obj || {}).map(mapEntriestoOptions);

const isFirstOcurrence = (item, index, self) =>
	self.findIndex(({ id }) => id === item.id) === index;

// merge inherited and initial items, marking all initial items as invalids;
// then we filter the duplicated ones (keeping the first ocurrence of each one)
// so all initial items not present in inherited list are set as invalids
const mergeOptions = ({ inheritedOptions, initialOptions, defaultOptions }) =>
	isEmpty(inheritedOptions)
		? defaultOptions
		: [
				...inheritedOptions,
				...initialOptions.map(item => ({
					...item,
					invalid: true
				}))
		  ].filter(isFirstOcurrence);

const filterInvalidOptions = (options, getFieldToCompare, parentOptions) =>
	options.filter(option =>
		parentOptions.some(parentOption => {
			const fieldToCompare = getFieldToCompare(option);
			return (
				parentOption ===
				fieldToCompare.substring(0, fieldToCompare.lastIndexOf("-"))
			);
		})
	);

const getRequestGeoCodes = (options, inheritedOptions) =>
	isEmpty(options) ? [...inheritedOptions.map(({ id }) => id)] : [...options];

const getRequestGeoParams = ({
	searchString,
	countries,
	inheritedCountries,
	regions,
	inheritedRegions
}) => ({
	requestParams: {
		searchString,
		country_code: getRequestGeoCodes(countries, inheritedCountries),
		region_code: regions
			? getRequestGeoCodes(regions, inheritedRegions)
			: undefined
	}
});

const shouldApplyDMA = (countries, inheritedCountries) =>
	isEmpty(countries)
		? isEmpty(inheritedCountries) ||
		  inheritedCountries.some(el => el.id === "US")
		: countries.includes("US");

const getInitEnabled = (fieldData, inheritedValues) => {
	const hasFieldData = GEO_FIELDS_KEYS.some(key => !isEmpty(fieldData[key]));
	const hasInheritValues = GEO_FIELDS_KEYS.some(key =>
		Boolean(inheritedValues[key])
	);
	return hasFieldData || hasInheritValues;
};

export const transformInitData = (data, fieldName) => {
	const fieldData = data[fieldName] || {};
	const inheritedValues = data.inheritedValues || {};

	return {
		[ENABLED_KEY]: getInitEnabled(fieldData, inheritedValues),
		[COUNTRY_KEY]: fieldData[COUNTRY_KEY] || [],
		[COUNTRY_INITIAL_OPTIONS_KEY]: transformObjectToOptionsArray(
			fieldData[COUNTRY_INITIAL_OPTIONS_KEY]
		),
		[COUNTRY_INHERITED_KEY]: transformObjectToOptionsArray(
			inheritedValues[COUNTRY_KEY]
		),
		[REGION_KEY]: fieldData[REGION_KEY] || [],
		[REGION_INITIAL_OPTIONS_KEY]: transformObjectToOptionsArray(
			fieldData[REGION_INITIAL_OPTIONS_KEY]
		),
		[REGION_INHERITED_KEY]: transformObjectToOptionsArray(
			inheritedValues[REGION_KEY]
		),
		[CITY_KEY]: fieldData[CITY_KEY] || [],
		[CITY_INITIAL_OPTIONS_KEY]: transformObjectToOptionsArray(
			fieldData[CITY_INITIAL_OPTIONS_KEY]
		),
		[CITY_SELECTED_OPTIONS_KEY]: transformObjectToOptionsArray(
			fieldData[CITY_INITIAL_OPTIONS_KEY]
		),
		[CITY_INHERITED_KEY]: transformObjectToOptionsArray(
			inheritedValues[CITY_KEY]
		),
		[DMA_KEY]: fieldData[DMA_KEY] || [],
		[DMA_INITIAL_OPTIONS_KEY]: transformObjectToOptionsArray(
			fieldData[DMA_INITIAL_OPTIONS_KEY]
		),
		[DMA_INHERITED_KEY]: transformObjectToOptionsArray(
			inheritedValues[DMA_KEY]
		),
		[ZIP_CODE_KEY]: fieldData[ZIP_CODE_KEY] || [],
		[ZIP_CODE_INITIAL_OPTIONS_KEY]: transformObjectToOptionsArray(
			fieldData[ZIP_CODE_INITIAL_OPTIONS_KEY]
		),
		[ZIP_CODE_INHERITED_KEY]: transformObjectToOptionsArray(
			inheritedValues[ZIP_CODE_KEY]
		)
	};
};

export const transformSubmitData = (data, fieldName) => {
	const {
		[ENABLED_KEY]: enabled,
		[COUNTRY_INITIAL_OPTIONS_KEY]: geoCountryInitialOptions,
		[COUNTRY_INHERITED_KEY]: geoCountryInherited,
		[REGION_INITIAL_OPTIONS_KEY]: geoRegionInitialOptions,
		[REGION_INHERITED_KEY]: geoRegionInherited,
		[CITY_INITIAL_OPTIONS_KEY]: geoCityInitialOptions,
		[CITY_SELECTED_OPTIONS_KEY]: geoCitySelectedOptions,
		[CITY_INHERITED_KEY]: geoCityInherited,
		[DMA_INITIAL_OPTIONS_KEY]: geoDmaInitialOptions,
		[DMA_INHERITED_KEY]: geoDmaInherited,
		[ZIP_CODE_INITIAL_OPTIONS_KEY]: geoZipCodeInitialOptions,
		[ZIP_CODE_INHERITED_KEY]: geoZipCodeInherited,
		...submissionData
	} = data;
	submissionData[CITY_KEY] = geoCitySelectedOptions.map(({ id }) => id);
	if (enabled) {
		return {
			[fieldName]: submissionData
		};
	}
	return { [fieldName]: {} };
};

function FormFieldGeoTargeting(props) {
	const { name } = props;
	const [field, , helpers] = useField(name);
	const formikData = field.value;
	const targetCountries = formikData[COUNTRY_KEY];
	const targetRegions = formikData[REGION_KEY];

	const [
		regionData,
		requestRegionsError,
		isLoadingRegions,
		fetchRegions
	] = useResourceClient(
		"manage/catalogue/regions",
		Operations.LIST,
		getRequestGeoParams({
			searchString: "",
			countries: targetCountries,
			inheritedCountries: formikData[COUNTRY_INHERITED_KEY]
		})
	);
	const regionOptions = useMemo(
		() => transformObjectToOptionsArray(regionData),
		[regionData]
	);

	const [
		cityData,
		requestCitiesError,
		isLoadingCities,
		fetchCities
	] = useResourceClient(
		"manage/catalogue/cities?limit=500",
		Operations.LIST,
		getRequestGeoParams({
			searchString: "",
			countries: targetCountries,
			inheritedCountries: formikData[COUNTRY_INHERITED_KEY],
			regions: targetRegions,
			inheritedRegions: formikData[REGION_INHERITED_KEY]
		})
	);
	const cityOptions = useMemo(() => transformObjectToOptionsArray(cityData), [
		cityData
	]);

	// refetch city options on city search, considering target/inherited countries and regions
	const onSearchCity = useAsyncDebounce((event, val) => {
		fetchCities(
			getRequestGeoParams({
				searchString: val,
				countries: targetCountries,
				inheritedCountries: formikData[COUNTRY_INHERITED_KEY],
				regions: targetRegions,
				inheritedRegions: formikData[REGION_INHERITED_KEY]
			})
		);
	}, 500);

	return (
		<>
			<Box sx={{ mb: formikData[ENABLED_KEY] ? 1 : undefined }}>
				<FormFieldSwitch
					id={`${name}.${ENABLED_KEY}`}
					name={`${name}.${ENABLED_KEY}`}
					label={localeContent.GEO_TARGETING_LABEL}
				/>
			</Box>
			{formikData[ENABLED_KEY] && (
				<Grid container spacing={1}>
					<FormField
						gridConfig={{ md: 6 }}
						tooltip={localeContent.COUNTRY.TOOLTIP}
					>
						<FormFieldMultiselect
							id={`${name}.${COUNTRY_KEY}`}
							name={`${name}.${COUNTRY_KEY}`}
							label={localeContent.COUNTRY.LABEL}
							optionsConfig={{
								requestEndpoint: "manage/catalogue/countries",
								mapEntries: mapEntriestoOptions,
								options: mergeOptions({
									inheritedOptions: formikData[COUNTRY_INHERITED_KEY],
									initialOptions: formikData[COUNTRY_INITIAL_OPTIONS_KEY],
									defaultOptions: null
								})
							}}
							fieldTypeOptions={{
								formStateSetter: updatedCountries => {
									let filteredRegions = formikData[REGION_KEY];
									let filteredCities = formikData[CITY_SELECTED_OPTIONS_KEY];
									let filteredDmas = formikData[DMA_KEY];

									if (!isEmpty(updatedCountries)) {
										filteredRegions = filterInvalidOptions(
											formikData[REGION_KEY],
											option => option,
											updatedCountries
										);
									}

									if (!isEmpty(filteredRegions)) {
										filteredCities = filterInvalidOptions(
											formikData[CITY_SELECTED_OPTIONS_KEY],
											option => option.id,
											filteredRegions
										);
									} else if (!isEmpty(updatedCountries)) {
										filteredCities = filterInvalidOptions(
											formikData[CITY_SELECTED_OPTIONS_KEY],
											option => option.id,
											updatedCountries
										);
									}

									if (
										!shouldApplyDMA(
											updatedCountries,
											formikData[COUNTRY_INHERITED_KEY]
										)
									) {
										filteredDmas = [];
									}

									fetchRegions(
										getRequestGeoParams({
											searchString: "",
											countries: updatedCountries,
											inheritedCountries: formikData[COUNTRY_INHERITED_KEY]
										})
									);
									fetchCities(
										getRequestGeoParams({
											searchString: "",
											countries: updatedCountries,
											inheritedCountries: formikData[COUNTRY_INHERITED_KEY],
											regions: filteredRegions,
											inheritedRegions: formikData[REGION_INHERITED_KEY]
										})
									);
									helpers.setValue({
										...formikData,
										[COUNTRY_KEY]: updatedCountries,
										[REGION_KEY]: filteredRegions || [],
										[CITY_SELECTED_OPTIONS_KEY]: filteredCities || [],
										[DMA_KEY]: filteredDmas || []
									});
								}
							}}
						/>
						<InheritedHelperText
							inheritedValues={formikData[COUNTRY_INHERITED_KEY]}
						/>
					</FormField>
					<FormField
						gridConfig={{ md: 6 }}
						tooltip={localeContent.REGION.TOOLTIP}
					>
						<FormFieldMultiselect
							id={`${name}.${REGION_KEY}`}
							name={`${name}.${REGION_KEY}`}
							label={localeContent.REGION.LABEL}
							optionsConfig={{
								options: mergeOptions({
									inheritedOptions: formikData[REGION_INHERITED_KEY],
									initialOptions: formikData[REGION_INITIAL_OPTIONS_KEY],
									defaultOptions: regionOptions
								}),
								showOptionsRequestError: Boolean(
									!isLoadingRegions && requestRegionsError
								)
							}}
							fieldTypeOptions={{
								formStateSetter: updatedRegions => {
									let filteredCities = formikData[CITY_SELECTED_OPTIONS_KEY];

									if (!isEmpty(updatedRegions)) {
										filteredCities = filterInvalidOptions(
											formikData[CITY_SELECTED_OPTIONS_KEY],
											option => option.id,
											updatedRegions
										);
									}

									fetchCities(
										getRequestGeoParams({
											searchString: "",
											countries: targetCountries,
											inheritedCountries: formikData[COUNTRY_INHERITED_KEY],
											regions: updatedRegions,
											inheritedRegions: formikData[REGION_INHERITED_KEY]
										})
									);
									helpers.setValue({
										...formikData,
										[REGION_KEY]: updatedRegions,
										[CITY_SELECTED_OPTIONS_KEY]: filteredCities || []
									});
								}
							}}
						/>
						<InheritedHelperText
							inheritedValues={formikData[REGION_INHERITED_KEY]}
						/>
					</FormField>
					<FormField
						gridConfig={{ md: 6 }}
						tooltip={localeContent.CITY.TOOLTIP}
					>
						<GeoTargetingMultiselect
							id={`${name}.${CITY_KEY}`}
							name={`${name}.${CITY_SELECTED_OPTIONS_KEY}`}
							label={localeContent.CITY.LABEL}
							options={mergeOptions({
								inheritedOptions: formikData[CITY_INHERITED_KEY],
								initialOptions: formikData[CITY_INITIAL_OPTIONS_KEY],
								defaultOptions: [
									...cityOptions,
									...formikData[CITY_SELECTED_OPTIONS_KEY]
								].filter(isFirstOcurrence)
							})}
							onInputChange={onSearchCity}
							hasRequestError={Boolean(!isLoadingCities && requestCitiesError)}
						/>
						<InheritedHelperText
							inheritedValues={formikData[CITY_INHERITED_KEY]}
						/>
					</FormField>
					{shouldApplyDMA(
						targetCountries,
						formikData[COUNTRY_INHERITED_KEY]
					) && (
						<FormField
							gridConfig={{ md: 6 }}
							tooltip={localeContent.DMA.TOOLTIP}
						>
							<FormFieldMultiselect
								id={`${name}.${DMA_KEY}`}
								name={`${name}.${DMA_KEY}`}
								label={localeContent.DMA.LABEL}
								optionsConfig={{
									requestEndpoint: "manage/catalogue/dmas",
									mapEntries: mapEntriestoOptions,
									options: mergeOptions({
										inheritedOptions: formikData[DMA_INHERITED_KEY],
										initialOptions: formikData[DMA_INITIAL_OPTIONS_KEY],
										defaultOptions: null
									})
								}}
							/>
							<InheritedHelperText
								inheritedValues={formikData[DMA_INHERITED_KEY]}
							/>
						</FormField>
					)}
					<FormField gridConfig={{ md: 6 }}>
						<FormFieldLargeList
							name={`${name}.${ZIP_CODE_KEY}`}
							fieldTypeOptions={{
								targetingTypeLabel: localeContent.ZIP_CODE.LABEL,
								validatorFunction:
									validators.isValidAlphaNumericCharactersAndUTF8
							}}
							value={[]}
							inheritedValues={formikData[ZIP_CODE_INHERITED_KEY].map(
								e => e.name
							)}
						/>
					</FormField>
				</Grid>
			)}
		</>
	);
}

FormFieldGeoTargeting.propTypes = {
	name: PropTypes.string.isRequired
};

FormFieldGeoTargeting.defaultProps = {};

Yup.addMethod(
	Yup.array,
	"nonInheritedValidation",
	function nonInheritedValidation(
		inheritedKey,
		mapOptions,
		message,
		inheritedOptionIdKey = "id"
	) {
		return this.test(
			"nonInheritedValidation",
			message,
			function testNonInheritedValidation(value) {
				const { [inheritedKey]: inherited } = this.parent;
				if (
					!isEmpty(inherited) &&
					!value
						.map(mapOptions)
						.every(item =>
							inherited.some(({ [inheritedOptionIdKey]: id }) => item === id)
						)
				) {
					throw this.createError({
						path: this.path,
						message
					});
				}

				return true;
			}
		);
	}
);

Yup.addMethod(
	Yup.array,
	"nonAlphaNumericValidation",
	function nonAlphaNumericValidation(message) {
		return this.test(
			"nonAlphaNumericValidation",
			message,
			function testNonAlphaNumericValidation(value) {
				if (value.some(validators.containsNonAlphaNumericCharaters)) {
					throw this.createError({
						path: this.path,
						message
					});
				}

				return true;
			}
		);
	}
);

export const validationSchema = Yup.object({
	[COUNTRY_KEY]: Yup.array().when([ENABLED_KEY], {
		is: enabled => enabled,
		then: Yup.array().nonInheritedValidation(
			COUNTRY_INHERITED_KEY,
			option => option,
			localeContent.COUNTRY.NON_INHERITED_VALIDATION_ERROR
		)
	}),
	[REGION_KEY]: Yup.array().when([ENABLED_KEY], {
		is: enabled => enabled,
		then: Yup.array().nonInheritedValidation(
			REGION_INHERITED_KEY,
			option => option,
			localeContent.REGION.NON_INHERITED_VALIDATION_ERROR
		)
	}),
	[CITY_SELECTED_OPTIONS_KEY]: Yup.array().when([ENABLED_KEY], {
		is: enabled => enabled,
		then: Yup.array().nonInheritedValidation(
			CITY_INHERITED_KEY,
			option => option.id,
			localeContent.CITY.NON_INHERITED_VALIDATION_ERROR
		)
	}),
	[DMA_KEY]: Yup.array().when([ENABLED_KEY], {
		is: enabled => enabled,
		then: Yup.array().nonInheritedValidation(
			DMA_INHERITED_KEY,
			option => option,
			localeContent.DMA.NON_INHERITED_VALIDATION_ERROR
		)
	}),
	[ZIP_CODE_KEY]: Yup.array().when([ENABLED_KEY], {
		is: enabled => enabled,
		then: Yup.array().nonInheritedValidation(
			ZIP_CODE_INHERITED_KEY,
			option => option,
			localeContent.ZIP_CODE.NON_INHERITED_VALIDATION_ERROR,
			"name"
		)
	})
});

export default FormFieldGeoTargeting;
