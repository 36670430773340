export default {
	COLUMN_EMAIL: "Email",
	FIRST_NAME_LABEL: "First Name",
	LAST_NAME_LABEL: "Last Name",
	PHONE_NUMBER_LABEL: "Phone Number",
	EMAIL_ADDR_LABEL: "Email Address",
	TIMEZONE_LABEL: "Timezone",
	STATUS_LABEL: "Status",
	ROLES_LABEL: "Roles",
	ROLES_TOOLTIP:
		"Select roles to give the user access to a variety of features and functionality",
	ACCESS_RESTRICTION_TYPE_NONE_LABEL: "Block All",
	ACCESS_RESTRICTION_TYPE_ALL_LABEL: "Allow All",
	FIELD_PUBLISHER_ACCESS_RESTRICTION: {
		TYPE_LABEL: "Publisher Access",
		BLACKLIST_LABEL: "Publisher Block List",
		WHITELIST_LABEL: "Publisher Allow List"
	},
	FIELD_ADVERTISER_ACCESS_RESTRICTION: {
		TYPE_LABEL: "Advertiser Access",
		BLACKLIST_LABEL: "Advertiser Block List",
		WHITELIST_LABEL: "Advertiser Allow List"
	},
	FIELD_IS_DEMAND_CLIENT: {
		LABEL: "Demand Client",
		TOOLTIP:
			"If enabled, this user will see default terminology replaced with demand-focused terms"
	}
};
