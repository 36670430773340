import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { FieldArray, useField } from "formik";
import { Button, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import FormField from "../CrudForm/FormField";
import FormTextField from "../FormTextField/FormTextField";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import localeContent from "./localeContent";
import crudFormLocaleContent from "../CrudForm/localeContent";
import useResource, { Operations } from "../../hooks/useResource";
import FormFieldSelect from "../FormFieldSelect/FormFieldSelect";
import useErrorDeserializer from "../../hooks/useErrorDeserializer";
import commonLocaleContent from "../../screens/commonLocaleContent";

const REGION_ID_KEY = "rtbRegionId";
const URL_KEY = "url";
export const TEST_ID_ROW = "bidder-row";

export const getDefaultRowConfig = () => ({
	clientId: uuidv4(),
	[REGION_ID_KEY]: "",
	[URL_KEY]: ""
});

function BidderRegionRow(props) {
	const {
		index,
		name,
		deleteRow,
		isExistingData,
		regionOptions,
		showOptionsRequestError
	} = props;
	const fieldNameRegionId = `${name}[${index}].${REGION_ID_KEY}`;
	const fieldNameUrl = `${name}[${index}].${URL_KEY}`;

	const onDeleteRow = useCallback(() => {
		if (deleteRow) deleteRow(index);
	}, [deleteRow, index]);

	return (
		<Grid
			container
			item
			spacing={MUI_GRID_CONTAINER_SPACING}
			alignItems="flex-start"
			data-testid={TEST_ID_ROW}
		>
			<FormField gridConfig={{ md: 3, lg: 4 }}>
				<FormFieldSelect
					id={fieldNameRegionId}
					label={localeContent.FIELD_LABEL_REGION_ID}
					name={fieldNameRegionId}
					disabled={isExistingData}
					required
					optionsConfig={{
						options: regionOptions,
						showOptionsRequestError
					}}
				/>
			</FormField>
			<FormField gridConfig={{ md: 8, lg: 7 }}>
				<FormTextField
					id={fieldNameUrl}
					label={localeContent.FIELD_LABEL_URL}
					name={fieldNameUrl}
					required
				/>
			</FormField>
			{deleteRow && (
				<Grid item xs={2} sm={1}>
					<IconButton
						onClick={onDeleteRow}
						aria-label={localeContent.ARIA_LABEL_DELETE_ROW}
					>
						<DeleteIcon fontSize="inherit" />
					</IconButton>
				</Grid>
			)}
		</Grid>
	);
}

BidderRegionRow.propTypes = {
	index: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	deleteRow: PropTypes.func,
	isExistingData: PropTypes.bool.isRequired,
	regionOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	showOptionsRequestError: PropTypes.bool
};
BidderRegionRow.defaultProps = {
	deleteRow: null,
	showOptionsRequestError: false
};

// Iterates through fieldRows to get an array of currently selected region ids
const getSelectedRegionIds = fieldValue =>
	fieldValue.reduce((selectedRegions, fieldRow) => {
		if (fieldRow[REGION_ID_KEY]) {
			selectedRegions.push(parseInt(fieldRow[REGION_ID_KEY], 10));
		}
		return selectedRegions;
	}, []);

// Region option data comes in as an object map, which needs to be converted to an array with value and label keys
const mapRegionDataToOptions = regionsData =>
	Object.entries(regionsData).map(([value, label]) => {
		return {
			value,
			label
		};
	});

// We need to filter options to prevent an option from being selected in multiple rows
const getRowRegionOptions = (
	regionsOptions,
	selectedRegionIds,
	rtbRegionId
) => {
	return regionsOptions.filter(regionOption => {
		const intValue = parseInt(regionOption.value, 10);
		return (
			intValue === parseInt(rtbRegionId, 10) ||
			!selectedRegionIds.includes(intValue)
		);
	});
};

function FormFieldBidderRegionConfig(props) {
	const { name } = props;
	const [field, meta, helpers] = useField({ name });

	useErrorDeserializer(meta.error, helpers);

	const [regionsData, requestRegionsError, isLoadingRegions] = useResource(
		"manage/regions",
		Operations.LIST
	);

	const selectedRegionIds = getSelectedRegionIds(field.value || []);
	const regionsOptions = useMemo(
		() => mapRegionDataToOptions(regionsData || []),
		[regionsData]
	);

	return (
		<FieldArray name={name}>
			{fieldArrayHelpers => {
				const addRow = () => {
					fieldArrayHelpers.push(getDefaultRowConfig());
				};
				return (
					<Grid
						container
						direction="column"
						spacing={MUI_GRID_CONTAINER_SPACING}
					>
						{field.value.map(({ clientId, id, rtbRegionId }, index) => {
							const rowRegionOptions = getRowRegionOptions(
								regionsOptions,
								selectedRegionIds,
								rtbRegionId
							);
							return (
								<BidderRegionRow
									key={id ?? clientId}
									index={index}
									name={name}
									deleteRow={
										field.value.length > 1
											? fieldArrayHelpers.remove
											: undefined
									}
									isExistingData={id !== undefined}
									regionOptions={rowRegionOptions}
									showOptionsRequestError={Boolean(
										!isLoadingRegions && requestRegionsError
									)}
								/>
							);
						})}
						{field.value.length < regionsOptions.length && (
							<Grid item xs={12}>
								<Button variant="contained" onClick={addRow}>
									{localeContent.BUTTON_TEXT_ADD_ROW}
								</Button>
							</Grid>
						)}
					</Grid>
				);
			}}
		</FieldArray>
	);
}

FormFieldBidderRegionConfig.propTypes = {
	name: PropTypes.string.isRequired
};

export const ValidationSchema = Yup.array().of(
	Yup.object({
		[REGION_ID_KEY]: Yup.string().required(
			crudFormLocaleContent.REQUIRED_FIELD_WARNING
		),
		[URL_KEY]: Yup.string()
			.required(crudFormLocaleContent.REQUIRED_FIELD_WARNING)
			.max(1024, commonLocaleContent.MAX_STRING_LENGTH_EXCEEDED)
	})
);

export default FormFieldBidderRegionConfig;
