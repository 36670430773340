import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import useResource, { Operations } from "../../hooks/useResource";
import transformOptions from "../../utils/transformOptions";
import Select from "./Select";
import stringifiedNumber from "../../utils/stringifiedNumber";
import getDropdownOptionsStatus from "../../utils/inputUtils";
import commonLocaleContent from "../../screens/commonLocaleContent";

function FormFieldSelect(props) {
	const {
		id,
		label,
		name,
		tooltip,
		disabled,
		readOnly,
		required,
		optionsConfig,
		variant,
		ariaOnlyLabel
	} = props;
	const {
		requestEndpoint,
		requestParams,
		showOptionsRequestError
	} = optionsConfig;
	const [field, meta] = useField(name);

	const [optionsData, requestError, isLoadingOptions] = useResource(
		requestEndpoint,
		Operations.LIST,
		{
			requestParams
		}
	);
	const fieldOptions = transformOptions(optionsConfig, optionsData);

	let showError = Boolean(meta.touched && meta.error);
	let helperText = showError ? meta.error : "";
	if (showOptionsRequestError || (!isLoadingOptions && requestError)) {
		showError = true;
		helperText = commonLocaleContent.UNABLE_TO_RETRIEVE_OPTIONS_WARNING;
	}

	const loadingStatus = getDropdownOptionsStatus(
		optionsConfig?.fetchingStatus,
		fieldOptions.length
	);

	// This makes sure the value stays empty until options are loaded
	const overrideValueWhileLoading = fieldOptions.length === 0;
	const value = overrideValueWhileLoading ? "" : stringifiedNumber(field.value);
	return (
		<Select
			id={id}
			label={label}
			ariaOnlyLabel={ariaOnlyLabel}
			name={name}
			value={value}
			onChange={field.onChange}
			onBlur={field.onBlur}
			options={fieldOptions}
			showError={showError}
			helperText={loadingStatus.helperText || helperText}
			tooltip={tooltip}
			disabled={disabled}
			readOnly={readOnly}
			required={required}
			variant={variant}
			fetchingStatusMessage={loadingStatus.noOptionsText}
		/>
	);
}

FormFieldSelect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	ariaOnlyLabel: PropTypes.bool,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	optionsConfig: PropTypes.shape(),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
	variant: PropTypes.string
};

FormFieldSelect.defaultProps = {
	ariaOnlyLabel: false,
	disabled: false,
	readOnly: false,
	required: false,
	optionsConfig: {},
	tooltip: null,
	variant: undefined
};

export default FormFieldSelect;
