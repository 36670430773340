import { FILTER_TYPE, TABLE_DATA_TYPE } from "../../config/constants";
import { buildColumnDefinition } from "../../utils/ReactTableUtils/ReactTableUtils";
import AlignmentColumnEditLink from "../../components/AlignmentTool/AlignmentColumnEditLink";

export default [
	buildColumnDefinition({
		Cell: AlignmentColumnEditLink,
		Header: "Name",
		accessor: "name",
		preventVisibilityToggle: true,
		enableGlobalFilter: true,
		ext: {
			getEditURL: row => {
				const demandForm = row.isProgrammaticDemand
					? "programmatic-demand"
					: "media";
				return `/dashboard/${demandForm}/${row.id}`;
			}
		}
	}),
	buildColumnDefinition({
		Header: "Campaign Name",
		accessor: "campaignName"
	}),
	buildColumnDefinition({
		Header: "Insertion Name",
		accessor: "insertionOrderName"
	}),
	buildColumnDefinition({
		Header: "Demand Type",
		accessor: "demandType",
		ext: {
			filterType: FILTER_TYPE.ALPHA_SINGLE
		}
	}),
	buildColumnDefinition({
		Header: "Advertiser Name",
		accessor: "advName"
	}),
	buildColumnDefinition({
		Header: "Deal ID",
		accessor: "dealId"
	}),
	buildColumnDefinition({
		Header: "CPM/Floor ($)",
		accessor: "cpmFloor",
		type: TABLE_DATA_TYPE.CURRENCY
	}),
	buildColumnDefinition({
		Header: "Margin",
		accessor: "margin",
		type: TABLE_DATA_TYPE.PERCENTAGE
	}),
	buildColumnDefinition({
		Header: "Revenue ($)",
		accessor: "revenue",
		type: TABLE_DATA_TYPE.CURRENCY
	}),
	buildColumnDefinition({
		Header: "Pub Margin",
		accessor: "publisherMargin",
		type: TABLE_DATA_TYPE.PERCENTAGE
	}),
	buildColumnDefinition({
		Header: "Pub Revenue ($)",
		accessor: "publisherRevenue",
		type: TABLE_DATA_TYPE.CURRENCY
	}),
	buildColumnDefinition({
		Header: "Status",
		accessor: "status",
		ext: {
			filterType: FILTER_TYPE.ALPHA_SINGLE
		}
	})
];
