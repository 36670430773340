import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

import Tooltip from "../Tooltip/Tooltip";

export const errorStyle = {
	color: "error.500",
	borderColor: "error.500",
	"& .MuiSvgIcon-root": { color: "error.500" }
};

function Multiselect(props) {
	const {
		id,
		label,
		name,
		required,
		disabled,
		showError,
		helperText,
		onChange,
		onInputChange,
		onBlur,
		options,
		selectedOptions,
		noOptionsText,
		tooltip,
		preSelected
	} = props;
	const preselectedItems = preSelected.map(item => item.id);

	const disablePreselectedChip = chipId => {
		return (
			chipId === preselectedItems.find(selectedItem => selectedItem === chipId)
		);
	};
	const [tooltipOpen, setTooltipOpen] = React.useState(false);
	const [selectOpen, setSelectOpen] = React.useState(false);
	const [inputValue, setInputValue] = useState("");

	const handleTooltipClose = () => setTooltipOpen(false);

	// Hide the tooltip when the select options are open so that we don't cover them up with the tooltip
	const handleTooltipOpen = () => setTooltipOpen(!selectOpen);

	const handleSelectClose = () => setSelectOpen(false);

	const handleSelectOpen = () => setSelectOpen(true);

	useEffect(() => {
		if (selectOpen) {
			setTooltipOpen(false);
		} else {
			setInputValue("");
		}
	}, [selectOpen]);

	return (
		<Autocomplete
			multiple
			disableCloseOnSelect
			id={id}
			onChange={onChange}
			inputValue={inputValue}
			onBlur={onBlur}
			disabled={disabled}
			value={selectedOptions}
			options={options}
			getOptionLabel={option => option.name || ""}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			onInputChange={onInputChange}
			noOptionsText={noOptionsText}
			getOptionDisabled={option => disablePreselectedChip(option.id)} // This will disabled the dropdown items too whose chips are disabled.
			onClose={handleSelectClose}
			onOpen={handleSelectOpen}
			/*
				Fixed the Warning: Encountered two children with the same key issue.
				Reference: https://stackoverflow.com/questions/69395945/how-can-i-add-unique-keys-to-react-mui-autocomplete-component
			*/
			renderOption={(renderProps, option) => {
				/*
				<li> tag is the default MUI implementation
				https://github.com/mui/material-ui/blob/41210c9313cf6481838e857f6430e5c4e1ca5148/packages/mui-material/src/Autocomplete/Autocomplete.js#L544
				*/
				return (
					<li
						// This is a material-ui prop so we have no control over this
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...renderProps}
						key={option.id}
					>
						{option.name}
					</li>
				);
			}}
			renderInput={params => {
				const textField = (
					<TextField
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...params}
						label={label}
						error={showError}
						helperText={helperText}
						onChange={e => setInputValue(e.target.value)}
						required={required}
						name={name}
						variant="filled"
					/>
				);
				return tooltip ? (
					<Tooltip
						title={tooltip || ""}
						open={tooltipOpen}
						onClose={handleTooltipClose}
						onOpen={handleTooltipOpen}
					>
						{textField}
					</Tooltip>
				) : (
					textField
				);
			}}
			// We use render tags to render an outlined variant of the Chip
			renderTags={(value, getTagProps) =>
				value.map((option, index) => {
					const { invalid } = options.find(item => item.id === option.id);
					return (
						<Chip
							key={option.id}
							sx={invalid ? errorStyle : undefined}
							variant="outlined"
							size="small"
							label={option.name}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...getTagProps({ index })}
							data-testid="multiselect-chip"
							disabled={disablePreselectedChip(option.id)}
						/>
					);
				})
			}
		/>
	);
}

Multiselect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onInputChange: PropTypes.func,
	onBlur: PropTypes.func,
	showError: PropTypes.bool,
	helperText: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.shape()),
	selectedOptions: PropTypes.arrayOf(PropTypes.shape()),
	noOptionsText: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
	preSelected: PropTypes.arrayOf(PropTypes.shape())
};

Multiselect.defaultProps = {
	required: false,
	disabled: false,
	onChange: null,
	onInputChange: null,
	onBlur: null,
	showError: false,
	helperText: null,
	options: [],
	selectedOptions: [],
	noOptionsText: "",
	tooltip: null,
	preSelected: []
};

export default Multiselect;
