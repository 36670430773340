import React from "react";
import PropTypes from "prop-types";
import {
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarColumnsButton
} from "@mui/x-data-grid-pro";
import { Divider } from "@mui/material";
import GridToolbarExportButton from "./GridToolbarExportButton";

import GridToolbarScheduledReportsButton from "./GridToolbarScheduledReportsButton";
import FormFieldDimensionsToolbar from "../FormFieldDimensionsToolbar/FormFieldDimensionsToolbar";

function GridToolbarCustom({
	scheduledReportsLinkRoute,
	exportReportParams,
	dimensionOptions,
	defaultUserDimensions
}) {
	return (
		<>
			<FormFieldDimensionsToolbar
				dimensionOptions={dimensionOptions}
				defaultUserDimensions={defaultUserDimensions}
			/>
			<Divider />
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarDensitySelector />
				<GridToolbarScheduledReportsButton
					scheduledReportsLinkRoute={scheduledReportsLinkRoute}
				/>
				<GridToolbarExportButton exportReportParams={exportReportParams} />
			</GridToolbarContainer>
		</>
	);
}

GridToolbarCustom.propTypes = {
	scheduledReportsLinkRoute: PropTypes.string.isRequired,
	exportReportParams: PropTypes.shape().isRequired,
	dimensionOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	defaultUserDimensions: PropTypes.shape()
};

GridToolbarCustom.defaultProps = {
	defaultUserDimensions: {}
};

export default GridToolbarCustom;
