import { format } from "date-fns";
import {
	REPORT_TYPE_KEY,
	DATE_RANGE_KEY,
	FILTER_KEY,
	SLICE_CONFIG_KEY,
	TIME_ZONE_KEY
} from "./constants";
import { dateRangeServerFormat } from "../../config/constants";

export const transformFilterConfigToQueryParams = filtersConfig =>
	filtersConfig.reduce(
		(agg, filter) => ({
			...agg,
			[filter.dimension]: filter.value
		}),
		{}
	);

export const buildPerformanceDataRequestParams = reportConfig => {
	const {
		[REPORT_TYPE_KEY]: reportType,
		[DATE_RANGE_KEY]: [startDate, endDate],
		[FILTER_KEY]: filtersConfig,
		[TIME_ZONE_KEY]: tz
	} = reportConfig;
	// Get formatted date string from datepicker
	const start = format(startDate, dateRangeServerFormat);
	const end = format(endDate, dateRangeServerFormat);
	const filters = transformFilterConfigToQueryParams(filtersConfig);
	const perfParams = {
		requestParams: {
			reportType,
			start,
			end,
			tz,
			filters
		}
	};
	return perfParams;
};

// The filter-and-group endpoint requires a "group" parameter in addition to the performance data request parameters
export const buildGroupandFilterRequestParams = reportConfig => {
	const { [SLICE_CONFIG_KEY]: sliceConfig } = reportConfig;

	const filterAndGroupParams = {
		requestParams: {
			...buildPerformanceDataRequestParams(reportConfig).requestParams,
			group: sliceConfig[0] // This is the page level request so we use the first slice
		}
	};
	return filterAndGroupParams;
};
