import React, { useContext, useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Paper, Skeleton } from "@mui/material";
import {
	LineChart as LineChartRecharts,
	Line,
	YAxis,
	Tooltip,
	XAxis,
	ResponsiveContainer,
	CartesianGrid
} from "recharts";
import { useTheme } from "@emotion/react";
import { isEmpty } from "lodash";
import {
	formatAsCurrency,
	formatAsNumberWithSeparator,
	formatAsPercent
} from "../../../utils/formatters";
import Legend from "./Legend";
import lc from "./localeContent";
import { PRIMARY_COLORS, SECONDARY_COLORS } from "../../../config/constants";
import { getColorDefinition } from "../../../config/defaultMuiTheme";
import { getMetricsConfigByType, METRIC_TYPES } from "../metricsConfig";
import { AuthorizationContext } from "../../../context/AuthorizationContext";
import { LEFT_METRIC_INDEX, RIGHT_METRIC_INDEX } from "../constants";
import { BrandingContext } from "../../../context/BrandingContext";
import { AuthContext } from "../../../context/AuthContext";

export const CHART_CONTAINER_TEST_ID = "line-chart-container";
export const CHART_LOADING_SKELETON_TEST_ID = "line-chart-loading-skeleton";
const AXIS_FONT_SIZE = 14;
export const LEFT_AXIS_ID = "LEFT";
export const RIGHT_AXIS_ID = "RIGHT";

const formatNumberWithAdornments = value => {
	// Trillions
	if (value >= 1000000000000) {
		return `${value / 1000000000000} ${lc.NUMBER_ABBREVIATION.TRILLIONS}`;
	}
	// Billions
	if (value >= 1000000000) {
		return `${value / 1000000000} ${lc.NUMBER_ABBREVIATION.BILLIONS}`;
	}
	// Millions
	if (value >= 1000000) {
		return `${value / 1000000} ${lc.NUMBER_ABBREVIATION.MILLIONS}`;
	}
	// Thousands
	if (value >= 1000) {
		return `${value / 1000} ${lc.NUMBER_ABBREVIATION.THOUSANDS}`;
	}
	return `${Number.isInteger(value) ? value : formatAsCurrency(value)}`;
};

const formattersByType = {
	[METRIC_TYPES.NUMBER]: value => formatAsNumberWithSeparator(value),
	[METRIC_TYPES.CURRENCY]: value => `$${formatAsCurrency(value)}`,
	[METRIC_TYPES.PERCENT]: value => formatAsPercent(value)
};

const tickCount = 7;

// Formats values by rounding to the nearest thousand, million, billion, or trillion depending on the size of the value
const formatYAxisLabel = (value, type) => {
	if (type === METRIC_TYPES.NUMBER) return formatNumberWithAdornments(value);
	if (type === METRIC_TYPES.CURRENCY)
		return `$${formatNumberWithAdornments(value)}`;
	return formatAsPercent(value);
};

const hasPermissions = (columnItem, authorizationContext) => {
	if (columnItem.permissionsRequired) {
		return columnItem.permissionsRequired.every(permission =>
			authorizationContext.hasPermission(permission)
		);
	}
	return true;
};

const getColorSecondary = primaryColor => {
	let secondayColor = SECONDARY_COLORS.GREY.id;
	Object.entries(PRIMARY_COLORS).forEach(([, value]) => {
		const color = getColorDefinition(value.id);
		if (color[500] === primaryColor) {
			secondayColor = value.secondary.id;
		}
	});
	return secondayColor;
};

const getMetricValue = (colConfig, row, companyConfig) =>
	colConfig.getDerivedValue
		? colConfig.getDerivedValue(row, companyConfig)
		: row[colConfig.metric];

function BasicLineChart(props) {
	const {
		metricsData,
		isLoadingMetricsData,
		minTickGap,
		ticks,
		getSeriesPointLabel,
		seriesDefaultData,
		reportType
	} = props;
	const theme = useTheme();
	const authorizationContext = useContext(AuthorizationContext);
	const [series, setSeries] = useState([...seriesDefaultData]);
	const [reportTypeInChart, setReportTypeInChart] = useState(reportType);
	const { companyConfig } = useContext(BrandingContext);
	const { isDemandClient } = useContext(AuthContext);

	const metrics = useMemo(
		() =>
			getMetricsConfigByType(reportType, { isDemandClient })
				// Filter out columns user doesn't have permission to access
				.filter(column => hasPermissions(column, authorizationContext))
				.map(column => ({
					metric: column.field,
					label: column.name,
					type: column.dataType || METRIC_TYPES.NUMBER,
					getDerivedValue: column.getDerivedValue
				})),
		[authorizationContext, reportType, isDemandClient]
	);

	const labeledData = useMemo(() => {
		const leftConfig = series[LEFT_METRIC_INDEX] || {};
		const rightConfig = series[RIGHT_METRIC_INDEX] || {};
		return (metricsData || []).map((row, index) => {
			return {
				...row,
				name: getSeriesPointLabel(index),
				[leftConfig.metric]: getMetricValue(leftConfig, row, companyConfig),
				[rightConfig.metric]: getMetricValue(rightConfig, row, companyConfig)
			};
		});
	}, [getSeriesPointLabel, metricsData, series, companyConfig]);

	useEffect(() => {
		if (reportTypeInChart !== reportType) {
			setReportTypeInChart(reportType);
			setSeries(
				series.map((data, index) => {
					const defaultSeries = seriesDefaultData[index];
					return { ...defaultSeries };
				})
			);
		}
	}, [reportTypeInChart, reportType, series, seriesDefaultData]);

	const handleLegendMetricChange = (metric, index) => {
		const metricConfig = metrics.find(m => m.metric === metric);
		series[index].metric = metric;
		series[index].label = metricConfig.label;
		series[index].type = metricConfig.type || METRIC_TYPES.NUMBER;
		series[index].getDerivedValue = metricConfig.getDerivedValue;
		setSeries([...series]);
	};

	// Build a map of metric key => metric formatter (based on type number, currency or percentage)
	const metricFormatters = useMemo(() => {
		return series.reduce(
			(agg, metricConfig) => ({
				...agg,
				[metricConfig.metric]: formattersByType[metricConfig.type]
			}),
			{}
		);
	}, [series]);

	// Define colors based on company
	const lineConfig = useMemo(() => {
		const primaryColor = theme.palette.primary.main;
		const secondaryColor = getColorSecondary(primaryColor);
		const leftMetricLineWidth = 2;
		const rightMetricLineWidth = 2;
		return [
			{ stroke: primaryColor, width: leftMetricLineWidth },
			{ stroke: secondaryColor, width: rightMetricLineWidth }
		];
	}, [theme.palette.primary.main]);

	const primaryColor = lineConfig[0].stroke || theme.palette.primary.main;
	const secondaryColor = lineConfig[1].stroke || SECONDARY_COLORS.GREY.id;

	return (
		!isEmpty(series) && (
			<Paper
				sx={{ mb: 1, pt: 2, pb: isLoadingMetricsData ? 2 : undefined }}
				data-testid={CHART_CONTAINER_TEST_ID}
			>
				{isLoadingMetricsData ? (
					<Box
						data-testid={CHART_LOADING_SKELETON_TEST_ID}
						aria-busy="true"
						aria-live="polite"
						role="status"
					>
						<Skeleton
							variant="rounded"
							height={40}
							width={150}
							sx={{ ml: 8, display: "inline-block" }}
						/>
						<Skeleton
							variant="rounded"
							height={40}
							width={150}
							sx={{ ml: 1.5, display: "inline-block" }}
						/>
						<Skeleton
							variant="rectangular"
							height={264}
							sx={{ mx: 8, pb: 2 }}
						/>
					</Box>
				) : (
					<>
						{metricsData &&
							!isEmpty(metrics) &&
							reportType === reportTypeInChart && (
								<Legend
									leftSeriesColor={primaryColor}
									rightSeriesColor={secondaryColor}
									metrics={metrics}
									leftMetric={series[LEFT_METRIC_INDEX].metric}
									rightMetric={series[RIGHT_METRIC_INDEX].metric}
									onChange={handleLegendMetricChange}
								/>
							)}

						<ResponsiveContainer height={275} width="100%">
							<LineChartRecharts data={labeledData}>
								<CartesianGrid strokeDasharray="2 3" vertical={false} />

								<XAxis
									style={{ fontSize: AXIS_FONT_SIZE }}
									dataKey="name"
									stroke="black"
									axisLine={{ stroke: `black`, strokeWidth: 2 }}
									tickLine={false}
									minTickGap={minTickGap}
									ticks={ticks}
								/>
								<YAxis
									yAxisId={LEFT_AXIS_ID}
									tickCount={tickCount}
									style={{ fontSize: AXIS_FONT_SIZE }}
									stroke="black"
									axisLine={{ stroke: `${primaryColor}`, strokeWidth: 2 }}
									tickLine={false}
									tickFormatter={(value, index) => {
										if (index === 0 || index === tickCount - 1) return "";
										return `${formatYAxisLabel(
											value,
											series[LEFT_METRIC_INDEX].type
										)}`;
									}}
								/>
								<YAxis
									yAxisId={RIGHT_AXIS_ID}
									tickCount={tickCount}
									style={{ fontSize: AXIS_FONT_SIZE }}
									stroke="black"
									axisLine={{ stroke: `${secondaryColor}`, strokeWidth: 2 }}
									orientation="right"
									tickLine={false}
									tickFormatter={(value, index) => {
										if (index === 0 || index === tickCount - 1) return "";
										return `${formatYAxisLabel(
											value,
											series[RIGHT_METRIC_INDEX].type
										)}`;
									}}
								/>

								<Tooltip
									offset={16}
									formatter={(value, name, formatterProps) => {
										const formatter = metricFormatters[formatterProps.dataKey];
										return formatter ? formatter(value) : val => val;
									}}
									wrapperStyle={{ outline: "none" }}
								/>
								{series.map((seriesConfig, index) => {
									const { stroke, width } = lineConfig[index];
									const key = `${seriesConfig.metric}${index}`;
									return (
										<Line
											key={key}
											yAxisId={seriesConfig.yAxisId}
											name={seriesConfig.label}
											dataKey={seriesConfig.metric}
											strokeWidth={width}
											type="monotone"
											dot={false}
											stroke={stroke}
										/>
									);
								})}
							</LineChartRecharts>
						</ResponsiveContainer>
					</>
				)}
			</Paper>
		)
	);
}

BasicLineChart.propTypes = {
	metricsData: PropTypes.arrayOf(PropTypes.shape()),
	isLoadingMetricsData: PropTypes.bool.isRequired,
	minTickGap: PropTypes.number,
	ticks: PropTypes.arrayOf(PropTypes.string),
	getSeriesPointLabel: PropTypes.func.isRequired,
	reportType: PropTypes.string.isRequired,
	seriesDefaultData: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

BasicLineChart.defaultProps = {
	metricsData: null,
	minTickGap: undefined,
	ticks: undefined
};

export default BasicLineChart;
