import {
	COMPANY_ID_QUERY_PARAM,
	SERVER_URL_WITH_VERSION
} from "../config/constants";

// This can eventually be extended to work for objects as well if we need
export const isEmpty = testArray => {
	if (!testArray) {
		return true;
	}
	return testArray.length === 0;
};

/**
 * Safe division will not fail when trying to divide by zero
 * @param {Number} numerator division numerator
 * @param {Number} denominator division denominator
 * @returns returns 0 if trying to divide by zero, else the result of dividing the numbers
 */
export const safeDivide = (numerator, denominator) => {
	if (denominator === 0 || denominator === undefined) {
		return 0;
	}
	return numerator / denominator;
};

// From https://stackoverflow.com/questions/14810506/map-function-for-objects-instead-of-arrays
export const objectMap = (object, fn) =>
	Object.fromEntries(
		Object.entries(object).map(([key, value], index) => [
			key,
			fn(value, key, index)
		])
	);

export const getAuthenticatedEndpoint = endpoint => {
	return `${SERVER_URL_WITH_VERSION}/${endpoint}`;
};

const companyIdRegEx = new RegExp(
	`([?&])${COMPANY_ID_QUERY_PARAM}=.*?(&|$)`,
	"i"
);

// created it specific to company id so that reg ex is not be created for every link considering performance
// following method replaces/adds/updates the COMPANY_ID_QUERY_PARAM value to the passed
export const updateCompanyIdQueryStringParameter = (uri, companyId) => {
	// as conversion of companyId happens somewhere it could be 0 or NaN too
	if ([0, NaN, undefined, null].includes(companyId)) return uri;

	const separator = uri.indexOf("?") !== -1 ? "&" : "?";
	if (uri.match(COMPANY_ID_QUERY_PARAM)) {
		return uri.replace(
			companyIdRegEx,
			`$1${COMPANY_ID_QUERY_PARAM}=${companyId}$2`
		);
	}
	return `${uri + separator + COMPANY_ID_QUERY_PARAM}=${companyId}`;
};
