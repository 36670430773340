import { EXPRESSION_NODE_TYPE } from "../../config/constants";
import lc from "./localeContent";

export default {
	AND: { nodeType: EXPRESSION_NODE_TYPE.AND, label: lc.OPERATOR_LABELS.AND },
	OR: { nodeType: EXPRESSION_NODE_TYPE.OR, label: lc.OPERATOR_LABELS.OR },
	NOT: { nodeType: EXPRESSION_NODE_TYPE.NOT, label: lc.OPERATOR_LABELS.NOT },
	PARENTHESIS: {
		nodeType: "PARENTHESIS",
		label: "( )"
	},
	LEFT_PAREN: {
		nodeType: "LEFT_PAREN",
		label: "("
	},
	RIGHT_PAREN: {
		nodeType: "RIGHT_PAREN",
		label: ")"
	}
};
