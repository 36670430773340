import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { addDays } from "date-fns";

import lc from "./localeContent";

// We need to subtract one day from the max range so that the range includes the start date
const getMaxRangeEndDate = (startDate, maxDateRange) =>
	addDays(startDate, maxDateRange - 1);

const endDateTooFarInFuture = (startDate, endDate, maxDateRange) =>
	endDate > getMaxRangeEndDate(startDate, maxDateRange);

function FormFieldDateRangePicker(props) {
	const { name, minDate, maxDate, maxDateRange, onAccept } = props;
	const [field, , helpers] = useField(name);

	const shouldDisableDate = useCallback(
		(date, dateRangePosition) => {
			// Only validate end dates ("start" and "end" are the two possibilities for dateRangePosition, which is passed by MUI)
			if (dateRangePosition !== "end") {
				return false;
			}
			return endDateTooFarInFuture(field.value[0], date, maxDateRange);
		},
		[field.value, maxDateRange]
	);

	return (
		<StaticDateRangePicker
			onAccept={dateRange => {
				// Set the new range
				helpers.setValue(dateRange);
				onAccept();
			}}
			displayStaticWrapperAs="desktop"
			value={field.value}
			onChange={([start]) => {
				// Clear the end date whenever we select a new range and set it to the start date
				// NOTE: onChange function also runs after the end date is selected but onAccept runs right after and passes the full range
				helpers.setValue([start, start]);
			}}
			minDate={minDate}
			maxDate={maxDate}
			shouldDisableDate={maxDateRange ? shouldDisableDate : undefined}
			renderInput={(startProps, endProps) => (
				<>
					{/* Material Ui props */}
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<TextField {...startProps} />
					<Box sx={{ mx: 2 }}> {lc.TO_LABEL} </Box>
					{/* Material Ui props */}
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<TextField {...endProps} />
				</>
			)}
		/>
	);
}

FormFieldDateRangePicker.propTypes = {
	name: PropTypes.string.isRequired,
	minDate: PropTypes.instanceOf(Date),
	maxDate: PropTypes.instanceOf(Date),
	maxDateRange: PropTypes.number,
	onAccept: PropTypes.func
};

FormFieldDateRangePicker.defaultProps = {
	minDate: null,
	maxDate: null,
	onAccept: () => {},
	maxDateRange: undefined
};

export default FormFieldDateRangePicker;
