import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";
import localeContent from "./localeContent";

function ReactTableCellEnabledOriginalRowCheckbox({ row }) {
	const { enabled, name } = row.original;
	const ariaLabel = localeContent.TOGGLE_ROW_SELECTED_LABEL(name);
	return (
		<Checkbox checked={enabled} inputProps={{ "aria-label": ariaLabel }} />
	);
}

ReactTableCellEnabledOriginalRowCheckbox.propTypes = {
	row: PropTypes.shape().isRequired
};

export default ReactTableCellEnabledOriginalRowCheckbox;
