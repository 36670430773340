import React from "react";
import * as Yup from "yup";
import CrudForm from "../../components/CrudForm/CrudForm";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";

const tabsConfig = [
	{
		label: localeContent.TAB_LABEL_SETTINGS,
		key: "SETTINGS",
		fieldsConfig: [
			{
				key: "BASIC_SETTINGS",
				fields: [
					{
						label: localeContent.ADVERTISER_NAME_LABEL,
						name: "name",
						gridConfig: { md: 8 },
						isRequired: true
					},
					{
						label: localeContent.STATUS_LABEL,
						name: "status",
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									value: "active",
									label: localeContent.STATUS_OPTION_LABEL_ACTIVE
								},
								{
									value: "inactive",
									label: localeContent.STATUS_OPTION_LABEL_INACTIVE
								}
							]
						},
						defaultValue: "active"
					},
					{
						label: localeContent.PRICING_OPTIONS_LABEL,
						tooltip: localeContent.PRICING_OPTIONS_TOOLTIP,
						permissionRequired: "VIEW_ADVERTISER_CPM",
						name: "pricingOptions",
						fieldType: FieldTypes.RADIO,
						gridConfig: { md: 6 },
						optionsConfig: {
							options: [
								{ value: "CPM", label: "CPM" },
								{ value: "PCPM", label: "Predicted CPM" },
								{ value: "ANY", label: "CPM and PCPM" }
							]
						},
						defaultValue: "CPM"
					},
					{
						label: localeContent.DEMAND_FEE_PERCENTAGE_LABEL,
						tooltip: localeContent.DEMAND_FEE_PERCENTAGE_TOOLTIP,
						permissionRequired: "EDIT_DEMAND_FEE_PERCENTAGE_ADVERTISER_FORM",
						name: "demandFeePercentage",
						gridConfig: { md: 6 },
						fieldType: FieldTypes.PERCENT,
						fieldTypeOptions: {
							endAdornmentText: "%"
						},
						validationSchema: Yup.number()
							.min(0, commonLocaleContent.PERCENTAGE_FIELD_MUST_BE_POSITIVE)
							.max(
								100,
								commonLocaleContent.PERCENTAGE_FIELD_MUST_BE_BETWEEN_0_100
							)
					}
				]
			},
			{
				key: "CONTACT_INFO",
				fields: [
					{
						label: localeContent.CONTACT_NAME_LABEL,
						name: "contactName",
						gridConfig: { md: 6 },
						isRequired: true
					},
					{
						label: localeContent.CONTACT_EMAIL_LABEL,
						name: "contactEmail",
						gridConfig: { md: 6 },
						fieldType: FieldTypes.EMAIL,
						isRequired: true
					},
					{
						label: localeContent.CONTACT_PHONE_LABEL,
						name: "contactPhone",
						gridConfig: { md: 6 },
						fieldType: FieldTypes.PHONE_NUMBER,
						isRequired: true
					},
					{
						label: localeContent.CONTACT_ADDRESS_LABEL,
						name: "contactAddress",
						gridConfig: { md: 6 },
						isRequired: true
					},
					{
						label: localeContent.IS_BILLING_INFO_APPLICABLE_LABEL,
						name: "billingInfoApplicable",
						fieldType: FieldTypes.CHECKBOX_SINGLE,
						defaultValue: false
					}
				]
			},
			{
				key: "BILLING_INFO",
				renderIf: formValues => formValues.billingInfoApplicable,
				fields: [
					{
						label: localeContent.BILLING_CONTACT_NAME_LABEL,
						name: "billingContactName",
						gridConfig: { md: 6 }
					},
					{
						label: localeContent.BILLING_CONTACT_EMAIL_LABEL,
						name: "billingContactEmail",
						gridConfig: { md: 6 },
						fieldType: FieldTypes.EMAIL
					},
					{
						label: localeContent.BILLING_CONTACT_PHONE_LABEL,
						name: "billingContactPhone",
						gridConfig: { md: 6 },
						fieldType: FieldTypes.PHONE_NUMBER
					},
					{
						label: localeContent.BILLING_CONTACT_ADDRESS_LABEL,
						name: "billingContactAddress",
						gridConfig: { md: 6 }
					}
				]
			}
		]
	},
	{
		label: localeContent.TAB_LABEL_INSERTION_ORDERS,
		key: "INSERTION_ORDERS",
		disableOnCreate: true,
		childResourceConfig: {
			columnsConfig: [
				{
					name: commonLocaleContent.COLUMN_HEADER_STATUS,
					key: COLUMN_KEY_STATUS,
					cellDataType: "upperCase"
				}
			],
			resourceEndpoint: "manage/insertion-orders",
			resourceRoute: "insertion-orders",
			resourceName: commonLocaleContent.INSERTION_ORDER,
			getRequestParams: advId => ({
				advId
			})
		}
	}
];

const viewReportConfig = {
	permissionsRequired: ["VIEW_REPORT_BUTTON", "VIEW_CAMPAIGN_REPORT"],
	reportType: "campaign",
	filterKeyValuesGetter: context => {
		return {
			buyer: context.initData.guid
		};
	}
};

function AdvertiserResourceScreen() {
	return (
		<CrudForm
			resourceEndpoint="manage/advtsrs"
			resourceRoute="advertisers"
			resourceString={commonLocaleContent.ADVERTISER}
			preSubmit={formValues => {
				const submissionValues = { ...formValues };
				if (!formValues.billingInfoApplicable) {
					submissionValues.billingContactName = null;
					submissionValues.billingContactEmail = null;
					submissionValues.billingContactPhone = null;
					submissionValues.billingContactAddress = null;
				}
				return submissionValues;
			}}
			tabsConfig={tabsConfig}
			viewReportConfig={viewReportConfig}
		/>
	);
}

export default AdvertiserResourceScreen;
