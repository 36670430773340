import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useField } from "formik";
import localeContent from "./localeContent";
import FormField from "../CrudForm/FormField";
import Switch from "../FormFieldSwitch/Switch";
import NumberField from "../FormFieldNumber/NumberField";

const validFrequencyCapSchema = Yup.number()
	.transform((value, originalValue) =>
		typeof originalValue === "string" && originalValue.trim() === ""
			? undefined
			: value
	)
	.typeError(localeContent.NUMBER_TYPE_WARNING)
	.required(localeContent.REQUIRED_WHEN_ENABLED_WARNING)
	.integer(localeContent.INTEGER_WARNING)
	.max(32767, localeContent.MAX_VALUE_EXCEEDED_WARNING)
	.positive(localeContent.GREATER_THAN_ZERO_WARNING);

const enableFrequencyCapReducer = (state, action) => {
	switch (action.type) {
		case "ENABLE_FREQUENCY_CAP":
			return {
				...state,
				enableFrequencyCappingChanged: true,
				enableFrequencyCapping: true
			};
		case "DISABLE_FREQUENCY_CAP":
			return {
				...state,
				enableFrequencyCappingChanged: true,
				enableFrequencyCapping: false
			};
		case "RESET_FREQUENCY_CAPPING_CHANGED":
			return {
				...state,
				enableFrequencyCappingChanged: false
			};
		default:
			throw new Error();
	}
};

function FormFieldFrequencyCap(props) {
	const { name, formValues } = props;

	const [state, dispatch] = useReducer(enableFrequencyCapReducer, {
		enableFrequencyCappingChanged: false,
		enableFrequencyCapping: formValues.frequencyCap !== -1
	});

	const [inputValue, setInputValue] = React.useState(
		formValues.frequencyCap !== -1 ? formValues.frequencyCap : ""
	);

	const [field, meta, helpers] = useField({
		name,
		validate: value => {
			if (!state.enableFrequencyCapping) {
				return undefined;
			}
			return validFrequencyCapSchema
				.validate(value)
				.then(() => undefined)
				.catch(err => {
					return err.message;
				});
		}
	});
	const showError = Boolean(meta.touched && meta.error);
	const helperText = showError ? meta.error : "";

	// We need to set the value of the field to -1 if frequency capping is not enabled, or else use the value from the input
	useEffect(() => {
		if (!state.enableFrequencyCappingChanged) return;
		if (!state.enableFrequencyCapping) {
			helpers.setValue(-1);
		} else {
			helpers.setValue(inputValue);
		}
		dispatch({ type: "RESET_FREQUENCY_CAPPING_CHANGED" });
	}, [
		inputValue,
		state.enableFrequencyCappingChanged,
		state.enableFrequencyCapping,
		helpers
	]);

	return (
		<>
			<FormField
				gridConfig={{ md: 3, lg: 2 }}
				tooltip={localeContent.ENABLE_FREQUENCY_CAP_TOOLTIP}
			>
				<Switch
					id="enableFrequencyCapping"
					name="enableFrequencyCapping"
					label={localeContent.ENABLE_FREQUENCY_CAP_LABEL}
					value={state.enableFrequencyCapping}
					onChange={event => {
						dispatch({
							type: event.target.checked
								? "ENABLE_FREQUENCY_CAP"
								: "DISABLE_FREQUENCY_CAP"
						});
					}}
					required
				/>
			</FormField>
			{state.enableFrequencyCapping && (
				<FormField
					gridConfig={{ md: 3, lg: 2 }}
					tooltip={localeContent.FREQUENCY_CAP_TOOLTIP}
				>
					<NumberField
						id={name}
						name={name}
						label={localeContent.FREQUENCY_CAP_LABEL}
						value={inputValue || field.value || ""}
						onChange={event => {
							const fieldValue = event.target.value;
							setInputValue(fieldValue);
							helpers.setValue(fieldValue);
						}}
						onBlur={field.onBlur}
						showError={showError}
						helperText={helperText}
						required
					/>
				</FormField>
			)}
		</>
	);
}

FormFieldFrequencyCap.propTypes = {
	name: PropTypes.string.isRequired,
	formValues: PropTypes.shape()
};

FormFieldFrequencyCap.defaultProps = {
	formValues: {}
};

export default FormFieldFrequencyCap;
