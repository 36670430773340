export default {
	NEXT_STEP_MESSAGE:
		"If the email address you entered matches one in our system you will receive an email with password reset instructions in the next few minutes. If you do not receive a message please contact <a href='mailto:support@column6.com' style='color:white'>support</a>.",
	EMAIL_ADDRESS_LABEL: "Email Address",
	FORGOT_PASSWORD_LABEL: "Forgot Password",
	SUBMIT_BUTTON_LABEL: "Submit",
	BACK_TO_LOGIN_LABEL: "Back to login screen",
	INVALID_EMAIL_MESSAGE: "Must be a valid email address",
	FORGOT_PASSWORD_FORM_TITLE: "Forgot Password"
};
