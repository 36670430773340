export default {
	FIELD_RTB_OBJECT: {
		LABEL: "Extension Parent Object",
		OPTIONS: {
			BID_REQUEST: "Bid Request",
			SOURCE: "Source",
			REGS: "Regs",
			IMP: "Imp",
			BANNER: "Banner",
			VIDEO: "Video",
			PMP: "PMP (Private MarketPlace)",
			SITE: "Site",
			APP: "App",
			PUBLISHER: "Publisher",
			CONTENT: "Content",
			DEVICE: "Device",
			GEO: "Geo",
			USER: "User"
		}
	},
	FIELD_LABEL_VALUE: "Extension Value",
	FIELD_LABEL_NAME: "Extension Parameter",
	ARIA_LABEL_DELETE_ROW: "Remove Extension",
	BUTTON_TEXT_ADD_ROW: "Add Extension",
	UNIQUE_PARAMETER_WARNING: "Duplicate Parameter per bidder are not allowed."
};
