import { buildColumnDefinition } from "../../../utils/ReactTableUtils/ReactTableUtils";
import {
	MEDIA_TYPE_DISPLAY_ID,
	TABLE_DATA_TYPE
} from "../../../config/constants";
import AlignmentColumnEditLink from "../AlignmentColumnEditLink";

export default [
	buildColumnDefinition({
		Cell: AlignmentColumnEditLink,
		Header: "Name",
		accessor: "name",
		preventVisibilityToggle: true,
		enableGlobalFilter: true,
		ext: {
			getEditURL: row => {
				return `/dashboard/placements/${row.id}`;
			}
		}
	}),
	buildColumnDefinition({
		Header: "Site Name",
		accessor: "siteName"
	}),
	buildColumnDefinition({
		Header: "Publisher Name",
		accessor: "publisherName"
	}),
	buildColumnDefinition({
		Header: "Placement Type",
		accessor: "typeName"
	}),
	buildColumnDefinition({
		Header: "Size",
		accessor: "size",
		renderIf: values => values.mediaTypeId === MEDIA_TYPE_DISPLAY_ID
	}),
	buildColumnDefinition({
		Header: "Floor Price ($)",
		accessor: "floor",
		type: TABLE_DATA_TYPE.CURRENCY
	}),
	buildColumnDefinition({
		Header: "Revenue Share",
		accessor: "revenueShare",
		type: TABLE_DATA_TYPE.PERCENTAGE
	}),
	buildColumnDefinition({
		Header: "Pub Revenue ($)",
		accessor: "publisherRevenue",
		type: TABLE_DATA_TYPE.CURRENCY
	}),
	buildColumnDefinition({
		Header: "Pub Margin",
		accessor: "publisherMargin",
		type: TABLE_DATA_TYPE.PERCENTAGE
	}),
	buildColumnDefinition({
		Header: "Revenue ($)",
		accessor: "revenue",
		type: TABLE_DATA_TYPE.CURRENCY
	}),
	buildColumnDefinition({
		Header: "Margin",
		accessor: "margin",
		type: TABLE_DATA_TYPE.PERCENTAGE
	}),
	buildColumnDefinition({
		Header: "Status",
		accessor: "status",
		ext: {
			filterType: TABLE_DATA_TYPE.STATUS
		}
	})
];
