export default {
	COLUMN_COOKIE_SYNC_URL: "Cookie Sync URL",
	COLUMN_RTB_VERSION: "Open RTB Version",
	TAB_LABEL_BID_PARAMETERS: "Bid Parameters",
	FIELD_BIDDER_NAME: {
		LABEL: "Bidder Name"
	},
	FIELD_RTB_SPEC_VERSION: {
		LABEL: "OpenRTB Version"
	},
	FIELD_TEST_MODE: {
		LABEL: "Test Mode"
	},
	FIELD_MULTIPLE_IMP_OBJECTS: {
		LABEL: "Multiple Imp Objects",
		TOOLTIP:
			"If enabled, multiple Imp objects will be included in requests sent to this bidder (when applicable)."
	},
	FIELD_COMPANY_ACCESS_RESTRICTION: {
		TYPE_LABEL: "Company Access",
		TYPE_NONE_LABEL: "All",
		BLACKLIST_LABEL: "Company Block List",
		WHITELIST_LABEL: "Company Allow List"
	},
	DEMAND_FEE_PERCENTAGE: {
		LABEL: "Demand Fee Percentage",
		TOOLTIP:
			"Percentage of revenue we pay to demand partners during active transactions."
	},
	FIELD_COOKIE_SYNC_URL: {
		LABEL: "Cookie Sync URL"
	},
	FIELD_COOKIE_SYNC_PERCENTAGE: {
		LABEL: "Cookie Sync Percentage"
	},
	FIELD_MATCHED_COOKIE_BIDDING: {
		LABEL: "Matched Cookie Bidding",
		TOOLTIP:
			"If 'Matched Cookie Bidding' is selected a bid request will only be sent when the user's browser contains the Bidder's 'Cookie Sync URL'."
	},
	FIELD_SUPPORTED_RTB_MEDIA_TYPES: {
		LABEL: "RTB Media Type",
		TOOLTIP: "Specify the types of inventory which this bidder can bid on.",
		OPTION_LABELS: {
			VIDEO: "Video",
			DISPLAY: "Display",
			MOBILE: "Mobile"
		}
	},
	FIELD_COMPRESS_BID_REQUESTS: {
		LABEL: "Compress Bid Requests",
		TOOLTIP: "If Yes, a gzipped bid request will be sent to the bidder."
	},
	FIELD_FLOOR: {
		LABEL: "RTB Floor"
	},
	FIELD_RESPONSE_TIMEOUT: {
		LABEL: "Timeout Duration"
	},
	FIELD_CLASS_OVERRIDE: {
		LABEL: "Class Override"
	},

	FIELD_BID_REQUEST_EXTENSION_CONFIG: {
		LABEL: "Extension Parent Object"
	},

	FIELD_BID_REQUEST_CONFIG: {
		LABEL: "Omitted Bid Request Parameters",
		TOOLTIP:
			"Selected parameters will be omitted from the BidRequest Object of the bid request."
	},
	FIELD_BID_SOURCE_CONFIG: {
		LABEL: "Omitted Source Parameters",
		TOOLTIP:
			"The primary purpose of the below parameters is to define post-auction or upstream decisions when the exchange itself does not control the final decision. Selected parameters will be omitted from the Source Object of the bid request."
	},
	FIELD_BID_IMPRESSION_CONFIG: {
		LABEL: "Omitted Impression Parameters",
		TOOLTIP:
			"Selected parameters will be omitted from the Impression Object of the bid request."
	},
	FIELD_BID_BANNER_CONFIG: {
		LABEL: "Omitted Banner Parameters",
		TOOLTIP:
			"Selected parameters will be omitted from the Banner Object of the bid request."
	},
	FIELD_BID_VIDEO_CONFIG: {
		LABEL: "Omitted Video Parameters",
		TOOLTIP:
			"Selected parameters will be omitted from the Video object of the bid request. Supported Frameworks are VPAID, MRAID, etc. Supported Delivery Methods are Streaming and Progressive."
	},
	FIELD_CONTENT_DELIVERY_METHOD_CONFIG: {
		LABEL: "Content Delivery Type",
		TOOLTIP:
			"Supported delivery methods (e.g., streaming, progressive). If none specified, assume all are supported.",
		OPTION_LABELS: {
			STREAMING: "Streaming",
			PROGRESSIVE: "Progressive",
			DOWNLOAD: "Download"
		}
	},
	FIELD_LINEARITY_CONFIG: {
		LABEL: "Video Linearity Type",
		TOOLTIP: "Indicates if the impression must be linear or nonlinear.",
		OPTION_LABELS: {
			LINEAR: "Linear",
			NON_LINEAR: "Non-Linear"
		}
	},
	FIELD_BID_DEAL_CONFIG: {
		LABEL: "Omitted Deal Parameters",
		TOOLTIP:
			"Selected parameters will not be included in the Deal Object of the bid request."
	},
	FIELD_BID_SITE_CONFIG: {
		LABEL: "Omitted Site Parameters",
		TOOLTIP:
			"Selected parameters will not be included in the Site Object of the bid request."
	},
	FIELD_BID_PUBLISHER_CONFIG: {
		LABEL: "Omitted Publisher Parameters",
		TOOLTIP:
			"Selected parameters will be omitted from the Publisher Object of the bid request."
	},
	FIELD_BID_DEVICE_CONFIG: {
		LABEL: "Omitted Device Parameters",
		TOOLTIP:
			"Selected parameters will be omitted from the Device Object of the bid request."
	},
	FIELD_BID_GEO_CONFIG: {
		LABEL: "Omitted Geo Parameters",
		TOOLTIP:
			"Selected parameters will be omitted from the Geo Object of the bid request."
	},
	FIELD_BID_USER_CONFIG: {
		LABEL: "Omitted User Parameters",
		TOOLTIP:
			"Selected parameters should be omitted from the User Object of the bid request."
	},
	TARGETING_TYPE_DOMAIN_LABEL: "Domain",
	TARGETING_TYPE_ADVERTISER_DOMAIN_LABEL: "Advertiser Domain"
};
