import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";

function useParsedQueryParams() {
	const locationSearch = useLocation().search;
	const parsedParams = useMemo(() => {
		// Slice to remove the "?" from the search string
		return qs.parse(locationSearch.slice(1), {
			// by default, qs will limit specifying indices in an array to a maximum index of 20 https://github.com/ljharb/qs#parsing-arrays
			// we pick 40 as an arbitrarily "large enough" value to cover the max number of items. Integration tests should hopefully catch any failures that would require us to increase the number
			arrayLimit: 40
		});
	}, [locationSearch]);
	return parsedParams;
}

export default useParsedQueryParams;
