import * as Yup from "yup";
import validators from "../utils/validators";

const yupConfig = Yup;

// We don'mt want to add an error to the first instance of a "duplicated" property
const isFirstOccurrence = (parentValues, rowValue, propertyName) => {
	return (
		parentValues.find(
			value => value[propertyName] === rowValue[propertyName]
		) === rowValue
	);
};

Yup.addMethod(Yup.object, "uniqueProperty", function addUniqueProperty(
	propertyName,
	message
) {
	return this.test("uniqueProperty", message, function testUniqueProperty(
		value
	) {
		if (
			this.parent
				.filter(v => v !== value)
				.some(v => v[propertyName] === value[propertyName]) &&
			!isFirstOccurrence(this.parent, value, propertyName)
		) {
			throw this.createError({
				path: `${this.path}.${propertyName}`,
				message
			});
		}

		return true;
	});
});

Yup.addMethod(Yup.string, "validDomain", function addValidDomain(message) {
	return this.test(
		"validDomain",
		message,
		validators.validateDomainEmptyAsValid
	);
});
export default yupConfig;
