import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Chip, Divider, Popover, useTheme } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import FilterForm, {
	FIELD_NAME_DIMENSION,
	FIELD_NAME_COMPARATOR,
	getValidationSchema,
	FIELD_NAME_FILTER_TYPE,
	FIELD_NAME_VALUE
} from "./FilterForm";
import { FILTER_TYPE } from "../../config/constants";
import lc from "./localeContent";

export const TEST_ID_FILTER_TOOLBAR_CHIP = "filter-toolbar-chip";

const getDefaultInitialFormValues = () => ({
	[FIELD_NAME_DIMENSION]: "",
	[FIELD_NAME_COMPARATOR]: "=",
	[FIELD_NAME_FILTER_TYPE]: null,
	[FIELD_NAME_VALUE]: ""
});

const getAvailableDimensionOptions = (
	filters,
	dimensionOptions,
	editedDimension
) => {
	const selectedDimensions = filters.reduce(
		(selectedDimensionMap, filter) => ({
			...selectedDimensionMap,
			[filter.dimension]: true
		}),
		{}
	);
	return dimensionOptions.filter(
		option =>
			!selectedDimensions[option.value] || option.value === editedDimension
	);
};

const convertFilterFieldValueByType = filter => {
	const { type } = filter;
	const convertedFilter = { ...filter };
	switch (type) {
		case "percent":
			convertedFilter[FIELD_NAME_VALUE] /= 100;
			break;
		default:
			break;
	}
	return convertedFilter;
};

const getInitialFormValueByFilterType = type => {
	switch (type) {
		case FILTER_TYPE.ALPHA_MULTIPLE:
			return [];
		case FILTER_TYPE.ALPHA_SINGLE:
		case FILTER_TYPE.CURRENCY:
		case FILTER_TYPE.PERCENTAGE:
			return "";
		default:
			return null;
	}
};

function FiltersToolbar(props) {
	const {
		filters,
		setFilters,
		dimensionOptions,
		dimensionValuesOptions,
		onSelectDimension,
		fetchingStatus,
		fixedFilters,
		validationSchemaOptions,
		typeaheadDetails,
		pageTitleBarHasTabs
	} = props;

	const [editedDimension, setEditedDimension] = useState(null);
	const availableDimensionOptions = useMemo(
		() =>
			getAvailableDimensionOptions(filters, dimensionOptions, editedDimension),
		[filters, dimensionOptions, editedDimension]
	);

	const [anchorEl, setAnchorEl] = useState(null);
	const openFilterMenu = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const isFilterMenuOpen = Boolean(anchorEl);
	const id = isFilterMenuOpen ? "filter-menu" : undefined;
	const [initialFormValues, setInitialFormValues] = useState(null);
	const saveFilter = formValues => {
		// Set the dimension while the modal is closing to prevent removal of the option from the list
		setEditedDimension(formValues.dimension);
		setInitialFormValues(null);
		handleClose();
		const { filterIndex, ...filter } = formValues;
		if (filterIndex !== undefined) {
			const newFilters = [...filters];
			newFilters.splice(filterIndex, 1, convertFilterFieldValueByType(filter));
			setFilters(newFilters);
		} else {
			setFilters([...filters, formValues]);
		}
	};

	const removeFilter = useCallback(
		filterIndex => () => {
			const newFilters = [...filters];
			newFilters.splice(filterIndex, 1);
			setFilters(newFilters);
		},
		[filters, setFilters]
	);

	const handleSelectDimension = useCallback(
		dimension => {
			const { type } = dimension;
			setInitialFormValues(existing => ({
				...existing,
				[FIELD_NAME_VALUE]: getInitialFormValueByFilterType(type)
			}));

			if (onSelectDimension) {
				onSelectDimension(dimension);
			}
		},
		[onSelectDimension]
	);
	const theme = useTheme();

	const stickyToolbarStyles = {
		position: "sticky",
		zIndex: 1100,
		top: pageTitleBarHasTabs ? "128px" : "80px",
		left: "auto",
		right: 0
	};

	return (
		<Box
			sx={{
				...stickyToolbarStyles,
				backgroundColor: theme.palette.grey[200]
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					px: 3,
					pt: 2,
					pb: 1
				}}
			>
				{!isEmpty(fixedFilters) && (
					<>
						<Box>{fixedFilters}</Box>
						<Divider orientation="vertical" flexItem sx={{ mx: 1.5 }} />
					</>
				)}
				{filters.map((filter, index) => {
					const filterDimension = filter.dimension;

					const filteredDimensionOption = dimensionOptions.find(
						dimensionOption => dimensionOption.value === filterDimension
					);
					if (!filteredDimensionOption) {
						return null;
					}
					return (
						<Chip
							key={filteredDimensionOption.label}
							label={filteredDimensionOption.label}
							onClick={event => {
								setEditedDimension(filterDimension);
								setInitialFormValues({ ...filter, filterIndex: index });
								openFilterMenu(event);
							}}
							onDelete={removeFilter(index)}
							data-testid={TEST_ID_FILTER_TOOLBAR_CHIP}
						/>
					);
				})}
				<Button
					onClick={event => {
						setEditedDimension(null);
						setInitialFormValues(getDefaultInitialFormValues());
						openFilterMenu(event);
					}}
					aria-describedby={id}
					startIcon={<AddIcon />}
				>
					{lc.ADD_ALIGNMENT_FILTER_BUTTON_LABEL}
				</Button>
			</Box>
			<Popover
				id={id}
				open={isFilterMenuOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left"
				}}
			>
				<Formik
					initialValues={initialFormValues}
					validationSchema={getValidationSchema(validationSchemaOptions)}
					onSubmit={saveFilter}
				>
					<FilterForm
						handleClose={handleClose}
						dimensionOptions={availableDimensionOptions}
						dimensionValuesOptions={dimensionValuesOptions}
						onSelectDimension={handleSelectDimension}
						fetchingStatus={fetchingStatus}
						typeaheadDetails={typeaheadDetails}
						editedDimension={editedDimension}
					/>
				</Formik>
			</Popover>
			<Divider />
		</Box>
	);
}

FiltersToolbar.propTypes = {
	filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	setFilters: PropTypes.func.isRequired,
	dimensionOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	dimensionValuesOptions: PropTypes.shape().isRequired,
	onSelectDimension: PropTypes.func,
	fetchingStatus: PropTypes.shape(),
	fixedFilters: PropTypes.arrayOf(PropTypes.node),
	validationSchemaOptions: PropTypes.shape({
		alphaMultipleSchema: PropTypes.shape()
	}),
	typeaheadDetails: PropTypes.shape(),
	pageTitleBarHasTabs: PropTypes.bool
};

FiltersToolbar.defaultProps = {
	onSelectDimension: undefined,
	fetchingStatus: undefined,
	fixedFilters: [],
	validationSchemaOptions: undefined,
	typeaheadDetails: undefined,
	pageTitleBarHasTabs: true
};
export default FiltersToolbar;
