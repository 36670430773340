export default {
	PAGE_TITLE: "Notification",
	STATUS: "Status",
	TYPE: "Type",
	CREATED_BY: "Created By",
	CREATE_DATE: "Create Date",
	NOTIFICATION_DATE: "Notification Date",
	INACTIVE_DATE: "Inactive Date",
	AUDIENCE_SIZE: "Audience Size",
	NOT_APPLICABLE: "N/A",
	NOTIFICATION_NAME: "Name",
	NOTIFICATION_TYPE: "Notification Type",
	GENERAL_NOTIFICATION: "General Notification",
	OPTIMIZATION_ALERT: "Optimization Alert",
	PRODUCT_UPDATE: "Product Update",
	SYSTEM_MAINTENANCE: "System Maintenance",
	NOTIFICATION_TEXT: "Notification Text",
	SEND_IMMEDIATELY: "Send Immediately",
	FORCE_NOTIFICATION: "Force Notification",
	FORCE_NOTIFICATION_TOOLTIP:
		"If enabled, the Notification will appear, unprompted, to all users",
	NOTIFICATION_DATE_TIME: "Notification Date/Time",
	NOTIFICATION_DATE_TIME_TOOLTIP:
		"Specify the date and time at which the notification should be sent",
	SET_EXPIRATION: "Set Expiration",
	SET_EXPIRATION_TOOLTIP:
		"If No, the notification will never leave the user’s mailbox unless manually deleted",
	EXPIRES_IN: "Expires In",
	AUDIENCE_SELECTION_TYPE: "Audience Selection Type",
	COMPANY: "Company",
	USER: "User",
	USER_ROLE: "User Role",
	COMPANY_AUDIENCE_SELECTION: "Company Audience Selection",
	AUDIENCE_SELECTION_TYPE_OPTION_TOOLTIP:
		"At least one user is required between the 3 audience selection types",
	FIELD_IS_REQUIRED: "Field is required",
	USER_AUDIENCE_SELECTION: "User Audience Selection",
	ROLE_AUDIENCE_SELECTION: "Role Audience Selection",
	NOTIFICATION_DATE_TIME_ERROR: "Notification date cannot be in the past",
	SAVE: "Save",
	EXPIRATION_DAYS_ERROR: "# of days must be at least 1.",

	DELETED_SUCCESSFULLY_MESSAGE:
		"The notification has been deleted successfully!",
	DELETE_NOTIFICATION_MODAL: {
		TITLE: "Delete Notification",
		DESCRIPTION: "Are you sure you want to delete?",
		DELETE_BUTTON_LABEL: "Delete"
	},
	NO_NOTIFICATION_MESSAGE: "There are no Notifications at this time"
};
