import React from "react";
import * as Yup from "yup";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import CrudForm from "../../components/CrudForm/CrudForm";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import commonFieldOptions from "../commonFieldOptions";
import PLACEMENT_ALIGNMENT_COLUMNS from "../../components/AlignmentTool/sharedColumnsConfig/placementAlignmentColumns";
import AlignmentTool from "../../components/AlignmentTool/AlignmentTool";
import validators from "../../utils/validators";
import Operators from "../../components/FormFieldExpressionBuilder/Operators";

import {
	BREADCRUMBS_ENUM,
	MEDIA_TYPE_VIDEO_ID,
	MEDIA_SOURCE_ASSET_ID,
	CREATIVE_TYPE_MEDIA_URL,
	MEDIA_TYPE_DISPLAY_ID,
	EXPRESSION_NODE_TYPE
} from "../../config/constants";

import { buildValidationSchema } from "../../components/FormFieldExpressionBuilder/FormFieldExpressionBuilder";
import CustomUrlParamsFieldDefinition from "../../components/CrudForm/sharedFieldConfigDefinitions/CustomUrlParams/CustomUrlParamsFieldDefinition";

const MEDIA_SOURCE_AD_TAG_ID = "tag";
const CREATIVE_TYPE_MEDIA_FILE = "mediaFile";
const POSITION_PRE_ROLL_ID = "pre";

const shouldRenderPcpmFields = (formValues, initData) =>
	initData.supportedPricingOptions === "PCPM" ||
	(initData.supportedPricingOptions === "ANY" && formValues.enablePCPM);

const breadcrumbsConfig = [
	BREADCRUMBS_ENUM.ADVERTISER,
	BREADCRUMBS_ENUM.INSERTION_ORDER,
	BREADCRUMBS_ENUM.CAMPAIGN,
	BREADCRUMBS_ENUM.NAME
];

const tabsConfig = [
	{
		key: "SETTINGS",
		label: commonLocaleContent.TAB_LABEL_SETTINGS,
		fieldsConfig: [
			{
				key: "PUBLISHER_SETTINGS",
				hideOnEdit: true,
				fields: [
					{
						name: "campaignId",
						label: localeContent.FIELD_CAMPAIGN_ID.LABEL,
						tooltip: localeContent.FIELD_CAMPAIGN_ID.TOOLTIP,
						isRequired: true,
						disableOnEdit: true,
						gridConfig: { md: 8 },
						fieldType: FieldTypes.COMBO_BOX,
						optionsConfig: {
							requestEndpoint: "manage/search/campaigns"
						}
					}
				]
			},
			{
				key: "BASIC_SETTINGS",
				renderIf: formValues => formValues.campaignId,
				fields: [
					{
						name: "name",
						label: localeContent.FIELD_MEDIA_NAME.LABEL,
						gridConfig: { md: 8 },
						isRequired: true
					},
					{
						name: "status",
						label: commonLocaleContent.FIELD_LABEL_STATUS,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: commonFieldOptions.mapEnumToFieldOptions(
								commonFieldOptions.STATUS_ENUM
							)
						},
						defaultValue: commonFieldOptions.STATUS_ENUM.active.value
					},
					{
						name: "enablePCPM",
						label: localeContent.FIELD_ENABLE_PCPM.LABEL,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									value: false,
									label: localeContent.FIELD_ENABLE_PCPM.OPTION_LABELS.CPM
								},
								{
									value: true,
									label: localeContent.FIELD_ENABLE_PCPM.OPTION_LABELS.PCPM
								}
							]
						},
						renderIf: (formValues, initData) =>
							initData.supportedPricingOptions === "ANY",
						defaultValue: false
					},
					{
						name: "cpm",
						label: localeContent.FIELD_CPM.LABEL,
						tooltip: localeContent.FIELD_CPM.TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.CURRENCY,
						renderIf: (formValues, initData) =>
							initData.supportedPricingOptions === "CPM" ||
							(initData.supportedPricingOptions === "ANY" &&
								!formValues.enablePCPM),
						disableIf: (formValues, initData) => initData.houseAd,
						isRequiredIfRendered: true,
						validationSchema: Yup.number().when("enablePCPM", {
							is: enablePCPM => enablePCPM !== "true",
							then: Yup.number().required(
								`Required when ${localeContent.FIELD_ENABLE_PCPM.LABEL} is ${localeContent.FIELD_ENABLE_PCPM.OPTION_LABELS.CPM}`
							)
						})
					},
					{
						name: "pcpm",
						label: localeContent.FIELD_PCPM.LABEL,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.CURRENCY,
						renderIf: shouldRenderPcpmFields,
						isRequiredIfRendered: true,
						validationSchema: Yup.number().when("enablePCPM", {
							is: enablePCPM => enablePCPM === "true",
							then: Yup.number().required(
								`Required when ${localeContent.FIELD_ENABLE_PCPM.LABEL} is ${localeContent.FIELD_ENABLE_PCPM.OPTION_LABELS.CPM}`
							)
						})
					},
					{
						name: "thirdPartyId",
						label: localeContent.FIELD_PCPM_API_ID.LABEL,
						gridConfig: { md: 3 },
						renderIf: shouldRenderPcpmFields
					},
					{
						name: "mediaTypeId",
						label: localeContent.FIELD_MEDIA_TYPE.LABEL,
						tooltip: localeContent.FIELD_MEDIA_TYPE.TOOLTIP,
						gridConfig: { md: 4, lg: 3, offset: { fullRowClear: true } },
						fieldType: FieldTypes.RADIO,
						disableOnEdit: true,
						optionsConfig: {
							useNumberValues: true,
							options: [
								{
									value: MEDIA_TYPE_VIDEO_ID,
									label: localeContent.FIELD_MEDIA_TYPE.OPTION_LABELS.VIDEO
								},
								{
									value: MEDIA_TYPE_DISPLAY_ID,
									label: localeContent.FIELD_MEDIA_TYPE.OPTION_LABELS.DISPLAY
								}
							]
						},
						defaultValue: MEDIA_TYPE_VIDEO_ID
					},
					{
						name: "mediaSource",
						label: localeContent.FIELD_MEDIA_SOURCE.LABEL,
						tooltip: localeContent.FIELD_MEDIA_SOURCE.TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.RADIO,
						disableOnEdit: true,
						optionsConfig: {
							options: [
								{
									value: MEDIA_SOURCE_AD_TAG_ID,
									label: localeContent.FIELD_MEDIA_SOURCE.OPTION_LABELS.AD_TAG
								},
								{
									value: MEDIA_SOURCE_ASSET_ID,
									label: localeContent.FIELD_MEDIA_SOURCE.OPTION_LABELS.ASSET
								}
							]
						},
						defaultValue: MEDIA_SOURCE_AD_TAG_ID
					},
					{
						name: "creativeType",
						label: localeContent.FIELD_CREATIVE_TYPE.LABEL,
						tooltip: localeContent.FIELD_CREATIVE_TYPE.TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.RADIO,
						renderIf: formValues =>
							formValues.mediaTypeId === MEDIA_TYPE_VIDEO_ID &&
							formValues.mediaSource === MEDIA_SOURCE_ASSET_ID,
						disableOnEdit: true,
						optionsConfig: {
							options: [
								{
									value: CREATIVE_TYPE_MEDIA_FILE,
									label:
										localeContent.FIELD_CREATIVE_TYPE.OPTION_LABELS.MEDIA_FILE
								},
								{
									value: CREATIVE_TYPE_MEDIA_URL,
									label:
										localeContent.FIELD_CREATIVE_TYPE.OPTION_LABELS.MEDIA_URL
								}
							]
						},
						defaultValue: CREATIVE_TYPE_MEDIA_FILE
					},
					{
						name: "size",
						label: localeContent.FIELD_SIZE.LABEL,
						tooltip: localeContent.FIELD_SIZE.TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							requestEndpoint: "manage/catalogue/display-sizes",
							mapOptions: option => ({ value: option, label: option }),
							sortOptionsByName: false
						},
						renderIf: formValues =>
							formValues.mediaTypeId === MEDIA_TYPE_DISPLAY_ID,
						isRequiredIfRendered: true,
						validationSchema: Yup.string().when("mediaTypeId", {
							is: mediaTypeId => mediaTypeId === `${MEDIA_TYPE_DISPLAY_ID}`,
							then: Yup.string().required(
								`Required when ${localeContent.FIELD_MEDIA_TYPE.LABEL} is display`
							)
						})
					},
					{
						name: "cloneAlignments",
						label: localeContent.CLONE_ALIGNMENTS.LABEL,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: true,
						tooltip: localeContent.CLONE_ALIGNMENTS.TOOLTIP,
						renderIf: (formValues, initData, resourceId, isClonedResource) =>
							isClonedResource
					}
				]
			},
			{
				key: "AD_TAG_SETTINGS",
				renderIf: formValues =>
					formValues.campaignId &&
					formValues.mediaSource === MEDIA_SOURCE_AD_TAG_ID,
				fields: [
					{
						name: "adTag",
						label: localeContent.FIELD_AD_TAG.LABEL,
						tooltip: localeContent.FIELD_AD_TAG.TOOLTIP,
						fieldType: FieldTypes.MACRO_TEXT,
						isRequiredIfRendered: true,
						validationSchema: Yup.string().when("mediaSource", {
							is: mediaSource => mediaSource === MEDIA_SOURCE_AD_TAG_ID,
							then: Yup.string().required(
								`Required when ${localeContent.FIELD_MEDIA_SOURCE.LABEL} is ${localeContent.FIELD_MEDIA_SOURCE.OPTION_LABELS.AD_TAG}`
							)
						})
					},
					{
						name: "supportsVpaid",
						label: localeContent.FIELD_SUPPORTS_VPAID.LABEL,
						tooltip: localeContent.FIELD_SUPPORTS_VPAID.TOOLTIP,
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{ label: commonLocaleContent.OPTION_LABEL_YES, value: true },
								{ label: commonLocaleContent.OPTION_LABEL_NO, value: false }
							]
						},
						defaultValue: false,
						renderIf: formValues =>
							formValues.mediaTypeId === MEDIA_TYPE_VIDEO_ID
					},
					{
						name: "customTags",
						label: localeContent.CUSTOM_PARAMETER_PASSTHROUGH.LABEL,
						tooltip: localeContent.CUSTOM_PARAMETER_PASSTHROUGH.TOOLTIP,
						fieldType: FieldTypes.CUSTOM_TAG
					}
				]
			},
			{
				key: "MEDIA_ASSET_SETTINGS",
				renderIf: formValues =>
					formValues.campaignId &&
					formValues.mediaTypeId === MEDIA_TYPE_VIDEO_ID &&
					formValues.mediaSource === MEDIA_SOURCE_ASSET_ID &&
					formValues.creativeType === CREATIVE_TYPE_MEDIA_FILE,
				fields: [
					{
						name: "mediaFiles",
						fieldType: FieldTypes.MEDIA_FILE_MANAGER,
						defaultValue: []
					}
				]
			},
			{
				key: "IMAGE_ASSET_SETTINGS",
				renderIf: formValues =>
					formValues.campaignId &&
					formValues.mediaTypeId === MEDIA_TYPE_DISPLAY_ID &&
					formValues.mediaSource === MEDIA_SOURCE_ASSET_ID,
				fields: [
					{
						name: "displayMediaFile",
						fieldType: FieldTypes.IMAGE_FILE_MANAGER,
						defaultValue: {}
					},
					{
						name: "clickThroughUrl",
						label: localeContent.FIELD_CLICK_THROUGH_URL.LABEL
					}
				]
			},
			{
				key: "MEDIA_ASSET_URL_SETTINGS",
				renderIf: formValues =>
					formValues.campaignId &&
					formValues.mediaTypeId === MEDIA_TYPE_VIDEO_ID &&
					formValues.mediaSource === MEDIA_SOURCE_ASSET_ID &&
					formValues.creativeType === CREATIVE_TYPE_MEDIA_URL,
				fields: [
					{
						name: "mediaUrls",
						fieldType: FieldTypes.MEDIA_URL,
						defaultValue: []
					}
				]
			},
			{
				key: "CUSTOM_DATE_RANGE",
				renderIf: formValues => formValues.campaignId,
				fields: [
					{
						name: "customDateRange",
						fieldType: FieldTypes.CHECKBOX_SINGLE,
						defaultValue: false,
						label: localeContent.FIELD_CUSTOM_DATE_RANGE.LABEL,
						tooltip: localeContent.FIELD_CUSTOM_DATE_RANGE.TOOLTIP,
						gridConfig: { md: 4, lg: 3 }
					},
					{
						name: "customStartDate",
						fieldType: FieldTypes.DATE_TIME_PICKER,
						getFieldTypeOptions: ({ initData }) => ({
							minDateTime: initData.campaignStartDate,
							maxDateTime: initData.campaignEndDate || undefined,
							clearable: true,
							showTodayButton: true
						}),
						label: localeContent.FIELD_CUSTOM_START_DATE.LABEL,
						tooltip: localeContent.FIELD_CUSTOM_START_DATE.TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						validationSchema: Yup.date().when("customDateRange", {
							is: customDateRange => customDateRange === true,
							then: Yup.date().required(
								localeContent.FIELD_CUSTOM_START_DATE.REQUIRED
							)
						}),
						renderIf: formValues => formValues.customDateRange === true
					},
					{
						name: "customEndDate",
						fieldType: FieldTypes.DATE_TIME_PICKER,
						getFieldTypeOptions: ({ initData }) => ({
							minDateTime: initData.campaignStartDate,
							maxDateTime: initData.campaignEndDate || undefined,
							clearable: true
						}),
						label: localeContent.FIELD_CUSTOM_END_DATE.LABEL,
						tooltip: localeContent.FIELD_CUSTOM_END_DATE.TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						renderIf: formValues => formValues.customDateRange === true
					}
				]
			},
			{
				key: "VIDEO_OPTIONS",
				renderIf: formValues =>
					formValues.campaignId &&
					formValues.mediaTypeId === MEDIA_TYPE_VIDEO_ID,
				fields: [
					{
						name: "position",
						label: localeContent.FIELD_POSITION.LABEL,
						tooltip: localeContent.FIELD_POSITION.TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [
								{
									value: POSITION_PRE_ROLL_ID,
									label: localeContent.FIELD_POSITION.OPTION_LABELS.PRE_ROLL
								},
								{
									value: "mid",
									label: localeContent.FIELD_POSITION.OPTION_LABELS.MID_ROLL
								},
								{
									value: "post",
									label: localeContent.FIELD_POSITION.OPTION_LABELS.POST_ROLL
								}
							]
						},
						defaultValue: POSITION_PRE_ROLL_ID
					},
					{
						name: "duration",
						label: localeContent.FIELD_DURATION.LABEL,
						tooltip: localeContent.FIELD_DURATION.TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.NUMBER,
						defaultValue: 30
					},
					{
						name: "advertiserDomain",
						label: localeContent.FIELD_ADVERTISER_DOMAIN.LABEL,
						tooltip: localeContent.FIELD_ADVERTISER_DOMAIN.TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.TEXT,
						validationSchema: Yup.string().validDomain(
							commonLocaleContent.INVALID_DOMAIN_WARNING
						)
					},
					{
						name: "clickThroughUrl",
						label: localeContent.FIELD_CLICK_THROUGH_URL.LABEL
					}
				]
			},
			{
				key: "PRIORITY_SETTINGS",
				renderIf: formValues => formValues.campaignId,
				fields: [
					{
						name: "priority",
						label: localeContent.FIELD_MEDIA_PRIORITY.LABEL,
						tooltip: localeContent.FIELD_MEDIA_PRIORITY.TOOLTIP,
						gridConfig: { md: 3, lg: 2 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [...commonFieldOptions.PRIORITY]
						},
						defaultValue: ""
					},
					{
						name: "weight",
						label: localeContent.FIELD_MEDIA_WEIGHT.LABEL,
						tooltip: localeContent.FIELD_MEDIA_WEIGHT.TOOLTIP,
						gridConfig: { md: 3, lg: 2 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [...commonFieldOptions.WEIGHT]
						},
						defaultValue: ""
					}
				]
			}
		]
	},
	{
		key: "QUALITY",
		label: commonLocaleContent.TAB_LABEL_QUALITY,
		disableIf: formValues => !formValues.campaignId,
		fieldsConfig: [
			{
				key: "TRACKERS_GROUP",
				fields: [
					{
						name: "trackers",
						label: localeContent.FIELD_TRACKERS.LABEL,
						tooltip: localeContent.FIELD_TRACKERS.TOOLTIP,
						gridConfig: { md: 8, lg: 6 },
						fieldType: FieldTypes.MULTISELECT,
						permissionRequired: "VIEW_MEDIA_TRACKER",
						optionsConfig: {
							requestEndpoint: "manage/trackers",
							requestParams: { mediaTrackers: true, impressionOnly: true }
						},
						defaultValue: []
					},
					{
						name: "ivtFilteringRequired",
						label: localeContent.FIELD_REQUIRE_IVT_FILTERING.LABEL,
						tooltip: localeContent.FIELD_REQUIRE_IVT_FILTERING.TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									label: commonLocaleContent.OPTION_LABEL_ENABLED,
									value: true
								},
								{
									label: commonLocaleContent.OPTION_LABEL_DISABLED,
									value: false
								}
							]
						},
						defaultValue: false
					}
				]
			},
			{
				key: "GEO_TARGETING_SETTINGS",
				fields: [
					{
						name: "geoTargetingConfig",
						fieldType: FieldTypes.GEO_TARGETING,
						useInheritedValues: true
					}
				]
			},
			{
				key: "DEVICE_TARGETING_SETTINGS",
				fields: [
					{
						name: "deviceTargeting",
						fieldType: FieldTypes.DEVICE_TARGETING,
						useInheritedValues: true,
						defaultValue: { devices: [] }
					}
				]
			},
			{
				key: "SIZE_TARGETING_SETTINGS",
				fields: [
					{
						name: "sizeTargeting",
						fieldType: FieldTypes.SIZE_TARGETING
					}
				]
			},
			{
				key: "LIST_TARGETING_SETTINGS",
				fields: [
					{
						name: "blacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: localeContent.TARGETING_TYPE_DOMAIN_LABEL,
							validatorFunction: validators.validateDomain
						},
						useInheritedValues: true,
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "appNameBlacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: localeContent.TARGETING_TYPE_APP_NAME_LABEL,
							validatorFunction: validators.validateUTF8
						},
						useInheritedValues: true,
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "appBundleIdBlacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel:
								localeContent.TARGETING_TYPE_APP_BUNDLE_IDS_LABEL,
							validatorFunction: validators.validateUTF8
						},
						useInheritedValues: true,
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					}
				]
			},
			{
				key: "CUSTOM_QUERY_PARAMETER",
				fields: [CustomUrlParamsFieldDefinition]
			},
			{
				key: "AUDIENCE_BUILDER",
				permissionRequired: "VIEW_AUDIENCE_MENU",
				fields: [
					{
						name: "audienceExpression",
						fieldType: FieldTypes.EXPRESSION_BUILDER,
						validationSchema: buildValidationSchema({
							leafName: localeContent.FIELD_AUDIENCE.LEAF_NAME_LABEL,
							leafNodeKey: EXPRESSION_NODE_TYPE.AUDIENCE,
							canEnable: true
						}),
						fieldTypeOptions: {
							leafOptionsConfig: {
								leafOptionsEndpoint: "manage/audiences",
								leafOptionIdKey: "id",
								existingLeafField: "audiences",
								leafTableColumnsConfig: [
									{
										Header:
											localeContent.FIELD_AUDIENCE.LEAF_COLUMN_HEADERS.NAME,
										accessor: "name",
										isSortable: true
									},
									{
										Header:
											localeContent.FIELD_AUDIENCE.LEAF_COLUMN_HEADERS
												.ESTIMATED_CPM,
										accessor: "cpm",
										align: "right"
									},
									{
										Header:
											localeContent.FIELD_AUDIENCE.LEAF_COLUMN_HEADERS
												.POTENTIAL_COOKIE_REACH,
										accessor: "potentialReach",
										align: "right"
									},
									{
										Header:
											localeContent.FIELD_AUDIENCE.LEAF_COLUMN_HEADERS
												.POTENTIAL_DEVICE_REACH,
										accessor: "potentialDeviceReach",
										align: "right"
									}
								]
							},
							leafExpressionNodeRepresentation: {
								nodeType: EXPRESSION_NODE_TYPE.AUDIENCE,
								idKey: "audienceId",
								labelKey: "name"
							},
							emptyExpressionWarning:
								localeContent.FIELD_AUDIENCE.EMPTY_EXPRESSION_WARNING,
							enableFieldLabel: localeContent.FIELD_AUDIENCE.ENABLE_FIELD_LABEL,
							suppressOperators: [Operators.NOT]
						}
					}
				]
			}
		]
	},
	{
		key: "ALIGNMENTS",
		label: commonLocaleContent.TAB_LABEL_ALIGNMENTS,
		disableOnCreate: true,
		useContainer: false,
		componentConfig: {
			Component: AlignmentTool,
			props: {
				columns: PLACEMENT_ALIGNMENT_COLUMNS,
				tableOptionLabelAvailable: localeContent.AVAILABLE_PLACEMENTS,
				tableOptionLabelAligned: localeContent.ALIGNED_PLACEMENTS,
				availableOptionsEndpoint: "manage/alignment-options/placements",
				availableOptionsIdKey: "mediaId",
				entityTypeLabel: commonLocaleContent.MEDIA,
				alignmentEndpoint: "media",
				defaultShownColumns: ["name", "floor", "margin"],
				mapAlignedOptions: alignmentConfig => alignmentConfig?.placements || [],
				onSave: placements => ({
					placements,
					alignmentType: "STATIC",
					ronMargin: "0"
				})
			}
		}
	}
];

const viewReportConfig = {
	permissionsRequired: ["VIEW_REPORT_BUTTON", "VIEW_CAMPAIGN_REPORT"],
	reportType: "campaign",
	filterKeyValuesGetter: context => {
		return {
			media: context.initData.guid
		};
	}
};

function MediaResource() {
	return (
		<CrudForm
			resourceEndpoint="manage/media"
			resourceRoute="media"
			parentIdField="campaignId"
			parentStatusKey="campaignStatus"
			resourceString={commonLocaleContent.MEDIA}
			breadcrumbsConfig={breadcrumbsConfig}
			preSubmit={formValues => {
				const submissionValues = { ...formValues };
				if (submissionValues.mediaSource !== MEDIA_SOURCE_ASSET_ID) {
					submissionValues.mediaFiles = [];
					submissionValues.mediaUrls = [];
				} else if (submissionValues.creativeType === CREATIVE_TYPE_MEDIA_FILE) {
					submissionValues.mediaUrls = [];
				} else if (submissionValues.creativeType === CREATIVE_TYPE_MEDIA_URL) {
					submissionValues.mediaFiles = [];
				}
				return {
					...submissionValues,
					locked: false
				};
			}}
			tabsConfig={tabsConfig}
			cloneUrlWithId
			viewReportConfig={viewReportConfig}
		/>
	);
}

export default MediaResource;
