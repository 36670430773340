import React from "react";
import PropTypes from "prop-types";

import localeContent from "./localeContent";

import GenericTableRowActionMenu, {
	ACTION_MENU_ITEM_TYPES
} from "../GenericTableRowActionMenu/GenericTableRowActionMenu";

function CrudTableRowActionMenu(props) {
	const {
		rowData,
		rowItemNameKey,
		resourceRoute,
		resourceEndpoint,
		resourceName,
		deleteItemCallback,
		childResourceConfig,
		customOptions,
		disableClone,
		customStartPathEndPoint
	} = props;

	const { id: rowItemId, [rowItemNameKey]: rowItemName } = rowData;

	const childResourceOptions = childResourceConfig
		? [
				{
					key: "ADD_CHILD",
					type: ACTION_MENU_ITEM_TYPES.link,
					to: `/dashboard/${childResourceConfig.route}/INIT?parentId=${rowItemId}`,
					label: localeContent.ACTION_BUTTON_LABEL_ADD_CHILD({
						childName: childResourceConfig.name
					})
				},
				{
					key: "VIEW_CHILDREN",
					type: ACTION_MENU_ITEM_TYPES.link,
					to: `/dashboard/${childResourceConfig.route}?parentId=${rowItemId}`,
					label: localeContent.ACTION_BUTTON_LABEL_VIEW_CHILD({
						childName: childResourceConfig.name,
						childPluralName: childResourceConfig.pluralName
					})
				}
		  ]
		: [];

	const crudMenuOptions = [
		{
			key: "EDIT",
			type: ACTION_MENU_ITEM_TYPES.link,
			to: `/dashboard/${resourceRoute || resourceEndpoint}/${rowItemId}`,
			label: localeContent.ACTION_BUTTON_LABEL_EDIT
		},
		...childResourceOptions,
		...customOptions
	];

	if (!disableClone) {
		crudMenuOptions.push({
			key: "CLONE",
			type: ACTION_MENU_ITEM_TYPES.link,
			to: `/dashboard/${resourceRoute ||
				resourceEndpoint}/${rowItemId}?clone=true`,
			label: localeContent.ACTION_BUTTON_LABEL_CLONE
		});
	}

	if (deleteItemCallback) {
		crudMenuOptions.push({
			key: "DELETE",
			type: ACTION_MENU_ITEM_TYPES.deleteResource,
			label: localeContent.ACTION_BUTTON_LABEL_DELETE,
			title: localeContent.DELETE_RESOURCE_MODAL.TITLE({ resourceName }),
			description: localeContent.DELETE_RESOURCE_MODAL.DESCRIPTION({
				itemName: rowItemName || rowData?.row[rowItemNameKey],
				deleteAssociatedResourceWarning: childResourceConfig?.deleteAssociatedResourceWarning.toLowerCase()
			}),
			confirmLabel:
				localeContent.DELETE_RESOURCE_MODAL.ACTION_BUTTON_LABEL_CONFIRM,
			deleteItemCallback,
			successMessage: localeContent.DELETE_RESOURCE_SUCCESS_MESSAGE(
				rowItemName || rowData?.row[rowItemNameKey]
			),
			resourceEndpoint,
			customStartPathEndPoint
		});
	}

	return (
		<GenericTableRowActionMenu
			rowData={rowData}
			menuOptions={crudMenuOptions}
		/>
	);
}

CrudTableRowActionMenu.propTypes = {
	rowData: PropTypes.shape().isRequired,
	rowItemNameKey: PropTypes.string,
	resourceRoute: PropTypes.string,
	resourceEndpoint: PropTypes.string,
	resourceName: PropTypes.string.isRequired,
	deleteItemCallback: PropTypes.func,
	childResourceConfig: PropTypes.shape(),
	customOptions: PropTypes.arrayOf(PropTypes.shape()),
	disableClone: PropTypes.bool,
	customStartPathEndPoint: PropTypes.func
};

CrudTableRowActionMenu.defaultProps = {
	rowItemNameKey: "name",
	childResourceConfig: null,
	deleteItemCallback: null,
	resourceRoute: null,
	resourceEndpoint: null,
	customOptions: [],
	disableClone: false,
	customStartPathEndPoint: undefined
};

export default CrudTableRowActionMenu;
