import React from "react";
import PropTypes from "prop-types";
import {
	Box,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Checkbox,
	ListItemText
} from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

function TableColumnToggleMenu(props) {
	const { menuId, menuButtonLabel, columnOptions } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const isMenuOpen = Boolean(anchorEl);

	const handleColumnsMenuOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleColumnsMenuClose = () => {
		setAnchorEl(null);
	};
	return (
		<Box>
			<IconButton
				aria-label={menuButtonLabel}
				aria-controls={menuId}
				aria-haspopup="true"
				onClick={handleColumnsMenuOpen}
			>
				<ViewColumnIcon />
			</IconButton>
			<Menu
				id={menuId}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				open={isMenuOpen}
				onClose={handleColumnsMenuClose}
			>
				{columnOptions.map(column => (
					<MenuItem dense onClick={() => column.toggleHidden()} key={column.id}>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={column.isVisible}
								disableRipple
								label={column.Header}
								size="small"
								inputProps={{
									"aria-labelledby": `${menuId}-${column.id}-menu-option`
								}}
							/>
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{
								id: `${menuId}-${column.id}-menu-option`
							}}
						>
							{column.Header}
						</ListItemText>
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
}

TableColumnToggleMenu.propTypes = {
	menuId: PropTypes.string.isRequired,
	menuButtonLabel: PropTypes.string.isRequired,
	columnOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default TableColumnToggleMenu;
