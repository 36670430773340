import { getComparator, stableSort } from "./sortUtil";

/**
 * transformOptions is meant to be used in form fields that need to sort and map an array of options to function (selects, radios, etc)
 * @param {*} optionsConfig a config object containing
 * mapOptions: a mapper function applied to each option
 * sortOptionsByName: a boolean indicating whether to sort the list by name
 * @param {*} optionsData an array of options
 */
const transformOptions = (optionsConfig, optionsData) => {
	const {
		options,
		mapOptions,
		mapEntries,
		sortOptionsByName = true
	} = optionsConfig;
	let transformedOptions = options || optionsData || [];
	if (!Array.isArray(transformedOptions)) {
		transformedOptions = Object.entries(transformedOptions).map(mapEntries);
	} else if (mapOptions) {
		transformedOptions = transformedOptions.map(mapOptions);
	}
	if (sortOptionsByName) {
		transformedOptions = stableSort(
			transformedOptions,
			getComparator("asc", "name"),
			false
		);
	}
	return transformedOptions;
};

export default transformOptions;
