import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Box, Chip } from "@mui/material";

export const TEST_ID_DIMENSIONS_TOOLBAR_CHIP = "dimensions-toolbar-chip";

const DimensionChip = forwardRef((props, ref) => {
	const { style, label, attributes, listeners, onDelete } = props;

	return (
		<Box
			ref={ref}
			style={style}
			sx={{
				display: "inline-block"
			}}
			component="li"
			aria-label={label}
		>
			<Chip
				// We put the sortable listeners and attributes on the label only, so that users can still click the delete icon that's added by MUI
				label={
					<Box
						sx={{
							cursor: "grab"
						}}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...attributes}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...listeners}
					>
						{label}
					</Box>
				}
				onDelete={onDelete}
				color="primary"
				sx={{
					mr: 1,
					mb: 1
				}}
				data-testid={TEST_ID_DIMENSIONS_TOOLBAR_CHIP}
			/>
		</Box>
	);
});

DimensionChip.propTypes = {
	style: PropTypes.shape(),
	attributes: PropTypes.shape(),
	listeners: PropTypes.shape(),
	label: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired
};

DimensionChip.defaultProps = {
	style: {},
	attributes: {},
	listeners: {}
};

export default DimensionChip;
