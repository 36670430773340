import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";

import RouterLink from "../Routing/LinkWithQuery";
import lc from "./localeContent";

function GridToolbarScheduledReportsButton({ scheduledReportsLinkRoute }) {
	return (
		<Button
			to={scheduledReportsLinkRoute}
			component={RouterLink}
			size="small"
			startIcon={<ScheduleIcon />}
		>
			{lc.SCHEDULED_REPORTS_BUTTON_LINK}
		</Button>
	);
}

GridToolbarScheduledReportsButton.propTypes = {
	scheduledReportsLinkRoute: PropTypes.string.isRequired
};

export default GridToolbarScheduledReportsButton;
