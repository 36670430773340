import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import FormFieldSwitch from "../FormFieldSwitch/FormFieldSwitch";
import DragAndDropSelector from "../DragAndDropSelector/DragAndDropSelector";
import Tooltip from "../Tooltip/Tooltip";
import lc from "./localeContent";

export const KEY_IS_ENABLED = "isEnabled";
export const KEY_SELECTED_IDS = "selectedIds";

function Dimensions(props) {
	const {
		id,
		name,
		label,
		isEnabled,
		options,
		selectedIds,
		setSelectedIds,
		selectedItemsError,
		fieldTypeOptions: { getDisabledDimensions }
	} = props;
	const enabledFieldName = useMemo(() => `${name}.${KEY_IS_ENABLED}`, [name]);
	const enabledFieldId = useMemo(() => `${id}-${KEY_IS_ENABLED}`, [id]);
	const { values: fieldValues } = useFormikContext();

	const disabledDimensions = getDisabledDimensions
		? getDisabledDimensions(fieldValues)
		: [];

	return (
		<>
			<Tooltip title={lc.ENABLE_FIELD_TOOLTIP}>
				<div>
					<FormFieldSwitch
						id={enabledFieldId}
						label={label}
						name={enabledFieldName}
					/>
				</div>
			</Tooltip>
			{isEnabled && (
				<DragAndDropSelector
					options={options}
					selectedIds={selectedIds}
					setSelectedIds={setSelectedIds}
					selectedItemsError={selectedItemsError}
					disabledItems={disabledDimensions}
				/>
			)}
		</>
	);
}

Dimensions.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.shape({})),
	selectedIds: PropTypes.arrayOf(PropTypes.string),
	setSelectedIds: PropTypes.func.isRequired,
	selectedItemsError: PropTypes.string,
	isEnabled: PropTypes.bool,
	fieldTypeOptions: PropTypes.shape({
		getDisabledDimensions: PropTypes.func
	})
};

Dimensions.defaultProps = {
	options: [],
	selectedIds: [],
	isEnabled: false,
	selectedItemsError: undefined,
	fieldTypeOptions: {}
};

export default Dimensions;
