import React from "react";
import PropTypes from "prop-types";
import { Backdrop, CircularProgress } from "@mui/material";

// Expose a testing id for the spinner
export const LOADING_INDICATOR_TEST_ID = "loading-circular-progress";

function LoadingBackdrop(props) {
	const { isOpen, testId } = props;

	return (
		<Backdrop
			sx={theme => ({
				zIndex: theme.zIndex.drawer + 1
			})}
			open={isOpen}
		>
			<CircularProgress
				data-testid={`${
					testId ? `${testId}-` : ""
				}${LOADING_INDICATOR_TEST_ID}`}
				color="primary"
			/>
		</Backdrop>
	);
}

LoadingBackdrop.propTypes = {
	isOpen: PropTypes.bool,
	testId: PropTypes.string
};

LoadingBackdrop.defaultProps = {
	isOpen: false,
	testId: null
};

export default LoadingBackdrop;
