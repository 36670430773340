import React, { useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import FormFieldMultiselect from "../FormFieldMultiselect/FormFieldMultiselect";
import { Operations, useResourceClient } from "../../hooks/useResource";
import useSkipInitChangeWatcher from "../../hooks/useSkipInitChangeWatcher";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";
import { METRICS_DEFAULT_VALUES, METRIC_LIST } from "../../config/metricsList";

const METRICS_ENDPOINT_OPERATION = {
	...Operations.ONE,
	getResourceEndpoint: (baseResourceEndpoint, params) =>
		`${baseResourceEndpoint}/${params.reportType}/metrics`
};
const METRICS_ENUM_FOR_API = {
	network: "NETWORK",
	campaign: "CAMPAIGN",
	rtb: "RTB"
};

function FormFieldMetrics(props) {
	const {
		id,
		name,
		label,
		formValues: { reportType }
	} = props;
	const [, , helpers] = useField(name);
	const [
		optionsData,
		requestError,
		isLoadingOptions,
		requestOptionsData
	] = useResourceClient(
		"reports/scheduled-reports",
		METRICS_ENDPOINT_OPERATION
	);

	const authenticatedUserSettings = useContext(
		AuthenticatedUserSettingsContext
	);

	// Add labels received from api call to the metric options list
	const transformedOptionsData = useMemo(() => {
		const data = optionsData || {};
		return METRIC_LIST[reportType]
			.map(({ id: metricId }) => ({
				id: metricId,
				name: data[metricId]
			}))
			.filter(metric => metric.name);
	}, [reportType, optionsData]);

	// Init data load sets the report type, which counts as the first "change" detected by the useEffect watcher. We need to take certain actions only on subsequent, post-init user changes to the form
	const [isReportTypeChangedByUser, dispatch] = useSkipInitChangeWatcher();

	useEffect(() => {
		if (reportType) {
			// Request new options data whenever report type changes
			requestOptionsData({
				reportType: METRICS_ENUM_FOR_API[reportType]
			});
			// Set default values only after the initial change
			dispatch({ type: "VALUE_CHANGED" });
		}
	}, [reportType, requestOptionsData, dispatch]);

	useEffect(() => {
		if (isReportTypeChangedByUser) {
			helpers.setValue(
				authenticatedUserSettings.defaultUserMetrics[reportType].length
					? authenticatedUserSettings.defaultUserMetrics[reportType]
					: METRICS_DEFAULT_VALUES[reportType]
			);
			dispatch({ type: "RESET" });
		}
	}, [
		isReportTypeChangedByUser,
		helpers,
		reportType,
		dispatch,
		authenticatedUserSettings
	]);

	const hasOptionsRequestError = Boolean(!isLoadingOptions && requestError);
	return (
		<FormFieldMultiselect
			id={id}
			name={name}
			label={label}
			optionsConfig={{
				options: transformedOptionsData,
				sortOptionsByName: false,
				showOptionsRequestError: hasOptionsRequestError
			}}
		/>
	);
}
FormFieldMetrics.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	formValues: PropTypes.shape().isRequired
};

FormFieldMetrics.defaultProps = {};

export default FormFieldMetrics;
