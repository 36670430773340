import React, { useContext } from "react";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import Badge from "@mui/material/Badge";
import { UserNotificationContext } from "../../context/UserNotificationContext";

function NotificationIconWithBadge() {
	const userNotificationContext = useContext(UserNotificationContext);
	const unreadNotifications = userNotificationContext
		? userNotificationContext.unreadNotifications
		: [];
	return (
		<Badge badgeContent={unreadNotifications.length} color="warning">
			<NotificationsIcon />
		</Badge>
	);
}

export default NotificationIconWithBadge;
