export default {
	SELECTED_OPTIONS_COUNT_LABEL: {
		AVAILABLE: "Align",
		ALIGNED: "Unalign"
	},
	TOGGLE_ALL_ROWS_SELECTED_LABEL: "Toggle All Rows Selected",
	TOGGLE_ROW_SELECTED_LABEL: "Toggle Row Selected",
	ALIGNMENTS_SAVED_SUCCESS_MESSAGE: "Alignments saved",
	SUBMIT_ALIGNMENTS_BUTTON_LABEL: "Save Alignments",
	CLEAR_ALIGNMENTS_BUTTON_LABEL: "Clear Selections"
};
