import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";

const MenuOptionClickHandler = forwardRef((props, ref) => {
	const { onClick, label } = props;
	return (
		<MenuItem ref={ref} color="inherit" onClick={onClick}>
			{label}
		</MenuItem>
	);
});

MenuOptionClickHandler.propTypes = {
	onClick: PropTypes.func,
	label: PropTypes.string.isRequired
};

MenuOptionClickHandler.defaultProps = {
	onClick: undefined
};

export default MenuOptionClickHandler;
