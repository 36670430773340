import React from "react";
import PropTypes from "prop-types";
import { useHistory, Prompt } from "react-router-dom";
import ConfirmDialogueSimple from "../ConfirmDialogueSimple/ConfirmDialogueSimple";
import localeContent from "./localeContent";

/**
 * Gets the full pathname for redirects by combining the base pathname with the search query params
 * @param {Object} location a react router location object
 */
const getRedirectPathnameWithParams = location => {
	const { pathname, search } = location;
	return pathname + search;
};

/**
 * Approach based on:
 * 		- https://material-ui.com/components/dialogs/#customized-dialogs
 * 		- https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
 * @param {*} props Defined in PropTypes
 */
function DirtyFormProtector(props) {
	const { when, resourceString, submitForm } = props;
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [redirectLocation, setRedirectLocation] = React.useState(null);
	const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);
	const history = useHistory();

	const [baseLocation] = React.useState(history.location);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleBlockedNavigation = attemptedNavigationLocation => {
		const locationChanged =
			baseLocation.pathname !== attemptedNavigationLocation.pathname;
		if (locationChanged && !confirmedNavigation) {
			setIsModalOpen(true);
			setRedirectLocation(attemptedNavigationLocation);
			return false;
		}
		return true;
	};

	const handleConfirmNavigationClick = () => {
		setIsModalOpen(false);
		setConfirmedNavigation(true);
	};

	const handleSubmitForm = () => {
		setIsModalOpen(false);
		submitForm(getRedirectPathnameWithParams(redirectLocation));
	};

	React.useEffect(() => {
		if (confirmedNavigation && redirectLocation) {
			history.push(getRedirectPathnameWithParams(redirectLocation));
		}
	}, [history, confirmedNavigation, redirectLocation]);

	return (
		<>
			<Prompt when={when} message={handleBlockedNavigation} />
			<ConfirmDialogueSimple
				title={localeContent.DIRTY_FORM.TITLE}
				description={localeContent.DIRTY_FORM.DESCRIPTION(resourceString)}
				isOpen={isModalOpen}
				onConfirm={handleSubmitForm}
				confirmLabel={
					localeContent.DIRTY_FORM.ACTION_BUTTON_LABEL_SAVE_AND_CONTINUE
				}
				onCancel={handleConfirmNavigationClick}
				cancelLabel={
					localeContent.DIRTY_FORM.ACTION_BUTTON_LABEL_DISCARD_AND_CONTINUE
				}
				onClose={handleClose}
			/>
		</>
	);
}

DirtyFormProtector.propTypes = {
	when: PropTypes.bool.isRequired,
	resourceString: PropTypes.string.isRequired,
	submitForm: PropTypes.func.isRequired
};

DirtyFormProtector.defaultProps = {};

export default DirtyFormProtector;
