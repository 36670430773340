import React, { useState, useContext, forwardRef } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";

import { Operations } from "../../hooks/useResource";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import FormFieldTags from "../FormFieldTags/FormFieldTags";

import ConfirmDialogueSimple from "../ConfirmDialogueSimple/ConfirmDialogueSimple";
import MenuOptionClickHandler from "./MenuOptionClickHandler";
import { SnackbarContext } from "../../context/SnackbarContext";
import localeContent from "./localeContent";

const KEY_EMAIL = "recipient";

const emailValidationSchema = Yup.array()
	.of(Yup.string().email(({ value }) => localeContent.INVALID_EMAIL(value)))
	.min(1, localeContent.EMPTY_SHARE_FIELD)
	.max(10, localeContent.MAX_LIMIT_RECIPIENT);

const VALIDATION_SCHEMA = Yup.object({
	[KEY_EMAIL]: emailValidationSchema
});

const DEFAULT_FORM_VALUES = {
	[KEY_EMAIL]: []
};

const MenuOptionShareResource = forwardRef((props, ref) => {
	const {
		label,
		onClick,
		resourceEndpoint,
		title,
		description,
		confirmLabel,
		setIsLoading
	} = props;
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const { execute: shareEntity } = useResourceAsync(
		resourceEndpoint,
		Operations.CREATE
	);

	const [isOpenShareConfirmation, setIsOpenShareConfirmation] = useState(false);

	const openShareConfirmation = () => {
		setIsOpenShareConfirmation(true);
	};

	const closeShareConfirmation = () => {
		setIsOpenShareConfirmation(false);
	};

	const handleShareItem = async formValues => {
		setIsLoading(true);
		/* Send Email Request */
		const { error } = await shareEntity({
			data: formValues[KEY_EMAIL]
		});
		setIsLoading(false);

		if (error) {
			const { message } = error;
			if (message) {
				triggerNewSnackbarMessage({ message, severity: "error" });
			}
			return;
		}
		triggerNewSnackbarMessage({
			message: localeContent.SHARE_REPORT_SUCCESS_MESSAGE,
			severity: "success"
		});
		closeShareConfirmation();
	};

	return (
		<>
			<MenuOptionClickHandler
				ref={ref}
				label={label}
				onClick={() => {
					onClick();
					openShareConfirmation();
				}}
			/>
			<Formik
				initialValues={DEFAULT_FORM_VALUES}
				validationSchema={VALIDATION_SCHEMA}
				onSubmit={handleShareItem}
			>
				{formikProps => (
					<ConfirmDialogueSimple
						title={title}
						description={description}
						isOpen={isOpenShareConfirmation}
						onConfirm={formikProps.handleSubmit}
						confirmLabel={confirmLabel}
						onCancel={closeShareConfirmation}
						onClose={closeShareConfirmation}
					>
						<form noValidate onSubmit={formikProps.handleSubmit}>
							<FormFieldTags
								id={KEY_EMAIL}
								name={KEY_EMAIL}
								label={localeContent.SHARE_RESOURCE_MODAL.LABEL}
								required
							/>
						</form>
					</ConfirmDialogueSimple>
				)}
			</Formik>
		</>
	);
});

MenuOptionShareResource.propTypes = {
	onClick: PropTypes.func,
	label: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	confirmLabel: PropTypes.string.isRequired,
	resourceEndpoint: PropTypes.string.isRequired,
	setIsLoading: PropTypes.func.isRequired
};

MenuOptionShareResource.defaultProps = {
	onClick: undefined
};

export default MenuOptionShareResource;
