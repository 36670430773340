import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { Box } from "@mui/material";
import Tooltip from "../Tooltip/Tooltip";
import localeContent from "../FormFieldMacroText/localeContent";
import { EVENT_ENTER_KEY } from "../../config/constants";

function MacroAutoComplete(props) {
	const { items, handleMacroSelection } = props;

	const [inputValue, setInputValue] = useState("");

	const [open, setOpen] = useState(false);
	const closePopper = () => setOpen(false);
	const openPopper = () => setOpen(true);

	const handleClick = macro => () => {
		handleMacroSelection(macro);
		setInputValue("");
	};

	const filterOptions = createFilterOptions({
		stringify: ({ label, macro }) => `${label} ${macro}`
	});

	const handleKeyDown = e => {
		if (e.key === EVENT_ENTER_KEY) {
			e.preventDefault();
		}
	};

	return (
		<Autocomplete
			disablePortal
			open={open}
			// We have to pass the value prop (null) to the Autocomplete component,
			// so it knows we want a 'controlled' variant.
			// https://mui.com/material-ui/react-autocomplete/#controlled-states
			value={null}
			onOpen={openPopper}
			onClose={closePopper}
			onChange={(event, itemValue) => handleClick(itemValue.macro)()}
			sx={{ width: 300 }}
			inputValue={inputValue}
			name="macro-auto-complete-search"
			onKeyDown={e => handleKeyDown(e)}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={textProps => (
				<TextField
					/* eslint-disable-next-line react/jsx-props-no-spreading */
					{...textProps}
					name="macro-auto-complete-input"
					label={localeContent.BUTTON_TEXT_ADD_MACRO}
				/>
			)}
			filterOptions={filterOptions}
			options={items}
			renderOption={(itemProps, option) => (
				<Box
					sx={{ pl: 6 }}
					component="li"
					/* eslint-disable-next-line react/jsx-props-no-spreading */
					{...itemProps}
				>
					<Tooltip title={option.tooltip}>
						<ListItemText
							primaryTypographyProps={{ macro: option.macro }}
							primary={option.label}
							onClick={handleClick(option.macro)}
						/>
					</Tooltip>
				</Box>
			)}
		/>
	);
}

MacroAutoComplete.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape()),
	handleMacroSelection: PropTypes.func.isRequired
};
MacroAutoComplete.defaultProps = {
	items: []
};
export default MacroAutoComplete;
