import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Box, Grid } from "@mui/material";

import FormFieldSwitch from "../FormFieldSwitch/FormFieldSwitch";
import localeContent from "./localeContent";
import DeviceTargetingRow from "./DeviceTargetingRow";

export const KEY_ENABLED = "enabled";
export const KEY_DEVICES = "devices";

const DESKTOP_OPTIONS = [
	{ id: "linux", name: "Linux" },
	{ id: "osx", name: "Mac OS" },
	{ id: "win", name: "Windows" }
];

const MOBILE_OPTIONS = [
	{ id: "android", name: "Android" },
	{ id: "bb", name: "BlackBerry" },
	{ id: "ios", name: "iOS" },
	{ id: "win", name: "Windows" }
];

const devices = {
	DESKTOP: "desktop",
	PHONE: "smartphone",
	TABLET: "tablet",
	CTV: "ctv" || "connectedtv"
};

const systemsLists = {
	DESKTOP: "desktopOperatingSystems",
	PHONE: "phoneOperatingSystems",
	TABLET: "tabletOperatingSystems",
	CTV: "connectedtvOperatingSystems"
};

const vendorLists = {
	DESKTOP: "desktopDeviceVendors",
	PHONE: "smartphoneDeviceVendors",
	TABLET: "tabletDeviceVendors",
	CTV: "ctvDeviceVendors"
};

const VENDOR_TYPES = {
	desktop: "Desktop",
	smartphone: "SmartPhone",
	tablet: "Tablet",
	ctv: "ConnectedTV"
};

// Function added during migration to v5 mui styling approach. It would probably be better to create a component to represent the rows rather than repeat sx definitions
const getDeviceTypeRowSx = theme => ({ minHeight: theme.spacing(9) });

const getInitEnabled = (fieldData, inheritedValues) => {
	const hasDeviceSelected = Object.values(devices).some(
		key =>
			Boolean(fieldData.devices?.includes(key)) ||
			Boolean(inheritedValues.devices?.includes(key))
	);

	return hasDeviceSelected;
};

export const transformInitData = (data, fieldName) => {
	const fieldData = data[fieldName] || {};
	const inheritedValues = data.inheritedValues || {};
	return {
		[KEY_ENABLED]: getInitEnabled(fieldData, inheritedValues),
		[KEY_DEVICES]: fieldData[KEY_DEVICES] || [],
		[systemsLists.DESKTOP]: fieldData[systemsLists.DESKTOP],
		[systemsLists.PHONE]: fieldData[systemsLists.PHONE],
		[systemsLists.TABLET]: fieldData[systemsLists.TABLET],
		[vendorLists.DESKTOP]: fieldData[vendorLists.DESKTOP],
		[vendorLists.PHONE]: fieldData[vendorLists.PHONE],
		[vendorLists.TABLET]: fieldData[vendorLists.TABLET],
		[vendorLists.CTV]: fieldData[vendorLists.CTV]
	};
};

export const transformSubmitData = (data, fieldName) => {
	const { [KEY_ENABLED]: enabled, ...submissionData } = data;
	if (!enabled) {
		return {
			[fieldName]: {
				[KEY_DEVICES]: []
			}
		};
	}
	return { [fieldName]: submissionData };
};

function FormFieldDeviceTargeting(props) {
	const { name, inheritedValues } = props;
	const [field, , helpers] = useField(name);
	const formikData = field.value;
	const hasInheritValues = useMemo(() => inheritedValues.devices?.length > 0, [
		inheritedValues
	]);

	const [isDesktopSelected, setIsDesktopSelected] = useState(false);
	const [isPhoneSelected, setIsPhoneSelected] = useState(false);
	const [isTabletSelected, setIsTabletSelected] = useState(false);
	const [isCTVSelected, setIsCTVSelected] = useState(false);

	useEffect(() => {
		setIsDesktopSelected(
			formikData.devices?.includes(devices.DESKTOP) ||
				Boolean(inheritedValues.devices?.includes(devices.DESKTOP))
		);
		setIsPhoneSelected(
			formikData.devices?.includes(devices.PHONE) ||
				Boolean(inheritedValues.devices?.includes(devices.PHONE))
		);
		setIsTabletSelected(
			formikData.devices?.includes(devices.TABLET) ||
				Boolean(inheritedValues.devices?.includes(devices.TABLET))
		);
		setIsCTVSelected(
			formikData.devices?.includes(devices.CTV) ||
				Boolean(inheritedValues.devices?.includes(devices.CTV))
		);
	}, [formikData.devices, inheritedValues.devices]);

	const onChangeCheckbox = (
		deviceValue,
		deviceListName,
		vendorListName
	) => isChecked => {
		let value;
		if (isChecked) {
			value = {
				...formikData,
				devices: [...formikData.devices, deviceValue]
			};
		} else {
			value = {
				...formikData,
				devices: formikData.devices.filter(device => device !== deviceValue)
			};
			delete value[deviceListName];
			delete value[vendorListName];
		}
		helpers.setValue(value);
	};

	const isEnabledFieldName = useMemo(() => `${name}.${KEY_ENABLED}`, [name]);
	return (
		<>
			<Box sx={{ mb: formikData[KEY_ENABLED] ? 1 : undefined }}>
				<FormFieldSwitch
					id="device-targeting-enabled"
					name={isEnabledFieldName}
					label={localeContent.DEVICE_TARGETING_LABEL}
					disabled={hasInheritValues}
				/>
			</Box>
			{formikData[KEY_ENABLED] && (
				<Grid container spacing={1} sx={{ pl: 2 }}>
					<Grid
						item
						container
						spacing={2}
						sx={theme => getDeviceTypeRowSx(theme)}
					>
						<DeviceTargetingRow
							name={name}
							isSelected={isDesktopSelected}
							checkboxLabel={localeContent.DESKTOP_LABEL}
							onSelect={onChangeCheckbox(
								devices.DESKTOP,
								systemsLists.DESKTOP,
								vendorLists.DESKTOP
							)}
							operatingSystemOptions={{
								id: systemsLists.DESKTOP,
								label: localeContent.DESKTOP_OS_LABEL,
								tooltip: localeContent.DESKTOP_TOOLTIP,
								fieldName: `${name}.${systemsLists.DESKTOP}`,
								options: DESKTOP_OPTIONS
							}}
							hasInheritValues={hasInheritValues}
							inheritedValues={inheritedValues}
							vendorOptions={{
								id: vendorLists.DESKTOP,
								label: localeContent.DESKTOP_VENDOR_LABEL,
								tooltip: localeContent.DESKTOP_VENDOR_TOOLTIP,
								vendorType: VENDOR_TYPES.desktop,
								fieldName: `${name}.${vendorLists.DESKTOP}`
							}}
						/>
					</Grid>
					<Grid
						item
						container
						spacing={2}
						sx={theme => getDeviceTypeRowSx(theme)}
					>
						<DeviceTargetingRow
							name={name}
							isSelected={isPhoneSelected}
							checkboxLabel={localeContent.PHONE_LABEL}
							onSelect={onChangeCheckbox(
								devices.PHONE,
								systemsLists.PHONE,
								vendorLists.PHONE
							)}
							operatingSystemOptions={{
								id: systemsLists.PHONE,
								label: localeContent.PHONE_OS_LABEL,
								tooltip: localeContent.PHONE_TOOLTIP,
								fieldName: `${name}.${systemsLists.PHONE}`,
								options: MOBILE_OPTIONS
							}}
							hasInheritValues={hasInheritValues}
							inheritedValues={inheritedValues}
							vendorOptions={{
								id: vendorLists.PHONE,
								label: localeContent.PHONE_VENDOR_LABEL,
								tooltip: localeContent.PHONE_VENDOR_TOOLTIP,
								vendorType: VENDOR_TYPES.smartphone,
								fieldName: `${name}.${vendorLists.PHONE}`
							}}
						/>
					</Grid>
					<Grid
						item
						container
						spacing={2}
						sx={theme => getDeviceTypeRowSx(theme)}
					>
						<DeviceTargetingRow
							name={name}
							isSelected={isTabletSelected}
							checkboxLabel={localeContent.TABLET_LABEL}
							onSelect={onChangeCheckbox(
								devices.TABLET,
								systemsLists.TABLET,
								vendorLists.TABLET
							)}
							operatingSystemOptions={{
								id: systemsLists.TABLET,
								label: localeContent.TABLET_OS_LABEL,
								tooltip: localeContent.TABLET_TOOLTIP,
								fieldName: `${name}.${systemsLists.TABLET}`,
								options: MOBILE_OPTIONS
							}}
							hasInheritValues={hasInheritValues}
							inheritedValues={inheritedValues}
							vendorOptions={{
								id: vendorLists.TABLET,
								label: localeContent.TABLET_VENDOR_LABEL,
								tooltip: localeContent.TABLET_VENDOR_TOOLTIP,
								vendorType: VENDOR_TYPES.tablet,
								fieldName: `${name}.${vendorLists.TABLET}`
							}}
						/>
					</Grid>
					<Grid
						item
						container
						spacing={2}
						sx={theme => getDeviceTypeRowSx(theme)}
					>
						<DeviceTargetingRow
							name={name}
							isSelected={isCTVSelected}
							checkboxLabel={localeContent.CTV_LABEL}
							onSelect={onChangeCheckbox(
								devices.CTV,
								systemsLists.CTV,
								vendorLists.CTV
							)}
							hasInheritValues={hasInheritValues}
							inheritedValues={inheritedValues}
							vendorOptions={{
								id: vendorLists.CTV,
								label: localeContent.CTV_VENDOR_LABEL,
								tooltip: localeContent.CTV_VENDOR_TOOLTIP,
								inheritedValues,
								vendorType: VENDOR_TYPES.ctv,
								fieldName: `${name}.${vendorLists.CTV}`
							}}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
}

FormFieldDeviceTargeting.propTypes = {
	name: PropTypes.string.isRequired,
	inheritedValues: PropTypes.shape()
};

FormFieldDeviceTargeting.defaultProps = {
	inheritedValues: {}
};

export default FormFieldDeviceTargeting;
