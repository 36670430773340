import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import NavRail from "./NavRail/NavRail";
import NavModal from "./NavModal/NavModal";
import useResource, { Operations } from "../../hooks/useResource";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import { AuthContext } from "../../context/AuthContext";
import { DASHBOARD_API_URL } from "../../config/ApiUrlConfig";

/**
 * Gets the active management menu icon and subitem button keys based on the current route (if any exist)
 * @param {Array} managementMenuItems array of management menu item config objects
 * @param {String} locationPathname current browser location pathname
 */
function getActiveItemsFromLocation(managementMenuItems, locationPathname) {
	for (let i = 0; i < managementMenuItems.length; i += 1) {
		for (let j = 0; j < managementMenuItems[i].subItems.length; j += 1) {
			const isRegexMatch = RegExp(
				`^\\${managementMenuItems[i].subItems[j].route}.*`
			).test(locationPathname);
			if (isRegexMatch) {
				return {
					activeMenuItemKey: managementMenuItems[i].key,
					activeMenuSubItemKey: managementMenuItems[i].subItems[j].key
				};
			}
		}
	}

	return {
		activeMenuItemKey: "",
		activeMenuSubItemKey: ""
	};
}

const NAV_ITEM_TYPE = {
	management: "management",
	companySwitch: "companySwitch"
};

export const CAN_SWITCH_COMPANIES_PERMISSION = "SEE_ALL_COMPANIES";
const hasPermission = (menuItem, authorizationContext) => {
	if (menuItem.permissionRequired) {
		return authorizationContext.hasPermission(menuItem.permissionRequired);
	}
	return true;
};

function Navigation(props) {
	const { managementMenuItems, userMenuItems } = props;
	const { companyId } = useContext(AuthContext);

	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [clickedMenuItem, setClickedMenuItem] = React.useState(null);
	const location = useLocation();
	const authorizationContext = useContext(AuthorizationContext);
	const {
		activeMenuItemKey,
		activeMenuSubItemKey
	} = getActiveItemsFromLocation(managementMenuItems, location.pathname);

	const getBrandingResource = ImagePath => {
		return `${DASHBOARD_API_URL}/${ImagePath}/${companyId}`;
	};

	const canSwitchCompanies = authorizationContext.hasPermission(
		CAN_SWITCH_COMPANIES_PERMISSION
	);

	const [companyList] = useResource(
		canSwitchCompanies ? "manage/companies/select" : undefined,
		Operations.LIST,
		{
			requestParams: { statusList: ["active", "inactive"] }
		}
	);

	const allowedManagementMenuItems = useMemo(() => {
		return managementMenuItems
			.filter(navItem => hasPermission(navItem, authorizationContext))
			.reduce((agg, navGroup) => {
				const allowedSubItems = navGroup.subItems.filter(subItem =>
					hasPermission(subItem, authorizationContext)
				);
				if (!isEmpty(allowedSubItems)) {
					agg.push({ ...navGroup, subItems: allowedSubItems });
				}
				return agg;
			}, []);
	}, [authorizationContext, managementMenuItems]);

	const onManagementNavItemClick = clickedItemKey => () => {
		const clickedItem = allowedManagementMenuItems.find(
			item => item.key === clickedItemKey
		);
		setClickedMenuItem({
			...clickedItem,
			navItemType: NAV_ITEM_TYPE.management
		});
		setIsModalOpen(true);
	};

	const onOpenCompanySwitch = () => {
		setClickedMenuItem({
			key: NAV_ITEM_TYPE.companySwitch,
			navItemType: NAV_ITEM_TYPE.companySwitch
		});
		setIsModalOpen(true);
	};

	const onModalOptionClick = () => {
		setIsModalOpen(false);
	};

	return (
		<nav>
			<NavRail
				managementNavItems={allowedManagementMenuItems}
				userNavItems={userMenuItems}
				onOpenCompanySwitch={
					canSwitchCompanies ? onOpenCompanySwitch : undefined
				}
				onManagementNavItemClicked={onManagementNavItemClick}
				activeMenuItemKey={activeMenuItemKey}
				getBrandingResource={getBrandingResource}
			/>
			<NavModal
				clickedOptionConfig={clickedMenuItem}
				isOpen={isModalOpen}
				handleModalToggle={onModalOptionClick}
				activeMenuItemKey={activeMenuItemKey}
				activeMenuSubItemKey={activeMenuSubItemKey}
				companyList={companyList}
				getBrandingResource={getBrandingResource}
				authorizationContext={authorizationContext}
			/>
		</nav>
	);
}

Navigation.propTypes = {
	managementMenuItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	userMenuItems: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default Navigation;
