import React, { useContext } from "react";
import * as Yup from "yup";
import { Link, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { Form, Formik } from "formik";
import parseHTML from "html-react-parser";
import lc from "./localeContent";
import { Operations } from "../../hooks/useResource";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import { SnackbarContext } from "../../context/SnackbarContext";
import FormTextField from "../../components/FormTextField/FormTextField";
import AppBarWithLogo from "../AppBarWithLogo/AppBarWithLogo";

const ValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email(lc.INVALID_EMAIL_MESSAGE)
		.required("Required")
});

function ForgotPassword() {
	const { showSnackbarInfoMessage } = useContext(SnackbarContext);

	const { execute: submitFormData } = useResourceAsync(
		"manage/user-password",
		Operations.CREATE
	);

	const handleSubmit = async (values, { resetForm }) => {
		const { data, isSuccess, error } = await submitFormData({
			data: values
		});
		if (isSuccess) {
			showSnackbarInfoMessage(parseHTML(lc.NEXT_STEP_MESSAGE));
		} else if (error) {
			showSnackbarInfoMessage(data.error);
		}
		resetForm();
	};

	return (
		<>
			<AppBarWithLogo />
			<Container maxWidth="xs" sx={{ pt: 19 }}>
				<Typography
					variant="subtitle1"
					component="h1"
					sx={{ textAlign: "center", mb: 2 }}
				>
					{lc.FORGOT_PASSWORD_FORM_TITLE}
				</Typography>
				<Formik
					initialValues={{ email: "" }}
					onSubmit={handleSubmit}
					validationSchema={ValidationSchema}
				>
					<Form noValidate>
						<Paper elevation={4} sx={{ mb: 5, p: 4 }}>
							<Grid item xs={12}>
								<FormTextField
									id="email"
									name="email"
									label={lc.EMAIL_ADDRESS_LABEL}
								/>
							</Grid>
						</Paper>
						<Grid container>
							<Grid item xs={12} sx={{ textAlign: "center" }}>
								<Button type="submit">{lc.SUBMIT_BUTTON_LABEL}</Button>
								<Link
									to="login"
									color="inherit"
									sx={{ display: "block" }}
									component={RouterLink}
								>
									{lc.BACK_TO_LOGIN_LABEL}
								</Link>
							</Grid>
						</Grid>
					</Form>
				</Formik>
			</Container>
		</>
	);
}

export default ForgotPassword;
