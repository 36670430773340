import React from "react";
import PropTypes from "prop-types";
import {
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio as MUIRadio,
	FormHelperText
} from "@mui/material";

function Radio(props) {
	const {
		id,
		label,
		name,
		value,
		onChange,
		onBlur,
		options,
		showError,
		helperText,
		ariaLabel,
		disabled
	} = props;
	return (
		<FormControl component="fieldset" error={showError} disabled={disabled}>
			{label && <FormLabel component="legend">{label}</FormLabel>}
			<RadioGroup
				row
				id={id}
				name={name}
				value={value ?? false}
				onChange={onChange}
				onBlur={onBlur}
				aria-label={ariaLabel || label}
			>
				{options.map(radioOption => (
					<FormControlLabel
						key={radioOption.label}
						label={radioOption.label}
						value={radioOption.value}
						disabled={radioOption.disabled}
						control={
							<MUIRadio
								color={radioOption.value === value ? "primary" : "default"}
							/>
						}
					/>
				))}
			</RadioGroup>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

Radio.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	ariaLabel: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
		PropTypes.number
	]),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.shape()),
	showError: PropTypes.bool,
	disabled: PropTypes.bool,
	helperText: PropTypes.string
};

Radio.defaultProps = {
	label: null,
	ariaLabel: null,
	value: false,
	onChange: null,
	onBlur: null,
	options: [],
	showError: false,
	disabled: false,
	helperText: null
};

export default Radio;
