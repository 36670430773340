import React from "react";
import PropTypes from "prop-types";
import TextField from "../FormTextField/TextField";

function NumberField(props) {
	const {
		name,
		id,
		label,
		required,
		fieldTypeOptions,
		value,
		onChange,
		onBlur,
		showError,
		helperText
	} = props;
	const { min, max, step } = fieldTypeOptions;
	return (
		<TextField
			label={label}
			id={id}
			name={name}
			type="number"
			required={required}
			inputProps={{
				min: min || 0,
				max: max || null,
				step: step || 1
			}}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			showError={showError}
			helperText={helperText}
		/>
	);
}

NumberField.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	fieldTypeOptions: PropTypes.shape(),
	required: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	showError: PropTypes.bool,
	helperText: PropTypes.string
};

NumberField.defaultProps = {
	fieldTypeOptions: {},
	required: false,
	value: "",
	onChange: null,
	onBlur: null,
	showError: false,
	helperText: null
};

export default NumberField;
