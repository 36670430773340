import React, { createContext, useCallback, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory } from "react-router-dom";
import useQueryParam from "../hooks/useQueryParam";
import {
	SERVER_URL_WITH_VERSION,
	COMPANY_ID_QUERY_PARAM
} from "../config/constants";

export const TOKEN_KEY = "token";
const USER_INFO_KEY = "userInfo";
const EXPIRE_KEY = "expiresAt";
const PRIMARY_COMPANY_ID_KEY = "primaryCompanyId";
const IS_DEMAND_CLIENT_KEY = "isDemandClient";
const LOGOUT_HASH = "logout";

const AuthContext = createContext();
const { Provider } = AuthContext;

function AuthProvider({ children }) {
	const token = localStorage.getItem(TOKEN_KEY);
	const userInfo = JSON.parse(localStorage.getItem(USER_INFO_KEY));
	const expiresAt = localStorage.getItem(EXPIRE_KEY);
	const primaryCompanyId = localStorage.getItem(PRIMARY_COMPANY_ID_KEY)
		? parseInt(localStorage.getItem(PRIMARY_COMPANY_ID_KEY), 10)
		: null;
	const [isAccessDenied, setIsAccessDenied] = useState(false);
	const queryParamCompanyId = useQueryParam(COMPANY_ID_QUERY_PARAM);
	const companyId = queryParamCompanyId
		? parseInt(queryParamCompanyId, 10)
		: primaryCompanyId;
	const isDemandClient = localStorage.getItem("isDemandClient") === "true";

	const history = useHistory();

	const [authState, setAuthState] = useState({
		token,
		expiresAt,
		userInfo,
		primaryCompanyId
	});

	const setAuthInfo = useCallback(
		({
			token: newToken,
			userInfo: newUserInfo,
			expiresAt: newExpiresAt,
			primaryCompanyId: newPrimaryCompanyId,
			defaultCompanyView,
			isDemandClient: newIsDemandClient
		}) => {
			localStorage.setItem(TOKEN_KEY, newToken);
			localStorage.setItem(USER_INFO_KEY, JSON.stringify(newUserInfo));
			localStorage.setItem(EXPIRE_KEY, newExpiresAt);
			localStorage.setItem(PRIMARY_COMPANY_ID_KEY, newPrimaryCompanyId);
			localStorage.setItem(IS_DEMAND_CLIENT_KEY, newIsDemandClient);

			setAuthState({
				token: newToken,
				userInfo: newUserInfo,
				expiresAt: newExpiresAt,
				primaryCompanyId: newPrimaryCompanyId,
				defaultCompanyView,
				isDemandClient: newIsDemandClient
			});
		},
		[]
	);

	const logout = useCallback(() => {
		localStorage.removeItem(TOKEN_KEY);
		localStorage.removeItem(USER_INFO_KEY);
		localStorage.removeItem(EXPIRE_KEY);
		localStorage.removeItem(PRIMARY_COMPANY_ID_KEY);
		localStorage.removeItem(IS_DEMAND_CLIENT_KEY);
		setAuthState({
			token: null,
			expiresAt: null,
			userInfo: {},
			primaryCompanyId: null,
			defaultCompanyView: null
		});
		history.push(`/login#${LOGOUT_HASH}`);
	}, [history]);

	const setAccessDenied = accessType => {
		setIsAccessDenied(accessType);
	};

	const isAuthenticated = useCallback(() => {
		return Boolean(authState.token);
	}, [authState.token]);

	const login = async ({ username, password }) => {
		return axios({
			url: `${SERVER_URL_WITH_VERSION}/auth`,
			method: "post",
			data: { username, password }
		}).then(async response => {
			if (response.status !== 202) {
				throw new Error(response);
			}
			const {
				authorization,
				primaryCompanyId: responsePrimaryCompanyId,
				defaultCompanyView,
				isDemandClient: responseIsDemandClient
			} = response.data;
			if (!authorization) {
				throw new Error(`Could not authenticate`);
			}

			// TODO: When implementing token refresh
			//  and once we have interpreted the token
			//  get the expiration date from the payload
			setAuthInfo({
				token: authorization,
				expiresAt: new Date(),
				userInfo: {
					username
				},
				primaryCompanyId: responsePrimaryCompanyId,
				defaultCompanyView,
				isDemandClient: responseIsDemandClient
			});
			return response;
		});
	};

	return (
		<Provider
			value={{
				authState,
				setAuthState: setAuthInfo,
				logout,
				isAuthenticated,
				setAccessDenied,
				login,
				isAccessDenied,
				companyId,
				primaryCompanyId,
				isDemandClient
			}}
		>
			{children}
		</Provider>
	);
}

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { AuthProvider, AuthContext };
