import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
	FROM_QUERY_PARAM,
	PROTECTED_CONTENT_PATH,
	COMPANY_ID_QUERY_PARAM
} from "../../config/constants";
import { AuthContext } from "../../context/AuthContext";
import RouterLink from "../../components/Routing/LinkWithQuery";
import TextField from "../../components/FormTextField/TextField";
import AppBarWithLogo from "../AppBarWithLogo/AppBarWithLogo";

import { SnackbarContext } from "../../context/SnackbarContext";
import localeContent from "./localeContent";
import useQueryParam from "../../hooks/useQueryParam";

const getRedirectTo = (fromParam, primaryCompanyId) => {
	return (
		fromParam ||
		`${PROTECTED_CONTENT_PATH}${
			primaryCompanyId ? `?${COMPANY_ID_QUERY_PARAM}=${primaryCompanyId}` : ""
		}`
	);
};

function Login() {
	const authContext = useContext(AuthContext);
	const { primaryCompanyId, defaultCompanyView } = authContext.authState;
	const fromParam = useQueryParam(FROM_QUERY_PARAM);
	const history = useHistory();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const { triggerNewSnackbarMessage, showSnackbarInfoMessage } = useContext(
		SnackbarContext
	);
	const login = async () => {
		return authContext.login({
			username,
			password
		});
	};
	const handleSubmit = e => {
		e.preventDefault();
		const UNAUTHORISED = 403;
		login().catch(error => {
			if (error.response.status === UNAUTHORISED) {
				triggerNewSnackbarMessage({
					message: localeContent.ACCOUNT_LOCKED_MESSAGE,
					severity: "info",
					style: { backgroundColor: "black", fontFamily: "Poppins" },
					forceDismiss: true
				});
				history.push("/forgot");
			} else {
				showSnackbarInfoMessage(
					localeContent.INVALID_USERNAME_VALIDATION_ERROR
				);
			}
		});
	};

	return !authContext.isAuthenticated() ? (
		<>
			<AppBarWithLogo />
			<Container maxWidth="xs" sx={{ pt: 19 }}>
				<form noValidate onSubmit={handleSubmit}>
					<Paper elevation={4} sx={{ mb: 5, p: 4 }}>
						<Grid item xs={12} sx={{ mb: 3 }}>
							<TextField
								id="userName"
								name="userName"
								label={localeContent.EMAIL_ADDRESS_LABEL}
								type="email"
								onChange={e => setUsername(e.target.value)}
								value={username}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="password"
								name="password"
								label={localeContent.PASSWORD_LABEL}
								type="password"
								onChange={e => setPassword(e.target.value)}
								value={password}
							/>
						</Grid>
					</Paper>
					<Grid container>
						<Grid item xs={12} sx={{ textAlign: "center" }}>
							<Button type="submit">
								{localeContent.SIGN_IN_BUTTON_LABEL}
							</Button>
							<Link
								to="forgot"
								color="inherit"
								component={RouterLink}
								sx={{ display: "block" }}
							>
								{localeContent.FORGOT_PASSWORD_LABEL}
							</Link>
						</Grid>
					</Grid>
				</form>
			</Container>
		</>
	) : (
		<Redirect
			to={getRedirectTo(fromParam, defaultCompanyView || primaryCompanyId)}
		/>
	);
}

export default Login;
