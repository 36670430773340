import commonLC from "../screens/commonLocaleContent";

export default function getDropdownOptionsStatus(
	fetchingStatus,
	countOfOptions
) {
	const status = {};
	if (fetchingStatus) {
		if (fetchingStatus.isLoading) {
			status.noOptionsText = commonLC.FETCHING_OPTIONS;
		} else if (!countOfOptions) {
			status.noOptionsText = commonLC.NO_OPTIONS_AVAILABLE;
		}
		if (!fetchingStatus.isLoading && fetchingStatus.error) {
			status.helperText = commonLC.UNABLE_TO_RETRIEVE_OPTIONS_WARNING;
		}
	}
	return status;
}
