import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { FieldArray, useField } from "formik";
import { Box, Button, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import FormField from "../CrudForm/FormField";
import FormFieldSwitch from "../FormFieldSwitch/FormFieldSwitch";
import FormTextField from "../FormTextField/FormTextField";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import localeContent from "./localeContent";
import crudFormLocaleContent from "../CrudForm/localeContent";
import Yup from "../../config/yupConfig";

const ENABLED_KEY = "enabled";
const CUSTOM_QUERY_KEY = "key";
const CUSTOM_QUERY_VALUE = "value";
const CUSTOM_QUERY_TYPE = "targeting";
export const TEST_ID_ROW = "custom-query-row";

const getDefaultRowConfig = () => ({
	clientId: uuidv4(),
	[CUSTOM_QUERY_KEY]: "",
	[CUSTOM_QUERY_VALUE]: ""
});

// Strips client-side data from rows and adds a hard coded type key (necessary until we deprecate type in the api)
const prepareRowsForSubmit = data => {
	return data.map(({ key, value }) => {
		return { type: CUSTOM_QUERY_TYPE, key, value };
	});
};

export const transformInitData = (data, fieldName) => {
	const enabled = Boolean(data[fieldName]?.length);
	const fieldData = data[fieldName] || {};

	return {
		...fieldData,
		[ENABLED_KEY]: enabled,
		parameter: data[fieldName] || []
	};
};

export const transformSubmitData = (data, fieldName) => {
	const { [ENABLED_KEY]: enabled, ...submissionData } = data;
	return {
		[fieldName]: enabled ? prepareRowsForSubmit(submissionData.parameter) : []
	};
};

function QueryParameterRow(props) {
	const { index, name, deleteRow } = props;
	const fieldNameKey = `${name}[${index}].${CUSTOM_QUERY_KEY}`;
	const fieldNameValue = `${name}[${index}].${CUSTOM_QUERY_VALUE}`;

	const onDeleteRow = useCallback(() => {
		if (deleteRow) deleteRow(index);
	}, [deleteRow, index]);

	return (
		<Grid
			container
			item
			spacing={MUI_GRID_CONTAINER_SPACING}
			alignItems="flex-start"
			data-testid={TEST_ID_ROW}
		>
			<FormField gridConfig={{ md: 3, lg: 4 }}>
				<FormTextField
					id={fieldNameKey}
					label={localeContent.FIELD_LABEL_KEY}
					name={fieldNameKey}
					required
				/>
			</FormField>
			<FormField gridConfig={{ md: 3, lg: 4 }}>
				<FormTextField
					id={fieldNameValue}
					label={localeContent.FIELD_LABEL_VALUE}
					name={fieldNameValue}
					required
				/>
			</FormField>
			{deleteRow && (
				<Grid item xs={2} sm={1}>
					<IconButton
						onClick={onDeleteRow}
						aria-label={localeContent.ARIA_LABEL_DELETE_ROW}
					>
						<DeleteIcon />
					</IconButton>
				</Grid>
			)}
		</Grid>
	);
}

QueryParameterRow.propTypes = {
	index: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	deleteRow: PropTypes.func
};
QueryParameterRow.defaultProps = {
	deleteRow: null
};

function FormFieldCustomQueryParameter(props) {
	const { name } = props;
	const [field, , helpers] = useField({ name });

	useEffect(() => {
		if (field.value.enabled && !field.value.parameter.length) {
			const fieldValueObj = {
				...field.value,
				parameter: [getDefaultRowConfig()]
			};
			helpers.setValue(fieldValueObj);
		}
	}, [field.value, helpers]);

	return (
		<>
			<Box sx={{ mb: field.value[ENABLED_KEY] ? 1 : undefined }}>
				<FormFieldSwitch
					id={`${name}.${ENABLED_KEY}`}
					name={`${name}.${ENABLED_KEY}`}
					label={localeContent.CUSTOM_QUERY_PARAMETER_LABEL}
				/>
			</Box>
			{field.value[ENABLED_KEY] && (
				<FieldArray name={`${name}.parameter`}>
					{fieldArrayHelpers => {
						const addRow = () => {
							fieldArrayHelpers.push(getDefaultRowConfig());
						};
						return (
							<Grid
								container
								direction="column"
								spacing={MUI_GRID_CONTAINER_SPACING}
							>
								{field.value.parameter &&
									field.value.parameter.map(({ clientId }, index) => {
										return (
											<QueryParameterRow
												key={index ?? clientId}
												index={index}
												name={`${name}.parameter`}
												deleteRow={
													field.value.parameter.length > 1
														? fieldArrayHelpers.remove
														: undefined
												}
											/>
										);
									})}
								<Grid item xs={12}>
									<Button variant="contained" onClick={addRow}>
										{localeContent.BUTTON_TEXT_ADD_ROW}
									</Button>
								</Grid>
							</Grid>
						);
					}}
				</FieldArray>
			)}
		</>
	);
}

FormFieldCustomQueryParameter.propTypes = {
	name: PropTypes.string.isRequired
};

export const ValidationSchema = Yup.object({
	parameter: Yup.array().when("enabled", {
		is: true,
		then: Yup.array().of(
			Yup.object({
				[CUSTOM_QUERY_KEY]: Yup.string().required(
					crudFormLocaleContent.REQUIRED_FIELD_WARNING
				),
				[CUSTOM_QUERY_VALUE]: Yup.string().required(
					crudFormLocaleContent.REQUIRED_FIELD_WARNING
				)
			})
		)
	})
});

export default FormFieldCustomQueryParameter;
