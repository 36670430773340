import React from "react";
import PropTypes from "prop-types";
import {
	Box,
	Button,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	useTheme
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import SquareRoundedIcon from "@mui/icons-material/SquareRounded";

import localeContent from "./localeContent";
import { DAYS_LIST, HOURS } from "./constants";

const STYLES = {
	amPmHeaderCell: {
		textAlign: "center",
		padding: "5px",
		textTransform: "uppercase"
	},
	dayRowPadding: {
		pb: 3
	},
	dayHourHeaderColor: {
		color: "#555"
	},
	iconStyles: {
		transform: "scale(1.2)"
	}
};

function DayPartingTable(props) {
	const {
		selectedDays,
		toggleHourSelected,
		toggleAllHoursForDay,
		toggleAllDaysForHour
	} = props;

	const theme = useTheme();
	return (
		<TableContainer>
			<Table
				sx={{
					[`& .${tableCellClasses.root}`]: {
						borderBottom: "none"
					}
				}}
			>
				<TableHead>
					<TableRow>
						<TableCell>{null}</TableCell>
						<TableCell
							colSpan={12}
							sx={{
								backgroundColor: theme.palette.primary[500],
								...STYLES.amPmHeaderCell
							}}
						>
							{localeContent.AM_LABEL}
						</TableCell>
						<TableCell
							colSpan={12}
							sx={{
								backgroundColor: theme.palette.primary[900],
								color: "white",
								...STYLES.amPmHeaderCell
							}}
						>
							{localeContent.PM_LABEL}
						</TableCell>
					</TableRow>
					<TableRow>
						{/* Spacer cell */}
						<TableCell />
						{/* Create a column header for each hour */}
						{HOURS.map((hour, index) => (
							<TableCell
								key={hour.id}
								sx={{
									textAlign: "center",
									padding: 0
								}}
							>
								<Button
									sx={{
										minWidth: 0,
										width: "100%",
										...STYLES.dayHourHeaderColor
									}}
									onClick={() => toggleAllDaysForHour(hour)}
								>
									{hour.name}
									<Box sx={visuallyHidden}>
										{index < 12
											? localeContent.AM_LABEL
											: localeContent.PM_LABEL}
									</Box>
								</Button>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{/* Create the data rows */}
					{DAYS_LIST.map(day => (
						<TableRow key={`${day.id}`}>
							<TableCell
								sx={{
									fontWeight: "500",
									...STYLES.dayRowPadding
								}}
								padding="checkbox"
								align="right"
								component="th"
								scope="row"
							>
								<Button
									sx={{ ...STYLES.dayHourHeaderColor, textTransform: "none" }}
									onClick={() => toggleAllHoursForDay(day)}
								>
									{day.label}
								</Button>
							</TableCell>
							{HOURS.map((hour, index) => (
								<TableCell
									key={`${day.id}-${hour.id}`}
									id={`${day.id}-${hour.id}`}
									padding="checkbox"
									sx={{
										...STYLES.dayRowPadding
									}}
								>
									<Checkbox
										size="large"
										inputProps={{
											"aria-label": `${day.label} ${hour.name}${
												index < 12
													? localeContent.AM_LABEL
													: localeContent.PM_LABEL
											}`
										}}
										sx={{ padding: 0 }}
										icon={
											<SquareRoundedIcon
												sx={{
													...STYLES.iconStyles,
													color: "#EEE"
												}}
											/>
										}
										checkedIcon={<SquareRoundedIcon sx={STYLES.iconStyles} />}
										checked={Boolean(selectedDays[day.id][hour.id])}
										onChange={() => {
											toggleHourSelected(day, hour);
										}}
									/>
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

DayPartingTable.propTypes = {
	selectedDays: PropTypes.shape().isRequired,
	toggleHourSelected: PropTypes.func.isRequired,
	toggleAllHoursForDay: PropTypes.func.isRequired,
	toggleAllDaysForHour: PropTypes.func.isRequired
};
DayPartingTable.defaultProps = {};

export default DayPartingTable;
