import commonLocaleContent from "../screens/commonLocaleContent";
import { UTC_TIME_ZONE } from "../config/constants";

const DETECTED_LOCALE_LANGUAGE = window.navigator
	? window.navigator.language
	: "en";

/**
 * Returns a number with thousands separators and two decimal precision
 * @param {Number} value the number to format
 * @param {String} locale (optional) overrides the DETECTED_LOCAL_LANGUAGE
 */
export function formatAsCurrency(value, locale, precisionDigit) {
	return value || value === 0
		? new Intl.NumberFormat(locale || DETECTED_LOCALE_LANGUAGE, {
				style: "decimal",
				useGrouping: true,
				minimumFractionDigits: 2,
				maximumFractionDigits: precisionDigit || 2
		  }).format(value)
		: "-";
}

/**
 * Returns a number with thousands separators and two decimal precision
 * @param {Number} value the number to format
 * @param {String} locale (optional) overrides the DETECTED_LOCAL_LANGUAGE
 */
const formatPercent = (value, locale) => {
	return value || value === 0
		? new Intl.NumberFormat(locale || DETECTED_LOCALE_LANGUAGE, {
				style: "percent",
				maximumFractionDigits: 2
		  }).format(value)
		: "-";
};
const valueWithoutSymbols = value => value.replace(/[^\d.]*/g, "");

export function formatAsPercent(value, locale) {
	const formattedPercentage = formatPercent(value, locale);

	if (valueWithoutSymbols(formattedPercentage) < 1) {
		return `${valueWithoutSymbols(formattedPercentage)}%`;
	}
	return `${Math.round(valueWithoutSymbols(formattedPercentage))}%`;
}

/**
 *	Returns a formatted number using thousands separator
 * @param {Number} value the number to format
 * @param {String} locale (optional) overrides the DETECTED_LOCAL_LANGUAGE
 */
export function formatAsNumberWithSeparator(value, locale) {
	return value || value === 0
		? value.toLocaleString(locale || DETECTED_LOCALE_LANGUAGE)
		: "-";
}

export function formatAsDate(value, locale) {
	return value
		? new Date(value).toLocaleDateString(locale || DETECTED_LOCALE_LANGUAGE)
		: "-";
}

export function formatAsDateWithTime(value, { locale, timeZone }) {
	const formattedValue = value
		? new Date(value).toLocaleString(locale || DETECTED_LOCALE_LANGUAGE, {
				timeZone
		  })
		: value;
	return `${formattedValue} ${timeZone}`;
}

export function getTimezoneString(tzCode) {
	if (tzCode === UTC_TIME_ZONE) {
		return "UTC";
	}
	return tzCode;
}

export const buildFormatNullValues = (replacementValue = "-") => value =>
	value ?? replacementValue;

export const formatAsEnabled = isEnabled =>
	isEnabled
		? commonLocaleContent.OPTION_LABEL_ENABLED
		: commonLocaleContent.OPTION_LABEL_DISABLED;

export const formatAsCapitalized = value =>
	value.charAt(0).toUpperCase() + value.slice(1);

/**
 * Exports all formatters as a package
 */
export default {
	formatAsCurrency,
	formatAsPercent,
	formatAsNumberWithSeparator,
	formatAsDate,
	formatAsDateWithTime,
	getTimezoneString,
	buildFormatNullValues,
	formatAsEnabled,
	formatAsCapitalized
};
