import localContent from "./localeContent";
import { PRIMARY_COLORS } from "../../config/constants";

const DIMENSION_OPTIONS = {
	DAY: {
		id: "day",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.DAY
	},
	HOUR: {
		id: "hour",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.HOUR
	},
	PUBLISHER: {
		id: "publisher",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.PUBLISHER
	},
	SITE: {
		id: "site",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.SITE
	},
	PLACEMENT: {
		id: "placement",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.PLACEMENT
	},
	INSERTION_ORDER: {
		id: "insertionOrder",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.INSERTION_ORDER
	},
	CAMPAIGN: {
		id: "campaign",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.CAMPAIGN
	},
	MEDIA: {
		id: "media",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.MEDIA
	},
	IAB_CATEGORY: {
		id: "iabCategory",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.IAB_CATEGORY
	},
	OPP_TYPES: {
		id: "opptypes",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.OPP_TYPES
	},
	DOMAIN: {
		id: "domain",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.DOMAIN
	},
	BUYER: {
		id: "buyer",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.BUYER
	},
	BUYERS: {
		id: "buyers",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.BUYERS
	},
	SIZE: {
		id: "size",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.SIZE
	},
	ACTUAL_SIZE: {
		id: "actualSize",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.ACTUAL_SIZE
	},
	TRUE_URL: {
		id: "trueurl",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.TRUE_URL
	},
	MEDIA_TYPE: {
		id: "mediatype",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.MEDIA_TYPE
	},
	DEVICE: {
		id: "device",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.DEVICE
	},
	OPERATING_SYSTEM: {
		id: "operatingSystem",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.OPERATING_SYSTEM
	},
	APP_NAME: {
		id: "appName",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.APP_NAME
	},
	APP_BUNDLE: {
		id: "appBundle",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.APP_BUNDLE
	},
	FLOOR: {
		id: "floor",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.FLOOR
	},
	GEO: {
		id: "geo",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.GEO
	},
	STATE: {
		id: "state",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.STATE
	},
	DMA: {
		id: "dma",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.DMA
	},
	CITY: {
		id: "city",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.CITY
	},
	CAMPAIGN_ID: {
		id: "campaignid",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.CAMPAIGN_ID
	},
	CREATIVE_ID: {
		id: "creativeid",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.CREATIVE_ID
	},
	ADVERTISER_ID: {
		id: "advertiserid",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.ADVERTISER_ID
	},
	ADVERTISER_DOMAIN: {
		id: "advertiserdomain",
		name:
			localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.ADVERTISER_DOMAIN
	},
	DEAL_ID: {
		id: "dealid",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.DEAL_ID
	},
	SEAT_ID: {
		id: "seatId",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.SEAT_ID
	},
	POD_SEQUENCE: {
		id: "podSequence",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.POD_SEQUENCE
	},
	CONTENT_GENRE: {
		id: "contentgenre",
		name: localContent.REPORTING_LIMIT_DIMENSIONS.OPTION_LABELS.CONTENT_GENRE
	}
};

export const PRIMARY_COLOR_OPTIONS = Object.entries(PRIMARY_COLORS).map(
	([, option]) => option
);

export const REPORTING_LIMIT_DIMENSIONS_RTB_OPTIONS = [
	DIMENSION_OPTIONS.DAY,
	DIMENSION_OPTIONS.HOUR,
	DIMENSION_OPTIONS.PUBLISHER,
	DIMENSION_OPTIONS.SITE,
	DIMENSION_OPTIONS.PLACEMENT,
	DIMENSION_OPTIONS.MEDIA_TYPE,
	DIMENSION_OPTIONS.IAB_CATEGORY,
	DIMENSION_OPTIONS.OPP_TYPES,
	DIMENSION_OPTIONS.DOMAIN,
	DIMENSION_OPTIONS.BUYERS,
	DIMENSION_OPTIONS.SIZE,
	DIMENSION_OPTIONS.ACTUAL_SIZE,
	DIMENSION_OPTIONS.TRUE_URL,
	DIMENSION_OPTIONS.CAMPAIGN_ID,
	DIMENSION_OPTIONS.CREATIVE_ID,
	DIMENSION_OPTIONS.ADVERTISER_ID,
	DIMENSION_OPTIONS.ADVERTISER_DOMAIN,
	DIMENSION_OPTIONS.DEAL_ID,
	DIMENSION_OPTIONS.SEAT_ID,
	DIMENSION_OPTIONS.DEVICE,
	DIMENSION_OPTIONS.OPERATING_SYSTEM,
	DIMENSION_OPTIONS.APP_NAME,
	DIMENSION_OPTIONS.APP_BUNDLE,
	DIMENSION_OPTIONS.GEO,
	DIMENSION_OPTIONS.STATE,
	DIMENSION_OPTIONS.DMA,
	DIMENSION_OPTIONS.CITY,
	DIMENSION_OPTIONS.CONTENT_GENRE
];

export const REPORTING_LIMIT_DIMENSIONS_NETWORK_OPTIONS = [
	DIMENSION_OPTIONS.DAY,
	DIMENSION_OPTIONS.HOUR,
	DIMENSION_OPTIONS.PUBLISHER,
	DIMENSION_OPTIONS.SITE,
	DIMENSION_OPTIONS.PLACEMENT,
	DIMENSION_OPTIONS.IAB_CATEGORY,
	DIMENSION_OPTIONS.OPP_TYPES,
	DIMENSION_OPTIONS.DOMAIN,
	DIMENSION_OPTIONS.SIZE,
	DIMENSION_OPTIONS.ACTUAL_SIZE,
	DIMENSION_OPTIONS.TRUE_URL,
	DIMENSION_OPTIONS.MEDIA_TYPE,
	DIMENSION_OPTIONS.DEVICE,
	DIMENSION_OPTIONS.OPERATING_SYSTEM,
	DIMENSION_OPTIONS.APP_NAME,
	DIMENSION_OPTIONS.APP_BUNDLE,
	DIMENSION_OPTIONS.FLOOR,
	DIMENSION_OPTIONS.GEO,
	DIMENSION_OPTIONS.STATE,
	DIMENSION_OPTIONS.DMA,
	DIMENSION_OPTIONS.CITY,
	DIMENSION_OPTIONS.CONTENT_GENRE
];

export const REPORTING_LIMIT_DIMENSIONS_CAMPAIGN_OPTIONS = [
	DIMENSION_OPTIONS.DAY,
	DIMENSION_OPTIONS.HOUR,
	DIMENSION_OPTIONS.INSERTION_ORDER,
	DIMENSION_OPTIONS.CAMPAIGN,
	DIMENSION_OPTIONS.MEDIA,
	DIMENSION_OPTIONS.PUBLISHER,
	DIMENSION_OPTIONS.SITE,
	DIMENSION_OPTIONS.PLACEMENT,
	DIMENSION_OPTIONS.IAB_CATEGORY,
	DIMENSION_OPTIONS.OPP_TYPES,
	DIMENSION_OPTIONS.DOMAIN,
	DIMENSION_OPTIONS.BUYER,
	DIMENSION_OPTIONS.SIZE,
	DIMENSION_OPTIONS.ACTUAL_SIZE,
	DIMENSION_OPTIONS.TRUE_URL,
	DIMENSION_OPTIONS.MEDIA_TYPE,
	DIMENSION_OPTIONS.DEAL_ID,
	DIMENSION_OPTIONS.DEVICE,
	DIMENSION_OPTIONS.OPERATING_SYSTEM,
	DIMENSION_OPTIONS.APP_NAME,
	DIMENSION_OPTIONS.APP_BUNDLE,
	DIMENSION_OPTIONS.GEO,
	DIMENSION_OPTIONS.STATE,
	DIMENSION_OPTIONS.DMA,
	DIMENSION_OPTIONS.CITY,
	DIMENSION_OPTIONS.POD_SEQUENCE,
	DIMENSION_OPTIONS.CONTENT_GENRE
];

export const REPORTING_LIMIT_DIMENSIONS_PUBLISHER_OPTIONS = [
	DIMENSION_OPTIONS.DAY,
	DIMENSION_OPTIONS.HOUR,
	DIMENSION_OPTIONS.PUBLISHER,
	DIMENSION_OPTIONS.SITE,
	DIMENSION_OPTIONS.PLACEMENT,
	DIMENSION_OPTIONS.IAB_CATEGORY,
	DIMENSION_OPTIONS.OPP_TYPES,
	DIMENSION_OPTIONS.DOMAIN,
	DIMENSION_OPTIONS.SIZE,
	DIMENSION_OPTIONS.ACTUAL_SIZE,
	DIMENSION_OPTIONS.TRUE_URL,
	DIMENSION_OPTIONS.MEDIA_TYPE,
	DIMENSION_OPTIONS.DEVICE,
	DIMENSION_OPTIONS.OPERATING_SYSTEM,
	DIMENSION_OPTIONS.APP_NAME,
	DIMENSION_OPTIONS.APP_BUNDLE,
	DIMENSION_OPTIONS.FLOOR,
	DIMENSION_OPTIONS.GEO,
	DIMENSION_OPTIONS.STATE,
	DIMENSION_OPTIONS.DMA,
	DIMENSION_OPTIONS.CITY,
	DIMENSION_OPTIONS.CONTENT_GENRE
];
