import React from "react";
import PropTypes from "prop-types";

/**
 * Wraps tab content to:
 *   - control content visibility
 *   - add accessibility attributes
 * @param {Object} props defined in PropTypes
 */
function TabPanel(props) {
	const { children, tabKey, activeTabKey } = props;
	return (
		<div
			role="tabpanel"
			hidden={tabKey !== activeTabKey}
			id={`tabpanel-${tabKey}`}
			aria-labelledby={`tab-${tabKey}`}
		>
			{tabKey === activeTabKey && children}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node.isRequired,
	tabKey: PropTypes.string.isRequired,
	activeTabKey: PropTypes.string.isRequired
};

export default TabPanel;
