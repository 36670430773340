const getLastItem = array => {
	const length = array == null ? 0 : array.length;
	return length ? array[length - 1] : undefined;
};

const getFirstItem = array => {
	return array != null && array.length ? array[0] : undefined;
};

export default {
	getLastItem,
	getFirstItem
};
