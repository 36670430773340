export const DAYS_LIST = [
	{ id: "sunday", label: "Sunday" },
	{ id: "monday", label: "Monday" },
	{ id: "tuesday", label: "Tuesday" },
	{ id: "wednesday", label: "Wednesday" },
	{ id: "thursday", label: "Thursday" },
	{ id: "friday", label: "Friday" },
	{ id: "saturday", label: "Saturday" }
];

export const HOURS = [
	{ id: 0, name: "12" },
	{ id: 1, name: "1" },
	{ id: 2, name: "2" },
	{ id: 3, name: "3" },
	{ id: 4, name: "4" },
	{ id: 5, name: "5" },
	{ id: 6, name: "6" },
	{ id: 7, name: "7" },
	{ id: 8, name: "8" },
	{ id: 9, name: "9" },
	{ id: 10, name: "10" },
	{ id: 11, name: "11" },
	{ id: 12, name: "12" },
	{ id: 13, name: "1" },
	{ id: 14, name: "2" },
	{ id: 15, name: "3" },
	{ id: 16, name: "4" },
	{ id: 17, name: "5" },
	{ id: 18, name: "6" },
	{ id: 19, name: "7" },
	{ id: 20, name: "8" },
	{ id: 21, name: "9" },
	{ id: 22, name: "10" },
	{ id: 23, name: "11" }
];
