import React from "react";
import PropTypes from "prop-types";
import { IconButton, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import lc from "./localeContent";

function ExpansionCell(props) {
	const { value, row, colDef } = props;
	const { onClick, expansionColumnId, isExpanded, filters = {} } = row;

	const shouldRenderExpansionIcon = colDef.field === expansionColumnId;
	const shouldRenderAll = !filters[colDef.field];

	return (
		value || (
			<>
				{shouldRenderExpansionIcon && (
					<IconButton
						onClick={onClick}
						aria-label={
							isExpanded
								? lc.EXPANSION_ICON_LABEL.COLLAPSE
								: lc.EXPANSION_ICON_LABEL.EXPAND
						}
					>
						{isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
					</IconButton>
				)}
				{shouldRenderAll && <Typography>{lc.ALL_LABEL}</Typography>}
			</>
		)
	);
}

ExpansionCell.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	row: PropTypes.shape({
		onClick: PropTypes.func,
		expansionColumnId: PropTypes.string,
		isExpanded: PropTypes.bool,
		filters: PropTypes.shape({})
	}).isRequired,
	colDef: PropTypes.shape({ field: PropTypes.string.isRequired }).isRequired
};

ExpansionCell.defaultProps = {
	value: undefined
};

export default ExpansionCell;
