import React from "react";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import FormTextField from "../FormTextField/FormTextField";

const DEFAULT_INPUT_ADORNMENT_TEXT = "ms";

/**
 * A wrapper around the base FormTextField that:
 *   - adds number attributes to the input
 *   - adds a configurable input adornment
 * @param {Object} props defined in propTypes
 */
function FormFieldDuration(props) {
	const { name, id, label, fieldTypeOptions } = props;
	const { inputAdornmentText } = fieldTypeOptions;
	return (
		<FormTextField
			label={label}
			id={id}
			name={name}
			type="number"
			// This is a material-ui prop so we have no control over this
			// eslint-disable-next-line react/jsx-no-duplicate-props
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						{inputAdornmentText || DEFAULT_INPUT_ADORNMENT_TEXT}
					</InputAdornment>
				)
			}}
		/>
	);
}

FormFieldDuration.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	fieldTypeOptions: PropTypes.shape()
};

FormFieldDuration.defaultProps = {
	fieldTypeOptions: {}
};

export default FormFieldDuration;
