import React from "react";
import PropTypes from "prop-types";

import BasicLineChart from "./BasicLineChart";
import hourLabels from "./hourLabels";

function HourlyChart(props) {
	const { metricsData, isLoadingMetricsData, reportType, series } = props;

	return (
		<BasicLineChart
			metricsData={metricsData}
			isLoadingMetricsData={isLoadingMetricsData}
			ticks={[hourLabels[3], hourLabels[9], hourLabels[15], hourLabels[21]]}
			getSeriesPointLabel={index => hourLabels[index]}
			reportType={reportType}
			seriesDefaultData={series}
		/>
	);
}

HourlyChart.propTypes = {
	metricsData: PropTypes.arrayOf(PropTypes.shape()),
	isLoadingMetricsData: PropTypes.bool.isRequired,
	reportType: PropTypes.string.isRequired,
	series: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

HourlyChart.defaultProps = {
	metricsData: null
};

export default HourlyChart;
