import { useReducer } from "react";

const skipInitValueChangeReducer = (state, action) => {
	if (action.type === "VALUE_CHANGED") {
		// Skip the initialization change event
		if (!state.isInitialized) {
			return {
				...state,
				isInitialized: true
			};
		}
		// Subsequent changes should reset the value
		return {
			...state,
			isChanged: true
		};
	}
	if (action.type === "RESET") {
		return {
			...state,
			isChanged: false
		};
	}
	return state;
};

/**
 * Used to manage the state aspect of a strategy that skips the first attempt to set a value
 * @returns [isChanged, dispatch]
 * 	isChanged is a boolean representing whether the change is valid or not
 * 	dispatch is used to set the isChanged state
 */
const useSkipInitChangeWatcher = () => {
	const [state, dispatch] = useReducer(skipInitValueChangeReducer, {
		isChanged: false,
		isInitialized: false
	});
	const { isChanged } = state;
	return [isChanged, dispatch];
};

export default useSkipInitChangeWatcher;
