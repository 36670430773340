import React from "react";
import PropTypes from "prop-types";
import commonLocaleContent from "../../components/CrudDataTable/localeContent";
import localeContent from "./localeContent";
import GenericTableRowActionMenu, {
	ACTION_MENU_ITEM_TYPES
} from "../../components/GenericTableRowActionMenu/GenericTableRowActionMenu";
import constants from "./constants";

const ACTION_MENU_DOWNLOAD_TYPE = {
	downloadURLExcel: "downloadURLExcel",
	downloadURLCSV: "downloadURLCSV",
	downloadURLJSON: "downloadURLJSON"
};

const RESOURCE_SHARE_ENDPOINT = (resourceEndPoint, reportId) =>
	`${resourceEndPoint}/${reportId}/share`;

const RESOURCE_RETRY_ENDPOINT = (resourceEndPoint, reportId) =>
	`${resourceEndPoint}/${reportId}/retry`;

function CompletedTableRowActionMenu(props) {
	const { rowData, resourceEndpoint, resourceName, deleteItemCallback } = props;
	const { id: rowItemId, name: rowItemName } = rowData;

	const menuOptions = [
		{
			key: constants.downloadFileTypes.DOWNLOAD_EXCEL,
			label: localeContent.ACTION_BUTTON_LABEL_DOWNLOAD_EXCEL,
			accessibilityLabel: localeContent.ACCESSIBILITY_LABEL_DOWNLOAD_EXCEL,
			href: rowData[ACTION_MENU_DOWNLOAD_TYPE.downloadURLExcel],
			download: "report.xls",
			type: ACTION_MENU_ITEM_TYPES.externalLink
		},
		{
			key: constants.downloadFileTypes.DOWNLOAD_CSV,
			label: localeContent.ACTION_BUTTON_LABEL_DOWNLOAD_CSV,
			accessibilityLabel: localeContent.ACCESSIBILITY_LABEL_DOWNLOAD_CSV,
			href: rowData[ACTION_MENU_DOWNLOAD_TYPE.downloadURLCSV],
			download: "report.csv",
			type: ACTION_MENU_ITEM_TYPES.externalLink
		},
		{
			key: "SHARE",
			type: ACTION_MENU_ITEM_TYPES.shareResource,
			label: localeContent.ACTION_BUTTON_LABEL_SHARE,
			title: localeContent.SHARE_RESOURCE_MODAL.TITLE,
			description: localeContent.SHARE_RESOURCE_MODAL.DESCRIPTION({
				itemName: rowItemName
			}),
			confirmLabel:
				localeContent.SHARE_RESOURCE_MODAL.ACTION_BUTTON_LABEL_CONFIRM,
			resourceEndpoint: RESOURCE_SHARE_ENDPOINT(resourceEndpoint, rowItemId)
		},
		{
			key: "RETRY",
			type: ACTION_MENU_ITEM_TYPES.confirmPostResource,
			label: localeContent.ACTION_BUTTON_LABEL_RETRY,
			title: localeContent.RETRY_RESOURCE_MODAL.TITLE,
			successMessage: localeContent.RETRY_RESOURCE_MODAL.SUCCESS_MESSAGE({
				itemName: rowItemName
			}),
			description: localeContent.RETRY_RESOURCE_MODAL.DESCRIPTION({
				itemName: rowItemName
			}),
			confirmLabel:
				localeContent.RETRY_RESOURCE_MODAL.ACTION_BUTTON_LABEL_CONFIRM,
			resourceEndpoint: RESOURCE_RETRY_ENDPOINT(resourceEndpoint, rowItemId),
			permissionRequired: "REPORTING_RUN_RETRY"
		},
		{
			key: "DELETE",
			type: ACTION_MENU_ITEM_TYPES.deleteResource,
			label: commonLocaleContent.ACTION_BUTTON_LABEL_DELETE,
			title: commonLocaleContent.DELETE_RESOURCE_MODAL.TITLE({ resourceName }),
			description: commonLocaleContent.DELETE_RESOURCE_MODAL.DESCRIPTION({
				itemName: rowItemName
			}),
			confirmLabel:
				commonLocaleContent.DELETE_RESOURCE_MODAL.ACTION_BUTTON_LABEL_CONFIRM,
			deleteItemCallback,
			successMessage: commonLocaleContent.DELETE_RESOURCE_SUCCESS_MESSAGE(
				rowItemName
			),
			resourceEndpoint
		}
	];
	const shouldRenderActionMenu =
		rowData.status !== constants.reportStatus.PENDING &&
		rowData.status !== constants.reportStatus.RUNNING;
	return (
		shouldRenderActionMenu && (
			<GenericTableRowActionMenu rowData={rowData} menuOptions={menuOptions} />
		)
	);
}

CompletedTableRowActionMenu.propTypes = {
	rowData: PropTypes.shape().isRequired,
	resourceEndpoint: PropTypes.string,
	resourceName: PropTypes.string.isRequired,
	deleteItemCallback: PropTypes.func
};

CompletedTableRowActionMenu.defaultProps = {
	deleteItemCallback: null,
	resourceEndpoint: null
};

export default CompletedTableRowActionMenu;
