import React, { useEffect } from "react";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";
import Tooltip from "../Tooltip/Tooltip";

const getRenderValueFunction = (value, noValueOption, options) => {
	if (!noValueOption) return null;
	const selectedOption = options.find(
		option => option.value.toString() === value.toString()
	);
	const renderedLabel = selectedOption?.label || noValueOption.label || "";
	return () => renderedLabel;
};

const buildOptionsWithFetchingMessage = (options, fetchingStatusMessage) => {
	const messageOptions = [];
	if (fetchingStatusMessage) {
		messageOptions.push({ value: "", label: fetchingStatusMessage });
	}
	return [...messageOptions, ...options];
};

function Select(props) {
	const {
		id,
		label,
		ariaOnlyLabel,
		name,
		value,
		options,
		onChange,
		onBlur,
		tooltip,
		disabled,
		readOnly,
		showError,
		helperText,
		required,
		variant,
		fetchingStatusMessage
	} = props;

	const labelId = `${id}-select-label`;

	const [tooltipOpen, setTooltipOpen] = React.useState(false);
	const [selectOpen, setSelectOpen] = React.useState(false);

	const handleTooltipClose = () => {
		setTooltipOpen(false);
	};

	const handleTooltipOpen = () => {
		// Hide the tooltip when the select options are open so that we don't cover them up with the tooltip
		setTooltipOpen(!selectOpen);
	};

	const handleSelectClose = () => {
		setSelectOpen(false);
	};

	const handleSelectOpen = () => {
		setSelectOpen(true);
	};

	useEffect(() => {
		if (selectOpen) {
			setTooltipOpen(false);
		}
	}, [selectOpen]);

	// Empty select values do not render normally, so we need to use a combination of displayEmpty, renderValue, and shrink per https://github.com/mui-org/material-ui/issues/8581
	const noValueOption = options.find(option => option.value === "");
	const renderValueFunction = getRenderValueFunction(
		value,
		noValueOption,
		options
	);

	const inputProps = ariaOnlyLabel ? { "aria-label": label } : undefined;
	const renderedOptions = buildOptionsWithFetchingMessage(
		options,
		fetchingStatusMessage
	);

	const formControl = (
		<FormControl
			variant={variant || "filled"}
			fullWidth
			error={showError}
			disabled={disabled}
			required={required}
		>
			{!ariaOnlyLabel && (
				<InputLabel id={labelId} shrink={Boolean(noValueOption || value)}>
					{label}
				</InputLabel>
			)}
			<MuiSelect
				labelId={ariaOnlyLabel ? undefined : labelId}
				id={id}
				name={name}
				value={value || ""}
				onChange={onChange}
				onBlur={onBlur}
				onClose={handleSelectClose}
				onOpen={handleSelectOpen}
				displayEmpty={Boolean(noValueOption)}
				renderValue={renderValueFunction}
				inputProps={inputProps}
				readOnly={readOnly}
			>
				{renderedOptions.map(option => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</MuiSelect>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);

	return tooltip ? (
		<Tooltip
			open={tooltipOpen}
			onClose={handleTooltipClose}
			onOpen={handleTooltipOpen}
			title={tooltip || ""}
		>
			{formControl}
		</Tooltip>
	) : (
		formControl
	);
}

Select.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	ariaOnlyLabel: PropTypes.bool,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.shape()),
	value: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	showError: PropTypes.bool,
	helperText: PropTypes.string,
	variant: PropTypes.string,
	fetchingStatusMessage: PropTypes.string
};

Select.defaultProps = {
	ariaOnlyLabel: false,
	disabled: false,
	readOnly: false,
	required: false,
	options: [],
	value: null,
	tooltip: null,
	onChange: null,
	onBlur: null,
	showError: false,
	helperText: null,
	variant: undefined,
	fetchingStatusMessage: undefined
};

export default Select;
