import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useField, useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import useResource, { Operations } from "../../hooks/useResource";
import transformOptions from "../../utils/transformOptions";
import FormFieldMultiselect from "../FormFieldMultiselect/FormFieldMultiselect";
import FormField from "../CrudForm/FormField";
import FormFieldSelect from "../FormFieldSelect/FormFieldSelect";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import localeContent from "./localeContent";

const ACCESS_RESTRICTION_KEY = "accessRestriction";
const WHITELIST_KEY = "whitelist";
const BLACKLIST_KEY = "blacklist";

export const ACCESS_RESTRICTION_TYPE_ENUM = {
	ALL: "all",
	BLACKLIST: "blacklist",
	WHITELIST: "whitelist",
	NONE: "none"
};
export const DEFAULT_ACCESS_RESTRICTION_TYPE_OPTIONS = [
	{
		value: ACCESS_RESTRICTION_TYPE_ENUM.BLACKLIST,
		label: localeContent.RESTRICTION_TYPE_LABELS.BLACKLIST
	},
	{
		value: ACCESS_RESTRICTION_TYPE_ENUM.WHITELIST,
		label: localeContent.RESTRICTION_TYPE_LABELS.WHITELIST
	}
];

const DEFAULT_GRID_CONFIG = {
	accessRestrictionType: { md: 4 },
	accessRestrictionList: { md: 8, lg: 6, xl: 4 }
};

function FormFieldAccessRestriction(props) {
	const { name, optionsConfig, fieldTypeOptions } = props;
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const { requestEndpoint, requestParams } = optionsConfig;
	const {
		accessRestrictionTypeLabel,
		accessRestrictionTypeOptions,
		blacklistLabel,
		whitelistLabel
	} = fieldTypeOptions;
	// Restriction List options
	const [optionsData, requestError, isLoadingOptions] = useResource(
		requestEndpoint,
		Operations.LIST,
		{
			requestParams
		}
	);
	const accessRestrictionListOptions = transformOptions(
		optionsConfig,
		optionsData
	);

	const fieldNames = React.useMemo(
		() => ({
			accessRestriction: `${name}.accessRestriction`,
			whitelist: `${name}.whitelist`,
			blacklist: `${name}.blacklist`
		}),
		[name]
	);

	const gridConfig = fieldTypeOptions.gridConfig || DEFAULT_GRID_CONFIG;

	useEffect(() => {
		if (meta.touched) {
			switch (field.value.accessRestriction) {
				case ACCESS_RESTRICTION_TYPE_ENUM.NONE:
				case ACCESS_RESTRICTION_TYPE_ENUM.ALL:
					setFieldValue(fieldNames.whitelist, []);
					setFieldValue(fieldNames.blacklist, []);
					break;
				case ACCESS_RESTRICTION_TYPE_ENUM.BLACKLIST:
					setFieldValue(fieldNames.whitelist, []);
					break;
				case ACCESS_RESTRICTION_TYPE_ENUM.WHITELIST:
					setFieldValue(fieldNames.blacklist, []);
					break;
				default:
			}
		}
	}, [field.value.accessRestriction, meta.touched, setFieldValue, fieldNames]);

	const hasOptionsRequestError = Boolean(!isLoadingOptions && requestError);
	return (
		<Grid container spacing={MUI_GRID_CONTAINER_SPACING}>
			<FormField gridConfig={gridConfig.accessRestrictionType}>
				<FormFieldSelect
					id={fieldNames.accessRestriction}
					name={fieldNames.accessRestriction}
					label={accessRestrictionTypeLabel}
					optionsConfig={{ options: accessRestrictionTypeOptions }}
				/>
			</FormField>

			{field.value.accessRestriction ===
				ACCESS_RESTRICTION_TYPE_ENUM.WHITELIST && (
				<FormField gridConfig={gridConfig.accessRestrictionList}>
					<FormFieldMultiselect
						id={fieldNames.whitelist}
						name={fieldNames.whitelist}
						label={whitelistLabel}
						optionsConfig={{
							options: accessRestrictionListOptions,
							showOptionsRequestError: hasOptionsRequestError
						}}
						required
						fieldTypeOptions={fieldTypeOptions}
					/>
				</FormField>
			)}
			{field.value.accessRestriction ===
				ACCESS_RESTRICTION_TYPE_ENUM.BLACKLIST && (
				<FormField gridConfig={gridConfig.accessRestrictionList}>
					<FormFieldMultiselect
						id={fieldNames.blacklist}
						name={fieldNames.blacklist}
						label={blacklistLabel}
						optionsConfig={{
							options: accessRestrictionListOptions,
							showOptionsRequestError: hasOptionsRequestError
						}}
						required
					/>
				</FormField>
			)}
		</Grid>
	);
}

const oneItemRequiredSchema = Yup.array().min(
	1,
	localeContent.ONE_ITEM_REQUIRED_WARNING
);
export const ValidationSchema = Yup.object({
	[ACCESS_RESTRICTION_KEY]: Yup.string(),
	[WHITELIST_KEY]: Yup.array().when(ACCESS_RESTRICTION_KEY, {
		is: accessRestriction =>
			accessRestriction === ACCESS_RESTRICTION_TYPE_ENUM.WHITELIST,
		then: oneItemRequiredSchema
	}),
	[BLACKLIST_KEY]: Yup.array().when(ACCESS_RESTRICTION_KEY, {
		is: accessRestriction =>
			accessRestriction === ACCESS_RESTRICTION_TYPE_ENUM.BLACKLIST,
		then: oneItemRequiredSchema
	})
});

FormFieldAccessRestriction.propTypes = {
	name: PropTypes.string.isRequired,
	optionsConfig: PropTypes.shape(),
	fieldTypeOptions: PropTypes.shape()
};

FormFieldAccessRestriction.defaultProps = {
	optionsConfig: {},
	fieldTypeOptions: {}
};

export default FormFieldAccessRestriction;
