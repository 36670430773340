import React from "react";
import { AppBar } from "@mui/material";
import Box from "@mui/material/Box";

function AppBarWithLogo() {
	return (
		<AppBar
			position="static"
			sx={{
				background: "#121244",
				p: 2.5,
				boxShadow: "none"
			}}
		>
			<Box
				sx={{
					display: "block",
					width: theme => theme.spacing(30),
					height: theme => theme.spacing(7.5),
					background: "url(/siprocal_logo.svg)",
					backgroundRepeat: "no-repeat",
					position: "relative"
				}}
			/>
		</AppBar>
	);
}

export default AppBarWithLogo;
