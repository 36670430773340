export default {
	COLUMN_TYPE: "Type",
	COLUMN_PLACEMENT_TRACKER: "Placement Tracker",
	COLUMN_MEDIA_TRACKER: "Media Tracker",
	TRACKER_NAME: {
		LABEL: "Name"
	},
	TRACKER_LABEL: {
		LABEL: "Label",
		TOOLTIP: "Label will be displayed in the Media and Placement form"
	},
	TRACKER_TYPE: {
		LABEL: "Tracker Type",
		TOOLTIP: "Determines whether the tracker is a pixel or JS tag",
		OPTION_LABELS: {
			BEACON: "Beacon",
			JAVASCRIPT: "Javascript"
		}
	},
	EVENT: {
		LABEL: "Event",
		TOOLTIP:
			"Determines if the tracker should fire with the Opportunity or Impression event",
		OPTION_LABELS: {
			OPPORTUNITY: "Opportunity",
			IMPRESSION: "Impression"
		}
	},
	TRACKER_URL: {
		LABEL: "Tracker URL"
	},
	PLACEMENT_TRACKER: {
		LABEL: "Placement Tracker",
		TOOLTIP: "If Enabled, the tracker may be applied to Placements"
	},
	DEFAULT_PLACEMENT_TRACKER: {
		LABEL: "Default Placement Tracker",
		TOOLTIP: "If Enabled, the tracker should be applied to all Placements"
	},
	MEDIA_TRACKER: {
		LABEL: "Media Tracker",
		TOOLTIP: "If Enabled, the tracker may be applied to Media"
	},
	DEFAULT_MEDIA_TRACKER: {
		LABEL: "Default Media Tracker",
		TOOLTIP: "If Enabled, the tracker should be applied to all Media"
	}
};
