import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { useDebouncedCallback } from "use-debounce";
import { isEmpty, orderBy, uniq } from "lodash";
import Tooltip from "../Tooltip/Tooltip";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import { Operations } from "../../hooks/useResource";
import commonLocaleContent from "../../screens/commonLocaleContent";

const MAX_OPTIONS = 200;
const DEFAULT_NAME_PROPERTY = "name";

const getLimitedUniqueOptions = options =>
	orderBy(uniq(options).slice(0, MAX_OPTIONS), DEFAULT_NAME_PROPERTY, "asc");

function MultiselectTypeAhead(props) {
	const {
		id,
		label,
		name,
		required,
		disabled,
		showError,
		helperText,
		onChange,
		onBlur,
		options,
		selectedOptions,
		tooltip,
		preSelected,
		transformOptions,
		requestEndpoint,
		noOptionsText,
		minCharsRequired
	} = props;
	const preselectedItems = preSelected.map(item => item.id);

	const disablePreselectedChip = chipId => {
		return (
			chipId === preselectedItems.find(selectedItem => selectedItem === chipId)
		);
	};
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [selectOpen, setSelectOpen] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [filteredOptions, setFilteredOptions] = useState(
		getLimitedUniqueOptions(options)
	);
	const [allSelectedOptions, setAllSelectedOptions] = useState(selectedOptions);
	const userTyped = useRef(false);

	const handleTooltipClose = () => setTooltipOpen(false);

	// Hide the tooltip when the select options are open so that we don't cover them up with the tooltip
	const handleTooltipOpen = () => setTooltipOpen(!selectOpen);

	const handleSelectClose = () => setSelectOpen(false);

	const handleSelectOpen = () => setSelectOpen(true);

	useEffect(() => {
		if (selectOpen) {
			setTooltipOpen(false);
		} else {
			setInputValue("");
		}
	}, [selectOpen]);

	const {
		data: optionsLoaded,
		isLoading,
		error,
		execute: executeApi
	} = useResourceAsync(requestEndpoint, Operations.LIST);

	const handleChange = (event, newValue) => onChange(event, newValue);

	const fetch = useDebouncedCallback(() => {
		if (!isEmpty(inputValue) && inputValue.length >= minCharsRequired) {
			const requestParams = { searchString: inputValue };
			executeApi({ requestParams });
			userTyped.current = true;
		} else if (isEmpty(inputValue)) {
			// to show default options
			setFilteredOptions(getLimitedUniqueOptions(options));
		} else {
			// to show type at least three chars
			setFilteredOptions([]);
		}
	}, 1000);

	useEffect(() => {
		if (isEmpty(inputValue)) {
			setFilteredOptions(getLimitedUniqueOptions(options));
			return;
		}
		fetch();
	}, [inputValue, fetch, options]);

	useEffect(() => {
		if (!isLoading && !isEmpty(inputValue) && userTyped.current) {
			const allOptions = optionsLoaded ? transformOptions(optionsLoaded) : [];
			setFilteredOptions(getLimitedUniqueOptions(allOptions));
			userTyped.current = false;
		}
	}, [isLoading, options, inputValue, transformOptions, optionsLoaded]);

	// done to reset the selected back to empty on change of dimension
	useEffect(() => {
		setAllSelectedOptions(selectedOptions);
	}, [selectedOptions]);

	const generateNoOptionText = useCallback(() => {
		if (isLoading) {
			return commonLocaleContent.FETCHING_OPTIONS;
		}
		if (inputValue.length > 0 && inputValue.length < minCharsRequired) {
			return commonLocaleContent.TYPE_ATLEAST_3_CHARS;
		}
		return noOptionsText || commonLocaleContent.NO_OPTIONS_AVAILABLE;
	}, [isLoading, noOptionsText, inputValue, minCharsRequired]);

	return (
		<Autocomplete
			multiple
			disableCloseOnSelect
			id={id}
			onChange={handleChange}
			inputValue={inputValue}
			onBlur={onBlur}
			disabled={disabled}
			value={allSelectedOptions}
			options={[...allSelectedOptions, ...filteredOptions]}
			getOptionLabel={option => option.name || ""}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			noOptionsText={generateNoOptionText()}
			getOptionDisabled={option => disablePreselectedChip(option.id)} // This will disabled the dropdown items too whose chips are disabled.
			onClose={handleSelectClose}
			onOpen={handleSelectOpen}
			filterOptions={option => option}
			/* Fix invalid value warning following this s://github.com/mui/material-ui/issues/18514#issuecomment-656604963 */
			filterSelectedOptions
			/*
        Fixed the Warning: Encountered two children with the same key issue.
        Reference: https://stackoverflow.com/questions/69395945/how-can-i-add-unique-keys-to-react-mui-autocomplete-component
      */
			renderOption={(renderProps, option) => {
				/*
        <li> tag is the default MUI implementation
        https://github.com/mui/material-ui/blob/41210c9313cf6481838e857f6430e5c4e1ca5148/packages/mui-material/src/Autocomplete/Autocomplete.js#L544
        */
				return (
					<li
						// This is a material-ui prop so we have no control over this
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...renderProps}
						key={option.id}
					>
						{option.name}
					</li>
				);
			}}
			renderInput={params => {
				const textField = (
					<TextField
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...params}
						label={label}
						error={showError}
						helperText={
							error
								? commonLocaleContent.UNABLE_TO_RETRIEVE_OPTIONS_WARNING
								: helperText
						}
						onChange={e => setInputValue(e.target.value)}
						required={required}
						name={name}
						variant="filled"
					/>
				);
				return tooltip ? (
					<Tooltip
						title={tooltip || ""}
						open={tooltipOpen}
						onClose={handleTooltipClose}
						onOpen={handleTooltipOpen}
					>
						{textField}
					</Tooltip>
				) : (
					textField
				);
			}}
			// We use render tags to render an outlined variant of the Chip
			renderTags={(value, getTagProps) =>
				value.map((option, index) => {
					return (
						<Chip
							key={option.id}
							variant="outlined"
							size="small"
							label={option.name}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...getTagProps({ index })}
							data-testid="multiselect-chip"
							disabled={disablePreselectedChip(option.id)}
						/>
					);
				})
			}
		/>
	);
}

MultiselectTypeAhead.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	showError: PropTypes.bool,
	helperText: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.shape()),
	selectedOptions: PropTypes.arrayOf(PropTypes.shape()),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
	preSelected: PropTypes.arrayOf(PropTypes.shape()),
	transformOptions: PropTypes.func.isRequired,
	requestEndpoint: PropTypes.string.isRequired,
	noOptionsText: PropTypes.string,
	minCharsRequired: PropTypes.number
};

MultiselectTypeAhead.defaultProps = {
	required: false,
	disabled: false,
	onChange: null,
	onBlur: null,
	showError: false,
	helperText: null,
	options: [],
	selectedOptions: [],
	tooltip: null,
	preSelected: [],
	noOptionsText: null,
	minCharsRequired: 3
};

export default MultiselectTypeAhead;
