import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { FieldArray, useField } from "formik";
import { Button, Grid, IconButton } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import FormField from "../CrudForm/FormField";
import FormTextField from "../FormTextField/FormTextField";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import localeContent from "./localeContent";
import crudFormLocaleContent from "../CrudForm/localeContent";
import useErrorDeserializer from "../../hooks/useErrorDeserializer";
import Yup from "../../config/yupConfig";

const SEAT_ID_KEY = "seatId";
const SEAT_NAME_KEY = "seatName";
export const TEST_ID_SEAT_ROW = "seat-row";

function BidderSeatRow(props) {
	const { index, name, deleteRow, disabled } = props;
	const seatIdFieldName = `${name}[${index}].${SEAT_ID_KEY}`;
	const seatNameFieldName = `${name}[${index}].${SEAT_NAME_KEY}`;

	const onDeleteRow = useCallback(() => {
		deleteRow(index);
	}, [deleteRow, index]);

	return (
		<Grid
			container
			item
			spacing={MUI_GRID_CONTAINER_SPACING}
			alignItems="flex-start"
			data-testid={TEST_ID_SEAT_ROW}
		>
			<FormField gridConfig={{ xs: 5 }}>
				<FormTextField
					id={seatIdFieldName}
					label={localeContent.FIELD_LABEL_SEAT_ID}
					name={seatIdFieldName}
					disabled={disabled}
					required
				/>
			</FormField>
			<FormField gridConfig={{ xs: 5, sm: 6 }}>
				<FormTextField
					id={seatNameFieldName}
					label={localeContent.FIELD_LABEL_SEAT_NAME}
					name={seatNameFieldName}
					disabled={disabled}
					required
				/>
			</FormField>
			<Grid item xs={2} sm={1}>
				<IconButton
					onClick={onDeleteRow}
					aria-label={localeContent.ARIA_LABEL_DELETE_ROW}
				>
					<DeleteIcon fontSize="inherit" />
				</IconButton>
			</Grid>
		</Grid>
	);
}

BidderSeatRow.propTypes = {
	index: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	deleteRow: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired
};

function FormFieldBidderSeats(props) {
	const { name } = props;
	const [field, meta, helpers] = useField({ name });

	useErrorDeserializer(meta.error, helpers);

	return (
		<FieldArray name={name}>
			{fieldArrayHelpers => {
				const addNewSeat = () => {
					fieldArrayHelpers.push({
						clientId: uuidv4(),
						seatId: "",
						seatName: ""
					});
				};
				return (
					<Grid
						container
						direction="column"
						spacing={MUI_GRID_CONTAINER_SPACING}
					>
						{field.value.map(({ clientId, id }, index) => (
							<BidderSeatRow
								key={id ?? clientId}
								index={index}
								name={name}
								deleteRow={fieldArrayHelpers.remove}
								disabled={id !== undefined}
							/>
						))}
						<Grid item xs={12}>
							<Button variant="contained" onClick={addNewSeat}>
								{localeContent.BUTTON_TEXT_ADD_ROW}
							</Button>
						</Grid>
					</Grid>
				);
			}}
		</FieldArray>
	);
}

FormFieldBidderSeats.propTypes = {
	name: PropTypes.string.isRequired
};

export const formFieldBidderSeatsValidationSchema = Yup.array().of(
	Yup.object({
		[SEAT_ID_KEY]: Yup.string().required(
			crudFormLocaleContent.REQUIRED_FIELD_WARNING
		),
		[SEAT_NAME_KEY]: Yup.string().required(
			crudFormLocaleContent.REQUIRED_FIELD_WARNING
		)
	}).uniqueProperty(SEAT_ID_KEY, localeContent.UNIQUE_SEAT_ID_WARNING)
);

export default FormFieldBidderSeats;
