import React from "react";
import CrudForm from "../../components/CrudForm/CrudForm";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import FieldTypes from "../../components/CrudForm/FieldTypes";

const FIELDS_CONFIG = [
	{
		key: "BASIC_SETTINGS",
		fields: [
			{
				name: "name",
				label: localeContent.NAME_LABEL,
				gridConfig: { md: 6, lg: 4 },
				isRequired: true
			},
			{
				name: "description",
				label: localeContent.DESCRIPTION_LABEL,
				gridConfig: { md: 6, lg: 4 },
				isRequired: true
			}
		]
	},
	{
		key: "PERMISSIONS_SETTINGS",
		fields: [
			{
				name: "permissions",
				fieldType: FieldTypes.PERMISSIONS,
				defaultValue: []
			}
		]
	}
];

function RolesResource() {
	return (
		<CrudForm
			resourceEndpoint="manage/user-roles"
			resourceRoute="user-roles"
			resourceString={commonLocaleContent.ROLE}
			fieldsConfig={FIELDS_CONFIG}
			prepopulateCreate
		/>
	);
}

export default RolesResource;
