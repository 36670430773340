export default {
	NUMBER_ABBREVIATION: {
		TRILLIONS: "t",
		BILLIONS: "b",
		MILLIONS: "m",
		THOUSANDS: "k"
	},
	CHART_WARNING: {
		CHART_DATA_FETCH_ERROR: "Unable to load chart data.",
		MORE_THAN_31_DAYS:
			"In an effort to load your data faster we have hidden charts that display hourly."
	}
};
