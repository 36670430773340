import commonLocaleContent from "../../screens/commonLocaleContent";

export default {
	SAVE_BUTTON_LABEL: commonLocaleContent.SAVE,
	CREATE_RESOURCE_TITLE: "Add",
	EDIT_RESOURCE_TITLE: "Edit",
	REQUIRED_FIELD_WARNING: commonLocaleContent.REQUIRED_FIELD_WARNING,
	NON_NEGATIVE_VALUE_WARNING: "Must be a non-negative number",
	GREATER_THAN_ONE_HUNDRED_WARNING: "Must be between 0-100",
	VALID_EMAIL_WARNING: "Must be a valid email address",
	NO_ALPHA_CHARACTERS_WARNING: "Value may only contain numeric characters",
	SUCCESS_MESSAGE: (resourceName, isCreateResource) =>
		`${resourceName} was successfully ${
			isCreateResource ? "created" : "updated"
		}`,
	DIRTY_FORM: {
		TITLE: "Unsaved Changes",
		DESCRIPTION: resourceString => `There are unsaved changes to this ${resourceString}. Would you like
		to save your changes before proceeding?`,
		ACTION_BUTTON_LABEL_DISCARD_AND_CONTINUE: "Discard",
		ACTION_BUTTON_LABEL_SAVE_AND_CONTINUE: "Save",
		ACTION_BUTTON_ARIA_LABEL_CLOSE: "close"
	},
	INVALID_DATE_WARNING: "Must be a valid date"
};
