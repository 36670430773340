import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import IconClose from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

import FormFieldSelect from "../FormFieldSelect/FormFieldSelect";
import lc from "./localeContent";
import crudFormLocaleContent from "../CrudForm/localeContent";
import FormFieldCurrency from "../FormCurrencyField/FormCurrencyField";
import FormFieldPercent from "../FormPercentField/FormPercentField";
import { FILTER_TYPE } from "../../config/constants";
import FormFieldMultiselectTypeAhead from "../FormFieldMultiselectTypeAhead/FormFieldMultiselectTypeAhead";
import FormFieldMultiselect from "../FormFieldMultiselect/FormFieldMultiselect";

export const FIELD_NAME_DIMENSION = "dimension";
export const FIELD_NAME_COMPARATOR = "comparator";
export const FIELD_NAME_VALUE = "value";
export const FIELD_NAME_FILTER_TYPE = "type";
export const PROGRAMMATIC_DEMAND_TYPE_FILTER = "Programmatic";
export const MANAGED_DEMAND_TYPE_FILTER = "Managed";
export const STATUS_TYPE_ACTIVE = "Active";
export const STATUS_TYPE_INACTIVE = "Inactive";

export const defaultAlphaMultipleSchema = Yup.array()
	.required(lc.MIN_VALUE_VALIDATION)
	.min(1, lc.MIN_VALUE_VALIDATION);

export const getValidationSchema = (options = {}) => {
	const { alphaMultipleSchema = defaultAlphaMultipleSchema } = options;
	return Yup.object({
		[FIELD_NAME_DIMENSION]: Yup.string().required(
			crudFormLocaleContent.REQUIRED_FIELD_WARNING
		),
		[FIELD_NAME_VALUE]: Yup.mixed()
			.when(FIELD_NAME_FILTER_TYPE, {
				is: type => type === FILTER_TYPE.ALPHA_MULTIPLE,
				then: alphaMultipleSchema
			})
			.when(FIELD_NAME_FILTER_TYPE, {
				is: type => type === FILTER_TYPE.ALPHA_SINGLE,
				then: Yup.string().required(
					crudFormLocaleContent.REQUIRED_FIELD_WARNING
				)
			})
			.when(FIELD_NAME_FILTER_TYPE, {
				is: type =>
					type === FILTER_TYPE.CURRENCY || type === FILTER_TYPE.PERCENTAGE,
				then: Yup.number().required(
					crudFormLocaleContent.REQUIRED_FIELD_WARNING
				)
			})
	});
};

export const COMPARATOR_OPTIONS = [
	{ value: "=", label: "=" },
	{ value: ">", label: ">" },
	{ value: ">=", label: ">=" },
	{ value: "<", label: "<" },
	{ value: "<=", label: "<=" }
];

const DEMAND_TYPE_OPTIONS = [
	{ value: MANAGED_DEMAND_TYPE_FILTER, label: MANAGED_DEMAND_TYPE_FILTER },
	{
		value: PROGRAMMATIC_DEMAND_TYPE_FILTER,
		label: PROGRAMMATIC_DEMAND_TYPE_FILTER
	}
];

const STATUS_TYPE_OPTIONS = [
	{ value: STATUS_TYPE_ACTIVE.toLowerCase(), label: STATUS_TYPE_ACTIVE },
	{
		value: STATUS_TYPE_INACTIVE.toLowerCase(),
		label: STATUS_TYPE_INACTIVE
	}
];

const ALPHA_SINGLE_OPTIONS = {
	demandType: DEMAND_TYPE_OPTIONS,
	status: STATUS_TYPE_OPTIONS
};

function FilterForm(props) {
	const {
		handleClose,
		dimensionOptions,
		dimensionValuesOptions,
		onSelectDimension,
		fetchingStatus,
		typeaheadDetails,
		editedDimension
	} = props;
	const { handleSubmit, values, setFieldValue } = useFormikContext();
	const dimensionValue = values[FIELD_NAME_DIMENSION];
	const selectedDimension = dimensionOptions.find(
		({ value }) => value === dimensionValue
	);

	const minimumCharsRequiredForTypeAhead = () => {
		const noMinCharsTypeAhead = ["seatId"];
		return noMinCharsTypeAhead.find(val => val === dimensionValue) ? 1 : 3;
	};

	useEffect(() => {
		if (selectedDimension) {
			const { type } = selectedDimension;
			if (
				type === FILTER_TYPE.ALPHA_MULTIPLE ||
				type === FILTER_TYPE.ALPHA_SINGLE
			) {
				setFieldValue(FIELD_NAME_COMPARATOR, "=");
			}
			setFieldValue(FIELD_NAME_FILTER_TYPE, type);
		}
	}, [selectedDimension, setFieldValue]);

	const type = selectedDimension?.type || FILTER_TYPE.ALPHA_MULTIPLE;

	useEffect(() => {
		if (selectedDimension) {
			if (editedDimension !== selectedDimension.value) {
				setFieldValue(FIELD_NAME_VALUE, "");
			}
			onSelectDimension(selectedDimension);
		}
	}, [selectedDimension, onSelectDimension, setFieldValue, editedDimension]);

	return (
		<Paper sx={{ flexGrow: 1, width: 650, overflow: "auto" }}>
			<Toolbar sx={{ pr: 1 }} variant="dense">
				<Typography sx={{ flex: "1 1 100%" }} component="div">
					{lc.FILTER_MENU_TITLE_ADD}
				</Typography>
				<IconButton onClick={handleClose}>
					<IconClose />
				</IconButton>
			</Toolbar>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<FormFieldSelect
							id={FIELD_NAME_DIMENSION}
							name={FIELD_NAME_DIMENSION}
							label={lc.FILTER_MENU_FIELD_DIMENSION.LABEL}
							optionsConfig={{
								options: dimensionOptions,
								fetchingStatus: fetchingStatus?.dimension
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<FormFieldSelect
							id={FIELD_NAME_COMPARATOR}
							name={FIELD_NAME_COMPARATOR}
							label={lc.FILTER_MENU_FIELD_COMPARATOR.LABEL}
							ariaOnlyLabel
							optionsConfig={{
								options: COMPARATOR_OPTIONS
							}}
							disabled={
								!selectedDimension ||
								type === FILTER_TYPE.ALPHA_MULTIPLE ||
								type === FILTER_TYPE.ALPHA_SINGLE
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={6}>
						{type === FILTER_TYPE.ALPHA_MULTIPLE &&
							(typeaheadDetails ? (
								<FormFieldMultiselectTypeAhead
									id={FIELD_NAME_VALUE}
									name={FIELD_NAME_VALUE}
									label={lc.FILTER_MENU_FIELD_VALUES.LABEL}
									optionsConfig={{
										options: dimensionValuesOptions[dimensionValue] || [],
										fetchingStatus: fetchingStatus?.values
									}}
									disabled={!selectedDimension}
									fieldTypeOptions={{ typeaheadDetails }}
									minCharsRequired={minimumCharsRequiredForTypeAhead()}
								/>
							) : (
								<FormFieldMultiselect
									id={FIELD_NAME_VALUE}
									name={FIELD_NAME_VALUE}
									label={lc.FILTER_MENU_FIELD_VALUES.LABEL}
									optionsConfig={{
										options: dimensionValuesOptions[dimensionValue] || [],
										fetchingStatus: fetchingStatus?.values
									}}
									disabled={!selectedDimension}
								/>
							))}
						{type === FILTER_TYPE.PERCENTAGE && (
							<FormFieldPercent
								id={FIELD_NAME_VALUE}
								name={FIELD_NAME_VALUE}
								label={lc.FILTER_MENU_FIELD_VALUE.LABEL}
								disabled={!selectedDimension}
							/>
						)}
						{type === FILTER_TYPE.CURRENCY && (
							<FormFieldCurrency
								id={FIELD_NAME_VALUE}
								name={FIELD_NAME_VALUE}
								label={lc.FILTER_MENU_FIELD_VALUE.LABEL}
								disabled={!selectedDimension}
							/>
						)}
						{type === FILTER_TYPE.ALPHA_SINGLE && (
							<FormFieldSelect
								id={FIELD_NAME_VALUE}
								name={FIELD_NAME_VALUE}
								label={lc.FILTER_MENU_FIELD_VALUE.LABEL}
								optionsConfig={{
									options: ALPHA_SINGLE_OPTIONS[dimensionValue]
								}}
								disabled={!selectedDimension}
							/>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={{ pr: 3 }}>
				<Button variant="contained" color="primary" onClick={handleSubmit}>
					{lc.BUTTON_LABEL_SAVE}
				</Button>
			</DialogActions>
		</Paper>
	);
}

FilterForm.propTypes = {
	handleClose: PropTypes.func.isRequired,
	dimensionOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	dimensionValuesOptions: PropTypes.shape().isRequired,
	onSelectDimension: PropTypes.func,
	fetchingStatus: PropTypes.shape(),
	typeaheadDetails: PropTypes.shape(),
	editedDimension: PropTypes.string
};

FilterForm.defaultProps = {
	onSelectDimension: () => {},
	fetchingStatus: undefined,
	typeaheadDetails: undefined,
	editedDimension: undefined
};

export default FilterForm;
