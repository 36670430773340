export default {
	ADD_ALIGNMENT_FILTER_BUTTON_LABEL: "Add a filter",
	FILTER_MENU_TITLE_ADD: "Add Filter",
	FILTER_MENU_FIELD_DIMENSION: {
		LABEL: "Dimension"
	},
	FILTER_MENU_FIELD_COMPARATOR: {
		LABEL: "Comparator"
	},
	FILTER_MENU_FIELD_VALUE: {
		LABEL: "Value"
	},
	FILTER_MENU_FIELD_VALUES: {
		LABEL: "Values"
	},
	BUTTON_LABEL_SAVE: "Save",
	MIN_VALUE_VALIDATION: "Must select at least one"
};
