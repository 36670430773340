/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import {
	useTable,
	useGlobalFilter,
	useSortBy,
	usePagination,
	useRowSelect
} from "react-table";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import _capitalize from "lodash/capitalize";
import TablePaginationActions from "../CrudDataGrid/DataTablePaginationActions";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import localeContent from "./localeContent";
import { isEmpty } from "../../utils/objectUtils";
import ReactTableSortableHeaderCell from "../ReactTableSortableHeaderCell/ReactTableSortableHeaderCell";
import { MUI_PADDING_NORMAL } from "../../config/constants";

export const ALIGNMENT_TABLE_SINGLE_TEST_ID_PREFIX = "alignment-table-";
export const TABLE_TITLE_TEST_ID_PREFIX = "alignment-table-title-";

const disabledCursorSx = { cursor: "not-allowed" };

const rowsPerPageOptions = [10, 15, 25];

// The header can use the table's getToggleAllRowsSelectedProps method
// to render a checkbox
function SelectAllHeaderCheckbox({
	getToggleAllRowsSelectedProps,
	isTableLocked
}) {
	return (
		<Checkbox
			{...getToggleAllRowsSelectedProps()}
			inputProps={{
				"aria-label": localeContent.TOGGLE_ALL_ROWS_SELECTED_LABEL
			}}
			disabled={isTableLocked}
		/>
	);
}
SelectAllHeaderCheckbox.propTypes = {
	getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
	isTableLocked: PropTypes.bool.isRequired
};

// The cell can use the individual row's getToggleRowSelectedProps method
// to the render a checkbox
function SelectRowCheckbox({ row, isTableLocked }) {
	return (
		<Checkbox
			{...row.getToggleRowSelectedProps()}
			inputProps={{ "aria-label": localeContent.TOGGLE_ROW_SELECTED_LABEL }}
			disabled={isTableLocked}
		/>
	);
}
SelectRowCheckbox.propTypes = {
	row: PropTypes.shape().isRequired,
	isTableLocked: PropTypes.bool.isRequired
};

function AlignmentTableSingle(props) {
	const {
		columns,
		data,
		tableId,
		accessibleTableLabel,
		tableSelectionOptions,
		tableSelectionValue,
		setTableSelectionValue,
		editedTableId,
		setEditedTable,
		selectionText,
		defaultHiddenColumns,
		onSubmit,
		commonPageSize,
		setCommonPageSize,
		setDirtyOverride,
		setPotentialRedirectLocation,
		entityTypeLabel
	} = props;
	const isSingleTableView = useMediaQuery(theme =>
		theme.breakpoints.down("lg")
	);

	const isTableLocked = Boolean(editedTableId && editedTableId !== tableId);
	const hidden = isSingleTableView && tableSelectionValue !== tableId;
	const tableTitle = useMemo(() => {
		return tableSelectionOptions.find(({ value }) => value === tableId).label;
	}, [tableSelectionOptions, tableId]);
	const [selectedOriginalIds, setSelectedOriginalIds] = useState(null);
	const [prefilteredSelectedIds, setPrefilteredSelectedIds] = useState(null);
	const {
		getTableProps,
		getTableBodyProps,
		visibleColumns,
		headerGroups,
		allColumns,
		prepareRow,
		rows,
		page,
		gotoPage,
		setPageSize,
		toggleAllRowsSelected,
		selectedFlatRows,
		state: { globalFilter, pageIndex, pageSize, selectedRowIds },
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			disableSortRemove: true,
			initialState: {
				hiddenColumns: defaultHiddenColumns,
				pageIndex: 0,
				pageSize: commonPageSize
			},
			autoResetPage: false,
			autoResetSortBy: false,
			autoResetGlobalFilter: false,
			stateReducer: (newState, action) => {
				const modifiedState = { ...newState };
				if (action.type === "resetSelectedRows") {
					if (selectedOriginalIds) {
						setPrefilteredSelectedIds(selectedOriginalIds);
					}
				}
				return modifiedState;
			}
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
			hooks.visibleColumns.push(cols => [
				// Make a column for selection
				{
					id: "selection",
					tableCellPadding: "checkbox",
					disableSortBy: true,
					preventVisibilityToggle: true,
					disabledPointerWhenLocked: true,
					Header: SelectAllHeaderCheckbox,
					Cell: SelectRowCheckbox
				},
				...cols
			]);
		}
	);

	useEffect(() => {
		setPageSize(commonPageSize);
	}, [commonPageSize, setPageSize]);
	const handleSubmit = useCallback(
		() => onSubmit(selectedFlatRows.map(({ original }) => original)),
		[onSubmit, selectedFlatRows]
	);

	// Maintain a map of selected rows by row.original.id (original data id)
	useEffect(() => {
		const anySelected = !isEmpty(selectedFlatRows);
		setSelectedOriginalIds(
			anySelected
				? selectedFlatRows.reduce(
						(aggregator, selectedRow) => ({
							...aggregator,
							[selectedRow.original.id]: true
						}),
						{}
				  )
				: null
		);
		// Set table to editing if anything is selected
		if (anySelected && !editedTableId) {
			setEditedTable(tableId);
			setDirtyOverride({
				when: true,
				resourceString: `${entityTypeLabel}'s alignments`,
				submitForm: path => {
					setPotentialRedirectLocation(path);
					handleSubmit();
				}
			});
		}
		// Clear edited table if no selections remain
		if (!anySelected && editedTableId === tableId) {
			setEditedTable(null);
		}
	}, [
		selectedFlatRows,
		setEditedTable,
		tableId,
		editedTableId,
		handleSubmit,
		setPotentialRedirectLocation,
		setDirtyOverride,
		entityTypeLabel
	]);

	// Restore any prior selections to filtered data
	useEffect(() => {
		if (prefilteredSelectedIds) {
			rows.forEach(row => {
				if (prefilteredSelectedIds[row.original.id]) {
					prepareRow(row);
					row.toggleRowSelected();
				}
			});
			setPrefilteredSelectedIds(null);
		}
	}, [prefilteredSelectedIds, rows, prepareRow]);
	const emptyRows = pageSize - page.length;

	const safePage = Math.floor(rows.length / pageSize);
	useEffect(() => {
		if (pageIndex > safePage) {
			gotoPage(safePage);
		}
	}, [safePage, pageIndex, gotoPage, rows, pageSize]);

	useEffect(() => {
		rows.map(row => {
			row.values.status = _capitalize(row.values.status);
			return row;
		});
	}, [rows]);

	return (
		<Box
			my={3}
			px={2}
			py={2}
			component={Paper}
			style={hidden ? { display: "none" } : null}
			data-testid={`${ALIGNMENT_TABLE_SINGLE_TEST_ID_PREFIX}${tableId}`}
		>
			{!isSingleTableView && (
				<Typography
					data-testid={`${TABLE_TITLE_TEST_ID_PREFIX}${tableId}`}
					sx={{ mb: 1 }}
				>
					{tableTitle}
				</Typography>
			)}
			<EnhancedTableToolbar
				tableSelectionOptions={tableSelectionOptions}
				tableSelectionValue={tableSelectionValue}
				setTableSelectionValue={setTableSelectionValue}
				numSelected={Object.keys(selectedRowIds).length}
				allColumns={allColumns}
				globalFilter={globalFilter}
				setGlobalFilter={setGlobalFilter}
				toggleAllRowsSelected={toggleAllRowsSelected}
				tableId={tableId}
				selectionText={selectionText}
				isSingleTableView={isSingleTableView}
				onSubmit={handleSubmit}
			/>
			<TableContainer>
				<MaUTable
					{...getTableProps()}
					aria-label={accessibleTableLabel}
					size="small"
					sx={{ minWidth: "960px" }}
				>
					<TableHead>
						{headerGroups.map(headerGroup => (
							<TableRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<ReactTableSortableHeaderCell
										key={column.id}
										column={column}
										sxProps={
											isTableLocked && column.disabledPointerWhenLocked
												? disabledCursorSx
												: undefined
										}
										renderProps={{ isTableLocked }}
									/>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody {...getTableBodyProps()}>
						{page.map(row => {
							prepareRow(row);
							return (
								<TableRow
									{...row.getRowProps()}
									data-testid={row.original.id}
									sx={isTableLocked ? disabledCursorSx : { cursor: "pointer" }}
									selected={row.isSelected}
									onClick={
										!isTableLocked ? () => row.toggleRowSelected() : undefined
									}
									hover={!isTableLocked}
								>
									{row.cells.map(cell => (
										<TableCell
											{...cell.getCellProps()}
											padding={
												cell.column.tableCellPadding || MUI_PADDING_NORMAL
											}
											align={cell.column.align}
										>
											{cell.render("Cell", { isTableLocked })}
										</TableCell>
									))}
								</TableRow>
							);
						})}
						{// Magical height number comes from MUI docs https://material-ui.com/components/tables/#sorting-amp-selecting
						emptyRows > 0 && (
							<TableRow style={{ height: 33 * emptyRows }}>
								<TableCell colSpan={visibleColumns.length} />
							</TableRow>
						)}
					</TableBody>
				</MaUTable>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={rowsPerPageOptions}
				component="div"
				count={rows.length}
				rowsPerPage={pageSize}
				page={Math.min(safePage, pageIndex)}
				onPageChange={(event, newPage) => {
					gotoPage(newPage);
				}}
				onRowsPerPageChange={event => {
					setCommonPageSize(parseInt(event.target.value, 10));
					gotoPage(0);
				}}
				ActionsComponent={TablePaginationActions}
				SelectProps={{
					labelId: "rows-per-page-label",
					name: "rowsPerPage"
				}}
				labelRowsPerPage={<span id="rows-per-page-label">Rows per page:</span>}
			/>
		</Box>
	);
}

AlignmentTableSingle.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	data: PropTypes.arrayOf(PropTypes.shape()),
	tableId: PropTypes.string.isRequired,
	tableSelectionOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	tableSelectionValue: PropTypes.string.isRequired,
	setTableSelectionValue: PropTypes.func.isRequired,
	selectionText: PropTypes.string.isRequired,
	defaultHiddenColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
	onSubmit: PropTypes.func.isRequired,
	accessibleTableLabel: PropTypes.string.isRequired,
	isSingleTableView: PropTypes.bool,
	editedTableId: PropTypes.string,
	setEditedTable: PropTypes.func.isRequired,
	commonPageSize: PropTypes.number.isRequired,
	setCommonPageSize: PropTypes.func.isRequired,
	setDirtyOverride: PropTypes.func.isRequired,
	entityTypeLabel: PropTypes.string,
	setPotentialRedirectLocation: PropTypes.func.isRequired
};

AlignmentTableSingle.defaultProps = {
	data: [],
	isSingleTableView: false,
	editedTableId: null,
	entityTypeLabel: null
};

export default AlignmentTableSingle;
